// MapComponent.js
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
    iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const ChangeView = ({ center, zoom }) => {
    const map = useMap();
    map.setView(center, zoom);
    return null;
};

const MapComponent = ({ latitude, longitude }) => {
    const [locationName, setLocationName] = useState('');
    const zoomLevel = 13;

    useEffect(() => {
        const fetchLocationName = async () => {
            try {
                const response = await fetch(
                    `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
                );
                const data = await response.json();
                setLocationName(data.display_name || 'Location not found');
            } catch (error) {
                console.error('Error fetching location name:', error);
                setLocationName('Error fetching location');
            }
        };
        fetchLocationName();
    }, [latitude, longitude]);

    return (
        <MapContainer
            center={[latitude, longitude]}
            zoom={zoomLevel}
            style={{ height: '230px', width: '100%' }}
        >
            <ChangeView center={[latitude, longitude]} zoom={zoomLevel} />
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
            />
            <Marker position={[latitude, longitude]}>
                <Popup>
                    <div>
                        <strong>Location:</strong> {locationName} <br />
                        <strong>Coordinates:</strong> {latitude}, {longitude}
                    </div>
                </Popup>
            </Marker>
        </MapContainer>
    );
};

export default MapComponent;

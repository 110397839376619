import React, { useState } from 'react';
import { Tab, Nav } from "react-bootstrap";
import TitleCmp from "../../Common/TitleCmp";
import DataTable from 'react-data-table-component';
import Dropdown from 'react-bootstrap/Dropdown';
import { SendIcon } from '../../../Components/Filter/Filter';
import { tableCustomStyles } from '../../../Components/Common/Utility';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faGear } from '@fortawesome/free-solid-svg-icons';
import { customTableStyles } from '../../Utility/CustomStylesForReact';
import '../section.css';
import useObjState from '../../../CADHook/useObjState';

const HospitalStatusCodeSection = () => {
  const [filterTypeIdOption, setFilterTypeIdOption] = useState('Contains');
  const [pageStatus, setPageStatus] = useState("1");
  const [
    hospitalStatusCodeState,
    _setHospitalStatusCodeState,
    handleHospitalStatusCodeState,
    _clearHospitalStatusCodeState,
  ] = useObjState({
    hospitalCode: "",
    codeDesc: "",
  });

  console.log("hospitalStatusCodeState", hospitalStatusCodeState)
  const data = [
    { id: 1, hospitalStatusCode: 'A1', codeDescription: 'Active Status', },
    { id: 2, hospitalStatusCode: 'B2', codeDescription: 'Inactive Status', },
    { id: 3, hospitalStatusCode: 'C3', codeDescription: 'Pending Status', },
    { id: 4, hospitalStatusCode: 'D4', codeDescription: 'Cancelled Status', },
    { id: 5, hospitalStatusCode: 'E5', codeDescription: 'Suspended Status', },
    { id: 6, hospitalStatusCode: 'F6', codeDescription: 'Deceased Status', },
  ];

  const [toggleStates, setToggleStates] = useState(
    data.map(() => true) // Initialize all as toggled on
  );

  // Handle toggle click
  const handleToggle = (index) => {
    setToggleStates(prevStates =>
      prevStates.map((state, i) => (i === index ? !state : state))
    );
  };

  const columns = [
    {
      name: 'Hospital Status Code',
      selector: row => row.hospitalStatusCode,
      sortable: true,
    },
    {
      name: 'Code Description',
      selector: row => row.codeDescription,
      sortable: true,
      width: "60%"
    },
    {
      name:
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {'Status'}
        </div>,
      cell: (row, index) =>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <span
            onClick={() => handleToggle(index)}
            className="btn btn-sm text-white px-1 py-0 mr-1"
            data-toggle="modal" data-target="#ConfirmModal"
            style={{ background: "#ddd", cursor: "pointer" }}
          >
            {toggleStates[index] ? (
              <i className="fa fa-toggle-on" style={{ color: "green" }} aria-hidden="true"></i>
            ) : (
              <i className="fa fa-toggle-off" style={{ color: "red" }} aria-hidden="true"></i>
            )}
          </span>
        </div>,
      width: "70px",
    },

  ];

  return (
    <>
      <div className='utilities-tab-content-main-container'>
        <TitleCmp title={"Hospital Status Code"} />
        <div className='utilities-tab-content-form-container'>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 incident-tab ">
              <ul className="nav nav-tabs mb-1 pl-2 " id="myTab" role="tablist">
                <span className={`nav-item ${pageStatus === '1' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("1"); }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '1' ? 'Red' : '' }}>Active</span>
                <span className={`nav-item ${pageStatus === '0' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("0"); }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '0' ? 'Red' : '' }}>InActive</span>
              </ul>
            </div>
            {
              pageStatus === '1' ?
                <>
                  <div className='utilities-tab-content-form-main'>
                    {/* line 1 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Hospital Status Code
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <input
                          type="text"
                          className="form-control py-1 new-input requiredColor"
                          placeholder='Placeholder'
                          value={hospitalStatusCodeState?.hospitalCode}
                          onChange={(e) => handleHospitalStatusCodeState("hospitalCode", e.target.value)}
                        />
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Code Description
                        </label>
                      </div>
                      <div className="col-4 d-flex align-self-center justify-content-end">
                        <input
                          type="text"
                          className="form-control py-1 new-input"
                          placeholder='Placeholder'
                          value={hospitalStatusCodeState?.codeDesc}
                          onChange={(e) => handleHospitalStatusCodeState("codeDesc", e.target.value)}

                        />
                      </div>
                    </div>

                    <div className="utilities-tab-content-button-container bb pb-2" >
                      <button type="button" className="btn btn-sm btn-success">Cancel</button>
                      <button type="button" className="btn btn-sm btn-success">Save</button>
                    </div>
                  </div>
                </>
                :
                <>
                </>
            }
          </div>

        </div>

        <div className='utilities-tab-content-table-container'>
          <div className="row mb-2">
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control py-1 new-input"
                placeholder='Search By Code...'
              />
            </div>
            <div className="col-1 d-flex align-self-center justify-content-end" style={{ zIndex: '2', }}>
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeIdOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control py-1 new-input"
                placeholder='Search By Description...'
              />
            </div>
            <div className="col-1 d-flex align-self-center justify-content-end" style={{ zIndex: '1', }}>
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeIdOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="table-responsive">
            <DataTable
              dense
              columns={columns}
              data={data}
              customStyles={tableCustomStyles}
              pagination // enable pagination
              responsive // enable responsive design
              striped // add zebra-striping to the table rows
              highlightOnHover // highlight row on hover
              fixedHeader
            // fixedHeaderScrollHeight="200px" 
            />
          </div>

          {/* <div className="utilities-tab-content-button-container" >
            <button type="button" className="btn btn-sm btn-success">Cancel</button>
            <button type="button" className="btn btn-sm btn-success">Save</button>
          </div> */}
        </div>
      </div>

    </>
  );
};

export default HospitalStatusCodeSection;
import React, { useContext, useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Link, useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Decrypt_Id_Name, colourStyles, getShowingDateText, getShowingWithOutTime, stringToBase64 } from '../../../Common/Utility';
import { AddDeleteUpadate, fetchPostData } from '../../../hooks/Api';
import { AgencyContext } from '../../../../Context/Agency/Index';
import { Comman_changeArrayFormat } from '../../../Common/ChangeArrayFormat';
import { toastifyError, toastifySuccess } from '../../../Common/AlertMsg';
import DeletePopUpModal from '../../../Common/DeleteModal';

const ArrestSearch = () => {

  const navigate = useNavigate();
  const { arrestSearchData } = useContext(AgencyContext);
  const [loginPinID, setLoginPinID,] = useState('');
  const [arrestSearchID, setArrestSearchID,] = useState('');


  const columns = [
    {
      width: '150px',
      name: <p className='text-end' style={{ position: 'absolute', top: '7px' }}>Action</p>,
      cell: row =>
        <span onClick={(e) => set_Edit_Value(row)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1">
          <i className="fa fa-edit"></i>
        </span>
    },
    {
      name: 'Arrest Date/Time',
      selector: (row) => row.ArrestDtTm ? getShowingDateText(row.ArrestDtTm) : " ",
      sortable: true
    },
    {
      name: 'Arrest Number',
      selector: (row) => row.ArrestNumber,
      sortable: true
    },
    {
      name: 'Incident Number',
      selector: (row) => row.IncidentNumber,
      sortable: true
    },
    {
      name: 'Arrest Type',
      selector: (row) => row.ArrestType_Description,
      sortable: true
    },
    {
      name: 'Arrest by',
      selector: (row) => row.GivenBy_Name,
      sortable: true
    },
    {
      name: 'Arrestee Name',
      selector: (row) => row.Arrestee_Name,
      sortable: true
    },

    // {
    //   name: <p className='text-end' style={{ position: 'absolute', top: '7px', right: 5 }}>Delete</p>,
    //   cell: row =>
    //     <div className="div" style={{ position: 'absolute', right: 5 }}>
    //       <Link to={`#`} onClick={(e) => setArrestSearchID(row.ArrestID)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
    //         <i className="fa fa-trash"></i>
    //       </Link>
    //     </div>
    // },
  ]

  const set_Edit_Value = (row) => {
    console.log(row)
    if (row.ArrestID) {
      navigate(`/Arrest-Home?page=MST-Arrest-Dash&ArrNo=${row?.ArrestNumber}&IncId=${stringToBase64(row?.IncidentID)}&Name=${row?.Arrestee_Name}&ArrestId=${stringToBase64(row?.ArrestID)}&ArrestSta=${true}&ChargeSta=${true}`);
    }
  }

  const DeleteArrest = () => {
    const val = {
      'ArrestID': arrestSearchID,
      'DeletedByUserFK': loginPinID
    }
    AddDeleteUpadate('Arrest/Delete_Arrest', val).then((res) => {
      if (res) {
        toastifySuccess(res.Message);
      } else { console.log("Somthing Wrong"); }
    })
  }

  const startRef = React.useRef();
  const startRef1 = React.useRef();

  const onKeyDown = (e) => {
    if (e.keyCode === 9 || e.which === 9) {
      startRef.current.setOpen(false);
      startRef1.current.setOpen(false);
    }
  };

  return (
    <>
      <div className="section-body view_page_design pt-3">
        <div className="row clearfix" >
          <div className="col-12 col-sm-12">
            <div className="card Agency">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 ">
                    <DataTable
                      dense
                      columns={columns}
                      data={arrestSearchData?.length > 0 ? arrestSearchData : []}
                      pagination
                      selectableRowsHighlight
                      highlightOnHover
                      paginationPerPage={'5'}
                      paginationRowsPerPageOptions={[5, 10, 15, 20]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {
        advancedSearch &&
        <dialog className="modal top fade " style={{ background: "rgba(0,0,0, 0.5)" }} id="ArrestSearchModal" tabIndex="-1" data-backdrop="false" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog  modal-xl">
            <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0px 0px 3px floralwhite' }}>
              <div className="modal-header px-3 p-2" style={{ backgroundColor: 'aliceblue', boxShadow: '0px 0px 2px dimgray' }}>
                <h5 className="modal-title">Arrest Advance Search</h5>
                <button type="button" className="close btn-modal" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" style={{ color: 'red', fontSize: '20px', }}>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="m-1 ">
                  <div className="row">
                    <div className="col-12">
                      <fieldset className='fieldset'>
                        <legend>Arrest Information</legend>
                        <div className="row">
                          <div className="col-6 col-md-3 mt-1">
                            <div className="text-field">
                              <input type="text" id='ArrestNumber' name='ArrestNumber' value={value?.ArrestNumber} onChange={HandleChange} />
                              <label className=''>Arrest Number From</label>
                            </div>
                          </div>
                          <div className="col-6 col-md-3 mt-1">
                            <div className="text-field">
                              <input type="text" id='ArrestNumberTo' name='ArrestNumberTo' value={value?.ArrestNumberTo} onChange={HandleChange} />
                              <label className=''>Arrest Number To</label>
                            </div>
                          </div>
                          <div className="col-6 col-md-3 mb-1">
                            <div className="dropdown__box">
                              <DatePicker
                                id='ArrestDtTm'
                                name='ArrestDtTm'
                                ref={startRef}
                                onKeyDown={onKeyDown}
                                onChange={(date) => { setArrestFromDate(date); setValue({ ...value, ['ArrestDtTm']: date ? getShowingWithOutTime(date) : null }) }}
                                className=''
                                dateFormat="MM/dd/yyyy"
                                autoComplete='Off'
                                timeInputLabel
                                maxDate={new Date()}
                                isClearable
                                showYearDropdown
                                dropdownMode="select"
                                selected={arrestFromDate}
                                placeholderText={value?.ArrestDtTm ? value.ArrestDtTm : 'Select...'}
                              />
                              <label htmlFor="" className='pt-1'>Arrest From Date</label>
                            </div>
                          </div>
                          <div className="col-6 col-md-3 mb-1">
                            <div className="dropdown__box">
                              <DatePicker
                                id='ArrestDtTmTo'
                                name='ArrestDtTmTo'
                                ref={startRef1}
                                onKeyDown={onKeyDown}
                                onChange={(date) => { setArrestToDate(date); setValue({ ...value, ['ArrestDtTmTo']: date ? getShowingWithOutTime(date) : null }) }}
                                className=''
                                dateFormat="MM/dd/yyyy"
                                autoComplete='Off'
                                timeInputLabel
                                minDate={arrestFromDate}
                                maxDate={new Date()}
                                isClearable
                                showYearDropdown
                                dropdownMode="select"
                                selected={arrestToDate}
                                placeholderText={value?.ArrestDtTmTo ? value.ArrestDtTmTo : 'Select...'}
                              />
                              <label htmlFor="" className='pt-1'>Arrest To Date</label>
                            </div>
                          </div>
                          <div className="col-6 col-md-4 col-lg-3 mt-2">
                            <div className="text-field">
                              <input type="text" id='IncidentNumber' name='IncidentNumber' value={value?.IncidentNumber} onChange={HandleChange} />
                              <label className=''>Incident</label>
                            </div>
                          </div>
                          <div className="col-6 col-md-4 col-lg-3 mb-1 mt-2">
                            <div className="dropdown__box">
                              <Select
                                name='PrimaryOfficerID'
                                styles={colourStyles}
                                menuPlacement='bottom'
                                value={headOfAgency?.filter((obj) => obj.value === value?.PrimaryOfficerID)}
                                isClearable
                                options={headOfAgency}
                                onChange={(e) => ChangeDropDown(e, 'PrimaryOfficerID')}
                                placeholder="Select..."
                              />
                              <label htmlFor='' className='mt-0'>Arresting Officer</label>
                            </div>
                          </div>
                          <div className="col-6 col-md-4 col-lg-3 mb-1 mt-2">
                            <div className="dropdown__box">
                              <Select
                                name='ChargeCodeID'
                                styles={colourStyles}
                                value={chargeCodeDrp?.filter((obj) => obj.value === value?.ChargeCodeID)}
                                isClearable
                                options={chargeCodeDrp}
                                onChange={(e) => ChangeDropDown(e, 'ChargeCodeID')}
                                placeholder="Select..."
                              />
                              <label htmlFor='' className='mt-0'>Charge Code/Description</label>
                            </div>
                          </div>
                          <div className="col-6 col-md-4 col-lg-3 mb-1 mt-2">
                            <div className="dropdown__box">
                              <Select
                                name='ArrestTypeID'
                                styles={colourStyles}
                                value={arrestTypeDrpData?.filter((obj) => obj.value === value?.ArrestTypeID)}
                                isClearable
                                options={arrestTypeDrpData}
                                onChange={(e) => { ChangeDropDown(e, 'ArrestTypeID') }}
                                placeholder="Select..."
                              />
                              <label htmlFor='' className='mt-0'>Arrest Type</label>
                            </div>
                          </div>
                          <div className="col-6 col-md-4 col-lg-3 mb-1 mt-2">
                            <div className="dropdown__box">
                              <Select
                                name="ArrestingAgencyID"
                                styles={colourStyles}
                                value={agencyNameDrpData?.filter((obj) => obj.value === value?.ArrestingAgencyID)}
                                isClearable
                                options={agencyNameDrpData}
                                onChange={(e) => { ChangeDropDown(e, 'ArrestingAgencyID') }}
                                placeholder="Select..."
                              />
                              <label htmlFor='' className='mt-0'>Arresting Agency</label>
                            </div>
                          </div>
                          <div className="col-6 col-md-4 col-lg-3 mb-1 mt-2">
                            <div className="dropdown__box">
                              <Select
                                name='JuvenileDispositionID'
                                menuPlacement='bottom'
                                styles={colourStyles}
                                value={juvenileDisDrp?.filter((obj) => obj.value === value?.JuvenileDispositionID)}
                                isClearable
                                options={juvenileDisDrp}
                                onChange={(e) => ChangeDropDown(e, 'JuvenileDispositionID')}
                                placeholder="Select..."
                              />
                              <label htmlFor='' className='mt-0'>Juvenile Disposition</label>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset className='fieldset mt-2'>
                        <legend>Arrestee Information</legend>
                        <div className="row">
                          <div className="col-6 col-md-3 mt-2">
                            <div className="text-field">
                              <input type="text" id='LastName' name='LastName' value={value?.LastName} onChange={HandleChange} />
                              <label className=''>Last Name</label>
                            </div>
                          </div>
                          <div className="col-6 col-md-3 mt-2">
                            <div className="text-field">
                              <input type="text" id='FirstName' name='FirstName' value={value?.FirstName} onChange={HandleChange} />
                              <label className=''>First Name</label>
                            </div>
                          </div>
                          <div className="col-6 col-md-3 mt-2">
                            <div className="text-field">
                              <input type="text" id='MiddleName' name='MiddleName' value={value?.MiddleName} onChange={HandleChange} />
                              <label className=''>Middle Name</label>
                            </div>
                          </div>
                          <div className="col-6 col-md-3 mt-2">
                            <div className="text-field">
                              <input type="text" id='SSN' name='SSN' value={value?.SSN} onChange={HandleChange} />
                              <label className=''>SSN</label>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      <div className="row mt-1 px-2 text-right">
                        <div className="col-12">
                          <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => { get_Data_Arrest(); }}>Search</button>
                          <button type="button" data-dismiss="modal" className="btn btn-sm btn-success" >Close</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </dialog>
      } */}
      <DeletePopUpModal func={DeleteArrest} />
    </>
  )
}

export default ArrestSearch
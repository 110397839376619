import axios from "axios";
class ResourcesStatusServices {

    getIncidents = async ({ queryKey }) => {
        const [_key] = queryKey;
        return await axios.post(`/CallTakerIncident/GetData_Incident_dp`);

    };

    getHospitalNameCode = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/MasterHospitalNamecode/InsertMasterHospitalNamecode`, payload);
        }
        console.warn("payload not provided, ResourcesStatusServices.getHospitalNameCode");
        return null;
    };

    insertDispatcher = async (payload) => {
        if (payload) {
            return await axios.post(`/Dispatcher/Dispatcher`, payload);
        }
        console.warn("payload not provided, ResourcesStatusServices.insertDispatcher");
        return null;
    };

}

const instance = new ResourcesStatusServices();

export default instance;

import React, { useContext, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPostData } from '../../../../hooks/Api';
import { Decrypt_Id_Name, getShowingDateText, tableCustomStyles } from '../../../../Common/Utility';
import { get_ScreenPermissions_Data } from '../../../../../redux/actions/IncidentAction';


const VehicleTransactionLog = (props) => {

    const { DecVehId, DecMVehId, DecIncID } = props

    const dispatch = useDispatch();
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const [transactionData, setTransactionData] = useState([]);
    const effectiveScreenPermission = useSelector((state) => state.Incident.effectiveScreenPermission);


    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    const { nameFilterData, setIncidentStatus } = useContext(AgencyContext);

    // const useQuery = () => new URLSearchParams(useLocation().search);

    useEffect(() => {
        if (localStoreData) {
            dispatch(get_ScreenPermissions_Data("V085", localStoreData?.AgencyID, localStoreData?.PINID));
        }
    }, [localStoreData]);

    let openPage = useQuery().get('page');
    useEffect(() => {
        if (DecVehId) { Get_TransactionData(DecVehId); }
        setIncidentStatus(true);
    }, [DecVehId])

    const Get_TransactionData = (VehicleID) => {
        const val = { 'VehicleID': VehicleID, }
        fetchPostData('TransactionLog/GetData_VehicleTransactionLog', val).then((res) => {
            if (res) {
                console.log(res);
                setTransactionData(res)
            } else {
                setTransactionData();
            }
        })
    }

    const columns = [
        // {
        //     width: '120px',
        //     name: 'Full Name',
        //     selector: (row) => row.LastName + ' ' + row.FirstName + ',' + row.MiddleName,
        //     sortable: true
        // },
        {
            width: '120px',
            name: 'Full Name',
            selector: (row) => row.Name,
            sortable: true
        },
        {
            width: '180px',
            name: 'Transaction Name',
            selector: (row) => row.TransactionName,
            sortable: true
        },
        {
            width: '180px',
            name: 'Transaction Number',
            selector: (row) => row.TransactionNumber,
            sortable: true
        },
        // {
        //     width: '180px',
        //     name: 'Transaction Name',
        //     selector: (row) => row.TransactionName,
        //     sortable: true
        // },
        // {
        //     width: '180px',
        //     name: 'Transaction Number',
        //     selector: (row) => row.TransactionNumber,
        //     sortable: true
        // },
        // {
        //     name: 'Loss Code ',
        //     selector: (row) => row.LossCode_Description,
        //     sortable: true
        // },
        // {
        //     width: '180px',
        //     name: 'Reported Date/Time',
        //     selector: (row) => row.ReportedDate ? getShowingDateText(row.ReportedDate) : " ",
        //     sortable: true
        // },
        // {
        //     width: '100px',
        //     name: 'DOB',
        //     selector: (row) => row.DOB,
        //     sortable: true
        // },
        // {
        //     width: '90px',
        //     name: 'Age',
        //     selector: (row) => row.Age,
        //     sortable: true
        // },
        // {
        //     width: '100px',
        //     name: 'Race',
        //     selector: (row) => row.Race,
        //     sortable: true
        // },
        // {
        //     width: '130px',
        //     name: 'Reason Code',
        //     selector: (row) => <>{row?.ReasonCode ? row?.ReasonCode.substring(0, 10) : ''}{row?.ReasonCode?.length > 20 ? '  . . .' : null} </>,
        //     sortable: true
        // },
        // {
        //     name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 0 }}>Action</p>,
        //     cell: row =>
        //         <div className="div" style={{ position: 'absolute', top: 4, right: 4 }}>
        //             <Link to={openPage === 'mastername' ? '/nametab?page=mastername' : '/nametab'} className="btn btn-sm bg-green text-white px-1 py-0" >
        //                 <i className="fa fa-eye"></i>
        //             </Link>
        //         </div>

        // },
    ]

    return (

        <div className="col-md-12 mt-2">
            {/* <div className="bg-line text-white py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                <p className="p-0 m-0 d-flex align-items-center">
                    Transaction Log
                </p>
            </div> */}
            <div className="col-12">
                <DataTable
                    dense
                    columns={columns}
                    data={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? transactionData : [] : transactionData}
                    noDataComponent={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? "There are no data to display" : "You don’t have permission to view data" : 'There are no data to display'}
                    pagination
                    selectableRowsHighlight
                    highlightOnHover
                    responsive
                    showPaginationBottom={10}
                    fixedHeader
                    persistTableHead={true}
                    customStyles={tableCustomStyles}
                />
            </div>
        </div>



    )
}

export default VehicleTransactionLog
// Import Component
import React, { memo, useContext, useState } from 'react'
import { toastifyError } from '../Common/AlertMsg';
import { get_OTP } from '../Common/Utility';
import { fetchPostData } from '../hooks/Api';
import Otp from './Otp';
import { AgencyContext } from '../../Context/Agency/Index';
import Select from 'react-select';

const ForgotPassword = () => {

    const { setForgetPasswordArray, } = useContext(AgencyContext);
    const [userName, setUserName] = useState('');
    const [timerOn, setTimerOn] = useState(false);
    const [otp, setOtp] = useState('');
    const [type, setType] = useState('forget_password')
    const [userNameErr, setUserNameErr] = useState(false)

    // Check User 
    const verify_User = (e) => {
        const value = { UserName: userName }
        fetchPostData('Personnel/GetData_ForgotPassword', value)
            .then(res => {
                if (res.length > 0) {
                    setForgetPasswordArray(res);
                    dom_Fuction('otp-page')
                }
                else toastifyError('Username is Wrong')
            })
    }

    const handleLoginSubmit = async (e) => {
        e.preventDefault()
        if (userName === '') {
            setUserNameErr("Please enter UserName")
        } else {
            setUserNameErr(false)
        }

        if (userName !== '' || userNameErr) {
            verify_User()
        }

    }

    // Change Page With Dom 
    const dom_Fuction = (val) => {
        if (val === 'otp-page') {
            setTimerOn(true)
            const otp = get_OTP()
            setOtp(otp)
            document.getElementById('otp-page').style.display = 'block'
            document.getElementById('check_user').style.display = 'none'
            document.getElementById('change_pass').style.display = 'none'
        }
    }

    const handleCopy = (e) => {
        e.preventDefault();
    };

    // custuom style withoutColor
    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 20,
            minHeight: 35,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    return (
        <>
            {/* User Verify Page */}
            <div className="login-container" id='check_user' style={{ display: 'block' }}>
                <div className="auth mx-4">
                    <div className="card py-3 py-3 col-12 col-sm-6 col-md-5 col-lg-3">
                        <div className="text-center mb-2">
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleLoginSubmit} >
                                <div className="text-center pb-2">
                                    <h5 className="m-0 pb-3">Forgot Password</h5>
                                    <spna style={{ fontSize: '14px', color: 'red' }}></spna>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">UserName </label>
                                    <input type="text" onCut={handleCopy} onCopy={handleCopy} onPaste={handleCopy} className="form-control" onChange={(e) => setUserName(e.target.value)} placeholder="Enter Username" />
                                    {userNameErr && <span className="text-danger" style={{ fontSize: '13px' }}>{userNameErr}</span>}
                                </div>
                                <div className="form-group">
                                    {/* <label className="form-label">Agency</label> */}
                                    <Select
                                        name='AgencyID'
                                        // value={agency?.filter((obj) => obj.value === LoginAgencyId)}
                                        // options={agency}
                                        // placeholder={agencyPlaceholder}
                                        // onChange={(e) => AgencyChanges(e)}
                                        styles={customStylesWithOutColor}
                                    />
                                    {/* <span className="text-danger" style={{ fontSize: '13px' }}>{agencyErr}</span> */}
                                </div>
                                <div className='mt-1'>
                                    <button type="submit" className="btn btn-primary btn-block" >Verify</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
            {/* Otp Page Component */}
            <div id='otp-page' style={{ display: 'none' }}>
                <Otp {...{ timerOn, otp, setOtp, type }} />
            </div>
        </>
    )
}

export default memo(ForgotPassword)
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { SendIcon } from '../../../Components/Filter/Filter';
import { tableCustomStyles } from '../../../Components/Common/Utility';
import DataTable from 'react-data-table-component';
import '../section.css';
import useObjState from '../../../CADHook/useObjState';
import { toastifySuccess } from '../../../Components/Common/AlertMsg';
import MasterTableListServices from "../../../CADServices/APIs/masterTableList";
import { useQuery } from 'react-query';


const ResourceTypeSection = () => {
  const [filterTypeIdOption, setFilterTypeIdOption] = useState('Contains');
  const [pageStatus, setPageStatus] = useState("1");
  const [
    resourceTypeState,
    setResourceTypeState,
    handleResourceTypeState,
    clearResourceTypeState,
  ] = useObjState({
    id: "",
    typeCode: "",
    description: "",
    law: false,
    fire: false,
    ems: false,
    other: false,
    isActive: true
  });

  const data = [
    { resourceTypeCode: 'RT001', description: 'Fire Truck', agencyType: 'Fire Department', },
    { resourceTypeCode: 'RT002', description: 'Ambulance', agencyType: 'Health Department', },
    { resourceTypeCode: 'RT003', description: 'Fire Ambulance', agencyType: 'Fire Department', },
    { resourceTypeCode: 'RT004', description: 'Fire Ambulance', agencyType: 'Fire Department', },
    { resourceTypeCode: 'RT005', description: 'Fire Ambulance', agencyType: 'Fire Department', },
    { resourceTypeCode: 'RT006', description: 'Fire Ambulance', agencyType: 'Fire Department', },
  ];


  const [toggleStates, setToggleStates] = useState(
    data.map(() => true) // Initialize all as toggled on
  );


  const handleToggle = (index) => {
    setToggleStates(prevStates =>
      prevStates.map((state, i) => (i === index ? !state : state))
    );
  };

  const columns = [
    {
      name: 'Resource Type Code',
      selector: row => row.ResourceTypeCode,
      sortable: true,
    },
    {
      name: 'Description',
      selector: row => row.description,
      sortable: true,
      width: "60%",
    },
    {
      name: 'Agency Type',
      selector: row => {
        let result = [];
        if (row.law === 1) result.push("L");
        if (row.fire === 1) result.push("F");
        if (row.emergencymedicalservice === 1) result.push("E");
        if (row.Other === 1) result.push("O");
        return result.join(", ") || "";
      },
      sortable: true,
    },
    {
      name:
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {'Status'}
        </div>,
      cell: (row, index) =>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <span
            onClick={() => handleToggle(index)}
            className="btn btn-sm text-white px-1 py-0 mr-1"
            data-toggle="modal" data-target="#ConfirmModal"
            style={{ background: "#ddd", cursor: "pointer" }}
          >
            {row?.isActive ? (
              <i className="fa fa-toggle-on" style={{ color: "green" }} aria-hidden="true"></i>
            ) : (
              <i className="fa fa-toggle-off" style={{ color: "red" }} aria-hidden="true"></i>
            )}
          </span>
        </div>,
      width: "70px",
    },

  ];

  const GetResourceTypeKey = `/MasterResourceType/GetResourceType`;
  const { data: resourceTypeData, isSuccess: isFetchResourceType, refetch } = useQuery(
    [GetResourceTypeKey, { Action: "GET", ResourceTypeID: "" },],
    MasterTableListServices.getResourceType,
    {
      refetchOnWindowFocus: false,
    }
  );

  function handelSetEditData(data) {
    setResourceTypeState({
      id: data?.ResourceTypeID || "",
      typeCode: data?.ResourceTypeCode || "",
      description: data?.description || "",
      law: data?.law === 1 ? true : false,
      fire: data?.fire === 1 ? true : false,
      ems: data?.emergencymedicalservice === 1 ? true : false,
      other: data?.Other === 1 ? true : false,
      isActive: data?.isActive
    })
  }

  async function handleSave() {
    const isUpdate = resourceTypeState?.id ? true : false;
    const data = {
      Action: isUpdate ? "UPDATE" : "ADD",
      ResourceTypeID: resourceTypeState?.id || undefined,
      ResourceTypeCode: resourceTypeState?.typeCode,
      description: resourceTypeState?.description,
      law: resourceTypeState?.law ? 1 : 0,
      fire: resourceTypeState?.fire ? 1 : 0,
      emergencymedicalservice: resourceTypeState?.ems ? 1 : 0,
      Other: resourceTypeState?.other ? 1 : 0,
      isActive: resourceTypeState?.isActive ? 1 : 0
    };

    try {
      const response = await MasterTableListServices.insertResourceType(data);
      console.log("response", response);

      if (response?.status === 200) {
        toastifySuccess(isUpdate ? "Data Updated Successfully" : "Data Saved Successfully");
        clearResourceTypeState();
        refetch();
      }
    } catch (error) {
      console.error("Error saving resource type:", error);
      // Optionally handle errors with toast notifications or similar
    }
  }


  return (
    <>
      <div className='utilities-tab-content-main-container'>
        <div className='utilities-tab-content-form-container'>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 incident-tab ">
              <ul className="nav nav-tabs mb-1 pl-2 " id="myTab" role="tablist">
                <span className={`nav-item ${pageStatus === '1' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("1"); }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '1' ? 'Red' : '' }}>Active</span>
                <span className={`nav-item ${pageStatus === '0' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("0"); }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '0' ? 'Red' : '' }}>InActive</span>
              </ul>
            </div>
            {
              pageStatus === '1' ?
                <>
                  <div className='utilities-tab-content-form-main'>
                    {/* line 1 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Resource Type Code
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <input
                          type="text"
                          className="form-control py-1 new-input"
                          placeholder='Placeholder'
                          value={resourceTypeState.typeCode}
                          onChange={(e) => { handleResourceTypeState("typeCode", e.target.value) }}
                        />
                      </div>
                      <div className="col-1 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Description
                        </label>
                      </div>
                      <div className="col-4 d-flex align-self-center justify-content-end">
                        <input
                          type="text"
                          className="form-control py-1 new-input"
                          placeholder='Placeholder'
                          value={resourceTypeState.description}
                          onChange={(e) => { handleResourceTypeState("description", e.target.value) }}
                        />
                      </div>
                    </div>

                    {/* line 2 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-start justify-content-end">
                        <label for="" className="tab-form-label" style={{ marginTop: "10px" }}>
                          Required Agency Types
                        </label>
                      </div>
                      <div className="col-4 d-flex align-self-center justify-content-end">
                        <div className='agency-types-checkbox-container'>
                          {/* L : Law */}
                          <div className="agency-checkbox-item">
                            <input type="checkbox" checked={resourceTypeState.law} onChange={(e) => { handleResourceTypeState("law", e.target.checked) }} />
                            <div className="agency-checkbox-text-container">
                              <span>L</span>
                              <span>|</span>
                              <span>Law</span>
                            </div>
                          </div>
                          {/* F : Fire */}
                          <div className="agency-checkbox-item">
                            <input type="checkbox" checked={resourceTypeState.fire} onChange={(e) => { handleResourceTypeState("fire", e.target.checked) }} />
                            <div className="agency-checkbox-text-container">
                              <span>F</span>
                              <span>|</span>
                              <span>Fire</span>
                            </div>
                          </div>
                          {/* E : Emergency Medical Service */}
                          <div className="agency-checkbox-item">
                            <input type="checkbox" checked={resourceTypeState.ems} onChange={(e) => { handleResourceTypeState("ems", e.target.checked) }} />
                            <div className="agency-checkbox-text-container">
                              <span>E</span>
                              <span>|</span>
                              <span>Emergency Medical Service </span>
                            </div>
                          </div>
                          {/* O : Law */}
                          <div className="agency-checkbox-item">
                            <input type="checkbox" checked={resourceTypeState.other} onChange={(e) => { handleResourceTypeState("other", e.target.checked) }} />
                            <div className="agency-checkbox-text-container">
                              <span>O</span>
                              <span>|</span>
                              <span>other</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                :
                <>
                </>
            }
          </div>

        </div>

        <div className='utilities-tab-content-table-container'>
          <div className="row">
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control py-1 new-input"
                placeholder='Search By Code...'
              />
            </div>
            <div className="col-1 d-flex align-self-center justify-content-end" style={{ zIndex: '2', }}>
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeIdOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control py-1 new-input"
                placeholder='Search By Description...'
              />
            </div>
            <div className="col-1 d-flex align-self-center justify-content-end" style={{ zIndex: '1', }}>
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeIdOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {isFetchResourceType &&
            <div className="table-responsive">
              <DataTable
                dense
                columns={columns}
                data={resourceTypeData?.data?.data?.Table}
                customStyles={tableCustomStyles}
                pagination
                responsive
                striped
                highlightOnHover
                fixedHeader
                onRowClicked={(row) => {
                  handelSetEditData(row);
                }}
              // fixedHeaderScrollHeight="200px" 
              />
            </div>}

          <div className="utilities-tab-content-button-container" >
            <button type="button" className="btn btn-sm btn-success" onClick={() => { clearResourceTypeState() }}>Cancel</button>
            <button type="button" className="btn btn-sm btn-success" onClick={() => { handleSave() }}>{!!resourceTypeState?.id ? "Update" : "Save"}</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResourceTypeSection;


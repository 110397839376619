import React, { memo, useState } from "react";
import Select from "react-select";

const OnOffDutyModal = (props) => {
  const { openOnOffDutyModal, setOnOffDutyModal } = props;
  const [selectedDuty, setSelectedDuty] = useState("on");

  const handleDutyClick = (duty) => {
    setSelectedDuty(duty);
  };

  const getButtonStyles = (duty) => {
    return {
      backgroundColor: selectedDuty === duty ? "#001F3F" : "transparent",
      color: selectedDuty === duty ? "white" : "black",
      cursor: "pointer",
      padding: "6px 0",
      transition: "background-color 0.3s ease, color 0.3s ease",
    };
  };

  const customStylesWithOutColor = {
    control: (base) => ({
      ...base,
      height: 20,
      minHeight: 35,
      fontSize: 14,
      marginTop: 2,
      boxShadow: 0,
    }),
  };

  return (
    <>
      {openOnOffDutyModal ? (
        <>
          <dialog
            className="modal fade"
            style={{ background: "rgba(0,0,0, 0.5)", zIndex: "200" }}
            id="OnOffDutyModal"
            tabIndex="-1"
            aria-hidden="true"
            data-backdrop="false"
          >
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content modal-content-cad">
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12 p-0 pb-2">
                      <div className="py-0 px-2 d-flex justify-content-between align-items-center">
                        <p
                          className="p-0 m-0 font-weight-medium"
                          style={{
                            fontSize: 18,
                            fontWeight: 500,
                            letterSpacing: 0.5,
                          }}
                        >
                          {'On/Off Duty'}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Form Section */}
                  <div className="m-1">
                    <fieldset style={{ border: "1px solid gray" }}>
                      {/* Line 1 */}
                      <div className="col-12 d-flex justify-content-center border-bottom">
                        <div
                          className="col-6 text-center border-right"
                          style={getButtonStyles("on")}
                          onClick={() => handleDutyClick("on")}
                        >
                          On Duty
                        </div>
                        <div
                          className="col-6 text-center"
                          style={getButtonStyles("off")}
                          onClick={() => handleDutyClick("off")}
                        >
                          Off Duty
                        </div>
                      </div>
                      {/* Line 2 */}
                      <div className="col-12 d-flex justify-content-center align-items-center my-4" style={{ gap: "80px" }}>
                        <div className="form-check ">
                          <input className="form-check-input" style={{ marginTop: "6px" }} type="radio" value="Attempted" name="AttemptComplete" id="flexRadioDefault1" />
                          <label className="form-check-label " htmlFor="flexRadioDefault1" >
                            Fire
                          </label>
                        </div>
                        <div className="form-check ">
                          <input className="form-check-input" style={{ marginTop: "6px" }} type="radio" value="Attempted" name="AttemptComplete" id="flexRadioDefault1" />
                          <label className="form-check-label " htmlFor="flexRadioDefault1" >
                            Police
                          </label>
                        </div>
                        <div className="form-check ">
                          <input className="form-check-input" style={{ marginTop: "6px" }} type="radio" value="Attempted" name="AttemptComplete" id="flexRadioDefault1" />
                          <label className="form-check-label " htmlFor="flexRadioDefault1" >
                            EMS
                          </label>
                        </div>
                        <div className="form-check ">
                          <input className="form-check-input" style={{ marginTop: "6px" }} type="radio" value="Attempted" name="AttemptComplete" id="flexRadioDefault1" />
                          <label className="form-check-label " htmlFor="flexRadioDefault1" >
                            Other
                          </label>
                        </div>
                      </div>
                      <div className="col-12 d-flex justify-content-center align-items-center my-1" style={{ gap: "15px" }}>
                        <div className="tab-form-container">
                          <div className="d-flex align-items-center" style={{ gap: "6px" }}>
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Personnel 1 ID
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Personnel 2 ID
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Shift
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-75"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Zone
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Resource Type
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-75"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Resource #
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-flex justify-content-center align-items-center my-1" style={{ gap: "15px" }}>
                        <div className="tab-form-container">
                          <div className="d-flex align-items-center" style={{ gap: "6px" }}>
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Personnel 1 ID
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Personnel 2 ID
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Shift
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-75"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Zone
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Resource Type
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-75"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Resource #
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-flex justify-content-center align-items-center my-1" style={{ gap: "15px" }}>
                        <div className="tab-form-container">
                          <div className="d-flex align-items-center" style={{ gap: "6px" }}>
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Personnel 1 ID
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Personnel 2 ID
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Shift
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-75"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Zone
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Resource Type
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-75"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Resource #
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-flex justify-content-center align-items-center my-1" style={{ gap: "15px" }}>
                        <div className="tab-form-container">
                          <div className="d-flex align-items-center" style={{ gap: "6px" }}>
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Personnel 1 ID
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Personnel 2 ID
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Shift
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-75"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Zone
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Resource Type
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-75"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Resource #
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-flex justify-content-center align-items-center my-1" style={{ gap: "15px" }}>
                        <div className="tab-form-container">
                          <div className="d-flex align-items-center" style={{ gap: "6px" }}>
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Personnel 1 ID
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Personnel 2 ID
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Shift
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-75"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Zone
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Resource Type
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-75"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Resource #
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-flex justify-content-center align-items-center my-1" style={{ gap: "15px" }}>
                        <div className="tab-form-container">
                          <div className="d-flex align-items-center" style={{ gap: "6px" }}>
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Personnel 1 ID
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Personnel 2 ID
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Shift
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-75"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Zone
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Resource Type
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-75"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Resource #
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-flex justify-content-center align-items-center my-1" style={{ gap: "15px" }}>
                        <div className="tab-form-container">
                          <div className="d-flex align-items-center" style={{ gap: "6px" }}>
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Personnel 1 ID
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Personnel 2 ID
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Shift
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-75"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Zone
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Resource Type
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-75"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Resource #
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-flex justify-content-center align-items-center my-1" style={{ gap: "15px" }}>
                        <div className="tab-form-container">
                          <div className="d-flex align-items-center" style={{ gap: "6px" }}>
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Personnel 1 ID
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Personnel 2 ID
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Shift
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-75"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Zone
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Resource Type
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-75"
                              name="CFSLDetails" />
                            <label htmlFor="" className="new-label text-nowrap mb-0">
                              Resource #
                            </label>
                            <Select
                              isClearable
                              placeholder="Select..."
                              styles={customStylesWithOutColor}
                              className="w-100"
                              name="CFSLDetails" />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  {/* Buttons Section */}
                  <div className="row">
                    <div className="col-12 p-0">
                      <div className="py-0 px-2 d-flex justify-content-end align-items-center">
                        <div className="d-flex justify-content-end tab-form-row-gap mt-1">
                          <button
                            type="button"
                            className="save-button ml-2"
                          >
                            {'Save'}
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="cancel-button"
                            onClick={() => setOnOffDutyModal(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </dialog >
        </>
      ) : (
        <> </>
      )
      }
    </>
  );
};

export default memo(OnOffDutyModal);

import React, { useContext,useEffect,useState,useRef} from 'react';
import { Link } from 'react-router-dom'
import Select from "react-select";
import { AddDeleteUpadate, fetchPostData } from '../../../../../../hooks/Api'
import { AgencyContext } from '../../../../../../../Context/Agency/Index'
import { RequiredFieldIncident } from '../../../../../Utility/Personnel/Validation'
import { Comman_changeArrayFormat } from '../../../../../../Common/ChangeArrayFormat'
import { toastifySuccess } from '../../../../../../Common/AlertMsg'
import { getShowingDateText } from '../../../../../../Common/Utility'
import DataTable from 'react-data-table-component'
import Loader from '../../../../../../Common/Loader'
import DeletePopUpModal from '../../../../../../Common/DeleteModal'
import { components } from "react-select";

const Property = ({ loginPinID, nameID, victimID, incidentID }) => {

  const { get_NameVictim_Count } = useContext(AgencyContext)
  const SelectedValue = useRef();
  //screen permission 
  const [propertyDrp, setPropertyDrp] = useState();
  const [propertyData, setPropertyData] = useState();
  const [victimPropertyID, setVictimPropertyID] = useState();
  const [deleteStatus, setDeleteStatus] = useState(false)
  const [loder, setLoder] = useState(false)
  const [filterData, setFilterData] = useState()
  const [typeOfSecurityEditVal, setTypeOfSecurityEditVal] = useState();
  const [officerDrpData, setOfficerDrpData] = useState();

  const [value, setValue] = useState({
    'PropertyID': '',
    'labal': '',
    'NameID': nameID,
    'VictimID': victimID,
    'CreatedByUserFK': loginPinID,
  })

  const [multiSelected, setMultiSelected] = useState({
    PropertyID: null,
  })


  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const [errors, setErrors] = useState({
    'DropError': '',
  })

  const Reset = () => {
    setValue({
      ...value,
      'PropertyID': '',
    })
    setErrors({
      ...errors,
      'DropError': '',
    });
  }

  console.log(victimID ,'victim')

  const check_Validation_Error = (e) => {
    if (RequiredFieldIncident(value.PropertyID)) {
      setErrors(prevValues => { return { ...prevValues, ['DropError']: RequiredFieldIncident(value.PropertyID) } })
    }
  }

  useEffect(() => {
    if (typeOfSecurityEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['PropertyID']: typeOfSecurityEditVal } }) }
  }, [typeOfSecurityEditVal])

  // Check All Field Format is True Then Submit 
  const { DropError } = errors

  // useEffect(() => {
  //   if (DropError === 'true') {
  //     Add_Offender();
  //   }
  // }, [DropError])

  console.log(multiSelected ,'multiselected')


  useEffect(() => {
   
    get_Property_Data(victimID);
    get_Property_DropDown(incidentID, victimID);
  }, [incidentID, victimID])

  const get_Property_Data = (victimID) => {
    const val = {
      'VictimID': victimID,
    }
    fetchPostData('VictimProperty/GetData_VictimProperty', val).then((res) => {
      if (res) {
        console.log(res ,'response')
        setTypeOfSecurityEditVal(Comman_changeArrayFormat(res, 'VictimPropertyID', 'NameID', 'PretendToBeID', 'PropertyID', 'PropertyID1')); setLoder(true)
      } else {
        setTypeOfSecurityEditVal([]); setLoder(true)
      }
    })
  }

  const get_Property_DropDown = (incidentID, victimID) => {
    const val = {
      'IncidentID': incidentID,
      'VictimID': victimID,
    }
    fetchPostData('VictimProperty/GetData_InsertVictimProperty', val).then((data) => {
      if (data) {
        console.log(data)
        setFilterData(data)
        setPropertyDrp(Comman_changeArrayFormat(data, 'PropertyID', 'PropertyNumber', 'PropertyTypeID'))
      } else {
        setPropertyDrp([])
      }
    })
  }

  // const Add_Offender = () => {
  //   AddDeleteUpadate('VictimProperty/Insert_VictimProperty', value).then((res) => {
  //     if (res) {
  //       toastifySuccess(res.Message);
  //       get_NameVictim_Count(victimID)
  //       get_Property_DropDown(incidentID, victimID);
  //       get_Property_Data(victimID);
  //       Reset();
  //       onClear(); setErrors({ ...value['DropError'] })
  //     }
  //   })
  // }

  // const DeleteOffender = () => {
  //   const val = {
  //     'VictimPropertyID': victimPropertyID,
  //     'DeletedByUserFK': LoginPinID
  //   }
  //   AddDeleteUpadate('VictimProperty/Delete_VictimProperty', val).then((res) => {
  //     toastifySuccess(res.Message);
  //     get_NameVictim_Count(victimID)
  //     setDeleteStatus(false)
  //     get_Property_DropDown(incidentID, victimID);
  //     get_Property_Data(victimID);
  //   })
  // }

  const onClear = () => {
    SelectedValue?.current?.clearValue();
    setValue(pre => { return { ...pre, ['PropertyID']: '', ['victimPropertyID']: '', ['labal']: '' } });
  };

 

  // const columns = [
  //   {
  //     name: 'Property Number',
  //     selector: (row) => row.PropertyNumber,
  //     sortable: true
  //   },
  //   {
  //     name: 'Type',
  //     selector: (row) => row.PropertyType_Description,
  //     sortable: true
  //   },
  //   {
  //     name: 'Property Reason',
  //     selector: (row) => row.PropertyLossCode_Description,
  //     sortable: true
  //   },
  //   {
  //     name: 'Reported Date/Time',
  //     selector: (row) => row.ReportedDtTm ? getShowingDateText(row.ReportedDtTm) : " ",
  //     sortable: true
  //   },
  //   {
  //     name: 'Property Value',
  //     selector: (row) => row.Value,
  //     sortable: true
  //   },
  //   {
  //     name: <p className='text-end' style={{ position: 'absolute', top: 8, left: '15px' }}>Delete</p>,
  //     cell: row => 
       
  //       <Link to={`#`} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" onClick={() => { setDeleteStatus(true); setVictimPropertyID(row.victimPropertyID) }} data-toggle="modal" data-target="#DeleteModal">
  //             <i className="fa fa-trash"></i>
  //           </Link>
        
      
  //   }
  // ]

  // const tableCustomStyles = {
  //   rows: {
  //     style: {
  //       border: '3px solid aliceblue',
  //     },
  //   },
  //   headCells: {
  //     style: {
  //       fontWeight: 'bold',
  //       Color: '#000',
  //       backgroundColor: 'aliceblue',
  //     },
  //   },
  // };


  // const columns1 = [
  //   {
  //     name: 'Property Number',
  //     selector: (row) => row.PropertyNumber,
  //     sortable: true
  //   },
  //   {
  //     name: 'Type',
  //     selector: (row) => row.PropertyType_Description,
  //     sortable: true
  //   },
  //   {
  //     name: 'Reported Date/Time',
  //     selector: (row) => row.ReportedDtTm ? getShowingDateText(row.ReportedDtTm) : " ",
  //     sortable: true
  //   },
  //   {
  //     name: 'Property Value',
  //     selector: (row) => row.Value,
  //     sortable: true
  //   },

  // ]

  const notebookEntryHandler = row => {
    setValue(pre => { return { ...pre, ['PropertyID']: row.PropertyID, ['victimPropertyID']: row.PropertyID, ['labal']: row.PropertyNumber } });
    document.getElementById('customSelectBox').style.display = 'none'
  }

  const Officer = (multiSelected) => {
    setMultiSelected({
      ...multiSelected,
      PropertyID: multiSelected
    })
    const len = multiSelected.length - 1
    if (multiSelected?.length < typeOfSecurityEditVal?.length) {
      let missing = null;
      let i = typeOfSecurityEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(typeOfSecurityEditVal[--i])) ? missing : typeOfSecurityEditVal[i];
      }
      DelSertBasicInfo(missing.value, 'VictimPropertyID', 'VictimProperty/Delete_VictimProperty')
    } else {
      InSertBasicInfo(multiSelected[len].value, 'PropertyID', 'VictimProperty/Insert_VictimProperty')
    }
  }

  const InSertBasicInfo = (id, col1, url) => {
    const val = {
      'NameID': nameID,
    'VictimID': victimID,
      [col1]: id,
      'CreatedByUserFK': loginPinID,
    }
    AddDeleteUpadate(url, val).then((res) => {
      if (res) {
        console.log(res)
        toastifySuccess(res.Message);
        get_Property_DropDown(incidentID, victimID);
        // get_IncidentTab_Count(incidentID);
        col1 === 'PropertyID' &&  get_Property_Data(victimID);;
      } else {
        console.log("Somthing Wrong");
      }
    })
  }

  const DelSertBasicInfo = (victimOfficerID, col1, url) => {
    const val = {
      [col1]: victimOfficerID,
      'DeletedByUserFK': loginPinID,
    }
    AddDeleteUpadate(url, val).then((res) => {
      if (res) {
        toastifySuccess(res.Message);
        get_Property_DropDown(incidentID, victimID);
        // get_IncidentTab_Count(incidentID);
        col1 === 'VictimPropertyID' &&  get_Property_Data(victimID)
      } else {
        console.log("res");
      }
    })
  }

  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      minHeight: 60,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };

  return (
    <>
      <div className="col-12 col-md-12 pt-2 p-0" >
        <div className="bg-line  py-1 px-2 d-flex justify-content-between align-items-center">
          <p className="p-0 m-0">Property</p>
        </div>
      </div>
     
      <div className="col-6 col-md-6 col-lg-4 mt-2" style={{ zIndex: '1', }} >
      <Select
               options={propertyDrp}
              isClearable
             closeMenuOnSelect={false}
              placeholder="Select.."
              ref={SelectedValue}
              className="basic-multi-select"
              isMulti
              styles={customStylesWithOutColor}
              components={{ MultiValue, }}
              onChange={(e) => Officer(e)}
              value={multiSelected.PropertyID}
              name='PropertyID'
             
          
            />
        {/* <div className="text-field ">
          <input
            type="text"
            name='NoofHoles'
            id='NoofHoles'
            value={value.labal}
            required
            className='requiredColor'
            placeholder='Search By Property .....'
            autoComplete='off'
            onChange={(e) => {
              setValue({ ...value, labal: e.target.value })
              const result = propertyDrp?.filter((item) => {
                return (item.label.toLowerCase().includes(e.target.value.toLowerCase()))
              })
              setFilterData(result)
            }
            }
            onClick={() => {
              document.getElementById('customSelectBox').style.display = 'block'
            }}
          />
          {errors.DropError !== 'true' ? (
            <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.DropError}</span>
          ) : null}
          <Link to={''} className='offense-select' onClick={() => {
            document.getElementById('customSelectBox').style.display = 'none';
            setValue(pre => { return { ...pre, ['PropertyID']: '', ['victimPropertyID']: '', ['labal']: '' } });
          }}>
            <span className='select-cancel'>
              <i className='fa fa-times'></i>
            </span>
          </Link>
        </div> */}
        {/* <div id='customSelectBox' className="col-12 col-md-12 col-lg-12 " style={{ display: 'none', width: '700px', }}>
          <DataTable
            dense
            fixedHeader
            customStyles={tableCustomStyles}
            columns={columns1}
            data={filterData}
            onRowClicked={notebookEntryHandler}
            selectableRowsHighlight
            highlightOnHover
            className='new-table'
          />
        </div> */}
      </div>
      {/* <div className="col-2 col-md-6 col-lg-8    pl-3" style={{ marginTop: '22px' }}>
        <Link to=''>
          <button type="button" className="btn btn-md py-1 btn-success pl-2  text-center" onClick={() => { check_Validation_Error(); }} >Save</button>
        </Link>
      </div> */}
      {/* <div className="col-12">
        {
          loder ?
            <DataTable
              columns={columns}
              data={ propertyData}
              dense
              pagination
              selectableRowsHighlight
              highlightOnHover
            />
            :
            <Loader />
        }
      </div> */}
      {/* {
        deleteStatus ?
          <DeletePopUpModal func={DeleteOffender} />

          : ''
      } */}
    </>
  )
}

export default Property
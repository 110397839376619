import React, { memo } from "react";
import Select from "react-select";
import ClassNames from "classnames";

const FreeModal = (props) => {
    const { openFreeModal, setOpenFreeModal } = props;
    const onCloseLocation = () => {
        setOpenFreeModal(false);
    };


    const customStylesWithOutColor = {
        control: (base) => ({
            ...base,
            height: 20,
            minHeight: 35,
            fontSize: 14,
            marginTop: 2,
            boxShadow: 0,
        }),
    };

    const handleKeyDown = (e) => {
        const charCode = e.keyCode || e.which;
        const charStr = String.fromCharCode(charCode);
        const controlKeys = [8, 9, 13, 27, 37, 38, 39, 40, 46];
        const numpadKeys = [96, 97, 98, 99, 100, 101, 102, 103, 104, 105];
        const numpadSpecialKeys = [106, 107, 109, 110, 111];
        if (!charStr.match(/^[a-zA-Z]+$/) && !controlKeys.includes(charCode)) {
            e.preventDefault();
        }
        if (
            (charCode >= 48 && charCode <= 57) ||
            numpadKeys.includes(charCode) ||
            numpadSpecialKeys.includes(charCode)
        ) {
            e.preventDefault();
        }
    };

    const handleSpecialKeyDown = (e) => {
        const allowedKeys = /^[a-zA-Z0-9]$/;
        const isBackspace = e.key === "Backspace";

        if (!allowedKeys.test(e.key) && !isBackspace) {
            e.preventDefault();
        }
    };
    const handleNumberKeyDown = (e) => {
        const allowedKeys = /^[0-9]$/; // Only letters and numbers

        if (!allowedKeys.test(e.key)) {
            e.preventDefault();
        }
    };

    const colourStylesEC = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "#fce9bf",
            // height: 20,
            minHeight: 35,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    return (
        <>
            {openFreeModal ? (
                <>
                    <dialog
                        className="modal fade"
                        style={{ background: "rgba(0,0,0, 0.5)", zIndex: "200" }}
                        id="freeModal"
                        tabIndex="-1"
                        aria-hidden="true"
                        data-backdrop="false"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-xl">
                            <div className="modal-content modal-content-cad">
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-12 p-0 pb-2">
                                            <div className="py-0 px-2 d-flex justify-content-between align-items-center">
                                                <p
                                                    className="p-0 m-0 font-weight-medium"
                                                    style={{
                                                        fontSize: 18,
                                                        fontWeight: 500,
                                                        letterSpacing: 0.5,
                                                    }}
                                                >
                                                    Free Resource
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="m-1">
                                        <fieldset style={{ border: "1px solid gray" }}>

                                            <div className="tab-form-container">
                                                {/* Line 1 */}
                                                <div className="tab-form-row">
                                                    <div className="col-2 d-flex justify-content-end">
                                                        <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Incident #</label>
                                                    </div>
                                                    <div className="col-2">
                                                        <input name="location" type="text"
                                                            className="form-control py-1 new-input" />
                                                    </div>
                                                    <div className="col-1">{"or"}</div>
                                                </div>

                                                {/* Line 4 */}
                                                <div className="tab-form-row">
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <label htmlFor="" className="new-label">
                                                            Resource #
                                                        </label>
                                                    </div>
                                                    <div className="col-10 d-flex align-items-center" style={{ gap: "15px" }}>
                                                        <Select
                                                            isClearable
                                                            placeholder="Select..."
                                                            styles={customStylesWithOutColor}
                                                            className="w-100"
                                                            name="CFSLDetails" />
                                                        <Select
                                                            isClearable
                                                            placeholder="Select..."
                                                            styles={customStylesWithOutColor}
                                                            className="w-100"
                                                            name="CFSLDetails" />
                                                        <Select
                                                            isClearable
                                                            placeholder="Select..."
                                                            styles={customStylesWithOutColor}
                                                            className="w-100"
                                                            name="CFSLDetails" />
                                                        <Select
                                                            isClearable
                                                            placeholder="Select..."
                                                            styles={customStylesWithOutColor}
                                                            className="w-100"
                                                            name="CFSLDetails" />
                                                    </div>
                                                </div>
                                                <div className="tab-form-row">
                                                    <div className="col-2 d-flex justify-content-end">
                                                        <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Comments</label>
                                                    </div>
                                                    <div className="col-10">
                                                        <textarea name="comments" rows="5"
                                                            className="form-control py-1 new-input" />
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    {/* Buttons */}
                                    <div className="row">
                                        <div className="col-12 p-0">
                                            <div className="py-0 px-2 d-flex justify-content-end align-items-center">
                                                <div className="d-flex justify-content-end tab-form-row-gap mt-1">
                                                    <button
                                                        type="button"
                                                        className="save-button ml-2"
                                                        onClick={onCloseLocation}
                                                    >
                                                        {"Save"}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        data-dismiss="modal"
                                                        className="cancel-button"
                                                        onClick={onCloseLocation}
                                                    >
                                                        Cancel
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </dialog >
                </>
            ) : (
                <> </>
            )
            }
        </>
    );
};

export default memo(FreeModal);

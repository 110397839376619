import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { AddDeleteUpadate, fetchPostData } from '../../../../../../hooks/Api';
import { toastifyError, toastifySuccess } from '../../../../../../Common/AlertMsg';
import DeletePopUpModal from '../../../../../../Common/DeleteModal';
import { AgencyContext } from '../../../../../../../Context/Agency/Index';
import Select from "react-select";
import { RequiredFieldIncident } from '../../../../../Utility/Personnel/Validation';
import { Comman_changeArrayFormat } from '../../../../../../Common/ChangeArrayFormat';
import { tableCustomStyles } from '../../../../../../Common/Utility';
import { useDispatch, useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../../../../redux/api';
import { Decrypt_Id_Name } from '../../../../../../Common/Utility';



const Relationship = (props) => {


  const {DecNameID, DecMasterNameID,victimID, DecIncID} = props

  const dispatch = useDispatch();
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';




  const { get_NameVictim_Count, } = useContext(AgencyContext);
  const [clickedRow, setClickedRow] = useState(null);

 
  const [relationshipData, setRelationshipData] = useState([]);
  const [status, setStatus] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [RelationshipID, setRelationshipID] = useState('');
  const [editCount, setEditCount] = useState(0);
  const [relationShipDrp, setRelationShipDrp] = useState([]);
  const [name, setName] = useState([]);
  const [singleData, setSingleData] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(0);
  const [selected, setSelected] = useState(false)
  const [loginPinID, setLoginPinID,] = useState('');
  const [loginAgencyID, setLoginAgencyID] = useState('');
  const [nameID, setNameID] = useState('');
  const [incidentID ,setIncidentID] = useState();

 
    useEffect(() => {
    if (localStoreData) {
      setLoginAgencyID(localStoreData?.AgencyID); setLoginPinID(localStoreData?.PINID);
    }
    get_NameVictim_Count(victimID)
   
  }, [localStoreData]);

  useEffect(() => {
    if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
      if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
    }
  }, []);

 


  useEffect(() => {
    if(DecNameID ){
        setNameID(DecNameID);
        setIncidentID(DecIncID);
        setValue({ ...value, 'CreatedByUserFK': loginPinID, })
    }
   
  }, [DecNameID,loginPinID,]);

console.log(loginPinID ,'loginpin');


  const [value, setValue] = useState({
    'Code': 'VIC',
    'IncidentID': DecIncID,
    'VictimID': victimID,
    'NameID': DecNameID,
    'RelationshipTypeID': '',
    'VictimNameID': '',
    'OffenderNameID': '',
    'ModifiedByUserFK': '',
    'RelationshipID': '',
   
  });

  const [errors, setErrors] = useState({
    'RelationshipTypeIDErrors': '', ' VictimNameIDErrors': '',
  })

  const check_Validation_Error = (e) => {
    if (RequiredFieldIncident(value.RelationshipTypeID)) {
      setErrors(prevValues => { return { ...prevValues, ['RelationshipTypeIDErrors']: RequiredFieldIncident(value.RelationshipTypeID) } })
    }
    if (RequiredFieldIncident(value.VictimNameID)) {
      setErrors(prevValues => { return { ...prevValues, ['VictimNameIDErrors']: RequiredFieldIncident(value.VictimNameID) } })
    }
  }





  // Check All Field Format is True Then Submit 
  const { RelationshipTypeIDErrors, VictimNameIDErrors } = errors

  useEffect(() => {
  
     
      if (RelationshipTypeIDErrors === 'true' && VictimNameIDErrors === 'true') {
        if (status && RelationshipID) update_Relationship()
        else save_Relationship()
      }
    // }
    // else{
    //   if (RelationshipTypeIDErrors === 'true' && VictimNameIDErrors === 'true') {
    //     if (status) update_Relationship()
    //     else save_Relationship()
    //   }
    // }
   
  }, [RelationshipTypeIDErrors, VictimNameIDErrors])

  useEffect(() => {
    console.log('hello')
    console.log(nameID ,'nameid')

    get_Data_RelationShip_Drp(loginAgencyID); get_Data_Name_Drp(incidentID, nameID);
    Get_Relationship_Data(nameID);
   
  }, [nameID,incidentID,loginAgencyID])

  

  useEffect(() => { if (RelationshipID && status) get_Single_Data(RelationshipID) }, [RelationshipID, status])

  useEffect(() => {
    if (singleData?.RelationshipID && RelationshipID) {
      setValue(pre => {
        return {
          ...pre,
          RelationshipTypeID: singleData?.RelationshipTypeID,
          VictimNameID: singleData?.VictimNameID,
          OffenderNameID: singleData?.OffenderNameID,
          ModifiedByUserFK: loginPinID,
          RelationshipID: singleData?.RelationshipID,
        }
      })
    } else {
      resetHooks()
    }
  }, [singleData, editCount])

  const get_Data_RelationShip_Drp = (loginAgencyID) => {
    const val = {
      'AgencyID': loginAgencyID,
    }
    fetchPostData('VictimRelationshipType/GetDataDropDown_VictimRelationshipType', val).then((data) => {
      if (data) {
        setRelationShipDrp(Comman_changeArrayFormat(data, 'VictimRelationshipTypeID', 'Description'))
      } else {
        setRelationShipDrp([])
      }
    })
  }

  const get_Data_Name_Drp = (incidentID, nameID) => {
    console.log(nameID,'nameme')
    const val = {
      'IncidentID': incidentID,
      'NameID': nameID,
    }
    fetchPostData('NameRelationship/GetDataDropDown_OffenderName', val).then((data) => {
      if (data) {
        setName(Comman_changeArrayFormat(data, 'NameID', 'Name'))
      } else {
        setName([])
      }
    })
  }
  
 

  const get_Single_Data = (RelationshipID) => {
    const val = {
      'RelationshipID': RelationshipID,
    }
    fetchPostData('NameRelationship/GetSingleData_NameRelationship', val).then((data) => {
      if (data) {
        setSingleData(data[0])
      } else {
        setSingleData([])
      }
    })
  }

  const ChangeDropDown = (e, name) => {
    if (e) {
      setValue({
        ...value,
        [name]: e.value
      })
    } else {
      setValue({
        ...value,
        [name]: null
      })
    }
  }

  const save_Relationship = () => {
    const result = relationshipData?.find(item => {
      if (item.VictimNameID === value.VictimNameID) {
        return item.VictimNameID === value.VictimNameID
      } else return item.VictimNameID === value.VictimNameID
    });
    if (result) {
      toastifyError('Already Exists')
      setErrors({
        ...errors,
        ['RelationshipTypeIDErrors']: '',
      })
    } else {
      AddDeleteUpadate('NameRelationship/Insert_NameRelationship', value).then((data) => {
        if (data.success) {
          toastifySuccess(data.Message); Get_Relationship_Data(nameID); setModalStatus(false); setStatus(false); resetHooks(); get_NameVictim_Count(victimID)
        } else {
          toastifyError(data.Message)
        }
      })
    }
  }

  const update_Relationship = () => {
    AddDeleteUpadate('NameRelationship/Update_NameRelationship', value).then((data) => {
      if (data.success) {
        toastifySuccess(data.Message); Get_Relationship_Data(nameID); setModalStatus(false); setStatus(true); 
      } else {
        toastifyError(data.Message)
      }
    })
  }

  const resetHooks = () => {
    setValue({
      ...value,
      RelationshipTypeID: '',
      VictimNameID: '',
      OffenderNameID: '',
      ModifiedByUserFK: '',
      RelationshipID: '',
    })
    setErrors({
      ...errors,
      'RelationshipTypeIDErrors': '', ' VictimNameIDErrors': '',
    });
  }

  const Get_Relationship_Data = (nameID) => {
    const val = {
      'Code': 'VIC',
      'NameID': nameID,
    }
    fetchPostData('NameRelationship/GetData_NameRelationship', val).then((res) => {
      if (res) {
        console.log(res ,'data');
        setRelationshipData(res)
      } else {
        setRelationshipData([]);
      }
    })
  }

  const columns = [
    {
      name: <p className='text-end' style={{ position: 'absolute', top: '7px' }}>Action</p>,
      cell: row =>
        <div style={{ position: 'absolute', top: 4, left: 20 }}>
          <Link to={'/nametab'} onClick={(e) => {
            setModalStatus(true); setStatus(true); setRelationshipID(row.relationshipID); setEditCount(editCount + 1)
          }
          } className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#RelationshipModal">
            <i className="fa fa-edit"></i>
          </Link>

        </div>

    },
    {
      name: 'Name',
      selector: (row) => row.Name,
      sortable: true
    },
    {
      name: 'Realtionship',
      selector: (row) => row.RelationshipType_Description,
      sortable: true
    },
    {
      name: <p className='text-end' style={{ position: 'absolute', top: '7px' }}>Delete</p>,
      cell: row =>
        <div style={{ position: 'absolute', top: 4, left: 25 }}>

          <span  onClick={(e) => {setRelationshipID(row.RelationshipID);}} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
            <i className="fa fa-trash"></i>
          </span>
        </div>

    }
  ];

  const DeleteRelationship = () => {
    const val = {
      'RelationshipID': RelationshipID,
      'DeletedByUserFK': loginPinID,
    }
    AddDeleteUpadate('NameRelationship/Delete_NameRelationship', val).then((res) => {
      if (res) {
        toastifySuccess(res.Message); setEditCount(editCount + 1)
        Get_Relationship_Data(nameID);
        get_NameVictim_Count(victimID)
       
      } else { toastifyError("Somthing Wrong"); }
    })
  }
  const colourStyles = {
    control: (styles) => ({
      ...styles, backgroundColor: "#fce9bf",
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  }
  const conditionalRowStyles = [
    {
      when: row => row === clickedRow,
      style: {
        backgroundColor: '#001f3fbd',
        color: 'white',
        cursor: 'pointer',
      },
    },
  ];

  const set_Edit_Value = (row) => {

    console.log('hello')
    setStatus(true);
   // setUpdateStatus(updateStatus + 1);
    setRelationshipID(row.RelationshipID);
  }

 


  const setStatusFalse = (e) => {
    setClickedRow(null); resetHooks();
    setStatus(false)
    setSelected(false);

    setUpdateStatus(updateStatus + 1);

  }
  return (
    <>
      <div className="col-12 col-md-12 mt-2" >
        {/* <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center ">
          <p className="p-0 m-0 d-flex align-items-center">Relationship</p>
        </div> */}
        <div className="row">
          <div className="col-2 col-md-2 col-lg-2 mt-3">
            <label htmlFor="" className='label-name '>Offender{errors.VictimNameIDErrors !== 'true' ? (
              <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.VictimNameIDErrors}</p>
            ) : null}</label>
          </div>
          <div className="col-4 col-md-4 col-lg-4  mt-2" >
            <Select
              name='VictimNameID'
              styles={colourStyles}
              isClearable
              value={name?.filter((obj) => obj.value === value.VictimNameID)}
              options={name}
              onChange={(e) => { ChangeDropDown(e, 'VictimNameID'); }}
              placeholder="Select.."
            />
          </div>
          <div className="col-2 col-md-2 col-lg-2 mt-3">
            <Link to={'/ListManagement?page=Relationship%20Type&call=/Name-Home'} className='new-link'>
              Relationship {errors.RelationshipTypeIDErrors !== 'true' ? (
                <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.RelationshipTypeIDErrors}</p>
              ) : null}
            </Link>
          </div>
          <div className="col-4 col-md-4 col-lg-4  mt-2" >
            <Select
              name='RelationshipTypeID'
              styles={colourStyles}
              isClearable
              value={relationShipDrp?.filter((obj) => obj.value === value.RelationshipTypeID)}
              options={relationShipDrp}
              onChange={(e) => { ChangeDropDown(e, 'RelationshipTypeID'); }}
              placeholder="Select.."
            />
          </div>
          <div className="btn-box text-right col-12 col-md-12 col-lg-12 mt-2 mr-1 mb-2">
            {
              status ?
                <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }}>Update</button>
                :
                <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }}>Save</button>
            }
            <button type="button" data-dismiss="modal" className="btn btn-sm btn-success mr-1" onClick={() => { setStatusFalse(); conditionalRowStyles(''); setUpdateStatus(updateStatus + 1); }}>New</button>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-12  mb-3">
            <div className="table-responsive">
              <DataTable
                dense
                columns={columns}
                data={relationshipData}
                highlightOnHover
                customStyles={tableCustomStyles}
                onRowClicked={(row) => {
                  setClickedRow(row);
                  set_Edit_Value(row);
                }}
                fixedHeader
                persistTableHead
                fixedHeaderScrollHeight='330px'
                conditionalRowStyles={conditionalRowStyles}
              />
            </div>
          </div>
        </div>
      </div>
      <DeletePopUpModal func={DeleteRelationship} />
    </>
  )
}

export default Relationship
import React, { useEffect, useState, memo, useContext, useRef } from 'react'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import Select from "react-select";
import SelectBox from '../../../../../../Common/SelectBox';
import { AddDeleteUpadate, fetchPostData, } from '../../../../../../hooks/Api';
import { toastifySuccess } from '../../../../../../Common/AlertMsg';
import { RequiredFieldIncident } from '../../../../../Utility/Personnel/Validation';
import { ErrorStyle, ErrorTooltip, NameVictimOffenses } from '../../../../../../NIBRSError';
import { AgencyContext } from '../../../../../../../Context/Agency/Index';
import { components } from "react-select";
import { offenseArray, threeColVictimOffenseArray } from '../../../../../../Common/ChangeArrayFormat';
import { useDispatch, useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../../../../redux/api';
import { Decrypt_Id_Name } from '../../../../../../Common/Utility';

const Offense = (props) => {


  const {DecNameID, DecMasterNameID, DecIncID,victimID} = props

  const dispatch = useDispatch();
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';


  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const { get_NameVictim_Count, } = useContext(AgencyContext);
  const SelectedValue = useRef();
  const [offenseDrp, setOffenseDrp] = useState();
  const [offenseNameData, setOffenseNameData] = useState([]);
  const [offenseNameID, setOffenseNameID] = useState();
  const [deleteStatus, setDeleteStatus] = useState(false)
  const [OffenseID, setOffenseID] = useState()
  const [typeOfSecurityEditVal, setTypeOfSecurityEditVal] = useState();
  const [loginPinID, setLoginPinID,] = useState('');
  const [loginAgencyID, setLoginAgencyID] = useState('');
  const [nameID, setNameID] = useState('');
  const [incidentID ,setIncidentID] = useState();

  const [value, setValue] = useState({
    'OffenseID': '',
    'NameID': DecNameID,
    'VictimID': victimID,
    'CreatedByUserFK': loginPinID,
    'VictimOffenseID': '',
  })
  const [multiSelected, setMultiSelected] = useState({
    OffenseID: null,
  })
  useEffect(() => {
    setValue(pre => { return { ...pre, 'CreatedByUserFK': loginPinID, 'NameID': nameID, 'VictemTypeCode': null, } });
  }, [loginPinID])

  const [errors, setErrors] = useState({
    'DropError': '',
  })

  const check_Validation_Error = (e) => {
    if (RequiredFieldIncident(value.OffenseID)) {
      setErrors(prevValues => { return { ...prevValues, ['DropError']: RequiredFieldIncident(value.OffenseID) } })
    }
  }


  // Check All Field Format is True Then Submit 
  const { DropError } = errors

  // useEffect(() => {
  //   if (DropError === 'true') {
  //     Add_Victim_Offense();
  //   }
  // }, [DropError])

  useEffect(() => {
    if (localStoreData) {
      setLoginAgencyID(localStoreData?.AgencyID); setLoginPinID(localStoreData?.PINID);
    }
  }, [localStoreData]);

  useEffect(() => {
    if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
      if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
    }
  }, []);

  useEffect(() => {
    if(DecNameID ){
        setNameID(DecNameID);
        setIncidentID(DecIncID);

    }
   
  }, [DecNameID,loginPinID,]);



  useEffect(() => {
    if (victimID) { get_OffenseName_Data(); }
  }, [victimID])

  useEffect(() => {
    if (victimID) {
      get_Offense_DropDown(incidentID, victimID);
    }
  }, [incidentID, victimID])

  const get_OffenseName_Data = () => {
    const val = {
      'VictimID': victimID,
    }
    fetchPostData('VictimOffense/GetData_VictimOffense', val).then((res) => {
      if (res) {
        console.log(res, 'responses');
        setTypeOfSecurityEditVal(offenseArray(res, 'VictimOffenseID', 'OffenseID', 'NameID', 'VictimID', 'Offense_Description', 'PretendToBeID'));
        // setOffenseNameData(res)
        get_NameVictim_Count(victimID)
      } else {
        setTypeOfSecurityEditVal([]);

        // setOffenseNameData([]);
      }
    })
  }

  useEffect(() => {
    if (typeOfSecurityEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['OffenseID']: typeOfSecurityEditVal } }) }
  }, [typeOfSecurityEditVal])

  console.log(victimID ,'victimme')

  const get_Offense_DropDown = (incidentID, victimID) => {
    const val = {
      // 'AgencyID': loginAgencyID,
      'IncidentID': incidentID,
      'VictimID': victimID
    }
    fetchPostData('VictimOffense/GetData_InsertVictimOffense', val).then((data) => {
      if (data) {
        console.log(data, 'data');
        setOffenseID(data.CrimeID, 'crimess');
        setOffenseDrp(threeColVictimOffenseArray(data, 'CrimeID', 'Offense_Description'))
      } else {
        setOffenseDrp([])
      }
    })
  }


  // const Add_Victim_Offense = () => {
  //   AddDeleteUpadate('VictimOffense/Insert_VictimOffense', value).then((res) => {
  //     if (res) {
  //       toastifySuccess(res.Message);
  //       get_Offense_DropDown(victimID);

  //       get_OffenseName_Data(victimID);
  //       get_NameVictim_Count(victimID)
  //       onClear(); setErrors({ ...value['DropError'] })
  //     }
  //   })
  // }

  // const DeleteVictimOffense = () => {
  //   const val = {
  //     'VictimOffenseID': offenseNameID,
  //     'DeletedByUserFK': loginPinID
  //   }
  //   AddDeleteUpadate('VictimOffense/Delete_VictimOffense', val).then((res) => {
  //     toastifySuccess(res.Message); setDeleteStatus(false)
  //     get_Offense_DropDown(victimID);
  //     get_OffenseName_Data(victimID);
  //     get_NameVictim_Count(victimID)
  //   })
  // }

  const ChangeDropDown = (e, name) => {
    if (e) {
      setValue({
        ...value,
        [name]: e.value
      })
    } else setValue({
      ...value,
      [name]: null
    })
  }

  const onClear = () => {
    SelectedValue?.current?.clearValue();
  };


  console.log(offenseDrp, 'offensedrop')
  const columns = [
    {
      name: 'Description',
      selector: (row) => row.Offense_Description,
      sortable: true
    },
    {
      name: <p className='text-end' style={{ position: 'absolute', top: 8, left: '15px' }}>Delete</p>,
      cell: row =>
        <div style={{ position: 'absolute', top: 4, left: 20 }}>
          <Link to={`#`} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" onClick={() => { setDeleteStatus(true); setOffenseNameID(row.VictimOffenseID) }} data-toggle="modal" data-target="#DeleteModal">
            <i className="fa fa-trash"></i>
          </Link>

        </div>

    }
  ]

  const offense = (multiSelected) => {


    console.log(multiSelected, 'multislected ')
    setMultiSelected({
      ...multiSelected,
      OffenseID: multiSelected
    })
    const len = multiSelected.length - 1
    if (multiSelected?.length < typeOfSecurityEditVal?.length) {
      let missing = null;
      let i = typeOfSecurityEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(typeOfSecurityEditVal[--i])) ? missing : typeOfSecurityEditVal[i];
      }

      console.log(missing, 'missing')
      DelSertBasicInfo(missing.value, 'VictimOffenseID', 'VictimOffense/Delete_VictimOffense')
    } else {
      InSertBasicInfo(multiSelected[len].value, 'OffenseID', 'VictimOffense/Insert_VictimOffense')
    }
  }

  // console.log(multiSelected[len].value ,'offenseid')

  const InSertBasicInfo = (id, col1, url) => {

    console.log(col1, 'col1');
    const val = {
      'NameID': nameID,
      'VictimID': victimID,

      [col1]: id,
      'CreatedByUserFK': loginPinID,
    }
    AddDeleteUpadate(url, val).then((res) => {
      if (res) {
        console.log(res)
        toastifySuccess(res.Message);
        get_Offense_DropDown(incidentID, victimID);
        // get_IncidentTab_Count(incidentID);
        col1 === 'OffenseID' && get_OffenseName_Data();
      } else {
        console.log("Somthing Wrong");
      }
    })
  }

  const DelSertBasicInfo = (VictimOffenseID, col1, url) => {

    console.log(offenseNameID, 'offensedelete')
    const val = {
      [col1]: VictimOffenseID,
      'DeletedByUserFK': loginPinID,
    }
    AddDeleteUpadate(url, val).then((res) => {
      if (res) {
        toastifySuccess(res.Message);
        get_Offense_DropDown(incidentID, victimID);
        // get_IncidentTab_Count(incidentID);
        col1 === 'VictimOffenseID' && get_OffenseName_Data()
      } else {
        console.log("res");
      }
    })
  }
  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      minHeight: 70,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };

  console.log(offenseDrp, 'offense')
  return (
    <>
      <div className="col-12 " id='display-not-form'>
        <div className="row mt-2">
          <div className="col-2 col-md-2 col-lg-1 mt-4">
            <label htmlFor="" className='label-name '>Offense {offenseNameData.length > 0 && value?.OffenseID ? ErrorTooltip(NameVictimOffenses) : <></>}
              {errors.DropError !== 'true' ? (
                <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.DropError}</p>
              ) : null}</label>
          </div>
          <div className="col-8 col-md-8 col-lg-10  mt-2" >
            <SelectBox
              name='OffenseID'
              // styles={(offenseNameData.length > 0 && value?.OffenseID) ? ErrorStyle(true) : ErrorStyle(false)}
              isClearable
              options={offenseDrp}
              closeMenuOnSelect={false}
              // onChange={(e) => { ChangeDropDown(e, 'OffenseID'); }}
              placeholder="Select.."
              components={{ MultiValue, }}
              onChange={(e) => offense(e)}
              value={multiSelected.OffenseID}
              ref={SelectedValue}
              className="basic-multi-select"
              isMulti
              styles={customStylesWithOutColor}

            />
          </div>
          {/* <div className="col-6 col-md-6 col-lg-7  mt-2 pt-1 pl-3">
            <Link to=''>
              <button type="button" className="btn btn-md py-1 btn-success pl-2  text-center" onClick={() => { check_Validation_Error(); }} >Save</button>
            </Link>
          </div> */}
        </div>
      </div>
      {/* <div className="col-12 mt-4">
        <DataTable
          columns={columns}
          data={offenseNameData}
          dense
          pagination
          paginationPerPage={'3'}
          paginationRowsPerPageOptions={[3]}
          selectableRowsHighlight
          highlightOnHover
          customStyles={tableCustomStyles}
          fixedHeader
          fixedHeaderScrollHeight='200px'
        />
      </div> */}
      {/* {
        deleteStatus ?
          <DeletePopUpModal func={DeleteVictimOffense} />
          : ''
      } */}
    </>
  )
}

export default memo(Offense)
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Decrypt_Id_Name, getShowingMonthDateYear } from '../../../Common/Utility';
import DatePicker from "react-datepicker";
import Select from "react-select";
import { fetchPostData } from '../../../hooks/Api';
import { Comman_changeArrayFormat, Comman_changeArrayFormat_With_Name, threeColArray } from '../../../Common/ChangeArrayFormat';
import { toastifyError } from '../../../Common/AlertMsg';
import { Comparision } from '../../PersonnelCom/Validation/PersonnelValidation';
import { AgencyContext } from '../../../../Context/Agency/Index';
import { useDispatch } from 'react-redux';
import { get_LocalStoreData } from '../../../../redux/actions/Agency';
import { useSelector } from 'react-redux';
import { get_Eye_Color_Drp_Data, get_Hair_Color_Drp_Data } from '../../../../redux/actions/DropDownsData';
const os = require('os');

const NameSearchPage = () => {

    const [nameTypeCode, setNameTypeCode] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setnameSearch, setChangesStatus } = useContext(AgencyContext);

    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const eyeColorDrpData = useSelector((state) => state.DropDown.eyeColorDrpData);
    const hairColorDrpData = useSelector((state) => state.DropDown.hairColorDrpData);
    const nameTypeData = useSelector((state) => state.Agency.nameTypeData);

    const [nameTypeIdDrp, setNameTypeIdDrp] = useState([]);
    const [suffixIdDrp, setSuffixIdDrp] = useState([]);
    const [sexIdDrp, setSexIdDrp] = useState([]);
    const [raceIdDrp, setRaceIdDrp] = useState([]);
    const [ethinicityDrpData, setEthinicityDrpData] = useState([]);

    // const [eyeColoIDDrp, setEyeColoIDDrp] = useState([]);
    // const [hairColorIDDrp, setHairColorIDDrp] = useState([]);

    const [smtLocation, setSmtLocation] = useState([]);
    const [smtType, setSmtType] = useState([]);
    const [reasonIdDrp, setReasonIdDrp] = useState([]);
    const [loginAgencyID, setLoginAgencyID] = useState('');
    const [masterNameID, setMasterNameID] = useState();
    const [nameID, setNameID] = useState();
    const [businessTypeDrp, setBusinessTypeDrp] = useState([]);
    const [phoneTypeIdDrp, setPhoneTypeIdDrp] = useState([]);


    const [value, setValue] = useState({
        NameIDNumber: '', NameTypeID: '', NameReasonCodeID: '', LastName: '', MiddleName: '', FirstName: '',
        SuffixID: '', DateOfBirthFrom: '', DateOfBirthTo: '', SexID: '', RaceID: '', EthnicityID: '', HairColorID: '',
        EyeColorID: '', WeightFrom: '', WeightTo: '', SMTTypeID: '', SMTLocationID: '', SSN: '', SMT_Description: '',
        IncidentNumber: '', IncidentNumberTo: '', ReportedDate: '', ReportedDateTo: '', DateOfBirth: '',
        HeightFrom: '', HeightTo: '', AgencyID: loginAgencyID, DLNumber: '', BusinessTypeID: "", PhoneTypeID: '', Contact: '', FaxNumber: '',
    });

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID);
        }
    }, [localStoreData]);

    useEffect(() => {
        if (loginAgencyID) {
            if (hairColorDrpData?.length === 0) dispatch(get_Hair_Color_Drp_Data(loginAgencyID))
            if (eyeColorDrpData?.length === 0) dispatch(get_Eye_Color_Drp_Data(loginAgencyID))

            GetNameTypeIdDrp(loginAgencyID); GetSuffixIDDrp(loginAgencyID); GetSexIDDrp(loginAgencyID); GetRaceIdDrp(loginAgencyID); getEthinicityDrp(loginAgencyID); get_SMTTypeID(loginAgencyID);
            get_Name_Drp_Data(loginAgencyID)

            // GetColoIDDrp(loginAgencyID);
        }
    }, [loginAgencyID]);

    useEffect(() => {
        GetReasonIdDrp(loginAgencyID, value.NameTypeID);
    }, [value.NameTypeID])

    const GetReasonIdDrp = (loginAgencyID, id) => {
        const val = { AgencyID: loginAgencyID, CategoryID: id, }
        fetchPostData('NameReasonCode/GetDataDropDown_NameReasonCode', val).then((data) => {
            if (data) {
                setReasonIdDrp(Comman_changeArrayFormat(data, 'NameReasonCodeID', 'Description'))
            } else {
                setReasonIdDrp([]);
            }
        })
    }

    const get_SMTLocationID = (LoginAgencyID, id) => {
        fetchPostData('SMTLocations/GetDataDropDown_SMTLocations', { AgencyID: LoginAgencyID, SMTTypeID: id }).then((data) => {
            if (data) {
                setSmtLocation(Comman_changeArrayFormat(data, 'SMTLocationID', 'Description'))
            } else {
                setSmtLocation([]);
            }
        })
    }

    const onChangeReasonCode = (e, name) => {
        if (e) {
            if (name === 'NameReasonCodeID') {
                const ids = []
                e.forEach(({ value }) => ids.push(value))

                setValue({ ...value, [name]: JSON.stringify(ids) })
            } else {
                setValue({ ...value, [name]: e.value, })
            }
        } else {
            if (name === 'NameReasonCodeID') {
                setValue({ ...value, [name]: null, });
                return;
            } else {
                setValue({ ...value, [name]: null, });
            }

        }
    }

    const GetNameTypeIdDrp = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID
        }
        fetchPostData('NameType/GetDataDropDown_NameType', val).then((data) => {
            if (data) {
                const id = data?.filter((val) => { if (val.NameTypeCode === "I") return val })
                if (id.length > 0) {
                    setValue(prevValues => { return { ...prevValues, ['NameTypeID']: id[0].NameTypeID } })
                }
                setNameTypeIdDrp(threeColArray(data, 'NameTypeID', 'Description', 'NameTypeCode'))
            } else {
                setNameTypeIdDrp([]);
            }
        })
    };

    const GetSuffixIDDrp = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID
        }
        fetchPostData('Suffix/GetDataDropDown_Suffix', val).then((data) => {
            if (data) {
                setSuffixIdDrp(Comman_changeArrayFormat(data, 'SuffixID', 'Description'))
            } else {
                setSuffixIdDrp([]);
            }
        })
    };

    const GetSexIDDrp = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID
        }
        fetchPostData('DropDown/GetData_SexType', val).then((data) => {
            if (data) {
                setSexIdDrp(Comman_changeArrayFormat(data, 'SexCodeID', 'Description'))
            } else {
                setSexIdDrp([]);
            }
        })
    }

    const GetRaceIdDrp = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID
        }
        fetchPostData('DropDown/GetData_RaceType', val).then((data) => {
            if (data) {
                setRaceIdDrp(Comman_changeArrayFormat(data, 'RaceTypeID', 'Description'))
            } else {
                setRaceIdDrp([]);
            }
        })
    }

    const getEthinicityDrp = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID
        }
        fetchPostData('/DropDown/GetDataDropDown_Ethnicity', val).then((data) => {
            if (data) {
                setEthinicityDrpData(Comman_changeArrayFormat(data, 'EthnicityID', 'Description'));
            }
            else {
                setEthinicityDrpData([])
            }
        })
    };

    const get_SMTTypeID = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID
        }
        fetchPostData('SMTTypes/GetDataDropDown_SMTTypes', val).then((data) => {
            if (data) {
                setSmtType(Comman_changeArrayFormat(data, 'SMTTypeID', 'Description'))
            } else {
                setSmtType([]);
            }
        })
    }

    const check_Validation_Error = () => {
        if (Comparision(value.WeightFrom, value.WeightTo, 'Weight') === 'true') {
            get_Name_Advance_Search();
        }
    }

    const get_Name_Advance_Search = async () => {
        const {
            NameIDNumber, NameTypeID, NameReasonCodeID, LastName, MiddleName, FirstName, SuffixID, DateOfBirthFrom, DateOfBirthTo, SexID, RaceID, EthnicityID, HairColorID,
            EyeColorID, WeightFrom, WeightTo, SMTTypeID, SMTLocationID, SSN, SMT_Description, IncidentNumber, IncidentNumberTo, ReportedDate, ReportedDateTo, DateOfBirth,
            HeightFrom, HeightTo, AgencyID, DLNumber, BusinessTypeID, PhoneTypeID, Contact, FaxNumber
        } = value
        const val = {
            'NameIDNumber': NameIDNumber, 'NameTypeID': NameTypeID, 'NameReasonCodeID': NameReasonCodeID, 'LastName': LastName, 'MiddleName': MiddleName, 'FirstName': FirstName,
            'SuffixID': SuffixID, 'DateOfBirthFrom': DateOfBirthFrom, 'DateOfBirthTo': DateOfBirthTo, 'SexID': SexID, 'RaceID': RaceID, 'EthnicityID': EthnicityID, 'HairColorID': HairColorID,
            'EyeColorID': EyeColorID, 'WeightFrom': WeightFrom, 'WeightTo': WeightTo, 'SMTTypeID': SMTTypeID, 'SMTLocationID': SMTLocationID, 'SSN': SSN, 'SMT_Description': SMT_Description,
            'IncidentNumber': IncidentNumber, 'IncidentNumberTo': IncidentNumberTo, 'ReportedDate': ReportedDate, 'ReportedDateTo': ReportedDateTo, 'DateOfBirth': DateOfBirth,
            'HeightFrom': HeightFrom, 'HeightTo': HeightTo, 'AgencyID': loginAgencyID, 'DLNumber': DLNumber, BusinessTypeID: BusinessTypeID, PhoneTypeID: PhoneTypeID, Contact: Contact, FaxNumber: FaxNumber
        }
        fetchPostData("MasterName/Search_Name", val).then((res) => {
            if (res.length > 0) {
                setnameSearch(res); Reset();
                navigate('/namesearch?page=Name-Search');
            } else {
                setnameSearch([]); toastifyError("Data Not Available");
            }
        })
    }

    const ChangeDropDown = (e, name) => {
        if (e) {
            if (name === 'SMTTypeID') {
                get_SMTLocationID(loginAgencyID, e.value)
                setValue({
                    ...value,
                    [name]: e.value,
                    ['SMTLocationID']: null,
                })
            } else {
                setValue({
                    ...value,
                    [name]: e.value,
                })
            }
            if (name === 'NameTypeID') {
                setValue({
                    ...value,
                    [name]: e.value,
                    ['SMTLocationID']: null,
                })
                setNameTypeCode(e.id);
                if (e.id === 'B') { GetBusinessTypeDrp(loginAgencyID) }
            }
            if (name === 'PhoneTypeID') {
                setValue({
                    ...value,
                    [name]: e.value,
                    ['SMTLocationID']: null,
                })

            }

        } else {
            if (name === 'SMTTypeID') {
                setValue({
                    ...value,
                    [name]: null,
                    ['SMTLocationID']: null,
                });
                setSmtLocation([]);
                return;
            }
            setValue({
                ...value,
                [name]: null,
            })

        }
    }

    const GetBusinessTypeDrp = (loginAgencyID) => {
        const val = { AgencyID: loginAgencyID, }
        fetchPostData('NameBusinessType/GetDataDropDown_NameBusinessType', val).then((data) => {
            if (data) {
                setBusinessTypeDrp(Comman_changeArrayFormat(data, 'NameBusinessTypeID', 'Description'))
            } else {
                setBusinessTypeDrp([]);
            }
        })
    };

    const get_Name_Drp_Data = (loginAgencyID) => {
        const val = { AgencyID: loginAgencyID, }
        fetchPostData('MasterName/GetNameDropDown', val).then((data) => {
            if (data) {
                setPhoneTypeIdDrp(threeColArray(data[0]?.ContactType, 'ContactPhoneTypeID', 'Description', 'ContactPhoneTypeCode'))
            } else {
                setPhoneTypeIdDrp([]);
            }
        })
    };

    const Reset = () => {
        setValue({
            ...value,
            NameIDNumber: '', NameTypeID: '', NameReasonCodeID: '', LastName: '', MiddleName: '', FirstName: '',
            SuffixID: '', DateOfBirthFrom: '', DateOfBirthTo: '', SexID: '', RaceID: '', EthnicityID: '', HairColorID: '',
            EyeColorID: '', WeightFrom: '', WeightTo: '', SMTTypeID: '', SMTLocationID: '', SSN: '', SMT_Description: '',
            IncidentNumber: '', IncidentNumberTo: '', ReportedDate: '', ReportedDateTo: '', DateOfBirth: '',
            HeightFrom: '', HeightTo: '', AgencyID: loginAgencyID, Contact: '', FaxNumber: ''
        });
    }

    const OnClose = () => {
        Reset(); navigate('/dashboard-page');
    }

    const handlChange = (e) => {
        if (e.target.name === 'SSN') {
            let ele = e.target.value.replace(/\D/g, '');
            if (ele.length === 9) {
                const cleaned = ('' + ele).replace(/\D/g, '');
                const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
                if (match) {
                    setValue({
                        ...value,
                        [e.target.name]: match[1] + '-' + match[2] + '-' + match[3]
                    })
                }
            } else {
                ele = e.target.value.split('-').join('').replace(/\D/g, '');
                setValue({
                    ...value,
                    [e.target.name]: ele
                })
            }
            if (e.target.name === 'SSN') {
                return 'true';
            }
            if (e.target.name.length === 11) {
                return 'true'
            }
        } else if (e.target.name === 'IncidentNumber' || e.target.name === 'IncidentNumberTo') {
            let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
            if (ele.length === 8) {
                const cleaned = ('' + ele).replace(/[^a-zA-Z0-9\s]/g, '');
                const match = cleaned.match(/^(\d{2})(\d{6})$/);
                if (match) {
                    setValue({
                        ...value,
                        [e.target.name]: match[1] + '-' + match[2]
                    })
                }
            } else {
                ele = e.target.value.split("'").join('').replace(/[^0-9\s]/g, '');
                setValue({
                    ...value,
                    [e.target.name]: ele
                })
            }
        } else if (e.target.name === 'HeightFrom') {
            let ele = e.target.value
            if (ele.length === 3) {
                const cleaned = ('' + ele)?.replace(/\D/g, '');
                const match = cleaned?.match(/^(\d{1})(\d{2})$/);
                if (parseInt(match[2]) < 12) {
                    setValue({
                        ...value,
                        [e.target.name]: match[1] + "'" + match[2] + "'"
                    })
                } else {
                    setValue({
                        ...value,
                        [e.target.name]: match[1] + "'" + "11" + "'"
                    })
                }
            } else {
                ele = e.target.value.split("'").join('').replace(/\D/g, '');
                setValue({
                    ...value,
                    [e.target.name]: ele
                })
            }
        } else if (e.target.name === 'Contact') {
            // if (phoneTypeCode === 'E') {
            //     setChangesStatus(true)
            //     setValue({ ...value, [e.target.name]: e.target.value });
            // } else {
            let ele = e.target.value.replace(/\D/g, '');
            if (ele.length === 10) {
                setValue(pre => { return { ...pre, ['IsUnListedPhNo']: 'true', } });
                const cleaned = ('' + ele).replace(/\D/g, '');
                const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
                if (match) {
                    setChangesStatus(true)
                    setValue({
                        ...value,
                        [e.target.name]: match[1] + '-' + match[2] + '-' + match[3]
                    })
                }
            } else {
                ele = e.target.value.split('-').join('').replace(/\D/g, '');
                setChangesStatus(true)
                setValue({
                    ...value,
                    [e.target.name]: ele
                })
            }
            // }
        } else if (e.target.name === 'HeightTo') {
            let ele = e.target.value
            if (ele.length === 3) {
                const cleaned = ('' + ele).replace(/\D/g, '');
                const HeightFromVal = value?.HeightFrom?.split("'")?.join('')?.replace(/\D/g, '');
                const match = cleaned.match(/^(\d{1})(\d{2})$/);
                if (parseInt(HeightFromVal) < parseInt(cleaned)) {
                    if (parseInt(match[2]) < 12) {
                        setValue({
                            ...value,
                            [e.target.name]: match[1] + "'" + match[2] + "'"
                        })
                    } else {
                        setValue({
                            ...value,
                            [e.target.name]: match[1] + "'" + "11" + "'"
                        })
                    }
                }
            } else {
                ele = e.target.value?.split("'").join('').replace(/\D/g, '');
                setValue({
                    ...value,
                    [e.target.name]: ele
                })
            }
        } else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }

    // const onChangeNameIDNum = (e) => {
    //     if (e) {
    //         if (e.target.name === 'NameIDNumber') {
    //             let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
    //             if (ele.length === 10) {
    //                 const cleaned = ('' + ele).replace(/[^a-zA-Z0-9\s]/g, '');
    //                 // const match = cleaned.match(/^(\w{1})(\d{9})$/);
    //                 const match = cleaned.match(/^([AJ]{1})(\d{9})$/);
    //                 // console.log(match)
    //                 if (match) {
    //                     setValue({
    //                         ...value,
    //                         [e.target.name]: match[1] + '-' + match[2]
    //                     })
    //                 }
    //             } else {
    //                 ele = e.target.value.split("'").join('').replace(/[^a-zA-Z0-9\s]/g, '');
    //                 setValue({
    //                     ...value,
    //                     [e.target.name]: ele
    //                 })
    //             }
    //         } else {
    //             setValue({
    //                 ...value,
    //                 [e.target.name]: e.target.value
    //             })
    //         }
    //     } else {
    //         setValue({
    //             ...value,
    //             [e.target.name]: e.target.value
    //         })
    //     }
    // }

    const onChangeNameIDNum = (e) => {
        if (e) {
            if (e.target.name === 'NameIDNumber') {
                let ele = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
                if (ele.length <= 11) {
                    const alphabet = ele[0]?.toUpperCase() || '';
                    const digits = ele.slice(1).replace(/[^0-9]/g, '');
                    // console.log(match)
                    if (digits.length === 9) {
                        setValue({
                            ...value,
                            [e.target.name]: alphabet + '-' + digits
                        });
                    } else {
                        setValue({
                            ...value,
                            [e.target.name]: alphabet + digits
                        });
                    }
                } else {
                    ele = e.target.value.split("'").join('').replace(/[^a-zA-Z0-9\s]/g, '');
                    setValue({
                        ...value,
                        [e.target.name]: ele
                    })
                }
            } else {
                setValue({
                    ...value,
                    [e.target.name]: e.target.value
                })
            }
        } else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }

    const startRef = React.useRef();
    const startRef1 = React.useRef();
    const startRef2 = React.useRef();
    const startRef3 = React.useRef();

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
            startRef2.current.setOpen(false);
            startRef3.current.setOpen(false);
        }
    };

    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 20,
            minHeight: 35,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    // const ChangeNameType = (e, name) => {
    //     if (e) {
    //         if (name === 'NameTypeID') {
    //             setValue({
    //                 ...value,
    //                 [name]: e.value,
    //                 'NameIDNumber': 'Auto Generated', 'BusinessTypeID': '', 'SuffixID': '', 'VerifyID': '', 'SexID': '', 'RaceID': '', 'PhoneTypeID': '', 'NameReasonCodeID': [], 'CertifiedByID': '', 'AgeUnitID': '',
    //                 'IsVerify': true, 'IsUnListedPhNo': '', 'LastName': '', 'FirstName': '', 'MiddleName': '', 'SSN': '', 'WeightFrom': '', 'WeightTo': '', 'HeightFrom': '', 'HeightTo': '', 'Address': '', 'Contact': '', 'DateOfBirth': '', 'CertifiedDtTm': null, 'AgeFrom': '', 'AgeTo': '', 'Years': '', 'DLStateID': '',
    //                 'IsJuvenile': e.id === 'B' ? null : value.IsJuvenile
    //             });
    //             // setMultiSelected({ optionSelected: [] }); setPhoneTypeCode(''); setNameTypeCode(e.id); setChangesStatus(true);
    //             // if (e.id === 'B') { setIsBusinessName(true); GetBusinessTypeDrp(loginAgencyID); get_Arrestee_Drp_Data(mainIncidentID); } else { setIsBusinessName(false); }
    //         } else {
    //             setChangesStatus(true)
    //             setValue({
    //                 ...value,
    //                 [name]: e.value,
    //                 'IsJuvenile': e.id === 'B' ? null : value.IsJuvenile
    //             })

    //         }
    //     } else {
    //         setChangesStatus(true)
    //         setValue({
    //             ...value,
    //             [name]: null
    //         }); setNameTypeCode('');
    //         // setIsBusinessName(false); setPhoneTypeCode('')
    //     }
    // }

    return (
        <div className="section-body pt-1 p-1 bt" >
            <div className="div">
                <div className="dark-row" >
                    <div className="col-12 col-sm-12">
                        <div className="card Agency name-card ">
                            <div className="card-body" >
                                <div className="row " style={{ marginTop: '-10px' }}>
                                    <div className="col-12 ">
                                        <fieldset >
                                            <legend>Name Type</legend>
                                            <div className="row px-1">
                                                <div className="col-2 col-md-2 col-lg-1  mt-2 ">
                                                    <label htmlFor="" className='new-label'>Name Type</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 mt-1 ">
                                                    <Select
                                                        name='NameTypeID'
                                                        value={nameTypeIdDrp?.filter((obj) => obj.value === value?.NameTypeID)}
                                                        options={nameTypeIdDrp}
                                                        onChange={(e) => ChangeDropDown(e, 'NameTypeID')}
                                                        // isClearable
                                                        placeholder="Select..."
                                                        styles={customStylesWithOutColor}
                                                    />
                                                    {/* <Select
                                                        name='NameTypeID'
                                                        value={nameTypeData?.filter((obj) => obj.value === value?.NameTypeID)}
                                                        options={nameTypeData}
                                                        onChange={(e) => ChangeNameType(e, 'NameTypeID')}
                                                        // isClearable
                                                        placeholder="Select..."
                                                        isDisabled={nameID || masterNameID ? true : false}
                                                        styles={customStylesWithOutColor}
                                                    /> */}
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-2  mt-2 ">
                                                    <label htmlFor="" className='new-label'>Reason</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-6 mt-1">
                                                    <Select
                                                        name='NameReasonCodeID'
                                                        // value={reasonIdDrp?.filter((obj) => obj.value === value?.NameReasonCodeID)}
                                                        options={reasonIdDrp}
                                                        onChange={(e) => onChangeReasonCode(e, 'NameReasonCodeID')}
                                                        isClearable
                                                        placeholder="Select..."
                                                        isDisabled={value.NameTypeID ? false : true}
                                                        styles={customStylesWithOutColor}
                                                        defaultValue={[]}
                                                        isMulti
                                                    />
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    {
                                        nameTypeCode === "B" ?
                                            <></> :
                                            <div className="col-12 ">
                                                <fieldset >
                                                    <legend>Name Info </legend>
                                                    <div className="row px-1">
                                                        <div className="col-2 col-md-2 col-lg-1  mt-2 ">
                                                            <label htmlFor="" className='new-label'>MNI</label>
                                                        </div>
                                                        <div className="col-4 col-md-4 col-lg-3 text-field mt-1">
                                                            <input type="text" id='NameIDNumber' maxLength={11} name='NameIDNumber' value={value?.NameIDNumber} onChange={onChangeNameIDNum} />
                                                        </div>

                                                        <div className="col-2 col-md-2 col-lg-1  mt-2 ">
                                                            <label htmlFor="" className='new-label'>Last Name</label>
                                                        </div>
                                                        <div className="col-4 col-md-4 col-lg-3   text-field mt-1">
                                                            <input type="text" id='LastName' name='LastName' value={value?.LastName} onChange={handlChange} />
                                                        </div>
                                                        <div className="col-2 col-md-2 col-lg-1  mt-2 ">
                                                            <label htmlFor="" className='new-label'>First Name</label>
                                                        </div>
                                                        <div className="col-4 col-md-4 col-lg-3  text-field mt-1">
                                                            <input type="text" id='FirstName' name='FirstName' value={value?.FirstName} onChange={handlChange} />
                                                        </div>
                                                        <div className="col-2 col-md-2 col-lg-1  mt-2 px-0">
                                                            <label htmlFor="" className='new-label px-0'>Middle&nbsp;Name</label>
                                                        </div>
                                                        <div className="col-4 col-md-4 col-lg-3  text-field mt-1">
                                                            <input type="text" id='MiddleName' name='MiddleName' value={value?.MiddleName} onChange={handlChange} />
                                                        </div>
                                                        <div className="col-2 col-md-2 col-lg-1  mt-2">
                                                            <label htmlFor="" className='new-label'>Suffix</label>
                                                        </div>
                                                        <div className="col-4 col-md-4 col-lg-3  mt-1 ">
                                                            <Select
                                                                name='SuffixID'
                                                                value={suffixIdDrp?.filter((obj) => obj.value === value?.SuffixID)}
                                                                options={suffixIdDrp}
                                                                onChange={(e) => ChangeDropDown(e, 'SuffixID')}
                                                                isClearable
                                                                placeholder="Select..."
                                                                styles={customStylesWithOutColor}
                                                            />
                                                        </div>
                                                        <div className="col-2 col-md-2 col-lg-1  mt-2 ">
                                                            <label htmlFor="" className='new-label'>DOB From</label>
                                                        </div>
                                                        <div className="col-4 col-md-4 col-lg-3  ">
                                                            <DatePicker
                                                                id='DateOfBirthFrom'
                                                                name='DateOfBirthFrom'
                                                                ref={startRef}
                                                                onKeyDown={onKeyDown}
                                                                onChange={(date) => {
                                                                    if (date) {
                                                                        setValue({
                                                                            ...value,
                                                                            ['DateOfBirthFrom']: date ? getShowingMonthDateYear(date) : null,
                                                                            ['DateOfBirthTo']: getShowingMonthDateYear(new Date()),
                                                                        })
                                                                    } else {
                                                                        setValue({
                                                                            ...value,
                                                                            ['DateOfBirthFrom']: null,
                                                                            ['DateOfBirthTo']: null,
                                                                        })
                                                                    }
                                                                }}
                                                                className=''
                                                                dateFormat="MM/dd/yyyy"
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                isClearable={value?.DateOfBirthFrom ? true : false}
                                                                selected={value?.DateOfBirthFrom && new Date(value?.DateOfBirthFrom)}
                                                                placeholderText={value?.DateOfBirthFrom ? value.DateOfBirthFrom : 'Select...'}
                                                                autoComplete='Off'
                                                                maxDate={new Date()}
                                                            />
                                                        </div>
                                                        <div className="col-2 col-md-2 col-lg-1  mt-2 ">
                                                            <label htmlFor="" className='new-label'>DOB To</label>
                                                        </div>
                                                        <div className="col-4 col-md-4 col-lg-3 ">
                                                            <DatePicker
                                                                id='DateOfBirthTo'
                                                                name='DateOfBirthTo'
                                                                ref={startRef1}
                                                                onKeyDown={onKeyDown}
                                                                onChange={(date) => { setValue({ ...value, ['DateOfBirthTo']: date ? getShowingMonthDateYear(date) : null }) }}
                                                                className=''
                                                                dateFormat="MM/dd/yyyy"
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                isClearable={value?.DateOfBirthTo ? true : false}
                                                                selected={value?.DateOfBirthTo && new Date(value?.DateOfBirthTo)}
                                                                placeholderText={value?.DateOfBirthTo ? value.DateOfBirthTo : 'Select...'}
                                                                autoComplete='Off'
                                                                maxDate={new Date()}
                                                                minDate={value?.DateOfBirthFrom && new Date(value?.DateOfBirthFrom)}
                                                                disabled={value.DateOfBirthFrom ? false : true}
                                                            />
                                                        </div>
                                                        <div className="col-2 col-md-2 col-lg-1  mt-2 ">
                                                            <label htmlFor="" className='new-label'>SSN</label>
                                                        </div>
                                                        <div className="col-4 col-md-4 col-lg-3 mt-1 text-field">
                                                            <input type="text" id='SSN' name='SSN' maxLength={9} value={value?.SSN} onChange={handlChange} />
                                                        </div>
                                                        <div className="col-2 col-md-2 col-lg-1  mt-2 ">
                                                            <label htmlFor="" className='new-label'>DL Number</label>
                                                        </div>
                                                        <div className="col-4 col-md-4 col-lg-3 mt-1 text-field">
                                                            <input type="text" id='DLNumber' name='DLNumber' maxLength={21} value={value?.DLNumber} onChange={handlChange} />
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                    }
                                    {
                                        nameTypeCode === "B" ?
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="row ">
                                                    <div className="col-2 col-md-2 col-lg-1 mt-2 px-0">
                                                        <label htmlFor="" className='label-name'>Business Name</label>
                                                    </div>
                                                    <div className="col-4 col-md-4 col-lg-3   text-field mt-1">
                                                        <input type="text" id='LastName' name='LastName' value={value?.LastName} onChange={handlChange} />
                                                    </div>
                                                    <div className="col-2 col-md-2 col-lg-2 mt-2">
                                                        <label htmlFor="" className='label-name '>Business Type</label>
                                                    </div>
                                                    <div className="col-4 col-md-4 col-lg-6  mt-1">
                                                        <Select
                                                            name='BusinessTypeID'
                                                            value={businessTypeDrp?.filter((obj) => obj.value === value?.BusinessTypeID)}
                                                            options={businessTypeDrp}
                                                            onChange={(e) => ChangeDropDown(e, 'BusinessTypeID')}
                                                            isClearable
                                                            placeholder="Select..."
                                                            styles={customStylesWithOutColor}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-2 col-md-2 col-lg-1 mt-2">
                                                        <label htmlFor="" className='label-name '>Contact Type</label>
                                                    </div>
                                                    <div className="col-4 col-md-4 col-lg-3  mt-1">
                                                        <Select
                                                            name='PhoneTypeID'
                                                            styles={customStylesWithOutColor}
                                                            placeholder="Select..."
                                                            value={phoneTypeIdDrp?.filter((obj) => obj.value === value?.PhoneTypeID)}
                                                            options={phoneTypeIdDrp}
                                                            onChange={(e) => ChangeDropDown(e, 'PhoneTypeID')}
                                                            isClearable

                                                        />
                                                    </div>
                                                    <div className="col-2 col-md-2 col-lg-2 mt-2 ">
                                                        <label htmlFor="" className='label-name '>Contact No.</label>
                                                    </div>
                                                    <div className="col-4 col-md-4 col-lg-2 text-field mt-1">
                                                        <input type="text" name='Contact' className={''} value={value?.Contact} onChange={handlChange} maxLength={10} />
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                <div className="col-12">
                                                    <fieldset >
                                                        <legend>Physical Descriptor</legend>
                                                        <div className="row mt-2">
                                                            <div className="col-2 col-md-2 col-lg-1  mt-2 ">
                                                                <label htmlFor="" className='new-label'>Gender</label>
                                                            </div>
                                                            <div className="col-4 col-md-4 col-lg-3  ">
                                                                <Select
                                                                    name='SexID'
                                                                    value={sexIdDrp?.filter((obj) => obj.value === value?.SexID)}
                                                                    options={sexIdDrp}
                                                                    onChange={(e) => ChangeDropDown(e, 'SexID')}
                                                                    isClearable
                                                                    placeholder="Select..."
                                                                    styles={customStylesWithOutColor}
                                                                />
                                                            </div>
                                                            <div className="col-2 col-md-2 col-lg-1  mt-2 ">
                                                                <label htmlFor="" className='new-label'>Race</label>
                                                            </div>
                                                            <div className="col-4 col-md-4 col-lg-3 ">
                                                                <Select
                                                                    name='RaceID'
                                                                    value={raceIdDrp?.filter((obj) => obj.value === value?.RaceID)}
                                                                    options={raceIdDrp}
                                                                    onChange={(e) => ChangeDropDown(e, 'RaceID')}
                                                                    isClearable
                                                                    placeholder="Select..."
                                                                    styles={customStylesWithOutColor}
                                                                />
                                                            </div>
                                                            <div className="col-2 col-md-2 col-lg-1  mt-2 ">
                                                                <label htmlFor="" className='new-label'>Ethnicity</label>
                                                            </div>
                                                            <div className="col-4 col-md-4 col-lg-3  mt-1">
                                                                <Select
                                                                    name='EthnicityID'
                                                                    value={ethinicityDrpData?.filter((obj) => obj.value === value?.EthnicityID)}
                                                                    options={ethinicityDrpData}
                                                                    onChange={(e) => ChangeDropDown(e, 'EthnicityID')}
                                                                    isClearable
                                                                    placeholder="Select..."
                                                                    styles={customStylesWithOutColor}
                                                                />
                                                            </div>
                                                            <div className="col-2 col-md-2 col-lg-1  mt-2 ">
                                                                <label htmlFor="" className='new-label'>Hair Color</label>
                                                            </div>
                                                            <div className="col-4 col-md-4 col-lg-3 mt-1">
                                                                <Select
                                                                    onChange={(e) => ChangeDropDown(e, 'HairColorID')}
                                                                    name="HairColorID"
                                                                    value={hairColorDrpData?.filter((obj) => obj.value === value?.HairColorID)}
                                                                    options={hairColorDrpData}
                                                                    isClearable
                                                                    placeholder="Select..."
                                                                    styles={customStylesWithOutColor}
                                                                />
                                                            </div>
                                                            <div className="col-2 col-md-2 col-lg-1  mt-2 ">
                                                                <label htmlFor="" className='label-name '>Height
                                                                    <p className='text-center mb-0' style={{ fontWeight: 'bold', fontSize: '10px' }}>(FT)</p>
                                                                </label>
                                                            </div>
                                                            <div className="col-2 col-md-2 col-lg-1 text-field mt-1" >
                                                                <input type="text" name='HeightFrom' maxLength={3} value={value?.HeightFrom} onChange={handlChange} placeholder='From' />
                                                            </div>
                                                            <span className='dash-name mt-1' style={{ marginRight: '-10px' }}>__</span>
                                                            <div className="col-3 col-md-2 col-lg-1 ">
                                                                <div className="text-field px-2 mt-1">
                                                                    <input type="text" name='HeightTo' maxLength={3} value={value?.HeightTo} onChange={handlChange} placeholder='To' />
                                                                </div>
                                                            </div>
                                                            <div className="col-2 col-md-2 col-lg-2  mt-2 ">
                                                                <label htmlFor="" className='label-name '>Weight
                                                                    <p className='text-center mb-0' style={{ fontWeight: 'bold', fontSize: '10px' }}>(FT)</p>
                                                                </label>
                                                            </div>
                                                            <div className="col-2 col-md-2 col-lg-1 text-field mt-1" >
                                                                <input type="text" id='WeightFrom' name='WeightFrom' value={value?.WeightFrom} onChange={handlChange} maxLength={3} placeholder='From' />
                                                            </div>
                                                            <span className='dash-name mt-1' style={{ marginRight: '-10px' }}>__</span>
                                                            <div className="col-3 col-md-1 col-lg-1 ">
                                                                <div className="text-field px-2 mt-1">
                                                                    <input type="text" id='WeightTo' name='WeightTo' min={value?.WeightFrom} value={value?.WeightTo} onChange={handlChange} maxLength={3} placeholder='To' />
                                                                </div>
                                                            </div>
                                                            <div className="col-2 col-md-2 col-lg-1  mt-2 ">
                                                                <label htmlFor="" className='new-label'>Eye Color</label>
                                                            </div>
                                                            <div className="col-4 col-md-10 col-lg-3">
                                                                <Select
                                                                    name="EyeColorID"
                                                                    styles={customStylesWithOutColor}
                                                                    value={eyeColorDrpData?.filter((obj) => obj.value === value?.EyeColorID)}
                                                                    options={eyeColorDrpData}
                                                                    onChange={(e) => ChangeDropDown(e, 'EyeColorID')}
                                                                    isClearable
                                                                    placeholder="Select..."
                                                                    menuPlacement="top"
                                                                />
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                                <div className="col-12">
                                                    <fieldset >
                                                        <legend>SMT</legend>
                                                        <div className="row">
                                                            <div className="col-2 col-md-2 col-lg-1  mt-2 ">
                                                                <label htmlFor="" className='new-label'>SMT Type</label>
                                                            </div>
                                                            <div className="col-4 col-md-4 col-lg-3 mt-1">
                                                                <Select
                                                                    name='SMTTypeID'
                                                                    value={smtType?.filter((obj) => obj.value === value?.SMTTypeID)}
                                                                    isClearable
                                                                    options={smtType}
                                                                    onChange={(e) => ChangeDropDown(e, 'SMTTypeID')}
                                                                    placeholder="Select..."
                                                                    styles={customStylesWithOutColor}
                                                                    menuPlacement='top'
                                                                />
                                                            </div>
                                                            <div className="col-2 col-md-2 col-lg-2  mt-2 ">
                                                                <label htmlFor="" className='new-label'>SMT Location</label>
                                                            </div>
                                                            <div className="col-4 col-md-4 col-lg-6 mt-1">
                                                                <Select
                                                                    name='SMTLocationID'
                                                                    value={smtLocation?.filter((obj) => obj.value === value?.SMTLocationID)}
                                                                    isClearable
                                                                    options={smtLocation}
                                                                    onChange={(e) => ChangeDropDown(e, 'SMTLocationID')}
                                                                    placeholder="Select..."
                                                                    isDisabled={value.SMTTypeID ? false : true}
                                                                    styles={customStylesWithOutColor}
                                                                />
                                                            </div>
                                                            <div className="col-2 col-md-2 col-lg-1  mt-2 ">
                                                                <label htmlFor="" className='new-label'>Description</label>
                                                            </div>
                                                            <div className="col-10 col-md-10 col-lg-11 mt-1 text-field">
                                                                <textarea id='SMT_Description' name='SMT_Description' value={value?.SMT_Description} onChange={handlChange} cols="30" rows="1" required></textarea>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>
                            <div className="btn-box  text-right  mr-1 mb-1" >
                                <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => { get_Name_Advance_Search(); }}>Search</button>
                                <button type="button" data-dismiss="modal" className="btn btn-sm btn-success mr-1 " onClick={() => { OnClose(); }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default NameSearchPage
import axios from "axios";
class CallTakerServices {
  getReceiveSource = async (payload) => {
    if (payload) {
      return await axios.post(
        `/CallTakerReceiveSource/GetData_ReceiveSource`,
        payload
      );
    }
    console.warn("payload not provided, CallTakerServices.getReceiveSource");
    return null;
  };

  getCFS = async () => {
    return await axios.post(`/CallTackerCFS/GetData_CFS`);
  };

  getPriorities = async () => {
    return await axios.post(`/CallTackerPriorieties/GetData_Priorities`);
  };

  getResources = async ({ queryKey }) => {
    const [_key, { payloadGetDataResources }] = queryKey;
    if (payloadGetDataResources) {
      return await axios.post(`/CallTackerResource/GetData_Resources`, payloadGetDataResources);
    }
    console.warn("payload not provided, CallTakerServices.getResources");
    return null;

  };

  getPropertyVehiclePlateType = async ({ queryKey }) => {
    const [_key, { vehiclePlateTypePayload }] = queryKey;
    if (vehiclePlateTypePayload) {
      return await axios.post(`/CallTakerVehiclePlateType/GetData_PropertyVehiclePlateType`, vehiclePlateTypePayload);
    }
    console.warn("payload not provided, CallTakerServices.getPropertyVehiclePlateType");
    return null;
  };

  getNameReasonCode = async ({ queryKey }) => {
    const [_key, { payloadReasonCode }] = queryKey;
    if (payloadReasonCode) {
      return await axios.post(
        `/DispatcherNameReasonCode/GetDataDropDown_NameReasonCode`,
        payloadReasonCode
      );
    }
    console.warn("payload not provided, CallTakerServices.getNameReasonCode");
    return null;
  };

  getTagYear = async () => {
    return await axios.post(`/CallTakerVehiclePlateType/GetData_TagYear`);
  };

  getState = async () => {
    return await axios.post(`/CallTakerState/GetData_State`);
  };

  insertIncident = async (payload) => {
    if (payload) {
      return await axios.post(`/CallTakerIncident/InsertIncident`, payload);
    }
    console.warn("payload not provided, CallTakerServices.insertIncident");
    return null;
  };

  insertName = async (payload) => {
    if (payload) {
      return await axios.post(`/CallTakerMasterName/InsertIncidentNEW`, payload);
    }
    console.warn("payload not provided, CallTakerServices.insertName");
    return null;
  };



  // searchName = async ({ queryKey }) => {
  //   const [_key, { payload123 }] = queryKey;
  //   if (payload123) {
  //     return await axios.post(
  //       `/CallTakerMasterName/Search_MasterName`,
  //       payload123
  //     );
  //   }
  //   console.warn("payload not provided, CallTakerServices.searchName");
  //   return null;
  // };

  searchName = async (payload) => {
    return await axios.post(`/CallTakerMasterName/Search_MasterName`, payload);
  };

  updateName = async (nameCallTaker) => {
    if (nameCallTaker) {
      return await axios.post(`/CallTakerMasterName/Update_MasterName`, nameCallTaker);
    }
    console.warn("nameCallTaker not provided, CallTakerServices.updateName");
    return null;
  };

  insertVehicle = async (payload) => {
    if (payload) {
      return await axios.post(`/CallTackerVehicle/InsertVehicleList`, payload);
    }
    console.warn("payload not provided, CallTakerServices.InsertVehicle");
    return null;
  };

  searchVehicle = async ({ queryKey }) => {
    const [_key, { payload }] = queryKey;
    if (payload) {
      return await axios.post(
        `/CallTackerVehicle/GetData_Vehicle`,
        payload
      );
    }
    console.warn("payload not provided, CallTakerServices.searchVehicle");
    return null;
  };

  updateVehicle = async (payload) => {
    if (payload) {
      return await axios.post(`/CallTackerVehicle/UpdateVehicle`, payload);
    }
    console.warn("payload not provided, CallTakerServices.updateVehicle");
    return null;
  };

  updateIncident = async (payload) => {
    if (payload) {
      return await axios.post(`/CallTakerIncident/UpdateIncident`, payload);
    }
    console.warn("payload not provided, CallTakerServices.updateIncident");
    return null;
  };
}

const instance = new CallTakerServices();

export default instance;

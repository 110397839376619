import React, { useState, useEffect, useContext } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AgencyContext } from "../../Context/Agency/Index";
import { toastifySuccess } from "../Common/AlertMsg";
import ThemeSetting from "./ThemeSetting";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import defualtImage from '../../img/uploadImage.png';
import { Decrypt_Id_Name, currentDate, getShowingDateText } from '../Common/Utility';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../redux/actions/Agency';
import TreeComponent from '../Pages/PropertyRoom/PropertyRoomTab/TreeComponent/TreeComponent';

const Header = (props) => {

  const dispatch = useDispatch();
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';

  const useQuery = () => {
    const params = new URLSearchParams(useLocation().search);
    return {
      get: (param) => params.get(param)
    };
  };

  const query = useQuery();
  var id = query?.get('id');
  var call = query?.get('call');

  const { listManagementSideBar, agencySideBar, personnelSideBar } = props
  const { setUpdateCount, updateCount, incidentStatus, setIncidentStatus, deleteStoreData, setTabCount, setIncidentCount, setAgencyName, agnecyName, setShowPage, setShowPagePersonnel, setInActiveStatus, changesStatus, localStoreArray, get_LocalStorage, setIsLogout, incAdvSearchData, setIncAdvSearchData, setIncidentSearchData } = useContext(AgencyContext)

  // const useQuery = () => new URLSearchParams(useLocation().search);
  // let aId = useQuery().get('id');
  // let goToBack = useQuery().get('call');
  const navigate = useNavigate();

  // Logout User
  const signOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    toastifySuccess('Logout Succesfully !!')
    setIsLogout(true)
    navigate('/')
  }

  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Get the mode preference from localStorage (or default to light mode if not available)
    return sessionStorage.getItem('darkMode') === 'true';
  });

  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => {
      const newMode = !prevMode;
      // Store the new mode preference in localStorage
      sessionStorage.setItem('darkMode', newMode);
      return newMode;
    });
    document.body.classList.toggle('dark-mode');
  };

  // Effect to update body class on initial load
  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [isDarkMode]);

  const [userName, setUserName] = useState('')

  useEffect(() => {
    if (!localStoreData?.Agency_Name) {
      if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
    }
  }, []);

  useEffect(() => {
    if (localStoreData) {
      setAgencyName(localStoreData?.Agency_Name);
      setUserName(localStoreData?.UserName);
    }
  }, [localStoreData]);


  const clickOnIncident = () => {
    setUpdateCount(updateCount + 1); setIncidentStatus(false); setIncAdvSearchData(false); setIncidentSearchData([]);
    setIncidentCount([]); setTabCount([]);
  }

  return (
    <>
      <div id="page_top" className={`${listManagementSideBar ? 'section-body' : personnelSideBar ? 'section-body' : agencySideBar ? 'section-body' : 'section-body top_dark'}`} style={{ padding: `${listManagementSideBar ? '0' : personnelSideBar ? '0' : agencySideBar ? '0' : '20px;'}` }}>
        <div className="container-fluid p-0" style={{ backgroundColor: `${listManagementSideBar ? '#001f3f' : personnelSideBar ? '#001f3f' : agencySideBar ? '#001f3f' : ''}` }} >
          <div className="page-header" >
            <div className="left text-white">
              <i className="mr-2 ml-2"></i>
              {/* <h6 className=" mt-1 pt-1 " >{listManagementSideBar ? 'List Management' : personnelSideBar ? 'Personnel' : agencySideBar ? 'Agency' : ''}</h6> */}
              <div className="div d-flex header-menu">
                <Link to='/dashboard-page' className=" text-white  ml-2">
                  <span className=" ">
                    Dashboard
                  </span>
                </Link>
                <div className="dropdown d-flex">
                  <span className="nav-link icon text-white btn-icon ml-2" data-toggle="dropdown">
                    Application
                    <FontAwesomeIcon icon={faCaretDown} className='pl-1' />
                  </span>
                  <div className="dropdown-menu dropdown-menu-left dropdown-menu-arrow mt-2 pt-1">
                    <Link to={'/Arrest-Home?page=MST-Arrest-Dash'} className="dropdown-item" data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Arrest
                    </Link>
                    <Link className="dropdown-item" to={`/Inc-Home?IncId=${0}&IncNo=${''}&IncSta=${false}`} onClick={clickOnIncident} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Incident
                    </Link>
                    {/* <Link className="dropdown-item" to={'/incident'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Incident
                    </Link> */}
                    <Link to={`/Name-Home?page=MST-Name-Dash&IncNo=${0}&IncSta=${0}&NameID=${0}&MasterNameID=${0}&NameStatus=${false}`} className="dropdown-item" data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Master Name
                    </Link>
                    <Link
                      to={`/Prop-Home?page=MST-Property-Dash&ProId=${0}&MProId=${0}&ProSta=${false}`}
                      // to={`/Prop-Home?page=MST-Property-Dash&IncId=${0}&IncNo=${0}&IncSta=${0}&ProId=${0}&MProId=${0}&ProSta=${false}`} 
                      className="dropdown-item" data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Master Property
                    </Link>
                    <Link to={`/Vehicle-Home?page=MST-Vehicle-Dash&IncId=${0}&IncNo=${0}&IncSta=${0}&?VehId=${0}&?MVehId=${0}`} className="dropdown-item" data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Master Vehicle
                    </Link>
                    <Link to={'/Property-room'} className="dropdown-item" data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Property Room
                    </Link>
                    {/* <Link to={'/field-interview'} className="dropdown-item" data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Field Interview
                    </Link> */}
                    {/* <Link to={'/Citation-Home'} className="dropdown-item" data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Citation
                    </Link> */}
                  </div>
                </div>
                <div className="dropdown d-flex">
                  <span className="nav-link icon text-white btn-icon ml-2" data-toggle="dropdown" >
                    Search
                    <FontAwesomeIcon icon={faCaretDown} className='pl-1' />
                    {/*  <FontAwesomeIcon icon={faCaretDown}  className='pl-1'/> */}
                  </span>
                  <div className="dropdown-menu dropdown-menu-left dropdown-menu-arrow mt-2 pt-1">
                    <Link className="dropdown-item" to={'/incident'} onClick={clickOnIncident} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Incident
                    </Link>
                    {/* <Link className="dropdown-item" to={'/incident-advanceSearch?page=incidentAdvanceSearch'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Incident
                    </Link> */}
                    <Link className="dropdown-item" to={'/name-advanceSearch?page=Name-Search'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Name
                    </Link>
                    <Link className="dropdown-item" to={'/property-advanceSearch?page=Property-Search'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Property
                    </Link>
                    <Link className="dropdown-item" to={'/arrest-advanceSearch?page=Arrest-Search'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Arrest
                    </Link>
                    <Link className="dropdown-item" to={'/vehicle-advanceSearch?page=Vehicle-Search'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Vehicle
                    </Link>
                    {/* <Link className="dropdown-item" to={'#'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Warrant
                    </Link> */}
                  </div>
                </div>
                <div className="dropdown d-flex">
                  <span className="nav-link icon text-white btn-icon ml-2" data-toggle="dropdown">
                    Report
                    <FontAwesomeIcon icon={faCaretDown} className='pl-1' />
                  </span>
                  <div className="dropdown-menu dropdown-menu-left dropdown-menu-arrow mt-2 pt-1">
                    <Link className="dropdown-item" to={'/Reports'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Incident
                    </Link>
                    <Link className="dropdown-item" to={'/Reports'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Name
                    </Link>
                    <Link className="dropdown-item" to={'/Reports'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Property
                    </Link>
                    <Link className="dropdown-item" to={'/Reports'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Arrest
                    </Link>
                    <Link className="dropdown-item" to={'/Reports'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Vehicle
                    </Link>
                    {/* <Link className="dropdown-item" to={'/Reports'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Warrant
                    </Link> */}
                  </div>
                </div>
                <div className="dropdown d-flex">
                  <span className="nav-link icon text-white btn-icon ml-2" data-toggle="dropdown" >
                    Utility
                    <FontAwesomeIcon icon={faCaretDown} className='pl-1' />
                  </span>
                  <div className="dropdown-menu dropdown-menu-left dropdown-menu-arrow mt-2 pt-1">
                    <Link className=" dropdown-item" to={`/agencyTab?Aid=${0}&ASta=${false}`} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Agency
                    </Link>
                    <Link className="dropdown-item" to={'/ListManagement'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      List Manager
                    </Link>
                    <Link className="dropdown-item" to={'/security-manager'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Security Manager
                    </Link>
                    <Link className="dropdown-item" to={'/Editable-Incident'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Editable  Incident
                    </Link>
                    <Link className="dropdown-item" to={'/ListPermission'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      List-Module Manager
                    </Link>
                    <Link className="dropdown-item" to={'/CounterTable'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Counter Table
                    </Link>
                    <Link className="dropdown-item" to={'/PreviousYearCounter'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Pervious Year Counter
                    </Link>
                    <Link className="dropdown-item" to={'#'} data-toggle="modal" data-target="#TreeModal" >
                      Propery Location
                    </Link>
                    <Link className="dropdown-item" to={'/Expunge'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Expunge
                    </Link>
                    <Link className="dropdown-item" to={'/Name-Consolidation'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Consolidation
                    </Link>
                    <Link className="dropdown-item" to={'/Alert-Master'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Alert Master
                    </Link>
                    <Link className="dropdown-item" to={'/ucr-report'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      UCR Report
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* {
              agencySideBar && <span className="text-white">{agnecyName || ''}</span>
            } */}
            <div className="right " >
              <div className="notification d-flex justify-content-between align-items-center px-3" >
                {/* {
                  listManagementSideBar || agencySideBar || personnelSideBar ?
                    <Link to={listManagementSideBar ? goToBack ? goToBack : '/dashboard-page' : agencySideBar ? `/agency` : personnelSideBar ? `/agencyTab?id=U2FsdGVkX1${aId.split(" ", 3)[0].split("/", 1)[0].substring(10,)}/rbn7XDh9W4GiUkZ4MTV1Vx8pMNVkdjyw=` : '/dashboard-page'}>
                      <button style={{ background: 'inherit', border: 'none', outline: 'none', color: '#e63946' }}
                        onClick={() => {
                          agencySideBar ? setShowPage('home') :
                            personnelSideBar ? setShowPagePersonnel('home') : setInActiveStatus(false);
                        }}
                      >
                        <i className='fa fa-close text-white'></i>
                      </button>
                    </Link>
                    :
                    <></>
                } */}
                <div>
                  <button onClick={toggleDarkMode} className='dark-toogle'>
                    <FontAwesomeIcon icon={isDarkMode ? faSun : faMoon} />
                  </button>
                </div>
                {
                  <div className="dropdown d-flex">
                    <span className="nav-link icon  btn  btn-icon ml-2" data-toggle="dropdown" >
                      <span className="text-uppercase text-white">
                        {userName}&nbsp;
                        <FontAwesomeIcon icon={faCaretDown} className='pl-1' />
                      </span>
                    </span>
                    <div className="dropdown-menu  dropdown-menu-right dropdown-menu-arrow" >
                      <div className="header-card">
                        <h6 className="text-center mt-2" style={{ fontSize: '15px' }}>{agnecyName ? agnecyName : ''}</h6>
                        <fieldset >
                          <legend style={{ margin: 'auto' }}>
                            <div className="header-img mt-3 text-center">
                              <img src={defualtImage} alt='' />
                            </div>
                          </legend>
                        </fieldset>
                        <div className="text-start ml-5 mt-2">
                          <h5 className="text-bold " style={{ fontSize: '14px', color: '#001f3f' }}>{userName}</h5>
                          {/* <h6 className="" style={{ fontSize: '12px' }}>Sign In : <span className='pl-1' style={{ fontSize: '14px' }}>{getShowingDateText(new Date())}</span></h6> */}
                        </div>
                        {/* <Link className="dropdown-item bb " to={changesStatus ? "/agency" : "/agency"} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                          <span className="float-right">
                          </span>
                          <i className="fa fa-building-o" aria-hidden="true"></i>&nbsp; Agency
                        </Link> */}
                        <Link className="dropdown-item bb " to={changesStatus ? '#' : "/LockedUser"} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''}>
                          <i className="fa fa-lock"></i> &nbsp; Locked User
                        </Link>
                        <Link className="dropdown-item bb" to={changesStatus ? '#' : "/Dictionary"} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''}>
                          <i className="fa fa-lock"></i> &nbsp; Data Dictionary
                        </Link>
                        <Link className="dropdown-item bb" to={changesStatus ? '#' : "/Eroor-Log"} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''}>
                          <i className="fa fa-lock"></i> &nbsp; Error Log
                        </Link>
                        <Link className="dropdown-item" to={'/'} onClick={() => signOut()}>
                          <i className="fa fa-sign-out"></i>&nbsp; Sign out
                        </Link>
                      </div>
                    </div>

                  </div>
                }
              </div>
            </div>
          </div>
        </div >
      </div >
      <TreeComponent />
      <ThemeSetting />
    </>
  )
}

export default Header;

import React from "react";
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { tableCustomStyles } from '../../../../Components/Common/Utility';
import { customTableStyles } from '../../../Utility/CustomStylesForReact';

const columns = [
  {
    name: 'Comment Date & Time',
    selector: row => row.commentDateTime,
    sortable: true,
    format: row => new Date(row.commentDateTime).toLocaleString(),
  },
  {
    name: 'Operator Name',
    selector: row => row.operatorName,
    sortable: true,
  },
  {
    name: 'Comments',
    selector: row => row.comments,
    sortable: false,
    width: "60%"
  },
  {
    name: 'Resource #',
    selector: row => row.resourceNumber,
    sortable: true,
  },
];


const data = [
  {
    commentDateTime: '2024-07-24T10:30:00Z',
    operatorName: 'John Doe',
    comments: 'This is a detailed comment about the resource.',
    resourceNumber: '12345',
  },
  {
    commentDateTime: '2024-07-24T11:00:00Z',
    operatorName: 'Jane Smith',
    comments: 'Another comment with additional details.',
    resourceNumber: '67890',
  },
  {
    commentDateTime: '2024-07-24T11:30:00Z',
    operatorName: 'Alice Johnson',
    comments: 'Short comment.',
    resourceNumber: '54321',
  },
  {
    commentDateTime: '2024-07-24T11:30:00Z',
    operatorName: 'Alice Johnson Max',
    comments: 'Short comment.',
    resourceNumber: '54321',
  },
  {
    commentDateTime: '2024-07-24T11:30:00Z',
    operatorName: 'Alice Johnson Jhone',
    comments: 'Short comment.',
    resourceNumber: '54321',
  },
];

const CommentsTabFrom = () => {
  return (
    <>
      <div className="tab-form-monitor-container">
        <div className="tab-form-row">
          <div className="col-2 d-flex align-self-center justify-content-end">
            <label for="" className="tab-form-label">
              Incident #
            </label>
          </div>
          <div className="col-2 d-flex align-self-center justify-content-end">
            <input
              type="text"
              className="form-control py-1 new-input highlighter-input-readonly"
              readonly=""
            />
          </div>
          <div className="col-1 d-flex align-self-center justify-content-end">
            <label for="" className="tab-form-label">
              Location
            </label>
          </div>
          <div className="col-6 d-flex align-self-center justify-content-end">
            <input
              type="text"
              className="form-control py-1 new-input"
            />
          </div>
        </div>
        <div className="tab-form-row">
          <div className="col-2 d-flex align-self-center justify-content-end">
            <label for="" className="tab-form-label">
              Comments
            </label>
          </div>
          <div className="col-9 d-flex align-self-center justify-content-end">
            <textarea
              className="form-control py-1 new-input"
               rows="3"
            />
          </div>
        </div>
        <div className="tab-form-row from-button-container" >
          <button className="save-button d-flex align-items-center" style={{ gap: '5px' }}>
            <FontAwesomeIcon icon={faAdd} style={{ color: 'white' }} />
            {"Add Comment"}
          </button>
        </div>
      </div>
      <div className="tab-table-container">
        <DataTable
          dense
          columns={columns}
          data={data}
          customStyles={tableCustomStyles}
          pagination // enable pagination
          responsive // enable responsive design
          striped // add zebra-striping to the table rows
          highlightOnHover // highlight row on hover
          fixedHeader
        // fixedHeaderScrollHeight="200px" 
        />
      </div>
    </>
  );
};

export default CommentsTabFrom;

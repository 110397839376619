import React, { useContext, useState, useEffect } from 'react'
import { Link, useLocation } from "react-router-dom";
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Dropdown from 'react-bootstrap/Dropdown';
import { AddDeleteUpadate, fetchData, fetchPostData, ScreenPermision } from '../../hooks/Api';
import { One_Value_Search_Filter, Three_Search_FilterWith_Date } from '../../Filter/Filter';
import { AgencyContext } from '../../../Context/Agency/Index';
import { Decrypt_Id_Name, DecryptedList, EncryptedList, getShowingDateText, getShowingMonthDateYear, stringToBase64, tableCustomStyles, } from '../../Common/Utility';
import Select from "react-select";
import '../../../style/incident.css';
import { Comman_changeArrayFormat, } from '../../Common/ChangeArrayFormat';
import Loader from '../../Common/Loader';
import { toastifyError, toastifySuccess } from '../../Common/AlertMsg';
import { useDispatch, useSelector } from 'react-redux';
import { Incident_ID, Incident_Number } from '../../../redux/actionTypes';
import { get_LocalStoreData } from '../../../redux/actions/Agency';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faPenToSquare, faPlus } from '@fortawesome/free-solid-svg-icons';


const Incident = () => {

    const dispatch = useDispatch();
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';

    const { setShowIncPage, setTabCount, setIncidentCount, incidentRecentData, setIncidentRecentData, exceptionalClearID, GetDataExceptionalClearanceID, rmsDisposition, getRmsDispositionID, incidentSearchData, setIncidentSearchData, incAdvSearchData, setIncAdvSearchData } = useContext(AgencyContext);

    // Filter Option
    const [filterIncidentIdOption, setFilterIncidentIdOption] = useState('Contains');
    const [filterRmsCfsOption, setFilterRmsCfsOption] = useState('Contains');
    const [filterOccuredToOption, setFilterOccuredToOption] = useState('Contains');
    const [searchValue1, setSearchValue1] = useState('');
    const [searchValue2, setSearchValue2] = useState('');
    const [searchValue3, setSearchValue3] = useState('');
    const [incidentData, setIncidentData] = useState();
    const [incidentFilterData, setIncidentFilterData] = useState();
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [effectiveScreenPermission, setEffectiveScreenPermission] = useState([]);
    const [loder, setLoder] = useState(false);

    //Assign Incident 
    const [officerPinData, setOfficerPinData] = useState();
    const [assignModalStatus, setAssignModalStatus] = useState(false);
    const [assignFilterData, setAssignFilterData] = useState([]);
    const [loginAgencyID, setLoginAgencyID] = useState('');

    //DropDown
    const [headOfAgency, setHeadOfAgency] = useState([]);
    const [reciveSourceID, setReciveSourceID] = useState([]);
    const [rmsCfsID, setRmsCfsID] = useState([]);
    const [typeOfSecurityID, setTypeOfSecurityID] = useState([]);
    const [pinActivityID, setPinActivityID] = useState([]);
    const [userName, setUserName] = useState('');
    const [startDate, setStartDate] = useState(new Date());

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID); getScreenPermision(localStoreData?.AgencyID, localStoreData?.PINID);
            setValue({ ...value, 'AgencyID': localStoreData?.AgencyID, 'CreatedByUserFK': localStoreData?.PINID, });
        }
    }, [localStoreData]);

    const [value, setValue] = useState({
        'ReportedDate': getShowingDateText(new Date()),
        'ReportedDateTo': getShowingDateText(new Date()),
        'IncidentNumber': '', 'IncidentNumberTo': '', 'MasterIncidentNumber': '', 'MasterIncidentNumberTo': '', 'RMSCFSCodeList': '', 'OccurredFrom': '', 'OccurredFromTo': '', 'RMSDispositionId': '',
        'DispositionDate': '', 'DispositionDateTo': '', 'ReceiveSourceID': '', 'NIBRSClearanceID': '', 'IncidentPINActivityID': '', 'IncidentSecurityID': '', 'PINID': '',
        'AgencyID': '',
    });

    useEffect(() => {
        if (loginAgencyID) {
            getIncidentData();
        }
        setShowIncPage('home');
    }, [loginAgencyID, incAdvSearchData])

    const getIncidentData = async () => {
        if (!incAdvSearchData) {
            fetchPostData('Incident/Search_Incident', value).then((res) => {
                if (res) {
                    setIncidentFilterData(res); setAdvancedSearch(false); setLoder(true); setIncidentSearchData([]);
                    reset_Fields();
                } else {
                    setLoder(true); setIncidentFilterData([]); setIncidentSearchData([])
                }
            })
        } else {
            setAdvancedSearch(false); setLoder(true); reset_Fields();
        }
    }

    const getIncidentSearchData = async () => {
        fetchPostData('Incident/Search_Incident', value).then((res) => {
            if (res.length > 0) {
                setIncAdvSearchData(false); setIncidentFilterData(res); setAdvancedSearch(false); setLoder(true);
                reset_Fields();
            } else {
                toastifyError("No Data Available"); setLoder(true); setIncidentFilterData([]);
            }
        });
    }

    const onClick_Reset = () => {
        setValue({ ...value, 'ReportedDate': getShowingDateText(new Date()), 'ReportedDateTo': getShowingDateText(new Date()), });
    }

    const getScreenPermision = (LoginAgencyID, PinID) => {
        ScreenPermision("I034", LoginAgencyID, PinID).then(res => {
            if (res) {
                // console.log(res)
                setEffectiveScreenPermission(res);
            } else {
                setEffectiveScreenPermission([])
            }
        });
    }

    const columns = [
        {
            width: "80px",
            name: <p className='text-end' style={{ position: 'absolute', top: 8, }}>Action</p>,
            cell: row => (
                <div style={{ position: 'absolute', top: 4, }}>
                    {
                        effectiveScreenPermission ?
                            effectiveScreenPermission[0]?.Changeok ?
                                <Link to={`/Inc-Home?IncId=${stringToBase64(row?.IncidentID)}&IncNo=${row?.IncidentNumber}&IncSta=${true}`}
                                    onClick={(e) => { set_IncidentId(row); }}
                                    className="btn btn-sm bg-green text-white px-1 py-0 mr-1">
                                    <i className="fa fa-edit"></i>
                                    {/* <FontAwesomeIcon icon={faPenToSquare} /> */}
                                </Link>
                                : <></>
                            : <Link to={`/Inc-Home?IncId=${stringToBase64(row?.IncidentID)}&IncNo=${row?.IncidentNumber}&IncSta=${true}`}
                                onClick={(e) => { set_IncidentId(row); }}
                                className="btn btn-sm bg-green text-white px-1 py-0 mr-1">
                                <i className="fa fa-edit"></i>
                                {/* <FontAwesomeIcon icon={faPenToSquare} /> */}
                            </Link>
                    }
                </div>)
        },
        {
            width: '140px',
            name: 'Incident',
            selector: (row) => row.IncidentNumber,
            sortable: true
        },
        {
            width: '180px',
            name: 'Occurred  To',
            selector: (row) => row.OccurredTo ? getShowingDateText(row.OccurredTo) : " ",
        },
        {
            width: '180px',
            name: 'Reported Date/Time',
            selector: (row) => row.ReportedDate ? getShowingDateText(row.ReportedDate) : " ",
            sortable: true
        },
        {
            width: '150px',
            name: 'RMS CFS',
            selector: (row) => (<div>{row?.RMSCFSCode_Description ? row?.RMSCFSCode_Description.substring(0, 20) : ''}{row?.RMSCFSCode_Description?.length > 40 ? '  . . .' : null} </div>),
            sortable: true
        },
        {
            name: 'Location',
            selector: (row) => <>{row?.CrimeLocation ? row?.CrimeLocation.substring(0, 30) : ''}{row?.CrimeLocation?.length > 40 ? '  . . .' : null} </>,
            sortable: true
        },
    ]

    const set_IncidentId = (row) => {
        let newData = [...incidentRecentData];
        let currentItem = newData.find((item) => row.IncidentID === item.IncidentID);
        if (!currentItem) {
            newData.push(row);
        }
        setIncidentRecentData(newData);
        if (row.IncidentID) {
            dispatch({ type: Incident_ID, payload: row?.IncidentID });
            dispatch({ type: Incident_Number, payload: row?.IncidentNumber });
        }
    }

    const columns1 = [
        {
            name: 'Officer Name',
            selector: (row) => row.HeadOfAgency,
            sortable: true
        },

    ]

    const reset_Fields = () => {
        setValue({
            ...value,
            'IncidentNumber': '', 'IncidentNumberTo': '', 'MasterIncidentNumber': '', 'MasterIncidentNumberTo': '', 'RMSCFSCodeList': '',
            'OccurredFrom': '', 'OccurredFromTo': '', 'RMSDispositionId': '', 'DispositionDate': '', 'DispositionDateTo': '',
            'ReceiveSourceID': '', 'NIBRSClearanceID': '', 'IncidentPINActivityID': '', 'IncidentSecurityID': '', 'PINID': '',
        });
    }

    const HandleChange = (e,) => {
        if (e.target.name === 'IncidentNumber' || e.target.name === 'IncidentNumberTo') {
            let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
            if (ele.length === 8) {
                const cleaned = ('' + ele).replace(/[^a-zA-Z0-9\s]/g, '');
                const match = cleaned.match(/^(\d{2})(\d{6})$/);
                if (match) {
                    setValue({
                        ...value,
                        [e.target.name]: match[1] + '-' + match[2]
                    })
                }
            } else {
                ele = e.target.value.split("'").join('').replace(/[^0-9\s]/g, '');
                setValue({
                    ...value,
                    [e.target.name]: ele
                })
            }
        } else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }

    const changeDropDown = (e, name) => {
        if (e) {
            if (name === 'RMSCFSCodeList') {
                const ids = []
                e.forEach(({ value }) => ids.push(value))
                setValue({
                    ...value,
                    [name]: JSON.stringify(ids)
                })
            } else {
                setValue({
                    ...value,
                    [name]: e.value,
                })
            }
        } else {
            setValue({
                ...value,
                [name]: null,
            })
        }
    }

    useEffect(() => {
        if (advancedSearch) {
            if (loginAgencyID) {
                get_Head_Of_Agency(loginAgencyID);
                GetDataReciveSourceID(loginAgencyID); getRmsCfsCodeID(loginAgencyID); GetDataTypeOfSecurity(loginAgencyID); GetDataPinActivity(); getRmsDispositionID(loginAgencyID); GetDataExceptionalClearanceID(loginAgencyID);
            }
        }
    }, [loginAgencyID, advancedSearch]);

    const getRmsCfsCodeID = (LoginAgencyID) => {
        const val = { AgencyID: LoginAgencyID, }
        fetchPostData('Incident/GetData_RMSCFSCODE', val).then((data) => {
            if (data) {
                setRmsCfsID(Comman_changeArrayFormat(data, 'RMSCFSCodeID', 'RMSCFSCode'))
            } else {
                setRmsCfsID([]);
            }
        })
    }

    const get_Head_Of_Agency = (LoginAgencyID) => {
        const val = { AgencyID: LoginAgencyID, }
        fetchPostData('DropDown/GetData_HeadOfAgency', val).then((data) => {
            if (data) {
                setOfficerPinData(data);
                setHeadOfAgency(Comman_changeArrayFormat(data, 'PINID', 'HeadOfAgency'));
            }
            else {
                setHeadOfAgency([]);
            }
        });
    };

    const GetDataReciveSourceID = (LoginAgencyID) => {
        const val = { AgencyID: LoginAgencyID, }
        fetchPostData('Incident/GetData_ReceiveSource', val).then((data) => {
            if (data) {
                setReciveSourceID(Comman_changeArrayFormat(data, 'ReceiveSourceID', 'ReceiveSourceCode'))
            } else {
                setReciveSourceID([]);
            }
        })
    }

    const GetDataTypeOfSecurity = (LoginAgencyID) => {
        const val = { AgencyID: LoginAgencyID, }
        fetchPostData('IncidentSecurity/GetDataDropDown_IncidentSecurity', val).then((data) => {
            if (data) {
                setTypeOfSecurityID(Comman_changeArrayFormat(data, 'SecurityId', 'Description'))
            } else {
                setTypeOfSecurityID([]);
            }
        })
    }

    const GetDataPinActivity = () => {
        fetchData('PINActivity/GetData_PINActivityType').then((data) => {
            setPinActivityID(Comman_changeArrayFormat(data, 'ActivityTypeID', 'Description'));
        })
    }

    const click_AdvanceSearch = () => {
        setValue({ ...value, 'ReportedDate': '', 'ReportedDateTo': '', });
        setAdvancedSearch(true);
    }

    const colourStyles = {
        control: (styles) => ({
            ...styles,
            height: 20,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    }

    const startRef = React.useRef();
    const startRef1 = React.useRef();
    const startRef2 = React.useRef();
    const startRef3 = React.useRef();
    const startRef4 = React.useRef();
    const startRef5 = React.useRef();
    const startRef6 = React.useRef();
    const startRef7 = React.useRef();
    const startRef8 = React.useRef();

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
            startRef2.current.setOpen(false);
            startRef3.current.setOpen(false);
            startRef4.current.setOpen(false);
            startRef5.current.setOpen(false);
            startRef6.current.setOpen(false);
            startRef7.current.setOpen(false);
            startRef8.current.setOpen(false);
        }
    };

    return (
        <>
            <div className="col-12">
                <div className="d-flex mt-2">
                    <div className="md col-5">
                        <h6 className="d-inline-block form-set p-2 text-dark text-bold" >
                            Welcome ,{userName ? userName : ''} Incident
                        </h6>
                        <small className="d-block">
                        </small>
                    </div>
                    <div className="col-7">
                        <div className="row" style={{ marginTop: '-10px' }}>
                            <div className="col-3 col-md-3 col-lg-4 mb-1">
                                <div className="dropdown__box">
                                    <DatePicker
                                        name='ReportedDate'
                                        id='ReportedDate'
                                        onChange={(date) => {
                                            setValue({
                                                ...value,
                                                ['ReportedDate']: date ? getShowingDateText(date) : null,
                                                ['ReportedDateTo']: getShowingDateText(new Date())
                                            })
                                        }}
                                        selected={value.ReportedDate && new Date(value.ReportedDate)}
                                        dateFormat="MM/dd/yyyy"
                                        timeInputLabel
                                        isClearable={value?.ReportedDate ? true : false}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        autoComplete='Off'
                                        disabled={false}
                                        maxDate={new Date()}
                                        placeholderText='Select From Date...'
                                    />
                                    {/* <label htmlFor="" className='pl-0 pt-1' >Reported From Date</label> */}
                                </div>
                            </div>
                            <div className="col-3 col-md-3 col-lg-5 mb-1">
                                <div className="dropdown__box">
                                    <DatePicker
                                        name='ReportedDateTo'
                                        id='ReportedDateTo'
                                        onChange={(date) => { setValue({ ...value, ['ReportedDateTo']: date ? getShowingDateText(date) : null }) }}
                                        selected={value?.ReportedDateTo && new Date(value?.ReportedDateTo)}
                                        dateFormat="MM/dd/yyyy"
                                        timeInputLabel
                                        isClearable={value?.ReportedDateTo ? true : false}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        autoComplete='Off'
                                        minDate={new Date(value?.ReportedDate)}
                                        disabled={value?.ReportedDate ? false : true}
                                        maxDate={new Date()}
                                        placeholderText='Select To Date...'
                                    />
                                    {/* <label htmlFor="" className='pl-0 pt-1' >Reported To Date</label>    */}
                                </div>
                            </div>
                            <div className="col-3 col-md-3 col-lg-3 mt-3 pt-1">
                                <button className="btn btn-sm bg-green text-white px-2 py-1 mr-2" onClick={() => { getIncidentSearchData(); }}>
                                    Search
                                </button>
                                <button className="btn btn-sm bg-green text-white px-2 py-1 " onClick={() => { onClick_Reset(); }}>
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-body mt-4" style={{ margin: '10px 10px 10px 15px' }}>
                <div className="row clearfix">
                    <div className="main-dashboard col-12 mb-2 mt-2">
                        <div className="col-12 col-sm-12">
                            {
                                loder ?
                                    <DataTable
                                        columns={columns}
                                        persistTableHead={true}
                                        dense
                                        data={effectiveScreenPermission ?
                                            effectiveScreenPermission[0]?.DisplayOK ?
                                                incidentSearchData.length > 0 && incAdvSearchData ? incidentSearchData : incidentFilterData && !incAdvSearchData ? incidentFilterData : []
                                                : ""
                                            : ""
                                        }
                                        pagination
                                        paginationPerPage={'100'}
                                        paginationRowsPerPageOptions={[100, 150, 200, 500]}
                                        showPaginationBottom={100}
                                        // paginationRowsPerPageOptions={[10, 15, 20]}
                                        highlightOnHover
                                        subHeader
                                        responsive
                                        fixedHeaderScrollHeight='380px'
                                        fixedHeader
                                        customStyles={tableCustomStyles}
                                        subHeaderComponent={
                                            <div className="col-12 px-0 mt-1">
                                                <div className="row px-0">
                                                    <div className="col-3 col-md-4 col-lg-8 mt-2 px-0 pl-0">
                                                        <p className="new-para ">Incident</p>
                                                    </div>
                                                    <div className="col-3 col-md-8 col-lg-4 ">
                                                        <button className="btn btn-sm new-btn  py-1 mr-2">
                                                            <FontAwesomeIcon icon={faFilter} className="react-icon pl-1" />
                                                            Filter
                                                        </button>

                                                        <Link to='/incident-advanceSearch'>
                                                            <button className="btn btn-sm  new-btn py-1 mr-1" onClick={() => { click_AdvanceSearch(); }} data-toggle="modal" data-target="#AddMasterModal">Advance Search</button>
                                                        </Link>
                                                        {
                                                            effectiveScreenPermission ?
                                                                effectiveScreenPermission[0]?.AddOK ?
                                                                    <Link
                                                                        to={`/Inc-Home?IncId=${0}&IncNo=${''}&IncSta=${false}`}
                                                                        onClick={() => { setShowIncPage('home'); setTabCount([]); setIncidentCount([]); }}
                                                                        className="btn btn-sm text-white py-0"
                                                                    >
                                                                        <button className="btn btn-sm bg-green text-white  py-1 " >
                                                                            {/* <i className="fa fa-plus px-0"> */}
                                                                            <FontAwesomeIcon icon={faPlus} className='px-0 react-icon' />
                                                                            <span className=''>Add Incident</span>
                                                                        </button>
                                                                    </Link>
                                                                    :
                                                                    <>
                                                                    </>
                                                                :
                                                                <>
                                                                    <Link
                                                                        to={`/Inc-Home?IncId=${0}&IncNo=${''}&IncSta=${false}`}
                                                                        onClick={() => { setShowIncPage('home'); setTabCount([]); setIncidentCount([]); }}
                                                                        className="btn btn-sm text-white py-0"
                                                                    >
                                                                        <button className="btn btn-sm bg-green text-white  py-1 " >
                                                                            <FontAwesomeIcon icon={faPlus} className='px-0 react-icon' />
                                                                            <span className=''>Add Incident</span>
                                                                        </button>
                                                                    </Link>
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        subHeaderAlign='left'
                                        noDataComponent={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? "There are no data to display" : "You don’t have permission to view data" : 'There are no data to display'}
                                    />
                                    :
                                    <Loader />
                            }
                        </div>
                    </div>
                    {
                        advancedSearch &&
                        <dialog className='modal top fade' style={{ background: 'rgba(0,0,0, 0.5)' }} id='AddMasterModal' tabIndex='1' data-backdrop='false' aria-labelledby='exampleModalCenterTitle'  >
                            <div className="modal-dialog modal-xl">
                                <div className='modal-content' style={{ borderRadius: '10px', boxShadow: '0px 0px 3px floralwhite' }}>
                                    <div className='modal-header px-3 p-2' style={{ backgroundColor: 'aliceblue', boxShadow: '0px 0px 2px dimgray' }}>
                                        <h5 className="modal-title">Incident Advance Search</h5>
                                        <button type='button' className='close btn-modal' aria-label='close' data-dismiss='modal'>
                                            <span style={{ color: 'red', fontSize: '20px', }}>&times;</span>
                                        </button>
                                    </div>
                                    <div className='modal-body'>
                                        <div className='m-1'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <fieldset className='fieldset' style={{ marginTop: '-10px' }}>
                                                        <legend>Incident Report</legend>
                                                        <div className='row'>
                                                            <div className='col-6 col-md-6 col-lg-3 mb-1'>
                                                                <div className='text-field'>
                                                                    <input type="text" id='IncidentNumber' maxLength={9} name='IncidentNumber' value={value?.IncidentNumber} onChange={HandleChange} />
                                                                    <label >Case From</label>
                                                                </div>
                                                            </div>
                                                            <div className='col-6 col-md-6 col-lg-3 mb-1'>
                                                                <div className='text-field'>
                                                                    <input type="text" id='IncidentNumberTo' maxLength={9} name='IncidentNumberTo' value={value?.IncidentNumberTo} onChange={HandleChange} />
                                                                    <label >Case To</label>
                                                                </div>
                                                            </div>
                                                            <div className='col-6 col-md-6 col-lg-3 mb-1'>
                                                                <div className='text-field'>
                                                                    <input type="text" id='MasterIncidentNumber' name='MasterIncidentNumber' value={value?.MasterIncidentNumber} onChange={HandleChange} />
                                                                    <label >Master Incident From</label>
                                                                </div>
                                                            </div>
                                                            <div className='col-6 col-md-6 col-lg-3 mb-1'>
                                                                <div className="text-field">
                                                                    <input type="text" id='MasterIncidentNumberTo' name='MasterIncidentNumberTo' value={value?.MasterIncidentNumberTo} onChange={HandleChange} />
                                                                    <label >Master Incident To</label>
                                                                </div>
                                                            </div>
                                                            <div className='col-6 col-md-6 col-lg-6 pt-1 mb-1 mt-1'>
                                                                <div className="dropdown__box">
                                                                    <Select
                                                                        name='RMSCFSCodeList'
                                                                        defaultValue={[]}
                                                                        options={rmsCfsID}
                                                                        isClearable
                                                                        isMulti
                                                                        onChange={(e) => changeDropDown(e, 'RMSCFSCodeList')}
                                                                        placeholder="Select..."
                                                                    />
                                                                    <label className='mt-0'>RMS CFS Range </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-6 col-md-6 col-lg-3 pt-1 mb-1'>
                                                                <div className="dropdown__box">
                                                                    <DatePicker
                                                                        id='OccurredFrom'
                                                                        name='OccurredFrom'
                                                                        ref={startRef2}
                                                                        onKeyDown={onKeyDown}
                                                                        onChange={(date) => { setValue({ ...value, ['OccurredFrom']: date ? getShowingMonthDateYear(date) : null }) }}
                                                                        dateFormat="MM/dd/yyyy"
                                                                        isClearable={value?.OccurredFrom ? true : false}
                                                                        selected={value?.OccurredFrom && new Date(value?.OccurredFrom)}
                                                                        maxDate={new Date()}
                                                                        placeholderText={'Select...'}
                                                                        showDisabledMonthNavigation
                                                                        autoComplete="off"
                                                                        showYearDropdown
                                                                        showMonthDropdown
                                                                        dropdownMode="select"
                                                                    />
                                                                    <label className='pt-1'>Occurred From Date</label>
                                                                </div>
                                                            </div>
                                                            <div className='col-6 col-md-6 col-lg-3 pt-1 mb-1'>
                                                                <div className="dropdown__box">
                                                                    <DatePicker
                                                                        id='OccurredFromTo'
                                                                        name='OccurredFromTo'
                                                                        ref={startRef3}
                                                                        onKeyDown={onKeyDown}
                                                                        onChange={(date) => { setValue({ ...value, ['OccurredFromTo']: date ? getShowingMonthDateYear(date) : null }) }}
                                                                        dateFormat="MM/dd/yyyy"
                                                                        isClearable={value?.OccurredFromTo ? true : false}
                                                                        disabled={value?.OccurredFrom ? false : true}
                                                                        selected={value?.OccurredFrom && new Date(value?.OccurredFrom)}
                                                                        minDate={new Date(value?.OccurredFrom)}
                                                                        maxDate={new Date()}
                                                                        placeholderText={'Select...'}
                                                                        showDisabledMonthNavigation
                                                                        autoComplete="off"
                                                                        showYearDropdown
                                                                        showMonthDropdown
                                                                        dropdownMode="select"
                                                                    />
                                                                    <label className='pt-1'>Occurred To Date</label>
                                                                </div>
                                                            </div>
                                                            <div className='col-6 col-md-6 col-lg-3 pt-1 mb-1'>
                                                                <div className="dropdown__box">
                                                                    <DatePicker
                                                                        name='ReportedDate'
                                                                        id='ReportedDate'
                                                                        onChange={(date) => {
                                                                            setValue({
                                                                                ...value,
                                                                                ['ReportedDate']: date ? getShowingDateText(date) : null,
                                                                                ['ReportedDateTo']: getShowingDateText(new Date())
                                                                            })
                                                                        }}
                                                                        selected={value?.ReportedDate && new Date(value?.ReportedDate)}
                                                                        dateFormat="MM/dd/yyyy"
                                                                        timeInputLabel
                                                                        isClearable={value?.ReportedDate ? true : false}
                                                                        peekNextMonth
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                        autoComplete='Off'
                                                                        disabled={false}
                                                                        maxDate={new Date()}
                                                                        placeholderText='Select...'
                                                                    />
                                                                    <label className='pt-1'>Reported From Date</label>
                                                                </div>
                                                            </div>
                                                            <div className='col-6 col-md-6 col-lg-3 pt-1 mb-1'>
                                                                <div className='dropdown__box'>
                                                                    <DatePicker
                                                                        name='ReportedDateTo'
                                                                        id='ReportedDateTo'
                                                                        onChange={(date) => { setValue({ ...value, ['ReportedDateTo']: date ? getShowingDateText(date) : null }) }}
                                                                        selected={value?.ReportedDateTo && new Date(value?.ReportedDateTo)}
                                                                        dateFormat="MM/dd/yyyy"
                                                                        timeInputLabel
                                                                        isClearable={value?.ReportedDateTo ? true : false}
                                                                        peekNextMonth
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                        autoComplete='Off'
                                                                        minDate={new Date(value?.ReportedDate)}
                                                                        disabled={value?.ReportedDate ? false : true}
                                                                        maxDate={new Date()}
                                                                        placeholderText='Select...'
                                                                    />
                                                                    <label className='pt-1'>Reported To Date</label>
                                                                </div>
                                                            </div>
                                                            <div className='col-6 col-md-6 col-lg-3 pt-2 mb-1'>
                                                                <div className='dropdown__box'>
                                                                    <Select
                                                                        name='IncidentPINActivityID'
                                                                        styles={colourStyles}
                                                                        value={pinActivityID?.filter((obj) => obj.value === value?.IncidentPINActivityID)}
                                                                        options={pinActivityID}
                                                                        isClearable
                                                                        onChange={(e) => changeDropDown(e, 'IncidentPINActivityID')}
                                                                        placeholder="Select..."
                                                                    />
                                                                    <label className='mt-0'>Pin Activity</label>
                                                                </div>
                                                            </div>
                                                            <div className='col-6 col-md-6 col-lg-3 pt-2'>
                                                                <div className='dropdown__box'>
                                                                    <Select
                                                                        name='PINID'
                                                                        styles={colourStyles}
                                                                        menuPlacement='top'
                                                                        value={headOfAgency?.filter((obj) => obj.value === value?.PINID)}
                                                                        isClearable
                                                                        options={headOfAgency}
                                                                        onChange={(e) => changeDropDown(e, 'PINID')}
                                                                        placeholder="Select..."
                                                                    />
                                                                    <label className='mt-0'>Officer</label>
                                                                </div>
                                                            </div>
                                                            <div className='col-6 col-md-6 col-lg-3 pt-1'>
                                                                <div className='dropdown__box'>
                                                                    <Select
                                                                        name='IncidentSecurityID'
                                                                        styles={colourStyles}
                                                                        value={typeOfSecurityID?.filter((obj) => obj.value === value?.IncidentSecurityID)}
                                                                        options={typeOfSecurityID}
                                                                        isClearable
                                                                        onChange={(e) => changeDropDown(e, 'IncidentSecurityID')}
                                                                        placeholder="Select..."
                                                                    />
                                                                    <label className='mt-0'>Type Of Security</label>
                                                                </div>
                                                            </div>
                                                            <div className='col-6 col-md-6 col-lg-3 pt-1'>
                                                                <div className='dropdown__box'>
                                                                    <Select
                                                                        name='ReceiveSourceID'
                                                                        value={reciveSourceID?.filter((obj) => obj.value === value?.ReceiveSourceID)}
                                                                        isClearable
                                                                        options={reciveSourceID}
                                                                        menuPlacement='top'
                                                                        onChange={(e) => changeDropDown(e, 'ReceiveSourceID')}
                                                                        placeholder="Select..."
                                                                        styles={colourStyles}
                                                                    />
                                                                    <label className='mt-0'>How Reported</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <fieldset className='fieldset mt-2'>
                                                <legend>RMS Disposition/Clearance Information</legend>
                                                <div className='row'>
                                                    <div className="col-6 col-md-6 col-lg-3 pt-1 mb-1 mt-2">
                                                        <div className='dropdown__box'>
                                                            <Select
                                                                name='RMSDispositionId'
                                                                value={rmsDisposition?.filter((obj) => obj.value === value?.RMSDispositionId)}
                                                                isClearable
                                                                options={rmsDisposition}
                                                                onChange={(e) => changeDropDown(e, 'RMSDispositionId')}
                                                                placeholder="Select..."
                                                                styles={colourStyles}
                                                            />
                                                            <label className='mt-0'>RMS Disposition</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-3 pt-1 mb-1 mt-1">
                                                        <div className='dropdown__box'>
                                                            <DatePicker
                                                                id='DispositionDate'
                                                                name='DispositionDate'
                                                                ref={startRef6}
                                                                onKeyDown={onKeyDown}
                                                                onChange={(date) => { setValue({ ...value, ['DispositionDate']: date ? getShowingMonthDateYear(date) : null }) }}
                                                                dateFormat="MM/dd/yyyy"
                                                                isClearable={value?.DispositionDate ? true : false}
                                                                selected={value?.DispositionDate && new Date(value?.DispositionDate)}
                                                                maxDate={new Date()}
                                                                placeholderText={'Select...'}
                                                                showDisabledMonthNavigation
                                                                autoComplete="off"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                dropdownMode="select"
                                                            />
                                                            <label className='pt-1'>Disposition From Date </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-3 pt-1 mb-1 mt-1">
                                                        <div className='dropdown__box'>
                                                            <DatePicker
                                                                id='DispositionDateTo'
                                                                name='DispositionDateTo'
                                                                ref={startRef7}
                                                                onKeyDown={onKeyDown}
                                                                onChange={(date) => { setValue({ ...value, ['DispositionDateTo']: date ? getShowingMonthDateYear(date) : null }) }}
                                                                dateFormat="MM/dd/yyyy"
                                                                isClearable={value?.DispositionDateTo ? true : false}
                                                                disabled={value?.DispositionDate ? false : true}
                                                                selected={value?.DispositionDateTo && new Date(value?.DispositionDateTo)}
                                                                maxDate={new Date()}
                                                                minDate={new Date(value?.DispositionDate)}
                                                                placeholderText={'Select...'}
                                                                showDisabledMonthNavigation
                                                                autoComplete="off"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                dropdownMode="select"
                                                            />
                                                            <label className='pt-1'>Disposition To Date </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-3 pt-1 mb-1 mt-2">
                                                        <div className='dropdown__box'>
                                                            <Select
                                                                name='NIBRSClearanceID'
                                                                value={exceptionalClearID?.filter((obj) => obj.value === value?.NIBRSClearanceID)}
                                                                isClearable
                                                                options={exceptionalClearID}
                                                                onChange={(e) => changeDropDown(e, 'NIBRSClearanceID')}
                                                                placeholder="Select..."
                                                                styles={colourStyles}
                                                            />
                                                            <label className='mt-0'>Exceptional Clearance</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                    <div className="btn-box text-right mr-1 mb-2">
                                        <button type='button' className="btn btn-sm btn-success mr-1"
                                            onClick={() => {
                                                getIncidentSearchData();
                                            }}>Search</button>
                                        <button type="button" className="btn btn-sm btn-success mr-1"
                                            onClick={() => { reset_Fields(); }} data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </dialog>
                    }
                    {
                        assignModalStatus &&
                        <dialog className='modal fade' style={{ background: 'rgba(0,0,0, 0.5)' }} id="AddAssignModal" tabIndex='-1' data-backdrop='false' aria-labelledby='exampleModalCenterTitle' aria-hidden='true'>
                            <div className="modal-dialog modal-dialog-centered modal-lg">
                                <div className='modal-content'>
                                    <div className='modal-header px-3 p-2' style={{ backgroundColor: 'aliceblue', boxShadow: '0px 0px 2px dimgray' }}>
                                        <h5 className="modal-title text-dark text-bold">Officer List</h5>
                                        <button type='button' className='close btn-modal' aria-label='Close' data-dismiss='modal'>
                                            <span aria-hidden={true} style={{ color: 'red', fontSize: '20px' }}>&times;</span>
                                        </button>
                                    </div>
                                    <div className="box text-center px-2">
                                        <div className="col-12">
                                            <DataTable
                                                fixedHeader
                                                persistTableHead={true}
                                                dense
                                                columns={columns1}
                                                data={assignFilterData.length > 0 ? assignFilterData : officerPinData}
                                                selectableRows
                                                onSelectedRowsChange={''}
                                                pagination
                                                selectableRowsHighlight
                                                highlightOnHover
                                                subHeader
                                                subHeaderComponent={
                                                    <div className="col-12 pl-0 ml-0">
                                                        <div className="row ">
                                                            <div className="col-4 col-md-6">
                                                                <input type="text" onChange={(e) => {
                                                                    const result = One_Value_Search_Filter(officerPinData, e.target.value, filterIncidentIdOption, 'HeadOfAgency')
                                                                    setAssignFilterData(result)
                                                                }} className='form-control' placeholder='Search By Name...' />
                                                            </div>
                                                            <div className='col-1 col-md-1'>
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                                                        <i className="fa fa-filter"></i>
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item onClick={() => setFilterIncidentIdOption('Contains')}>Contains</Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => setFilterIncidentIdOption('is equal to')}>is equal to</Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => setFilterIncidentIdOption('is not equal to ')}>is not equal to </Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => setFilterIncidentIdOption('Starts With')}>Starts With</Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => setFilterIncidentIdOption('End with')}>End with</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </dialog>
                    }
                </div >
            </div>
        </>
    )
}

export default Incident;
import React, { memo } from "react";
import Select from "react-select";

const ArriveModal = (props) => {
  const { openArriveModal, setArriveModal } = props;
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fce9bf",
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };
  return (
    <>
      {openArriveModal ? (
        <>
          <dialog
            className="modal fade"
            style={{ background: "rgba(0,0,0, 0.5)", zIndex: "200" }}
            id="ArriveModal"
            tabIndex="-1"
            aria-hidden="true"
            data-backdrop="false"
          >
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content modal-content-cad">
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12 p-0 pb-2">
                      <div className="py-0 px-2 d-flex justify-content-between align-items-center">
                        <p
                          className="p-0 m-0 font-weight-medium"
                          style={{
                            fontSize: 18,
                            fontWeight: 500,
                            letterSpacing: 0.5,
                          }}
                        >
                          Arrive
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Form Section */}
                  <div className="m-1">
                    <fieldset style={{ border: "1px solid gray" }}>
                      {/* Line 1 */}
                      <div className="tab-form-row py-2">
                        <div className="col-1 d-flex align-self-center justify-content-end">
                          <label className="tab-form-label">
                            Arrive To
                          </label>
                        </div>
                        <div className="d-flex align-self-center justify-content-start" style={{ width: '120px' }}>
                          <div className='d-flex align-self-center justify-content-start' style={{ gap: '5px' }}>
                            <input type="radio" id="scene" value="scene" />
                            <label for="scene" style={{ margin: '0', }}>Scene</label>
                          </div>
                        </div>
                        <div className="d-flex align-self-center justify-content-start" style={{ width: '120px' }}>
                          <div className='d-flex align-self-center justify-content-start' style={{ gap: '5px' }}>
                            <input type="radio" id="hospital" value="hospital" />
                            <label for="hospital" style={{ margin: '0', }}>Hospital</label>
                          </div>
                        </div>
                      </div>

                      {/* Line 2 */}
                      <div className="tab-form-row py-2">
                        <div className="col-1 d-flex align-items-center justify-content-end">
                          <label className="tab-form-label">
                            Incident #
                          </label>
                        </div>
                        <div className="col-2 d-flex align-items-center justify-content-end">
                          <input
                            type="text"
                            className="form-control  py-1 new-input"
                            placeholder="Placeholder"
                          />
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                          <label className="tab-form-label">
                            OR
                          </label>
                        </div>
                      </div>

                      {/* Line 3 */}
                      <div className="tab-form-row py-2">
                        <div className="col-1 d-flex align-items-center justify-content-end">
                          <label className="tab-form-label">
                            Resource #
                          </label>
                        </div>
                        <div className="col-11 d-flex align-items-center justify-content-end" style={{ gap: '10px' }}>
                          <Select
                            isClearable
                            placeholder="Select..."
                            className="w-100"
                          />
                          <Select
                            isClearable
                            placeholder="Select..."
                            className="w-100"
                          />
                          <Select
                            isClearable
                            placeholder="Select..."
                            className="w-100"
                          />
                          <Select
                            isClearable
                            placeholder="Select..."
                            className="w-100"
                          />
                        </div>
                      </div>

                      {/* Line 4 */}
                      <div className="tab-form-row py-2">
                        <div className="col-1 d-flex align-items-center justify-content-end">
                          {/* Empty Space */}
                        </div>
                        <div className="col-11 d-flex align-items-center justify-content-end" style={{ gap: '10px' }}>
                          <Select
                            isClearable
                            placeholder="Select..."
                            className="w-100"
                          />
                          <Select
                            isClearable
                            placeholder="Select..."
                            className="w-100"
                          />
                          <Select
                            isClearable
                            placeholder="Select..."
                            className="w-100"
                          />
                          <Select
                            isClearable
                            placeholder="Select..."
                            className="w-100"
                          />
                        </div>
                      </div>

                      {/* Line 5 */}
                      <div className="tab-form-row py-2">
                        <div className="col-1 d-flex align-items-center justify-content-end">
                          <label className="tab-form-label">
                            Hospital Code
                          </label>
                        </div>
                        <div className="col-2 d-flex align-items-center justify-content-end">
                          <Select
                            isClearable
                            placeholder="Select..."
                            className="w-100"
                          />
                        </div>
                      </div>

                      {/* Line 6 */}
                      <div className="tab-form-row py-2" style={{ alignItems: 'baseline' }}>
                        <div className="col-1 d-flex align-items-end justify-content-end">
                          <label className="tab-form-label">
                            Comments
                          </label>
                        </div>
                        <div className="col-11 d-flex align-items-center justify-content-end">
                          <textarea
                            type="text"
                            className="form-control  py-1 new-input"
                            placeholder="Placeholder"
                            style={{ height: '60px' }}
                          />
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  {/* Buttons Section */}
                  <div className="row">
                    <div className="col-12 p-0">
                      <div className="py-0 px-2 d-flex justify-content-end align-items-center">
                        <div className="d-flex justify-content-end tab-form-row-gap mt-1">
                          <button
                            type="button"
                            className="save-button ml-2"
                          >
                            {'Save'}
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="cancel-button"
                            onClick={() => setArriveModal(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </dialog >
        </>
      ) : (
        <> </>
      )
      }
    </>
  );
};

export default memo(ArriveModal);

import React, { useContext, useState, useEffect } from 'react'
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Link, useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Decrypt_Id_Name, colourStyles, getShowingDateText, getShowingMonthDateYear, stringToBase64, tableCustomStyles } from '../../../Common/Utility';
import { AgencyContext } from '../../../../Context/Agency/Index';
import { threeColArray } from '../../../Common/ChangeArrayFormat';
import { AddDeleteUpadate, fetchPostData } from '../../../hooks/Api';
import DeletePopUpModal from '../../../Common/DeleteModal';
import { toastifyError, toastifySuccess } from '../../../Common/AlertMsg';

const PropertySearch = () => {

    const navigate = useNavigate();
    const { propertySearchData, } = useContext(AgencyContext);

    const [masterPropertyID, setMasterPropertyID] = useState('');
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [loginPinID, setLoginPinID] = useState('');

    const columns = [
        {
            width: '150px',
            name: <p className='text-end' style={{ position: 'absolute', top: '7px' }}>Action</p>,
            cell: row => <>
                {
                    <span onClick={(e) => set_Edit_Value(row)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1">
                        <i className="fa fa-edit"></i>
                    </span >
                }
            </>
        },
        {
            name: 'Incident Number',
            selector: (row) => row.IncidentNumber,
            sortable: true
        },
        {
            name: 'Property Number',
            selector: (row) => row.PropertyNumber,
            sortable: true
        },
        {
            name: 'Property Type',
            selector: (row) => row.Description,
            sortable: true
        },
        {
            name: 'Property Category',
            selector: (row) => row.Category_Description,
            sortable: true
        },
        {
            name: 'Property Classfication',
            selector: (row) => row.Classification_Description,
            sortable: true
        },

        {
            name: 'Loss Code',
            selector: (row) => row.LossCode_Description,
            sortable: true
        },
        {

            name: 'Reported Date/Time',
            selector: (row) => row.ReportedDtTm ? getShowingDateText(row.ReportedDtTm) : " ",
            sortable: true
        },
        {
            name: 'Property Value',
            selector: (row) => row.Value,
            sortable: true
        },
        {
            name: 'Owner',
            selector: (row) => row.OwnerName,
            sortable: true
        },
        {
            name: 'Misc Description',
            selector: (row) => row.Mis_Description,
            sortable: true
        },
        // {
        //     name: <p className='text-end' style={{ position: 'absolute', top: '7px', right: 5 }}>Delete</p>,
        //     cell: row =>
        //         <div className="div" style={{ position: 'absolute', right: 5 }}>
        //             {
        //                 <span onClick={(e) => { setMasterPropertyID(row.MasterPropertyID) }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
        //                     <i className="fa fa-trash"></i>
        //                 </span>
        //             }
        //         </div>
        // },
    ]

    const set_Edit_Value = (row) => {
        if (row.PropertyID || row.MasterPropertyID) {
            setMasterPropertyID(row.MasterPropertyID);
            navigate(`/Prop-Home?page=MST-Property-Dash&ProId=${stringToBase64(row?.PropertyID)}&MProId=${stringToBase64(row?.MasterPropertyID)}&ModNo=${row?.PropertyNumber?.trim()}&ProSta=${true}`);
        }
    }

    const Delete_MasterProperty = () => {
        const val = { 'MasterPropertyID': masterPropertyID, 'DeletedByUserFK': loginPinID, }
        AddDeleteUpadate('MainMasterProperty/Delete_MainMasterProperty', val).then((res) => {
            if (res) {
                toastifySuccess(res.Message);
            } else {
                console.log("Something Wrong")
            }
        })
    }

    const startRef = React.useRef();
    const startRef1 = React.useRef();
    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
        }
    };

    return (
        <>
            <div className="section-body view_page_design pt-1">
                <div className="row clearfix" >
                    <div className="col-12 col-sm-12">
                        <div className="card Agency">
                            <div className="card-body">
                                <fieldset >
                                    <legend>Property Search</legend>
                                </fieldset>
                                {/* <fieldset className='fieldset '>
                                    <legend>Property Owner</legend>
                                    <div className="row">
                                        <div className="col-4 col-md-4">
                                            <div className="text-field">
                                                <input type="text" name='LastName' id='LastName' value={value?.LastName} onChange={handlChange} />
                                                <label className=''>Last Name</label>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4">
                                            <div className="text-field">
                                                <input type="text" name='FirstName' id='FirstName' value={value?.FirstName} onChange={handlChange} />
                                                <label className=''>First Name</label>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4">
                                            <div className="text-field">
                                                <input type="text" name='MiddleName' id='MiddleName' value={value?.MiddleName} onChange={handlChange} />
                                                <label className=''>Middle Name</label>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset> */}
                                {/* <div className="row mt-1 px-2 text-right">
                                    <div className="col-12 col-md-12 col-lg-12" style={{ marginTop: '3px' }}>
                                        <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => { getPropertySearch(); }}>Search</button>
                                        <Link to={'/dashboard-page'}>
                                            <button type="button" className="btn btn-sm btn-success mr-1" data-dismiss="modal"  >Close</button>
                                        </Link>
                                        <button className="btn btn-sm bg-green text-white px-2 py-1" onClick={() => { setAdvancedSearch(true); }} data-toggle="modal" data-target="#PropertySearchModal">Advance Search</button>
                                    </div>
                                </div> */}
                                <div className="row">
                                    {/* <div className="col-12  mt-2">
                                        <div className="bg-line text-white py-1 px-2 d-flex justify-content-between align-items-center">
                                            <p className="p-0 m-0">Property</p>
                                            <p className="p-0 m-0">
                                                <Link to={'/propertytab?page=masterProperty'} onClick={() => {
                                                    setPropertyStatus(false);
                                                    storeData({ 'propertyStatus': false });
                                                    // deleteStoreData({ 'PropertyID': '', 'MasterPropertyID': '' });
                                                }} className="btn btn-sm bg-green text-white px-2 py-0" >
                                                    <i className="fa fa-plus"></i>
                                                </Link>
                                            </p>
                                        </div>
                                    </div> */}
                                    <div className="col-12 ">
                                        <DataTable
                                            dense
                                            columns={columns}
                                            data={
                                                propertySearchData?.length > 0 ? propertySearchData : []
                                            }
                                            pagination
                                            selectableRowsHighlight
                                            highlightOnHover
                                            paginationPerPage={'13'}
                                            paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                            fixedHeaderScrollHeight='600px'
                                            fixedHeader
                                            persistTableHead={true}
                                            customStyles={tableCustomStyles}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                // advancedSearch &&
                // <dialog className="modal top fade " style={{ background: "rgba(0,0,0, 0.5)" }} id="PropertySearchModal" tabIndex="-1" data-backdrop="false" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                //     <div className="modal-dialog  modal-xl">
                //         <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0px 0px 3px floralwhite' }}>
                //             <div className="modal-header px-3 p-2" style={{ backgroundColor: 'aliceblue', boxShadow: '0px 0px 2px dimgray' }}>
                //                 <h5 className="modal-title">Property Advance Search</h5>
                //                 <button type="button" className="close btn-modal" data-dismiss="modal" aria-label="Close">
                //                     <span aria-hidden="true" style={{ color: 'red', fontSize: '20px', }}>&times;</span>
                //                 </button>
                //             </div>
                //             <div className="modal-body">
                //                 <div className="m-1 ">
                //                     <div className="row">
                //                         <div className="col-12">
                //                             <fieldset className='fieldset'>
                //                                 <legend>Property Information</legend>
                //                                 <div className="row">
                //                                     <div className="col-6 col-md-3 " style={{ marginTop: '5px' }}>
                //                                         <div className="text-field">
                //                                             <input type="text" id='IncidentNumber' maxLength={8} name='IncidentNumber' className={''} value={value.IncidentNumber} onChange={handlChange} />
                //                                             <label className=''>Master Case</label>
                //                                         </div>
                //                                     </div>
                //                                     <div className="col-6 col-md-3 " style={{ marginTop: '5px' }}>
                //                                         <div className="text-field">
                //                                             <input type="text" id='PropertyNumber' style={{ textTransform: "uppercase" }} maxLength={10} name='PropertyNumber' value={value.PropertyNumber} onChange={handlChange} />
                //                                             <label className=''>Property</label>
                //                                         </div>
                //                                     </div>
                //                                     <div className="col-6 col-md-3 col-lg-3 mt-1">
                //                                         <div className=" dropdown__box">
                //                                             <Select
                //                                                 styles={colourStyles}
                //                                                 name='PropertyTypeID'
                //                                                 value={propertyTypeData?.filter((obj) => obj.value === value?.PropertyTypeID)}
                //                                                 options={propertyTypeData}
                //                                                 onChange={(e) => ChangeDropDown(e, 'PropertyTypeID')}
                //                                                 isClearable
                //                                                 placeholder="Select..."
                //                                                 isDisabled={sessionStorage.getItem('PropertyID') || sessionStorage.getItem('MasterPropertyID') ? true : false}
                //                                             />
                //                                             <label>Type</label>

                //                                         </div>
                //                                     </div>
                //                                     <div className="col-6 col-md-4 col-lg-3 pt-1 ">
                //                                         <div className="dropdown__box">
                //                                             <Select
                //                                                 name='LossCodeID'
                //                                                 styles={colourStyles}
                //                                                 value={propertyLossCodeData?.filter((obj) => obj.value === value?.LossCodeID)}
                //                                                 options={propertyLossCodeData}
                //                                                 onChange={(e) => ChangeDropDown(e, 'LossCodeID')}
                //                                                 isClearable
                //                                                 placeholder="Select..."
                //                                             />
                //                                             <label htmlFor='' className='mt-0'>Property Reason</label>
                //                                         </div>
                //                                     </div>
                //                                     <div className="col-6 col-md-4 col-lg-3 mb-1 mt-1">
                //                                         <div className="dropdown__box">
                //                                             <DatePicker
                //                                                 id='ReportedDtTm'
                //                                                 name='ReportedDtTm'
                //                                                 ref={startRef}
                //                                                 onKeyDown={onKeyDown}
                //                                                 onChange={(date) => { setValue({ ...value, ['ReportedDtTm']: date ? getShowingMonthDateYear(date) : null }) }}
                //                                                 className=''
                //                                                 dateFormat="MM/dd/yyyy"
                //                                                 timeInputLabel
                //                                                 dropdownMode="select"
                //                                                 showMonthDropdown
                //                                                 autoComplete='Off'
                //                                                 showYearDropdown
                //                                                 isClearable={value?.ReportedDtTm ? true : false}
                //                                                 selected={value?.ReportedDtTm && new Date(value?.ReportedDtTm)}
                //                                                 maxDate={new Date()}
                //                                                 placeholderText={value?.ReportedDtTm ? value.ReportedDtTm : 'Select...'}
                //                                             />
                //                                             <label htmlFor="" className='pt-1'>Reported From Date</label>
                //                                         </div>
                //                                     </div>
                //                                     <div className="col-6 col-md-4 col-lg-3 mb-1 mt-1">
                //                                         <div className="dropdown__box">
                //                                             <DatePicker
                //                                                 id='ReportedDtTmTo'
                //                                                 name='ReportedDtTmTo'
                //                                                 ref={startRef1}
                //                                                 onKeyDown={onKeyDown}
                //                                                 onChange={(date) => { setValue({ ...value, ['ReportedDtTmTo']: date ? getShowingMonthDateYear(date) : null }) }}
                //                                                 className=''
                //                                                 dateFormat="MM/dd/yyyy"
                //                                                 timeInputLabel
                //                                                 dropdownMode="select"
                //                                                 showMonthDropdown
                //                                                 autoComplete='Off'
                //                                                 showYearDropdown
                //                                                 disabled={value?.ReportedDtTm ? false : true}
                //                                                 isClearable={value?.ReportedDtTmTo ? true : false}
                //                                                 selected={value?.ReportedDtTmTo && new Date(value?.ReportedDtTmTo)}
                //                                                 maxDate={new Date()}
                //                                                 minDate={new Date(value?.ReportedDtTm)}
                //                                                 placeholderText={'Select...'}
                //                                             />
                //                                             <label htmlFor="" className='pt-1'>Reported To Date</label>
                //                                         </div>
                //                                     </div>
                //                                 </div>
                //                             </fieldset>
                //                             <fieldset className='fieldset mt-2'>
                //                                 <legend>Property Owner</legend>
                //                                 <div className="row">
                //                                     <div className="col-6 col-md-3">
                //                                         <div className="text-field">
                //                                             <input type="text" name='LastName' id='LastName' value={value?.LastName} onChange={handlChange} />
                //                                             <label className=''>Last Name</label>
                //                                         </div>
                //                                     </div>
                //                                     <div className="col-6 col-md-3">
                //                                         <div className="text-field">
                //                                             <input type="text" name='FirstName' id='FirstName' value={value?.FirstName} onChange={handlChange} />
                //                                             <label className=''>First Name</label>
                //                                         </div>
                //                                     </div>
                //                                     <div className="col-6 col-md-3">
                //                                         <div className="text-field">
                //                                             <input type="text" name='MiddleName' id='MiddleName' value={value?.MiddleName} onChange={handlChange} />
                //                                             <label className=''>Middle Name</label>
                //                                         </div>
                //                                     </div>
                //                                 </div>
                //                             </fieldset>
                //                             <div className="row mt-1 px-2 text-right">
                //                                 <div className="col-12">
                //                                     {/* <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => { getPropertySearch(); }}>Search</button> */}
                //                                     {/* <button type="button" data-dismiss="modal" className="btn btn-sm btn-success" onClick={() => { Reset(); setAdvancedSearch(false) }}>Close</button> */}
                //                                 </div>
                //                             </div>
                //                         </div>
                //                     </div>
                //                 </div>
                //             </div>
                //         </div>
                //     </div>
                // </dialog>
            }
            <DeletePopUpModal func={Delete_MasterProperty} />
        </>
    )
}

export default PropertySearch
import React, { useContext, useEffect, useState } from 'react'
import MissingTab from '../../../Utility/Tab/MissingTab'
import { Decrypt_Id_Name, base64ToString, getShowingDateText, getShowingMonthDateYear, getYearWithOutDateTime, stringToBase64 } from '../../../Common/Utility'
import Select from "react-select";
import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_AgencyOfficer_Data, get_ArresteeName_Data, get_Classification_Drp_Data, get_Data_VODID_Drp_Data, get_MakeId_Drp_Data, get_ModalId_Drp_Data, get_PlateType_Drp_Data, get_State_Drp_Data, get_StyleId_Drp_Data, get_VehicleLossCode_Drp_Data, get_Vehicle_Color_Drp_Data } from '../../../../redux/actions/DropDownsData';
import { get_Inc_ReportedDate, get_LocalStoreData } from '../../../../redux/actions/Agency';
import { AddDeleteUpadate, fetchPostData } from '../../../hooks/Api';
import { threeColArray } from '../../../Common/ChangeArrayFormat';
import { RequiredFieldIncident, RequiredFieldOnConditon } from '../../Utility/Personnel/Validation';
import { toastifyError, toastifySuccess } from '../../../Common/AlertMsg';
import MasterNameModel from '../../MasterNameModel/MasterNameModel';
import ChangesModal from '../../../Common/ChangesModal';
import { AgencyContext } from '../../../../Context/Agency/Index';
import ListModal from '../../Utility/ListManagementModel/ListModal';

const MissingPersonVehicle = () => {


    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    let DecEIncID = 0
    let DecMissPerID = 0
    let DecMissVehID = 0

    const query = useQuery();
    // var openPage = query?.get('page');
    var IncID = query?.get("IncId");
    var MissPerId = query?.get("MissPerID");
    var MissPerSta = query?.get('MissPerSta');
    var IncNo = query?.get("IncNo");
    var IncSta = query?.get("IncSta");
    var MissVehID = query?.get("MissVehID");
    var MissVehSta = query?.get("MissVehSta");
    let MstPage = query?.get('page');

    if (!IncID) { DecEIncID = 0; }
    else { DecEIncID = parseInt(base64ToString(IncID)); }

    if (!MissPerId) { DecMissPerID = 0; }
    else { DecMissPerID = parseInt(base64ToString(MissPerId)); }

    if (!MissVehID) { DecMissVehID = 0; }
    else { DecMissVehID = parseInt(base64ToString(MissVehID)); }

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { setChangesStatus, VehicleFilterData, get_Data_Vehicle } = useContext(AgencyContext);

    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const incReportedDate = useSelector((state) => state.Agency.incReportedDate);
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const vodIdData = useSelector((state) => state.DropDown.vehicleVODIDDrpData);
    const colorDrp = useSelector((state) => state.DropDown.vehicleColorDrpData);
    const primaryOfficerID = useSelector((state) => state.DropDown.agencyOfficerDrpData)
    const arresteeDrpData = useSelector((state) => state.DropDown.arresteeNameData);
    const modalIdDrp = useSelector((state) => state.DropDown.modalIdDrpData)
    const makeIdDrp = useSelector((state) => state.DropDown.makeIdDrpData)
    const styleIdDrp = useSelector((state) => state.DropDown.styleIdDrpData)
    const classificationID = useSelector((state) => state.DropDown.classificationDrpData)
    const plateTypeIdDrp = useSelector((state) => state.DropDown.vehiclePlateIdDrpData)
    const stateList = useSelector((state) => state.DropDown.stateDrpData);
    const propertyLossCodeData = useSelector((state) => state.DropDown.vehicleLossCodeDrpData);

    const [categoryIdDrp, setCategoryIdDrp] = useState([]);
    const [plateExpDate, setPlateExpDate] = useState();
    const [manufactureDate, setManufactureDate] = useState();
    const [lossCode, setLossCode] = useState('');
    const [loginAgencyID, setLoginAgencyID] = useState('');
    const [loginPinID, setLoginPinID] = useState('');
    const [possenSinglData, setPossenSinglData] = useState([]);
    const [ownerOfID, setOwnerOfID] = useState('');
    const [nameModalStatus, setNameModalStatus] = useState(false);
    const [type, setType] = useState("Property");
    const [missingVehicleID, setMissingVehicleID] = useState()
    const [editval, setEditval] = useState()
    const [vehicleData, setVehicleData] = useState()
    const [possessionID, setPossessionID] = useState();
    const [openPage, setOpenPage] = useState('');


    const [value, setValue] = useState({
        'IncidentID': '', 'AgencyID': '', 'CategoryID': null, 'PlateID': null, 'PlateTypeID': null, 'ClassificationID': null, 'VIN': '', 'VODID': null, 'PlateExpireDtTm': '', 'OANID': null, 'StyleID': null, 'MakeID': null, 'ModelID': null, 'ManufactureYear': '', 'Weight': '', 'OwnerID': null,
        'PrimaryColorID': null, 'SecondaryColorID': null, 'Value': '', 'CreatedByUserFK': '', 'VehicleNo': '',
        'VehicleNumber': '', 'ReportedDtTm': '', 'LossCodeID': '', 'Inspection_Sticker': '', 'InspectionExpiresDtTm': '',
        'PrimaryOfficerID': '', 'InProfessionOf': '', 'TagID': '', 'NICBID': '', 'DestroyDtTm': '', 'Description': '',
        'IsEvidence': '', 'IsPropertyRecovered': '', 'IsImmobalizationDevice': '', 'IsEligibleForImmobalization': '',
        'VehicleID': '', 'IsMaster': false

    })

    const [errors, setErrors] = useState({
        'CategoryIDError': '', 'PlateTypeIDError': '', 'VehicleNoError': '', 'vinLengthError': ''
    })

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID); setLoginPinID(localStoreData?.PINID);
        }
    }, [localStoreData]);

    useEffect(() => {
        if (loginAgencyID) {
            setValue({
                ...value,
                'IncidentID': DecEIncID, 'CreatedByUserFK': loginPinID, 'AgencyID': loginAgencyID, 'PrimaryOfficerID': loginPinID, 'LossCodeID': 8, 'ReportedDtTm': incReportedDate ? getShowingDateText(incReportedDate) : getShowingMonthDateYear(new Date())
            });
            if (propertyLossCodeData?.length === 0) { dispatch(get_VehicleLossCode_Drp_Data(loginAgencyID)) };
            if (plateTypeIdDrp?.length === 0) { dispatch(get_PlateType_Drp_Data(loginAgencyID)) };
            if (styleIdDrp?.length === 0) { dispatch(get_StyleId_Drp_Data(loginAgencyID)) };
            if (makeIdDrp?.length === 0) { dispatch(get_MakeId_Drp_Data(loginAgencyID)) };
            if (arresteeDrpData?.length === 0) { dispatch(get_ArresteeName_Data('', '', DecEIncID)) }
            if (vodIdData?.length === 0) { dispatch(get_Data_VODID_Drp_Data(loginAgencyID)) };
            if (colorDrp?.length === 0) { dispatch(get_Vehicle_Color_Drp_Data(loginAgencyID)) };
            if (primaryOfficerID?.length === 0) { dispatch(get_AgencyOfficer_Data(loginAgencyID)) };
            if (stateList?.length === 0) { dispatch(get_State_Drp_Data()) };
            PropertyType(loginAgencyID);
            get_Data_Vehicle(DecEIncID);
            if (!incReportedDate) { dispatch(get_Inc_ReportedDate(DecEIncID)) }
        }
    }, [loginAgencyID, incReportedDate]);

    useEffect(() => {
        if (possessionID) { setValue({ ...value, ['OwnerID']: parseInt(possessionID) }) }
    }, [possessionID, arresteeDrpData]);

    useEffect(() => {
        if (VehicleFilterData?.length > 0) {
            const normalObjectArray = VehicleFilterData?.map(vehicle => {
                return {
                    value: vehicle.VehicleID,
                    label: vehicle.VehicleNumber
                };
            });
            setVehicleData(normalObjectArray)
        }
    }, [VehicleFilterData])

    useEffect(() => {
        if (DecMissVehID) {
            GetSingleDataVehicle(DecMissVehID);
        }
    }, [MissVehID]);

    const PropertyType = (loginAgencyID) => {
        const val = { AgencyID: loginAgencyID }
        fetchPostData('PropertyCategory/GetDataDropDown_PropertyCategory', val).then((data) => {
            if (data) {
                const res = data?.filter((val) => {
                    if (val.PropertyCategoryCode === "V") return val
                })
                if (res.length > 0) {
                    get_CategoryId_Drp(res[0]?.PropertyCategoryID)
                }
            }
        })
    }
    const get_CategoryId_Drp = (CategoryID) => {
        const val = { CategoryID: CategoryID }
        fetchPostData('Property/GetDataDropDown_PropertyType', val).then((data) => {
            if (data) {
                setCategoryIdDrp(threeColArray(data, 'PropertyDescID', 'Description', 'CategoryID'))
            } else {
                setCategoryIdDrp([]);
            }
        })
    }

    const GetSingleDataVehicle = (VehicleID, masterPropertyID) => {
        const val = { 'VehicleID': VehicleID, 'MasterPropertyID': masterPropertyID }
        fetchPostData('PropertyVehicle/GetSingleData_PropertyVehicle', val).then((res) => {
            if (res.length > 0) {
                reset()
                setEditval(res);
            } else { setEditval([]) }
        })
    }

    useEffect(() => {
        if (editval) {
            setValue({
                ...value,
                'SecondaryColorID': editval[0]?.SecondaryColorID, 'IncidentID': editval[0]?.IncidentID,
                'PrimaryColorID': editval[0]?.PrimaryColorID, 'OwnerID': editval[0]?.OwnerID, 'ModelID': editval[0]?.ModelID, 'MakeID': editval[0]?.MakeID, 'StyleID': editval[0]?.StyleID, 'OANID': editval[0]?.OANID, 'VODID': editval[0]?.VODID, 'ClassificationID': editval[0]?.ClassificationID, 'PlateTypeID': editval[0]?.PlateTypeID, 'PlateID': editval[0]?.PlateID, 'CategoryID': editval[0]?.CategoryID, 'VehicleNumber': editval[0]?.VehicleNumber, 'Weight': editval[0]?.Weight, 'ManufactureYear': editval[0]?.ManufactureYear, 'VIN': editval[0]?.VIN ? editval[0]?.VIN : '', 'VehicleNo': editval[0]?.VehicleNo, 'PlateExpireDtTm': editval[0]?.PlateExpireDtTm, 'ModifiedByUserFK': loginPinID,
                'Value': editval[0]?.Value ? editval[0]?.Value : "", 'VehicleID': editval[0]?.VehicleID,
                'ReportedDtTm': editval[0]?.ReportedDtTm, 'LossCodeID': editval[0]?.LossCodeID, 'Inspection_Sticker': editval[0]?.Inspection_Sticker, 'InspectionExpiresDtTm': editval[0]?.InspectionExpiresDtTm,
                'PrimaryOfficerID': editval[0]?.PrimaryOfficerID, 'InProfessionOf': editval[0]?.InProfessionOf, 'TagID': editval[0]?.TagID, 'NICBID': editval[0]?.NICBID, 'DestroyDtTm': editval[0]?.DestroyDtTm, 'Description': editval[0]?.Description,
                'IsEvidence': editval[0]?.IsEvidence, 'IsPropertyRecovered': editval[0]?.IsPropertyRecovered, 'IsImmobalizationDevice': editval[0]?.IsImmobalizationDevice, 'IsEligibleForImmobalization': editval[0]?.IsEligibleForImmobalization,
            })
            dispatch(get_Classification_Drp_Data(editval[0]?.CategoryID));
            setOwnerOfID(editval[0]?.OwnerID)
            setPossessionID(editval[0]?.OwnerID);
            dispatch(get_ModalId_Drp_Data(loginAgencyID, editval[0]?.MakeID));
            setManufactureDate(editval[0]?.ManufactureYear ? new Date(editval[0]?.ManufactureYear) : '');
            setPlateExpDate(editval[0]?.PlateExpireDtTm ? new Date(editval[0]?.PlateExpireDtTm) : '');
            setMissingVehicleID(editval[0]?.VehicleID)
        } else {
            setValue({
                ...value,
                'AgencyID': loginAgencyID, 'CategoryID': null, 'PlateID': null, 'PlateTypeID': null, 'ClassificationID': null, 'VIN': '', 'VODID': null, 'PlateExpireDtTm': '', 'OANID': null, 'StyleID': null, 'MakeID': null, 'ModelID': null, 'ManufactureYear': '', 'Weight': '', 'OwnerID': null,
                'PrimaryColorID': null, 'SecondaryColorID': null, 'Value': '', 'CreatedByUserFK': '', 'VehicleNo': '',
                'Inspection_Sticker': '', 'InspectionExpiresDtTm': '', 'VehicleNumber': '',
                'PrimaryOfficerID': '', 'InProfessionOf': '', 'TagID': '', 'NICBID': '', 'DestroyDtTm': '', 'Description': '',
                'IsEvidence': '', 'IsPropertyRecovered': '', 'IsImmobalizationDevice': '', 'IsEligibleForImmobalization': '',
                'IsMaster': false,
            })
        }
    }, [editval])

    const check_Validation_Error = (e) => {
        // const LossCodeIDErr = RequiredFieldIncident(value.LossCodeID);
        const CategoryIDErr = RequiredFieldIncident(value.CategoryID);
        const PlateTypeIDErr = RequiredFieldIncident(value.PlateTypeID);
        const VehicleNoErr = value.PlateID === '' || value.PlateID === null ? 'true' : RequiredFieldIncident(value.VehicleNo);
        const ContactErr = lossCode === 'STOL' || lossCode === 'BURN' || lossCode === 'RECD' ? RequiredFieldOnConditon(value.Value) : 'true';
        const vinErr = value?.VIN.length > 0 && value?.VIN.length < 17 ? "Enter Minimum 17 Charecters" : 'true';

        setErrors(pre => {
            return {
                ...pre,
                // ['LossCodeIDError']: LossCodeIDErr || pre['LossCodeIDError'],
                ['CategoryIDError']: CategoryIDErr || pre['CategoryIDError'],
                ['PlateTypeIDError']: PlateTypeIDErr || pre['PlateTypeIDError'],
                ['ContactError']: ContactErr || pre['ContactError'],
                ['VehicleNoError']: VehicleNoErr || pre['VehicleNoError'],
                ['vinLengthError']: vinErr || pre['vinLengthError'],

            }
        });
    }

    const { CategoryIDError, PlateTypeIDError, ContactError, VehicleNoError, vinLengthError } = errors

    useEffect(() => {
        if (CategoryIDError === 'true' && PlateTypeIDError === 'true' && ContactError === 'true' && VehicleNoError === 'true' && vinLengthError === 'true') {
            if (missingVehicleID && (MissVehSta === true || MissVehSta || 'true')) { Update_MissingVehicle_Data() }
            else {
                Insert_Vehicle();
            }
        }
    }, [CategoryIDError, PlateTypeIDError, ContactError, VehicleNoError, vinLengthError])

    const ChangeDropDown = (e, name) => {
        if (e) {
            if (name === 'MakeID') {
                dispatch(get_ModalId_Drp_Data(loginAgencyID, e.value))
                setValue({ ...value, ['MakeID']: e.value });
                setChangesStatus(true)
            } else if (name === 'CategoryID') {
                dispatch(get_Classification_Drp_Data(e.value))
                setValue({
                    ...value,
                    [name]: e.value
                });
                setErrors({ ...errors, 'CategoryIDError': '' })
                setChangesStatus(true)
            } else if (name === 'PlateTypeID') {
                setValue({
                    ...value,
                    [name]: e.value
                });
                setErrors({ ...errors, 'PlateTypeIDError': '' })
                setChangesStatus(true)
            } else if (name === 'VehicleID') {
                setValue({
                    ...value,
                    [name]: e.value
                });
                if (e.value) {
                    navigate(`/Missing-Vehicle-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&MissPerID=${MissPerId}&MissPerSta=${true}&MissVehID=${stringToBase64(e.value)}&MissVehSta=${true}`)
                }
            } else if (name === 'OwnerID') {
                setValue({ ...value, [name]: e.value });
                setOwnerOfID(e.value);
                setPossessionID(e.value);
                setPossenSinglData([])
            } else {
                setValue({
                    ...value,
                    [name]: e.value
                });
                setChangesStatus(true)
            }

        } else if (e === null) {
            setValue({ ...value, [name]: null });
        } else {
            setValue({ ...value, [name]: null });
        }
    }

    const HandleChanges = (e) => {
        if (e.target.name === 'Value') {
            const ele = e.target.value.replace(/[^0-9\.]/g, "")
            if (ele.includes('.')) {
                if (ele.length === 16) {
                    setValue({ ...value, [e.target.name]: ele });
                } else {
                    if (ele.substr(ele.indexOf('.') + 1).slice(0, 2)) {
                        const checkDot = ele.substr(ele.indexOf('.') + 1).slice(0, 2).match(/\./g)
                        if (!checkDot) {
                            setValue({ ...value, [e.target.name]: ele.substring(0, ele.indexOf(".")) + '.' + ele.substr(ele.indexOf('.') + 1).slice(0, 2) });
                            return;
                        } else {
                            return;
                        }
                    } else { setValue({ ...value, [e.target.name]: ele }) }
                }
            } else {
                setValue({ ...value, [e.target.name]: ele });
            }
            setChangesStatus(true)
        } else if (e.target.name === 'Weight') {
            const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
            setChangesStatus(true)
            setValue({ ...value, [e.target.name]: checkNumber })
        } else if (e.target.name === 'VIN' || e.target.name === 'OANID' || e.target.name === 'VehicleNo') {
            var ele = e.target.value.replace(/[^0-9a-zA-Z]/g, "")
            const checkNumber = ele.toUpperCase();
            setChangesStatus(true)
            setValue({ ...value, [e.target.name]: checkNumber })
            setErrors({ ...errors, 'VehicleNoError': '' })

        } else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            });
            setChangesStatus(true)
        }
    }

    // const onInProfessionChange = (e, name) => {

    //     if (e) {
    //         if (name === 'OwnerID') {
    //             setValue({ ...value, [name]: e.value }); setOwnerOfID(e.value); setPossenSinglData([])
    //         } else {
    //             setValue({ ...value, [name]: e.value })
    //         }
    //     } else {
    //         if (name === 'OwnerID') {
    //             setValue({ ...value, [name]: null }); setOwnerOfID(''); setPossenSinglData([])
    //         } else {
    //             setValue({ ...value, [name]: null })
    //         }

    //     }
    // }

    const reset = () => {
        setValue({
            ...value,
            'CategoryID': null, 'PlateID': null, 'PlateTypeID': null, 'ClassificationID': null, 'VIN': '', 'VODID': null, 'PlateExpireDtTm': '', 'OANID': '', 'StyleID': null, 'MakeID': null, 'ModelID': null, 'ManufactureYear': '', 'Weight': '', 'OwnerID': null,
            'PrimaryColorID': null, 'SecondaryColorID': null, 'Value': '', 'CreatedByUserFK': '', 'VehicleNo': '', 'VehicleID': '',
            'ReportedDtTm': incReportedDate ? getShowingDateText(incReportedDate) : getShowingMonthDateYear(new Date()), 'Inspection_Sticker': '', 'InspectionExpiresDtTm': '',
            'PrimaryOfficerID': '', 'InProfessionOf': '', 'TagID': '', 'NICBID': '', 'DestroyDtTm': '', 'Description': '',
            'IsEvidence': '', 'IsPropertyRecovered': '', 'IsImmobalizationDevice': '', 'IsEligibleForImmobalization': '', 'VehicleNumber': ''
        })
        setErrors({
            ...errors,
            'CategoryIDError': '', 'PlateTypeIDError': '', 'VehicleNoError': '', 'ContactError': ''
        })
        setPlateExpDate();
        setManufactureDate();
        setOwnerOfID('')
        setPossessionID('');

    }

    const Insert_Vehicle = () => {
        const { IncidentID, CategoryID, PlateID, PlateTypeID, ClassificationID, VIN, VODID, PlateExpireDtTm, OANID, StyleID, MakeID, ModelID, ManufactureYear, Weight, OwnerID, PrimaryColorID, SecondaryColorID, Value, VehicleNo, VehicleID, VehicleNumber, ReportedDtTm, LossCodeID, Inspection_Sticker, InspectionExpiresDtTm, PrimaryOfficerID, InProfessionOf, TagID, NICBID, DestroyDtTm, Description, IsEvidence, IsPropertyRecovered, IsImmobalizationDevice, IsEligibleForImmobalization
        } = value;
        const val = {
            'IncidentID': IncidentID, 'AgencyID': loginAgencyID, 'CategoryID': CategoryID, 'PlateID': PlateID, 'PlateTypeID': PlateTypeID, 'ClassificationID': ClassificationID, 'VIN': VIN, 'VODID': VODID, 'PlateExpireDtTm': PlateExpireDtTm, 'OANID': OANID, 'StyleID': StyleID, 'MakeID': MakeID, 'ModelID': ModelID, 'ManufactureYear': ManufactureYear, 'Weight': Weight, 'OwnerID': OwnerID, 'PrimaryColorID': PrimaryColorID, 'SecondaryColorID': SecondaryColorID, 'Value': Value, 'CreatedByUserFK': loginPinID, 'VehicleNo': VehicleNo, 'MissingPersonVehicleID': missingVehicleID, 'VehicleID': VehicleID, 'ReportedDtTm': ReportedDtTm, 'LossCodeID': LossCodeID, 'Inspection_Sticker': Inspection_Sticker, 'InspectionExpiresDtTm': InspectionExpiresDtTm, 'PrimaryOfficerID': loginPinID, 'InProfessionOf': InProfessionOf, 'TagID': TagID, 'NICBID': NICBID, 'DestroyDtTm': DestroyDtTm, 'Description': Description, 'IsEvidence': IsEvidence, 'IsPropertyRecovered': IsPropertyRecovered, 'IsImmobalizationDevice': IsImmobalizationDevice, 'IsEligibleForImmobalization': IsEligibleForImmobalization, 'VehicleNumber': VehicleNumber
        }
        AddDeleteUpadate('PropertyVehicle/Insert_PropertyVehicle', val).then((res) => {
            if (res.success) {
                toastifySuccess(res.Message);
                // get_MissingVehicle_Data()
                if (res?.VehicleID) {
                    navigate(`/Missing-Vehicle-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&MissPerID=${MissPerId}&MissPerSta=${true}&MissVehID=${stringToBase64(res?.VehicleID)}&MissPerSta=${true}`)
                    Insert_MisingPerson_Vehicle(res?.VehicleID)
                }
                // setMissingVehicleID(res.MissingPersonVehicleID);
                get_Data_Vehicle(DecEIncID);
                setErrors({ ...errors, ['CategoryIDError']: '' })
                PropertyType(loginAgencyID);
                setChangesStatus(false)

            } else {
                toastifyError('Error');
                setErrors({ ...errors, ['CategoryIDError']: '' });
            }
        })
    }

    const Update_MissingVehicle_Data = () => {
        const previousValue = value.Value;
        const { IncidentID, CategoryID, PlateID, PlateTypeID, ClassificationID, VIN, VODID, PlateExpireDtTm, OANID, StyleID, MakeID, ModelID, ManufactureYear, Weight, OwnerID, PrimaryColorID, SecondaryColorID, Value, VehicleNo, VehicleID, VehicleNumber, ReportedDtTm, LossCodeID, Inspection_Sticker, InspectionExpiresDtTm, PrimaryOfficerID, InProfessionOf, TagID, NICBID, DestroyDtTm, Description, IsEvidence, IsPropertyRecovered, IsImmobalizationDevice, IsEligibleForImmobalization

        } = value;
        const val = {
            'IncidentID': IncidentID, 'AgencyID': loginAgencyID, 'CategoryID': CategoryID, 'PlateID': PlateID, 'PlateTypeID': PlateTypeID, 'ClassificationID': ClassificationID, 'VIN': VIN, 'VODID': VODID, 'PlateExpireDtTm': PlateExpireDtTm, 'OANID': OANID, 'StyleID': StyleID, 'MakeID': MakeID, 'ModelID': ModelID, 'ManufactureYear': ManufactureYear, 'Weight': Weight, 'OwnerID': OwnerID, 'PrimaryColorID': PrimaryColorID, 'SecondaryColorID': SecondaryColorID, 'Value': Value, 'ModifiedByUserFK': loginPinID, 'VehicleNo': VehicleNo, 'MissingPersonVehicleID': missingVehicleID, 'VehicleID': VehicleID, 'ReportedDtTm': ReportedDtTm, 'LossCodeID': LossCodeID, 'Inspection_Sticker': Inspection_Sticker, 'InspectionExpiresDtTm': InspectionExpiresDtTm, 'PrimaryOfficerID': loginPinID, 'InProfessionOf': InProfessionOf, 'TagID': TagID, 'NICBID': NICBID, 'DestroyDtTm': DestroyDtTm, 'Description': Description, 'IsEvidence': IsEvidence, 'IsPropertyRecovered': IsPropertyRecovered, 'IsImmobalizationDevice': IsImmobalizationDevice, 'IsEligibleForImmobalization': IsEligibleForImmobalization, 'VehicleNumber': VehicleNumber
        }
        AddDeleteUpadate('PropertyVehicle/Update_PropertyVehicle', val).then((res) => {
            if (res.success) {
                toastifySuccess(res.Message);
                setChangesStatus(false)
                setErrors({ ...errors, ['CategoryIDError']: '' });
                setValue({ ...value, Value: previousValue, });
                Insert_MisingPerson_Vehicle(VehicleID)
            } else {
                toastifyError('Error');
                setErrors({ ...errors, ['CategoryIDError']: '' });
            }
        })
    }

    const Insert_MisingPerson_Vehicle = (vehID) => {
        const val = { 'VehicleID': vehID, 'MissingPersonID': DecMissPerID, 'ModifiedByUserFK': loginPinID }
        AddDeleteUpadate('MissingPerson/Insert_MissingPersonVehicle', val).then((res) => {
            if (res.success) {
                // toastifySuccess(res.Message);
            } else {
                toastifyError('Error');
                setErrors({ ...errors, ['CategoryIDError']: '' });
            }
        })
    }


    const GetSingleDataPassion = (nameID, masterNameID) => {
        const val = { 'NameID': nameID, 'MasterNameID': masterNameID }
        fetchPostData('MasterName/GetSingleData_MasterName', val).then((res) => {
            if (res) {
                setPossenSinglData(res);
            } else { setPossenSinglData([]); }
        })
    }


    const startRef = React.useRef();
    const startRef1 = React.useRef();
    const startRef2 = React.useRef();
    const startRef3 = React.useRef();
    const startRef4 = React.useRef();

    const onKeyDown = (e) => {
        if (e.target.id === 'ReportedDate') {
            e.preventDefault();
        } else if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
            startRef2.current.setOpen(false);
            startRef3.current.setOpen(false);
            startRef4.current.setOpen(false);

        }
    };

    const setStatusFalse = () => {
        navigate(`/Missing-Vehicle-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&MissPerID=${MissPerId}&MissPerSta=${true}&MissVehID=${''}&MissVehSta=${false}`)
        setMissingVehicleID('')
        reset()
        setChangesStatus(false)
    }


    const colourStyles = {
        control: (styles) => ({
            ...styles, backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 35,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    }

    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 20,
            minHeight: 35,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };


    return (
        <>
            <div className="section-body view_page_design pt-1 p-1 bt" >
                <div className="col-12  inc__tabs">
                    <MissingTab />
                </div>
                <div className="dark-row" >
                    <div className="col-12 col-sm-12">
                        <div className="card Agency incident-card ">
                            <div className="card-body" >
                                <fieldset>
                                    <legend>Missing Person vehicle</legend>
                                    <div className="col-12 ">
                                        <div className="row">
                                            <div className="col-2 col-md-2 col-lg-1 mt-2 ">
                                                <label htmlFor="" className='new-label'>Missing&nbsp;Vehicle</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-7  mt-1">
                                                <Select
                                                    name='VehicleID'
                                                    styles={colourStyles}
                                                    value={vehicleData?.filter((obj) => obj.value === value?.VehicleID)}
                                                    onChange={(e) => ChangeDropDown(e, 'VehicleID')}
                                                    options={vehicleData}
                                                    isClearable
                                                    placeholder="Select..."
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 ">
                                        <div className="row">
                                            <div className="col-2 col-md-2 col-lg-2 mt-2 " style={{ display: "none" }}>
                                                <label htmlFor="" className='new-label'>Loss Code{errors.LossCodeIDError !== 'true' ? (
                                                    <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.LossCodeIDError}</p>
                                                ) : null}</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3 mt-1" style={{ display: "none" }}>
                                                <Select
                                                    name='LossCodeID'
                                                    value={propertyLossCodeData?.filter((obj) => obj.value === value?.LossCodeID)}
                                                    styles={colourStyles}
                                                    options={propertyLossCodeData}
                                                    // onChange={(e) => ChangePhoneType(e, 'LossCodeID')}
                                                    isClearable
                                                    placeholder="Select..."
                                                />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 mt-2 ">
                                                <label htmlFor="" className='new-label'> Category {errors.CategoryIDError !== 'true' ? (
                                                    <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.CategoryIDError}</p>
                                                ) : null}</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-2  mt-1">
                                                <Select
                                                    name='CategoryID'
                                                    value={categoryIdDrp?.filter((obj) => obj.value === value?.CategoryID)}
                                                    styles={colourStyles}
                                                    options={categoryIdDrp}
                                                    onChange={(e) => ChangeDropDown(e, 'CategoryID')}
                                                    isClearable
                                                    placeholder="Select..."
                                                />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'> Classification</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3 mt-1">
                                                <Select
                                                    name='ClassificationID'
                                                    value={classificationID?.filter((obj) => obj.value === value?.ClassificationID)}
                                                    styles={customStylesWithOutColor}
                                                    options={classificationID}
                                                    onChange={(e) => ChangeDropDown(e, 'ClassificationID')}
                                                    isClearable
                                                    placeholder="Select..."
                                                />
                                            </div>

                                            <div className="col-12 col-md-12 col-lg-4 d-flex ">
                                                <div className="col-3 col-md-2 col-lg-5 mt-2 pt-1 ">
                                                    <label htmlFor="" className='new-label '>Plate&nbsp;State&nbsp;&&nbsp;No.</label>
                                                </div>
                                                <div className="col-4 col-md-6 col-lg-4 mt-1" >
                                                    <Select
                                                        name='PlateID'
                                                        value={stateList?.filter((obj) => obj.value === value?.PlateID)}
                                                        styles={customStylesWithOutColor}
                                                        options={stateList}
                                                        onChange={(e) => ChangeDropDown(e, 'PlateID')}
                                                        isClearable
                                                        placeholder="Select..."
                                                    />
                                                </div>
                                                <span className='' style={{ marginLeft: '-10px', marginTop: '-5px' }}>
                                                    <div className="text-field col-12 col-md-12 col-lg-12 ">
                                                        <input className={`${value.PlateID ? "requiredColor" : ''}`} type="text" name='VehicleNo' id='VehicleNo' maxLength={8} value={value?.VehicleNo} onChange={HandleChanges} required placeholder='Number..' autoComplete='off' />
                                                    </div>
                                                    {errors.VehicleNoError !== 'true' && value.PlateID ? (
                                                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.VehicleNoError}</p>
                                                    ) : null}
                                                </span>
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 mt-2 ">
                                                {/* <label htmlFor="" className='new-label'>Plate Type{errors.PlateTypeIDError !== 'true' ? (
                                                    <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.PlateTypeIDError}</p>
                                                ) : null}</label> */}
                                                <span data-toggle="modal" data-target="#ListModel" className='new-link ' onClick={() => { setOpenPage('Property Vehicle Plate Type') }}>
                                                    Plate Type{errors.PlateTypeIDError !== 'true' ? (
                                                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.PlateTypeIDError}</p>
                                                    ) : null}
                                                </span>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-2 mt-1 ">
                                                <Select
                                                    name='PlateTypeID'
                                                    value={plateTypeIdDrp?.filter((obj) => obj.value === value?.PlateTypeID)}
                                                    styles={colourStyles}
                                                    options={plateTypeIdDrp}
                                                    onChange={(e) => ChangeDropDown(e, 'PlateTypeID')}
                                                    isClearable
                                                    placeholder="Select..."
                                                />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>VIN {errors.vinLengthError !== 'true' ? (
                                                    <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.vinLengthError}</p>
                                                ) : null}</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3 mt-1 text-field d-flex">
                                                <input type="text" name='VIN' id='VIN' maxLength={17} value={value?.VIN} onChange={HandleChanges} className='' required autoComplete='off' />
                                                <span className='mt-1 '>
                                                    <span className='  col-1 col-md-1 col-lg-1'>
                                                        <i className='fa fa-search btn btn-sm bg-green text-white px-2 py-1'></i>
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 mt-2 ">
                                                {/* <label htmlFor="" className='new-label'>VOD </label> */}
                                                <span data-toggle="modal" data-target="#ListModel" className='new-link ' onClick={() => { setOpenPage('Vehicle VOD') }}>
                                                    VOD
                                                </span>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3 pt-1">
                                                <Select
                                                    name='VODID'
                                                    value={vodIdData?.filter((obj) => obj.value === value?.VODID)}
                                                    styles={customStylesWithOutColor}
                                                    options={vodIdData}
                                                    onChange={(e) => ChangeDropDown(e, 'VODID')}
                                                    isClearable
                                                    placeholder="Select..."
                                                />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 mt-2 ">
                                                <label htmlFor="" className='new-label'>Plate Expires</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-2  ">
                                                <DatePicker
                                                    id='PlateExpireDtTm'
                                                    name='PlateExpireDtTm'
                                                    ref={startRef1}
                                                    // onKeyDown={onKeyDown}
                                                    onKeyDown={(e) => {
                                                        if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ')) {
                                                            e?.preventDefault();
                                                        } else {
                                                            onKeyDown(e);
                                                        }
                                                    }}
                                                    onChange={(date) => { setPlateExpDate(date); setValue({ ...value, ['PlateExpireDtTm']: date ? getShowingMonthDateYear(date) : null }) }}
                                                    dateFormat="MM/dd/yyyy"
                                                    isClearable
                                                    selected={plateExpDate}
                                                    placeholderText={value?.PlateExpireDtTm ? value?.PlateExpireDtTm : 'Select...'}
                                                    autoComplete="off"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    dropdownMode="select"
                                                />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>OAN Id</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3 mt-1 text-field ">
                                                <input type="text" name='OANID' id='OANID' value={value?.OANID} onChange={HandleChanges} className='' required maxLength={20} autoComplete='off' />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 mt-2 ">
                                                {/* <label htmlFor="" className='new-label'>Style</label> */}
                                                <span data-toggle="modal" data-target="#ListModel" className='new-link ' onClick={() => { setOpenPage('Property Vehicle Style') }}>
                                                    Style
                                                </span>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3 mt-1 text-field ">
                                                <Select
                                                    name='StyleID'
                                                    value={styleIdDrp?.filter((obj) => obj.value === value?.StyleID)}
                                                    styles={customStylesWithOutColor}
                                                    options={styleIdDrp}
                                                    onChange={(e) => ChangeDropDown(e, 'StyleID')}
                                                    isClearable
                                                    placeholder="Select..."
                                                />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 mt-2 ">
                                                <label htmlFor="" className='new-label'>Owner</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-2 mt-1 ">
                                                <Select
                                                    name='OwnerID'
                                                    value={arresteeDrpData?.filter((obj) => obj.value === value?.OwnerID)}
                                                    styles={customStylesWithOutColor}
                                                    options={arresteeDrpData}
                                                    onChange={(e) => ChangeDropDown(e, 'OwnerID')}
                                                    isClearable
                                                    placeholder="Select..."
                                                />
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-1   px-0" style={{ marginTop: '8px' }}>
                                                <button
                                                    onClick={() => {
                                                        if (possessionID) { GetSingleDataPassion(possessionID); }
                                                        setNameModalStatus(true);
                                                    }}
                                                    className="btn btn-sm bg-green text-white py-0" data-toggle="modal" data-target="#MasterModal">
                                                    <i className="fa fa-plus"></i>
                                                </button>
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 mt-2 ">
                                                {/* <label htmlFor="" className='new-label'>Make</label> */}
                                                <span data-toggle="modal" data-target="#ListModel" className='new-link ' onClick={() => { setOpenPage('Property Vehicle Make') }}>
                                                    Make
                                                </span>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3 mt-1 ">
                                                <Select
                                                    name='MakeID'
                                                    value={makeIdDrp?.filter((obj) => obj.value === value?.MakeID)}
                                                    styles={customStylesWithOutColor}
                                                    options={makeIdDrp}
                                                    onChange={(e) => ChangeDropDown(e, 'MakeID')}
                                                    isClearable
                                                    placeholder="Select..."
                                                />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 mt-2 ">
                                                <label htmlFor="" className='new-label'>Model</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3 mt-1 ">
                                                <Select
                                                    name='ModelID'
                                                    value={modalIdDrp?.filter((obj) => obj.value === value?.ModelID)}
                                                    styles={customStylesWithOutColor}
                                                    options={modalIdDrp}
                                                    onChange={(e) => ChangeDropDown(e, 'ModelID')}
                                                    isClearable
                                                    placeholder="Select..."
                                                />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 mt-2 ">
                                                <label htmlFor="" className='new-label'>Weight</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-2 mt-1 text-field">
                                                <input type="text" name='Weight' id='Weight' maxLength={4} value={value?.Weight} onChange={HandleChanges} className='' required autoComplete='off' />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-2 mt-2 ">
                                                {/* <label htmlFor="" className='new-label'>Primary Color</label> */}
                                                <span data-toggle="modal" data-target="#ListModel" className='new-link ' onClick={() => { setOpenPage('Color') }}>
                                                    Primary Color
                                                </span>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3 mt-1 ">
                                                <Select
                                                    name='PrimaryColorID'
                                                    value={colorDrp?.filter((obj) => obj.value === value?.PrimaryColorID)}
                                                    styles={customStylesWithOutColor}
                                                    options={colorDrp}
                                                    onChange={(e) => ChangeDropDown(e, 'PrimaryColorID')}
                                                    isClearable
                                                    placeholder="Select..."
                                                />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-2 mt-2 ">
                                                {/* <label htmlFor="" className='new-label'>Secondary Color</label> */}
                                                <span data-toggle="modal" data-target="#ListModel" className='new-link ' onClick={() => { setOpenPage('Color') }}>
                                                    Secondary Color
                                                </span>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-2 mt-1">
                                                <Select
                                                    name='SecondaryColorID'
                                                    value={colorDrp?.filter((obj) => obj.value === value?.SecondaryColorID)}
                                                    styles={customStylesWithOutColor}
                                                    options={colorDrp}
                                                    onChange={(e) => ChangeDropDown(e, 'SecondaryColorID')}
                                                    isClearable
                                                    placeholder="Select..."
                                                />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 mt-2 ">
                                                <label htmlFor="" className='new-label'>Value {errors.ContactError !== 'true' ? (
                                                    <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.ContactError}</p>
                                                ) : null}</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-2 mt-1 text-field">
                                                <input
                                                    type="text"
                                                    name="Value"
                                                    id="Value"
                                                    className={lossCode === 'STOL' || lossCode === 'BURN' || lossCode === 'RECD' ? 'requiredColor' : ''}
                                                    maxLength={20}
                                                    value={`$ ${value?.Value}`}
                                                    onChange={HandleChanges}
                                                    required
                                                    autoComplete="off"
                                                />
                                            </div>

                                            <div className="col-2 col-md-2 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Mfg. Year</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-1 ">
                                                <DatePicker
                                                    name='ManufactureYear'
                                                    id='ManufactureYear'
                                                    selected={manufactureDate}
                                                    onChange={(date) => { setManufactureDate(date); setValue({ ...value, ['ManufactureYear']: date ? getYearWithOutDateTime(date) : null }) }}
                                                    showYearPicker
                                                    dateFormat="yyyy"
                                                    yearItemNumber={9}
                                                    ref={startRef2}
                                                    onKeyDown={onKeyDown}
                                                    autoComplete="off"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    dropdownMode="select"
                                                    maxDate={new Date()}
                                                />
                                            </div>
                                            <div className=" col-4 col-md-4 col-lg-1 pt-1 mt-3">
                                                <div className="img-box" style={{ marginTop: '-18px' }}>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 text-right  p-0 field-button" style={{ position: 'absolute', bottom: '10px', textAlign: 'right', right: "1rem" }}>
                                        <button type="button" className="btn btn-sm btn-success mr-1" onClick={setStatusFalse} >New</button>
                                        <button type="button" className="btn btn-sm btn-success  mr-1" onClick={(e) => { check_Validation_Error(); }}>{missingVehicleID && (MissVehSta === true || MissVehSta || 'true') ? 'Update' : 'Save'}</button>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <ChangesModal func={check_Validation_Error} />
            <ListModal {...{ openPage, setOpenPage }} />
            <MasterNameModel {...{ value, setValue, nameModalStatus, setNameModalStatus, loginPinID, loginAgencyID, type, possenSinglData, setPossessionID, possessionID, setPossenSinglData, GetSingleDataPassion }} />

        </>
    )
}

export default MissingPersonVehicle
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { fetchPostData, AddDeleteUpadate, fetchData } from '../../../../hooks/Api';
import { Decrypt_Id_Name, getShowingWithOutTime, tableCustomStyles } from '../../../../Common/Utility';
import { toastifySuccess } from '../../../../Common/AlertMsg';
import DeletePopUpModal from '../../../../Common/DeleteModal';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import { RequiredFieldSpaceNotAllow } from '../../../Agency/AgencyValidation/validators';
import { RequiredFieldIncident, Space_Not_Allow } from '../../../Utility/Personnel/Validation';
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Comman_changeArrayFormat, Comman_changeArrayFormat_With_Name } from '../../../../Common/ChangeArrayFormat';
import IdentifyFieldColor from '../../../../Common/IdentifyFieldColor';
import { useDispatch, useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../../redux/api';
import NameListing from '../../../ShowAllList/NameListing';
import ListModal from '../../../Utility/ListManagementModel/ListModal';
import { get_ScreenPermissions_Data } from '../../../../../redux/actions/IncidentAction';
import ChangesModal from '../../../../Common/ChangesModal';

const Identification = (props) => {


  const { ListData, DecNameID, DecMasterNameID, DecIncID } = props

  const dispatch = useDispatch();
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
  const effectiveScreenPermission = useSelector((state) => state.Incident.effectiveScreenPermission);

  useEffect(() => {
    if (localStoreData) {
      setLoginAgencyID(localStoreData?.AgencyID); setLoginPinID(localStoreData?.PINID);
      dispatch(get_ScreenPermissions_Data("N051", localStoreData?.AgencyID, localStoreData?.PINID));
    }
  }, [localStoreData]);

  useEffect(() => {
    if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
      if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
    }
  }, []);


  const { get_Name_Count, localStoreArray, get_LocalStorage, setChangesStatus } = useContext(AgencyContext)
  const useQuery = () => new URLSearchParams(useLocation().search);
  let MstPage = useQuery().get('page');
  const [clickedRow, setClickedRow] = useState(null);

  const [identificationData, setIdentificationData] = useState();
  const [status, setStatus] = useState(false);
  const [modal, setModal] = useState(false)
  const [updateStatus, setUpdateStatus] = useState(0)
  const [identificationNumberID, setIdentificationNumberID] = useState('');

  const [loginAgencyID, setLoginAgencyID] = useState('');
  const [loginPinID, setLoginPinID,] = useState('');
  const [masterNameID, setMasterNameID,] = useState('');
  const [nameID, setNameID] = useState();

  const [identification, setIdentification] = useState([]);
  const [editval, setEditval] = useState();
  const [identificationDate, setIdentificationDate] = useState();
  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [isCountrySelected, setIsCountrySelected] = useState(false);
  const [selected, setSelected] = useState(false)
  const [openPage, setOpenPage] = useState('');
  const [maxIdNumberLength, setMaxIdNumberLength] = useState(9);
  const [isTypeOne, setIsTypeOne] = useState(false);


  const localStore = {
    Value: "",
    UniqueId: sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '',
    Key: JSON.stringify({ AgencyID: "", PINID: "", MasterNameID: '', NameID: '', Agency_Name: "", }),
  }

  useEffect(() => {
    if (!localStoreArray.AgencyID || !localStoreArray.PINID) {
      get_LocalStorage(localStore);
    }
  }, []);

  const [value, setValue] = useState({
    'IdentificationTypeID': null, 'StateID': null, 'CountryID': null, 'IdentificationNumber': '', 'IsCurrent': "", 'ExpiryDate': "", 'IdentificationNumberID': '',
    'NameID': '', 'MasterNameID': '', 'CreatedByUserFK': '',
    'IsMaster': MstPage === "MST-Name-Dash" ? true : false,
  })

  // useEffect(() => {
  //   if (localStoreArray) {
  //     if (localStoreArray?.AgencyID && localStoreArray?.PINID) {
  //       setLoginAgencyID(localStoreArray?.AgencyID);
  //       setLoginPinID(parseInt(localStoreArray?.PINID));
  //       setNameID(localStoreArray?.NameID); setMasterNameID(localStoreArray?.MasterNameID);
  //       Get_IdentificationData(localStoreArray?.NameID, localStoreArray?.MasterNameID);
  //     }
  //   }
  // }, [localStoreArray])


  useEffect(() => {
    if (DecNameID || DecMasterNameID) {
      setValue(pre => { return { ...pre, 'CreatedByUserFK': loginPinID, 'MasterNameID': DecMasterNameID, 'NameID': DecNameID } });
      Get_IdentificationData(DecNameID, DecMasterNameID);
    }
  }, [DecNameID, DecMasterNameID, loginPinID]);


  const [errors, setErrors] = useState({
    'IdentificationTypeIDErrors': '', 'IdentificationNumberErrors': '', 'StateIDErrors': '', 'CountryIDErrors': '',
  })

  // useEffect(() => {
  //   setValue(pre => { return { ...pre, 'CreatedByUserFK': loginPinID, 'MasterNameID': masterNameID, 'NameID': nameID } });
  // }, [nameID, masterNameID, updateStatus]);



  useEffect(() => {
    if (identificationNumberID) {
      GetSingleData(identificationNumberID)
    }
  }, [updateStatus, identificationNumberID])

  const GetSingleData = (identificationNumberID) => {
    const val = { 'IdentificationNumberID': identificationNumberID }
    fetchPostData('NameIdentificationNumber/GetSingleData_NameIdentificationNumber', val)
      .then((res) => {
        if (res) { setEditval(res) }
        else { setEditval() }
      })
  }

  useEffect(() => {
    if (status) {
      setValue({
        ...value,
        'IdentificationNumberID': identificationNumberID, 'IdentificationTypeID': editval[0]?.IdentificationTypeID, 'IsCurrent': editval[0]?.IsCurrent,
        'ExpiryDate': editval[0]?.ExpiryDate ? getShowingWithOutTime(editval[0]?.ExpiryDate) : '', "IdentificationNumber": editval[0]?.IdentificationNumber,
        'StateID': editval[0]?.StateID, 'CountryID': editval[0]?.CountryID ? editval[0]?.CountryID : 20001, 'ModifiedByUserFK': loginPinID,
      })
    }
    else {
      setValue({
        ...value,
        'IdentificationTypeID': null, 'IdentificationNumber': '', 'IsCurrent': "", 'StateID': null, 'CountryID': null, 'ExpiryDate': "", 'ModifiedByUserFK': '',
      })
    }
  }, [editval])

  const reset = () => {
    setValue({
      ...value,
      'IdentificationTypeID': '', 'IdentificationNumber': '', 'IsCurrent': "", 'StateID': "", 'CountryID': "", 'ExpiryDate': "",
    });
    setIdentificationDate('')
    setErrors({
      'IdentificationTypeIDErrors': '', 'IdentificationNumberErrors': '',
    })
  }
  const check_Validation_Error = () => {
    if (Space_Not_Allow(value.IdentificationNumber)) {
      setErrors(prevValues => { return { ...prevValues, ['IdentificationNumberErrors']: Space_Not_Allow(value.IdentificationNumber) } })
    }
    if (RequiredFieldIncident(value.IdentificationTypeID)) {
      setErrors(prevValues => { return { ...prevValues, ['IdentificationTypeIDErrors']: RequiredFieldIncident(value.IdentificationTypeID) } })
    }
    if (selected) {
      if (RequiredFieldIncident(value.IdentificationTypeID)) {
        setErrors(prevValues => { return { ...prevValues, ['StateIDErrors']: RequiredFieldIncident(value.StateID) } });
      }
      if (RequiredFieldIncident(value.CountryID)) {
        setErrors(prevValues => ({ ...prevValues, ['CountryIDErrors']: RequiredFieldIncident(value.CountryID) }));
      }
    }

  }

  // Check All Field Format is True Then Submit 
  const { IdentificationNumberErrors, IdentificationTypeIDErrors, StateIDErrors } = errors

  useEffect(() => {
    if (selected) {
      if (IdentificationNumberErrors === 'true' && IdentificationTypeIDErrors === 'true' && StateIDErrors === 'true') {
        if (status) { update_Identification(); }
        else { Add_Type(); }
      }
    }
    else {
      if (IdentificationNumberErrors === 'true' && IdentificationTypeIDErrors === 'true') {
        if (status) { update_Identification(); }
        else { Add_Type(); }
      }
    }

  }, [IdentificationNumberErrors, IdentificationTypeIDErrors, StateIDErrors, status]);

  useEffect(() => {
    if (openPage || loginAgencyID) {
      get_Identification(loginAgencyID);
    }
    getCountryID(); getStateList();
  }, [openPage, loginAgencyID])

  const get_Identification = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID,
    }
    fetchPostData('IDTypes/GetDataDropDown_IDTypes', val).then((data) => {
      if (data) {
        setIdentification(Comman_changeArrayFormat(data, 'IDTypeID', 'Description'))
      } else { setIdentification([]); }
    })
  }

  const getStateList = async () => {
    fetchData("State_City_ZipCode/GetData_State").then((data) => {
      if (data) {
        setStateList(Comman_changeArrayFormat_With_Name(data, "StateID", "StateName", "StateID"));
      } else { setStateList([]); }
    });
  };


  const getCountryID = async () => {
    const val = { 'IsUSCitizen': true, };
    fetchPostData("State_City_ZipCode/GetData_Country", val).then((data) => {
      if (data) {
        setCountryList(Comman_changeArrayFormat_With_Name(data, "CountryID", "CountryName", "CountryID"));
      } else { setCountryList([]); }
    });
  };

  const selectHandleChange = (newValue, name) => {
    if (name === 'StateID') { setErrors(prevErrors => ({ ...prevErrors, StateIDErrors: '' })); }
    if (newValue) {
      setValue({ ...value, [name]: newValue.value });
      setChangesStatus(true)
    } else {
      setValue({ ...value, [name]: null });
      setChangesStatus(true)
    }
  }


  // const handleChange = (e) => {
  //   if (e.target.name === "IsCurrent") {
  //     setValue({ ...value, [e.target.name]: e.target.checked, });
  //     setChangesStatus(true)
  //   } else {
  //     setValue({ ...value, [e.target.name]: e.target.value, });
  //     setChangesStatus(true)
  //   }
  // };

  const handleChange = (e) => {
    if (e.target.name === 'IsCurrent') {
      setValue({ ...value, [e.target.name]: e.target.checked });
      setChangesStatus(true);
    } else if (e.target.name === 'IdentificationNumber') {
      if (isTypeOne) {
        let ele = e.target.value.replace(/\D/g, '');
        if (ele.length === 9) {
          const cleaned = ('' + ele).replace(/\D/g, '');
          const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/); 
          if (match) {
            console.log(match[1] ,'match');
            setChangesStatus(true);
            setValue({
              ...value,
              [e.target.name]: match[1] + '-' + match[2] + '-' + match[3]
            });  
          }
        } else {
          ele = e.target.value.split('-').join('').replace(/\D/g, '');
          setChangesStatus(true);
          setValue({
            ...value,
            [e.target.name]: ele
          });
        }
      } else {
        const cleanedValue = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
        setValue({ ...value, [e.target.name]: cleanedValue });
        setChangesStatus(true);
      }
    } else {
      setValue({ ...value, [e.target.name]: e.target.value });
      setChangesStatus(true);
    }
  };
  

  
  const startRef = React.useRef();
  const onKeyDown = (e) => {
    if (e.keyCode === 9 || e.which === 9) {
      startRef.current.setOpen(false);
    }
  };

  const Add_Type = () => {
    AddDeleteUpadate(MstPage === 'mastername' ? 'MainMasterNameIdentificationNumber/Insert_MainMasterNameIdentificationNumber' : 'NameIdentificationNumber/Insert_NameIdentificationNumber', value)
      .then((res) => {
        const parsedData = JSON.parse(res.data);
        const message = parsedData.Table[0].Message;
        setChangesStatus(false);
        toastifySuccess(message);
        Get_IdentificationData(DecNameID, DecMasterNameID);
        get_Name_Count(DecNameID,DecMasterNameID,);
        setModal(false)
        reset(); setErrors({ ...errors, 'IdentificationTypeIDErrors': '', })
      })
  }

  const update_Identification = () => {
    AddDeleteUpadate('NameIdentificationNumber/Update_NameIdentificationNumber', value).then((res) => {
      const parsedData = JSON.parse(res.data);
      const message = parsedData.Table[0].Message;
      setChangesStatus(false);
      toastifySuccess(message);
      setModal(false);
      Get_IdentificationData(DecNameID, DecMasterNameID);
      setErrors({ ...errors, 'IdentificationTypeIDErrors': '', })
      reset();
      setStatus(false);
    })
  }

  const ChangeDropDown = (newValue, name) => {
    console.log(newValue, 'newvalue');
    if (newValue) {
      setChangesStatus(true)
      if (name == 'IdentificationTypeID') {
        if (newValue.value == '2') {
          setSelected(true);
        }
        else { setSelected(false); }
        if (newValue.value == '1') {
          setMaxIdNumberLength(9)
          setIsTypeOne(true); 
        }
        else {
          setMaxIdNumberLength(25)
          setIsTypeOne(false); 
        }
      }
      if (newValue) {
        setValue({ ...value, [name]: newValue.value });
      } else {
        setValue({ ...value, [name]: null });
      }
    }
    else {
      setValue({ ...value, [name]: null });
      setSelected(false);
      setErrors({
        'StateIDErrors': '', 'CountryIDErrors': '',
      })
      setChangesStatus(true)
    }

  }

  const closeModal = () => {
    reset();
    setModal(false);
  }

  const colourStyles = {
    control: (styles) => ({
      ...styles, backgroundColor: "#fce9bf",
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  }

  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };
  const Get_IdentificationData = (DecNameID, DecMasterNameID) => {
    const val = { NameID: DecNameID, MasterNameID: DecMasterNameID, }
    const val2 = { MasterNameID: DecMasterNameID, NameID: 0, 'IsMaster': MstPage === "MST-Name-Dash" ? true : false, }
    fetchPostData('NameIdentificationNumber/GetData_NameIdentificationNumber', MstPage ? val2 : val).then((res) => {
      if (res) {
        setIdentificationData(res)
      } else {
        setIdentificationData([]);
      }
    })
  }

  const columns = [
    // {
    //   width: '120px',
    //   name: <p className='text-end' style={{ position: 'absolute', top: 8, }}>Action</p>,
    //   cell: row =>
    //     <div className="div" style={{ position: 'absolute', top: 4, left: 20 }}>

    //       <Link to={openPage === 'mastername' ? '/nametab?page=mastername' : '/nametab'} onClick={(e) => { set_Edit_Value(e, row) }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#PinModal" >
    //         <i className="fa fa-edit"></i></Link>

    //     </div>

    // },
    {
      width: '250px',
      name: 'Identification Type',
      selector: (row) => row.IdType_Description,
      sortable: true
    },
    {
      name: 'ID Number',
      selector: (row) => row.IdentificationNumber || row.DLIdentificationNumber,
      sortable: true
    },

    {
      name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 10 }}>Delete</p>,
      cell: row =>
        <div className="div" style={{ position: 'absolute', top: 4, right: 10 }}>

          {/* <span onClick={() => { setIdentificationNumberID(row.IdentificationNumberID); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
            <i className="fa fa-trash"></i>
          </span> */}

          {
            effectiveScreenPermission ?
              effectiveScreenPermission[0]?.DeleteOK ?
                <span onClick={() => { setIdentificationNumberID(row.IdentificationNumberID); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                  <i className="fa fa-trash"></i>
                </span>
                : <></>
              : <span onClick={() => { setIdentificationNumberID(row.IdentificationNumberID); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash"></i>
              </span>
          }

        </div>

    }
  ]

  const set_Edit_Value = (row) => {
    reset();
    setStatus(true);
    setModal(true);
    GetSingleData(row.IdentificationNumberID)
    setUpdateStatus(updateStatus + 1);
    setIdentificationNumberID(row.IdentificationNumberID);
  }

  const DeleteIdentification = () => {
    const val = {
      'IdentificationNumberID': identificationNumberID,
      'DeletedByUserFK': loginPinID,
    }
    AddDeleteUpadate('NameIdentificationNumber/Delete_NameIdentificationNumber', val).then((res) => {
      if (res) {
        toastifySuccess(res.Message);

        Get_IdentificationData(DecNameID, DecMasterNameID);
        get_Name_Count(DecNameID,DecMasterNameID);
        setStatus(false);
        reset();
      } else console.log("Somthing Wrong");
    })
  }

  const setStatusFalse = (e) => {
    setClickedRow(null); reset();
    setStatus(false)
    setSelected(false);

    setUpdateStatus(updateStatus + 1);

  }


  const conditionalRowStyles = [
    {
      when: row => row === clickedRow,
      style: {
        backgroundColor: '#001f3fbd',
        color: 'white',
        cursor: 'pointer',
      },
    },
  ];
  return (
    <>
      <NameListing  {...{ ListData }} />
      <div className="col-md-12 mt-2">
        {/* <div className="bg-line text-white py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
          <p className="p-0 m-0 d-flex align-items-center">
            Identification
          </p>
          <div>
            <Link to={openPage === 'mastername' ? '/nametab?page=mastername' : '/nametab'} className="btn btn-sm bg-green text-white px-2 py-0" onClick={setStatusFalse}
              data-toggle="modal" data-target="#PinModal" style={{ marginTop: '-7px' }}>
              <i className="fa fa-plus"></i>
            </Link>

            <FindListDropDown
              array={NaIdentificationListDropDownArray}
            />
          </div>
        </div> */}
        <div className="row">
          <div className="col-3 col-md-3 col-lg-2 mt-3">
            {/* <Link to={'/ListManagement?page=ID%20Types&call=/Name-Home'} className='new-link'>
              Identification Type {errors.IdentificationTypeIDErrors !== 'true' ? (
                <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.IdentificationTypeIDErrors}</p>
              ) : null}
            </Link> */}
            <span data-toggle="modal" onClick={() => {
              setOpenPage('ID Types')
            }} data-target="#ListModel" className='new-link'>
              Identification Type {errors.IdentificationTypeIDErrors !== 'true' ? (
                <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.IdentificationTypeIDErrors}</p>
              ) : null}
            </span>
          </div>
          <div className="col-4 col-md-4 col-lg-3  mt-2" >
            <Select
              name='IdentificationTypeID'
              styles={colourStyles}
              value={identification?.filter((obj) => obj.value === value?.IdentificationTypeID)}
              isClearable
              options={identification}
              onChange={(e) => {
                ChangeDropDown(e, 'IdentificationTypeID');
              }}
              placeholder="Select..."
            />
          </div>
          <div className="col-3 col-md-3 col-lg-2 mt-3">
            <label htmlFor="" className='label-name '>Identification Number{errors.IdentificationNumberErrors !== 'true' ? (
              <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.IdentificationNumberErrors}</p>
            ) : null}
            </label>
          </div>
          <div className="col-4 col-md-4 col-lg-2 text-field mt-2" >
            <input type="text" style={{ textTransform: "uppercase" }} value={
              value?.IdentificationNumber
            } maxLength={maxIdNumberLength} onChange={handleChange} className='requiredColor' name='IdentificationNumber' required autoComplete='off' />
          </div>
          <div className="col-3 col-md-3 col-lg-1 mt-3">
            <label htmlFor="" className='label-name '>ID Expiry</label>
          </div>
          <div className="col-4 col-md-4 col-lg-2 " >
            <DatePicker
              ref={startRef}
              // onKeyDown={onKeyDown}
              onKeyDown={(e) => {
                if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ')) {
                  e.preventDefault();
                } else {
                  onKeyDown(e);
                }}}
              id='ExpiryDate'
              name='ExpiryDate'
              dateFormat="MM/dd/yyyy"
              onChange={(date) => { setIdentificationDate(date); setValue({ ...value, ['ExpiryDate']: date ? getShowingWithOutTime(date) : null }) }}
              showMonthDropdown
              isClearable={value?.ExpiryDate ? true : false}
              autoComplete="off"
              showDisabledMonthNavigation
              dropdownMode="select"
              showYearDropdown
              placeholderText={value?.ExpiryDate ? value?.ExpiryDate : 'Select...'}
              selected={identificationDate}

            />
          </div>
          <div className="col-3 col-md-3 col-lg-2 mt-3">
            <label htmlFor="" className='label-name '>Country{errors.CountryIDErrors !== 'true' && errors.CountryIDErrors ? (
              <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.CountryIDErrors}</p>
            ) : null}</label>
          </div>
          <div className="col-4 col-md-4 col-lg-3  mt-2" >
            <Select
              name="CountryID"
              value={countryList?.filter((obj) => obj.value === value?.CountryID)}
              isClearable
              options={countryList}
              onChange={(newValue) => {
                selectHandleChange(newValue, 'CountryID');
                ChangeDropDown(newValue, 'CountryID');
                setIsCountrySelected(!!newValue);
              }}
              placeholder="Select..."

              styles={value.IdentificationTypeID === 2 ? colourStyles : customStylesWithOutColor}
            />
          </div>
          <div className="col-3 col-md-3 col-lg-2 mt-3">
            <label htmlFor="" className='label-name '>State{errors.StateIDErrors !== 'true' ? (
              <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.StateIDErrors}</p>
            ) : null}</label>
          </div>
          <div className="col-4 col-md-4 col-lg-4  mt-2" >
            <Select
              name="StateID"
              value={value?.CountryID ? (value.StateID ? stateList.find(obj => obj.value === value.StateID) : null) : null}
              isClearable
              options={stateList}
              onChange={(newValue) => {
                selectHandleChange(newValue, 'StateID');
                ChangeDropDown(newValue, 'StateID');
              }}
              placeholder="Select..."
              styles={value.IdentificationTypeID === 2 ? colourStyles : customStylesWithOutColor}

              isDisabled={value?.CountryID ? false : true}
            />


          </div>
        </div>
        <div className="btn-box text-right mt-3 mr-1 mb-2">
          <button type="button" className="btn btn-sm btn-success mr-1 " onClick={() => { setStatusFalse(); conditionalRowStyles(''); setUpdateStatus(updateStatus + 1); }}>New</button>
          {/* {
            status ?
              <button type="button" onClick={(e) => { check_Validation_Error(); }} className="btn btn-sm btn-success mr-1">Update</button>
              :
              <button type="button" onClick={(e) => { check_Validation_Error(); }} className="btn btn-sm btn-success mr-1">Save</button>
          } */}

          {
            status ?
              effectiveScreenPermission ?
                effectiveScreenPermission[0]?.Changeok ?
                  <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }}>Update</button>
                  :
                  <>
                  </>
                :
                <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }}>Update</button>
              :
              effectiveScreenPermission ?
                effectiveScreenPermission[0]?.AddOK ?
                  <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }}>Save</button>
                  :
                  <>
                  </>
                :
                <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }}>Save</button>
          }



        </div>
        <DataTable
          dense
          columns={columns}
          // data={identificationData}
          data={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? identificationData : [] : identificationData}
          pagination
          highlightOnHover
          // noDataComponent={"There are no data to display"}
          customStyles={tableCustomStyles}
          onRowClicked={(row) => {
            setClickedRow(row);
            set_Edit_Value(row);
          }}
          fixedHeader
          persistTableHead={true}
          fixedHeaderScrollHeight='200px'
          conditionalRowStyles={conditionalRowStyles}
          noDataComponent={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? "There are no data to display" : "You don’t have permission to view data" : 'There are no data to display'}
        />
      </div>
      <DeletePopUpModal func={DeleteIdentification} />
      <IdentifyFieldColor />
      <ListModal {...{ openPage, setOpenPage }} />
      <ChangesModal func={check_Validation_Error} setToReset={setStatusFalse} />
    </>
  )
}

export default Identification; 
import axios from "axios";

// const googleAddKey = process.env.REACT_APP_GOOGLE_ADD_API_KEY;
const googleAddKey = "AIzaSyBBfoUsiF8ZX5iopPppB9x5XRriRaALhiY";
// const REACT_APP_GOOGLE_ADD_API_KEY = "AIzaSyBEhZNFF92eTAqWeQ2ZCaITiWeaFrWlf1w"


export async function SplitAddress(Add) {
    try {
        const myHeaders = new Headers();

        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "address": {
                "regionCode": "US",
                "addressLines": [Add]
            },
            "enableUspsCass": true
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
            // types: 'geocode' 
        };

        const res = await fetch(`https://addressvalidation.googleapis.com/v1:validateAddress?key=${googleAddKey}`, requestOptions);
        // console.log(res)
        if (!res.ok) {
            throw Error(res.statusText);
        }
        const data = await res.json();
        // console.log(data?.result);
        const newData = data?.result
        return newData
    } catch (error) {
        console.log(error)
    }
}


// export async function SplitAddress(Add) {
//     try {
//         const url = 'https://loqate-address.p.rapidapi.com/rest/?lqtkey=14dea8c6fggh51yt7b1c8d477994542b7f115009&p=v%2Bg&addr=1111%20bayhill%20dr%20san%20bruno%20USA&add1=1111%20bayhill%20dr&add2=Suite%20290&add3=San%20Bruno&add4=CA&add5=94066&add6=USA&ctry=United%20States&fs=no&outputfields=Address1%2CAddress2%2CAddress3%2CAddress4%2CCountryName&maxresults=10&opts=DefaultCountry%3DUSA%2COutputScript%3DLatn';
//         const options = {
//             method: 'GET',
//             headers: {
//                 'x-rapidapi-key': 'Sign Up for Key',
//                 'x-rapidapi-host': 'loqate-address.p.rapidapi.com'
//             }
//         };

//         const response = await fetch(url, options);
//         console.log(response)
//         const result = await response.text();
//         console.log(result);

//     } catch (error) {
//         console.log(error)
//     }
// }

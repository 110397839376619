import axios from "axios";
class MasterCFSServices {


  insertCFS = async (payload) => {
    if (payload) {
      return await axios.post(`/MasterCallforServiceCode/InsertCallforServiceCode`, payload);
    }
    console.warn("payload not provided, MasterCFSServices.insertCFS");
    return null;
  };

 
}

const instance = new MasterCFSServices();

export default instance;

import React, { useContext, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { AgencyContext } from './Context/Agency/Index';
import Header from './CADComponents/Header';
const CryptoJS = require("crypto-js");

const CADAuth = (props) => {

  const { cmp, listManagementSideBar, agencySideBar, propertyRoomSideBar, personnelSideBar, path, incidentSideBar, dashboardSidebar, nameSearchSideBar, arrestSearchSideBar, progressData, progressStatus, reportSidebar, searchSidebar, consolidationSideBar, hideHeader } = props
  const Com = cmp;


  const navigate = useNavigate()

  const { setAuthSession, setLogByOtp, setIsLogout } = useContext(AgencyContext);

  const IsLoginSession = sessionStorage.getItem('is-login') ? sessionStorage.getItem('is-login') : false;

  const currentLocation = window.location.pathname + window.location.search + window.location.hash;


  useEffect(() => {
    // if (IsLoginSession && window.location.pathname === "/dashboard-page") {
    //   preventBack();
    // }
    if (IsLoginSession && window.location.href === window.location.origin + "/dashboard-page") {
      preventBack();
    }
  }, [window.location.pathname]);

  function preventBack() {
    window.onpopstate = function (event) {
      if (event.state && event.state.url === currentLocation || currentLocation === window.location.pathname) {
        window.history.go(1);
      }
    };
    // window.history.pushState({ url: currentLocation }, '');
    window.history.pushState(null, null, currentLocation);
  }

  useEffect(() => {
    if (IsLoginSession === 'true' || IsLoginSession === true) {

    } else {
      setIsLogout(false);
      setLogByOtp(false)
      setAuthSession('');
      navigate('/');
    }
  }, [])

  useEffect(() => {
    if (IsLoginSession === 'true' || IsLoginSession === true) {
      console.log("Login Sucess")
    } else {
      setIsLogout(false);
      setLogByOtp(false)
      setAuthSession('');
      navigate('/');
    }
  }, [IsLoginSession])

  const timerRef = useRef(null);
  const timeout = 1200000;

  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(logout, timeout);
  };

  const logout = () => {
    window.location.href = '/'
    localStorage.clear()
  };

  useEffect(() => {
    resetTimer();
    const events = ['mousemove', 'mousedown', 'keypress', 'scroll', 'touchstart', 'load', 'click', 'resize'];
    const handleActivity = () => {
      resetTimer();
    };

    events.forEach(event => {
      window.addEventListener(event, handleActivity);
    });

    return () => {
      clearTimeout(timerRef.current);
      events.forEach(event => {
        window.removeEventListener(event, handleActivity);
      });
    };
  }, []);

  return (
    <>
      {/* Sidebar */}
      {
        !IsLoginSession ? navigate('/')
          : <>
            <div>
              {!hideHeader && <Header {...{ listManagementSideBar, agencySideBar, personnelSideBar }} />}

              <Com {...{ progressData, progressStatus }} />
            </div>
          </>
      }
    </>
  )
}

export default CADAuth


export function encrypt(plain) {
  const aesKey = '0ca175b9c0f726a831d895e269332461';
  const key = CryptoJS.enc.Utf8.parse(aesKey);
  const encryptedData = CryptoJS.AES.encrypt(plain, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(encryptedData.ciphertext.toString()));
}


export function decrypt(secret) {
  const aesKey = '0ca175b9c0f726a831d895e269332461';
  const key = CryptoJS.enc.Utf8.parse(aesKey);
  const decryptedData = CryptoJS.AES.decrypt(secret, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return decryptedData.toString(CryptoJS.enc.Utf8);
}
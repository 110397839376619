/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import { Nav } from "react-bootstrap";
import TestCmp from "../../CADComponents/Common/TitleCmp";
import IncidentTabSection from "../../CADComponents/MonitorScreen/TabSections/IncidentTabSection";
// import NameTabSection from "../../CADComponents/MonitorScreen/TabSections/NameTabSection";
import CommentsTabSection from "../../CADComponents/MonitorScreen/TabSections/CommentsTabSection";
// import PropertyTabSection from "../../CADComponents/MonitorScreen/TabSections/PropertyTabSection";
// import VehicleTabSection from "../../CADComponents/MonitorScreen/TabSections/VehicleTabSection";
import ResourceHistoryTabSection from "../../CADComponents/MonitorScreen/TabSections/ResourceHistoryTabSection";
import ResourceStatusTabSection from "../../CADComponents/MonitorScreen/TabSections/ResourceStatusTabSection";
import IncidentTableSection from "../../CADComponents/MonitorScreen/IncidentTableSection";
import ResourcesTableSection from "../../CADComponents/MonitorScreen/ResourcesTableSection";
// For Modal
import VehicleTabSectionModal from "../../CADComponents/MonitorScreen/TabSections/VehicleTabSectionModal";
import PropertyTabSectionModal from "../../CADComponents/MonitorScreen/TabSections/PropertyTabSectionModal";
import NameTabSectionModal from "../../CADComponents/MonitorScreen/TabSections/NameTabSectionModal";
import ResizableContainer from "../../CADComponents/Common/ResizableContainer";
import "./index.css";

const CADDashboard = () => {
  // For Modal
  const [openNameTabSectionModal, setOpenNameTabSectionModal] = useState(false);
  const [openPropertyTabSectionModal, setOpenPropertyTabSectionModal] = useState(false);
  const [openVehicleTabSectionModal, setOpenVehicleTabSectionModal] = useState(false);

  const tabConfig = [
    { key: "incident", label: "Incident", component: IncidentTabSection, isPopup: false },
    // { key: "name", label: "Name", component: NameTabSection, isPopup: true, data_target: 'NameTabSectionModal', target_function: () => setOpenNameTabSectionModal(true) },
    { key: "name", label: "Name", isPopup: true, data_target: 'NameTabSectionModal', target_function: () => setOpenNameTabSectionModal(true) },
    // { key: "property", label: "Property", component: PropertyTabSection, isPopup: true, data_target: 'PropertyTabSectionModal', target_function: () => setOpenPropertyTabSectionModal(true) },
    { key: "property", label: "Property", isPopup: true, data_target: 'PropertyTabSectionModal', target_function: () => setOpenPropertyTabSectionModal(true) },
    { key: "comments", label: "Comments", component: CommentsTabSection, isPopup: false, },
    // { key: "vehicle", label: "Vehicle", component: VehicleTabSection, isPopup: true, data_target: 'VehicleTabSectionModal', target_function: () => setOpenVehicleTabSectionModal(true) },
    { key: "vehicle", label: "Vehicle", isPopup: true, data_target: 'VehicleTabSectionModal', target_function: () => setOpenVehicleTabSectionModal(true) },
    { key: "resourceHistory", label: "Resource History", component: ResourceHistoryTabSection, isPopup: false },
    { key: "resourceStatus", label: "Resource Status", component: ResourceStatusTabSection, isPopup: false },
  ];

  const [activeTab, setActiveTab] = useState("incident");
  const ActiveTabComponent = tabConfig.find(tab => tab.key === activeTab)?.component;

  // Refs for dynamic resizing
  const ActiveTabComponentRef = useRef(null);
  const incidentTableRef = useRef(null);
  const resourcesTableRef = useRef(null);

  const [activeTabComponentHeight, setActiveTabComponentHeight] = useState(null);
  const [incidentTableHeight, setIncidentTableHeight] = useState(null);
  const [resourcesTableHeight, setResourcesTableHeight] = useState(null);

  // Helper function to observe element size
  const observeSize = (element, setSize) => {
    if (!element) return;
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setSize(entry.contentRect.height);
      }
    });
    resizeObserver.observe(element);
    return () => resizeObserver.disconnect();
  };

  useEffect(() => {
    // Observing size of tab content, incident table, and resources table
    const cleanupTabObserver = observeSize(ActiveTabComponentRef.current, setActiveTabComponentHeight);
    const cleanupIncidentObserver = observeSize(incidentTableRef.current, setIncidentTableHeight);
    const cleanupResourcesObserver = observeSize(resourcesTableRef.current, setResourcesTableHeight);

    return () => {
      cleanupTabObserver && cleanupTabObserver();
      cleanupIncidentObserver && cleanupIncidentObserver();
      cleanupResourcesObserver && cleanupResourcesObserver();
    };
  }, [activeTab]); // Re-run when the active tab changes

  return (
    <>
      <div className="section-body view_page_design pt-3">
        {/* CAD Dashboard */}
        <div className="dashboard-main-container">
          {/* Dispatcher */}
          <div className="dispatcher-container">
            <TestCmp title={"Dispatcher"} />
            {/* Tab Controller */}
            <div className="tab-controller-container">
              {/* Tab Navigation */}
              <Nav variant="tabs" className="cad-nav-tabs" style={{ width: "90%" }}>
                {tabConfig.map((tab) => (
                  tab.isPopup ? (
                    <>
                      <Nav.Item key={tab.key} >
                        <a
                          className=""
                          data-toggle="modal"
                          data-target={`#${tab.data_target}`}
                          onClick={tab.target_function}
                        >
                          {tab.label}
                        </a>
                      </Nav.Item>
                    </>
                  ) : (
                    <Nav.Item key={tab.key} >
                      <Nav.Link
                        active={activeTab === tab.key}
                        onClick={() => setActiveTab(tab.key)}
                      >
                        {tab.label}
                      </Nav.Link>
                    </Nav.Item>
                  )
                ))}
              </Nav>
              {/* Tab Content */}
              <ResizableContainer maxHeight={activeTabComponentHeight + 30} defaultHeight={300}>
                <div className="mt-3" ref={ActiveTabComponentRef}>
                  {ActiveTabComponent && <ActiveTabComponent />}
                </div>
              </ResizableContainer>
            </div>
          </div>

          {/* Incident */}
          <div className="incident-view-container">
            <TestCmp title={"Incidents View"} />
            <ResizableContainer maxHeight={incidentTableHeight}>
              <div ref={incidentTableRef}>
                <IncidentTableSection />
              </div>
            </ResizableContainer>
          </div>

          {/* Resources */}
          <div className="resources-view-container">
            <TestCmp title={"Resources View"} />
            <ResizableContainer maxHeight={resourcesTableHeight}>
              <div ref={resourcesTableRef}>
                <ResourcesTableSection />
              </div>
            </ResizableContainer>
          </div>
        </div>
      </div >

      <NameTabSectionModal openNameTabSectionModal={openNameTabSectionModal} setOpenNameTabSectionModal={setOpenNameTabSectionModal} />
      <PropertyTabSectionModal openPropertyTabSectionModal={openPropertyTabSectionModal} setOpenPropertyTabSectionModal={setOpenPropertyTabSectionModal} />
      <VehicleTabSectionModal openVehicleTabSectionModal={openVehicleTabSectionModal} setOpenVehicleTabSectionModal={setOpenVehicleTabSectionModal} />
    </>
  );
};

export default CADDashboard;

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { base64ToString, getShowingMonthDateYear, stringToBase64 } from '../../../../Common/Utility';
import { RequiredFieldIncident } from '../../../Utility/Personnel/Validation';
import { Comman_changeArrayFormat, sixColArray } from '../../../../Common/ChangeArrayFormat';
import { AddDeleteUpadate, fetchPostData } from '../../../../hooks/Api';
import { toastifySuccess } from '../../../../Common/AlertMsg';
import Select from "react-select";
import DatePicker from "react-datepicker";
import { get_AgencyOfficer_Data } from '../../../../../redux/actions/DropDownsData';

const PropertyManagement = (props) => {
    const navigate = useNavigate();

    const { DecPropID, DecMPropID, DecIncID } = props
    const dispatch = useDispatch();
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const agencyOfficerDrpData = useSelector((state) => state.DropDown.agencyOfficerDrpData);
    const arresteeNameData = useSelector((state) => state.DropDown.arresteeNameData);
    const mastersNameDrpData = useSelector((state) => state.DropDown.mastersNameDrpData);

    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    // let DecPropID = 0, DecMPropID = 0
    const query = useQuery();
    var IncID = query?.get("IncId");
    var IncNo = query?.get("IncNo");
    var IncSta = query?.get("IncSta");
    var ProId = query?.get("ProId");
    var MProId = query?.get('MProId');
    var ProSta = query?.get('ProSta');
    let MstPage = query?.get('page');

    if (!IncID) IncID = 0;
    else IncID = parseInt(base64ToString(IncID));
    // if (!ProId) ProId = 0;
    // else DecPropID = parseInt(base64ToString(ProId));
    // if (!MProId) ProId = 0;
    // else DecMPropID = parseInt(base64ToString(MProId));


    const [ownerNameData, setOwnerNameData] = useState([]);
    const [possenSinglData, setPossenSinglData] = useState([]);
    // date 
    const [expecteddate, setExpecteddate] = useState();
    const [courtdate, setCourtdate] = useState('');
    const [releasedate, setreleasedate] = useState('');
    const [destroydate, setdestroydate] = useState('');
    // dropdown
    const [loginAgencyID, setloginAgencyID] = useState('');
    const [loginPinID, setloginPinID,] = useState('');
    const [activitydate, setactivitydate] = useState();

    const [clickedRow, setClickedRow] = useState(null);
    const [reasonIdDrp, setReasonIdDrp] = useState([]);
    const [editval, setEditval] = useState();
    const [searchData, setSearchData] = useState([]);
    const [propertyId, setPropertyId] = useState('');
    const [masterpropertyId, setMasterPropertyId] = useState('');
    const [possessionID, setPossessionID] = useState('');
    // checkbox states
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState('');
    // functionality states
    const [propertyNumber, setPropertyNumber] = useState('');
    const [rowClicked, setRowClicked] = useState(false);
    const [nameModalStatus, setNameModalStatus] = useState(false);
    const [mainIncidentID, setMainIncidentID] = useState('');

    const AddType = [
        { value: 1, label: 'Property Number' },
        { value: 2, label: 'Location' },
        { value: 3, label: 'Barcode' },
        { value: 4, label: 'Transaction Number' },
        { value: 5, label: 'Property Type' },
        { value: 6, label: 'Property Tag' },
    ]

    const AddTransfer = [
        { value: 1, label: 'Check In' },
        { value: 2, label: 'Check Out' },
        { value: 3, label: 'Release' },
        { value: 4, label: 'Destroy' },
    ]

    const [selectedOptions, setSelectedOptions] = useState(AddType[0]);
    const [transfer, settransfer] = useState(AddTransfer[0]);

    const [value, setValue] = useState({
        'PropertyID': '', 'MasterPropertyId': '', 'ActivityType': '', 'ActivityReasonID': '', 'ExpectedDate': '', 'ActivityComments': '', 'OtherPersonNameID': '', 'PropertyRoomPersonNameID': '', 'ChainDate': '', 'DestroyDate': '',
        'CourtDate': '', 'ReleaseDate': '', 'PropertyTag': '', 'RecoveryNumber': '', 'StorageLocationID': '', 'ReceiveDate': '', 'OfficerNameID': '', 'InvestigatorID': '', 'location': '', 'activityid': '', 'EventId': '',
        'IsCheckIn': false, 'IsCheckOut': false, 'IsRelease': false, 'IsDestroy': false, 'IsTransferLocation': false, 'IsUpdate': false, 'CreatedByUserFK': '',

    })

    const [errors, setErrors] = useState({
        'ReasonError': '', 'ActivityDateError': '', 'InvestigatorError': '', 'LocationError': '', 'PropertyError': '', 'ExpectedDateError': '', 'OfficerNameError': '', 'NameError': '', 'CourtDateError': '', 'ReleaseDateError': '', 'DestroyDateError': '', 'TypeError': '', 'TransferError': ''
    })

    useEffect(() => {
        if (localStoreData) {
            setloginAgencyID(localStoreData?.AgencyID); setloginPinID(localStoreData?.PINID);
        }
    }, [localStoreData]);

    useEffect(() => {
        if (loginAgencyID && selectedOption) {
            GetActivityReasonDrp(loginAgencyID);
        }
    }, [loginAgencyID, selectedOption]);

    useEffect(() => {
        if (DecIncID) {
            setMainIncidentID(DecIncID);
            // get_Arrestee_Drp_Data(IncID);
        }
    }, [DecIncID, nameModalStatus, possessionID]);

    // useEffect(() => {
    //     dispatch(get_Masters_Name_Drp_Data(possessionID, 0, 0));
    //     if (possessionID) { setValue({ ...value, ['PropertyRoomPersonNameID']: parseInt(possessionID) }) }
    // }, [possessionID]);

    // const get_Arrestee_Drp_Data = (IncidentID) => {
    //     const val = { 'MasterNameID': 0, 'IncidentID': IncidentID, }
    //     fetchPostData('Arrest/GetDataDropDown_Arrestee', val).then((data) => {
    //         if (data) {
    //             setOwnerNameData(sixColArray(data, 'NameID', 'Arrestee_Name', 'LastName', 'DateOfBirth', 'Gendre_Description', 'Race_Description', 'NameID', 'MasterNameID'));
    //         } else {
    //             setOwnerNameData([])
    //         }
    //     })
    // };

    const GetSingleDataPassion = (nameID, masterNameID) => {
        const val = { 'NameID': nameID, 'MasterNameID': masterNameID }
        fetchPostData('MasterName/GetSingleData_MasterName', val).then((res) => {
            if (res) {
                setPossenSinglData(res);
            } else { setPossenSinglData([]); }
        })
    }

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
        const { value: selectedOption } = event.target;

        setValue(prevState => ({
            ...prevState,
            IsCheckIn: selectedOption === 'CheckIn',
            IsCheckOut: selectedOption === 'CheckOut',
            IsRelease: selectedOption === 'Release',
            IsDestroy: selectedOption === 'Destroy',
            IsTransferLocation: selectedOption === 'TransferLocation',
            IsUpdate: selectedOption === 'Update',
        }));
    };

    const isCheckInSelected = selectedOption === 'CheckIn';

    const handleInputChange = (e) => {
        setPropertyNumber(e.target.value);
    };

    const check_Validation_Error = (e) => {
        // if (RequiredFieldIncident(value.NameID)) {
        //     setErrors(prevValues => { return { ...prevValues, ['LastNameErrors']: RequiredFieldIncident(value.NameID) } })
        // }
        const ReasonError = RequiredFieldIncident(value.ActivityReasonID);
        // const ActivityDateError = RequiredField(value.SexID);
        // const InvestigatorError = RequiredFieldIncident(value.InvestigatorID);
        const PropertyError = RequiredFieldIncident(value.OtherPersonNameID);
        // const ExpectedDateError = value.IsCheckIn ? 'true' : RequiredFieldIncident(value.ExpectedDate);
        const OfficerNameError = RequiredFieldIncident(value.OfficerNameID);
        // const NameError = value.IsCheckIn ? 'true' : RequiredField(value.SexID);
        const CourtDateError = RequiredFieldIncident(value.CourtDate);
        const ReleaseDateError = RequiredFieldIncident(value.ReleaseDate);
        const DestroyDateError = RequiredFieldIncident(value.DestroyDate);
        const LocationError = value.IsCheckIn || value.IsTransferLocation ? RequiredFieldIncident(value.location) : 'true';

        setErrors(prevValues => {
            return {
                ...prevValues,
                ['ReasonError']: ReasonError || prevValues['ReasonError'],
                // ['ActivityDateError']: ActivityDateError || prevValues['ActivityDateError'],
                // ['InvestigatorError']: InvestigatorError || prevValues['InvestigatorError'],
                ['PropertyError']: PropertyError || prevValues['PropertyError'],
                // ['ExpectedDateError']: ExpectedDateError || prevValues['ExpectedDateError'],
                ['OfficerNameError']: OfficerNameError || prevValues['OfficerNameError'],
                // ['NameError']: NameError || prevValues['NameError'],
                // ['CourtDateError']: CourtDateError || prevValues['CourtDateError'],
                // ['ReleaseDateError']: ReleaseDateError || prevValues['ReleaseDateError'],
                // ['DestroyDateError']: DestroyDateError || prevValues['DestroyDateError'],
                ['LocationError']: LocationError || prevValues['LocationError'],

            }
        })
    }

    const { ReasonError, LocationError, PropertyError, ExpectedDateError, OfficerNameError, } = errors

    useEffect(() => {
        if (ReasonError === 'true' && LocationError === 'true' && PropertyError === 'true' && OfficerNameError === 'true') {
            Add_Type()
        }
    }, [ReasonError, PropertyError, LocationError, OfficerNameError])

    const GetActivityReasonDrp = (loginAgencyID) => {
        const val = { AgencyID: loginAgencyID, 'EvidenceReasonType': selectedOption };
        fetchPostData("PropertyEvidenceReason/GetDataDropDown_PropertyEvidenceReason", val).then((data) => {
            if (data) {
                setReasonIdDrp(Comman_changeArrayFormat(data, 'EvidenceReasonID', 'Description'))
            } else {
                setReasonIdDrp([]);
            }
        });
    };

    useEffect(() => {
        if (loginAgencyID) {
            setValue({
                ...value,
                'IncidentID': DecMPropID, 'CreatedByUserFK': loginPinID, 'AgencyID': loginAgencyID,
            }); if (agencyOfficerDrpData?.length === 0) { dispatch(get_AgencyOfficer_Data(loginAgencyID)); }
        }
    }, [loginAgencyID]);


    const SearchButton = () => {
        const val = { 'AgencyID': loginAgencyID, 'PropertyNumber': propertyNumber };
        AddDeleteUpadate('Propertyroom/SearchPropertyRoom', val).then((res) => {
            const parsedData = JSON.parse(res.data);
            setSearchData(parsedData.Table);

            if (parsedData.Table && parsedData.Table.length > 0) {
                const propertyId = parsedData.Table[0].PropertyID;
                const masterpropertyId = parsedData.Table[0].MasterPropertyID;
                setPropertyId(propertyId);
                setMasterPropertyId(masterpropertyId);
            }
        });
    };


    const Add_Type = () => {
        const PropertyID = DecPropID;
        const MasterPropertyId = masterpropertyId;
        const ActivityType = selectedOption
        // const IncidentID = mainIncidentID;
        const CreatedByUserFK = loginPinID;
        const { ActivityReasonID, ExpectedDate, ActivityComments, OtherPersonNameID, PropertyRoomPersonNameID, ChainDate, DestroyDate,
            CourtDate, ReleaseDate, PropertyTag, RecoveryNumber, StorageLocationID, ReceiveDate, OfficerNameID, InvestigatorID, location, activityid, EventId,
            IsCheckIn, IsCheckOut, IsRelease, IsDestroy, IsTransferLocation, IsUpdate,
        } = value;

        const val = {
            PropertyID, ActivityType, ActivityReasonID, ExpectedDate, ActivityComments, OtherPersonNameID, PropertyRoomPersonNameID, ChainDate, DestroyDate,
            CourtDate, ReleaseDate, PropertyTag, RecoveryNumber, StorageLocationID, ReceiveDate, OfficerNameID, InvestigatorID, location, activityid, EventId,
            MasterPropertyId, IsCheckIn, IsCheckOut, IsRelease, IsDestroy, IsTransferLocation, IsUpdate, CreatedByUserFK,
        };

        AddDeleteUpadate('Propertyroom/PropertyroomInsert', val).then((res) => {
            reset();
            navigate(`/Prop-Home?IncId=${stringToBase64(IncID)}&IncNo=${IncNo}&IncSta=${IncSta}&ProId=${stringToBase64(res?.PropertyID)}&MProId=${stringToBase64(res?.MasterPropertyID)}&ProSta=${true}`)
            // SearchButton();
            toastifySuccess(res.Message);
        })
    }


    const set_Edit_Value = (row) => {
        setSelectedStatus(row.Status);
        setRowClicked(true);
    }

    const columns = [
        {
            name: 'Property Number',
            selector: (row) => row.PropertyNumber,
            sortable: true
        },
        {
            name: 'Type',
            selector: (row) => row.Type,
            sortable: true
        },
        {
            name: 'Description',
            selector: (row) => row.Description,
            sortable: true

        },
        {
            name: 'Classification',
            selector: (row) => row.Classification,
            sortable: true

        },
        {
            name: 'Status',
            selector: (row) => row.Status,
            sortable: true

        },


        {
            name: <p className='text-end' style={{ position: 'absolute', top: '7px', right: 30 }}>Action</p>,
            cell: row =>
                <div style={{ position: 'absolute', top: 4, right: 30 }}>
                    {/* {
                effectiveScreenPermission ? effectiveScreenPermission[0]?.DeleteOK ?
                  <span onClick={(e) => setCrimeId(row.CrimeID)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                    <i className="fa fa-trash"></i>
                  </span>
                  : <></>
                  : <span onClick={(e) => setCrimeId(row.CrimeID)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                    <i className="fa fa-trash"></i>
                  </span>
              }
        */}
                </div>
        }

    ]

    const ChangeDropDown = (e, name) => {
        if (e) {
            setValue({
                ...value,
                [name]: e.value
            })
            setPossessionID(e.value);
        } else {
            setValue({
                ...value,
                [name]: null
            });
            setPossessionID('');
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (event) {
            setValue((prevState) => ({ ...prevState, [name]: value, }));
        }
        else {
            setValue((prevState) => ({ ...prevState, [name]: null, }));
        }
    };

    const setStatusFalse = (e) => {
        reset();
        setClickedRow(null);
    }

    const handleCheckboxChange = ({ selectedRows }) => {
        if (selectedRows.length > 0) {
            const status = selectedRows[0].Status;
            setSelectedStatus(status);
            setRowClicked(true);
        } else {
            setSelectedStatus(null);
        }
    };

    const reset = () => {
        setValue({
            ...value,
            'PropertyID': '', 'ActivityType': '', 'ActivityReasonID': '', 'ExpectedDate': '', 'ActivityComments': '', 'OtherPersonNameID': '', 'PropertyRoomPersonNameID': '', 'ChainDate': '', 'DestroyDate': '',
            'CourtDate': '', 'ReleaseDate': '', 'PropertyTag': '', 'RecoveryNumber': '', 'StorageLocationID': '', 'ReceiveDate': '', 'OfficerNameID': '', 'InvestigatorID': '', 'location': '', 'activityid': '', 'EventId': '',
            'MasterPropertyId': '', 'IsCheckIn': '', 'IsCheckOut': '', 'IsRelease': '', 'IsDestroy': '', 'IsTransferLocation': '', 'IsUpdate': '', 'CreatedByUserFK': '',
        });
        setErrors({
            ...errors,
            'ReasonError': '', 'ActivityDateError': '', 'InvestigatorError': '', 'PropertyError': '', 'ExpectedDateError': '', 'OfficerNameError': '', 'NameError': '', 'CourtDateError': '', 'ReleaseDateError': '', 'DestroyDateError': '', 'TypeError': '', 'TransferError': '', 'LocationError': '',
        })
        setCourtdate(''); setreleasedate(''); setdestroydate(''); setExpecteddate('');
        setSelectedStatus(''); setRowClicked(''); setSelectedOption(''); setactivitydate('')
        //  setPropertyNumber('');
        setReasonIdDrp([]);

        // setPossessionID(''); setPossenSinglData([]);
    }

    const conditionalRowStyles = [
        {
            when: row => row === clickedRow,
            style: {
                backgroundColor: '#001f3fbd',
                color: 'white',
                cursor: 'pointer',
            },
        },
    ];

    const colourStyles = {
        control: (styles) => ({
            ...styles, backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 35,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    }

    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 20,
            minHeight: 35,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    return (
        <>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 col-md-4 col-lg-2  pt-1 pl-lg-5 ml-lg-5 pl-md-0 ml-md-0">
                        <div className="form-check  ">
                            <input className="form-check-input" type="radio" value="CheckIn" name="AttemptComplete" cid="flexRadioDefault" onChange={handleRadioChange} />
                            <label className="form-check-label" htmlFor="flexRadioDefault">
                                Check In
                            </label>
                        </div>
                    </div>
                    <div className="col-4 col-md-4 col-lg-2  pt-1 ">
                        <div className="form-check  ">
                            <input className="form-check-input" type="radio" value="CheckOut" name="AttemptComplete" id="flexRadioDefault1" onChange={handleRadioChange} />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Check Out
                            </label>
                        </div>
                    </div>
                    <div className="col-4 col-md-4 col-lg-2  pt-1 ">
                        <div className="form-check  ">
                            <input className="form-check-input" type="radio" value="Release" name="AttemptComplete" id="flexRadioDefault2" onChange={handleRadioChange} />
                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                Release
                            </label>
                        </div>
                    </div>
                    <div className="col-4 col-md-4 col-lg-2  pt-1 ">
                        <div className="form-check  ">
                            <input className="form-check-input" type="radio" value="Destroy" name="AttemptComplete" id="flexRadioDefault3" onChange={handleRadioChange} />
                            <label className="form-check-label" htmlFor="flexRadioDefault3">
                                Destroy
                            </label>
                        </div>
                    </div>
                    <div className="col-4 col-md-4 col-lg-2  pt-1 ">
                        <div className="form-check  ">
                            <input className="form-check-input" type="radio" value="TransferLocation" name="AttemptComplete" id="flexRadioDefault4" onChange={handleRadioChange} />
                            <label className="form-check-label" htmlFor="flexRadioDefault4">
                                Transfer Location
                            </label>
                        </div>
                    </div>
                    <div className="col-4 col-md-4 col-lg-1  pt-1 ">
                        <div className="form-check  ">
                            <input className="form-check-input" type="radio" value="Update" name="AttemptComplete" id="flexRadioDefault5" onChange={handleRadioChange} />
                            <label className="form-check-label" htmlFor="flexRadioDefault5">
                                Update
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3 col-md-3 col-lg-2 mt-2 px-1">
                        <label htmlFor="" className='new-label'>Reason{errors.ReasonError !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ReasonError}</p>
                        ) : null}</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-4 mt-1">
                        <Select
                            name='ActivityReasonID'
                            value={reasonIdDrp?.filter((obj) => obj.value === value?.ActivityReasonID)}
                            isClearable
                            options={reasonIdDrp}
                            onChange={(e) => ChangeDropDown(e, 'ActivityReasonID')}
                            placeholder="Select..."
                            styles={colourStyles}
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-2 mt-2 px-1">
                        <label htmlFor="" className='new-label'>Investigator{errors.InvestigatorError !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.InvestigatorError}</p>
                        ) : null}</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-4 mt-1">
                        <Select
                            name='InvestigatorID'
                            value={agencyOfficerDrpData?.filter((obj) => obj.value === value?.InvestigatorID)}
                            isClearable
                            options={agencyOfficerDrpData}
                            onChange={(e) => ChangeDropDown(e, 'InvestigatorID')}
                            placeholder="Select..."
                            styles={customStylesWithOutColor}
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-2 mt-2 px-0">
                        <label htmlFor="" className='new-label px-0'>Property&nbsp;Person{errors.PropertyError !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.PropertyError}</p>
                        ) : null}</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-4 mt-1">
                        <Select
                            name='OtherPersonNameID'
                            value={agencyOfficerDrpData?.filter((obj) => obj.value === value?.OtherPersonNameID)}
                            isClearable
                            options={agencyOfficerDrpData}
                            onChange={(e) => ChangeDropDown(e, 'OtherPersonNameID')}
                            placeholder="Select..."
                            styles={colourStyles}
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-2 mt-2 px-1">
                        <label htmlFor="" className='new-label'>Officer Name{errors.OfficerNameError !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.OfficerNameError}</p>
                        ) : null}</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-4 mt-1">
                        <Select
                            name='OfficerNameID'
                            value={agencyOfficerDrpData?.filter((obj) => obj.value === value?.OfficerNameID)}
                            isClearable
                            options={agencyOfficerDrpData}
                            onChange={(e) => ChangeDropDown(e, 'OfficerNameID')}
                            placeholder="Select..."
                            styles={colourStyles}
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-2 mt-2 px-1">
                        <label htmlFor="" className='new-label'>Activity Date/Time{errors.ActivityDateError !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ActivityDateError}</p>
                        ) : null}</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-4 ">
                        <DatePicker
                            name='activitydate'
                            id='activitydate'
                            onChange={(date) => {
                                setactivitydate(date); setValue({ ...value, ['LastSeenDtTm']: date ? getShowingMonthDateYear(date) : null, });

                            }}
                            isClearable={activitydate ? true : false}
                            selected={activitydate}
                            placeholderText={activitydate ? activitydate : 'Select...'}
                            dateFormat="MM/dd/yyyy HH:mm"
                            // filterTime={filterPassedTime}
                            timeInputLabel
                            showTimeSelect
                            timeIntervals={1}
                            timeCaption="Time"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            showDisabledMonthNavigation
                            autoComplete='off'
                            // maxDate={new Date()}
                            className='requiredColor'
                        />
                    </div>


                    <div className="col-3 col-md-3 col-lg-2 mt-2 px-1">
                        <label htmlFor="" className='new-label'>Expected Return Date{errors.ExpectedDateError !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ExpectedDateError}</p>
                        ) : null}</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-4 ">
                        <DatePicker
                            name='ExpectedDate'
                            id='ExpectedDate'
                            onChange={(date) => {
                                setExpecteddate(date); setValue({ ...value, ['ExpectedDate']: date ? getShowingMonthDateYear(date) : null, });

                            }}
                            isClearable={expecteddate ? true : false}
                            selected={expecteddate}
                            placeholderText={expecteddate ? expecteddate : 'Select...'}
                            dateFormat="MM/dd/yyyy"
                            // filterTime={filterPassedTime}
                            // timeInputLabel
                            // showTimeSelect
                            timeIntervals={1}
                            timeCaption="Time"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            showDisabledMonthNavigation
                            autoComplete='off'
                            // maxDate={new Date()}
                            className={value.IsCheckIn || value.IsRelease || value.IsDestroy || value.IsTransferLocation || value.IsUpdate ? 'readonlyColor' : ''}
                            disabled={value.IsCheckIn || value.IsRelease || value.IsDestroy || value.IsTransferLocation || value.IsUpdate}

                        />
                    </div>

                    <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                        <label htmlFor="" className='new-label'>Location{errors.LocationError !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.LocationError}</p>
                        ) : null}</label>
                    </div>
                    <div className="col-8 col-md-8 col-lg-4 text-field mt-1">
                        <input type="text" name="location" id="" className={value.IsCheckIn || value.IsTransferLocation ? 'requiredColor' : ''} onChange={(e) => { handleChange(e) }} />
                    </div>
                    <div className="col-1 pt-1" >
                        <button
                            className=" btn btn-sm bg-green text-white" data-toggle="modal" data-target="#TreeModal" style={{ cursor: 'pointer' }}>
                            <i className="fa fa-plus" > </i>
                        </button>
                    </div>
                    <div className="col-3 col-md-3 col-lg-1 mt-2 ">
                        <label htmlFor="" className='new-label'>Name{errors.NameError !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.NameError}</p>
                        ) : null}</label>
                    </div>
                    <div className='d-flex col-8 col-md-8 col-lg-4'>
                        <div className="col-12 col-md-12 col-lg-11  mt-1">
                            <Select
                                name='OwnerNameID'
                                options={mastersNameDrpData}
                                value={mastersNameDrpData?.filter((obj) => obj.value === value?.PropertyRoomPersonNameID)}
                                isClearable={value?.OwnerNameID ? true : false}
                                onChange={(e) => ChangeDropDown(e, 'PropertyRoomPersonNameID')}
                                placeholder="Select..."
                                isDisabled={value.IsCheckIn || value.IsCheckOut || value.IsDestroy || value.IsUpdate || value.IsTransferLocation}
                                styles={value.IsCheckIn || value.IsCheckOut || value.IsDestroy || value.IsUpdate || value.IsTransferLocation ? 'readonlyColor' : 'requiredColor'}
                            />
                            {/* <Select
                                name='Investigator'

                                isClearable
                                placeholder="Select..."
                                // isDisabled={isCheckInSelected}
                                isDisabled={value.IsCheckIn || value.IsCheckOut || value.IsDestroy || value.IsUpdate || value.IsTransferLocation}
                                styles={value.IsCheckIn || value.IsCheckOut || value.IsDestroy || value.IsUpdate || value.IsTransferLocation ? 'readonlyColor' : 'requiredColor'}
                            /> */}
                        </div>
                        <div className="col-1 pt-2" data-toggle="modal" data-target="#MasterModal" style={{ cursor: 'pointer' }}>
                            <button onClick={() => {
                                if (possessionID) { GetSingleDataPassion(possessionID); } setNameModalStatus(true);
                            }}
                                className=" btn btn-sm bg-green text-white py-1"   >
                                <i className="fa fa-plus" > </i>
                            </button>
                        </div>
                    </div>
                    <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                        <label htmlFor="" className='new-label'>Comments</label>
                    </div>
                    <div className="col-9 col-md-9 col-lg-10 text-field mt-1">
                        <input type="text" name="ActivityComments" onChange={(e) => { handleChange(e) }} />
                    </div>
                    <div className="col-3 col-md-3 col-lg-2 mt-2 px-0">
                        <label htmlFor="" className='new-label px-0'>Misc&nbsp;Description</label>
                    </div>
                    <div className="col-9 col-md-9 col-lg-10 text-field mt-1">
                        <input type="text" name="ActivityComments" disabled onChange={(e) => { handleChange(e) }} className='readonlyColor' />
                    </div>
                </div>
            </div >
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-0" >
                <fieldset>
                    <legend>Schedule</legend>
                    <div className="row px-0">
                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                            <label htmlFor="" className='new-label'>Court Date{errors.CourtDateError !== 'true' ? (
                                <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.CourtDateError}</p>
                            ) : null}</label>
                        </div>
                        <div className="col-3 col-md-3 col-lg-2 px-0">
                            <DatePicker
                                name='CourtDate'
                                id='CourtDate'
                                onChange={(date) => {
                                    setCourtdate(date);
                                    setValue({
                                        ...value,
                                        ['CourtDate']: date ? getShowingMonthDateYear(date) : null,
                                    });
                                    if (destroydate && new Date(destroydate) < new Date(date)) {
                                        setdestroydate(null);
                                        setValue({
                                            ...value,
                                            ['DestroyDate']: null,
                                        });
                                    }
                                }}
                                isClearable={!!courtdate}
                                selected={courtdate}
                                placeholderText={courtdate ? courtdate : 'Select...'}
                                dateFormat="MM/dd/yyyy"
                                // filterTime={filterPassedTime}
                                // timeInputLabel
                                // showTimeSelect
                                timeIntervals={1}
                                timeCaption="Time"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                showDisabledMonthNavigation
                                autoComplete='off'
                                minDate={new Date()}
                                maxDate={value.ReleaseDate ? new Date(value?.ReleaseDate) : ''}

                                // className='requiredColor'
                                disabled={value.IsCheckOut || value.IsRelease || value.IsDestroy || value.IsTransferLocation}
                                className={value.IsCheckOut || value.IsRelease || value.IsDestroy || value.IsTransferLocation ? 'readonlyColor' : ''}

                            />
                        </div>
                        <div className="col-3 col-md-3 col-lg-2 mt-2 px-1">
                            <label htmlFor="" className='new-label'>Release Date{errors.ReleaseDateError !== 'true' ? (
                                <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ReleaseDateError}</p>
                            ) : null}</label>
                        </div>
                        <div className="col-3 col-md-3 col-lg-2 ">
                            <DatePicker
                                name='ReleaseDate'
                                id='ReleaseDate'
                                onChange={(date) => {
                                    setreleasedate(date); setValue({ ...value, ['ReleaseDate']: date ? getShowingMonthDateYear(date) : null, });

                                }}
                                isClearable={releasedate ? true : false}
                                selected={releasedate}
                                placeholderText={releasedate ? releasedate : 'Select...'}
                                dateFormat="MM/dd/yyyy"
                                // filterTime={filterPassedTime}
                                // timeInputLabel
                                // showTimeSelect
                                timeIntervals={1}
                                timeCaption="Time"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                showDisabledMonthNavigation
                                autoComplete='off'
                                minDate={courtdate ? new Date(courtdate) : new Date()}
                                disabled={value.IsCheckOut || value.IsRelease || value.IsDestroy || value.IsTransferLocation}
                                className={value.IsCheckOut || value.IsRelease || value.IsDestroy || value.IsTransferLocation ? 'readonlyColor' : ''}

                            />
                        </div>
                        <div className="col-3 col-md-3 col-lg-1 mt-2 px-1">
                            <label htmlFor="" className='new-label'>Destroy&nbsp;Date{errors.DestroyDateError !== 'true' ? (
                                <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.DestroyDateError}</p>
                            ) : null}</label>
                        </div>
                        <div className="col-3 col-md-3 col-lg-3 ">
                            <DatePicker
                                name='DestroyDate'
                                id='DestroyDate'
                                onChange={(date) => {
                                    setdestroydate(date);
                                    setValue({
                                        ...value,
                                        ['DestroyDate']: date ? getShowingMonthDateYear(date) : null,
                                    });
                                }}
                                isClearable={!!destroydate}
                                selected={destroydate}
                                placeholderText={destroydate ? destroydate : 'Select...'}
                                dateFormat="MM/dd/yyyy"
                                // filterTime={filterPassedTime}
                                // timeInputLabel
                                // showTimeSelect
                                timeIntervals={1}
                                timeCaption="Time"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                showDisabledMonthNavigation
                                autoComplete='off'
                                minDate={courtdate ? new Date(courtdate) : new Date()}
                                disabled={value.IsCheckOut || value.IsRelease || value.IsDestroy || value.IsTransferLocation}
                                className={value.IsCheckOut || value.IsRelease || value.IsDestroy || value.IsTransferLocation ? 'readonlyColor' : ''}

                            />
                        </div>

                        <div className=" col-12  mt-2 btn-box" >
                            <div className="row propertyroom-button" style={{ position: 'fixed', bottom: 5, right: '30px' }}>
                                <button type="button" className="btn btn-sm btn-success mr-2 mb-2 mt-1 " onClick={''}>
                                    Close
                                </button>
                                <button type="button" className="btn btn-sm btn-success mr-2 mb-2 mt-1" onClick={(e) => { check_Validation_Error(); }}>
                                    Save
                                </button>
                                <button type="button" className="btn btn-sm btn-success mr-2 mb-2 mt-1" onClick={() => { setStatusFalse(); conditionalRowStyles(''); }}>
                                    Clear
                                </button>
                            </div>
                        </div>

                    </div >
                </fieldset>
            </div>

        </>
    )
}

export default PropertyManagement
import React, { useEffect, useState } from 'react'
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import { Decrypt_Id_Name, getShowingWithOutTime } from '../../../Common/Utility';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_CityDrp_Data, get_CountryDrp_Data, get_DL_StatesDrp_Data, get_How_Verify_Drp_Data, get_Marital_Drp_Data, get_Residents_Drp_Data, get_StatesDrp_Data } from '../../../../redux/actions/DropDownsData';
import { City_Drp_Data, DL_States_Drp_Data, States_Drp_Data } from '../../../../redux/actionTypes';
import { AddDeleteUpadate, fetchPostData } from '../../../hooks/Api';
import { toastifySuccess } from '../../../Common/AlertMsg';
import { RequiredFieldIncident } from '../../Utility/Personnel/Validation';
import { RequiredField } from '../../PersonnelCom/Validation/PersonnelValidation';

const MasterGeneral = (props) => {

    const { possessionID, mstPossessionID, loginAgencyID, loginPinID } = props

    const dispatch = useDispatch();
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const countryDrpData = useSelector((state) => state.DropDown.countryDrpData);
    const statesDrpData = useSelector((state) => state.DropDown.statesDrpData);
    const dlStatesDrpData = useSelector((state) => state.DropDown.dlStatesDrpData);
    const cityDrpData = useSelector((state) => state.DropDown.cityDrpData);
    const colorDrpData = useSelector((state) => state.DropDown.colorDrpData);
    const howVerifyDrpData = useSelector((state) => state.DropDown.howVerifyDrpData);
    const residentDrpData = useSelector((state) => state.DropDown.residentDrpData);
    const maritalDrpData = useSelector((state) => state.DropDown.maritalDrpData);

    const [editval, setEditval] = useState([]);
    const [value, setValue,] = useState({
        //-------dropDown------//
        'DLVerifyID': null, 'BICountryID': null, 'BIStateID': null,
        'BIVerifyID': null, 'EyeColorID': null, 'HairColorID': null, 'ResidentID': null, 'MaritalStatusID': null,
        //------date--------//
        'DLExpiryDate': null,
        //------text------//
        'DLNumber': "", 'IsUSCitizen': false, 'BirthPlace': "", 'BINationality': "",
        'DLStateID': null, 'DLCountryID': null, "BICityID": null,
        'NameID': '', 'MasterNameID': '', 'CreatedByUserFK': '', 'ModifiedByUserFK': '',
    });

    const [errors, setErrors] = useState({
        'DlNumberError': '',
    })

    useEffect(() => {
        if (loginAgencyID) {
            if (howVerifyDrpData?.length === 0) { dispatch(get_How_Verify_Drp_Data(loginAgencyID)) }
            if (residentDrpData?.length === 0) { dispatch(get_Residents_Drp_Data(loginAgencyID)) }
            if (maritalDrpData?.length === 0) { dispatch(get_Marital_Drp_Data(loginAgencyID)) }
        }
    }, [loginAgencyID]);

    useEffect(() => {
        dispatch(get_CountryDrp_Data(value?.IsUSCitizen))
    }, []);

    useEffect(() => {
        if (possessionID) {
            setValue(pre => { return { ...pre, 'CreatedByUserFK': loginPinID, 'ModifiedByUserFK': loginPinID, 'MasterNameID': mstPossessionID, 'NameID': possessionID } });
        }
    }, [possessionID, mstPossessionID, loginPinID]);

    useEffect(() => {
        if (possessionID || mstPossessionID) {
            get_Single_Data(possessionID, mstPossessionID);
        }
    }, [possessionID, mstPossessionID])

    const get_Single_Data = (possessionID, mstPossessionID) => {
        const val = {
            NameID: possessionID,
            MasterNameID: mstPossessionID,
        }
        fetchPostData('MasterName/GetSingleData_MasterName', val).then((res) => {
            if (res) { setEditval(res); } else { setEditval([]) }
        })
    }

    useEffect(() => {
        if (editval) {
            setValue({
                ...value,
                'MasterNameID': mstPossessionID,
                'NameID': possessionID,
                'MaritalStatusID': editval[0]?.MaritalStatusID, 'HairColorID': editval[0]?.HairColorID, 'BIVerifyID': editval[0]?.BIVerifyID,
                'BICountryID': editval[0]?.BICountryID, 'BIStateID': editval[0]?.BIStateID, 'BICityID': editval[0]?.BICityID,
                'DLVerifyID': editval[0]?.DLVerifyID, "ResidentID": editval[0]?.ResidentID,
                'EyeColorID': editval[0]?.EyeColorID, 'DLStateID': editval[0]?.DLStateID, 'DLCountryID': editval[0]?.DLCountryID,
                // checkbox
                'IsJuvenile': editval[0]?.IsJuvenile, 'IsVerify': editval[0]?.IsVerify, 'IsUnListedPhNo': editval[0]?.IsUnListedPhNo,
                //textbox
                'BINationality': editval[0]?.BINationality, 'BirthPlace': editval[0]?.BirthPlace, 'IsUSCitizen': editval[0]?.IsUSCitizen,
                'DLNumber': editval[0]?.DLNumber,
                //Datepicker
                'DLExpiryDate': editval[0]?.DLExpiryDate ? getShowingWithOutTime(editval[0]?.DLExpiryDate) : null,
            })
            dispatch(get_StatesDrp_Data(editval[0]?.BICountryID))
            dispatch(get_DL_StatesDrp_Data(editval[0]?.DLCountryID))
            dispatch(get_CityDrp_Data(editval[0]?.DLStateID))
        } else {
            resetState()
        }
    }, [editval])

    const check_Validation_Error = () => {
        const NameTypeIDErr = value?.DLStateID ? RequiredField(value?.DLNumber) : 'true';
        setErrors(pre => {
            return {
                ...pre,
                ['DlNumberError']: NameTypeIDErr || pre['DlNumberError'],
            }
        })
    }

    const { DlNumberError } = errors

    useEffect(() => {
        if (DlNumberError === 'true') {
            Update_Name(); return;
        }
    }, [DlNumberError]);

    const Update_Name = () => {
        AddDeleteUpadate('MasterName/UpdateBasicInfoName', value).then((res) => {
            const parsedData = JSON.parse(res.data);
            const message = parsedData.Table[0].Message;
            toastifySuccess(message); setErrors({ ...errors, ['DlNumberError']: '', })
        })
    }

    const HandleChange = (e) => {
        if (e) {
            if (e.target.name == 'IsUSCitizen') {
                setValue({ ...value, [e.target.name]: e.target.checked });
                dispatch(get_CountryDrp_Data(e.target.checked))
            } else {
                setValue({
                    ...value,
                    [e.target.name]: e.target.value
                })
            }
        } else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    };

    const HandleCountryDropDown = (e, name) => {
        if (e) {
            setValue({ ...value, [name]: e.value, ['BIStateID']: null, ['BICityID']: null })
            dispatch(get_StatesDrp_Data(e.value))
        } else {
            setValue({ ...value, [name]: null, ['BIStateID']: null, ['BICityID']: null });
            dispatch({ type: States_Drp_Data, payload: [] });
            dispatch({ type: City_Drp_Data, payload: [] });
        }
    }

    const HandleStateDropDown = (e, name) => {
        if (e) {
            setValue({ ...value, [name]: e.value, ['BICityID']: null });
            dispatch(get_CityDrp_Data(e.value))
        } else {
            setValue({ ...value, [name]: null, ['BICityID']: null });
            dispatch({ type: City_Drp_Data, payload: [] });
        }
    }

    const OnChangeDlCountry = (e, name) => {
        if (e) {
            setValue({ ...value, [name]: e.value, ['DLStateID']: null });
            dispatch(get_DL_StatesDrp_Data(e.value))
        } else {
            setValue({ ...value, [name]: null, ['DLStateID']: null });
            dispatch({ type: DL_States_Drp_Data, payload: [] });
        }
    }

    const onChangeDropDown = (e, name) => {
        if (e) {
            if (name === 'DLStateID') {
                setValue({ ...value, [name]: e.value, ['DLNumber']: '' })
            } else {
                setValue({ ...value, [name]: e.value });
            }
        } else {
            if (name === 'DLStateID') {
                setValue({ ...value, [name]: null, ['DLNumber']: '' })
            } else {
                setValue({ ...value, [name]: null });
            }
        }
    }

    const resetState = () => {
        setValue({
            ...value,
            //-------dropDown------//
            'BIVerifyID': "", 'EyeColorID': "", 'HairColorID': "", 'ResidentID': "", 'MaritalStatusID': "",
            //------date--------//
            'DLExpiryDate': null,
            //------text------//
            'DLNumber': "", 'IsUSCitizen': "", 'BirthPlace': "", 'BINationality': "",
            'DLStateID': "", 'DLCountryID': "", 'DLVerifyID': "",
        })
    }
    // custuom style withoutColor
    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 20,
            minHeight: 33,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    const customWithOutColor = {
        control: base => ({
            ...base,
            height: 20,
            minHeight: 33,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            width: 130,
        }),
    };

    const startRef = React.useRef();
    const startRef1 = React.useRef();

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef1.current.setOpen(false);
        }
    };

    return (
        <div>
            <div className="col-12 " id="display-not-form">
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-4 pt-3 ">
                        <fieldset>
                            <legend>Birth Information</legend>
                            <div className="row ">
                                <div className="col-12 col-md-12 col-lg-12 mt-1  ml-5 pl-5">
                                    <div className="form-check ">
                                        <input
                                            className="form-check-input"
                                            value={value?.IsUSCitizen}
                                            checked={value?.IsUSCitizen}
                                            onChange={HandleChange}
                                            type="checkbox"
                                            name="IsUSCitizen"
                                            id="flexCheckDefault"
                                        />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            US Citizen
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-2 col-md-2 col-lg-3 mt-3">
                                        <label htmlFor="" className='label-name '>Country</label>
                                    </div>
                                    <div className="col-4 col-md-4 col-lg-8  mt-1 " >
                                        <Select
                                            name="BICountryID"
                                            styles={customStylesWithOutColor}
                                            value={countryDrpData?.filter((obj) => obj.value === value?.BICountryID)}
                                            isClearable
                                            options={countryDrpData}
                                            onChange={(e) => HandleCountryDropDown(e, 'BICountryID')}
                                            placeholder="Select..."
                                        />
                                    </div>
                                    <div className="col-2 col-md-2 col-lg-3 mt-3">
                                        <label htmlFor="" className='label-name '>State</label>
                                    </div>
                                    <div className="col-4 col-md-4 col-lg-8  mt-1" >
                                        <Select
                                            name="BIStateID"
                                            styles={customStylesWithOutColor}
                                            value={statesDrpData?.filter((obj) => obj.value === value?.BIStateID)}
                                            isClearable
                                            options={statesDrpData}
                                            onChange={(e) => HandleStateDropDown(e, 'BIStateID')}
                                            // isDisabled={value?.BICountryID ? false : true}
                                            placeholder="Select..."
                                        />
                                    </div>
                                    <div className="col-2 col-md-2 col-lg-3 mt-2">
                                        <label htmlFor="" className='label-name '>City</label>
                                    </div>
                                    <div className="col-4 col-md-4 col-lg-8  mt-1" >
                                        <Select
                                            name="BICityID"
                                            styles={customStylesWithOutColor}
                                            value={cityDrpData?.filter((obj) => obj.value === value?.BICityID)}
                                            isClearable
                                            options={cityDrpData}
                                            onChange={(e) => onChangeDropDown(e, 'BICityID')}
                                            placeholder="Select..."
                                        // isDisabled={value?.BIStateID ? false : true}
                                        />
                                    </div>
                                    <div className="col-2 col-md-2 col-lg-3 mt-3 px-0">
                                        <label htmlFor="" className='label-name px-0'>Place Of Birth</label>
                                    </div>
                                    <div className="col-4 col-md-4 col-lg-8 text-field mt-2" >
                                        <input type="text" className="" value={value?.BirthPlace} onChange={HandleChange} name="BirthPlace" required autoComplete='off' />
                                    </div>
                                    <div className="col-2 col-md-2 col-lg-3 mt-3">
                                        <label htmlFor="" className='label-name '>Nationality</label>
                                    </div>
                                    <div className="col-4 col-md-4 col-lg-8 text-field mt-1" >
                                        <input type="text" className="" value={value?.BINationality} onChange={HandleChange} name="BINationality" required autoComplete='off' />
                                    </div>
                                    <div className="col-2 col-md-2 col-lg-3 mt-3">
                                        <span data-toggle="modal" className='new-link px-0'>
                                            How Verify
                                        </span>
                                    </div>
                                    <div className="col-4 col-md-4 col-lg-8 " >
                                        <Select
                                            name="BIVerifyID"
                                            styles={customStylesWithOutColor}
                                            value={howVerifyDrpData?.filter((obj) => obj.value === value?.BIVerifyID)}
                                            options={howVerifyDrpData}
                                            onChange={(e) => onChangeDropDown(e, 'BIVerifyID')}
                                            isClearable
                                            placeholder="Select..."
                                        />
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div className="col-12 col-md-12 col-lg-4 pt-3">
                        <fieldset>
                            <legend>DL Information</legend>
                            <div className="row mt-4">
                                <div className="col-2 col-md-2 col-lg-3 mt-3">
                                    <label htmlFor="" className='label-name '>Country</label>
                                </div>
                                <div className="col-4 col-md-4 col-lg-9 mt-2" >
                                    <Select
                                        name="DLCountryID"
                                        styles={customStylesWithOutColor}
                                        value={countryDrpData?.filter((obj) => obj.value === value?.DLCountryID)}
                                        isClearable
                                        options={countryDrpData}
                                        onChange={(e) => OnChangeDlCountry(e, 'DLCountryID')}
                                        placeholder="Select..."
                                    />
                                </div>
                                <div className="col-2 col-md-2 col-lg-3 mt-3">
                                    <label htmlFor="" className='label-name '>License State{errors.DLStateID && <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }} className="error-message">{errors.DLStateID}</p>}</label>
                                </div>
                                <div className="col-4 col-md-4 col-lg-9 mt-2" >
                                    <Select
                                        name="DLStateID"
                                        styles={customStylesWithOutColor}
                                        value={dlStatesDrpData?.filter(obj => obj.value === value.DLStateID)}
                                        isClearable
                                        options={dlStatesDrpData}
                                        onChange={(e) => onChangeDropDown(e, 'DLStateID')}
                                        placeholder="Select..."
                                    // isDisabled={value?.DLCountryID ? false : true}
                                    />
                                </div>
                                <div className="col-2 col-md-2 col-lg-3 mt-3">
                                    <label htmlFor="" className='label-name '>DL Number
                                        {errors.DlNumberError !== 'true' ? (
                                            <p style={{ color: 'red', fontSize: '9px', margin: '0px', padding: '0px' }}>{errors.DlNumberError}</p>
                                        ) : null}
                                    </label>
                                </div>
                                <div className="col-4 col-md-4 col-lg-9 text-field mt-2" >
                                    <input
                                        type="text"
                                        style={{ textTransform: "uppercase" }}
                                        value={value.DLNumber}
                                        className={value?.DLStateID ? 'requiredColor' : ''}
                                        maxLength={21}
                                        onChange={HandleChange}
                                        name="DLNumber"
                                        required
                                        autoComplete='off'
                                        disabled={value?.DLStateID ? false : true}
                                    />
                                </div>
                                <div className="col-2 col-md-2 col-lg-3 mt-3 px-0">
                                    <label htmlFor="" className='label-name px-0'>DL Expiry Date</label>
                                </div>
                                <div className="col-4 col-md-4 col-lg-9  " >
                                    <DatePicker
                                        ref={startRef}
                                        onKeyDown={onKeyDown}
                                        id="DLExpiryDate"
                                        name="DLExpiryDate"
                                        dateFormat="MM/dd/yyyy"
                                        onChange={(date) => { setValue({ ...value, ["DLExpiryDate"]: date ? getShowingWithOutTime(date) : null, }); }}
                                        isClearable
                                        selected={value?.DLExpiryDate && new Date(value?.DLExpiryDate)}
                                        placeholderText={"Select..."}
                                        autoComplete="Off"
                                        dropdownMode="select"
                                        showMonthDropdown
                                        showDisabledMonthNavigation
                                        showYearDropdown
                                    />
                                </div>
                                <div className="col-2 col-md-2 col-lg-3 mt-3">
                                    <span data-toggle="modal" className='new-link px-0'>
                                        How Verify
                                    </span>
                                </div>
                                <div className="col-4 col-md-4 col-lg-9  mt-1" >
                                    <Select
                                        name="DLVerifyID"
                                        styles={customStylesWithOutColor}
                                        value={howVerifyDrpData?.filter((obj) => obj.value === value?.DLVerifyID)}
                                        options={howVerifyDrpData}
                                        onChange={(e) => onChangeDropDown(e, 'DLVerifyID')}
                                        isClearable
                                        placeholder="Select..."
                                    />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    {/* Basic */}
                    <div className="col-12 col-md-12 col-lg-4 pt-3">
                        <fieldset>
                            <legend>Basic Information</legend>
                            <div className="row mt-4">
                                <div className="col-2 col-md-2 col-lg-3 mt-3">
                                    <span data-toggle="modal" className='new-link px-0'>
                                        Eye Color
                                    </span>
                                </div>
                                <div className="col-4 col-md-4 col-lg-8  mt-2" >
                                    <Select
                                        name="EyeColorID"
                                        styles={customStylesWithOutColor}
                                        value={colorDrpData?.filter((obj) => obj.value === value?.EyeColorID)}
                                        options={colorDrpData}
                                        onChange={(e) => onChangeDropDown(e, 'EyeColorID')}
                                        isClearable
                                        placeholder="Select..."
                                        menuPlacement="top"
                                    />
                                </div>
                                <div className="col-2 col-md-2 col-lg-3 mt-3">
                                    <span data-toggle="modal" className='new-link px-0'>
                                        Hair Color
                                    </span>
                                </div>
                                <div className="col-4 col-md-4 col-lg-8  mt-2" >
                                    <Select
                                        name="HairColorID"
                                        styles={customStylesWithOutColor}
                                        value={colorDrpData?.filter((obj) => obj.value === value?.HairColorID)}
                                        options={colorDrpData}
                                        onChange={(e) => onChangeDropDown(e, 'HairColorID')}
                                        isClearable
                                        placeholder="Select..."
                                        menuPlacement="top"
                                    />
                                </div>
                                <div className="col-2 col-md-2 col-lg-3 mt-3">
                                    <span data-toggle="modal" className='new-link px-0'>
                                        Resident
                                    </span>
                                </div>
                                <div className="col-4 col-md-4 col-lg-8  mt-2" >
                                    <Select
                                        name="ResidentID"
                                        styles={customStylesWithOutColor}
                                        value={residentDrpData?.filter((obj) => obj.value === value?.ResidentID)}
                                        options={residentDrpData}
                                        onChange={(e) => onChangeDropDown(e, 'ResidentID')}
                                        isClearable
                                        placeholder="Select..."
                                        menuPlacement="top"
                                    />
                                </div>
                                <div className="col-2 col-md-2 col-lg-3 mt-3 px-0">
                                    <span data-toggle="modal" className='new-link px-0'>
                                        Marital Status
                                    </span>
                                </div>
                                <div className="col-4 col-md-4 col-lg-8  mt-2" >
                                    <Select
                                        name="MaritalStatusID"
                                        styles={customStylesWithOutColor}
                                        value={maritalDrpData?.filter((obj) => obj.value === value?.MaritalStatusID)}
                                        options={maritalDrpData}
                                        onChange={(e) => onChangeDropDown(e, 'MaritalStatusID')}
                                        isClearable
                                        placeholder="Select..."
                                        menuPlacement="top"
                                    />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div className="col-12  text-right mt-2 mb-2 p-0">
                    <button type="button" className="btn btn-sm btn-success pl-2" onClick={check_Validation_Error}>Update</button>
                </div>
            </div >
        </div>
    )
}

export default MasterGeneral
import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Decrypt_Id_Name, colourStyles, getShowingMonthDateYear } from '../../../Common/Utility';
import { toastifyError } from '../../../Common/AlertMsg';
import { fetchPostData } from '../../../hooks/Api';
import { AgencyContext } from '../../../../Context/Agency/Index';
import DatePicker from "react-datepicker";
import Select from "react-select";
import { threeColArray } from '../../../Common/ChangeArrayFormat';
import { get_LocalStoreData } from '../../../../redux/actions/Agency';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { get_PlateType_Drp_Data, get_VehicleLossCode_Drp_Data } from '../../../../redux/actions/DropDownsData';

const VehicleSearchPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const propertyLossCodeData = useSelector((state) => state.DropDown.vehicleLossCodeDrpData);
    const propertyLossCodevehicle = useSelector((state) => state.DropDown.vehicleLossCodeDrpData);
    const plateTypeIdDrp = useSelector((state) => state.DropDown.vehiclePlateIdDrpData)

    const { setChangesStatus, setVehicleSearchData } = useContext(AgencyContext);

    const [categoryIdDrp, setCategoryIdDrp] = useState([]);
    const [loginAgencyID, setloginAgencyID] = useState('');
    const [loginPinID, setloginPinID,] = useState('');


    const [value, setValue] = useState({
        'IncidentNumber': null, 'VehicleNumber': null, 'LossCodeID': null, 'ReportedDtTm': null, 'ReportedDtTmTo': null, 'VIN': '', 'PlateTypeID': '',
        'LastName': null, 'FirstName': null, 'MiddleName': null, 'CategoryID': null, 'AgencyID': loginAgencyID,
    });

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (localStoreData) {
            setloginAgencyID(parseInt(localStoreData?.AgencyID)); setloginPinID(parseInt(localStoreData?.PINID));
            if (propertyLossCodeData?.length === 0) { dispatch(get_VehicleLossCode_Drp_Data(loginAgencyID)) };
            if (plateTypeIdDrp?.length === 0) { dispatch(get_PlateType_Drp_Data(loginAgencyID)) };
            PropertyType(loginAgencyID);
        }
    }, [localStoreData]);

    const handlChange = (e,) => {
        if (e.target.name === 'VehicleNumber') {
            let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
            if (ele.length === 10) {
                const cleaned = ('' + ele).replace(/[^a-zA-Z0-9\s]/g, '');
                const match = cleaned.match(/^(\w{2})(\d{4})(\d{4})$/);
                if (match) {
                    setValue({
                        ...value,
                        [e.target.name]: match[1] + '-' + match[2] + '-' + match[3]
                    })
                }
            } else {
                ele = e.target.value.split("'").join('').replace(/[^a-zA-Z0-9\s]/g, '');
                setValue({
                    ...value,
                    [e.target.name]: ele
                })
            }
        } else if (e.target.name === 'IncidentNumber') {
            let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
            if (ele.length === 8) {
                const cleaned = ('' + ele).replace(/[^a-zA-Z0-9\s]/g, '');
                const match = cleaned.match(/^(\d{2})(\d{6})$/);
                if (match) {
                    console.log(match)
                    setValue({
                        ...value,
                        [e.target.name]: match[1] + '-' + match[2]
                    })
                }
            } else {
                ele = e.target.value.split("'").join('').replace(/[^0-9\s]/g, '');
                setValue({
                    ...value,
                    [e.target.name]: ele
                })
            }
        }
        else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }

    const ChangeDropDown = (e, name) => {
        if (e) {
            setValue({
                ...value,
                [name]: e.value
            });
        }
        else {
            setValue({
                ...value,
                [name]: null
            });
        }
    }

    const PropertyType = (loginAgencyID) => {
        const val = { AgencyID: loginAgencyID }
        fetchPostData('PropertyCategory/GetDataDropDown_PropertyCategory', val).then((data) => {
            if (data) {
                const res = data?.filter((val) => {
                    if (val.PropertyCategoryCode === "V") return val
                })
                if (res.length > 0) {
                    get_CategoryId_Drp(res[0]?.PropertyCategoryID)
                }
            }
        })
    }

    const get_CategoryId_Drp = (CategoryID) => {
        const val = { CategoryID: CategoryID }
        fetchPostData('Property/GetDataDropDown_PropertyType', val).then((data) => {
            if (data) {
                setCategoryIdDrp(threeColArray(data, 'PropertyDescID', 'Description', 'CategoryID'))
            } else {
                setCategoryIdDrp([]);
            }
        })
    }

    const get_Vehicle_Search = async () => {
        const { IncidentNumber, VehicleNumber, LossCodeID, ReportedDtTm, ReportedDtTmTo, LastName, FirstName, MiddleName, CategoryID, PlateTypeID, VIN } = value;
        const val = {
            'IncidentNumber': IncidentNumber, 'VehicleNumber': VehicleNumber, 'LossCodeID': LossCodeID, 'ReportedDtTm': ReportedDtTm, 'ReportedDtTmTo': ReportedDtTmTo, 'LastName': LastName,
            'MiddleName': MiddleName, 'CategoryID': CategoryID, 'PlateTypeID': PlateTypeID, 'VIN': VIN, 'AgencyID': loginAgencyID, 'FirstName': FirstName,
        };
        fetchPostData("PropertyVehicle/Search_PropertyVehicle", val).then((res) => {
            if (res.length > 0) {
                setVehicleSearchData(res); navigate('/vehicle-search')
                Reset();
            } else {
                setVehicleSearchData([]); toastifyError("Data Not Available");
            }
        })
    }

    const HandleChanges = (e) => {
        if (e.target.name === 'IsEvidence' || e.target.name === 'IsPropertyRecovered' || e.target.name === 'IsImmobalizationDevice' || e.target.name === 'IsEligibleForImmobalization') {
            setChangesStatus(true)
            setValue({
                ...value,
                [e.target.name]: e.target.checked
            })
        }
        else if (e.target.name === 'Value') {
            const ele = e.target.value.replace(/[^0-9]/g, "")
            console.log(ele);
            setChangesStatus(true)
            setValue({
                ...value,
                [e.target.name]: ele
            });
        } else if (e.target.name === 'Weight') {
            const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
            setChangesStatus(true)
            setValue({ ...value, [e.target.name]: checkNumber })
        }
        else {
            setChangesStatus(true)
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }

    const Reset = () => {
        setValue({
            ...value,
            'IncidentNumber': '', 'VehicleNumber': '', 'LossCodeID': '', 'ReportedDtTm': '', 'ReportedDtTmTo': '',
            'LastName': '', 'FirstName': '', 'MiddleName': '', 'CategoryID': '',
        })
    }

    const onClose = () => {
        navigate('/dashboard-page'); Reset();
    }
    const startRef = React.useRef();
    const startRef1 = React.useRef();

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
        }
    };

    return (
        <div className=" section-body pt-3 p-1 bt" >
            <div className="div">
                <div className="dark-row" >
                    <div className="col-12 col-sm-12">
                        <div className="card Agency incident-card ">
                            <div className="card-body" >
                                <div className="row ">
                                    <div className="col-12 ">
                                        <fieldset >
                                            <legend>Vehicle Information</legend>
                                            <div className="row">
                                                <div className="col-2 col-md-2 col-lg-2  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Incident Number</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 text-field ">
                                                    <input type="text" id='IncidentNumber' name='IncidentNumber' maxLength={8} className={''} value={value.IncidentNumber} onChange={handlChange} />
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-3  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Vehicle Number</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 text-field ">
                                                    <input type="text" id='VehicleNumber' style={{ textTransform: "uppercase" }} maxLength={10} name='VehicleNumber' className={''} value={value.VehicleNumber} onChange={handlChange} />
                                                </div>
                                                {/* <div className="col-2 col-md-2 col-lg-2  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Vehicle Reason</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3  mt-2">
                                                    <Select
                                                        name='LossCodeID'
                                                        value={propertyLossCodevehicle?.filter((obj) => obj.value === value?.LossCodeID)}
                                                        styles={colourStyles}
                                                        options={propertyLossCodevehicle}
                                                        onChange={(e) => ChangeDropDown(e, 'LossCodeID')}
                                                        isClearable
                                                        placeholder="Select..."
                                                    />
                                                </div> */}
                                          
                                                <div className="col-2 col-md-2 col-lg-2  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Loss Code</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3  mt-1">
                                                    <Select
                                                        name='LossCodeID'
                                                        value={propertyLossCodeData?.filter((obj) => obj.value === value?.LossCodeID)}
                                                        styles={colourStyles}
                                                        options={propertyLossCodeData}
                                                        onChange={(e) => ChangeDropDown(e, 'LossCodeID')}
                                                        isClearable
                                                        placeholder="Select..."
                                                    />
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-3  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Category</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 mt-2">
                                                    <Select
                                                        name='CategoryID'
                                                        value={categoryIdDrp?.filter((obj) => obj.value === value?.CategoryID)}
                                                        styles={colourStyles}
                                                        options={categoryIdDrp}
                                                        onChange={(e) => ChangeDropDown(e, 'CategoryID')}
                                                        isClearable
                                                        placeholder="Select..."
                                                    />
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-2  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Reported From Date</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 ">
                                                    <DatePicker
                                                        id='ReportedDtTm'
                                                        name='ReportedDtTm'
                                                        ref={startRef}
                                                        onKeyDown={onKeyDown}
                                                        onChange={(date) => { setValue({ ...value, ['ReportedDtTm']: date ? getShowingMonthDateYear(date) : null }) }}
                                                        className=''
                                                        dateFormat="MM/dd/yyyy"
                                                        timeInputLabel
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        autoComplete='Off'
                                                        dropdownMode="select"
                                                        isClearable={value?.ReportedDtTm ? true : false}
                                                        selected={value?.ReportedDtTm && new Date(value?.ReportedDtTm)}
                                                        placeholderText={value?.ReportedDtTm ? value.ReportedDtTm : 'Select...'}
                                                        maxDate={new Date()}
                                                    />
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-3  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Reported To Date</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3">
                                                    <DatePicker
                                                        id='ReportedDtTmTo'
                                                        name='ReportedDtTmTo'
                                                        ref={startRef1}
                                                        onKeyDown={onKeyDown}
                                                        onChange={(date) => { setValue({ ...value, ['ReportedDtTmTo']: date ? getShowingMonthDateYear(date) : null }) }}
                                                        className=''
                                                        dateFormat="MM/dd/yyyy "
                                                        autoComplete='Off'
                                                        timeInputLabel
                                                        isClearable={value?.ReportedDtTmTo ? true : false}
                                                        selected={value?.ReportedDtTmTo && new Date(value?.ReportedDtTmTo)}
                                                        placeholderText={value?.ReportedDtTmTo ? value.ReportedDtTmTo : 'Select...'}
                                                        maxDate={new Date()}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        minDate={new Date(value?.ReportedDtTm)}
                                                    />
                                                </div>
                                     
                                                <div className="col-2 col-md-2 col-lg-2  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Plate Type</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 mt-1">
                                                    <Select
                                                        name='PlateTypeID'
                                                        value={plateTypeIdDrp?.filter((obj) => obj.value === value?.PlateTypeID)}
                                                        styles={colourStyles}
                                                        options={plateTypeIdDrp}
                                                        onChange={(e) => ChangeDropDown(e, 'PlateTypeID')}
                                                        isClearable
                                                        placeholder="Select..."
                                                    />
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-3  mt-2 ">
                                                    <label htmlFor="" className='new-label'>VIN</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 text-field mt-1">
                                                    <input type="text" name='VIN' id='VIN' maxLength={17} value={value?.VIN} onChange={HandleChanges} className='' required />
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset >
                                            <legend>Vehicle Owner</legend>
                                            <div className="row">
                                                <div className="col-2 col-md-2 col-lg-1  mt-2 ">
                                                    <label htmlFor="" className='new-label'>Last Name</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 text-field mt-1">
                                                    <input type="text" id='LastName' name='LastName' className={''} value={value.LastName} onChange={handlChange} />
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-1  mt-2 ">
                                                    <label htmlFor="" className='new-label'>First Name</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 text-field mt-1">
                                                    <input type="text" id='FirstName' name='FirstName' className={''} value={value.FirstName} onChange={handlChange} />
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-1  mt-2 px-0">
                                                    <label htmlFor="" className='new-label px-0'>Middle Name</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 text-field mt-1">
                                                    <input type="text" id='MiddleName' name='MiddleName' className={''} value={value.MiddleName} onChange={handlChange} />
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-box text-right  mr-1 mb-2">
                                <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => { get_Vehicle_Search() }} >Search</button>
                                <button type="button" data-dismiss="modal" className="btn btn-sm btn-success mr-2" onClick={() => { onClose(); }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VehicleSearchPage
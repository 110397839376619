import { Decrypt_Id_Name } from "../../Components/Common/Utility";
import { ScreenPermision } from "../../Components/hooks/Api";

import {
    //----------------------------------------Drop_DownsDataArrays-----------------------------------
    Agency_OfficerDrp_Data, Screen_Permissions,

} from "../actionTypes"

const initialState = {
    // All use
    uniqueId: sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '',
    agencyOfficerDrpData: [], effectiveScreenPermission: [],

}

const IncidentReducer = (state = initialState, action) => {
    switch (action.type) {
        case Agency_OfficerDrp_Data:
            return {
                ...state,
                agencyOfficerDrpData: action.payload
            }
        case Screen_Permissions:
            return {
                ...state,
                effectiveScreenPermission: action.payload
            }
        default: return state
    }
}

export default IncidentReducer

import React, { useContext, useEffect, useState } from 'react';
import { AgencyContext } from '../../../Context/Agency/Index';
import Tab from '../../Utility/Tab/Tab';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SubTab from '../../Utility/Tab/SubTab';
import { IncidentTabs } from '../../Utility/Tab/TabsArray';
import Pin from './IncidentTab/PIN/Pin';
import TypeOfSecurity from './IncidentTab/TypeOfSecurity/TypeOfSecurity';
import DispatchActivity from './IncidentTab/DispatchActivity/DispatchActivity';
import Narrative from './IncidentTab/NarrativesCom/Narrative';
import LocationHistory from './IncidentTab/LocationHistory/LocationHistory';
import IncidentHome from './IncidentTab/IncidentHome';
import Log from '../Log/Log';
import { useDispatch } from 'react-redux';
import { Decrypt_Id_Name, DecryptedList, base64ToString } from '../../Common/Utility';
import { get_LocalStoreData } from '../../../redux/actions/Agency';
import { useSelector } from 'react-redux';

const IncidentTab = () => {

    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };
    const query = useQuery();
    let IncID = query?.get("IncId");
    var IncNo = query?.get("IncNo");
    var IncSta = query?.get("IncSta");


    const dispatch = useDispatch()
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const localStoreData = useSelector((state) => state.Agency.localStoreData);

    const navigate = useNavigate()
    const { changesStatus, tabCount } = useContext(AgencyContext);
    const [status, setStatus] = useState();
    const [showIncPage, setShowIncPage] = useState('home');
    const [incidentReportedDate, setIncidentReportedDate] = useState(null);
    const [incidentId, setIncidentId] = useState('')
    const iconHome = <i className="fa fa-home" style={{ fontSize: '20px' }}></i>

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (IncSta === true || IncSta === 'true') { setStatus(true); }
        else { setStatus(false); }
        setShowIncPage('home');
    }, [IncSta]);

    useEffect(() => {
        if (IncID) { setIncidentId(base64ToString(IncID)) }
    }, [IncID]);

    return (
        <div className="section-body view_page_design pt-1 p-1 bt" >
            <div className="div">
                <div className="col-12  inc__tabs">
                    <Tab />
                </div>
                <div className="dark-row" >
                    <div className="col-12 col-sm-12">
                        <div className="card Agency incident-card ">
                            <div className="card-body" >
                                <div className="row " style={{ marginTop: '-18px', marginLeft: '-18px' }}>
                                    <div className="col-12   incident-tab">
                                        {/* <SubTab tabs={IncidentTabs}  showPage={showIncPage} setShowPage={setShowIncPage} /> */}
                                        <ul className='nav nav-tabs'>
                                            <Link
                                                className={`nav-item ${showIncPage === 'home' ? 'active' : ''} ${!status ? 'disabled' : ''}`}
                                                to={`/Inc-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}`}
                                                data-toggle={changesStatus ? "modal" : "pill"}
                                                data-target={changesStatus ? "#SaveModal" : ''}
                                                style={{ color: showIncPage === 'home' ? 'Red' :  '#000',  }}
                                                aria-current="page"
                                                onClick={() => { if (!changesStatus) setShowIncPage('home') }}
                                            >
                                                {iconHome}
                                            </Link>
                                            <span
                                                className={`nav-item ${showIncPage === 'Pin Activity' ? 'active' : ''} ${!status ? 'disabled' : ''}`}
                                                // to={`/Inc-Home?IncId=${IncID}`}
                                                data-toggle={changesStatus ? "modal" : "pill"}
                                                data-target={changesStatus ? "#SaveModal" : ''}
                                                style={{ color: showIncPage === 'Pin Activity' ? 'Red' : tabCount?.PINActivityCount > 0 ? 'blue' :  '#000', }}
                                                aria-current="page"
                                                onClick={() => { if (!changesStatus) setShowIncPage('Pin Activity') }}
                                            >
                                                Pin Activity{`${tabCount?.PINActivityCount > 0 ? '(' + tabCount?.PINActivityCount + ')' : ''}`}
                                            </span>
                                            <span
                                                className={`nav-item ${showIncPage === 'TypeOfSecurity' ? 'active' : ''} ${!status ? 'disabled' : ''}`}
                                                // to={`/Inc-Home?IncId=${IncID}`}
                                                data-toggle={changesStatus ? "modal" : "pill"}
                                                data-target={changesStatus ? "#SaveModal" : ''}
                                                style={{ color: showIncPage === 'type of security' ? 'Red' : tabCount?.SecurityCount > 0 ? 'blue' :  '#000', }}
                                                aria-current="page"
                                                onClick={() => { if (!changesStatus) setShowIncPage('type of security') }}
                                            >
                                                Type Of Security{`${tabCount?.SecurityCount > 0 ? '(' + tabCount?.SecurityCount + ')' : ''}`}
                                            </span>
                                            <span
                                                className={`nav-item ${showIncPage === 'DispatchActivity' ? 'active' : ''} ${!status ? 'disabled' : ''}`}
                                                // to={`/Inc-Home?IncId=${IncID}`}
                                                data-toggle={changesStatus ? "modal" : "pill"}
                                                data-target={changesStatus ? "#SaveModal" : ''}
                                                style={{ color: showIncPage === 'dispatch activity' ? 'Red' : tabCount?.IncidentDispatcherCount > 0 ? 'blue' :  '#000', }}
                                                aria-current="page"
                                                onClick={() => { if (!changesStatus) setShowIncPage('dispatch activity') }}
                                            >
                                                Dispatch Activity{`${tabCount?.IncidentDispatcherCount > 0 ? '(' + tabCount?.IncidentDispatcherCount + ')' : ''}`}
                                            </span>
                                            <span
                                                className={`nav-item ${showIncPage === 'Report' ? 'active' : ''} ${!status ? 'disabled' : ''}`}
                                                // to={`/Inc-Home?IncId=${IncID}`}
                                                data-toggle={changesStatus ? "modal" : "pill"}
                                                data-target={changesStatus ? "#SaveModal" : ''}
                                                style={{ color: showIncPage === 'Report' ? 'Red' : tabCount?.NarrativeCount > 0 ? 'blue' :  '#000', }}
                                                aria-current="page"
                                                onClick={() => { if (!changesStatus) setShowIncPage('Report') }}
                                            >
                                                Report{`${tabCount?.NarrativeCount > 0 ? '(' + tabCount?.NarrativeCount + ')' : ''}`}
                                            </span>
                                            <span
                                                className={`nav-item ${showIncPage === 'LocationHistory' ? 'active' : ''} ${!status ? 'disabled' : ''}`}
                                                // to={`/Inc-Home?IncId=${IncID}`}
                                                data-toggle={changesStatus ? "modal" : "pill"}
                                                data-target={changesStatus ? "#SaveModal" : ''}
                                                style={{ color: showIncPage === 'location history' ? 'Red' : tabCount?.IncidentLocationCount > 0 ? 'blue' :  '#000', }}
                                                aria-current="page"
                                                onClick={() => { if (!changesStatus) setShowIncPage('location history') }}
                                            >
                                                Location History{`${tabCount?.IncidentLocationCount > 0 ? '(' + tabCount?.IncidentLocationCount + ')' : ''}`}
                                            </span>
                                            <span
                                                className={`nav-item ${showIncPage === 'AuditLog' ? 'active' : ''} ${!status ? 'disabled' : ''}`}
                                                // to={`/Inc-Home?IncId=${IncID}`}
                                                data-toggle={changesStatus ? "modal" : "pill"}
                                                data-target={changesStatus ? "#SaveModal" : ''}
                                                style={{ color: showIncPage === 'AuditLog' ? 'Red' : tabCount?.IncidentLocationCount > 0 ? 'blue' :  '#000', }}
                                                aria-current="page"
                                                onClick={() => { if (!changesStatus) setShowIncPage('AuditLog') }}
                                            >
                                                Audit Log
                                            </span>
                                            {/* <span
                                                className={`nav-item ${showIncPage === 'Summary' ? 'active' : ''} ${!status  ? 'disabled' : ''}`}
                                                to={'/Inc-Home?page=Summary'}
                                                data-toggle={changesStatus ? "modal" : "pill"}
                                                data-target={changesStatus ? "#SaveModal" : ''}
                                                style={{ color: showIncPage === 'Summary' ? 'Red' : tabCount?.IncidentLocationCount > 0 ? 'blue' :  '#000', }}
                                                aria-current="page"
                                                onClick={() => { if (!changesStatus) setShowIncPage('Summary') }}
                                            >
                                                Summary
                                            </span> */}
                                        </ul>
                                    </div>
                                </div>
                                {
                                    showIncPage === 'home' ?
                                        <IncidentHome {...{ incidentReportedDate, setIncidentReportedDate }} />
                                        :
                                        showIncPage === 'Pin Activity' ?
                                            <Pin {...{ incidentReportedDate }} />
                                            :
                                            showIncPage === 'type of security' ?
                                                <TypeOfSecurity />
                                                :
                                                showIncPage === 'dispatch activity' ?
                                                    <DispatchActivity {...{ incidentReportedDate }} />
                                                    :
                                                    showIncPage === 'Report' ?
                                                        <Narrative {...{ incidentReportedDate }} />
                                                        :
                                                        showIncPage === 'location history' ?
                                                            <LocationHistory />
                                                            :
                                                            showIncPage === 'AuditLog' ?
                                                                <Log ParentId={incidentId} scrCode={'I089'} url={'Log/GetData_Log'} para={'IncidentID'} />
                                                                :

                                                                <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IncidentTab
import axios from 'axios';
import { fetchPostData } from '../../Components/hooks/Api';
import { DL_State_DrpData, Incident_ReportDate, Local_Store_Data, NameType_Data, PropertyType_Data } from '../actionTypes';
import * as api from '../api'
import { Comman_changeArrayFormat, threeColArray } from '../../Components/Common/ChangeArrayFormat';

//----------get the All  local Array data  with unique ID ------------Dk-> 
export const insert_LocalStoreData = (formData, Tokens) => async (dispatch) => {
    // console.log("insertLocalpara", Tokens)
    try {
        // const res = await api.insert_LocalStoreData(formData);
        dispatch({ type: Local_Store_Data, payload: Tokens });
    } catch (error) {
        console.log(error);
        dispatch({ type: Local_Store_Data, payload: Tokens });
    }
};

//----------get the All  local Array data  with unique ID ------------Dk-> 
export const get_LocalStoreData = (UniqueId) => async (dispatch) => {
    const val = { UniqueId: UniqueId };
    try {
        const data = await api.get_LocalStoreData(val);
        // const data = await axios.post('LocalStorage/GetData_UniqueLocalStorage', val)
        // console.log(data)
        const TextData = JSON.parse(data?.data?.data);
        const dataObj = TextData?.Table[0]
        // console.log("get Local data", dataObj)
        dispatch({ type: Local_Store_Data, payload: dataObj });
    } catch (error) {
        dispatch({ type: Local_Store_Data, payload: [] });
    }
};

export const get_Inc_ReportedDate = (ID) => async (dispatch) => {
    const val = { IncidentID: ID };
    try {
        const data = await api.get_Inc_ReportedDate(val);
        const TextData = JSON.parse(data?.data?.data);
        const dataObj = TextData?.Table[0]
        dispatch({ type: Incident_ReportDate, payload: dataObj?.ReportedDate });
    } catch (error) {
        dispatch({ type: Incident_ReportDate, payload: [] });
    }
};

export const get_NameTypeData = (LoginAgencyID) => async (dispatch) => {
    const val = { AgencyID: LoginAgencyID }
    try {
        let res = await api.get_NameTypeData(val);
        let TextData = JSON.parse(res?.data?.data);
        let dataObj = TextData?.Table
        // const dataArr = dataObj?.filter((val) => { if (val.NameTypeCode === "I") return val });
        dispatch({ type: NameType_Data, payload: threeColArray(dataObj, 'NameTypeID', 'Description', 'NameTypeCode') });

        // const id = data?.filter((val) => { if (val.NameTypeCode === "I") return val })
        // threeColArray(data, 'NameTypeID', 'Description', 'NameTypeCode')
    } catch {
        dispatch({ type: NameType_Data, payload: [] });
    }
}




import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Select from "react-select";
import { AddDeleteUpadate, fetchPostData } from '../../../../../../hooks/Api';
import DataTable from 'react-data-table-component';
import { Comman_changeArrayFormat, Comman_changeArrayFormatBasicInfowithoutcode, threeColVictimOffenseArray, offenseArray, AssaultInjuryComArrayFormat, threeColArray, threeColVictimInjuryArray } from '../../../../../../Common/ChangeArrayFormat';
import { toastifySuccess } from '../../../../../../Common/AlertMsg';
import DeletePopUpModal from '../../../../../../Common/DeleteModal';
import { RequiredFieldIncident } from '../../../../../Utility/Personnel/Validation';
import { AgencyContext } from '../../../../../../../Context/Agency/Index';
import { components } from "react-select";
import SelectBox from '../../../../../../Common/SelectBox';
import { ErrorStyleOffenderUse, ErrorTooltip, OffenderUseError_N, OffenderUseError_Other } from '../../../../../Offense/OffenceTab/ErrorNibrs';
import { get_LocalStoreData } from '../../../../../../../redux/api';
import { Decrypt_Id_Name } from '../../../../../../Common/Utility';
import { useDispatch, useSelector } from 'react-redux';
import NameListing from '../../../../../ShowAllList/NameListing';
import { get_ScreenPermissions_Data } from '../../../../../../../redux/actions/IncidentAction';

const Home = (props) => {

  const { ListData, DecNameID, DecMasterNameID, DecIncID } = props

  const dispatch = useDispatch();
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
  const effectiveScreenPermission = useSelector((state) => state.Incident.effectiveScreenPermission);




  const { nameSingleData, localStoreArray, get_LocalStorage, inActiveStatus, changesStatus } = useContext(AgencyContext);

  const { get_NameVictim_Count, } = useContext(AgencyContext);
  const [disabled, setDisabled] = useState(false);
  const [typeOfSecurityEditVal, setTypeOfSecurityEditVal] = useState();

  const SelectedValue = useRef();
  const [offenderOffenseDrp, setOffenderOffenseDrp] = useState();
  const [offenderAssaultDrp, setOffenderAssaultDrp] = useState();
  const [value, setValue] = useState()
  const [nameID, setNameID] = useState();
  const [loginPinID, setLoginPinID,] = useState('');
  const [loginAgencyID, setLoginAgencyID] = useState('');
  const [incidentID, setIncidentID] = useState('');
  const [assaultEditVal, setAssaultEditVal] = useState();
  const [injuryTypeEditVal, setInjuryTypeEditVal] = useState();
  const [injuryTypeDrp, setInjuryTypeDrp] = useState();
  const [propertyDrp, setPropertyDrp] = useState();
  const [propertyEditVal, setPropertyEditVal] = useState();



  const [multiSelected, setMultiSelected] = useState({
    OffenseID: null, InjuryID: null, AssaultID: null, PropertyID: null,
  })



  useEffect(() => {
    if (localStoreData) {
      setLoginAgencyID(localStoreData?.AgencyID); setLoginPinID(localStoreData?.PINID);
      dispatch(get_ScreenPermissions_Data("N058", localStoreData?.AgencyID, localStoreData?.PINID));
    }
  }, [localStoreData]);

  useEffect(() => {
    if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
      if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
    }
  }, []);

  useEffect(() => {
    if (DecNameID) {
      setNameID(DecNameID);
      setIncidentID(DecIncID);

    }

  }, [DecNameID, loginPinID,]);


  useEffect(() => {
    if (DecNameID && DecMasterNameID) {
      setValue({ ...value, 'NameID': DecNameID, 'CreatedByUserFK': loginPinID, })
    }

  }, [DecNameID, DecMasterNameID, loginPinID]);

  useEffect(() => {
    if (nameID) { get_Offense_Data(nameID); get_InjuryType_Data(nameID); get_Offender_Assault_Data(DecNameID); get_Offender_Property_Data(nameID); }
  }, [nameID])
  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
  const [errors, setErrors] = useState({
    'DropError': '',
  })

  const Reset = () => {
    setValue({
      ...value,
      'OfficerID': '',
    })
    setErrors({
      ...errors,
      'DropError': '',
    });
  }

  const check_Validation_Error = (e) => {
    if (RequiredFieldIncident(value.OfficerID)) {
      setErrors(prevValues => { return { ...prevValues, ['DropError']: RequiredFieldIncident(value.OfficerID) } })
    }
  }

  const { DropError } = errors

  // useEffect(() => {
  //   if (localStoreArray) {
  //     if (localStoreArray?.AgencyID && localStoreArray?.PINID) {
  //       setLoginAgencyID(localStoreArray?.AgencyID);
  //       setLoginPinID(localStoreArray?.PINID);
  //       if (localStoreArray.IncidentID) { setIncidentID(localStoreArray?.IncidentID); }
  //       if (localStoreArray.NameID) { setNameID(localStoreArray?.NameID); }
  //     }
  //   }
  // }, [localStoreArray])

  // useEffect(() => {
  //   if (DropError === 'true') {
  //     Add_Victim_Officer();
  //   }
  // }, [DropError])
  useEffect(() => {
    if (typeOfSecurityEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['OffenseID']: typeOfSecurityEditVal } }) }
    if (injuryTypeEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['InjuryID']: injuryTypeEditVal } }) }
    if (propertyEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['PropertyID']: propertyEditVal } }) }
    if (assaultEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['AssaultID']: assaultEditVal } }) }

  }, [injuryTypeEditVal, typeOfSecurityEditVal, assaultEditVal, propertyEditVal])


  useEffect(() => {
    if (incidentID) {
      get_Data_Offense_Drp(incidentID, nameID);
      get_Offender_Assault_Drp(incidentID, nameID);
      get_Data_InjuryType_Drp(incidentID, nameID);
      get_Offender_Property_Drp(incidentID, nameID);
    }

  }, [nameID, incidentID, loginAgencyID])

  const get_Offense_Data = () => {
    const val = {
      'NameID': nameID,
    }
    fetchPostData('OffenderOffense/GetData_OffenderOffense', val).then((res) => {
      if (res) {
        setTypeOfSecurityEditVal(offenseArray(res, 'OffenderOffenseID', 'OffenseID', 'NameID', 'VictimID', 'Offense_Description', 'PretendToBeID'));
      } else {
        setTypeOfSecurityEditVal([]);
      }
    })
  }
  const get_InjuryType_Data = () => {
    const val = {
      'NameID': nameID,
    }
    fetchPostData('OffenderInjury/GetData_OffenderInjury', val).then((res) => {
      if (res) {
        setInjuryTypeEditVal(AssaultInjuryComArrayFormat(res, 'InjuryID', 'NameID', 'PretendToBeID', 'OffenderInjuryID', 'Injury_Description'));
      } else {
        setInjuryTypeEditVal([]);
      }
    })
  }
  const get_Offender_Assault_Data = () => {

    const val = {
      'NameID': nameID,
    }
    fetchPostData('OffenderAssault/GetData_OffenderAssault', val).then((res) => {
      if (res) {
        setAssaultEditVal(AssaultInjuryComArrayFormat(res, 'OffenderAssaultID', 'NameID', 'PretendToBeID', 'AssaultID', 'Assault_Description'));
      } else {
        setAssaultEditVal([]);
      }
    })
  }
  const get_Offender_Property_Data = () => {
    const val = {
      'NameID': nameID,
    }
    fetchPostData('OffenderProperty/GetData_OffenderProperty', val).then((res) => {
      if (res) {
        setPropertyEditVal(Comman_changeArrayFormatBasicInfowithoutcode(res, 'OffenderPropertyID', 'NameID', 'PretendToBeID', 'PropertyID', 'Description'));
      } else {
        setPropertyEditVal([]);
      }
    })
  }

  const get_Data_Offense_Drp = (incidentID, nameID) => {

    const val = {
      'NameID': nameID,
      'IncidentId': incidentID,
    }
    fetchPostData('OffenderOffense/GetData_InsertOffenderOffense', val).then((data) => {
      if (data) {
        setOffenderOffenseDrp(threeColVictimOffenseArray(data, 'CrimeID', 'Offense_Description',))
      } else {
        setOffenderOffenseDrp([])
      }
    })
  }

  const get_Data_InjuryType_Drp = (incidentID, nameID) => {
    const val = {
      'NameID': nameID,
      'IncidentId': incidentID,
    }
    fetchPostData('OffenderInjury/GetData_InsertOffenderInjury', val).then((data) => {
      if (data) {

        setInjuryTypeDrp(threeColVictimInjuryArray(data, 'VictimInjuryID', 'Description', 'InjuryCode'))
      } else {
        setInjuryTypeDrp([])
      }
    })
  }


  const get_Offender_Assault_Drp = (incidentID, nameID) => {
    const val = {
      'NameID': nameID,
      'IncidentId': incidentID,
    }
    fetchPostData('OffenderAssault/GetData_InsertOffenderAssault', val).then((data) => {
      if (data) {
        setOffenderAssaultDrp(Comman_changeArrayFormat(data, 'AssaultTypeID', 'Description'))
      } else {
        setOffenderAssaultDrp([])
      }
    })
  }


  const get_Offender_Property_Drp = (incidentID, nameID) => {
    const val = {
      'NameID': nameID,
      'IncidentId': incidentID,
    }
    fetchPostData('OffenderProperty/GetData_InsertOffenderProperty', val).then((data) => {
      if (data) {
        setPropertyDrp(threeColArray(data, 'PropertyID', 'Description'))
      } else {
        setPropertyDrp([])
      }
    })
  }

  const ChangeDropDown = (e, name) => {
    if (e) {
      setValue({
        ...value,
        [name]: e.value
      })
    } else setValue({
      ...value,
      [name]: null
    })
  }

  const onClear = () => {
    SelectedValue?.current?.clearValue();
  };


  const offenceChange = (multiSelected) => {
    setMultiSelected({
      ...multiSelected,
      OffenseID: multiSelected
    })
    const len = multiSelected.length - 1
    if (multiSelected?.length < typeOfSecurityEditVal?.length) {
      let missing = null;
      let i = typeOfSecurityEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(typeOfSecurityEditVal[--i])) ? missing : typeOfSecurityEditVal[i];
      }
      DelSertBasicInfo(missing.value, 'OffenderOffenseID', 'OffenderOffense/Delete_OffenderOffense')
    } else {
      InSertBasicInfo(multiSelected[len].value, 'OffenseID', 'OffenderOffense/Insert_OffenderOffense')
    }
  }

  const PropertyChange = (multiSelected) => {
    setMultiSelected({
      ...multiSelected,
      PropertyID: multiSelected
    })
    const len = multiSelected.length - 1
    if (multiSelected?.length < propertyEditVal?.length) {
      let missing = null;
      let i = propertyEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(propertyEditVal[--i])) ? missing : propertyEditVal[i];
      }
      DelSertBasicInfo(missing.value, 'OffenderPropertyID', 'OffenderProperty/Delete_OffenderProperty')
    } else {
      InSertBasicInfo(multiSelected[len].value, 'PropertyID', 'OffenderProperty/Insert_OffenderProperty')
    }
  }

  const AssaultTypeChange = (multiSelected) => {
    setMultiSelected({
      ...multiSelected,
      AssaultID: multiSelected
    })
    const len = multiSelected.length - 1
    if (multiSelected?.length < assaultEditVal?.length) {
      let missing = null;
      let i = assaultEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(assaultEditVal[--i])) ? missing : assaultEditVal[i];
      }
      DelSertBasicInfo(missing.value, 'OffenderAssaultID', 'OffenderAssault/DeleteOffenderAssault')
    } else {
      InSertBasicInfo(multiSelected[len].value, 'AssaultID', 'OffenderAssault/InsertOffenderAssault')
    }
  }



  const InjuryTypeChange = (multiSelected) => {
    setMultiSelected({
      ...multiSelected,
      InjuryID: multiSelected
    })
    const len = multiSelected.length - 1
    if (multiSelected?.length < injuryTypeEditVal?.length) {
      let missing = null;
      let i = injuryTypeEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(injuryTypeEditVal[--i])) ? missing : injuryTypeEditVal[i];
      }
      DelSertBasicInfo(missing.id, 'OffenderInjuryID', 'OffenderInjury/DeleteOffenderInjury')
    } else {
      InSertBasicInfo(multiSelected[len].value, 'InjuryID', 'OffenderInjury/InsertOffenderInjury')
    }
  }
  const InSertBasicInfo = (id, col1, url) => {
    setDisabled(true)
    const val = {
      'NameID': nameID,
      [col1]: id,
      'CreatedByUserFK': loginPinID,
    }
    AddDeleteUpadate(url, val).then((res) => {
      if (res) {
        setDisabled(false); toastifySuccess(res.Message);
        col1 === 'OffenseID' && get_Offense_Data(); get_Data_Offense_Drp(incidentID, nameID);
        col1 === 'PropertyID' && get_Offender_Property_Data(); get_Offender_Property_Drp(incidentID, nameID);
        col1 === 'InjuryID' && get_InjuryType_Data();
        col1 === 'AssaultID' && get_Offender_Assault_Data(); get_Offender_Assault_Drp(incidentID, nameID);
      } else {
        console.log("Somthing Wrong");
      }
    })
  }



  const DelSertBasicInfo = (OffenderOffenseID, col1, url) => {


    setDisabled(true)
    const val = {
      [col1]: OffenderOffenseID,
      'DeletedByUserFK': loginPinID,
    }
    AddDeleteUpadate(url, val).then((res) => {
      if (res) {
        setDisabled(false); toastifySuccess(res.Message);
        col1 === 'OffenderOffenseID' && get_Offense_Data(); get_Data_Offense_Drp(incidentID, nameID);
        col1 === 'OffenderPropertyID' && get_Offender_Property_Data(); get_Offender_Property_Drp(incidentID, nameID);
        col1 === 'OffenderInjuryID' && get_InjuryType_Data()
        col1 === 'OffenderAssaultID' && get_Offender_Assault_Data(); get_Offender_Assault_Drp(incidentID, nameID);
      } else {
        console.log("Somthing Wrong");
      }
    })
  }
  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      minHeight: 60,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };


  return (
    <>
              <NameListing {... { ListData }} />
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12">
                    <div className="row">
                      <div className="col-2 col-md-2 col-lg-2 mt-4">
                        <label htmlFor="" className='new-label'>Offence</label>
                      </div>
                      <div className="col-10 col-md-10 col-lg-10  mt-2">
                        <SelectBox
                          className="basic-multi-select"
                          options={offenderOffenseDrp}
                          styles={customStylesWithOutColor}
                          // isClearable={false}
                          // isDisabled={disabled}
                          name='OffenderOffenseID'
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={true}
                          components={{ MultiValue, }}
                          onChange={(e) => offenceChange(e)}
                          value={multiSelected.OffenseID}
                          placeholder='Select Pretented To Be From List'
                        />
                      </div>
                      <div className="col-2 col-md-2 col-lg-2 mt-5">
                        <Link to={'/ListManagement?page=Assault%20Type&call=/Off-Home?page=CrimeInformation'} className='new-link'>
                          AssaultType
                        </Link>
                      </div>
                      <div className="col-4 col-md-4 col-lg-4 mt-4">
                        <SelectBox
                          className="basic-multi-select"
                          styles={customStylesWithOutColor}
                          name='OffenderAssaultID'
                          options={offenderAssaultDrp}
                          isClearable={false}
                          // isDisabled={disabled}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={true}
                          components={{ MultiValue, }}
                          onChange={(e) => AssaultTypeChange(e)}
                          value={multiSelected.AssaultID}
                          placeholder='Select Point Of Exit From List'

                        />
                      </div>
                      <div className="col-2 col-md-2 col-lg-2 mt-5">
                        <Link to={'/ListManagement?page=Injury%20Type&call=/Name-Home?page=Offender'} className='new-link'>
                          InjuryType
                        </Link>
                      </div>
                      <div className="col-4 col-md-4 col-lg-4  mt-4 ">
                        <SelectBox
                          className="basic-multi-select"
                          styles={customStylesWithOutColor}
                          name='OffenderInjuryID'
                          options={injuryTypeDrp}
                          isClearable={false}
                          // isDisabled={disabled}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={true}
                          components={{ MultiValue, }}
                          onChange={(e) => InjuryTypeChange(e)}
                          value={multiSelected.InjuryID}
                          placeholder='Select Point Of Entry From List'
                        />
                      </div>

                    </div>
                  </div>


                  <div className="col-2 col-md-2 col-lg-2 mt-5">
                    <label htmlFor="" className='new-label'>
                      Property
                    </label>
                  </div>
                  <div className="col-10 col-md-10 col-lg-10 mt-4">
                    <SelectBox
                      className="basic-multi-select"
                      name='OffenderPropertyID'
                      options={propertyDrp}
                      isClearable={false}
                      // isDisabled={disabled}
                      isMulti
                      // style={ErrorStyleOffenderUse(propertyEditVal)}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={true}
                      components={{ MultiValue, }}
                      onChange={(e) => PropertyChange(e)}
                      value={multiSelected.PropertyID}
                      placeholder='Select Offender Using From List'
                      styles={customStylesWithOutColor}
                    />
                  </div>
                </div>
              </div>
              </>
  )
}

export default Home
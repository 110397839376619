import React, { useContext, useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { AgencyContext } from '../../../../Context/Agency/Index';
import SubTab from '../../../Utility/Tab/SubTab';
import { AgencyTabs } from '../../../Utility/Tab/TabsArray';
import Division from '../AgencyTab/Division/Division';
import FieldSecurity from '../AgencyTab/FieldSecurity/FieldSecurity';
import Group from '../AgencyTab/Group/Group';
import Home from '../AgencyTab/Home/Index';
import LockRestrictLevel from '../AgencyTab/LockRestrictLevel/LockRestrictLevel';
import Login from '../AgencyTab/Login/Login';
import Member from '../AgencyTab/Member/Member';
import PasswordSetting from '../AgencyTab/PasswordSetting/PasswordSetting';
import Personnel from '../AgencyTab/Personnel/Personnel';
import Ranks from '../AgencyTab/Ranks/Ranks';
import Roster from '../AgencyTab/Roster/Roster';
import ScreenPermission from '../AgencyTab/ScreenPermission/ScreenPermission';
import Shift from '../AgencyTab/Shift/Shift';
import Unit from '../AgencyTab/Unit/Unit';
import UnitAssignment from '../AgencyTab/UnitAssignment/UnitAssignment';
import AgencyContact from '../AgencyTab/AgencyContact/AgencyConatct';
import { Decrypt_Id_Name, base64ToString } from '../../../Common/Utility';
import AgencySetting from '../AgencyTab/AgencySetting/AgencySetting';
import PersonnelTab from '../../PersonnelCom/PersonnelModal/PersonnelTab';
import TabAgency from '../../../Utility/Tab/TabAgency';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../redux/actions/Agency';

const AgencyTab = ({ send_Notification }) => {

    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    // let DecOffID = 0
    const query = useQuery();
    var AId = query?.get("Aid");
    var AgySta = query?.get("ASta");
    var AgyName = query?.get("AgyName");
    var ORINum = query?.get("ORINum");


    const dispatch = useDispatch()
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const localStoreData = useSelector((state) => state.Agency.localStoreData);

    const { count, changesStatus } = useContext(AgencyContext);
    const iconHome = <i className="fa fa-home" style={{ fontSize: '20px' }}></i>
    const [pinID, setPinID] = useState();
    const [showPage, setShowPage] = useState('home');
    const [status, setStatus] = useState();
    const [allowMultipleLogin, setAllowMultipleLogin] = useState('');

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (AgySta === true || AgySta === 'true') { setStatus(true); }
        else { setStatus(false); }
        setShowPage('home');
    }, [AgySta]);


    return (
        <div className="section-body pt-1 p-1 bt" >
            <div className="div">
                <div className="col-12  inc__tabs">
                    <TabAgency />
                </div>
                <div className="dark-row" >
                    <div className="col-12 col-sm-12">
                        <div className="card Agency incident-card ">
                            <div className="card-body" >
                                <div className="row " style={{ marginTop: '-18px', marginLeft: '-18px' }}>
                                    <div className="col-12 incident-tab">
                                        <ul className='nav nav-tabs'>
                                            <Link
                                                className={`nav-item ${showPage === 'home' ? 'active' : ''}${!status ? 'disabled' : ''}`}
                                                to={`/agencyTab?Aid=${AId}&ASta=${AgySta}&AgyName=${AgyName}&ORINum=${ORINum}`}
                                                data-toggle={changesStatus ? "modal" : "pill"}
                                                data-target={changesStatus ? "#SaveModal" : ''}
                                                style={{ color: showPage === 'home' ? 'Red' : '#000' }}
                                                aria-current="page"
                                                onClick={() => { if (!changesStatus) setShowPage('home') }}
                                            >
                                                {iconHome}
                                            </Link>
                                            <span
                                                className={`nav-item ${showPage === 'Group' ? 'active' : ''} ${!status ? 'disabled' : ''}`}
                                                data-toggle={changesStatus ? "modal" : "pill"}
                                                data-target={changesStatus ? "#SaveModal" : ''}
                                                style={{ color: showPage === 'Group' ? 'Red' : count?.GroupCount > 0 && status ? 'blue' : '#000' }}
                                                aria-current="page"
                                                onClick={() => { if (!changesStatus) setShowPage('Group') }}
                                            >
                                                Group{count?.GroupCount > 0 && status ? '(' + count?.GroupCount + ')' : ''}
                                            </span>
                                            {/* <span
                                                className={`nav-item ${showPage === 'personnel' ? 'active' : ''} ${!status ? 'disabled' : ''}`}
                                                data-toggle={changesStatus ? "modal" : "pill"}
                                                data-target={changesStatus ? "#SaveModal" : ''}
                                                style={{ color: showPage === 'personnel' ? 'Red' : count?.PersonnelCount > 0 ? 'blue' : '#000' }}
                                                aria-current="page"
                                                onClick={() => { if (!changesStatus) setShowPage('personnel') }}
                                            >
                                                Personnel
                                            </span> */}
                                            <span
                                                className={`nav-item ${showPage === 'member' ? 'active' : ''} ${!status ? 'disabled' : ''}`}
                                                data-toggle={changesStatus ? "modal" : "pill"}
                                                data-target={changesStatus ? "#SaveModal" : ''}
                                                style={{ color: showPage === 'member' ? 'Red' : count?.member > 0 && status ? 'blue' : '#000' }}
                                                aria-current="page"
                                                onClick={() => { if (!changesStatus) setShowPage('member') }}
                                            >
                                                Group Member{count?.member > 0 && status ? '(' + count?.member + ')' : ''}
                                            </span>
                                            {/* <span
                                                className={`nav-item ${showPage === 'division' ? 'active' : ''} ${!status ? 'disabled' : ''}`}
                                                data-toggle={changesStatus ? "modal" : "pill"}
                                                data-target={changesStatus ? "#SaveModal" : ''}
                                                style={{ color: showPage === 'division' ? 'Red' : count?.DivisionCount > 0 ? 'blue' : '#000' }}
                                                aria-current="page"
                                                onClick={() => { if (!changesStatus) setShowPage('division') }}
                                            >
                                                Division
                                            </span> */}
                                            <span
                                                className={`nav-item ${showPage === 'screenpermission' ? 'active' : ''} ${!status ? 'disabled' : ''}`}
                                                data-toggle={changesStatus ? "modal" : "pill"}
                                                data-target={changesStatus ? "#SaveModal" : ''}
                                                style={{ color: showPage === 'screenpermission' ? 'Red' : '#000' }}
                                                aria-current="page"
                                                onClick={() => { if (!changesStatus) setShowPage('screenpermission') }}
                                            >
                                                Screen Permission
                                            </span>
                                            <span
                                                className={`nav-item ${showPage === 'PasswordSetting' ? 'active' : ''} ${!status ? 'disabled' : ''}`}
                                                data-toggle={changesStatus ? "modal" : "pill"}
                                                data-target={changesStatus ? "#SaveModal" : ''}
                                                style={{ color: showPage === 'PasswordSetting' ? 'Red' : '#000' }}
                                                aria-current="page"
                                                onClick={() => { if (!changesStatus) setShowPage('PasswordSetting') }}
                                            >
                                                Password Setting
                                            </span>
                                            {/* <span
                                                className={`nav-item ${showPage === 'unit' ? 'active' : ''} ${!status ? 'disabled' : ''}`}
                                                data-toggle={changesStatus ? "modal" : "pill"}
                                                data-target={changesStatus ? "#SaveModal" : ''}
                                                style={{ color: showPage === 'unit' ? 'Red' : count?.UnitCount > 0 ? 'blue' : '#000' }}
                                                aria-current="page"
                                                onClick={() => { if (!changesStatus) setShowPage('unit') }}
                                            >
                                                Unit
                                            </span> */}

                                            {/* <span
                                            className={`nav-item ${showPage === 'roster' ? 'active' : ''} ${!status ? 'disabled' : ''}`}
                                            data-toggle={changesStatus ? "modal" : "pill"}
                                            data-target={changesStatus ? "#SaveModal" : ''}
                                            style={{ color: showPage === 'roster' ? 'Red' : '#000' }}
                                            aria-current="page"
                                            onClick={() => { if (!changesStatus) setShowPage('roster') }}
                                        >
                                            Unit Assignment
                                        </span> */}
                                            <span
                                                className={`nav-item ${showPage === 'ShiftA' ? 'active' : ''} ${!status ? 'disabled' : ''}`}
                                                data-toggle={changesStatus ? "modal" : "pill"}
                                                data-target={changesStatus ? "#SaveModal" : ''}
                                                style={{ color: showPage === 'ShiftA' ? 'Red' : count?.ShiftCount > 0 && status ? 'blue' : '#000' }}
                                                aria-current="page"
                                                onClick={() => { if (!changesStatus) setShowPage('ShiftA') }}
                                            >
                                                Shift{count?.ShiftCount > 0 && status ? '(' + count?.ShiftCount + ')' : ''}
                                            </span>
                                            {/* <span
                                                className={`nav-item ${showPage === 'Ranks' ? 'active' : ''} ${!status ? 'disabled' : ''}`}
                                                data-toggle={changesStatus ? "modal" : "pill"}
                                                data-target={changesStatus ? "#SaveModal" : ''}
                                                style={{ color: showPage === 'Ranks' ? 'Red' : count?.RankCount > 0 ? 'blue' : '#000' }}
                                                aria-current="page"
                                                onClick={() => { if (!changesStatus) setShowPage('Ranks') }}
                                            >
                                                Ranks
                                            </span> */}
                                            <span
                                                className={`nav-item ${showPage === 'AgencyContact' ? 'active' : ''} ${!status ? 'disabled' : ''}`}
                                                data-toggle={changesStatus ? "modal" : "pill"}
                                                data-target={changesStatus ? "#SaveModal" : ''}
                                                style={{ color: showPage === 'AgencyContact' ? 'Red' : count?.AgencyContactCount > 0 && status ? 'blue' : '#000' }}
                                                aria-current="page"
                                                onClick={() => { if (!changesStatus) setShowPage('AgencyContact') }}
                                            >
                                                Agency Contact{count?.AgencyContactCount > 0 && status ? '(' + count?.AgencyContactCount + ')' : ''}
                                            </span>
                                            <span
                                                className={`nav-item ${showPage === 'AgencySetting' ? 'active' : ''} ${!status ? 'disabled' : ''}`}
                                                data-toggle={changesStatus ? "modal" : "pill"}
                                                data-target={changesStatus ? "#SaveModal" : ''}
                                                style={{ color: showPage === 'AgencySetting' ? 'Red' : '#000' }}
                                                aria-current="page"
                                                onClick={() => { if (!changesStatus) setShowPage('AgencySetting') }}
                                            >
                                                Agency Setting
                                            </span>
                                        </ul>
                                    </div>
                                </div>
                                {
                                    showPage === 'home' ?
                                        <Home {...{ send_Notification, allowMultipleLogin }} />
                                        :
                                        showPage === 'Group' ?
                                            <Group />
                                            :
                                            showPage === 'PasswordSetting' ?
                                                <PasswordSetting />
                                                :
                                                showPage === 'member' ?
                                                    <Member />
                                                    :
                                                    // showPage === 'division' ?
                                                    //     <Division {...{ AId, pinID }} />
                                                    //     :
                                                    // showPage === 'unit' ?
                                                    //     <Unit {...{ AId, pinID }} />
                                                    //     :
                                                    // showPage === 'personnel' ?
                                                    //     <Personnel {...{ AId, pinID }} />
                                                    //     :
                                                    // showPage === 'personnel' ?
                                                    //     <PersonnelTab {...{ AId, pinID }} />
                                                    //     :
                                                    // showPage === 'login' ?
                                                    //     <Login />
                                                    //     :
                                                    showPage === 'screenpermission' ?
                                                        <ScreenPermission />
                                                        :
                                                        // showPage === 'lockrestrictlevel' ?
                                                        //     <LockRestrictLevel {...{ AId, pinID }} />
                                                        //     :
                                                        // showPage === 'roster' ?
                                                        //     <Roster  {...{ AId, pinID }} />
                                                        //     :
                                                        // showPage === 'UnitAssignment' ?
                                                        //     <UnitAssignment {...{ AId, pinID }} />
                                                        //     :
                                                        // showPage === 'fieldsecurity' ?
                                                        //     <FieldSecurity />
                                                        //     :
                                                        // showPage === 'Ranks' ?
                                                        //     <Ranks {...{ AId, pinID }} />
                                                        //     :
                                                        showPage === 'ShiftA' ?
                                                            <Shift {...{ AId, pinID }} />
                                                            :
                                                            showPage === 'AgencyContact' ?
                                                                <AgencyContact />
                                                                :
                                                                showPage === 'AgencySetting' ?
                                                                    <AgencySetting />
                                                                    : ''
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>


    )
}

export default AgencyTab
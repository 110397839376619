export const dropDownDataModel = (data, value, label) => {
  const result = data?.map((item) => ({
    value: item[value],
    label: item[label],
  }));
  return result;
};

export const handleTextKeyDown = (e) => {
  const regex = /^[a-zA-Z\s]$/;

  // Allow control keys such as Backspace, Delete, Tab, Enter, and arrow keys
  const controlKeys = [
    "Backspace",
    "Delete",
    "Tab",
    "Enter",
    "Escape",
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
  ];

  // If the key does not match the regex and is not a control key, prevent the input
  if (!regex.test(e.key) && !controlKeys.includes(e.key)) {
    e.preventDefault();
  }
};

export const handleNumberTextKeyDown = (e) => {
  const regex = /^[a-zA-Z0-9\s]$/;

  // Allow control keys such as Backspace, Delete, Tab, Enter, and arrow keys
  const controlKeys = [
    "Backspace",
    "Delete",
    "Tab",
    "Enter",
    "Escape",
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
  ];

  // If the key does not match the regex and is not a control key, prevent the input
  if (!regex.test(e.key) && !controlKeys.includes(e.key)) {
    e.preventDefault();
  }
};

export function isEmptyObject(obj) {
  for (let prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }
  return true;
}
export function isNotEmpty(str) {
  const pattern = /\S+/;
  return pattern.test(str); // returns a boolean
}

export function isEmpty(str) {
  return !isNotEmpty(str);
}
import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import Select from "react-select";
import Dropdown from 'react-bootstrap/Dropdown';
import { SendIcon } from '../../../Components/Filter/Filter';
import { tableCustomStyles } from '../../../Components/Common/Utility';
import { colorLessStyle_Select } from '../../Utility/CustomStylesForReact';
import '../section.css';
import { priorityCodeData, alarmLevelData } from '../../../CADUtils/constant';
import MasterCallforServiceCode from "../../../CADServices/APIs/masterCFS";
import useObjState from '../../../CADHook/useObjState';

const CallForServiceCodeSection = () => {
  const [filterTypeIdOption, setFilterTypeIdOption] = useState('Contains');
  const [pageStatus, setPageStatus] = useState("1");

  const [CFSData, setCFSData] =
    useState({
      cfsCode: "",
      cfsCodeDesc: "",
      priorityCode: "",
      alarmLevel: "",
      cfsLaw: false,
      cfsFire: false,
      cfsEmergencyMedicalService: false,
      cfsOther: false,
    });

  const [
    CFSCodeState,
    _setCFSCodeState,
    handleCFSCodeState,
    _clearCFSCodeState,
  ] = useObjState({
    cfsCode: "",
    cfsCodeDesc: "",
    priorityCode: "",
    alarmLevel: "",
    cfsLaw: false,
    cfsFire: false,
    cfsEmergencyMedicalService: false,
    cfsOther: false,
  })


  const data = [
    { cfsCode: 'CFS001', description: 'Fire Emergency', alarmLevel: 'High', requiredAgencyType: 'Fire Department', priorityCode: 'P1', },
    { cfsCode: 'CFS002', description: 'Medical Emergency', alarmLevel: 'Medium', requiredAgencyType: 'Health Department', priorityCode: 'P2', },
    { cfsCode: 'CFS003', description: 'Emergency', alarmLevel: 'Low', requiredAgencyType: 'Fire Department', priorityCode: 'P3', },
    { cfsCode: 'CFS004', description: 'Emergency', alarmLevel: 'Low', requiredAgencyType: 'Fire Department', priorityCode: 'P3', },
    { cfsCode: 'CFS005', description: 'Emergency', alarmLevel: 'Low', requiredAgencyType: 'Fire Department', priorityCode: 'P3', },
  ];


  const [toggleStates, setToggleStates] = useState(
    data.map(() => true)
  );

  const handleToggle = (index) => {
    setToggleStates(prevStates =>
      prevStates.map((state, i) => (i === index ? !state : state))
    );
  };


  const handleChangeCFS = (e, selectedOption = null, actionMeta = null) => {
    if (selectedOption) {
      const { name } = actionMeta;
      setCFSData((prevState) => ({
        ...prevState,
        [name]: selectedOption,
      }));
    } else {
      const { name, type, value, checked } = e.target;

      if (type === "checkbox") {
        setCFSData((prevState) => ({
          ...prevState,
          [name]: checked,
        }));
      } else {
        setCFSData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }
  };

  const columns = [
    {
      name: 'CFS Code',
      selector: row => row.cfsCode,
      sortable: true,
    },
    {
      name: 'Description',
      selector: row => row.description,
      sortable: true,
    },
    {
      name: 'Alarm Level',
      selector: row => row.alarmLevel,
      sortable: true,
    },
    {
      name: 'Required Agency Type',
      selector: row => row.requiredAgencyType,
      sortable: true,
    },
    {
      name: 'Priority Code',
      selector: row => row.priorityCode,
      sortable: true,
    },
    {
      name:
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {'Status'}
        </div>,
      cell: (row, index) =>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <span
            onClick={() => handleToggle(index)}
            className="btn btn-sm text-white px-1 py-0 mr-1"
            data-toggle="modal" data-target="#ConfirmModal"
            style={{ background: "#ddd", cursor: "pointer" }}
          >
            {toggleStates[index] ? (
              <i className="fa fa-toggle-on" style={{ color: "green" }} aria-hidden="true"></i>
            ) : (
              <i className="fa fa-toggle-off" style={{ color: "red" }} aria-hidden="true"></i>
            )}
          </span>
        </div>,
      width: "70px",
    },

  ];

  const onSave = async () => {
    try {
      const payload = {
        Action: "ADD",
        CFSCODE: CFSData?.cfsCode,
        CFSCodeDescription: CFSData?.cfsCodeDesc,
        Prioritycode: CFSData?.priorityCode,
        AlrmLevel: CFSData?.alarmLevel?.value,
        LAW: CFSData?.cfsLaw,
        FIRE: CFSData?.cfsFire,
        EMERGENCYMEDICALSERVICE: CFSData?.cfsEmergencyMedicalService,
        OTHER: CFSData?.cfsOther,
        CallforServiceID: 111
      }
      await MasterCallforServiceCode.insertCFS(
        payload
      );
    } catch (error) {
      console.error("Error during insert:", error);
    }
  }
  return (
    <>
      <div className='utilities-tab-content-main-container'>
        <div className='utilities-tab-content-form-container'>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 incident-tab ">
              <ul className="nav nav-tabs mb-1 pl-2 " id="myTab" role="tablist">
                <span className={`nav-item ${pageStatus === '1' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("1"); }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '1' ? 'Red' : '' }}>Active</span>
                <span className={`nav-item ${pageStatus === '0' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("0"); }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '0' ? 'Red' : '' }}>InActive</span>
              </ul>
            </div>
            {
              pageStatus === '1' ?
                <>
                  <div className='utilities-tab-content-form-main'>
                    {/* line 1 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          CFS Code
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <input
                          type="text"
                          className="form-control py-1 new-input"
                          placeholder='Placeholder'
                          name="cfsCode"
                          value={CFSCodeState.cfsCode}
                          onChange={(e) => handleCFSCodeState("cfsCode", e.target.value)}
                        />
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          CFS Code Description
                        </label>
                      </div>
                      <div className="col-4 d-flex align-self-center justify-content-end">
                        <input
                          type="text"
                          className="form-control py-1 new-input"
                          placeholder='Placeholder'
                          name="cfsCodeDesc"
                          value={CFSCodeState.cfsCodeDesc}
                          onChange={(e) => handleCFSCodeState("cfsCodeDesc", e.target.value)}
                        />
                      </div>
                    </div>
                    {/* line 2 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-start justify-content-end">
                        <label for="" className="tab-form-label" style={{ marginTop: "10px" }}>
                          Priority Code
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-start justify-content-end">
                        <Select
                          isClearable
                          options={priorityCodeData}
                          placeholder="Select..."
                          styles={colorLessStyle_Select}
                          className="w-100"
                          name="priorityCode"
                          value={CFSCodeState.priorityCode}
                          onChange={(e) => { handleCFSCodeState("priorityCode", e) }}
                        />
                      </div>
                      <div className="col-1 d-flex align-self-start justify-content-end">
                        <label for="" className="tab-form-label" style={{ marginTop: "10px" }}>
                          Alarm Level
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-start justify-content-end">
                        <Select
                          isClearable
                          options={alarmLevelData}
                          placeholder="Select..."
                          styles={colorLessStyle_Select}
                          className="w-100"
                          name="alarmLevel"
                          value={CFSCodeState.alarmLevel}
                          onChange={(e) => { handleCFSCodeState("alarmLevel", e) }}

                        />
                      </div>
                      <div className="col-2 d-flex align-self-start justify-content-end">
                        <label for="" className="tab-form-label" style={{ marginTop: "10px" }}>
                          Required Agency Types
                        </label>
                      </div>
                      <div className="col-3 d-flex align-self-center justify-content-end">
                        <div className='agency-types-checkbox-container'>
                          {/* L : Law */}
                          <div className="agency-checkbox-item">
                            <input
                              type="checkbox"
                              name="cfsLaw"
                              checked={CFSCodeState.cfsLaw}
                              onChange={(e) => { handleCFSCodeState("cfsLaw", e.target.checked) }}
                            />
                            <div className="agency-checkbox-text-container">
                              <span>L</span>
                              <span>|</span>
                              <span>Law</span>
                            </div>
                          </div>
                          {/* F : Fire */}
                          <div className="agency-checkbox-item">
                            <input
                              type="checkbox"
                              name="cfsFire"
                              checked={CFSCodeState.cfsFire}
                              onChange={(e) => { handleCFSCodeState("cfsFire", e.target.checked) }}
                            />
                            <div className="agency-checkbox-text-container">
                              <span>F</span>
                              <span>|</span>
                              <span>Fire</span>
                            </div>
                          </div>
                          {/* E : Emergency Medical Service */}
                          <div className="agency-checkbox-item">
                            <input
                              type="checkbox"
                              name="cfsEmergencyMedicalService"
                              checked={CFSCodeState.cfsEmergencyMedicalService}
                              onChange={(e) => { handleCFSCodeState("cfsEmergencyMedicalService", e.target.checked) }}
                            />
                            <div className="agency-checkbox-text-container">
                              <span>E</span>
                              <span>|</span>
                              <span>Emergency Medical Service </span>
                            </div>
                          </div>
                          {/* O : Other */}
                          <div className="agency-checkbox-item">
                            <input
                              type="checkbox"
                              name="cfsOther"
                              checked={CFSCodeState.cfsOther}
                              onChange={(e) => { handleCFSCodeState("cfsOther", e.target.checked) }}

                            />
                            <div className="agency-checkbox-text-container">
                              <span>O</span>
                              <span>|</span>
                              <span>Law</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                :
                <>
                </>
            }
          </div>

        </div>

        <div className='utilities-tab-content-table-container'>
          <div className="row">
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control py-1 new-input"
                placeholder='Search By Code...'
              />
            </div>
            <div className="col-1 d-flex align-self-center justify-content-end" style={{ zIndex: '2', }}>
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeIdOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control py-1 new-input"
                placeholder='Search By Description...'
              />
            </div>
            <div className="col-1 d-flex align-self-center justify-content-end" style={{ zIndex: '1', }}>
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeIdOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="table-responsive">
            <DataTable
              dense
              columns={columns}
              data={data}
              customStyles={tableCustomStyles}
              pagination // enable pagination
              responsive // enable responsive design
              striped // add zebra-striping to the table rows
              highlightOnHover // highlight row on hover
              fixedHeader
            // fixedHeaderScrollHeight="200px" 
            />
          </div>

          <div className="utilities-tab-content-button-container" >
            <button type="button" className="btn btn-sm btn-success">Cancel</button>
            <button type="button" className="btn btn-sm btn-success" onClick={() => {
              onSave();
            }}>Save</button>
          </div>
        </div>
      </div>

    </>
  );
};

export default CallForServiceCodeSection;
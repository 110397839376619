import React, { useContext, useEffect, useState, useRef } from "react";
import Select from "react-select";
import SubTab from "../../../../Utility/Tab/SubTab";
import { VictimTabs } from "../../../../Utility/Tab/TabsArray";
import Offense from "./VictimTab/Offense/Offense";
import Relationship from "./VictimTab/Relationship/Relationship";
import InjuryType from "./VictimTab/InjuryType/InjuryType";
import JustifiableHomicide from "./VictimTab/JustifiableHomicide/JustifiableHomicide";
import AssaultType from "./VictimTab/AssaultType/AssaultType";
import Property from "./VictimTab/Property/Property";
import Officer from "./VictimTab/Officer/Officer";
import { Decrypt_Id_Name } from "../../../../Common/Utility";
import { AddDeleteUpadate, fetchPostData } from "../../../../hooks/Api";
import {
  Comman_changeArrayFormat,
  threeColArray,
} from "../../../../Common/ChangeArrayFormat";
import { toastifySuccess } from "../../../../Common/AlertMsg";
import DeletePopUpModal from "../../../../Common/DeleteModal";
import FindListDropDown from "../../../../Common/FindListDropDown";
import { NaVictimListDropDownArray } from "../../../../Utility/ListDropDownArray/ListDropArray";
import { RequiredFieldIncident } from "../../../Utility/Personnel/Validation";
import { AgencyContext } from "../../../../../Context/Agency/Index";
import { VictimError } from "../../../../NIBRSError";
import IdentifyFieldColor from "../../../../Common/IdentifyFieldColor";
// import Ori from "./VictimTab/ORI/OriOri";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Home from "./VictimTab/Home/Home";
import { get_LocalStoreData } from "../../../../../redux/actions/Agency";
import NameListing from "../../../ShowAllList/NameListing";
import { get_ScreenPermissions_Data } from "../../../../../redux/actions/IncidentAction";
import ChangesModal from "../../../../Common/ChangesModal";

const Victim = (props) => {
  const { ListData, DecNameID, DecMasterNameID, DecIncID } = props;

  const dispatch = useDispatch();
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const uniqueId = sessionStorage.getItem("UniqueUserID")
    ? Decrypt_Id_Name(
        sessionStorage.getItem("UniqueUserID"),
        "UForUniqueUserID"
      )
    : "";

  const effectiveScreenPermission = useSelector(
    (state) => state.Incident.effectiveScreenPermission
  );

  const {
    nameSingleData,
    localStoreArray,
    get_LocalStorage,
    setChangesStatus,
  } = useContext(AgencyContext);

  const SelectedValue = useRef();
  const [showPage, setShowPage] = useState("home");
  const [victimTypeDrp, setVictimTypeDrp] = useState([]);
  const [bodyArmorDrp, setBodyArmorDrp] = useState([]);
  const [callTypeDrp, setCallTypeDrp] = useState([]);
  const [additionalJustiDrp, setAdditionalJustiDrp] = useState([]);
  const [assignmentTypeDrp, setAssignmentTypeDrp] = useState([]);
  const [editval, setEditval] = useState();
  const [victimStatus, setVictimStatus] = useState(false);
  const [updateCount, setUpdateCount] = useState(0);
  const [victimID, setVictimID] = useState();

  const [loginAgencyID, setLoginAgencyID] = useState("");
  const [loginPinID, setLoginPinID] = useState("");
  const [nameID, setNameID] = useState();
  const [incidentID, setIncidentID] = useState("");

  const localStore = {
    Value: "",
    UniqueId: sessionStorage.getItem("UniqueUserID")
      ? Decrypt_Id_Name(
          sessionStorage.getItem("UniqueUserID"),
          "UForUniqueUserID"
        )
      : "",
    Key: JSON.stringify({
      AgencyID: "",
      PINID: "",
      IncidentID: "",
      MasterNameID: "",
      NameID: "",
    }),
  };

  useEffect(() => {
    if (localStoreData) {
      setLoginAgencyID(localStoreData?.AgencyID);
      setLoginPinID(localStoreData?.PINID);
      dispatch(
        get_ScreenPermissions_Data(
          "N057",
          localStoreData?.AgencyID,
          localStoreData?.PINID
        )
      );
    }
  }, [localStoreData]);

  useEffect(() => {
    if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
      if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
    }
  }, []);

  useEffect(() => {
    if (DecNameID && DecMasterNameID) {
      setValue((pre) => {
        return {
          ...pre,
          CreatedByUserFK: loginPinID,
          NameID: DecNameID,
          VictemTypeCode: null,
        };
      });
    }
  }, [DecNameID, DecMasterNameID, loginPinID]);

  useEffect(() => {
    if (!localStoreArray.AgencyID || !localStoreArray.PINID) {
      get_LocalStorage(localStore);
    }
  }, []);

  console.log(DecIncID, "incident");

  // useEffect(() => {
  //     if (localStoreArray) {
  //         if (localStoreArray?.AgencyID && localStoreArray?.PINID) {
  //             setLoginAgencyID(localStoreArray?.AgencyID);
  //             setLoginPinID(localStoreArray?.PINID);
  //             if (localStoreArray.IncidentID) { setIncidentID(localStoreArray?.IncidentID); }
  //             if (localStoreArray.NameID) { GetSingleData(localStoreArray?.NameID); setNameID(localStoreArray?.NameID); }
  //         }
  //     }
  // }, [localStoreArray])

  const [value, setValue] = useState({
    VictimTypeID: "",
    BodyArmorID: "",
    CallTypeID: "",
    AdditionalJustificationID: "",
    AssignmentTypeID: "",
    CreatedByUserFK: "",
    NameID: "",
    ModifiedByUserFK: "",
    VictimID: "",
    VictemTypeCode: "",
  });

  const [errors, setErrors] = useState({
    VictimTypeIDErrors: "",
  });

  useEffect(() => {
    setValue((pre) => {
      return {
        ...pre,
        CreatedByUserFK: loginPinID,
        NameID: DecNameID,
        VictemTypeCode: null,
      };
    });
    GetSingleData(DecNameID);
  }, [DecNameID, loginPinID]);

  const GetSingleData = (DecMasterNameID) => {
    const val = { NameID: DecMasterNameID };
    fetchPostData("Victim/GetData_Victim", val).then((res) => {
      console.log(res);
      if (res.length != 0) {
        setEditval(res);
        setVictimStatus(true);
        setUpdateCount(updateCount + 1);
      } else {
        setEditval([]);
        setVictimStatus(false);
      }
    });
  };

  useEffect(() => {
    if (editval) {
      setValue({
        ...value,
        VictimTypeID: editval[0]?.VictimTypeID,
        BodyArmorID: editval[0]?.BodyArmorID,
        CallTypeID: editval[0]?.CallTypeID,
        AdditionalJustificationID: editval[0]?.AdditionalJustificationID,
        AssignmentTypeID: editval[0]?.AssignmentTypeID,
        VictimID: editval[0]?.VictimID,
        ModifiedByUserFK: loginPinID,
        VictemTypeCode: editval[0]?.VictimCode,
      });
      setVictimID(editval[0]?.VictimID);
    } else {
      reset_Value_Data();
    }
  }, [editval, updateCount]);

  const check_Validation_Error = (e) => {
    RequiredFieldIncident(value.VictimTypeID) &&
      setErrors((prevValues) => {
        return {
          ...prevValues,
          ["VictimTypeIDErrors"]: RequiredFieldIncident(value.VictimTypeID),
        };
      });
  };

  const { VictimTypeIDErrors } = errors;

  useEffect(() => {
    if (VictimTypeIDErrors === "true") {
      if (victimStatus) {
        UpdateVictim();
      } else {
        AddVictim();
      }
    }
  }, [VictimTypeIDErrors]);

  useEffect(() => {
    if (loginAgencyID) {
      get_Victim_Type_Data(loginAgencyID);
      get_Body_Armor_Data(loginAgencyID);
      get_Call_Type_Data(loginAgencyID);
      get_Additional_justificaion_Data(loginAgencyID);
      get_AssignmentType_Data(loginAgencyID);
    }
  }, [loginAgencyID]);

  const get_AssignmentType_Data = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID,
    };
    fetchPostData(
      "VictimAssignmentType/GetDataDropDown_VictimAssignmentType",
      val
    ).then((data) => {
      if (data) {
        setAssignmentTypeDrp(
          Comman_changeArrayFormat(
            data,
            "VictimAssignmentTypeID",
            "Description"
          )
        );
      } else {
        setAssignmentTypeDrp([]);
      }
    });
  };

  const get_Additional_justificaion_Data = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID,
    };
    fetchPostData(
      "AdditionalJustification/GetDataDropDown_AdditionalJustification",
      val
    ).then((data) => {
      if (data) {
        setAdditionalJustiDrp(
          Comman_changeArrayFormat(
            data,
            "AdditionalJustificationID",
            "Description"
          )
        );
      } else {
        setAdditionalJustiDrp([]);
      }
    });
  };

  const get_Call_Type_Data = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID,
    };
    fetchPostData("VictimCallType/GetDataDropDown_VictimCallType", val).then(
      (data) => {
        if (data) {
          setCallTypeDrp(
            Comman_changeArrayFormat(data, "VictimCallTypeID", "Description")
          );
        } else {
          setCallTypeDrp([]);
        }
      }
    );
  };

  const get_Body_Armor_Data = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID,
    };
    fetchPostData("BodyArmor/GetDataDropDown_BodyArmor", val).then((data) => {
      if (data) {
        setBodyArmorDrp(
          Comman_changeArrayFormat(data, "BodyArmorID", "Description")
        );
      } else {
        setBodyArmorDrp([]);
      }
    });
  };

  const get_Victim_Type_Data = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID,
    };
    fetchPostData("VictimType/GetDataDropDown_VictimType", val).then((data) => {
      if (data) {
        setVictimTypeDrp(
          threeColArray(data, "VictimTypeID", "Description", "VictimCode")
        );
      } else {
        setVictimTypeDrp([]);
      }
    });
  };

  const ChangeDropDown = (e, name) => {
    if (e) {
      if (name === "VictimTypeID") {
        setValue((pre) => {
          return { ...pre, [name]: e.value, ["VictemTypeCode"]: e.id };
        });
        setChangesStatus(true);
      } else {
        setValue({ ...value, [name]: e.value });
        setChangesStatus(true);
      }
    } else {
      if (name === "VictimTypeID") {
        setValue((pre) => {
          return { ...pre, [name]: "", ["VictemTypeCode"]: null };
        });
      }
      setValue({ ...value, [name]: null });
      setChangesStatus(true);
    }
  };

  const AddVictim = () => {
    AddDeleteUpadate("Victim/Insert_Victim", value).then((res) => {
      if (res) {
        console.log(res);
        if (res.VictimID) {
          setChangesStatus(false);
          GetSingleData(DecNameID);
        }
        toastifySuccess(res.Message);
        setErrors({
          ...errors,
          ["VictimTypeIDErrors"]: "",
        });
      }
    });
  };

  const UpdateVictim = () => {
    AddDeleteUpadate("Victim/Update_Victim", value).then((res) => {
      if (res) {
        setChangesStatus(false);
        toastifySuccess(res.Message);
        setErrors({
          ...errors,
          ["VictimTypeIDErrors"]: "",
        });
      }
    });
  };

  const DeletePin = () => {
    const val = {
      VictimID: victimID,
      DeletedByUserFK: loginPinID,
    };
    AddDeleteUpadate("Victim/Delete_Victim", val).then((res) => {
      if (res) {
        toastifySuccess(res.Message);
        setValue({ ...value, ["NameID"]: "" });
        reset_Value_Data();
      } else console.log("Somthing Wrong");
    });
  };

  const reset_Value_Data = () => {
    setValue({
      ...value,
      VictimTypeID: "",
      BodyArmorID: "",
      CallTypeID: "",
      AdditionalJustificationID: "",
      AssignmentTypeID: "",
      ModifiedByUserFK: "",
    });
    setVictimStatus(false);
  };

  const customStylesWithOutColor = {
    control: (base) => ({
      ...base,
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };

  return (
    <>
      {/* <div className="col-12 " id='display-not-form'>
                <div className="col-12 col-md-12 mt-2 pt-1 p-0" >
                    <div className="bg-line  py-1 px-2 d-flex justify-content-between align-items-center">
                        <p className="p-0 m-0">Victim</p>
                        <FindListDropDown array={NaVictimListDropDownArray} />
                    </div>
                </div>
            </div> */}
      <NameListing {...{ ListData }} />
      <div className="col-12">
        <div className="row">
          <div className="col-3 col-md-3 col-lg-2 mt-3">
            <Link
              to={"/ListManagement?page=Type%20of%20Victim&call=/Name-Home"}
              className="new-link"
            >
              Victim Type{" "}
              {VictimError(value?.VictemTypeCode, nameSingleData[0], "tool")}
              {errors.VictimTypeIDErrors !== "true" ? (
                <p
                  style={{
                    color: "red",
                    fontSize: "13px",
                    margin: "0px",
                    padding: "0px",
                  }}
                >
                  {errors.VictimTypeIDErrors}
                </p>
              ) : null}
            </Link>
          </div>
          <div className="col-3 col-md-3 col-lg-4  mt-2">
            <Select
              name="VictimTypeID"
              value={victimTypeDrp?.filter(
                (obj) => obj.value === value?.VictimTypeID
              )}
              styles={VictimError(
                value?.VictemTypeCode,
                nameSingleData[0],
                "Color"
              )}
              isClearable
              options={victimTypeDrp}
              onChange={(e) => {
                ChangeDropDown(e, "VictimTypeID");
              }}
              placeholder="Select.."
              ref={SelectedValue}
            />
          </div>
          <div className="col-3 col-md-3 col-lg-2 mt-3">
            <Link
              to={"/ListManagement?page=Body%20Armor&call=/Name-Home"}
              className="new-link"
            >
              Body Armor
            </Link>
          </div>
          <div className="col-3 col-md-3 col-lg-4  mt-2">
            <Select
              name="BodyArmorID"
              value={bodyArmorDrp?.filter(
                (obj) => obj.value === value?.BodyArmorID
              )}
              styles={customStylesWithOutColor}
              isClearable
              options={bodyArmorDrp}
              onChange={(e) => {
                ChangeDropDown(e, "BodyArmorID");
              }}
              placeholder="Select.."
            />
          </div>
          <div className="col-3 col-md-3 col-lg-2 mt-3">
            <Link
              to={"/ListManagement?page=Victim%20Call%20Type&call=/Name-Home"}
              className="new-link"
            >
              Call Type
            </Link>
          </div>
          <div className="col-3 col-md-3 col-lg-4  mt-2">
            <Select
              name="CallTypeID"
              value={callTypeDrp?.filter(
                (obj) => obj.value === value?.CallTypeID
              )}
              styles={customStylesWithOutColor}
              isClearable
              options={callTypeDrp}
              onChange={(e) => {
                ChangeDropDown(e, "CallTypeID");
              }}
              placeholder="Select.."
            />
          </div>
          <div className="col-3 col-md-3 col-lg-2 mt-3">
            <Link
              to={
                "/ListManagement?page=Additional%20Justification&call=/Name-Home"
              }
              className="new-link"
            >
              Additional Justification
            </Link>
          </div>
          <div className="col-3 col-md-3 col-lg-4  mt-2">
            <Select
              name="AdditionalJustificationID"
              value={additionalJustiDrp?.filter(
                (obj) => obj.value === value?.AdditionalJustificationID
              )}
              styles={customStylesWithOutColor}
              isClearable
              options={additionalJustiDrp}
              onChange={(e) => {
                ChangeDropDown(e, "AdditionalJustificationID");
              }}
              placeholder="Select.."
            />
          </div>
          <div className="col-3 col-md-3 col-lg-2 mt-3">
            <Link
              to={
                "/ListManagement?page=Officer%20Assignment%20Type&call=/Name-Home"
              }
              className="new-link"
            >
              Assignment Type
            </Link>
          </div>
          <div className="col-3 col-md-3 col-lg-4  mt-2">
            <Select
              name="AssignmentTypeID"
              value={assignmentTypeDrp?.filter(
                (obj) => obj.value === value?.AssignmentTypeID
              )}
              styles={customStylesWithOutColor}
              isClearable
              options={assignmentTypeDrp}
              onChange={(e) => {
                ChangeDropDown(e, "AssignmentTypeID");
              }}
              placeholder="Select.."
            />
          </div>
          <div className="col-6 col-md-6 col-lg-6 text-right  mt-3 pt-2">
            {/* {
                            victimStatus ?
                                <>
                                    <button type="button" className="btn btn-sm btn-success  mr-1" onClick={() => check_Validation_Error()}>Update</button>
                                    <button type="button" className="btn btn-sm btn-success  mr-1" data-toggle="modal" data-target="#DeleteModal" >Delete</button>
                                </>
                                :

                                <button type="button" className="btn btn-sm btn-success  mr-1" onClick={() => check_Validation_Error()} >Save</button>
                        } */}

            {victimStatus ? (
              effectiveScreenPermission ? (
                effectiveScreenPermission[0]?.Changeok ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-sm btn-success mr-1"
                      onClick={(e) => {
                        check_Validation_Error();
                      }}
                    >
                      Update
                    </button>
                    {effectiveScreenPermission ? (
                      effectiveScreenPermission[0]?.DeleteOK ? (
                        <button
                          type="button"
                          className="btn btn-sm btn-success  mr-1"
                          data-toggle="modal"
                          data-target="#DeleteModal"
                        >
                          Delete
                        </button>
                      ) : (
                        <></>
                      )
                    ) : (
                      <button
                        type="button"
                        className="btn btn-sm btn-success  mr-1"
                        data-toggle="modal"
                        data-target="#DeleteModal"
                      >
                        Delete
                      </button>
                    )}
                  </>
                ) : (
                  <></>
                )
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-sm btn-success mr-1"
                    onClick={(e) => {
                      check_Validation_Error();
                    }}
                  >
                    Update
                  </button>

                  {effectiveScreenPermission ? (
                    effectiveScreenPermission[0]?.DeleteOK ? (
                      <button
                        type="button"
                        className="btn btn-sm btn-success  mr-1"
                        data-toggle="modal"
                        data-target="#DeleteModal"
                      >
                        Delete
                      </button>
                    ) : (
                      <></>
                    )
                  ) : (
                    <button
                      type="button"
                      className="btn btn-sm btn-success  mr-1"
                      data-toggle="modal"
                      data-target="#DeleteModal"
                    >
                      Delete
                    </button>
                  )}
                </>
              )
            ) : effectiveScreenPermission ? (
              effectiveScreenPermission[0]?.AddOK ? (
                <button
                  type="button"
                  className="btn btn-sm btn-success mr-1"
                  onClick={(e) => {
                    check_Validation_Error();
                  }}
                >
                  Save
                </button>
              ) : (
                <></>
              )
            ) : (
              <button
                type="button"
                className="btn btn-sm btn-success mr-1"
                onClick={(e) => {
                  check_Validation_Error();
                }}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </div>
      <div className={`col-12 col-md-12`}>
        <div className="row px-0">
          <div className="col-12 mt-3">
            <SubTab
              tabs={VictimTabs}
              showPage={showPage}
              setShowPage={setShowPage}
              status={victimStatus}
            />
          </div>
        </div>
      </div>
      {showPage === "home" && victimStatus ? (
        <Home
          {...{ victimID, DecNameID, loginPinID, DecIncID, loginAgencyID }}
        />
      ) : showPage === "offense" && victimStatus ? (
        <Offense
          {...{ victimID, DecNameID, loginPinID, DecIncID, loginAgencyID }}
        />
      ) : showPage === "relationship" ? (
        <Relationship
          {...{ victimID, DecNameID, loginPinID, DecIncID, loginAgencyID }}
        />
      ) : showPage === "InjuryType" && victimStatus ? (
        <InjuryType
          {...{ victimID, nameID, loginPinID, incidentID, loginAgencyID }}
        />
      ) : showPage === "JustifiableHomicide" && victimStatus ? (
        <JustifiableHomicide
          {...{ victimID, nameID, loginPinID, incidentID, loginAgencyID }}
        />
      ) : showPage === "AssaultType" && victimStatus ? (
        <AssaultType
          {...{ victimID, nameID, loginPinID, incidentID, loginAgencyID }}
        />
      ) : showPage === "Officer" && victimStatus ? (
        <Officer
          {...{ victimID, nameID, loginPinID, incidentID, loginAgencyID }}
        />
      ) : showPage === "Property" && victimStatus ? (
        <Property
          {...{ victimID, nameID, loginPinID, incidentID, loginAgencyID }}
        />
      ) : (
        <div></div>
      )}
      <IdentifyFieldColor />
      <DeletePopUpModal func={DeletePin} />
      <ChangesModal func={check_Validation_Error} />
    </>
  );
};

export default Victim;

import React, { memo, useRef, useEffect } from "react";
import { useLoadScript, GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import usePlacesAutocomplete from "use-places-autocomplete";
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from "@reach/combobox";
import "@reach/combobox/styles.css";
import { useLocation } from "react-router-dom";
import { SplitAddress, } from "../Common/SplitAddress";

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const libraries = ["places"];

function Location({ setValue, value, col, setOnSelectLocation, locationStatus, setLocationStatus, updateStatus, check, ref, locationID, verify }) {

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: googleMapsApiKey,
        libraries
    });

    const [markers, setMarkers] = React.useState([]);
    const mapRef = React.useRef();

    const panTo = React.useCallback(({ lat, lng }) => {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(14);
        setMarkers(current => [
            ...current,
            {
                lat,
                lng,
                time: new Date()
            }
        ]);
    }, []);

    if (loadError) return "Error";
    if (!isLoaded) return "Loading...";

    return (
        <div>
            <Search
                panTo={panTo}
                set={setValue}
                val={value}
                col={col}
                setOnSelectLocation={setOnSelectLocation}
                LoStatus={locationStatus}
                updCount={updateStatus}
                setLoSta={setLocationStatus}
                check={check}
                ref={ref}
                locationID={locationID}
                verify={verify}
            />
        </div>
    );
}

export default memo(Location)


function Search({ panTo, set, val, col, setOnSelectLocation, LoStatus, updCount, setLoSta, check = { check }, ref, locationID, verify }) {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: googleMapsApiKey
    })

    const containerStyle = { width: '800px', height: '200px' };

    const center = { lat: -3.745, lng: -38.523 };

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    let storageRef = useRef(verify);

    useEffect(() => {
        // console.log(LoStatus)
        if (!storageRef.current || LoStatus) {
            handleInput(null);
        }
    }, [verify, LoStatus, updCount])

    const useQuery = () => new URLSearchParams(useLocation().search);
    let openPage = useQuery().get('page');

    const { ready, value, suggestions: { status, data }, setValue, clearSuggestions } = usePlacesAutocomplete({
        requestOptions: {
            // location: { lat: () => 43.6532, lng: () => -79.3832 },
            // radius: 100 * 1000,
            componentRestrictions: { country: "us" },
            strictBounds: false,
            // types: ["establishment"],
            fields: [
                "route", 'name', 'geometry', 'formatted_address', 'street_number', 'geocode', "address_components",
                'displayName', 'formattedAddress', 'location',
            ]
        }
    });

    useEffect(() => {
        if (val[col]) {
            setValue(val[col], false);
        }
    }, [val])

    // const handleInput = (e) => {
    //     if (e) {
    //         setValue(e.target.value);
    //     } else {
    //         setValue('');
    //     }
    // };

    const handleInput = (e) => {
        // console.log(e)
        if (e) {
            setOnSelectLocation(true)
            const inputValue = e ? e.target.value : '';
            setValue(inputValue);
            set(prevState => ({ ...prevState, [col]: inputValue }));
        } else {
            const inputValue = e ? e.target.value : '';
            setValue(inputValue);
            set(prevState => ({ ...prevState, [col]: inputValue }));
        }
    };

    useEffect(() => {
        if (openPage === 'clear') { }
    }, [openPage])

    const handleSelect = async (address) => {
        // console.log(address)
        set(pre => { return { ...pre, [col]: address } })
        setValue(address, false);
        clearSuggestions();
        saveVerifyLocation({ address, set, val, col, locationID }); setOnSelectLocation(false)
    };

    return (
        <>
            <div className="search" style={{ pointerEvents: !verify && 'none' }}>
                <Combobox onSelect={handleSelect}>
                    <ComboboxInput style={{ background: check ? '#fce9bf' : '', zIndex: 200 }}
                        value={value}
                        onChange={handleInput}
                        disabled={!ready}
                        placeholder="Search your location"
                    />
                    <ComboboxPopover >
                        {
                            verify ?
                                <ComboboxList>
                                    {status === "OK" &&
                                        data.map(({ id, description }) => (
                                            <ComboboxOption key={id} value={description} />
                                        ))}
                                </ComboboxList>
                                :
                                <>
                                </>
                        }
                    </ComboboxPopover>
                </Combobox>
            </div>
        </>
        // <GoogleMap
        //     mapContainerStyle={containerStyle}
        //     center={center}
        //     zoom={10}
        //     onLoad={onLoad}
        //     onUnmount={onUnmount}
        // >
        //     <></>
        // </GoogleMap>
    );
}

const saveVerifyLocation = async ({ address, set, val, col, locationID }) => {
    let geoApiData = await SplitAddress(address);
    const LocationGeoCode = geoApiData?.geocode?.location
    // console.log(LocationGeoCode)
    // const newArr = LocationGeoCode.map(obj => ({ 'lat': obj.latitude, 'lng': obj.longitude, }));
    // console.log(newArr)
    let Add = geoApiData?.address?.addressComponents ? geoApiData?.address?.addressComponents : [];
    let country = Add?.filter((obj) => { if (obj?.componentType === "country") { return obj.componentName } });
    let City = Add?.filter((obj) => { if (obj?.componentType === "locality") { return obj.componentName } });
    let Street = Add?.filter((obj) => { if (obj?.componentType === "route") { return obj.componentName } });
    let street_number = Add?.filter((obj) => { if (obj?.componentType === 'street_number') { return obj.componentName } });
    let sublocality_level_1 = Add?.filter((obj) => { if (obj?.componentType === 'sublocality_level_1') { return obj.componentName } });
    let administrative_area_level_1 = Add?.filter((obj) => { if (obj?.componentType === "administrative_area_level_1") { return obj.componentName } });
    let postal_code = Add?.filter((obj) => { if (obj?.componentType === "postal_code") { return obj.componentName } });
    let point_of_interest = Add?.filter((obj) => { if (obj?.componentType === "point_of_interest") { return obj.componentName } });
    let neighborhood = Add?.filter((obj) => { if (obj?.componentType === "neighborhood") { return obj.componentName } });
    let subpremise = Add?.filter((obj) => { if (obj?.componentType === "subpremise") { return obj.componentName } });
    let premise = Add?.filter((obj) => { if (obj?.componentType === "premise") { return obj.componentName } });

    set(pre => {
        return {
            ...pre,
            'IsUsLocation': 'Y',
            'Street': Street[0]?.componentName?.text ? Street[0].componentName.text : '',
            'City': City[0]?.componentName?.text ? City[0]?.componentName?.text : '',
            'Country': country[0]?.componentName?.text ? country[0]?.componentName?.text : '',
            'PremiseNo': street_number[0]?.componentName?.text ? street_number[0]?.componentName?.text : '',
            'ZipCode': postal_code[0]?.componentName?.text ? postal_code[0]?.componentName?.text : '',
            'TypeSufix': typeof (sublocality_level_1[0]?.componentName?.text) === 'number' ? sublocality_level_1[0]?.componentName?.text : 0,
            'DirectionSufix': administrative_area_level_1[0]?.componentName?.text ? administrative_area_level_1[0]?.componentName?.text : '',
            'point_of_interest': point_of_interest[0]?.componentName?.text ? point_of_interest[0]?.componentName?.text : '',
            'neighborhood': neighborhood[0]?.componentName?.text ? neighborhood[0]?.componentName?.text : '',
            'subpremise': subpremise[0]?.componentName?.text ? subpremise[0]?.componentName?.text : '',
            'premise': premise[0]?.componentName?.text ? premise[0]?.componentName?.text : '',
            'DirectionPrefix': '',
            'State': '',
            'ApartmentNo': '',
            'CommonPlace': '',
            'ApartmentType': '',
            'Street_Parse': '',
            'PremiseNo_Parse': '',
            'DirectionPrefix_Parse': '',
            'TypeSuffix_Parse': '',
            'DirectionSuffix_Parse': '',
            'ZipCodeID': '',
            'CityID': '',
            'CountryID': '',
        }
    })
}

import axios from "axios";
class GeoServices {
  getGeoZone = async ({ queryKey }) => {
    const [_key, { zoneType }] = queryKey;
    let payload = new FormData();
    payload.append("zoneType", zoneType);

    if (payload) {
      return await axios.post(`/GeoPetrolZone/GetData_Zone`, payload);
    }
    console.warn("zoneType not provided, GeoServices.getGeoPetrolZone");
    return null;
  };

  getPremise = async ({ queryKey }) => {
    const [_key, { payload }] = queryKey;
    // if (payload) {
    return await axios.post(`/GeoPremiseType/GetData_Premise`, payload);
    // }
    // console.warn("payload not provided, GeoServices.getPremise");
    // return null;
  };

  getFlag = async ({ queryKey }) => {
    const [_key, { payload }] = queryKey;
    // if (payload) {
    return await axios.post(`/GeoFlage/GetData_Flag`, payload);
    // }
    // console.warn("payload not provided, GeoServices.getFlag");
    // return null;
  };

  insertLocation = async (payload) => {
    // const [_key, { payload }] = queryKey;

    // if (payload) {
    return await axios.post(`/GeoLocation/InsertLocation`, payload);
    // }
    // console.warn("zoneType not provided, GeoServices.insertLocation");
    // return null;
  };

  updateLocation = async (payload) => {
    // const [_key, { payload }] = queryKey;

    // if (payload) {
    return await axios.post(`/GeoLocation/UpdateLocation`, payload);
    // }
    // console.warn("zoneType not provided, GeoServices.updateLocation");
    // return null;
  };

  insertContactData = async (payload) => {
    // const [_key, { payload }] = queryKey;

    // if (payload) {
    return await axios.post(
      `/GeoLocationContactDetail/InsertGeoContact`,
      payload
    );
    // }
    // console.warn("zoneType not provided, GeoServices.getGeoPetrolZone");
    // return null;
  };
  updateContactData = async (payload) => {
    // const [_key, { payload }] = queryKey;

    // if (payload) {
    return await axios.post(
      `/GeoLocationContactDetail/UpdateGeoContact`,
      payload
    );
    // }
    // console.warn("zoneType not provided, GeoServices.getGeoPetrolZone");
    // return null;
  };
  getContactData = async ({ queryKey }) => {
    const [_key, { payload }] = queryKey;
    if (payload) {
      return await axios.post(
        `/GeoLocationContactDetail/GetData_GeoContact`,
        payload
      );
    }
    console.warn("payload not provided, GeoServices.getContactData");
    return null;
  };

  getSingleContactData = async (payload) => {
    // const [_key, { payload }] = queryKey;
    // console.log("payload", payload);
    if (payload) {
      return await axios.post(
        `/GeoLocationContactDetail/GetDataSingle`,
        payload
      );
    }
    console.warn("payload not provided, GeoServices.getSingleContactData");
    return null;
  };

  deleteContact = async (payload) => {
    // const [_key, { payload }] = queryKey;
    // console.log("payload", payload);
    if (payload) {
      return await axios.post(
        `/GeoLocationContactDetail/DeleteGeoContact`,
        payload
      );
    }
    console.warn("payload not provided, GeoServices.deleteContact");
    return null;
  };

  getLocationData = async (payload) => {
    // const [_key, { payload }] = queryKey;
    // console.log("payload", payload);
    if (payload) {
      return await axios.post(`/GeoLocation/GetData_Location`, payload);
    }
    console.warn("payload not provided, GeoServices.getLocationData");
    return null;
  };
}

const instance = new GeoServices();

export default instance;

import React from "react";
import Select from "react-select";
import { colorLessStyle_Select } from '../../../Utility/CustomStylesForReact';
import MapComponent from "../../../MapComponent";

const IncidentTabSection = () => {
  const colourStyles = {
    control: (styles) => ({
      ...styles, backgroundColor: "#fce9bf",
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };

  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };
  const mapCenter = {
    lng: -98.5795,
    lat: 39.8283,
  };


  return (
    <>
      <div className="tab-form-monitor-container">
        <div className="d-flex">
          <div
            className="col-8"
            style={{ display: "grid", gap: "5px" }}
          >

            <div className="tab-form-row">
              <div className="col-2 d-flex align-self-center justify-content-end">
                <label for="" className="tab-form-label">
                  Incident #
                </label>
              </div>
              <div className="col-10 d-flex align-items-center justify-content-end" style={{ display: "grid", gap: "5px" }}>
                <input
                  type="text"
                  className="form-control py-1 new-input highlighter-input-readonly"
                  readonly=""
                />
                <label for="" className="tab-form-label text-nowrap">
                  Master Incident #
                </label>
                <input
                  type="text"
                  className="form-control py-1 new-input highlighter-input-readonly"
                  readonly=""
                />
                <label for="" className="tab-form-label text-nowrap">
                  Case #
                </label>
                <input
                  type="text"
                  className="form-control py-1 new-input highlighter-input-readonly"
                  readonly=""
                />
              </div>
            </div>

            {/* Line 2 */}
            <div className="tab-form-row">
              <div className="col-2 d-flex align-self-center justify-content-end">
                <label for="" className="tab-form-label">
                  Reported Date & Time
                </label>
              </div>
              <div className="col-2 d-flex align-self-center justify-content-end">
                <input
                  type="date"
                  className="form-control py-1 new-input requiredColor"
                  placeholder="placeholder"
                />
              </div>
              <div className="col-2 d-flex align-self-center justify-content-end">
                <label for="" className="tab-form-label">
                  Occurred DT / TM
                </label>
              </div>
              <div className="col-2 d-flex align-self-center justify-content-end">
                <input type="date" className="form-control py-1 new-input highlighter-input-readonly" readonly="" />
              </div>
              <div className="col-2 d-flex align-self-center justify-content-end">
                <label for="" className="tab-form-label">
                  Recv Source
                </label>
              </div>
              <div className="col-2 d-flex align-self-center justify-content-end">
                <input
                  type="text"
                  className="form-control py-1 new-input"
                  placeholder="Placeholder"
                />
              </div>
            </div>

            {/* Line 3 */}
            <div className="tab-form-row">
              <div className="col-2 d-flex align-self-center justify-content-end">
                <label for="" className="tab-form-label">
                  Recv Source
                </label>
              </div>
              <div className="col-8 d-flex align-items-center justify-content-end" style={{ display: "grid", gap: "5px" }}>
                <input
                  type="text"
                  className="form-control py-1 new-input"
                  placeholder="Placeholder"
                />

                <label for="" className="tab-form-label text-nowrap">
                  Call Taker
                </label>
                <input
                  type="text"
                  className="form-control py-1 new-input"
                  placeholder="Placeholder"
                />

                <label for="" className="tab-form-label text-nowrap">
                  Call Agency
                </label>
              </div>
              <input
                type="text"
                className="form-control py-1 new-input"
                placeholder="Placeholder"
              />
            </div>
            {/* Line 4 */}
            <div className="tab-form-row">
              {/* <div className="col-2 d-flex align-self-center justify-content-end">
            <label for="" className="tab-form-label">
              Primary Res
            </label>
          </div>
          <div className="col-2 d-flex align-self-center justify-content-end">
            <input
              type="text"
              className="form-control py-1 new-input"
              placeholder="Placeholder"
            />
          </div> */}
              <div div className="col-2 d-flex align-self-center justify-content-end" >
                <label for="" className="tab-form-label">
                  Primary Officer
                </label>
              </div>
              <div className="col-3 w-100">
                <Select
                  styles={customStylesWithOutColor}
                  placeholder="Select"
                />
              </div>
              <div className="col-2 d-flex align-self-center justify-content-end">
                <label for="" className="tab-form-label">
                  Secondary Officer
                </label>
              </div>
              <div className="col-3 w-100">
                <Select
                  styles={customStylesWithOutColor}
                  placeholder="Select"
                />
              </div>
            </div>

            {/* Line 5 */}
            <div className="tab-form-row">
              <div className="col-2 d-flex align-self-center justify-content-end">
                <label for="" className="tab-form-label">
                  Common Place
                </label>
              </div>
              <div className="col-4 d-flex align-self-center justify-content-end">
                <input
                  type="text"
                  className="form-control py-1 new-input"
                  placeholder="Placeholder"
                />
              </div>
              <div className="col-2 d-flex align-self-center justify-content-end">
                <label for="" className="tab-form-label">
                  Cross Street
                </label>
              </div>
              <div className="col-4 d-flex align-self-center justify-content-end">
                <input
                  type="text"
                  className="form-control py-1 new-input"
                  placeholder="Placeholder"
                />
              </div>
            </div>

          </div>
          <div className="col-4" style={{ zIndex: "0" }}>
            <MapComponent latitude={mapCenter.lat} longitude={mapCenter.lng} />
          </div>
        </div>

        {/* Line 6 */}
        <div className="tab-form-row">
          <div className="d-flex align-self-center justify-content-end" style={{ width: "11%" }}>
            <label for="" className="tab-form-label">
              CFS L
            </label>
          </div>
          <div className="col-6 d-flex align-self-center justify-content-end">
            <div
              className="col-3 w-100"
            >
              <Select
                styles={colorLessStyle_Select}
                placeholder="Select"
              />
            </div>
            <div className="col-9 w-100">
              <Select
                styles={colorLessStyle_Select}
                placeholder="Select"
              />
            </div>
          </div>
          <div className="col-2 d-flex align-items-center justify-content-end">
            <label for="" className="tab-form-label mr-2">
              Priority
            </label>
            <Select
              styles={colorLessStyle_Select}
              placeholder="Select"
            />
          </div>

          <div className="col-2 d-flex align-items-center justify-content-end">
            <label for="" className="tab-form-label text-nowrap mr-2">
              Alarm Level
            </label>
            <Select
              styles={colorLessStyle_Select}
              placeholder="Select"
            />
          </div>
        </div>

        <div className="tab-form-row from-button-container" >
          <button className="cancel-button">Cancel</button>
          <button className="save-button">Save</button>
          <button className="save-button">New</button>
          <button className="save-button">Search</button>
        </div>
      </div>
    </>
  );
};

export default IncidentTabSection;

import React, { useEffect, useState } from 'react'
import { fetchPostData } from '../../hooks/Api';
import { useLocation, } from 'react-router-dom';
import { base64ToString, getShowingWithOutTime } from '../../Common/Utility';

const NameListing = (props) => {
    const { ListData, } = props
    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    let DeNameID = 0
    const query = useQuery();
    var NameID = query?.get("NameID");

    if (!NameID) NameID = 0;
    // else DeNameID = parseInt(base64ToString(NameID));

    // const [nameData, setNameData] = useState([]);
    const [value, setValue] = useState({
        'NameID': '',
    });
    useEffect(() => {
        if (ListData) {
            console.log(ListData)
        }
        // if (DecPropID) {
        //     setValue({ ...value, 'PropertyID': ProId, });
        //     get_Prop(DecPropID);
        // }
    }, [DeNameID]);
    // const get_Name = (NameID) => {
    //     const val = { NameID: NameID, }
    //     fetchPostData('TabBasicInformation/NameInformation', val).then((res) => {
    //         if (res) {
    //             setNameData(res);
    //         } else {
    //             setNameData([]);
    //         }
    //     })
    // }

    return (
        <>
            {
                ListData?.length === 0  ?
                    <>
                        <div className="mt-2">
                            <fieldset>
                                <legend>Name Information</legend>
                                <div className="col-12 bb">
                                    <div className="row">
                                        <div className="col-2 col-md-2 col-lg-1 showlist">
                                            <p htmlFor="" className='label-name'>Name:</p>
                                        </div>
                                        <div className="col-4 col-md-4 col-lg-2 text-show">
                                            <label htmlFor=""></label>
                                        </div>
                                        <div className="col-2 col-md-2 col-lg-1 showlist">
                                            <p htmlFor="" className='label-name'>DOB:</p>
                                        </div>
                                        <div className="col-4 col-md-4 col-lg-2 text-show">
                                            <label htmlFor=""></label>
                                        </div>
                                        <div className="col-2 col-md-2 col-lg-1 showlist">
                                            <p htmlFor="" className='label-name'>Gender:</p>
                                        </div>
                                        <div className="col-4 col-md-4 col-lg-2 text-show">
                                            <label htmlFor=""></label>
                                        </div>
                                        <div className="col-2 col-md-2 col-lg-1 showlist">
                                            <p htmlFor="" className='label-name'>SSN:</p>
                                        </div>
                                        <div className="col-4 col-md-4 col-lg-2 text-show">
                                            <label htmlFor=""></label>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </>
                    :
                    <>
                        {ListData?.map((data, index) => (
                            <div key={index} className="mt-2">
                                <fieldset>
                                    <legend>Name Information</legend>
                                    <div className="col-12 bb">
                                        <div className="row">
                                            <div className="col-2 col-md-2 col-lg-1 showlist">
                                                <p htmlFor="" className='label-name'>Name:</p>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-2 text-show">
                                                <label htmlFor="">{data.FullName}</label>
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 showlist">
                                                <p htmlFor="" className='label-name'>DOB:</p>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-2 text-show">
                                                <label htmlFor="">{data.DateOfBirth ? getShowingWithOutTime(data.DateOfBirth) : ''}</label>
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 showlist">
                                                <p htmlFor="" className='label-name'>Gender:</p>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-2 text-show">
                                                <label htmlFor="">{data.Gender}</label>
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 showlist">
                                                <p htmlFor="" className='label-name'>SSN:</p>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-2 text-show">
                                                <label htmlFor="">{data.SSN}</label>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        ))}
                    </>
            }
        </>
    )
}

export default NameListing
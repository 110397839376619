import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Decrypt_Id_Name, getShowingMonthDateYear } from '../../../../Common/Utility';
import { get_LocalStoreData } from '../../../../../redux/actions/Agency';
import { toastifySuccess } from '../../../../Common/AlertMsg';
import { AddDeleteUpadate } from '../../../../hooks/Api';

const EvidenceDestructionModel = (props) => {
    const { DecProRomId, DecPropID, DecMPropID, setSelectedFile, componentRef, get_EvidenceDestruction ,setEevidenceDestructionID,editval } = props

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';

    const [LoginAgencyID, setLoginAgencyID] = useState('');
    const [LoginPinID, setLoginPinID,] = useState('');
    const [PropertyRoomID, setPropertyRoomID] = useState('');

    //-----------Date------------------------
    const [crimeDate, setCrimeDate] = useState();
    const [dateOfReceipt, setDateOfReceipt] = useState();
    const [dateOfDestruction, setDateOfDestruction] = useState();
    const [dateOfDelivery, setDateOfDelivery] = useState();
    const [propertyReceivedDT, setPropertyReceivedDT] = useState();
    const [finalJudgementDT, setFinalJudgementDT] = useState();
    const [noticeSentDt, setNoticeSentDt] = useState();
    const [approvalDT, setApprovalDT] = useState();
    



    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID); setLoginPinID(localStoreData?.PINID);
        }
    }, [localStoreData]);

    useEffect(() => {
        if (DecProRomId) {
            setPropertyRoomID(DecProRomId);
        }
    }, [DecProRomId]);

    const [value, setValue] = useState({
        'PropertyroomID': '', 'Agency': '', 'ComplaintNo': '', 'ProsecutorCaseNo': '', 'DepartmentCaseNo': '', 'NameOfVicitm': '',
        'AddressOfVicitm': '', 'NJS': '', 'CrimeDate': '', 'PropertyReceivedDT': '', 'FinalJudgementDT': '',
        'LastCourtAction': '', 'DispositionOfCase': '', 'ReasonForDestruction': '', 'SignOfPersonReqDes': '',
        'NoticeSentDt': '', 'SentBy': '', 'SignCTAttorneyAppDes': '', 'ItemstobeDestroyed': '',
        'ApprovalDT': '', 'PropertydeliveredBy': '', 'DateOfDelivery': '', 'PropertyReceivedBy': '', 'DateOfReceipt': '',
        'DateOfDestruction': '', 'PlaceOfDestruction': '', 'MethodOfDestruction': '', 'PropertyOfDestruction': '',
        "CreatedByUserFK": '', 'CreatedDtTm': '',
        //------ReadioButton---
        'TypeOfEvidence': '',
        'TypeOfCase': '',
        'NoticeOfIntentSentToClaimant': '',
    });

    const Reset = () => {
        setValue({
            ...value, 'PropertyroomID': '', 'Agency': '', 'ComplaintNo': '', 'ProsecutorCaseNo': '', 'DepartmentCaseNo': '', 'NameOfVicitm': '',
            'AddressOfVicitm': '', 'NJS': '', 'CrimeDate': '', 'PropertyReceivedDT': '', 'FinalJudgementDT': '',
            'LastCourtAction': '', 'DispositionOfCase': '', 'ReasonForDestruction': '', 'SignOfPersonReqDes': '',
            'NoticeSentDt': '', 'SentBy': '', 'SignCTAttorneyAppDes': '', 'ItemstobeDestroyed': '',
            'ApprovalDT': '', 'PropertydeliveredBy': '', 'DateOfDelivery': '', 'PropertyReceivedBy': '', 'DateOfReceipt': '',
            'DateOfDestruction': '', 'PlaceOfDestruction': '', 'MethodOfDestruction': '', 'PropertyOfDestruction': '',
            "CreatedByUserFK": '', 'CreatedDtTm': '',
            'SignOfPersonDeliverProp': '',
            'SignOfPersonReceiveProp': '',
            'SignOfPersonDestWitDesProp': '',
            //------ReadioButton---
            'TypeOfEvidence': '',
            'TypeOfCase': '',
            'NoticeOfIntentSentToClaimant': '',
        }); setApprovalDT(''); setNoticeSentDt(''); setFinalJudgementDT(''); setPropertyReceivedDT(''); setDateOfDelivery(''); setDateOfDestruction(''); setDateOfReceipt(''); setCrimeDate('')
        // setErrors({ ...errors, ['NIBRSIDError']: '', });
    }

    useEffect(() => {
        if (editval) {
            setValue({
                ...value,
                'PropertyroomID': editval?.PropertyroomID, 'Agency': editval?.Agency, 'ComplaintNo': editval?.ComplaintNo, 'ProsecutorCaseNo': editval?.ProsecutorCaseNo, 'DepartmentCaseNo': editval?.DepartmentCaseNo, 'NameOfVicitm': editval?.NameOfVicitm,
        'AddressOfVicitm': editval?.AddressOfVicitm, 'NJS': editval?.NJS, 'CrimeDate': editval?.CrimeDate, 'PropertyReceivedDT': editval?.PropertyReceivedDT, 'FinalJudgementDT': editval?.FinalJudgementDT,
        'LastCourtAction': editval?.AddressOfVicitm, 'DispositionOfCase': editval?.AddressOfVicitm, 'ReasonForDestruction': editval?.AddressOfVicitm, 'SignOfPersonReqDes': editval?.AddressOfVicitm,
        'NoticeSentDt': editval?.NoticeSentDt, 'SentBy': editval?.SentBy, 'SignCTAttorneyAppDes': editval?.SignCTAttorneyAppDes, 'ItemstobeDestroyed': editval?.ItemstobeDestroyed,
        'ApprovalDT': editval?.ApprovalDT, 'PropertydeliveredBy': editval?.PropertydeliveredBy, 'DateOfDelivery': editval?.DateOfDelivery, 'PropertyReceivedBy': editval?.PropertyReceivedBy, 'DateOfReceipt': editval?.DateOfReceipt,
        'DateOfDestruction': editval?.DateOfDestruction, 'PlaceOfDestruction': editval?.PlaceOfDestruction, 'MethodOfDestruction': editval?.MethodOfDestruction, 'PropertyOfDestruction': editval?.PropertyOfDestruction,
        "CreatedByUserFK": editval?.CreatedByUserFK, 'CreatedDtTm': editval?.CreatedDtTm,
        //------ReadioButton---
        'TypeOfEvidence': editval?.TypeOfEvidence,
        'TypeOfCase': editval?.TypeOfCase,
        'NoticeOfIntentSentToClaimant': editval?.NoticeOfIntentSentToClaimant,
            })
          
        }
        else {
            setValue({
                ...value, 'PropertyroomID': '', 'Agency': '', 'ComplaintNo': '', 'ProsecutorCaseNo': '', 'DepartmentCaseNo': '', 'NameOfVicitm': '',
                'AddressOfVicitm': '', 'NJS': '', 'CrimeDate': '', 'PropertyReceivedDT': '', 'FinalJudgementDT': '',
                'LastCourtAction': '', 'DispositionOfCase': '', 'ReasonForDestruction': '', 'SignOfPersonReqDes': '',
                'NoticeSentDt': '', 'SentBy': '', 'SignCTAttorneyAppDes': '', 'ItemstobeDestroyed': '',
                'ApprovalDT': '', 'PropertydeliveredBy': '', 'DateOfDelivery': '', 'PropertyReceivedBy': '', 'DateOfReceipt': '',
                'DateOfDestruction': '', 'PlaceOfDestruction': '', 'MethodOfDestruction': '', 'PropertyOfDestruction': '',
                "CreatedByUserFK": '', 'CreatedDtTm': '',
                'SignOfPersonDeliverProp': '',
                'SignOfPersonReceiveProp': '',
                'SignOfPersonDestWitDesProp': '',
                //------ReadioButton---
                'TypeOfEvidence': '',
                'TypeOfCase': '',
                'NoticeOfIntentSentToClaimant': '',
            });
        }
    }, [editval])

    useEffect(() => {
        if (LoginAgencyID) {
            setValue({
                ...value,
                'PropertyroomID': DecProRomId, 'Agency': '', 'ComplaintNo': '', 'ProsecutorCaseNo': '', 'DepartmentCaseNo': '', 'NameOfVicitm': '',
                'AddressOfVicitm': '', 'NJS': '', 'CrimeDate': '', 'PropertyReceivedDT': '', 'FinalJudgementDT': '',
                'LastCourtAction': '', 'DispositionOfCase': '', 'ReasonForDestruction': '', 'SignOfPersonReqDes': '',
                'NoticeSentDt': '', 'SentBy': '', 'SignCTAttorneyAppDes': '', 'ItemstobeDestroyed': '',
                'ApprovalDT': '', 'PropertydeliveredBy': '', 'DateOfDelivery': '', 'PropertyReceivedBy': '', 'DateOfReceipt': '',
                'DateOfDestruction': '', 'PlaceOfDestruction': '', 'MethodOfDestruction': '', 'PropertyOfDestruction': '',
                "CreatedByUserFK": LoginPinID, 'CreatedDtTm': '',
            });
        }
    }, [LoginAgencyID]);


    const Add_Type = () => {
        console.log('Calling AddDeleteUpadate');
        AddDeleteUpadate('EvidenceDestruction/InsertEvidenceDestruction', value).then((res) => {
            console.log('Response received:', res);
            setEevidenceDestructionID(res.setEevidenceDestructionID)
            toastifySuccess(res.Message); get_EvidenceDestruction(DecProRomId);
        }).catch((error) => {
            console.error('Error:', error);


        });
    }

    const HandleChange = (e, name) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value,
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValue(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    return (
        <>
            <div class="modal fade" style={{ background: "rgba(0,0,0, 0.5)" }} id="EvidenceModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false">
                <div class="modal-dialog modal-xl rounded modal-dialog-scrollable">
                    <div class="modal-content">
                        <button type="button" className="border-0" aria-label="Close" data-dismiss="modal" style={{ alignSelf: "end" }} ><b>X
                        </b>
                        </button>
                        <div class="modal-body ">
                            <div className="col-12 col-md-12 col-lg-12 " style={{ marginTop: '-15px' }}>
                                <fieldset >
                                    <legend>Destruction Evidence</legend>
                                    <div className="row " >
                                        <div className="container mt-1" ref={componentRef}>
                                            <div className="row" >
                                                <table class="table table-bordered" >
                                                    <tbody>
                                                        <tr>
                                                            <td >
                                                                <span className='prop-td'>
                                                                    Type Of Evidence
                                                                </span>
                                                                <div className='row'>
                                                                    <div className="form-check pl-4">
                                                                        <input className="form-check-input" type="radio" name="TypeOfEvidence" id="flexRadioDefault1"
                                                                            value="CDS"
                                                                            onChange={handleChange}
                                                                            checked={value.TypeOfEvidence === 'CDS'}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                            CDS
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check pl-5">
                                                                        <input className="form-check-input" type="radio" name="TypeOfEvidence" id="flexRadioDefault2"
                                                                            value="Weapons"
                                                                            onChange={handleChange}
                                                                            checked={value.TypeOfEvidence === 'Weapons'}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                                            Weapons
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <span className='prop-td'>
                                                                    Type Of Case
                                                                </span>
                                                                <div className='row'>
                                                                    <div className="form-check pl-4">
                                                                        <input className="form-check-input" type="radio" name="TypeOfCase" id="flexRadioDefault3"
                                                                            value="Adult"
                                                                            // onChange={HandleChange}
                                                                            // checked={value.Adult}
                                                                            onChange={handleChange}
                                                                            checked={value.TypeOfCase === 'Adult'}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="flexRadioDefault3">
                                                                            Adult
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check pl-5">
                                                                        <input className="form-check-input" type="radio" name="TypeOfCase" id="flexRadioDefault4"
                                                                            value="Juvenile"
                                                                            onChange={handleChange}
                                                                            checked={value.TypeOfCase === 'Juvenile'}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="flexRadioDefault4">
                                                                            Juvenile
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>

                                                </table>
                                                <table class="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <span className='prop-td'>
                                                                    Agency:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className=' py-1' name='Agency' onChange={HandleChange} value={value?.Agency} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td className='col-4'>
                                                                <span className='prop-td'>
                                                                    Complaint No:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className=' py-1' name='ComplaintNo' onChange={HandleChange} value={value?.ComplaintNo} />
                                                                </div>
                                                            </td>
                                                            <td className='col-4'>
                                                                <span className='prop-td'>
                                                                    Prosecutor Case No:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className='py-1' name='ProsecutorCaseNo' onChange={HandleChange} value={value?.ProsecutorCaseNo} />
                                                                </div>
                                                            </td>
                                                            <td className='col-4'>
                                                                <span className='prop-td'>
                                                                    Department Case No:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className=' py-1 ' name='DepartmentCaseNo' onChange={HandleChange} value={value?.DepartmentCaseNo} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>

                                                </table>
                                                <table class="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <span className='prop-td'>
                                                                    Name  Of (defendant) (victim):
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className=' py-1 ' name='NameOfVicitm' onChange={HandleChange} value={value?.NameOfVicitm} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <span className='prop-td'>
                                                                    Address  Of (defendant) (victim):
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className=' py-1 ' name='AddressOfVicitm' onChange={HandleChange} value={value?.AddressOfVicitm} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td className='col-4'>
                                                                <span className='prop-td'>
                                                                    NJS:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className=' py-1 ' name='NJS' onChange={HandleChange} value={value?.NJS} />
                                                                </div>
                                                            </td>
                                                            <td className='col-4'>
                                                                <span className='prop-td'>
                                                                    Crime Date:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input
                                                                        type="text"
                                                                        className='py-1'
                                                                        onChange={(date) => { setCrimeDate(date); setValue({ ...value, ['CrimeDate']: date ? getShowingMonthDateYear(date) : null }) }}
                                                                        name='CrimeDate'
                                                                    // value={value?.CrimeDate}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td className='col-4'>
                                                                <span className='prop-td'>
                                                                    Date Property Received:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className='py-1' name='PropertyReceivedDT'
                                                                        onChange={(date) => { setPropertyReceivedDT(date); setValue({ ...value, ['PropertyReceivedDT']: date ? getShowingMonthDateYear(date) : null }) }}

                                                                    // value={value?.PropertyReceivedDT}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td className='col-4'>
                                                                <span className='prop-td'>
                                                                    Date Of Final Judgment:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className=' py-1 ' name='FinalJudgementDT'
                                                                        onChange={(date) => { setFinalJudgementDT(date); setValue({ ...value, ['FinalJudgementDT']: date ? getShowingMonthDateYear(date) : null }) }}
                                                                    // value={value?.FinalJudgementDT} 
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td className='col-4'>
                                                                <span className='prop-td'>
                                                                    Last Court Action:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className=' py-1 ' name='LastCourtAction' onChange={HandleChange} value={value?.LastCourtAction} />
                                                                </div>
                                                            </td>
                                                            <td className='col-4'>
                                                                <span className='prop-td'>
                                                                    Disposition Of Case:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className=' py-1 ' name='DispositionOfCase' onChange={HandleChange} value={value?.DispositionOfCase} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <span className='prop-td'>
                                                                    Reason For Destruction:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className=' py-1 ' name='ReasonForDestruction' onChange={HandleChange} value={value?.ReasonForDestruction} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td className='col-8'>
                                                                <span className='prop-td'>
                                                                    Person Requesting Destruction:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className=' py-1 ' name='SignOfPersonReqDes' onChange={HandleChange} value={value?.SignOfPersonReqDes} />
                                                                </div>
                                                            </td>
                                                            <td className='col-4'>
                                                                <span className='prop-td'>
                                                                    Date Of Request:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className=' py-1' name='DateOfRequest' onChange={HandleChange} value={value?.DateOfRequest} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <span className='prop-td'>
                                                                    Signature Of Person Requesting Destruction:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className=' py-1 ' name='SignatureOfPersonRequesting' onChange={HandleChange} value={value?.SignatureOfPersonRequesting} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <span className='prop-td'>
                                                                    Items to be destroyed (Complete descriptions-Weapons must include make,model,serial number etc):
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className=' py-1 ' onChange={HandleChange} value={value?.ItemstobeDestroyed} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td className='col-4'>
                                                                <span className='prop-td'>
                                                                    Notice of intent sent to claimant
                                                                </span>
                                                                <div className='row'>
                                                                    <div className="form-check pl-4">
                                                                        <input className="form-check-input" type="radio" name="NoticeOfIntentSentToClaimant" id="flexRadioDefault5"
                                                                            value="Yes"
                                                                            onChange={handleChange}
                                                                            checked={value.NoticeOfIntentSentToClaimant === 'Yes'}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="flexRadioDefault5">
                                                                            Yes
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check pl-5">
                                                                        <input className="form-check-input" type="radio" name="NoticeOfIntentSentToClaimant" id="flexRadioDefault6"
                                                                            value="No"
                                                                            onChange={handleChange}
                                                                            checked={value.NoticeOfIntentSentToClaimant === 'No'}

                                                                        />
                                                                        <label className="form-check-label" htmlFor="flexRadioDefault6">
                                                                            No
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check pl-5">
                                                                        <input className="form-check-input" type="radio" name="NoticeOfIntentSentToClaimant" id="flexRadioDefault6"
                                                                            value="N/A"
                                                                            onChange={handleChange}
                                                                            checked={value.NoticeOfIntentSentToClaimant === 'N/A'}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="flexRadioDefault6">
                                                                            N/A
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className='col-4'>
                                                                <span className='prop-td'>
                                                                    Date notice sent:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className=' py-1 ' name='NoticeSentDt'
                                                                        onChange={(date) => { setNoticeSentDt(date); setValue({ ...value, ['NoticeSentDt']: date ? getShowingMonthDateYear(date) : null }) }}
                                                                    //  value={value?.NoticeSentDt} 
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td className='col-4'>
                                                                <span className='prop-td'>
                                                                    Sent by:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className=' py-1 ' name='SentBy' onChange={HandleChange} value={value?.SentBy} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td className='col-6'>
                                                                <span className='prop-td'>
                                                                    Signature of chief trial attorney approving destruction:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className=' py-1 ' name='SignCTAttorneyAppDes' onChange={HandleChange} value={value?.SignCTAttorneyAppDes} />
                                                                </div>
                                                            </td>
                                                            <td className='col-6'>
                                                                <span className='prop-td'>
                                                                    Date Of Approval:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className=' py-1 ' name='ApprovalDT'
                                                                        onChange={(date) => { setApprovalDT(date); setValue({ ...value, ['ApprovalDT']: date ? getShowingMonthDateYear(date) : null }) }}
                                                                    // value={value?.ApprovalDT}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td className='col-6'>
                                                                <span className='prop-td'>
                                                                    Property delivered by:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className=' py-1 ' name='PropertydeliveredBy' onChange={HandleChange} value={value?.PropertydeliveredBy} />
                                                                </div>
                                                            </td>
                                                            <td className='col-6'>
                                                                <span className='prop-td'>
                                                                    Date Of delivery:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className=' py-1 ' name='DateOfDelivery'
                                                                        onChange={(date) => { setDateOfDelivery(date); setValue({ ...value, ['DateOfDelivery']: date ? getShowingMonthDateYear(date) : null }) }}
                                                                    // value={value?.DateOfDelivery} 
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td className='col-6'>
                                                                <span className='prop-td'>
                                                                    Property received by:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className=' py-1 ' name='PropertyReceivedBy' onChange={HandleChange} value={value?.PropertyReceivedBy} />
                                                                </div>
                                                            </td>
                                                            <td className='col-6'>
                                                                <span className='prop-td'>
                                                                    Date Of receipt:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className=' py-1 ' name='DateOfReceipt'
                                                                        onChange={(date) => { setDateOfReceipt(date); setValue({ ...value, ['DateOfReceipt']: date ? getShowingMonthDateYear(date) : null }) }}
                                                                    //  value={value?.DateOfReceipt}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td className='col-6'>
                                                                <span className='prop-td'>
                                                                    Date of destruction:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className=' py-1 ' name='DateOfDestruction'
                                                                        onChange={(date) => { setDateOfDestruction(date); setValue({ ...value, ['DateOfDestruction']: date ? getShowingMonthDateYear(date) : null }) }}
                                                                    //  value={value?.DateOfDestruction} 
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td className='col-6'>
                                                                <span className='prop-td'>
                                                                    Place of destruction:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className=' py-1 ' name='PlaceOfDestruction' onChange={HandleChange} value={value?.PlaceOfDestruction} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td className='col-6'>
                                                                <span className='prop-td'>
                                                                    Method of destruction:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className=' py-1 ' name='MethodOfDestruction' onChange={HandleChange} value={value?.MethodOfDestruction} />
                                                                </div>
                                                            </td>
                                                            <td className='col-6'>
                                                                <span className='prop-td'>
                                                                    Property of destruction:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <input type="text" className=' py-1 ' name='PropertyOfDestruction' onChange={HandleChange} value={value?.PropertyOfDestruction} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td className='col-4'>
                                                                <span className='prop-td'>
                                                                    Signature of person (s) delivering property:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-4 text-field">
                                                                    <textarea id="" cols="30" rows='2' name='SignOfPersonDeliverProp' onChange={HandleChange} value={value?.SignOfPersonDeliverProp}></textarea>
                                                                </div>
                                                            </td>
                                                            <td className='col-4'>
                                                                <span className='prop-td'>
                                                                    Signature of person (s) receiving property:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-4 text-field">
                                                                    <textarea id="" cols="30" rows='2' name='SignOfPersonReceiveProp' onChange={HandleChange} value={value?.SignOfPersonReceiveProp}></textarea>
                                                                </div>
                                                            </td>
                                                            <td className='col-4'>
                                                                <span className='prop-td'>
                                                                    Signature of person (s) dest and/or wit destruction of prop:
                                                                </span>
                                                                <div className="col-12 col-md-12 col-lg-12  mt-1 text-field">
                                                                    <textarea id="" cols="30" rows='2' name='SignOfPersonDestWitDesProp' onChange={HandleChange} value={value?.SignOfPersonDestWitDesProp}></textarea>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" onClick={Add_Type}>Save</button>
                            <button type="button" class="btn btn-secondary" onClick={Reset}>Clear</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={Reset} >Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EvidenceDestructionModel
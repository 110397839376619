import React from 'react'
import defualtImage from '../../../../img/uploadImage.png';

const ChainOfModel = (props) => {

  const { componentRefnew } = props

  return (
    <>

      <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)" }} id="ChainCustodyModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false">
        <div className="modal-dialog modal-xl rounded modal-dialog-scrollable">
          <div className="modal-content">
            <button type="button" className="border-0" aria-label="Close" data-dismiss="modal" style={{ alignSelf: "end" }} ><b>X
            </b>
            </button>
            <div className="modal-body ">
              <div classNameName="col-12 col-md-12 col-lg-12 " style={{ marginTop: '-15px' }}>
                <fieldset >
                  <legend className='prop-legend'>Chain Of Custody Report</legend>
                  <div classNameName="row " >
                    <div className="container" ref={componentRefnew}>
                      <div className="property-room px-3">
                        <div className="row">
                          <div className="col-4 col-md-3 col-lg-2 mt-2">
                            <div className="img-box" >
                              <img src={defualtImage} className='picture' style={{ width: '150px', height: '140px' }} />
                            </div>
                          </div>
                          <div className="col-8 col-md-9 col-lg-10">
                            <div className="row mt-3">
                              <div className="col-7 d-flex justify-content-center">
                                <h6>Agency:</h6>
                                <span>Universal:</span>
                              </div>
                              <div className="col-7 d-flex justify-content-center">
                                <h6>Phone:</h6>
                                <span>Testing:</span>
                              </div>
                              <div className="col-7 d-flex justify-content-center">
                                <h6>Fax:</h6>
                                <span>Testing:</span>
                              </div>
                              <div className="col-7 d-flex justify-content-center">
                                <h6>Address:</h6>
                                <span>jhfgsgfsgdfgsdfgsdjfsdh:</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      {/* chain info */}
                      <div className="property-info px-0">
                        <fieldset >
                          <legend className='prop-legend px-0'>Evidence Property Information</legend>
                          <table className="table table-bordered mt-3">
                            <tbody>
                              <tr className="table-row">
                                <td className="table-cell">
                                  <span className='prop-td'>Property No:</span>
                                  <p>2566545</p>
                                </td>
                                <td className="table-cell">
                                  <span className='prop-td'>Property Type:</span>
                                  <p>65465464</p>
                                </td>
                                <td className="table-cell">
                                  <span className='prop-td'>Reported Date:</span>
                                  <p>27-02646</p>
                                </td>

                              </tr>
                              <tr className="table-row">
                                <td className="table-cell">
                                  <span className='prop-td'>Incident No:</span>
                                  <p>{ }</p>
                                </td>
                                <td className="table-cell">
                                  <span className='prop-td'>Loss Code:</span>
                                  <p>{ }</p>
                                </td>
                                <td className="table-cell">
                                  <span className='prop-td'>Owner Name:</span>
                                  <p>{ }</p>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="table-cell">
                                  <span className='prop-td'>Category:</span>
                                  <p>3131466546</p>
                                </td>
                                <td className="table-cell">
                                  <span className='prop-td'>Classification:</span>
                                  <p>{ }</p>
                                </td>
                                <td className="table-cell">
                                  <span className='prop-td'>Brand:</span>
                                  <p>65465464</p>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="table-cell">
                                  <span className='prop-td'>Top Color:</span>
                                  <p>3131466546</p>
                                </td>
                                <td className="table-cell">
                                  <span className='prop-td'>Bottom Color:</span>
                                  <p>65465464</p>
                                </td>
                                <td className="table-cell">
                                  <span className='prop-td'>Quantity:</span>
                                  <p>27-02646</p>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="table-cell">
                                  <span className='prop-td'>Misc Description:</span>
                                  <p>3131466546</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                        </fieldset>
                      </div>
                      <div className="release-info">
                        <fieldset >
                          <legend className='prop-legend'>Chain Of Custody Information</legend>
                          <table className="table table-bordered mt-3">
                            <thead className='text-dark master-table'>
                              <tr>
                                <th className='' >Incident No.</th>
                                <th className='' >Activity Date/Time</th>
                                <th className='' >Activity Type</th>
                                <th className='' >Officer</th>
                                <th className='' >Property Romm Person</th>
                                <th className='' >Location/Reason</th>
                                <th className='' >Destroyed Date/Time</th>

                              </tr>
                            </thead>
                            <tbody >
                              <tr>
                                <td className='text-list' >adasd</td>
                                <td className='text-list' >adasd</td>
                                <td className='text-list' >adasd</td>
                                <td className='text-list' >adasd</td>
                                <td className='text-list' >adasd</td>
                                <td className='text-list' >adasd</td>
                                <td className='text-list' >adasd</td>
                              </tr>
                            </tbody>
                          </table>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div >
      </div >
    </>
  )
}

export default ChainOfModel
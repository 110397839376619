import React from 'react';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { CadDashboardTableCustomStyles } from '../../../Components/Common/Utility';

const data = [
  {
    id: 1,
    officer1: 'Officer A',
    officer2: 'Officer B',
    resourceId: 'R12345',
    agencyCode: 'AG01',
    incidentId: 'INC001',
    status: 'Open',
    statusDateTime: '2024-07-25 10:00:00',
    zone: 'Zone 1',
    eTime: '10:00:00',
    incidentDateTime: '2024-07-25 10:00:00',
    totalTime: '10:00:00',
  },
  {
    id: 2,
    officer1: 'Officer C',
    officer2: 'Officer D',
    resourceId: 'R12346',
    agencyCode: 'AG02',
    incidentId: 'INC002',
    status: 'Closed',
    statusDateTime: '2024-07-25 11:00:00',
    zone: 'Zone 2',
    eTime: '11:00:00',
    incidentDateTime: '2024-07-25 11:00:00',
    totalTime: '11:00:00',
  },
  {
    id: 3,
    officer1: 'Officer E',
    officer2: 'Officer F',
    resourceId: 'R12347',
    agencyCode: 'AG03',
    incidentId: 'INC003',
    status: 'Open',
    statusDateTime: '2024-07-25 12:00:00',
    zone: 'Zone 3',
    eTime: '12:00:00',
    incidentDateTime: '2024-07-25 12:00:00',
    totalTime: '12:00:00',
  },
  {
    id: 4,
    officer1: 'Officer G',
    officer2: 'Officer H',
    resourceId: 'R12348',
    agencyCode: 'AG04',
    incidentId: 'INC004',
    status: 'Closed',
    statusDateTime: '2024-07-25 13:00:00',
    zone: 'Zone 4',
    eTime: '13:00:00',
    incidentDateTime: '2024-07-25 13:00:00',
    totalTime: '13:00:00',
  },
  {
    id: 5,
    officer1: 'Officer I',
    officer2: 'Officer J',
    resourceId: 'R12349',
    agencyCode: 'AG05',
    incidentId: 'INC005',
    status: 'Open',
    statusDateTime: '2024-07-25 14:00:00',
    zone: 'Zone 5',
    eTime: '14:00:00',
    incidentDateTime: '2024-07-25 14:00:00',
    totalTime: '14:00:00',
  }
];

const columns = [
  {
    name: 'Officer 1',
    selector: (row) => row.officer1,
    sortable: true,
  },
  {
    name: 'Officer 2',
    selector: (row) => row.officer2,
    sortable: true,
  },
  {
    name: 'Resource #',
    selector: (row) => row.resourceId,
    sortable: true,
  },
  {
    name: 'Agency Code',
    selector: (row) => row.agencyCode,
    sortable: true,
  },
  {
    name: 'Incident #',
    selector: (row) => row.incidentId,
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row) => row.status,
    sortable: true,
  },
  {
    name: 'Status Date & Time',
    selector: (row) => row.statusDateTime,
    sortable: true,
  },
  {
    name: 'Zone',
    selector: (row) => row.zone,
    sortable: true,
  },
  {
    name: 'E Time',
    selector: (row) => row.eTime,
    sortable: true,
  },
  {
    name: 'Incident Date & Time',
    selector: (row) => row.incidentDateTime,
    sortable: true,
  },
  {
    name: 'Total Time',
    selector: (row) => row.totalTime,
    sortable: true,
  },
];
const ResourcesTableSection = () => {
  return (
    <>
      <div className='table-container'>
        <div className='row table-container-header'>
          <div className='col-6 d-flex align-content-center justify-content-start table-header-status'>
            <div className='d-flex'>
              <span>Active</span>
              <span>5</span>
            </div>
            <div className='d-flex'>
              <span>Available</span>
              <span>0</span>
            </div>
            <div className='d-flex'>
              <span>Unavailable</span>
              <span>0</span>
            </div>
          </div>
          <div className='col-6 d-flex align-content-center justify-content-end table-header-buttons'>
            <button>Active</button>
            <button>Available</button>
            <button>
              <FontAwesomeIcon icon={faFilter} style={{ marginRight: '8px', opacity: 0.7 }} />
              Filters
            </button>
          </div>
        </div>

        <DataTable
          dense
          columns={columns}
          data={data}
          customStyles={CadDashboardTableCustomStyles}
          pagination // enable pagination
          responsive // enable responsive design
          striped // add zebra-striping to the table rows
          highlightOnHover // highlight row on hover
          fixedHeader
        // fixedHeaderScrollHeight="200px" 
        />
      </div>
    </>
  );
};

export default ResourcesTableSection;

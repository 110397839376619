import React, { memo, useState, useEffect, useContext, useCallback } from 'react'
import Select from "react-select";
import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Carousel } from 'react-responsive-carousel';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { base64ToString, Decrypt_Id_Name, Encrypted_Id_Name, filterPassedDateTime, filterPassedTime, getShowingDateText, getShowingMonthDateYear, getShowingWithOutTime, getYearWithOutDateTime, stringToBase64, tableCustomStyles } from '../../../../Components/Common/Utility';
import { AgencyContext } from '../../../../Context/Agency/Index';
import { get_Inc_ReportedDate, get_LocalStoreData } from '../../../../redux/actions/Agency';
import { get_ScreenPermissions_Data } from '../../../../redux/actions/IncidentAction';
import { get_AgencyOfficer_Data, get_ArresteeName_Data, get_BoatModel_Drp_Data, get_Bottom_Color_Drp_Data, get_DrugManufactured_Drp_Data, get_Make_Drp_Data, get_Masters_Name_Drp_Data, get_Material_Drp_Data, get_MeasureType_Drp_Data, get_PropertyLossCode_Drp_Data, get_PropertyTypeData, get_PropSourceDrugDrpData, get_Propulusion_Drp_Data, get_State_Drp_Data, get_SuspectedDrug_Drp_Data, get_Top_Color_Drp_Data, get_TypeMarijuana_Drp_Data, get_VOD_Drp_Data, get_WeaponMake_Drp_Data, get_WeaponModel_Drp_Data } from '../../../../redux/actions/DropDownsData';
import { get_Property_Article_Search_Data, get_Property_Boat_Search_Data, get_Property_Other_Search_Data, get_Property_Security_Search_Data, get_Property_Weapon_Search_Data, get_PropertyMainModule_Data } from '../../../../redux/actions/PropertyAction';
import { RequiredFieldHIN, RequiredFieldIncident, RequiredFieldOnConditon } from '../../../../Components/Pages/Utility/Personnel/Validation';
import { AddDelete_Img, AddDeleteUpadate, fetchPostData } from '../../../../Components/hooks/Api';
import { MasterProperty_ID, Property_ID, Property_LossCode_Drp_Data, Property_Update_Status } from '../../../../redux/actionTypes';
import { Comman_changeArrayFormat, threeColArray } from '../../../../Components/Common/ChangeArrayFormat';
import { toastifyError, toastifySuccess } from '../../../../Components/Common/AlertMsg';
import AlertTable from '../../../../Components/Pages/AlertMaster/AlertTable';
import DeletePopUpModal from '../../../../Components/Common/DeleteModal';
import ChangesModal from '../../../../Components/Common/ChangesModal';
import IdentifyFieldColor from '../../../../Components/Common/IdentifyFieldColor';
import PropertySearchTab from '../../../../Components/Pages/PropertySearchTab/PropertySearchTab';
import MasterNameModel from '../../../../Components/Pages/MasterNameModel/MasterNameModel';
import ListModal from '../../../../Components/Pages/Utility/ListManagementModel/ListModal';
import ImageModel from '../../../../Components/Pages/ImageModel/ImageModel';
import AlertMasterModel from '../../../../Components/Pages/AlertMaster/AlertMasterModel';
import defualtImage from '../../../../img/uploadImage.png';


const PropertyTabSectionModal = (props, { setStatus, status, setShowVehicleRecovered, showVehicleRecovered, get_List }) => {
    const { openPropertyTabSectionModal, setOpenPropertyTabSectionModal } = props;

    const onCloseLocation = () => {
        setOpenPropertyTabSectionModal(false);
    };


    const dispatch = useDispatch();
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const propertyMainModuleData = useSelector((state) => state.Property.propertyMainModuleData);
    const incReportedDate = useSelector((state) => state.Agency.incReportedDate);
    const propertyTypeData = useSelector((state) => state.DropDown.propertyTypeData);
    const agencyOfficerDrpData = useSelector((state) => state.DropDown.agencyOfficerDrpData);

    const arresteeNameData = useSelector((state) => state.DropDown.arresteeNameData);
    const mastersNameDrpData = useSelector((state) => state.DropDown.mastersNameDrpData);

    const propertyLossCodeDrpData = useSelector((state) => state.DropDown.propertyLossCodeDrpData);
    const colorDrpData = useSelector((state) => state.DropDown.colorDrpData);
    const topColorDrpData = useSelector((state) => state.DropDown.topColorDrpData);
    const bottomColorDrpData = useSelector((state) => state.DropDown.bottomColorDrpData);
    const stateDrpData = useSelector((state) => state.DropDown.stateDrpData);
    const materialDrpData = useSelector((state) => state.DropDown.materialDrpData);
    const makeDrpData = useSelector((state) => state.DropDown.makeDrpData);
    const propulusionDrpData = useSelector((state) => state.DropDown.propulusionDrpData);
    const weaponMakeDrpData = useSelector((state) => state.DropDown.weaponMakeDrpData);
    const drugManufacturedDrpData = useSelector((state) => state.DropDown.drugManufacturedDrpData);
    const typeMarijuanaDrpData = useSelector((state) => state.DropDown.typeMarijuanaDrpData);
    const measureTypeDrpData = useSelector((state) => state.DropDown.measureTypeDrpData);
    const suspectedDrugDrpData = useSelector((state) => state.DropDown.suspectedDrugDrpData);
    const weaponModelDrpData = useSelector((state) => state.DropDown.weaponModelDrpData);
    const boatModelDrpData = useSelector((state) => state.DropDown.boatModelDrpData);
    const vodDrpData = useSelector((state) => state.DropDown.vodDrpData);
    const propSourceDrugDrpData = useSelector((state) => state.DropDown.propSourceDrugDrpData);
    const effectiveScreenPermission = useSelector((state) => state.Incident.effectiveScreenPermission);


    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    let DecPropID = 0, DecMPropID = 0
    const query = useQuery();
    var IncID = query?.get("IncId");
    var IncNo = query?.get("IncNo");
    var IncSta = query?.get("IncSta");
    var ProId = query?.get("ProId");
    var MProId = query?.get('MProId');
    var ProSta = query?.get('ProSta');
    let MstPage = query?.get('page');

    if (!IncID) IncID = 0;
    else IncID = parseInt(base64ToString(IncID));
    if (!ProId) ProId = 0;
    else DecPropID = parseInt(base64ToString(ProId));
    if (!MProId) ProId = 0;
    else DecMPropID = parseInt(base64ToString(MProId));

    const navigate = useNavigate();

    const { get_Incident_Count, setChangesStatus, changesStatus, get_Property_Count, setNameSearchStatus, nameSearchValue } = useContext(AgencyContext);

    const [loder, setLoder] = useState(false);
    const [drugLoder, setDrugLoder] = useState(false);
    const [manufactureDate, setManufactureDate] = useState();
    const [weaponfactureDate, setWeaponfactureDate] = useState();
    const [securityDate, setSecurityDate] = useState();
    const [propertyCategoryData, setPropertyCategoryData] = useState([]);
    const [propertyClassificationData, setPropertyClassificationData] = useState([]);
    const [editval, setEditval] = useState([]);
    const [propertyNumber, setPropertyNumber] = useState('');
    const [propertyArticle, setPropertyArticle] = useState([]);
    const [propertyWeapon, setPropertyWeapon] = useState([]);
    const [propertySecurity, setPropertySecurity] = useState([]);
    const [propertOther, setPropertOther] = useState([]);
    const [propertyBoat, setPropertyBoat] = useState([]);
    const [lossCode, setLossCode] = useState('');
    const [openPage, setOpenPage] = useState('');
    //------propertyID, MasterPropertyID------
    const [propertyID, setPropertyID] = useState('');
    const [masterPropertyID, setMasterPropertyID] = useState('');
    //-------------------image----------------
    const [multiImage, setMultiImage] = useState([]);
    const [imageId, setImageId] = useState('');
    const [modalStatus, setModalStatus] = useState(false);
    //------------DrugDataModal---------------
    const [drugData, setDrugData] = useState([]);
    const [propertyDrugID, setPropertyDrugID] = useState();
    const [drugModal, setDrugModal] = useState();
    const [drugEditData, setDrugEditData] = useState([]);
    const [drugTypecode, setDrugTypecode] = useState('');
    //row Clicked
    const [clickedRow, setClickedRow] = useState(null);
    const [isProperty, setIsProperty] = useState(true);
    const [nameModalStatus, setNameModalStatus] = useState(false);
    const [mainIncidentID, setMainIncidentID] = useState('');
    const [loginAgencyID, setLoginAgencyID] = useState('');
    const [loginPinID, setLoginPinID,] = useState('');
    const [updateCount, setUpdateCount] = useState(0);
    const [type, setType] = useState("Property");
    const [possessionID, setPossessionID] = useState('');
    const [possenSinglData, setPossenSinglData] = useState([]);
    const [uploadImgFiles, setuploadImgFiles] = useState([]);
    const [imageModalStatus, setImageModalStatus] = useState(false);
    const [hideDirector, setHideDirector] = useState(false);
    const [searchModalState, setSearchModalState] = useState();
    const [drugLocalArr, setDrugLocalArr] = useState([])
    const [localDrugCount, setLocalDrugCount] = useState(1);
    const [availableAlert, setAvailableAlert] = useState([]);

    const [value, setValue] = useState({
        'MasterPropertyID': '', 'PropertyID': '', 'AgencyID': '', 'IncidentID': '', 'CreatedByUserFK': '', 'ReportedDtTm': '', 'DestroyDtTm': '', 'Value': '',
        'PropertyCategoryCode': '', 'PropertyTypeID': null, 'CategoryID': null, 'ClassificationID': null, 'OfficerID': null, 'LossCodeID': null, 'PossessionOfID': null,
        'PropertyTag': '', 'NICB': '', 'Description': '', 'IsEvidence': '', 'IsSendToPropertyRoom': '', 'IsPropertyRecovered': '', 'MaterialID': null,
        'PropertyArticleID': null, 'SerialID': '', 'ModelID': '', 'OAN': '', 'Quantity': '', 'Brand': '', 'TopColorID': null, 'BottomColorID': null,
        'PropertyBoatID': null, 'BoatIDNumber': '', 'HIN': '', 'RegistrationNumber': '', 'VODID': null, 'Length': '', 'Comments': '', 'ManufactureYear': '',
        'MakeID': null, 'RegistrationExpiryDtTm': '', 'PropulusionID': null, 'RegistrationStateID': null, 'SuspectedDrugTypeID': null, 'SecurityDtTm': '',
        'OtherID': null, 'PropertyOtherID': null, 'PropertySecurityID': null, 'SecurityIDNumber': '', 'Denomination': '', 'IssuingAgency': '', 'MeasureTypeID': null,
        'WeaponIDNumber': '', 'Style': '', 'Finish': '', 'Caliber': '', 'Handle': '', 'IsAuto': '', 'BarrelLength': '', 'WeaponModelID': null, 'PropertyWeaponID': null,
        'EstimatedDrugQty': '', 'FractionDrugQty': '', 'MeasurementTypeID': null, 'ModifiedByUserFK': '', 'PropertyDrugID': 0, 'PropertySourceDrugTypeID': null,
        'MarijuanaTypeID': null, 'MarijuanaNumber': '', 'DrugManufacturedID': null, 'ClandistineLabsNumber': '', 'MasterID': null,
        'IsMaster': MstPage === "MST-Property-Dash" ? true : false, 'PropertyNumber': 'Auto Genrated', 'QuantityUnitID': null,
        'ArticleIDNumber': '',
        'Items': '', 'DoseUnits': '', 'LiquidOunces': '', 'SolidGrams': '', 'SolidOunces': '', 'SolidPounds': '', 'PropertyDrugMeasure_Description': '', 'SuspectedDrugType_Description': '',
    });

    const [imgData, setImgData] = useState({
        "PictureTypeID": '', "ImageViewID": '', "ImgDtTm": '', "OfficerID": '', "Comments": '', "DocumentID": ''
    })

    const [errors, setErrors] = useState({
        'PropertyTypeIDError': '', 'CategoryIDError': '', 'LossCodeIDError': '', 'OfficerIDError': '',
        //Boat RequireFields
        'RegStateError': '', 'RegNumError': '', 'HINError': '',
    })

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID); setLoginPinID(localStoreData?.PINID);
            dispatch(get_ScreenPermissions_Data("P059", localStoreData?.AgencyID, localStoreData?.PINID));
        }
    }, [localStoreData]);

    useEffect(() => {
        if (loginAgencyID) {
            setValue({
                ...value,
                'IncidentID': IncID, 'OfficerID': loginAgencyID, 'CreatedByUserFK': loginPinID, 'AgencyID': loginAgencyID,
                'ReportedDtTm': incReportedDate && MstPage != "MST-Property-Dash" ? getShowingDateText(incReportedDate) : getShowingMonthDateYear(new Date())
            });
            if (agencyOfficerDrpData?.length === 0) dispatch(get_AgencyOfficer_Data(loginAgencyID));
            dispatch(get_PropertyTypeData(loginAgencyID));
        }
    }, [loginAgencyID, incReportedDate]);

    useEffect(() => {
        if (DecPropID || DecMPropID) {
            setPropertyID(DecPropID); GetSingleData(DecPropID, DecMPropID); setMasterPropertyID(DecMPropID); get_Property_Count(DecPropID);
        }
    }, [DecPropID, DecMPropID]);

    useEffect(() => {
        if (propertyTypeData?.length != 0) {
            const Id = propertyTypeData?.filter((val) => { if (val?.id === "A") return val });
            if (Id.length > 0 && (ProSta == 'false' || ProSta == false)) {
                setValue({ ...value, ['PropertyTypeID']: Id[0]?.value, ['PropertyCategoryCode']: Id[0]?.id, })
                PropertyCategory(Id[0]?.value);
            }
        }
    }, [propertyTypeData, localStoreData]);


    useEffect(() => {
        if (IncID) {
            if (!incReportedDate) { dispatch(get_Inc_ReportedDate(IncID)) }
            setMainIncidentID(IncID);
            dispatch(get_PropertyMainModule_Data(IncID, MstPage === "MST-Property-Dash" ? true : false));
            dispatch(get_ArresteeName_Data('', '', IncID));
        }
        if (MstPage === "MST-Property-Dash" && possessionID) {
            dispatch(get_Masters_Name_Drp_Data(possessionID));
        }
    }, [IncID, nameModalStatus, possessionID]);

    useEffect(() => {
        if (possessionID) { setValue({ ...value, ['PossessionOfID']: parseInt(possessionID) }) }
    }, [possessionID, arresteeNameData]);

    const check_Validation_Error = (e) => {
        const PropertyTypeIDErr = RequiredFieldIncident(value?.PropertyTypeID);
        const CategoryIDErr = RequiredFieldIncident(value?.CategoryID);
        const LossCodeIDErr = RequiredFieldIncident(value?.LossCodeID);
        const ContactErr = lossCode === 'STOL' || lossCode === 'BURN' || lossCode === 'RECD' ? RequiredFieldOnConditon(value.Value) : 'true';
        const HINErr = value.PropertyCategoryCode === 'B' ? RequiredFieldHIN(value?.HIN, 12, 21) : 'true';
        const RegNumErr = value.PropertyCategoryCode === 'B' ? RequiredFieldIncident(value?.RegistrationNumber) : "true";
        const RegStateErr = value.PropertyCategoryCode === 'B' ? RequiredFieldIncident(value?.RegistrationStateID) : "true";

        setErrors(prevValues => {
            return {
                ...prevValues,
                ['PropertyTypeIDError']: PropertyTypeIDErr || prevValues['PropertyTypeIDError'],
                ['CategoryIDError']: CategoryIDErr || prevValues['CategoryIDError'],
                ['LossCodeIDError']: LossCodeIDErr || prevValues['LossCodeIDError'],
                ['ContactError']: ContactErr || prevValues['ContactError'],
                ['HINError']: HINErr || prevValues['HINError'],
                ['RegNumError']: RegNumErr || prevValues['RegNumError'],
                ['RegStateError']: RegStateErr || prevValues['RegStateError'],
            }
        });
    }

    // Check All Field Format is True Then Submit 
    const { PropertyTypeIDError, ContactError, CategoryIDError, LossCodeIDError, RegStateError, RegNumError, HINError } = errors

    useEffect(() => {
        if (PropertyTypeIDError === 'true' && ContactError === 'true' && CategoryIDError === 'true' && LossCodeIDError === 'true' && HINError === 'true' && RegNumError === 'true' && RegStateError === 'true') {
            console.log("123")
            if (MstPage === "MST-Property-Dash") {
                console.log("456")
                if (masterPropertyID) {
                    console.log("798")
                    update_Property();
                } else {
                    console.log("012")
                    Add_Property();
                }
            } else {
                if (propertyID && masterPropertyID) {
                    update_Property();
                } else {
                    Add_Property();
                }
            }
        }
    }, [PropertyTypeIDError, ContactError, CategoryIDError, LossCodeIDError, RegStateError, RegNumError, HINError]);

    const GetSingleData = (propertyId, masterPropertyId) => {
        const val = { 'PropertyID': propertyId, 'MasterPropertyID': masterPropertyId, 'PINID': loginPinID, 'IncidentID': mainIncidentID, 'IsMaster': MstPage === "MST-Property-Dash" ? true : false, }
        const val2 = { 'MasterPropertyID': masterPropertyId, 'PropertyID': 0, 'PINID': loginPinID, 'IncidentID': 0, 'IsMaster': MstPage === "MST-Property-Dash" ? true : false, }
        fetchPostData('Property/GetSingleData_Property', MstPage === "MST-Property-Dash" ? val2 : val).then((res) => {
            if (res) {
                setEditval(res); setLoder(true);
            } else { setEditval([]); setLoder(true); }
        })
    }

    const get_PropertyArticle_Single_Data = (masterPropertyId, propertyId, PropertyCategoryCode) => {
        const val = { 'PropertyID': propertyId, 'PropertyCategoryCode': PropertyCategoryCode, 'MasterPropertyID': 0, 'IsMaster': MstPage === "MST-Property-Dash" ? true : false, }
        const val2 = { 'MasterPropertyID': masterPropertyId, 'PropertyCategoryCode': PropertyCategoryCode, 'PropertyID': 0, 'IsMaster': true }
        fetchPostData('Property/GetData_PropertyArticle', MstPage === "MST-Property-Dash" || !propertyId ? val2 : val).then((res) => {
            if (res) {
                setPropertyArticle(res);
            }
            else { setPropertyArticle([]) }
        })
    }

    const get_PropertyBoat_Single_Data = (masterPropertyId, propertyId, PropertyCategoryCode) => {
        const val = { 'PropertyID': propertyId, 'PropertyCategoryCode': PropertyCategoryCode, 'MasterPropertyID': 0, 'IsMaster': MstPage === "MST-Property-Dash" ? true : false, }
        const val2 = { 'MasterPropertyID': masterPropertyId, 'PropertyCategoryCode': PropertyCategoryCode, 'PropertyID': 0, 'IsMaster': true }
        fetchPostData('Property/GetData_PropertyBoat', MstPage === "MST-Property-Dash" || !propertyId ? val2 : val).then((res) => {
            if (res) {
                setPropertyBoat(res);
            } else { setPropertyBoat([]) }
        })
    }

    const get_PropertOther_Single_Data = (masterPropertyId, propertyId, PropertyCategoryCode) => {
        const val = { 'PropertyID': propertyId, 'PropertyCategoryCode': PropertyCategoryCode, 'MasterPropertyID': 0, 'IsMaster': MstPage === "MST-Property-Dash" ? true : false, }
        const val2 = { 'MasterPropertyID': masterPropertyId, 'PropertyCategoryCode': PropertyCategoryCode, 'PropertyID': 0, 'IsMaster': true }
        fetchPostData('Property/GetData_PropertOther', MstPage === "MST-Property-Dash" || !propertyId ? val2 : val).then((res) => {
            if (res) {
                setPropertOther(res);
            }
            else { setPropertOther([]) }
        })
    }

    const get_PropertySecurity_Single_Data = (masterPropertyId, propertyId, PropertyCategoryCode) => {
        const val = { 'PropertyID': propertyId, 'PropertyCategoryCode': PropertyCategoryCode, 'MasterPropertyID': 0, 'IsMaster': MstPage === "MST-Property-Dash" ? true : false, }
        const val2 = { 'MasterPropertyID': masterPropertyId, 'PropertyCategoryCode': PropertyCategoryCode, 'PropertyID': 0, 'IsMaster': true }
        fetchPostData('Property/GetData_PropertySecurity', MstPage === "MST-Property-Dash" || !propertyId ? val2 : val).then((res) => {
            if (res) {
                setPropertySecurity(res);
            } else { setPropertySecurity([]) }
        })
    }

    const get_PropertyWeapon_Single_Data = (masterPropertyId, propertyId, PropertyCategoryCode) => {
        const val = { 'PropertyID': propertyId, 'PropertyCategoryCode': PropertyCategoryCode, 'MasterPropertyID': 0, 'IsMaster': MstPage === "MST-Property-Dash" ? true : false, }
        const val2 = { 'MasterPropertyID': masterPropertyId, 'PropertyCategoryCode': PropertyCategoryCode, 'PropertyID': 0, 'IsMaster': true }
        fetchPostData('Property/GetData_PropertyWeapon', MstPage === "MST-Property-Dash" || !propertyId ? val2 : val).then((res) => {
            if (res) {
                setPropertyWeapon(res);
            }
            else { setPropertyWeapon([]) }
        })
    }

    useEffect(() => {
        if (editval?.length > 0) {
            sessionStorage.setItem("propertyStolenValue", Encrypted_Id_Name(editval[0]?.Value, 'SForStolenValue'));
            setMasterPropertyID(editval[0]?.MasterPropertyID);
            setPropertyID(MstPage === "MST-Property-Dash" ? '' : editval[0]?.PropertyID)
            dispatch({ type: MasterProperty_ID, payload: editval[0]?.MasterPropertyID });
            dispatch({ type: Property_ID, payload: MstPage === "MST-Property-Dash" ? '' : editval[0]?.PropertyID });
            if (Get_Property_Code(editval, propertyTypeData) === 'A') {
                get_PropertyArticle_Single_Data(editval[0]?.MasterPropertyID, propertyID, Get_Property_Code(editval, propertyTypeData))
                setPropertOther([]); setPropertyBoat([]); setPropertyWeapon([]); setPropertySecurity([])
                dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '1', '', '', '', '', '')); console.log("Call  Type === A")

            } else if (Get_Property_Code(editval, propertyTypeData) === 'B') {
                get_PropertyBoat_Single_Data(editval[0]?.MasterPropertyID, propertyID, Get_Property_Code(editval, propertyTypeData))
                setPropertOther([]); setPropertyArticle([]); setPropertyWeapon([]); setPropertySecurity([])
                dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '1', '', '', '', '')); console.log("Call  Type === B")

            } else if (Get_Property_Code(editval, propertyTypeData) === 'O') {
                get_PropertOther_Single_Data(editval[0]?.MasterPropertyID, propertyID, Get_Property_Code(editval, propertyTypeData))
                setPropertyArticle([]); setPropertyBoat([]); setPropertyWeapon([]); setPropertySecurity([])
                dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '', '', '1', '', '')); console.log("Call  Type === O")

            } else if (Get_Property_Code(editval, propertyTypeData) === 'S') {
                get_PropertySecurity_Single_Data(editval[0]?.MasterPropertyID, propertyID, Get_Property_Code(editval, propertyTypeData))
                setPropertOther([]); setPropertyBoat([]); setPropertyWeapon([]); setPropertyArticle([])
                dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '', '1', '', '', '')); console.log("Call  Type === S")

            } else if (Get_Property_Code(editval, propertyTypeData) === 'G') {
                get_PropertyWeapon_Single_Data(editval[0]?.MasterPropertyID, propertyID, Get_Property_Code(editval, propertyTypeData))
                setPropertOther([]); setPropertyBoat([]); setPropertyArticle([]); setPropertySecurity([]);
                dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '', '', '', '', '1')); console.log("Call  Type === G")

            } else if (Get_Property_Code(editval, propertyTypeData) === 'D') {
                get_Data_Drug_Modal(editval[0]?.MasterPropertyID, propertyID);
                setPropertOther([]); setPropertyBoat([]); setPropertyArticle([]); setPropertySecurity([]); setPropertyWeapon([]);
                dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '', '', '', '1', '')); console.log("Call  Type === D")
            }

            setLossCode(Get_LossCode(editval, propertyLossCodeDrpData));
            setValue({
                ...value,
                'PropertyID': MstPage === "MST-Property-Dash" ? '' : editval[0]?.PropertyID,
                'MasterPropertyID': editval[0]?.MasterPropertyID,
                'PropertyTypeID': editval[0]?.PropertyTypeID,
                'ModifiedByUserFK': loginPinID,
                'PropertyNumber': editval[0]?.PropertyNumber,
                'CategoryID': editval[0]?.CategoryID,
                'ClassificationID': editval[0]?.ClassificationID,
                'ReportedDtTm': editval[0]?.ReportedDtTm ? getShowingDateText(editval[0]?.ReportedDtTm) : '',
                'DestroyDtTm': editval[0]?.DestroyDtTm ? getShowingDateText(editval[0]?.DestroyDtTm) : '',
                'Value': editval[0]?.Value ? editval[0]?.Value : '',
                'OfficerID': editval[0]?.OfficerID,
                'LossCodeID': editval[0]?.LossCodeID,
                'PropertyTag': editval[0]?.PropertyTag,
                'NICB': editval[0]?.NICB,
                'Description': editval[0]?.Description,
                'IsEvidence': editval[0]?.IsEvidence,
                'IsSendToPropertyRoom': editval[0]?.IsSendToPropertyRoom,
                'IsPropertyRecovered': editval[0]?.IsPropertyRecovered,
                'PossessionOfID': editval[0]?.PossessionOfID,
                'PropertyCategoryCode': Get_Property_Code(editval, propertyTypeData),
                // -------------------------------------------------Article fields --------
                'PropertyArticleID': Get_Property_Code(editval, propertyTypeData) === "A" ? editval[0].PropertyArticle[0]?.PropertyArticleID : '',
                //---------------------------------- boat Fields --------------------------
                'PropertyBoatID': Get_Property_Code(editval, propertyTypeData) === "B" ? editval[0].PropertyBoat[0]?.PropertyBoatID : "",
                // ----------------------------Other Fields-----------------------
                'PropertyOtherID': Get_Property_Code(editval, propertyTypeData) === "O" ? editval[0].PropertyOther[0]?.PropertyOtherID : '',
                // ----------------------------Security Fields-----------------------
                'PropertySecurityID': Get_Property_Code(editval, propertyTypeData) === "S" ? editval[0].PropertySecurity[0]?.PropertySecurityID : '',
                'PropertyWeaponID': Get_Property_Code(editval, propertyTypeData) === "G" ? editval[0].PropertyWeapon[0]?.PropertyWeaponID : '',
            })
            setPossessionID(editval[0]?.PossessionOfID);
            PropertyCategory(editval[0]?.PropertyTypeID);
            PropertyClassification(editval[0]?.CategoryID);
            setPropertyNumber(editval[0]?.PropertyNumber);
            get_Name_MultiImage(DecPropID, DecMPropID);
        } else {
            Reset();
        }
    }, [editval, propertyTypeData])

    // useEffect(() => {
    //   propertyLossCodeDrpData?.filter(val => {
    //     if (val.value === value?.LossCodeID) {
    //       if (val.id === "STOL" || val.id === "RECD" || val.id === "BURN") {
    //         setShowRecovered(true);
    //       } else {
    //         setShowRecovered(false);
    //       }
    //     }
    //   });
    // }, [value.LossCodeID, propertyLossCodeDrpData]);

    useEffect(() => {
        if (propertyArticle.length > 0) {
            // console.log(propertyArticle)
            setValue({
                ...value,
                'ArticleIDNumber': propertyArticle[0]?.ArticleIDNumber, 'SerialID': propertyArticle[0]?.SerialID, 'PropertyArticleID': propertyArticle[0]?.PropertyArticleID, 'ModelID': propertyArticle[0]?.ModelID, 'TopColorID': propertyArticle[0]?.TopColorID, 'BottomColorID': propertyArticle[0]?.BottomColorID, 'OAN': propertyArticle[0]?.OAN, 'Quantity': propertyArticle[0]?.Quantity, 'Brand': propertyArticle[0]?.Brand,
            })
        }
    }, [propertyArticle])

    useEffect(() => {
        if (propertyBoat.length > 0) {
            setValue({
                ...value,
                'BoatIDNumber': propertyBoat[0]?.BoatIDNumber, 'PropertyBoatID': propertyBoat[0]?.PropertyBoatID, 'MaterialID': propertyBoat[0]?.MaterialID, 'VODID': propertyBoat[0]?.VODID,
                'Length': propertyBoat[0]?.Length, 'RegistrationStateID': propertyBoat[0]?.RegistrationStateID, 'RegistrationNumber': propertyBoat[0]?.RegistrationNumber,
                'MakeID': propertyBoat[0]?.MakeID, 'ModelID': propertyBoat[0]?.ModelID, 'Comments': propertyBoat[0]?.Comments, 'HIN': propertyBoat[0]?.HIN,
                'PropulusionID': propertyBoat[0]?.PropulusionID, 'BottomColorID': propertyBoat[0]?.BottomColorID, 'TopColorID': propertyBoat[0]?.TopColorID,
                'ManufactureYear': propertyBoat[0]?.ManufactureYear ? getYearWithOutDateTime(propertyBoat[0]?.ManufactureYear) : null,
                // 'RegistrationExpiryDtTm': propertyBoat[0]?.RegistrationExpiryDtTm ? getShowingDateText(propertyBoat[0]?.RegistrationExpiryDtTm) : null,
                'RegistrationExpiryDtTm': propertyBoat[0]?.RegistrationExpiryDtTm ? propertyBoat[0]?.RegistrationExpiryDtTm : null,
            });
            setManufactureDate(propertyBoat[0]?.ManufactureYear ? new Date(propertyBoat[0]?.ManufactureYear) : null);
        }
    }, [propertyBoat])

    useEffect(() => {
        console.log(propertOther)
        if (propertOther.length > 0) {
            setValue({
                ...value,
                'OtherID': propertOther[0]?.OtherID, 'PropertyOtherID': propertOther[0]?.PropertyOtherID, 'Brand': propertOther[0]?.Brand, 'QuantityUnitID': propertOther[0]?.QuantityUnitID ? parseInt(propertOther[0]?.QuantityUnitID) : '',
                'SerialID': propertOther[0]?.SerialID ? propertOther[0]?.SerialID : '', 'TopColorID': propertOther[0]?.TopColorID, 'BottomColorID': propertOther[0]?.BottomColorID, 'ModelID': propertOther[0]?.ModelID, 'Quantity': propertOther[0]?.Quantity,
            })
        }
    }, [propertOther])

    useEffect(() => {
        if (propertySecurity.length > 0) {
            setValue({
                ...value,
                'SecurityIDNumber': propertySecurity[0]?.SecurityIDNumber, 'PropertySecurityID': propertySecurity[0]?.PropertySecurityID, 'Denomination': propertySecurity[0]?.Denomination,
                'IssuingAgency': propertySecurity[0]?.IssuingAgency, 'MeasureTypeID': propertySecurity[0]?.MeasureTypeID ? parseInt(propertySecurity[0]?.MeasureTypeID) : '', 'SecurityDtTm': propertySecurity[0]?.SecurityDtTm,
                'SerialID': propertySecurity[0]?.SerialID,
            })
            setSecurityDate(propertySecurity[0]?.SecurityDtTm ? new Date(propertySecurity[0]?.SecurityDtTm) : '');
        }
    }, [propertySecurity])

    useEffect(() => {
        if (propertyWeapon.length > 0) {
            setValue({
                ...value,
                'WeaponIDNumber': propertyWeapon[0]?.WeaponIDNumber, 'PropertyWeaponID': propertyWeapon[0]?.PropertyWeaponID, 'Style': propertyWeapon[0]?.Style, 'Finish': propertyWeapon[0]?.Finish, 'Caliber': propertyWeapon[0]?.Caliber, 'Handle': propertyWeapon[0]?.Handle, 'SerialID': propertyWeapon[0]?.SerialID, 'MakeID': propertyWeapon[0]?.MakeID, 'WeaponModelID': propertyWeapon[0]?.WeaponModelID, 'IsAuto': propertyWeapon[0]?.IsAuto, 'BarrelLength': propertyWeapon[0]?.BarrelLength,
                'ManufactureYear': propertyWeapon[0]?.ManufactureYear ? getYearWithOutDateTime(propertyWeapon[0]?.ManufactureYear) : null,
            });
            setWeaponfactureDate(propertyWeapon[0]?.ManufactureYear ? new Date(propertyWeapon[0]?.ManufactureYear) : null);
        }
    }, [propertyWeapon])

    useEffect(() => {
        if (value?.PropertyCategoryCode === 'A') {
            if (topColorDrpData?.length === 0) dispatch(get_Top_Color_Drp_Data(loginAgencyID))
            if (bottomColorDrpData?.length === 0) dispatch(get_Bottom_Color_Drp_Data(loginAgencyID))
            // if (colorDrpData?.length === 0) dispatch(get_Color_Drp_Data(loginAgencyID))
            dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '1', '', '', '', '', ''));
        } else if (value?.PropertyCategoryCode === 'B') {
            if (propulusionDrpData?.length === 0) dispatch(get_Propulusion_Drp_Data(loginAgencyID));
            // if (colorDrpData?.length === 0) dispatch(get_Color_Drp_Data(loginAgencyID))
            if (topColorDrpData?.length === 0) dispatch(get_Top_Color_Drp_Data(loginAgencyID))
            if (bottomColorDrpData?.length === 0) dispatch(get_Bottom_Color_Drp_Data(loginAgencyID))
            if (stateDrpData?.length === 0) dispatch(get_State_Drp_Data());
            if (materialDrpData?.length === 0) dispatch(get_Material_Drp_Data(loginAgencyID))
            if (vodDrpData?.length === 0) dispatch(get_VOD_Drp_Data(loginAgencyID));
            if (makeDrpData?.length === 0) dispatch(get_Make_Drp_Data(loginAgencyID))
            if (boatModelDrpData?.length === 0) dispatch(get_BoatModel_Drp_Data(loginAgencyID));
        } else if (value.PropertyCategoryCode === 'D') {
            if (suspectedDrugDrpData?.length === 0) dispatch(get_SuspectedDrug_Drp_Data(loginAgencyID));
            if (propSourceDrugDrpData?.length === 0) dispatch(get_PropSourceDrugDrpData(loginAgencyID));
            if (measureTypeDrpData?.length === 0) dispatch(get_MeasureType_Drp_Data(loginAgencyID));
            if (typeMarijuanaDrpData?.length === 0) dispatch(get_TypeMarijuana_Drp_Data(loginAgencyID));
            if (drugManufacturedDrpData?.length === 0) dispatch(get_DrugManufactured_Drp_Data(loginAgencyID));
        } else if (value.PropertyCategoryCode === 'O') {
            // if (colorDrpData?.length === 0) dispatch(get_Color_Drp_Data(loginAgencyID))
            if (measureTypeDrpData?.length === 0) dispatch(get_MeasureType_Drp_Data(loginAgencyID));
            if (topColorDrpData?.length === 0) dispatch(get_Top_Color_Drp_Data(loginAgencyID))
            if (bottomColorDrpData?.length === 0) dispatch(get_Bottom_Color_Drp_Data(loginAgencyID))
        } else if (value.PropertyCategoryCode === 'S') {
            if (measureTypeDrpData?.length === 0) dispatch(get_MeasureType_Drp_Data(loginAgencyID));
        } else if (value.PropertyCategoryCode === 'G') {
            if (weaponModelDrpData?.length === 0) dispatch(get_WeaponModel_Drp_Data(loginAgencyID));
            if (weaponMakeDrpData?.length === 0) dispatch(get_WeaponMake_Drp_Data(loginAgencyID));
        }
    }, [value?.PropertyCategoryCode])

    const get_Data_Drug_Modal = (masterPropertyId, propertyID) => {
        const val = { 'PropertyID': propertyID, 'MasterPropertyID': masterPropertyId, 'IsMaster': MstPage === "MST-Property-Dash" ? true : false, }
        fetchPostData('PropertyDrug/GetData_PropertyDrug', val).then((res) => {
            if (res) {
                setDrugData(res); setDrugLoder(true);
            } else {
                setDrugData([]); setDrugLoder(true);
            }
        })
    }

    const PropertyCategory = (CategoryID) => {
        const val = { CategoryID: CategoryID, }
        fetchPostData('Property/GetDataDropDown_PropertyType', val).then((data) => {
            if (data) {
                setPropertyCategoryData(threeColArray(data, 'PropertyDescID', 'Description', 'CategoryID'))
            } else {
                setPropertyCategoryData([]);
            }
        })
    }

    const PropertyClassification = (PropertyDescID) => {
        const val = { PropertyDescID: PropertyDescID, }
        fetchPostData('Property/GetDataDropDown_PropertyClassification', val).then((data) => {
            if (data) {
                setPropertyClassificationData(Comman_changeArrayFormat(data, 'PropertyClassificationID', 'Description'))
            } else {
                setPropertyClassificationData([]);
            }
        })
    }

    const Reset = () => {
        setValue({
            ...value,
            'PropertyID': '', 'MasterPropertyID': '', 'PropertyNumber': 'Auto Generated', 'PropertyCategoryCode': 'A', 'PossessionOfID': null, 'OfficerID': null,
            //  'PropertyTypeID': '',
            // Dropdown
            'CategoryID': null, 'ClassificationID': null, 'LossCodeID': null,
            // 'ReportedDtTm': '',
            'DestroyDtTm': '', 'Value': '', 'PropertyTag': '', 'NICB': '', 'Description': '',
            // checkbox
            'IsEvidence': '', 'IsSendToPropertyRoom': '', 'IsPropertyRecovered': '',
            // Article fields 
            'SerialID': '', 'ModelID': '', 'OAN': '', 'Quantity': '', 'Brand': '', 'TopColorID': null, 'BottomColorID': null,
            // boat Fields 
            'BoatIDNumber': '', 'HIN': '', 'RegistrationNumber': '', 'VODID': null, 'Length': '', 'Comments': '', 'ManufactureYear': null, 'MaterialID': null,
            'MakeID': null, 'RegistrationExpiryDtTm': null, 'PropulusionID': null, 'RegistrationStateID': null,
            // Other Fields
            'OtherID': null,
            //Security
            'SecurityIDNumber': '', 'Denomination': '', 'IssuingAgency': '', 'MeasureTypeID': null, 'SecurityDtTm': null,
            //Weapon 
            'WeaponIDNumber': '', 'Style': '', 'Finish': '', 'Caliber': '', 'Handle': '', 'IsAuto': '', 'BarrelLength': '', 'WeaponModelID': null, 'PropertyWeaponID': null,
            //drug Fields
            'SuspectedDrugTypeID': null, 'EstimatedDrugQty': '', 'FractionDrugQty': '', 'MarijuanaTypeID': null, 'MarijuanaNumber': '', 'DrugManufacturedID': null, '  ClandistineLabsNumber': null,
            'MasterID': null, 'Clandestine': '',
            'Items': '', 'DoseUnits': '', 'LiquidOunces': '', 'SolidGrams': '', 'SolidOunces': '', 'SolidPounds': '',
        });
        setErrors({ ...errors, 'PropertyTypeIDError': '', 'CategoryIDError': '', 'LossCodeIDError': '', 'OfficerIDError': '', });
        setSecurityDate(''); setPropertyNumber(''); setLossCode(''); setWeaponfactureDate(null); setManufactureDate(null); setDrugData([]); setAvailableAlert([])
        setMasterPropertyID(''); dispatch({ type: MasterProperty_ID, payload: '' });
        setPropertyID(''); dispatch({ type: Property_ID, payload: '' });
        if (propertyTypeData?.length != 0) {
            const id = propertyTypeData?.filter((val) => { if (val?.id === "A") return val });
            if (id.length > 0) {
                setValue(prevValues => { return { ...prevValues, ['PropertyTypeID']: id[0]?.value, ['PropertyCategoryCode']: id[0]?.id, } })
                PropertyCategory(id[0]?.value);
            }
        }
    }

    const ChangeDropDown = (e, name) => {
        if (e) {
            if (name === 'SuspectedDrugTypeID') {
                setDrugTypecode(e.id)
                setChangesStatus(true)
                setValue({
                    ...value,
                    [name]: e.value, 'SuspectedDrugType_Description': e.label, 'PropertySourceDrugTypeID': '', 'TypeMarijuana': '', 'MarijuanaNumber': '', '  ClandistineLabsNumber': '', 'DrugManufactured': '',
                });
            } else if (name === 'PropertyTypeID') {
                switch (e.id) {
                    case 'A': dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '1', '', '', '', '', '')); break;
                    case 'B': dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '1', '', '', '', '')); break;
                    case 'S': dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '', '1', '', '', '')); break;
                    case 'O': dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '', '', '1', '', '')); break;
                    case 'D': dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '', '', '', '1', '')); break;
                    case 'G': dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '', '', '', '', '1')); break;
                    default: dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '1', '', '', '', '', ''));;
                }
                PropertyCategory(e.value);
                setChangesStatus(true);
                setValue({ ...value, ['PropertyCategoryCode']: e.id, ['PropertyTypeID']: e.value, ['ClassificationID']: null, ['LossCodeID']: null, });
                setDrugLoder(true);
            } else if (name === 'CategoryID') {
                PropertyClassification(e.value);
                setChangesStatus(true)
                setValue({ ...value, [name]: e.value });
            } else if (name === "PossessionOfID") {
                setPossessionID(e.value); setPossenSinglData([]); setValue({ ...value, [name]: e.value });
            } else if (name === "MeasurementTypeID") {
                setValue({ ...value, [name]: e.value, 'PropertyDrugMeasure_Description': e.label });
            } else {
                setChangesStatus(true)
                setValue({ ...value, [name]: e.value });
            }
        } else {
            if (name === 'SuspectedDrugTypeID') {
                setChangesStatus(true)
                setValue({
                    ...value,
                    [name]: null
                });
                setDrugTypecode('');
            } else if (name === 'PropertyTypeID') {
                setChangesStatus(true);
                setValue({
                    ...value,
                    ['PropertyTypeID']: null, ['PropertyCategoryCode']: '', ['CategoryID']: null, ['ClassificationID']: null, ['LossCodeID']: null,
                });
                setPropertyCategoryData([]); setPropertyClassificationData([]); setLossCode('');
                dispatch({ type: Property_LossCode_Drp_Data, payload: [] });
            } else if (name === 'CategoryID') {
                setChangesStatus(true);
                setValue({ ...value, ['CategoryID']: null, ['ClassificationID']: null, });
                setPropertyClassificationData([]);
            } else if (name === "PossessionOfID") {
                setPossessionID(''); setPossenSinglData([])
                setChangesStatus(true);
                setValue({ ...value, [name]: null });
            } else {
                setChangesStatus(true);
                setValue({ ...value, [name]: null });
            }
            void 0;
        }
    }

    const HandleChanges = (e) => {
        if (e.target.name === 'IsEvidence' || e.target.name === 'IsSendToPropertyRoom' || e.target.name === 'IsPropertyRecovered' || e.target.name === 'IsAuto') {
            setChangesStatus(true)
            setValue({
                ...value,
                [e.target.name]: e.target.checked
            })
        }
        else if (e.target.name === 'EstimatedDrugQty' || e.target.name === 'SolidPounds' || e.target.name === 'SolidOunces' || e.target.name === 'SolidGrams' || e.target.name === 'LiquidOunces' || e.target.name === 'DoseUnits' || e.target.name === 'Items') {
            let ele = e.target.value.replace(/[^0-9]/g, "")
            if (ele.length === 10) {
                const cleaned = ('' + ele).replace(/[^0-9]/g, '');
                setChangesStatus(true)
                setValue({
                    ...value,
                    [e.target.name]: cleaned
                });
            } else {
                ele = e.target.value.split('$').join('').replace(/[^0-9]/g, "");
                setChangesStatus(true)
                setValue({
                    ...value,
                    [e.target.name]: ele
                });
            }
        } else if (e.target.name === 'Quantity' || e.target.name === 'Length' || e.target.name === 'BarrelLength' || e.target.name === 'FractionDrugQty' || e.target.name === 'MarijuanaNumber' || e.target.name === 'ClandistineLabsNumber') {
            const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
            setChangesStatus(true)
            setValue({
                ...value,
                [e.target.name]: checkNumber
            });
        }
        else if (e.target.name === 'Denomination') {
            var ele = e.target.value.replace(/[^0-9\.]/g, "")
            if (ele.includes('.')) {
                if (ele.length === 16) {
                    setChangesStatus(true)
                    setValue({ ...value, [e.target.name]: ele });
                } else {

                    if (ele.substr(ele.indexOf('.') + 1).slice(0, 2)) {
                        const checkDot = ele.substr(ele.indexOf('.') + 1).slice(0, 2).match(/\./g)
                        if (!checkDot) {
                            setChangesStatus(true)
                            setValue({ ...value, [e.target.name]: ele.substring(0, ele.indexOf(".")) + '.' + ele.substr(ele.indexOf('.') + 1).slice(0, 2) });
                        }
                    } else {
                        setChangesStatus(true)
                        setValue({ ...value, [e.target.name]: ele })
                    }
                }
            } else {
                if (ele.length === 16) {
                    setChangesStatus(true)
                    setValue({
                        ...value,
                        [e.target.name]: ele
                    });
                } else {
                    setChangesStatus(true)
                    setValue({
                        ...value,
                        [e.target.name]: ele
                    });
                }
            }
        } else if (e.target.name === 'HIN' || e.target.name === 'RegistrationNumber') {
            var ele = e.target.value.replace(/[^0-9a-zA-Z]/g, "")
            setValue({
                ...value,
                [e.target.name]: ele
            });
        }
        else if (e.target.name === 'Value') {
            const ele = e.target.value.replace(/[^0-9\.]/g, "")
            if (ele.includes('.')) {
                if (ele.length === 16) {
                    setValue({ ...value, [e.target.name]: ele });
                } else {
                    if (ele.substr(ele.indexOf('.') + 1).slice(0, 2)) {
                        const checkDot = ele.substr(ele.indexOf('.') + 1).slice(0, 2).match(/\./g)
                        if (!checkDot) {
                            setValue({ ...value, [e.target.name]: ele.substring(0, ele.indexOf(".")) + '.' + ele.substr(ele.indexOf('.') + 1).slice(0, 2) });
                            return;
                        } else {
                            return;
                        }
                    } else { setValue({ ...value, [e.target.name]: ele }) }
                }
            } else {
                setValue({ ...value, [e.target.name]: ele });
            }
        } else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }

    useEffect(() => {
        if (value.PropertyCategoryCode && editval.length === 0) ResetFields_On_Change(value.PropertyCategoryCode);
    }, [value.PropertyCategoryCode])

    const ResetFields_On_Change = (Code) => {
        //Boat 
        if (Code !== 'B') {
            setValue({
                ...value,
                'BoatIDNumber': '', 'ManufactureYear': '', 'Length': '', 'RegistrationStateID': '', 'RegistrationNumber': '', 'VODID': null, 'MaterialID': null,
                'MakeID': '', 'ModelID': '', 'Comments': '', 'HIN': '', 'RegistrationExpiryDtTm': '', 'PropulusionID': '', 'BottomColorID': '', 'TopColorID': '',
            });
        }
        //Article
        if (Code !== 'A') {
            setValue({
                ...value,
                'SerialID': '', 'ModelID': '', 'TopColorID': '', 'BottomColorID': '', 'OAN': '', 'Quantity': '', 'Brand': '',
            })
        }
        //Other
        if (Code !== 'O') {
            setValue({
                ...value,
                'OtherID': null, 'Brand': '', 'SerialID': '', 'BottomColorID': '', 'ModelID': '', 'Quantity': '',
            })
        }
        //Security
        if (Code !== 'S') {
            setValue({
                ...value,
                'SecurityIDNumber': '', 'Denomination': '', 'IssuingAgency': '', 'MeasureTypeID': null, 'SecurityDtTm': '', 'SerialID': '',
            })
        }
        //Weapon
        if (Code !== 'G') {
            setValue({
                ...value,
                'WeaponIDNumber': '', 'Style': '', 'Finish': '', 'Caliber': '', 'Handle': '', 'SerialID': '', 'MakeID': '', 'WeaponModelID': null, 'IsAuto': '', 'ManufactureYear': '',
                'BarrelLength': '',
            })
        }
    }

    const Add_Property = () => {
        AddDeleteUpadate('Property/Insert_Property', value).then((res) => {
            // console.log(res);
            if (res.success) {
                console.log(res)
                if (MstPage === "MST-Property-Dash") {
                    navigate(`/Prop-Home?page=MST-Property-Dash&ProId=${stringToBase64(res?.PropertyID)}&MProId=${stringToBase64(res?.MasterPropertyID)}&ModNo=${res?.PropertyNumber?.trim()}&ProSta=${true}`);
                } else {
                    navigate(`/Prop-Home?IncId=${stringToBase64(IncID)}&IncNo=${IncNo}&IncSta=${IncSta}&ProId=${stringToBase64(res?.PropertyID)}&MProId=${stringToBase64(res?.MasterPropertyID)}&ProSta=${true}`)
                }
                Reset();
                if (uploadImgFiles?.length > 0) {
                    upload_Image_File(res.PropertyID, res.MasterPropertyID)
                    setuploadImgFiles('')
                }
                if (drugLocalArr?.length > 0 && value.PropertyCategoryCode === 'D') {
                    Add_Drug(true, res.PropertyID, res.MasterPropertyID);
                }
                toastifySuccess(res.Message);
                setErrors({ ...errors, ['LossCodeIDError']: '', }); setUpdateCount(updateCount + 1)
                get_Incident_Count(mainIncidentID);
                dispatch(get_PropertyMainModule_Data(mainIncidentID, MstPage === "MST-Property-Dash" ? true : false));

                setChangesStatus(false); setPossenSinglData([]); setDrugLocalArr([]);
            } else {
                toastifyError('error');
                setErrors({ ...errors, ['PropertyTypeIDError']: '', })
            }
        })
    }

    const update_Property = () => {
        const previousValue = value.Value;
        AddDeleteUpadate('Property/Update_Property', value).then((res) => {
            if (res?.success) {
                toastifySuccess(res.Message);
                dispatch(get_PropertyMainModule_Data(mainIncidentID, MstPage === "MST-Property-Dash" ? true : false));
                setChangesStatus(false)
                setErrors({ ...errors, ['PropertyTypeIDError']: '', })
                setValue({ ...value, Value: previousValue, });
                // get_List(propertyID, masterPropertyID);
                if (uploadImgFiles?.length > 0) {
                    upload_Image_File()
                    setuploadImgFiles('')
                }
            } else {
                toastifyError('error');
                setErrors({ ...errors, ['PropertyTypeIDError']: '', })
            }
        })
    }

    const newProperty = () => {
        if (MstPage === "MST-Property-Dash") {
            navigate(`/Prop-Home?page=MST-Property-Dash&ProId=${0}&MProId=${0}&ModNo=${''}&ProSta=${false}`);
            Reset(); setClickedRow(null); setMultiImage([]); setPossessionID(''); setPossenSinglData([]);
            dispatch({ type: Property_Update_Status, payload: false });
            get_Property_Count(''); setChangesStatus(false)
        } else {
            navigate(`/Prop-Home?IncId=${stringToBase64(IncID)}&IncNo=${IncNo}&IncSta=${IncSta}&ProId=${0}&MProId=${0}&ProSta=${false}`)
            Reset(); setClickedRow(null); setMultiImage([]); setPossessionID(''); setPossenSinglData([]);
            dispatch({ type: Property_Update_Status, payload: false });
            get_Property_Count(''); setChangesStatus(false)
            setErrors({
                ...errors,
                'PropertyTypeIDError': '', 'CategoryIDError': '', 'LossCodeIDError': '', 'OfficerIDError': '',
                //Boat RequireFields
                'RegStateError': '', 'RegNumError': '', 'HINError': '',
            })
        }
    }

    const columns1 = [
        {
            width: '250px',
            name: 'Property Number',
            selector: (row) => row.PropertyNumber,
            sortable: true
        },
        {
            name: 'Property Type',
            selector: (row) => row.PropertyType_Description,
            sortable: true
        },
        {
            name: 'Category',
            selector: (row) => row.PropertyCategory_Description,
            sortable: true
        },
        {
            name: 'Loss Code',
            selector: (row) => row.PropertyLossCode_Description,
            sortable: true
        },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: '7px', right: 10 }}>Delete</p>,
            cell: row =>
                <div style={{ position: 'absolute', top: 4, right: 10 }}>
                    {
                        effectiveScreenPermission ?
                            effectiveScreenPermission[0]?.DeleteOK ?
                                <span onClick={(e) => { setPropertyID(row.PropertyID); dispatch({ type: Property_ID, payload: row.PropertyID }); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                                    <i className="fa fa-trash"></i>
                                </span>
                                : <></>
                            :
                            <span onClick={(e) => { setPropertyID(row.PropertyID); dispatch({ type: Property_ID, payload: row.PropertyID }); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                                <i className="fa fa-trash"></i>
                            </span>
                    }
                </div>
        }
    ]

    const HideCol = React.useMemo(() => [
        {
            width: '250px',
            name: 'Property Number',
            selector: (row) => row.PropertyNumber,
            sortable: true
        },
        {
            name: 'Category',
            selector: (row) => row.PropertyCategory_Description,
            sortable: true
        },
        {
            name: 'Loss Code',
            selector: (row) => row.PropertyLossCode_Description,
            sortable: true,
            omit: hideDirector,
        },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: '7px', right: 10 }}>Delete</p>,
            cell: row =>
                <div style={{ position: 'absolute', top: 4, right: 10 }}>
                    <span onClick={(e) => { setHideDirector(true); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1">
                        <i>Hide</i>
                    </span>
                </div>
        }
    ], [hideDirector]);

    const set_EditRow = (row) => {
        setuploadImgFiles('')
        if (row.PropertyID || row.MasterPropertyID) {
            navigate(`/Prop-Home?IncId=${stringToBase64(IncID)}&IncNo=${IncNo}&IncSta=${IncSta}&ProId=${stringToBase64(row?.PropertyID)}&MProId=${stringToBase64(row?.MasterPropertyID)}&ProSta=${true}`)
            Reset();
            GetSingleData(row?.PropertyID, row?.MasterPropertyID);
            get_Property_Count(row?.PropertyID);
            dispatch({ type: Property_Update_Status, payload: true });
            setMasterPropertyID(row?.MasterPropertyID); dispatch({ type: MasterProperty_ID, payload: row?.MasterPropertyID });
            setPropertyID(row?.PropertyID); dispatch({ type: Property_ID, payload: row.PropertyID });
        }
    }

    const Delete_Property = () => {
        const val = { 'PropertyID': propertyID, 'DeletedByUserFK': loginPinID, 'IsMaster': MstPage === "MST-Property-Dash" ? true : false, }
        AddDeleteUpadate('Property/Delete_Property', val).then((res) => {
            if (res) {
                toastifySuccess(res.Message); get_Incident_Count(mainIncidentID);
                dispatch(get_PropertyMainModule_Data(mainIncidentID, MstPage === "MST-Property-Dash" ? true : false));
                newProperty()
            } else { console.log("Somthing Wrong"); }
        })
    }

    const columns = [
        {
            name: 'Suspected Drug Type ID',
            selector: (row) => row.SuspectedDrugType_Description,
            sortable: true
        },
        {
            name: 'Estimated Drug Qty',
            selector: (row) => row.EstimatedDrugQty,
            sortable: true
        },
        {
            name: 'Fraction Drug Qty',
            selector: (row) => row.FractionDrugQty,
            sortable: true
        },
        {
            name: 'Measurement Type ID',
            selector: (row) => row.PropertyDrugMeasure_Description,
            sortable: true
        },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 10 }}>Action</p>,
            cell: row =>
                <div className="div" style={{ position: 'absolute', top: 0, right: 0 }}>
                    <button onClick={(e) => { set_Edit_Value(e, row) }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DrugModal" >
                        <i className="fa fa-edit"></i>
                    </button>
                    <button onClick={() => { setIsProperty(false); setPropertyDrugID(row.PropertyDrugID); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                        <i className="fa fa-trash"></i>
                    </button>
                </div>
        },
        {
            name: <button className="btn btn-sm bg-green text-white px-0 py-0 " style={{ position: 'absolute', top: 8, right: 10 }} onClick={() => { setStatusFalse() }} data-toggle="modal" data-target="#DrugModal"><i className="fa fa-plus"></i></button>,
        },
    ]

    const set_Edit_Value = (e, row) => {
        setPropertyDrugID(row.PropertyDrugID); setDrugEditData(row); setDrugModal(true)
    }

    const setStatusFalse = (e) => {
        setChangesStatus(false)
        setPropertyDrugID(''); setDrugTypecode('')
        setDrugModal(true)
        setValue({
            ...value,
            'SuspectedDrugTypeID': null, 'EstimatedDrugQty': '', 'FractionDrugQty': '', 'MeasurementTypeID': '', 'PropertyDrugID': '',
            'PropertySourceDrugTypeID': '', 'MarijuanaTypeID': null, 'MarijuanaNumber': '', 'DrugManufacturedID': null, '  ClandistineLabsNumber': '',
            'Items': '', 'DoseUnits': '', 'LiquidOunces': '', 'SolidGrams': '', 'SolidOunces': '', 'SolidPounds': '',
        })
    }

    const [drugErrors, setDrugErrors] = useState({
        'SuspectedDrugTypeIDError': '', 'EstimatedDrugQtyError': '', 'FractionDrugQtyError': '', 'MeasurementTypeIDError': '',
        'solidPoundsError': '', 'solidOunceError': '', 'solidGramError': '', 'liquidOunceError': '', 'doseUnitsError': '', 'ItemsError': '',
    })

    const check_Drug_Validation_Error = () => {
        const SuspectedDrugTypeIDErr = RequiredFieldIncident(value.SuspectedDrugTypeID);
        const EstimatedDrugQtyErr = RequiredFieldIncident(value.EstimatedDrugQty);
        const FractionDrugQtyErr = RequiredFieldIncident(value.FractionDrugQty);
        const MeasurementTypeIDErr = RequiredFieldIncident(value.MeasurementTypeID);
        const solidPoundsErr = RequiredFieldIncident(value.SolidPounds);
        const solidOunceErr = RequiredFieldIncident(value.SolidOunces);
        const solidGramErr = RequiredFieldIncident(value.SolidGrams);
        const liquidOunceErr = RequiredFieldIncident(value.LiquidOunces);
        const doseUnitsErr = RequiredFieldIncident(value.DoseUnits);
        const ItemsErr = RequiredFieldIncident(value.Items);
        setDrugErrors(prevValues => {
            return {
                ...prevValues,
                ['SuspectedDrugTypeIDError']: SuspectedDrugTypeIDErr || prevValues['SuspectedDrugTypeIDErr'],
                ['EstimatedDrugQtyError']: EstimatedDrugQtyErr || prevValues['EstimatedDrugQtyError'],
                ['FractionDrugQtyError']: FractionDrugQtyErr || prevValues['FractionDrugQtyError'],
                ['MeasurementTypeIDError']: MeasurementTypeIDErr || prevValues['MeasurementTypeIDError'],
                ['solidPoundsError']: solidPoundsErr || prevValues['solidPoundsError'],
                ['solidOunceError']: solidOunceErr || prevValues['solidOunceError'],
                ['solidGramError']: solidGramErr || prevValues['solidGramError'],
                ['liquidOunceError']: liquidOunceErr || prevValues['liquidOunceError'],
                ['doseUnitsError']: doseUnitsErr || prevValues['doseUnitsError'],
                ['ItemsError']: ItemsErr || prevValues['ItemsError'],
            }
        })
    }

    // Check All Field Format is True Then Submit 
    const { SuspectedDrugTypeIDError, EstimatedDrugQtyError, FractionDrugQtyError, MeasurementTypeIDError, solidPoundsError, solidOunceError, solidGramError, liquidOunceError, doseUnitsError, ItemsError } = drugErrors

    useEffect(() => {
        if (SuspectedDrugTypeIDError === 'true' && EstimatedDrugQtyError === 'true' && FractionDrugQtyError === 'true' && MeasurementTypeIDError === 'true' && solidPoundsError === 'true' && solidOunceError === 'true' && solidGramError === 'true' && liquidOunceError === 'true' && doseUnitsError === 'true' && ItemsError === 'true') {
            if (propertyDrugID) { update_DrugModal(); }
            else {
                if (ProSta === 'true' || ProSta === true) {
                    Add_Drug(true, 0, 0);
                } else {
                    Add_Drug(false, 0, 0);
                }
            }
        }
    }, [SuspectedDrugTypeIDError, EstimatedDrugQtyError, FractionDrugQtyError, MeasurementTypeIDError, solidPoundsError, solidOunceError, solidGramError, liquidOunceError, doseUnitsError, ItemsError])

    useEffect(() => {
        if (propertyDrugID) {
            setValue({
                ...value,
                'SuspectedDrugTypeID': parseInt(drugEditData?.SuspectedDrugTypeID),
                'EstimatedDrugQty': drugEditData?.EstimatedDrugQty,
                'FractionDrugQty': drugEditData?.FractionDrugQty,
                'MeasurementTypeID': drugEditData?.MeasurementTypeID,
                'MasterPropertyID': drugEditData?.MasterPropertyID,
                'PropertyDrugID': drugEditData?.PropertyDrugID,
                'PropertySourceDrugTypeID': drugEditData?.PropertySourceDrugTypeID,
                'MarijuanaTypeID': drugEditData?.MarijuanaTypeID,
                'MarijuanaNumber': drugEditData?.MarijuanaNumber,
                'DrugManufacturedID': drugEditData?.DrugManufacturedID,
                'ClandistineLabsNumber': drugEditData?.ClandistineLabsNumber,
                'Items': drugEditData?.Items, 'DoseUnits': drugEditData?.DoseUnits, 'LiquidOunces': drugEditData?.LiquidOunces, 'SolidGrams': drugEditData?.SolidGrams, 'SolidOunces': drugEditData?.SolidOunces, 'SolidPounds': drugEditData?.SolidPounds,
            })
            setDrugTypecode(Get_Drug_Code(drugEditData, suspectedDrugDrpData));
        } else {
            setValue({
                ...value,
                'SuspectedDrugTypeID': null, 'EstimatedDrugQty': '', 'FractionDrugQty': '', 'MeasurementTypeID': '', 'PropertySourceDrugTypeID': '',
                'MarijuanaTypeID': null, 'MarijuanaNumber': '', 'DrugManufacturedID': null, '  ClandistineLabsNumber': '',
                'Items': '', 'DoseUnits': '', 'LiquidOunces': '', 'SolidGrams': '', 'SolidOunces': '', 'SolidPounds': '',
            });
            setDrugErrors({
                ...drugErrors,
                'SuspectedDrugTypeIDError': '', 'EstimatedDrugQtyError': '', 'FractionDrugQtyError': '', 'MeasurementTypeIDError': '',
                'solidPoundsError': '', 'solidOunceError': '', 'solidGramError': '', 'liquidOunceError': '', 'doseUnitsError': '', 'ItemsError': '',
            })
        }
    }, [drugEditData, drugModal])

    // useEffect(() => {
    //   var obj = JSON.parse(sessionStorage.getItem('DrugLocalData'));
    //   console.log(obj)
    //   if (obj?.length > 0) {
    //     setDrugLocalArr(obj);
    //   }
    // }, []);

    const Add_Drug = (ProStatus, proID, MstProID) => {
        if (ProStatus) {
            if (proID || MstProID) {
                // var obj = JSON.parse(sessionStorage.getItem('DrugLocalData'));
                // console.log(obj)

                const oldArr = drugLocalArr
                const newArr = oldArr.map(obj => ({
                    MasterPropertyID: MstProID,
                    PropertyID: proID,
                    SuspectedDrugTypeID: obj.SuspectedDrugTypeID,
                    EstimatedDrugQty: obj.EstimatedDrugQty,
                    FractionDrugQty: obj.FractionDrugQty,
                    MeasurementTypeID: obj.MeasurementTypeID,
                    PropertyCategoryCode: obj.PropertyCategoryCode,
                    PropertySourceDrugTypeID: obj.PropertySourceDrugTypeID,
                    CreatedByUserFK: obj.CreatedByUserFK,
                    MarijuanaTypeID: obj.MarijuanaTypeID,
                    MarijuanaNumber: obj.MarijuanaNumber,
                    DrugManufacturedID: obj.DrugManufacturedID,
                    ClandistineLabsNumber: obj.ClandistineLabsNumber,
                    IsMaster: obj.IsMaster,
                    SolidPounds: obj.SolidPounds,
                    SolidOunces: obj.SolidOunces,
                    SolidGrams: obj.SolidGrams,
                    LiquidOunces: obj.LiquidOunces,
                    DoseUnits: obj.DoseUnits,
                    Items: obj.Items,
                }))
                // console.log(newArr)
                insetLocalDrugData(newArr)
            } else {
                AddDeleteUpadate(MstPage === "MST-Property-Dash" ? 'MainMasterPropertyDrug/Insert_MainMasterPropertyDrug' : 'PropertyDrug/Insert_PropertyDrug', value).then((res) => {
                    toastifySuccess(res.Message);
                    get_Data_Drug_Modal(masterPropertyID, propertyID);
                    setChangesStatus(false);
                    setDrugModal(false);
                    setDrugErrors({ ...drugErrors, 'SuspectedDrugTypeIDError': '' })
                })
            }
        } else {
            value.PropertyDrugID = localDrugCount
            setLocalDrugCount(localDrugCount + 1)
            setDrugLocalArr([...drugLocalArr, value]);
            sessionStorage.setItem('DrugLocalData', JSON.stringify([...drugLocalArr, value]));
            setDrugErrors({ ...drugErrors, 'SuspectedDrugTypeIDError': '' });
            setChangesStatus(false);
            setDrugModal(false);
        }
    }

    const insetLocalDrugData = async (DataArr) => {
        try {
            DataArr?.forEach((data) => {
                // console.log(data)
                const response = axios.post(MstPage === "MST-Property-Dash" ? 'MainMasterPropertyDrug/Insert_MainMasterPropertyDrug' : 'PropertyDrug/Insert_PropertyDrug', data).then((res) => {
                    // console.log(res)
                });
            });
            setDrugLocalArr([]);
        } catch (error) {
            console.log(error); setDrugLocalArr([]);
        }
    }

    const setDrugSessionData = () => {
        sessionStorage.setItem('DrugLocalData', JSON.stringify(drugLocalArr));
    }

    const update_DrugModal = () => {
        if (drugLocalArr?.length > 0) {

            const newArray = drugLocalArr?.filter(item => item.PropertyDrugID == propertyDrugID);

            const ModifyArr = newArray?.map(obj => ({
                'SuspectedDrugTypeID': value?.SuspectedDrugTypeID,
                'EstimatedDrugQty': value?.EstimatedDrugQty,
                'FractionDrugQty': value?.FractionDrugQty,
                'MeasurementTypeID': value?.MeasurementTypeID,
                'PropertyCategoryCode': value?.PropertyCategoryCode,
                'PropertySourceDrugTypeID': value?.PropertySourceDrugTypeID,
                'CreatedByUserFK': value?.CreatedByUserFK,
                'MarijuanaTypeID': value?.MarijuanaTypeID,
                'MarijuanaNumber': value?.MarijuanaNumber,
                'DrugManufacturedID': value?.DrugManufacturedID,
                'ClandistineLabsNumber': value?.ClandistineLabsNumber,
                'SolidPounds': value?.SolidPounds,
                'SolidOunces': value?.SolidOunces,
                'SolidGrams': value?.SolidGrams,
                'LiquidOunces': value?.LiquidOunces,
                'DoseUnits': value?.DoseUnits,
                'Items': value?.Items,
                'PropertyDrugMeasure_Description': value.PropertyDrugMeasure_Description,
                'SuspectedDrugType_Description': value.SuspectedDrugType_Description,
                'IsMaster': obj.IsMaster,
                'PropertyDrugID': obj.PropertyDrugID,
            }))
            const LastArray = drugLocalArr.map(obj => obj.PropertyDrugID == propertyDrugID ? ModifyArr[0] : obj);
            setDrugLocalArr(LastArray);
            setChangesStatus(false)
            setDrugModal(false);
            setDrugErrors({ ...drugErrors, 'SuspectedDrugTypeIDError': '' })
        } else {
            AddDeleteUpadate('PropertyDrug/Update_PropertyDrug', value).then((res) => {
                toastifySuccess(res.Message)
                get_Data_Drug_Modal(masterPropertyID, propertyID,);
                setChangesStatus(false)
                setDrugModal(false);
                setDrugErrors({ ...drugErrors, 'SuspectedDrugTypeIDError': '' })
            })
        }
    }

    const Delete_Prpperty_Drug = () => {
        if (drugLocalArr?.length > 0) {
            const newArray = drugLocalArr?.filter(item => item.PropertyDrugID !== propertyDrugID);
            setDrugLocalArr([...newArray]);
        } else {
            const val = { 'PropertyDrugID': propertyDrugID, 'DeletedByUserFK': loginPinID }
            AddDeleteUpadate('PropertyDrug/Delete_PropertyDrug', val).then((res) => {
                if (res) {
                    toastifySuccess(res.Message);
                    get_Data_Drug_Modal(masterPropertyID, propertyID,);
                    setChangesStatus(false)
                    dispatch(get_PropertyMainModule_Data(mainIncidentID, MstPage === "MST-Property-Dash" ? true : false));
                } else console.log("Somthing Wrong");
            })
        }
    }

    const onDrugClose = () => {
        if (!changesStatus) {
            setDrugModal(false);
            setValue({
                ...value,
                'SuspectedDrugTypeID': null, 'EstimatedDrugQty': '', 'FractionDrugQty': '', 'MeasurementTypeID': '', 'ClandistineLabsNumber': '',
                'PropertySourceDrugTypeID': '', 'MarijuanaTypeID': null, 'MarijuanaNumber': '', 'DrugManufacturedID': null,
                'Items': '', 'DoseUnits': '', 'LiquidOunces': '', 'SolidGrams': '', 'SolidOunces': '', 'SolidPounds': '',
            })
            setDrugErrors({
                ...drugErrors,
                'SuspectedDrugTypeIDError': '', 'EstimatedDrugQtyError': '', 'FractionDrugQtyError': '', 'MeasurementTypeIDError': '',
                'solidPoundsError': '', 'solidOunceError': '', 'solidGramError': '', 'liquidOunceError': '', 'doseUnitsError': '', 'ItemsError': '',
            })
        }
    }

    const get_Name_MultiImage = (propertyId, masterPropertyId) => {
        const val = { 'PropertyID': propertyId, 'MasterPropertyID': masterPropertyId, 'IsMaster': MstPage === "MST-Property-Dash" ? true : false, }
        const val1 = { 'PropertyID': 0, 'MasterPropertyID': masterPropertyId, 'IsMaster': MstPage === "MST-Property-Dash" ? true : false, }
        fetchPostData('Property/GetData_PropertyPhoto', MstPage === "MST-Property-Dash" ? val1 : val)
            .then((res) => {
                if (res) {
                    setMultiImage(res);
                }
                else { setMultiImage([]); }
            })
    }

    //-------------------------Image---------------------------
    // to update image data
    const update_Property_MultiImage = () => {
        const val = { "ModifiedByUserFK": loginPinID, "AgencyID": loginAgencyID, "PictureTypeID": imgData?.PictureTypeID, "ImageViewID": imgData?.ImageViewID, "ImgDtTm": imgData?.ImgDtTm, "OfficerID": imgData?.OfficerID, "Comments": imgData?.Comments, "DocumentID": imgData?.DocumentID }
        AddDelete_Img('PropertyVehicle/Update_PropertyVehiclePhotoDetail', val)
            .then((res) => {
                if (res.success) {
                    toastifySuccess(res.Message);
                    get_Name_MultiImage(propertyID, masterPropertyID);
                }
                else {
                    toastifyError(res?.Message);
                }
            })
    }

    const upload_Image_File = (propID, propMID) => {
        const formdata = new FormData();
        const newData = [];
        for (let i = 0; i < uploadImgFiles.length; i++) {
            const { file, imgData } = uploadImgFiles[i];
            const val = {
                'PropertyID': propertyID ? propertyID : propID,
                'MasterPropertyID': masterPropertyID ? masterPropertyID : propMID,
                'CreatedByUserFK': loginPinID,
                'PictureTypeID': imgData?.PictureTypeID,
                'ImageViewID': imgData?.ImageViewID,
                'ImgDtTm': imgData?.ImgDtTm,
                'OfficerID': imgData?.OfficerID,
                'Comments': imgData?.Comments,
                'IsMaster': MstPage === "MST-Property-Dash" ? true : false,
            }
            const val1 = {
                'PropertyID': 0,
                'MasterPropertyID': masterPropertyID ? masterPropertyID : propMID,
                'CreatedByUserFK': loginPinID,
                'PictureTypeID': imgData?.PictureTypeID,
                'ImageViewID': imgData?.ImageViewID,
                'ImgDtTm': imgData?.ImgDtTm,
                'OfficerID': imgData?.OfficerID,
                'Comments': imgData?.Comments,
                'IsMaster': MstPage === "MST-Property-Dash" ? true : false,
            }
            const values = JSON.stringify(MstPage === "MST-Property-Dash" ? val1 : val);
            formdata.append("file", file);
            newData.push(values);
        }
        formdata.append("Data", JSON.stringify(newData));
        AddDelete_Img('Property/Insert_PropertyPhoto', formdata).then((res) => {
            if (res.success) {
                get_Name_MultiImage(propertyID ? propertyID : propID, masterPropertyID ? masterPropertyID : propMID);
                setuploadImgFiles('')
            }
        }).catch(err => console.log(err))
    }

    const delete_Image_File = (e) => {
        const value = { 'PhotoID': imageId, 'DeletedByUserFK': loginPinID }
        AddDelete_Img('Property/Delete_PropertyPhoto', value).then((data) => {
            if (data.success) {
                get_Name_MultiImage(propertyID, masterPropertyID);
                // GetSingleData(propertyID, masterPropertyID);
                setModalStatus(false);
                setImageId('');
            } else {
                toastifyError(data?.Message);
            }
        });
    }

    const ChangePhoneType = (e, name) => {
        if (e) {
            setChangesStatus(true);
            if (name === 'LossCodeID') {
                setLossCode(e.id);
                setValue({ ...value, [name]: e.value, ['Value']: '', }); setErrors(({ ...errors, ['ContactError']: 'true' }));
            } else {
                setValue({ ...value, [name]: e.value, });
            }
        } else if (name === 'LossCodeID') {
            setChangesStatus(true);
            setLossCode(''); setErrors(({ ...errors, ['ContactError']: 'true' }));
            setValue({ ...value, [name]: null, [' Value']: '', });
        }
    };

    const startRef = React.useRef();
    const startRef1 = React.useRef();
    const startRef2 = React.useRef();
    const startRef3 = React.useRef();
    const startRef4 = React.useRef();

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
            startRef2.current.setOpen(false);
            startRef3.current.setOpen(false);
            startRef4.current.setOpen(false);
        }
    };

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    const conditionalRowStyles = [
        {
            when: row => row.PropertyID === propertyID,
            style: {
                backgroundColor: '#001f3fbd',
                color: 'white',
                cursor: 'pointer',
            },
        },
    ];

    const colourStyles = {
        control: (styles) => ({
            ...styles, backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 32,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    }

    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 20,
            minHeight: 32,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    const GetSingleDataPassion = (nameID, masterNameID) => {
        const val = { 'NameID': nameID, 'MasterNameID': masterNameID }
        fetchPostData('MasterName/GetSingleData_MasterName', val).then((res) => {
            if (res) {
                setPossenSinglData(res);
            } else { setPossenSinglData([]); }
        })
    }

    const onMasterPropClose = () => {
        if (!changesStatus) {
            navigate('/dashboard-page');
        }
    }

    // const get_Property_Article_Search_Data = (SerialID, ModelID, Brand, LossCodeID, TopColorID, CategoryID, setSearchModalState) => {
    //   dispatch({ type: PropertySearch_Type, payload: 'Pro-Article' });
    //   const val = { 'SerialID': SerialID, 'ModelID': ModelID, 'Brand': Brand, 'LossCodeID': LossCodeID, 'TopColorID': TopColorID, 'CategoryID': CategoryID, }
    //   const isEmpty = hasValues(val)
    //   if (isEmpty) {
    //     fetchPostData('Property/Search_PropertyArticle', { 'SerialID': SerialID, 'ModelID': ModelID, 'Brand': Brand, 'LossCodeID': LossCodeID, 'TopColorID': TopColorID, 'CategoryID': CategoryID, }).then((res) => {
    //       if (res?.length > 0) {
    //         // setSearchModalState(true)
    //         // alert(searchModalState)
    //         dispatch({ type: Property_Search_Data, payload: res });
    //         dispatch({ type: PropertySearch_Modal_Status, payload: true });
    //       } else {
    //         // setSearchModalState(false)
    //         dispatch({ type: PropertySearch_Modal_Status, payload: false });
    //         dispatch({ type: Property_Search_Data, payload: [] });
    //         toastifyError("Data Not Available")
    //       }
    //     })
    //   } else {
    //     console.log("Search Empty")
    //   }
    // };

    function hasValues(obj) {
        for (let key in obj) {
            if (obj[key]) {
                return true;
            }
        }
        return false;
    }
    console.log("value", value)
    console.log("propertyLossCodeDrpData", propertyLossCodeDrpData)

    return (
        <>
            {openPropertyTabSectionModal ? (
                <>
                    <dialog
                        className="modal fade"
                        style={{ background: "rgba(0,0,0, 0.5)", zIndex: "200" }}
                        id="PropertyTabSectionModal"
                        tabIndex="-1"
                        aria-hidden="true"
                        data-backdrop="false"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-xl">
                            <div className="modal-content modal-content-cad">
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-12 p-0 pb-2">
                                            <div className="py-0 px-2 d-flex justify-content-between align-items-center">
                                                <p
                                                    className="p-0 m-0 font-weight-medium"
                                                    style={{
                                                        fontSize: 18,
                                                        fontWeight: 500,
                                                        letterSpacing: 0.5,
                                                    }}
                                                >
                                                    {'Property'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="m-1">
                                        <fieldset style={{ border: "1px solid gray" }}>
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-11 pt-1 p-0" >
                                                        <div className="row ">
                                                            <div className="col-3 col-md-3 col-lg-1 mt-2 px-0">
                                                                <label htmlFor="" className='new-label px-0'>Property No.</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-3 mt-1 text-field">
                                                                <input type="text" className='readonlyColor' value={propertyNumber ? propertyNumber : 'Auto Generated'} required readOnly />
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-1 mt-2 px-1">
                                                                <label htmlFor="" className='new-label'>Loss Code{errors.LossCodeIDError !== 'true' ? (
                                                                    <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.LossCodeIDError}</p>
                                                                ) : null}</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-3 mt-1">
                                                                <Select
                                                                    name='LossCodeID'
                                                                    value={propertyLossCodeDrpData?.filter((obj) => obj.value === value?.LossCodeID)}
                                                                    styles={colourStyles}
                                                                    options={propertyLossCodeDrpData}
                                                                    onChange={(e) => ChangePhoneType(e, 'LossCodeID')}
                                                                    isClearable
                                                                    placeholder="Select..."
                                                                />
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                <label htmlFor="" className='new-label'>Value{errors.ContactError !== 'true' ? (
                                                                    <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.ContactError}</p>
                                                                ) : null}</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input
                                                                    type="text"
                                                                    name="Value"
                                                                    id="Value"
                                                                    className={lossCode === 'STOL' || lossCode === 'BURN' || lossCode === 'RECD' ? 'requiredColor' : ''}
                                                                    maxLength={20}
                                                                    value={`$${value?.Value}`}
                                                                    onChange={HandleChanges}
                                                                    required
                                                                    autoComplete='off'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-3 col-md-3 col-lg-1 mt-2">
                                                                <span className='new-label '>
                                                                    Type{errors.PropertyTypeIDError !== 'true' ? (
                                                                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.PropertyTypeIDError}</p>
                                                                    ) : null}
                                                                </span>

                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-3 mt-1">
                                                                <Select
                                                                    styles={colourStyles}
                                                                    name='PropertyTypeID'
                                                                    value={propertyTypeData?.filter((obj) => obj.value === value?.PropertyTypeID)}
                                                                    options={propertyTypeData}
                                                                    onChange={(e) => ChangeDropDown(e, 'PropertyTypeID')}
                                                                    isClearable
                                                                    placeholder="Select..."
                                                                    isDisabled={propertyID || masterPropertyID ? true : false}
                                                                />
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-1 mt-2">
                                                                {/* <Link to={'/ListManagement?page=Property%20Description&call=/Prop-Home'} className='new-link'>
                  Category
                  {errors.CategoryIDError !== 'true' ? (
                    <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.CategoryIDError}</p>
                  ) : null}
                </Link> */}
                                                                <span data-toggle="modal" onClick={() => {
                                                                    setOpenPage('Property Description')
                                                                }} data-target="#ListModel" className='new-link'>
                                                                    Category
                                                                    {errors.CategoryIDError !== 'true' ? (
                                                                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.CategoryIDError}</p>
                                                                    ) : null}
                                                                </span>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-3 mt-1">
                                                                <Select
                                                                    name='CategoryID'
                                                                    id='CategoryID'
                                                                    styles={colourStyles}
                                                                    value={propertyCategoryData?.filter((obj) => obj.value === value?.CategoryID)}
                                                                    options={propertyCategoryData}
                                                                    onChange={(e) => ChangeDropDown(e, 'CategoryID')}
                                                                    isClearable
                                                                    placeholder="Select..."
                                                                />
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                                {/* <Link to={'/ListManagement?page=Property%20Classification&call=/Prop-Home'} className='new-link'>
                  Classification
                </Link> */}
                                                                <span data-toggle="modal" onClick={() => {
                                                                    setOpenPage('Property Classification')
                                                                }} data-target="#ListModel" className='new-link'>
                                                                    Classification
                                                                </span>
                                                            </div>
                                                            <div className="col-9 col-md-9 col-lg-2 mt-1">
                                                                <Select
                                                                    styles={customStylesWithOutColor}
                                                                    name='ClassificationID'
                                                                    value={propertyClassificationData?.filter((obj) => obj.value === value?.ClassificationID)}
                                                                    options={propertyClassificationData}
                                                                    onChange={(e) => ChangeDropDown(e, 'ClassificationID')}
                                                                    isClearable
                                                                    placeholder="Select..."
                                                                />
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-1 mt-2 px-0">
                                                                <label htmlFor="" className='new-label px-0' style={{ paddingLeft: '5px' }}> Possession&nbsp;Of</label>
                                                            </div>
                                                            <div className="col-8 col-md-8 col-lg-3 d-flex mt-1 ">
                                                                <div className="col-12 col-md-12 col-lg-9">
                                                                    {
                                                                        MstPage === "MST-Property-Dash" ?
                                                                            <Select
                                                                                name='PossessionOfID'
                                                                                styles={customStylesWithOutColor}
                                                                                value={mastersNameDrpData?.filter((obj) => obj.value === value?.PossessionOfID)}
                                                                                isClearable
                                                                                options={mastersNameDrpData}
                                                                                onChange={(e) => ChangeDropDown(e, 'PossessionOfID')}
                                                                                placeholder="Select..."
                                                                            />
                                                                            :
                                                                            <Select
                                                                                name='PossessionOfID'
                                                                                styles={customStylesWithOutColor}
                                                                                value={arresteeNameData?.filter((obj) => obj.value === value?.PossessionOfID)}
                                                                                isClearable
                                                                                options={arresteeNameData}
                                                                                onChange={(e) => ChangeDropDown(e, 'PossessionOfID')}
                                                                                placeholder="Select..."
                                                                            />
                                                                    }
                                                                </div>
                                                                <div className="" data-toggle="modal" data-target="#MasterModal"  >
                                                                    <button
                                                                        onClick={() => {
                                                                            if (possessionID) { GetSingleDataPassion(possessionID); } setNameModalStatus(true);
                                                                        }}
                                                                        className=" btn btn-sm bg-green text-white py-1"
                                                                    >
                                                                        <i className="fa fa-plus" >
                                                                        </i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-1 mt-2 ">
                                                                <label htmlFor="" className='new-label '>Primary&nbsp;Officer{errors.OfficerIDError !== 'true' ? (
                                                                    <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.OfficerIDError}</p>
                                                                ) : null}</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-3 mt-1">
                                                                <Select
                                                                    name='OfficerID'
                                                                    styles={customStylesWithOutColor}
                                                                    value={agencyOfficerDrpData?.filter((obj) => obj.value === value?.OfficerID)}
                                                                    isClearable
                                                                    options={agencyOfficerDrpData}
                                                                    onChange={(e) => ChangeDropDown(e, 'OfficerID')}
                                                                    placeholder="Select..."
                                                                />
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                <label htmlFor="" className='new-label'>Reported Date/Time</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2">
                                                                {
                                                                    MstPage === "MST-Property-Dash" ?
                                                                        <DatePicker
                                                                            id='ReportedDtTm'
                                                                            name='ReportedDtTm'
                                                                            ref={startRef}
                                                                            // open={false}
                                                                            // onKeyDown={onKeyDown}
                                                                            onKeyDown={(e) => {
                                                                                if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ')) {
                                                                                    e?.preventDefault();
                                                                                } else {
                                                                                    onKeyDown(e);
                                                                                }
                                                                            }}
                                                                            dateFormat="MM/dd/yyyy HH:mm"
                                                                            isClearable={false}
                                                                            onChange={(date) => {
                                                                                if (date >= new Date()) {
                                                                                    setValue({ ...value, ['ReportedDtTm']: new Date() ? getShowingDateText(new Date()) : null })
                                                                                } else if (date <= new Date(incReportedDate)) {
                                                                                    setValue({ ...value, ['ReportedDtTm']: incReportedDate ? getShowingDateText(incReportedDate) : null })
                                                                                } else {
                                                                                    setValue({ ...value, ['ReportedDtTm']: date ? getShowingDateText(date) : null })
                                                                                }
                                                                            }}
                                                                            selected={value?.ReportedDtTm && new Date(value?.ReportedDtTm)}
                                                                            autoComplete="Off"
                                                                            className='requiredColor'
                                                                            timeInputLabel
                                                                            placeholderText={'Select...'}
                                                                            showTimeSelect
                                                                            timeIntervals={1}
                                                                            timeCaption="Time"
                                                                            showYearDropdown
                                                                            showMonthDropdown
                                                                            dropdownMode="select"
                                                                            maxDate={new Date()}
                                                                            // filterTime={filterPassedTime}
                                                                            filterTime={(time) => filterPassedDateTime(time, value?.ReportedDtTm, incReportedDate)}
                                                                        />
                                                                        :
                                                                        <DatePicker
                                                                            id='ReportedDtTm'
                                                                            name='ReportedDtTm'
                                                                            // open={false}
                                                                            ref={startRef}
                                                                            // disabled={true}
                                                                            // onKeyDown={onKeyDown}
                                                                            onKeyDown={(e) => {
                                                                                if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ')) {
                                                                                    e?.preventDefault();
                                                                                } else {
                                                                                    onKeyDown(e);
                                                                                }
                                                                            }}
                                                                            dateFormat="MM/dd/yyyy HH:mm"
                                                                            isClearable={false}
                                                                            selected={value?.ReportedDtTm && new Date(value?.ReportedDtTm)}
                                                                            onChange={(date) => {
                                                                                if (date >= new Date()) {
                                                                                    setValue({ ...value, ['ReportedDtTm']: new Date() ? getShowingDateText(new Date()) : null })
                                                                                } else if (date <= new Date(incReportedDate)) {
                                                                                    setValue({ ...value, ['ReportedDtTm']: incReportedDate ? getShowingDateText(incReportedDate) : null })
                                                                                } else {
                                                                                    setValue({ ...value, ['ReportedDtTm']: date ? getShowingDateText(date) : null })
                                                                                }
                                                                            }}
                                                                            autoComplete="Off"
                                                                            className='requiredColor'
                                                                            timeInputLabel
                                                                            showTimeSelect
                                                                            timeIntervals={1}
                                                                            timeCaption="Time"
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                            minDate={new Date(incReportedDate)}
                                                                            maxDate={new Date()}
                                                                            showDisabledMonthNavigation
                                                                            // filterTime={filterPassedTime}
                                                                            filterTime={(time) => filterPassedDateTime(time, value?.DispositionDate, incReportedDate)}
                                                                        />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-6 col-lg-12 ml-5 pl-5 ">
                                                            <div className="form-check px-2">
                                                                <input className="form-check-input" name='IsEvidence' value={value?.IsEvidence} onChange={HandleChanges} checked={value?.IsEvidence} type="checkbox" id="flexCheckDefault" />
                                                                <label className="form-check-label" for="flexCheckDefault">
                                                                    Evidence
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {/* <div className='row bt'>
              <div className="col-3 col-md-3 col-lg-1 mt-2">
                <label htmlFor="" className='new-label text-bold'>Alerts:</label>
              </div>
              <div className="col-3 col-md-3 col-lg-10 " style={{ marginTop: '6px' }}>
                <span data-toggle="modal" data-target="#MasterAlert" className='alert-link'>
                  Add Alert
                </span>
              </div>
            </div> */}
                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-1 pt-1 " >
                                                        <div className="img-box" >
                                                            <Carousel autoPlay={true} className="carousel-style" showArrows={true} showThumbs={false} showStatus={false} >
                                                                {
                                                                    multiImage.length > 0 ?
                                                                        multiImage?.map((item) => (
                                                                            <div key={item.PropertyTypeID} onClick={() => { setImageModalStatus(true) }} data-toggle="modal" data-target="#ImageModel">
                                                                                <img src={`data:image/png;base64,${item.Photo}`} style={{ height: '105px' }} />
                                                                            </div>
                                                                        ))
                                                                        :
                                                                        <div onClick={() => { setImageModalStatus(true) }} data-toggle="modal" data-target="#ImageModel">
                                                                            <img src={defualtImage} style={{ height: '105px' }} />
                                                                        </div>
                                                                }
                                                            </Carousel>
                                                        </div>
                                                    </div>

                                                    {/* Alert Master */}
                                                    <AlertTable availableAlert={availableAlert} masterPropertyID={masterPropertyID} ProSta={ProSta} />
                                                    {/* ARTICLE   */}
                                                    {
                                                        value.PropertyCategoryCode === 'A' ?
                                                            <div className="col-12 col-md-12 col-lg-12 mt-1 p-0" >
                                                                <fieldset>
                                                                    <legend>Article</legend>
                                                                    <div className="row">
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                            <label htmlFor="" className='new-label'>Serial Id</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                            <input type="text" name='SerialID' id='SerialID' autoComplete='off' value={value?.SerialID} onChange={HandleChanges} className='' required />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                            <label htmlFor="" className='new-label'>Model Id</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                            <input type="text" name='ModelID' id='ModelID' value={value?.ModelID} onChange={HandleChanges} className='' required autoComplete='off' />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                            {/* <Link to={' /ListManagement?page=Color&call=/Prop-Home'} className='new-link'>
                        Top Color
                      </Link> */}
                                                                            <span data-toggle="modal" onClick={() => {
                                                                                setOpenPage('Color')
                                                                            }} data-target="#ListModel" className='new-link'>
                                                                                Top Color
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2  mt-1">
                                                                            <Select
                                                                                name='TopColorID'
                                                                                value={topColorDrpData?.filter((obj) => obj.value === value?.TopColorID)}
                                                                                options={topColorDrpData}
                                                                                styles={customStylesWithOutColor}
                                                                                onChange={(e) => ChangeDropDown(e, 'TopColorID')}
                                                                                isClearable
                                                                                placeholder="Select..."
                                                                            />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1  px-0 mt-2 ">
                                                                            <span data-toggle="modal" onClick={() => {
                                                                                setOpenPage('Color')
                                                                            }} data-target="#ListModel" className='new-link'>
                                                                                Bottom Color
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                            <Select
                                                                                name='BottomColorID'
                                                                                value={bottomColorDrpData?.filter((obj) => obj.value === value?.BottomColorID)}
                                                                                options={bottomColorDrpData}
                                                                                styles={customStylesWithOutColor}
                                                                                onChange={(e) => ChangeDropDown(e, 'BottomColorID')}
                                                                                isClearable
                                                                                placeholder="Select..."
                                                                            />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2 ">
                                                                            <label htmlFor="" className='new-label'>OAN</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2  mt-1 text-field">
                                                                            <input type="text" name='OAN' id='OAN' maxLength={20} value={value?.OAN} onChange={HandleChanges} className='' required autoComplete='off' />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2 ">
                                                                            <label htmlFor="" className='new-label'>Quantity</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2  mt-1 text-field">
                                                                            <input type="text" name='Quantity' id='Quantity' maxLength={20} value={value?.Quantity} onChange={HandleChanges} className='' required autoComplete='off' />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2 ">
                                                                            <label htmlFor="" className='new-label'>Brand</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2  mt-1 text-field">
                                                                            <input type="text" name='Brand' id='Brand' maxLength={20} value={value?.Brand} onChange={HandleChanges} className='' required autoComplete='off' />
                                                                        </div>
                                                                        <div className="col-12  col-md-12 col-lg-3 mt-md-1 " >
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-sm btn-success"
                                                                                data-toggle="modal"
                                                                                data-target="#PropertyModal"
                                                                                onClick={() => {
                                                                                    dispatch(get_Property_Article_Search_Data(value?.SerialID, value?.ModelID, value?.Brand, value?.LossCodeID, value?.TopColorID, value?.CategoryID, setSearchModalState))
                                                                                    setSearchModalState(true)
                                                                                    // get_Property_Article_Search_Data(value?.SerialID, value?.ModelID, value?.Brand, value?.LossCodeID, value?.TopColorID, value?.CategoryID, setSearchModalState)
                                                                                }}
                                                                            >
                                                                                Search
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </fieldset>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                    {/* Others */}
                                                    {
                                                        value.PropertyCategoryCode === 'O' ?
                                                            <div className="col-12 col-md-12 col-lg-12 pt-2 p-0" >
                                                                <fieldset>
                                                                    <legend>Other</legend>
                                                                    <div className="row">
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                            <label htmlFor="" className='new-label'>Brand</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                            <input type="text" name='Brand' id='Brand' value={value?.Brand} onChange={HandleChanges} className='' required autoComplete='off' />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                            <label htmlFor="" className='new-label'>Serial Id</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                            <input type="text" name='SerialID' id='SerialID' value={value?.SerialID} onChange={HandleChanges} className='' required autoComplete='off' />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                            <span data-toggle="modal" onClick={() => {
                                                                                setOpenPage('Color')
                                                                            }} data-target="#ListModel" className='new-link'>
                                                                                Top Color
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2  mt-1">

                                                                            <Select
                                                                                name='TopColorID'
                                                                                value={topColorDrpData?.filter((obj) => obj.value === value?.TopColorID)}
                                                                                options={topColorDrpData}
                                                                                styles={customStylesWithOutColor}
                                                                                onChange={(e) => ChangeDropDown(e, 'TopColorID')}
                                                                                isClearable
                                                                                placeholder="Select..."
                                                                            />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1 px-0  mt-2">
                                                                            <span data-toggle="modal" onClick={() => {
                                                                                setOpenPage('Color')
                                                                            }} data-target="#ListModel" className='new-link'>
                                                                                Bottom Color
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2  mt-1">
                                                                            <Select
                                                                                name='BottomColorID'
                                                                                value={bottomColorDrpData?.filter((obj) => obj.value === value?.BottomColorID)}
                                                                                options={bottomColorDrpData}
                                                                                styles={customStylesWithOutColor}
                                                                                onChange={(e) => ChangeDropDown(e, 'BottomColorID')}
                                                                                isClearable
                                                                                placeholder="Select..."
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                            <label htmlFor="" className='new-label'>Model Id</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2 text-field  mt-1">
                                                                            <input type="text" name='ModelID' id='ModelID' value={value?.ModelID} onChange={HandleChanges} className='' required autoComplete='off' />
                                                                        </div>

                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                            <label htmlFor="" className='new-label'>Quantity</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                            <input type="text" name='Quantity' id='Quantity' value={value?.Quantity} onChange={HandleChanges} className='' required autoComplete='off' />
                                                                        </div>

                                                                        <div className="col-3 col-md-3 col-lg-1 px-0 mt-2">
                                                                            <label htmlFor="" className='new-label '>Quantity Unit</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2  mt-1">
                                                                            <Select
                                                                                styles={customStylesWithOutColor}
                                                                                name='QuantityUnitID'
                                                                                value={measureTypeDrpData?.filter((obj) => obj.value === value?.QuantityUnitID)}
                                                                                options={measureTypeDrpData}
                                                                                onChange={(e) => ChangeDropDown(e, 'QuantityUnitID')}
                                                                                isClearable
                                                                                placeholder="Select..."
                                                                            />
                                                                        </div>
                                                                        <div className="col-12  col-md-12 col-lg-3   mt-md-1" >
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-sm btn-success"
                                                                                data-toggle="modal"
                                                                                data-target="#PropertyModal"
                                                                                onClick={() => {
                                                                                    dispatch(get_Property_Other_Search_Data(value?.SerialID, value?.ModelID, value?.Brand, value?.LossCodeID, value?.CategoryID, setSearchModalState));
                                                                                    setSearchModalState(true)
                                                                                }}
                                                                            >
                                                                                Search
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </fieldset>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                    {/* Security */}
                                                    {
                                                        value.PropertyCategoryCode === 'S' ?
                                                            <div className="col-12 col-md-12 col-lg-12 pt-2 p-0" >
                                                                <fieldset>
                                                                    <legend>Security</legend>
                                                                    <div className="row">
                                                                        <div className="col-3 col-md-3 col-lg-2  mt-2">
                                                                            <label htmlFor="" className='new-label'>Denomination</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                            <input type="text" name='Denomination' maxLength={16} id='Denomination' value={value?.Denomination} onChange={HandleChanges} className='' required autoComplete='off' />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2  mt-2">
                                                                            <label htmlFor="" className='new-label'>Issuing Agency</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                            <input type="text" name='IssuingAgency' id='IssuingAgency' value={value?.IssuingAgency} onChange={HandleChanges} className='' required autoComplete='off' />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2  mt-2">
                                                                            <label htmlFor="" className='new-label'>Measure Type</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2  mt-1">
                                                                            <Select
                                                                                name='MeasureTypeID'
                                                                                value={measureTypeDrpData?.filter((obj) => obj.value === value?.MeasureTypeID)}
                                                                                styles={value?.Denomination ? colourStyles : customStylesWithOutColor}
                                                                                options={measureTypeDrpData}
                                                                                onChange={(e) => ChangeDropDown(e, 'MeasureTypeID')}
                                                                                isClearable
                                                                                placeholder="Select..."
                                                                            // isDisabled={value?.Denomination ? false : true}
                                                                            />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2  mt-2">
                                                                            <label htmlFor="" className='new-label'>Security Date</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2 ">
                                                                            <DatePicker
                                                                                id='SecurityDtTm'
                                                                                name='SecurityDtTm'
                                                                                ref={startRef1}
                                                                                // onKeyDown={onKeyDown}
                                                                                onKeyDown={(e) => {
                                                                                    if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ')) {
                                                                                        e?.preventDefault();
                                                                                    } else {
                                                                                        onKeyDown(e);
                                                                                    }
                                                                                }}
                                                                                onChange={(date) => { setSecurityDate(date); setValue({ ...value, ['SecurityDtTm']: date ? getShowingWithOutTime(date) : null }) }}
                                                                                className=''
                                                                                dateFormat="MM/dd/yyyy"
                                                                                isClearable={value?.SecurityDtTm ? true : false}
                                                                                selected={securityDate}
                                                                                placeholderText={value?.SecurityDtTm ? value.SecurityDtTm : 'Select...'}
                                                                                timeIntervals={1}
                                                                                autoComplete="Off"
                                                                                showYearDropdown
                                                                                showMonthDropdown
                                                                                dropdownMode="select"
                                                                                filterTime={filterPassedTime}
                                                                            />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2  mt-2">
                                                                            <label htmlFor="" className='new-label'>Serial Id</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2 text-field  mt-1">
                                                                            <input type="text" name='SerialID' id='SerialID' value={value?.SerialID} onChange={HandleChanges} className='' required autoComplete='off' />
                                                                        </div>
                                                                        <div className="col-12  col-md-12 col-lg-4   mt-md-1" >
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-sm btn-success"
                                                                                data-toggle="modal"
                                                                                data-target="#PropertyModal"
                                                                                onClick={() => {
                                                                                    dispatch(get_Property_Security_Search_Data(value?.SerialID, value?.IssuingAgency, value?.SecurityDtTm, value?.CategoryID, value?.Denomination, setSearchModalState));
                                                                                    setSearchModalState(true)
                                                                                }}
                                                                            >
                                                                                Search
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </fieldset>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                    {/* Weapon */}
                                                    {
                                                        value.PropertyCategoryCode === 'G' ?
                                                            <div className="col-12 col-md-12 col-lg-12 pt-2 p-0" >
                                                                <fieldset>
                                                                    <legend>Weapon</legend>
                                                                    <div className="row">
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                            <label htmlFor="" className='new-label'>Style</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2 text-field  mt-1">
                                                                            <input type="text" name='Style' id='Style' value={value?.Style} onChange={HandleChanges} className='' required autoComplete='off' />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                            <label htmlFor="" className='new-label'>Finish</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2 text-field  mt-1">
                                                                            <input type="text" name='Finish' id='Finish' value={value?.Finish} onChange={HandleChanges} className='' required autoComplete='off' />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                            <label htmlFor="" className='new-label'>Caliber</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2 text-field  mt-1">
                                                                            <input type="text" name='Caliber' maxLength={10} id='Caliber' value={value?.Caliber} onChange={HandleChanges} className='' required autoComplete='off' />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                            <label htmlFor="" className='new-label'>Handle</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2 text-field  mt-1">
                                                                            <input type="text" name='Handle' id='Handle' value={value?.Handle} onChange={HandleChanges} className='' required autoComplete='off' />
                                                                        </div>

                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                            <label htmlFor="" className='new-label'>Serial Id</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2 text-field  mt-1">
                                                                            <input type="text" name='SerialID' id='SerialID' value={value?.SerialID} onChange={HandleChanges} className='' required autoComplete='off' />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                            <label htmlFor="" className='new-label'>Make</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2   mt-1">
                                                                            <Select
                                                                                name='MakeID'
                                                                                value={weaponMakeDrpData?.filter((obj) => obj.value === value?.MakeID)}
                                                                                styles={customStylesWithOutColor}
                                                                                options={weaponMakeDrpData}
                                                                                onChange={(e) => ChangeDropDown(e, 'MakeID')}
                                                                                isClearable
                                                                                placeholder="Select..."
                                                                            />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                            <label htmlFor="" className='new-label'>Model Id</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2   mt-1">
                                                                            <Select
                                                                                name='WeaponModelID'
                                                                                styles={customStylesWithOutColor}
                                                                                value={weaponModelDrpData?.filter((obj) => obj.value === value?.WeaponModelID)}
                                                                                isClearable
                                                                                options={weaponModelDrpData}
                                                                                onChange={(e) => ChangeDropDown(e, 'WeaponModelID')}
                                                                                placeholder="Select..."
                                                                            />
                                                                        </div>
                                                                        <div className="col-3 col-md-4 col-lg-3 mt-2">
                                                                            <div className="form-check ">
                                                                                <input className="form-check-input" type="checkbox" name='auto' id="flexCheckDefault" checked={value?.IsAuto} />
                                                                                <label className="form-check-label" name='IsAuto' id='IsAuto' value={value?.IsAuto} onChange={HandleChanges} htmlFor="flexCheckDefault">
                                                                                    Auto
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                            <label htmlFor="" className='new-label'>Manu.&nbsp;Year</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2 ">
                                                                            <DatePicker
                                                                                name='ManufactureYear'
                                                                                id='ManufactureYear'
                                                                                selected={weaponfactureDate}
                                                                                onChange={(date) => { setWeaponfactureDate(date); setValue({ ...value, ['ManufactureYear']: date ? getYearWithOutDateTime(date) : null }) }}
                                                                                showYearPicker
                                                                                dateFormat="yyyy"
                                                                                yearItemNumber={9}
                                                                                ref={startRef4}
                                                                                onKeyDown={onKeyDown}
                                                                                autoComplete="off"
                                                                                showYearDropdown
                                                                                showMonthDropdown
                                                                                dropdownMode="select"
                                                                                maxDate={new Date()}
                                                                            />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2 px-0">
                                                                            <label htmlFor="" className='new-label px-0'>Barrel&nbsp;Length</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2 text-field  mt-1">
                                                                            <input type="text" name='BarrelLength' value={value?.BarrelLength} id='BarrelLength' maxLength={10} onChange={HandleChanges} className='' required autoComplete='off' />
                                                                        </div>
                                                                        <div className="col-12  col-md-12 col-lg-6   mt-md-1" >
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-sm btn-success"
                                                                                data-toggle="modal"
                                                                                data-target="#PropertyModal"
                                                                                onClick={() => {
                                                                                    dispatch(get_Property_Weapon_Search_Data(value?.SerialID, value?.MakeID, value?.LossCodeID, value?.CategoryID, value?.Caliber, setSearchModalState));
                                                                                    setSearchModalState(true);
                                                                                }}
                                                                            >
                                                                                Search
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </fieldset>
                                                            </div>
                                                            :
                                                            <>
                                                            </>
                                                    }
                                                    {/* Boat */}
                                                    {
                                                        value.PropertyCategoryCode === 'B' ?
                                                            <div className="col-12 col-md-12 col-lg-12 p-0" >
                                                                <fieldset>
                                                                    <legend>Boat</legend>
                                                                    <div className="row">
                                                                        <div className="col-3 col-md-3 col-lg-1   mt-2">
                                                                            <label htmlFor="" className='new-label'>Manu. Year</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1 ">
                                                                            <DatePicker
                                                                                name='ManufactureYear'
                                                                                id='ManufactureYear'
                                                                                selected={manufactureDate}
                                                                                onChange={(date) => { setManufactureDate(date); setValue({ ...value, ['ManufactureYear']: date ? getYearWithOutDateTime(date) : null }) }}
                                                                                showYearPicker
                                                                                dateFormat="yyyy"
                                                                                yearItemNumber={9}
                                                                                ref={startRef2}
                                                                                onKeyDown={onKeyDown}
                                                                                autoComplete="off"
                                                                                showYearDropdown
                                                                                showMonthDropdown
                                                                                dropdownMode="select"
                                                                                maxDate={new Date()}
                                                                            />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                            <label htmlFor="" className='new-label'>Length</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1 text-field mt-1 ">
                                                                            <input type="text" name='Length' id='Length' maxLength={9} value={value?.Length} onChange={HandleChanges} className='' required autoComplete='off' />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                            <label htmlFor="" className='new-label'>HIN  {errors.HINError !== 'true' ? (
                                                                                <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.HINError}</p>
                                                                            ) : null}</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1 text-field  mt-1">
                                                                            <input type="text" name='HIN' value={value?.HIN} maxLength={21} onChange={HandleChanges} className='requiredColor' required autoComplete='off' />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                            <label htmlFor="" className='new-label'>Reg. Expiry</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2">
                                                                            <DatePicker
                                                                                id='RegistrationExpiryDtTm'
                                                                                name='RegistrationExpiryDtTm'
                                                                                ref={startRef}
                                                                                // open={false}
                                                                                isClearable
                                                                                onKeyDown={onKeyDown}
                                                                                dateFormat="MM/yyyy"
                                                                                selected={value?.RegistrationExpiryDtTm && new Date(value?.RegistrationExpiryDtTm)}
                                                                                onChange={(date) => { setValue({ ...value, ['RegistrationExpiryDtTm']: date ? date : null }) }}
                                                                                showMonthYearPicker
                                                                                autoComplete="Off"
                                                                                className=''
                                                                                placeholderText={'Select...'}
                                                                            />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                            {/* <Link to={'/ListManagement?page=Boat%20VOD&call=/Prop-Home'} className='new-link'>
                        VOD
                      </Link> */}
                                                                            <span data-toggle="modal" onClick={() => {
                                                                                setOpenPage('Boat VOD')
                                                                            }} data-target="#ListModel" className='new-link'>
                                                                                VOD
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2  mt-1">
                                                                            <Select
                                                                                name='VODID'
                                                                                value={vodDrpData?.filter((obj) => obj.value === value?.VODID)}
                                                                                styles={customStylesWithOutColor}
                                                                                options={vodDrpData}
                                                                                onChange={(e) => ChangeDropDown(e, 'VODID')}
                                                                                isClearable
                                                                                placeholder="Select..."
                                                                            />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2 ">
                                                                            <label htmlFor="" className='new-label'>Reg. State  {errors.RegStateError !== 'true' ? (
                                                                                <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.RegStateError}</p>
                                                                            ) : null}</label>

                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2  mt-1 ">
                                                                            <Select
                                                                                name='RegistrationStateID'
                                                                                styles={colourStyles}
                                                                                value={stateDrpData?.filter((obj) => obj.value === value?.RegistrationStateID)}
                                                                                options={stateDrpData}
                                                                                onChange={(e) => ChangeDropDown(e, 'RegistrationStateID')}
                                                                                isClearable
                                                                                placeholder="Select..."
                                                                            />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                            <label htmlFor="" className='new-label'>Reg. No  {errors.RegNumError !== 'true' ? (
                                                                                <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.RegNumError}</p>
                                                                            ) : null}</label>

                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                            <input type="text" name='RegistrationNumber' id='RegistrationNumber' value={value?.RegistrationNumber} maxLength={10} onChange={HandleChanges} className='requiredColor' required autoComplete='off' />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1 mt-2">
                                                                            {/* <Link to={'/ListManagement?page=Property%20Boat%20OH%20Material&call=/Prop-Home'} className='new-link'>
                        Material
                      </Link> */}
                                                                            <span data-toggle="modal" onClick={() => {
                                                                                setOpenPage('Property Boat OH Material')
                                                                            }} data-target="#ListModel" className='new-link'>
                                                                                Material
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2  mt-1">
                                                                            <Select
                                                                                name='MaterialID'
                                                                                value={materialDrpData?.filter((obj) => obj.value === value?.MaterialID)}
                                                                                options={materialDrpData}
                                                                                styles={customStylesWithOutColor}
                                                                                onChange={(e) => ChangeDropDown(e, 'MaterialID')}
                                                                                isClearable
                                                                                placeholder="Select..."
                                                                            />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                            {/* <Link to={'/ListManagement?page=Property%20Boat%20Make&call=/Prop-Home'} className='new-link'>
                        Make
                      </Link> */}
                                                                            <span data-toggle="modal" onClick={() => {
                                                                                setOpenPage('Property Boat Make')
                                                                            }} data-target="#ListModel" className='new-link'>
                                                                                Make
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2  mt-1">
                                                                            <Select
                                                                                name='MakeID'
                                                                                value={makeDrpData?.filter((obj) => obj.value === value?.MakeID)}
                                                                                styles={customStylesWithOutColor}
                                                                                options={makeDrpData}
                                                                                onChange={(e) => ChangeDropDown(e, 'MakeID')}
                                                                                isClearable
                                                                                placeholder="Select..."
                                                                            />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                            {/* <Link to={'/ListManagement?page=Property%20Boat%20Model&call=/Prop-Home'} className='new-link'>
                        Model Id
                      </Link> */}
                                                                            <span data-toggle="modal" onClick={() => {
                                                                                setOpenPage('Property Boat Model')
                                                                            }} data-target="#ListModel" className='new-link'>
                                                                                Model Id
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2  mt-1">
                                                                            <Select
                                                                                name='ModelID'
                                                                                value={boatModelDrpData?.filter((obj) => obj.value === value?.ModelID)}
                                                                                styles={customStylesWithOutColor}
                                                                                options={boatModelDrpData}
                                                                                onChange={(e) => ChangeDropDown(e, 'ModelID')}
                                                                                isClearable
                                                                                placeholder="Select..."
                                                                            />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                            <label htmlFor="" className='new-label'>Top Color</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2  mt-1">
                                                                            <Select
                                                                                name='TopColorID'
                                                                                value={topColorDrpData?.filter((obj) => obj.value === value?.TopColorID)}
                                                                                options={topColorDrpData}
                                                                                styles={customStylesWithOutColor}
                                                                                onChange={(e) => ChangeDropDown(e, 'TopColorID')}
                                                                                isClearable
                                                                                placeholder="Select..."
                                                                                menuPlacement='top'
                                                                            />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1 px-0 mt-2">
                                                                            <label htmlFor="" className='new-label'>Bottom Color</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2  mt-1">
                                                                            <Select
                                                                                name='BottomColorID'
                                                                                value={bottomColorDrpData?.filter((obj) => obj.value === value?.BottomColorID)}
                                                                                options={bottomColorDrpData}
                                                                                styles={customStylesWithOutColor}
                                                                                onChange={(e) => ChangeDropDown(e, 'BottomColorID')}
                                                                                isClearable
                                                                                placeholder="Select..."
                                                                                menuPlacement='top'
                                                                            />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                            <span data-toggle="modal" onClick={() => {
                                                                                setOpenPage('Property Boat Propulsion')
                                                                            }} data-target="#ListModel" className='new-link'>
                                                                                Propulsion
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-2  mt-1">
                                                                            <Select
                                                                                name='PropulusionID'
                                                                                value={propulusionDrpData?.filter((obj) => obj.value === value?.PropulusionID)}
                                                                                styles={customStylesWithOutColor}
                                                                                options={propulusionDrpData}
                                                                                onChange={(e) => ChangeDropDown(e, 'PropulusionID')}
                                                                                isClearable
                                                                                placeholder="Select..."
                                                                                menuPlacement='top'
                                                                            />
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                            <label htmlFor="" className='new-label'>Comments</label>
                                                                        </div>
                                                                        <div className="col-3 col-md-3 col-lg-6  mt-1">
                                                                            <textarea name='Comments' id="Comments" value={value?.Comments} onChange={HandleChanges} cols="30" rows='1' className="form-control" >
                                                                            </textarea>
                                                                        </div>
                                                                        <div className="col-12  col-md-12 col-lg-1 text-right mt-md-1 " >
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-sm btn-success "
                                                                                data-toggle="modal"
                                                                                data-target="#PropertyModal"
                                                                                onClick={() => {
                                                                                    dispatch(get_Property_Boat_Search_Data(value?.RegistrationStateID, value?.RegistrationNumber, value?.HIN, value?.LossCodeID, value?.ManufactureYear, value?.CategoryID, value?.TopColorID, value?.MakeID, value?.PropulusionID, setSearchModalState));
                                                                                    setSearchModalState(true)
                                                                                }}
                                                                            >
                                                                                Search
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </fieldset>
                                                            </div>
                                                            :
                                                            <>
                                                            </>
                                                    }
                                                    {/* drug */}
                                                    {
                                                        value.PropertyCategoryCode === 'D' ?
                                                            <div className="col-12 col-md-12 pt-2 p-0" >
                                                                <div className=" ">
                                                                    <fieldset>
                                                                        <legend>Drug
                                                                            <div style={{ float: 'right' }}>
                                                                                {/* <button to="" className="btn btn-sm bg-green text-white px-1 py-0" onClick={setStatusFalse} data-toggle="modal" data-target="#DrugModal">
                          <i className="fa fa-plus"></i>
                        </button> */}
                                                                                {/* {
                          propertyID || masterPropertyID ?
                            <button to="" className="btn btn-sm bg-green text-white px-1 py-0" onClick={setStatusFalse} data-toggle="modal" data-target="#DrugModal">
                              <i className="fa fa-plus"></i>
                            </button>
                            :
                            <>
                            </>
                        } */}
                                                                            </div>
                                                                        </legend>
                                                                    </fieldset>
                                                                </div>
                                                                <div className="row ">
                                                                    <div className="col-12">
                                                                        <DataTable
                                                                            fixedHeader
                                                                            persistTableHead={true}
                                                                            customStyles={tableCustomStyles}
                                                                            dense
                                                                            columns={columns}
                                                                            data={drugData?.length > 0 ? drugData : drugLocalArr}
                                                                            pagination
                                                                            paginationPerPage={'5'}
                                                                            paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                                                            highlightOnHover
                                                                            noDataComponent={"There are no data to display"}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <>
                                                            </>
                                                    }
                                                    <div className="col-12 text-right p-0 mb-1" >
                                                        <button type="button" className="btn btn-sm btn-success mx-1" onClick={newProperty} data-dismiss="modal">New</button>
                                                        {
                                                            (propertyID || masterPropertyID) && (ProSta === 'true' || ProSta === true) ?
                                                                effectiveScreenPermission ?
                                                                    effectiveScreenPermission[0]?.Changeok ?
                                                                        <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }}>Update</button>
                                                                        :
                                                                        <>
                                                                        </>
                                                                    :
                                                                    <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }}>Update</button>
                                                                :
                                                                effectiveScreenPermission ?
                                                                    effectiveScreenPermission[0]?.AddOK ?
                                                                        <button type="button" className="btn btn-sm btn-success mr-1" onDoubleClick={''} onClick={(e) => { check_Validation_Error(); }}>Save</button>
                                                                        :
                                                                        <>
                                                                        </>
                                                                    :
                                                                    <button type="button" className="btn btn-sm btn-success mr-1" onDoubleClick={''} onClick={(e) => { check_Validation_Error(); }}>Save</button>
                                                        }
                                                        {
                                                            MstPage === "MST-Property-Dash" &&
                                                            <button type="button" className="btn btn-sm btn-success mx-1" onClick={onMasterPropClose} data-dismiss="modal">Close</button>
                                                        }
                                                        {/* <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }}>{propertyID || masterPropertyID ? 'Update' : 'Save'}</button> */}
                                                    </div>
                                                </div >
                                            </div >
                                            {
                                                drugModal &&
                                                <div className="modal fade" style={{ background: 'rgba(0,0,0, 0.5)' }} id='DrugModal' tabIndex='-1' aria-hidden='true' data-backdrop='false'>
                                                    <div className="modal-dialog modal-dialog-centered modal-xl">
                                                        <div className="modal-content">
                                                            <button type="button" className="border-0" aria-label="Close" data-dismiss="modal" style={{ alignSelf: "end" }}><b>X</b>
                                                            </button>
                                                            <div className="modal-body">
                                                                <div className="m-1 mt-3 bb">
                                                                    <fieldset >
                                                                        <legend >Drugs</legend>
                                                                        <div className="col-12">
                                                                            <div className="row mt-1">
                                                                                <div className="col-3 col-md-3  col-lg-2 mt-2">
                                                                                    <label className='new-label'>Suspected&nbsp;Drug&nbsp;Type</label>
                                                                                    {drugErrors.SuspectedDrugTypeIDError !== 'true' ? (
                                                                                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{drugErrors.SuspectedDrugTypeIDError}</p>
                                                                                    ) : null}
                                                                                </div>
                                                                                <div className="col-3 col-md-3  col-lg-3 mt-1">
                                                                                    <Select
                                                                                        name='SuspectedDrugTypeID'
                                                                                        styles={colourStyles}
                                                                                        value={suspectedDrugDrpData?.filter((obj) => obj.value === value?.SuspectedDrugTypeID)}
                                                                                        isClearable
                                                                                        options={suspectedDrugDrpData}
                                                                                        onChange={(e) => ChangeDropDown(e, 'SuspectedDrugTypeID')}
                                                                                        placeholder="Select..."
                                                                                    />
                                                                                </div>
                                                                                <div className="col-3 col-md-3  col-lg-3 mt-2">
                                                                                    <label className='new-label'>Property Source Drug Type</label>
                                                                                </div>
                                                                                <div className="col-3 col-md-3  col-lg-4 mt-1">
                                                                                    <Select
                                                                                        name='PropertySourceDrugTypeID'
                                                                                        styles={customStylesWithOutColor}
                                                                                        value={propSourceDrugDrpData?.filter((obj) => obj.value === value?.PropertySourceDrugTypeID)}
                                                                                        options={propSourceDrugDrpData}
                                                                                        onChange={(e) => ChangeDropDown(e, 'PropertySourceDrugTypeID')}
                                                                                        placeholder="Select..."
                                                                                        isClearable
                                                                                        isDisabled={drugTypecode !== 'E' ? false : true}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-3 col-md-3  col-lg-2 mt-2">
                                                                                    <label className='new-label'>Estimated&nbsp;Drug&nbsp;Qty  {drugErrors.EstimatedDrugQtyError !== 'true' ? (
                                                                                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{drugErrors.EstimatedDrugQtyError}</p>
                                                                                    ) : null}</label>
                                                                                </div>
                                                                                <div className="col-3 col-md-3 col-lg-2 mt-1 text-field">
                                                                                    <input type="text" maxLength={9} name='EstimatedDrugQty' id='EstimatedDrugQty' value={value?.EstimatedDrugQty} onChange={HandleChanges} className='requiredColor' required autoComplete='off' />
                                                                                </div>
                                                                                <div className="col-3 col-md-3  col-lg-2 mt-2">
                                                                                    <label className='new-label'>Fraction Drug Qty {drugErrors.FractionDrugQtyError !== 'true' ? (
                                                                                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{drugErrors.FractionDrugQtyError}</p>
                                                                                    ) : null}</label>
                                                                                </div>
                                                                                <div className="col-3 col-md-3 col-lg-2 mt-1 text-field">
                                                                                    <input type="text" maxLength={9} name='FractionDrugQty' id='FractionDrugQty' value={value?.FractionDrugQty} onChange={HandleChanges} className='requiredColor' required autoComplete='off' />
                                                                                </div>
                                                                                <div className="col-3 col-md-3  col-lg-2 mt-2 pt-1">
                                                                                    <label className='new-label'>Measurement Type {drugErrors.MeasurementTypeIDError !== 'true' ? (
                                                                                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{drugErrors.MeasurementTypeIDError}</p>
                                                                                    ) : null}</label>
                                                                                </div>
                                                                                <div className="col-9 col-md-9 col-lg-2 mt-2 ">
                                                                                    <Select
                                                                                        name='MeasurementTypeID'
                                                                                        value={measureTypeDrpData?.filter((obj) => obj.value === value?.MeasurementTypeID)}
                                                                                        styles={colourStyles}
                                                                                        options={measureTypeDrpData}
                                                                                        onChange={(e) => ChangeDropDown(e, 'MeasurementTypeID')}
                                                                                        isClearable
                                                                                        placeholder="Select..."
                                                                                    />
                                                                                </div>
                                                                                <div className="col-3 col-md-3  col-lg-2 mt-2">
                                                                                    <label className='new-label'>Solid Pounds{drugErrors.solidPoundsError !== 'true' ? (
                                                                                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{drugErrors.solidPoundsError}</p>
                                                                                    ) : null}</label>
                                                                                </div>
                                                                                <div className="col-3 col-md-3 col-lg-2 mt-1 text-field">
                                                                                    <input type="text" maxLength={9} name='SolidPounds' id='SolidPounds' value={value?.SolidPounds} onChange={HandleChanges} className='requiredColor' required autoComplete='off' />
                                                                                </div>
                                                                                <div className="col-3 col-md-3  col-lg-2 mt-2">
                                                                                    <label className='new-label'>Solid Ounces{drugErrors.solidOunceError !== 'true' ? (
                                                                                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{drugErrors.solidOunceError}</p>
                                                                                    ) : null}</label>
                                                                                </div>
                                                                                <div className="col-3 col-md-3 col-lg-2 mt-1 text-field">
                                                                                    <input type="text" maxLength={9} name='SolidOunces' id='SolidOunces' value={value?.SolidOunces} onChange={HandleChanges} className='requiredColor' required autoComplete='off' />
                                                                                </div>
                                                                                <div className="col-3 col-md-3  col-lg-2 mt-2">
                                                                                    <label className='new-label'>Solid Grams{drugErrors.solidGramError !== 'true' ? (
                                                                                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{drugErrors.solidGramError}</p>
                                                                                    ) : null}</label>
                                                                                </div>
                                                                                <div className="col-3 col-md-3 col-lg-2 mt-2 text-field">
                                                                                    <input type="text" maxLength={9} name='SolidGrams' id='SolidGrams' value={value?.SolidGrams} onChange={HandleChanges} className='requiredColor' required autoComplete='off' />
                                                                                </div>
                                                                                <div className="col-3 col-md-3  col-lg-2 mt-2">
                                                                                    <label className='new-label'>Liquid Ounces{drugErrors.liquidOunceError !== 'true' ? (
                                                                                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{drugErrors.liquidOunceError}</p>
                                                                                    ) : null}</label>
                                                                                </div>
                                                                                <div className="col-3 col-md-3 col-lg-2 mt-2 text-field">
                                                                                    <input type="text" maxLength={9} name='LiquidOunces' id='LiquidOunces' value={value?.LiquidOunces} onChange={HandleChanges} className='requiredColor' required autoComplete='off' />
                                                                                </div>
                                                                                <div className="col-3 col-md-3  col-lg-2 mt-2">
                                                                                    <label className='new-label'>Dose Units{drugErrors.doseUnitsError !== 'true' ? (
                                                                                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{drugErrors.doseUnitsError}</p>
                                                                                    ) : null}</label>
                                                                                </div>
                                                                                <div className="col-3 col-md-3 col-lg-2 mt-2 text-field">
                                                                                    <input type="text" maxLength={9} name='DoseUnits' id='DoseUnits' value={value?.DoseUnits} onChange={HandleChanges} className='requiredColor' required autoComplete='off' />
                                                                                </div>
                                                                                <div className="col-3 col-md-3  col-lg-2 mt-2">
                                                                                    <label className='new-label'>Items{drugErrors.ItemsError !== 'true' ? (
                                                                                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{drugErrors.ItemsError}</p>
                                                                                    ) : null}</label>
                                                                                </div>
                                                                                <div className="col-3 col-md-3 col-lg-2 mt-2 text-field">
                                                                                    <input type="text" maxLength={9} name='Items' id='Items' value={value?.Items} onChange={HandleChanges} className='requiredColor' required autoComplete='off' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                </div>
                                                            </div>
                                                            <div className="btn-box text-right  mr-1 mb-2">
                                                                <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => { check_Drug_Validation_Error() }}> {propertyDrugID ? 'Update' : 'Save'}</button>
                                                                <button type="button" data-dismiss="modal" className="btn btn-sm btn-success mr-1" data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} onClick={onDrugClose}>Close</button>
                                                            </div>
                                                        </div>
                                                    </div >
                                                </div >
                                            }
                                            {
                                                modalStatus &&
                                                <div className="modal" id="myModal2" style={{ background: "rgba(0,0,0, 0.5)", transition: '0.5s' }} data-backdrop="false">
                                                    <div className="modal-dialog">
                                                        <div className="modal-content">
                                                            <div className="box text-center py-5">
                                                                <h5 className="modal-title mt-2" id="exampleModalLabel">Do you want to Delete ?</h5>
                                                                <div className="btn-box mt-3">
                                                                    <button type="button" onClick={delete_Image_File} className="btn btn-sm text-white" style={{ background: "#ef233c" }} >Delete</button>
                                                                    <button type="button" onClick={() => { setImageId(''); setModalStatus(false); }} className="btn btn-sm btn-secondary ml-2"> Cancel</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-12 ">
                                                {
                                                    MstPage != "MST-Property-Dash" &&
                                                    <DataTable
                                                        dense
                                                        fixedHeader
                                                        persistTableHead={true}
                                                        customStyles={tableCustomStyles}
                                                        conditionalRowStyles={conditionalRowStyles}
                                                        columns={columns1}
                                                        // columns={HideCol}
                                                        data={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? propertyMainModuleData : [] : propertyMainModuleData}
                                                        // pagination
                                                        selectableRowsHighlight
                                                        highlightOnHover
                                                        responsive
                                                        // subHeaderComponent={<ThreeFilter Data={propertyData} setResult={setPropertyFilterData} Col1='PropertyNumber' Col2='Officer_Name' Col3='PropertyType_Description' searchName1='PropertyNumber' searchName2='Officer_Name' searchName3='PropertyType_Description' />}
                                                        // subHeaderAlign='left'
                                                        onRowClicked={(row) => {
                                                            setClickedRow(row);
                                                            set_EditRow(row);
                                                        }}
                                                        fixedHeaderScrollHeight='160px'
                                                        pagination
                                                        paginationPerPage={'100'}
                                                        paginationRowsPerPageOptions={[100, 150, 200, 500]}
                                                        showPaginationBottom={100}
                                                        noDataComponent={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? "There are no data to display" : "You don’t have permission to view data" : 'There are no data to display'}
                                                    />
                                                }
                                            </div>
                                            <DeletePopUpModal func={!isProperty ? Delete_Prpperty_Drug : Delete_Property} />
                                            {/* <ChangesModal func={check_Validation_Error} /> */}
                                            <ChangesModal func={check_Validation_Error} setToReset={newProperty} />
                                            <IdentifyFieldColor />
                                            <PropertySearchTab {...{ get_PropertyArticle_Single_Data, get_PropertyBoat_Single_Data, get_PropertOther_Single_Data, get_PropertySecurity_Single_Data, get_PropertyWeapon_Single_Data, searchModalState, setSearchModalState, mainIncidentID, value, setValue, loginPinID, loginAgencyID, MstPage, setPropertOther, setPropertyBoat, setPropertyWeapon, setPropertySecurity, setPropertyArticle, setLossCode, PropertyCategory, PropertyClassification, setPropertyNumber }} />
                                            <MasterNameModel {...{ value, setValue, nameModalStatus, setNameModalStatus, loginPinID, loginAgencyID, type, possenSinglData, setPossessionID, possessionID, setPossenSinglData, GetSingleDataPassion }} />
                                            <ListModal {...{ openPage, setOpenPage }} />
                                            <ImageModel multiImage={multiImage} value={value} primaryOfficerID={agencyOfficerDrpData} setMultiImage={setMultiImage} uploadImgFiles={uploadImgFiles} setuploadImgFiles={setuploadImgFiles} ChangeDropDown={ChangeDropDown} modalStatus={modalStatus} setModalStatus={setModalStatus} imageId={imageId} setImageId={setImageId} imageModalStatus={imageModalStatus} setImageModalStatus={setImageModalStatus} delete_Image_File={delete_Image_File} setImgData={setImgData} imgData={imgData} updateImage={update_Property_MultiImage} agencyID={loginAgencyID} />
                                            <AlertMasterModel masterID={masterPropertyID} modelName={"Property"} loginPinID={loginPinID} agencyID={loginAgencyID} getAlertData={setAvailableAlert} />
                                        </fieldset>
                                    </div>
                                    {/* Buttons Section */}
                                    <div className="row">
                                        <div className="col-12 p-0">
                                            <div className="py-0 px-2 d-flex justify-content-end align-items-center">
                                                <div className="d-flex justify-content-end tab-form-row-gap mt-1">
                                                    <button
                                                        type="button"
                                                        data-dismiss="modal"
                                                        className="cancel-button"
                                                        onClick={() => onCloseLocation()}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </dialog >
                </>
            ) : (
                <> </>
            )
            }
        </>
    );
};

export default memo(PropertyTabSectionModal);

const Get_Property_Code = (data, dropDownData) => {
    const result = data?.map((sponsor) =>
        (sponsor.PropertyTypeID)
    )
    const result2 = dropDownData?.map((sponsor) => {
        if (sponsor.value === result[0]) {
            return { value: result[0], label: sponsor.label, id: sponsor.id }
        }
    }
    )
    const val = result2.filter(function (element) {
        return element !== undefined;
    });
    return val[0]?.id
}

const Get_Drug_Code = (data, dropDownData) => {
    const newArr = [];
    newArr.push(data);

    const result = newArr?.map((sponsor) =>
        (sponsor.SuspectedDrugTypeID)
    )
    const result2 = dropDownData?.map((sponsor) => {
        if (sponsor.value === parseInt(result[0])) {
            return { value: result[0], label: sponsor.label, id: sponsor.id }
        }
    })
    const val = result2.filter(function (element) {
        return element !== undefined;
    });
    return val[0]?.id
}

const Get_LossCode = (data, dropDownData) => {
    const result = data?.map((sponsor) => (sponsor.LossCodeID))
    const result2 = dropDownData?.map((sponsor) => {
        if (sponsor.value === result[0]) {
            return { value: result[0], label: sponsor.label, id: sponsor.id }
        }
    })
    const val = result2.filter(function (element) {
        return element !== undefined;
    });
    return val[0]?.id
}
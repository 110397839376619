import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Tooltip, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './index.css'; // For custom styles

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const FitBoundsView = ({ bounds }) => {
  const map = useMap();
  useEffect(() => {
    if (bounds) {
      map.fitBounds(bounds); // Fit the map to the bounds of the markers
    }
  }, [map, bounds]);
  return null;
};

// Custom fullscreen control using browser fullscreen API
const FullscreenControl = () => {
  const map = useMap();

  useEffect(() => {
    const fullscreenControl = L.control({ position: 'topleft' });

    fullscreenControl.onAdd = function () {
      const div = L.DomUtil.create('div', 'leaflet-bar fullscreen-control');
      div.innerHTML = '&#x26F6;'; // Fullscreen symbol (optional: you can use a different icon)
      div.style.cursor = 'pointer';
      div.style.fontSize = '20px';
      div.style.width = '30px';
      div.style.height = '30px';
      div.style.display = 'flex';
      div.style.justifyContent = 'center';
      div.style.alignItems = 'center';
      div.title = 'Toggle fullscreen';

      div.onclick = function () {
        const mapContainer = map.getContainer();
        if (!document.fullscreenElement) {
          mapContainer.requestFullscreen(); // Enter fullscreen
        } else if (document.exitFullscreen) {
          document.exitFullscreen(); // Exit fullscreen
        }
      };

      return div;
    };

    fullscreenControl.addTo(map);

    return () => {
      map.removeControl(fullscreenControl);
    };
  }, [map]);

  return null;
};

const dynamicLocations = [
  {
    longitude: -121.015099,
    latitude: 37.639492,
    Title: "Location 1",
    Desc: "This is the first location",
    Icon: "",
  },
  {
    longitude: -121.275604,
    latitude: 37.961632,
    Title: "Location 2",
    Desc: "This is the second location",
    Icon: "",
  },
  {
    longitude: -119.772591,
    latitude: 36.746841,
    Title: "Location 3",
    Desc: "This is the third location",
    Icon: "",
  },
  {
    longitude: -121.607841,
    latitude: 39.136986,
    Title: "Location 4",
    Desc: "This is the fourth location",
    Icon: "",
  }
];

const CADMap = () => {
  const [bounds, setBounds] = useState(null);

  useEffect(() => {
    if (dynamicLocations.length > 0) {
      const locationBounds = dynamicLocations.map((loc) => [loc.latitude, loc.longitude]);
      setBounds(L.latLngBounds(locationBounds));
    }
  }, []);

  return (
    <MapContainer
      className="full-screen-map" // Custom CSS class to make the map full-screen
      bounds={bounds}
    >
      {bounds && <FitBoundsView bounds={bounds} />}
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
      />
      <FullscreenControl /> {/* Fullscreen control */}
      {dynamicLocations.map((location, index) => (
        <Marker
          key={index}
          position={[location.latitude, location.longitude]}
          icon={
            new L.DivIcon({
              className: 'custom-marker', // Custom CSS class
              html: `<div>
                      <img src='${location.Icon || "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png"}' style="width: 25px; height: 41px;" />
                      <div class="marker-title">${location.Title}</div>
                    </div>`,
              iconSize: [25, 41],
              iconAnchor: [12, 41],
            })
          }
        >
          <Popup>
            <div>
              <strong>{location.Title}</strong> <br />
              {location.Desc}
            </div>
          </Popup>
          <Tooltip>{location.Desc}</Tooltip>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default CADMap;

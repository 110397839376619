import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
// import { tableCustomStyles } from '../../Utility/CustomStylesForReact';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { CadDashboardTableCustomStyles } from '../../../Components/Common/Utility';


const initialColumns = [
  { id: 'note', name: 'Note', selector: row => row['Note'], sortable: true },
  { id: 'incident', name: 'Incident #', selector: row => row['Incident #'], sortable: true },
  { id: 'date', name: 'Receive Date & Time', selector: row => row['Receive Date & Time'], sortable: true },
  { id: 'source', name: 'Source', selector: row => row['Source'], sortable: true },
  { id: 'spd', name: 'SPD', selector: row => row['SPD'], sortable: true },
  { id: 'cfsCode', name: 'CFS Code', selector: row => row['CFS Code'], sortable: true },
  { id: 'cfsDescription', name: 'CFS Description', selector: row => row['CFS Description'], sortable: true },
  { id: 'priority', name: 'Priority', selector: row => row['Priority'], sortable: true },
  { id: 'unitStatus', name: 'Unit Status', selector: row => row['Unit Status'], sortable: true },
  { id: 'eTimer', name: 'E Timer', selector: row => row['E Timer'], sortable: true },
  { id: 'location', name: 'Location', selector: row => row['Location'], sortable: true },
];

const data = [
  {
    Note: 'Sample note 1',
    'Incident #': 'INC001',
    'Receive Date & Time': '2024-07-25 10:30 AM',
    Source: 'Emergency Call',
    SPD: 'Sample SPD',
    'CFS Code': 'CFS001',
    'CFS Description': 'Sample CFS Description',
    Priority: 'High',
    'Unit Status': 'Dispatched',
    'E Timer': '00:05:00',
    Location: '123 Main St'
  },
  {
    Note: 'Sample note 2',
    'Incident #': 'INC002',
    'Receive Date & Time': '2024-07-25 10:35 AM',
    Source: 'Online Report',
    SPD: 'Sample SPD',
    'CFS Code': 'CFS002',
    'CFS Description': 'Sample CFS Description',
    Priority: 'Medium',
    'Unit Status': 'Not Assigned',
    'E Timer': '00:00:00',
    Location: '456 Elm St'
  },
  {
    Note: 'Sample note 3',
    'Incident #': 'INC003',
    'Receive Date & Time': '2024-07-25 10:40 AM',
    Source: 'Phone Call',
    SPD: 'Sample SPD',
    'CFS Code': 'CFS003',
    'CFS Description': 'Sample CFS Description',
    Priority: 'Low',
    'Unit Status': 'En Route',
    'E Timer': '00:02:30',
    Location: '789 Oak St'
  },
  {
    Note: 'Sample note 4',
    'Incident #': 'INC004',
    'Receive Date & Time': '2024-07-25 10:45 AM',
    Source: 'Emergency Call',
    SPD: 'Sample SPD',
    'CFS Code': 'CFS004',
    'CFS Description': 'Sample CFS Description',
    Priority: 'High',
    'Unit Status': 'On Scene',
    'E Timer': '00:10:00',
    Location: '321 Pine St'
  },
  {
    Note: 'Sample note 5',
    'Incident #': 'INC005',
    'Receive Date & Time': '2024-07-25 10:50 AM',
    Source: 'Online Report',
    SPD: 'Sample SPD',
    'CFS Code': 'CFS005',
    'CFS Description': 'Sample CFS Description',
    Priority: 'Medium',
    'Unit Status': 'Not Assigned',
    'E Timer': '00:00:00',
    Location: '654 Maple St'
  },
];


const IncidentTableSection = () => {
  const [columns, setColumns] = useState(initialColumns);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedColumns = Array.from(columns);
    const [movedColumn] = reorderedColumns.splice(result.source.index, 1);
    reorderedColumns.splice(result.destination.index, 0, movedColumn);

    setColumns(reorderedColumns);
  };

  return (
    <>
      <div className='table-container'>
        <div className='row table-container-header'>
          <div className='col-6 d-flex align-content-center justify-content-start table-header-status'>
            <div className='d-flex'>
              <span>Unassigned</span>
              <span>5</span>
            </div>
            <div className='d-flex'>
              <span>Active</span>
              <span>0</span>
            </div>
          </div>
          <div className='col-6 d-flex align-content-center justify-content-end table-header-buttons'>
            <button>All</button>
            <button>Assigned</button>
            <button>Unassigned</button>
            <button>
              <FontAwesomeIcon icon={faFilter} style={{ marginRight: '8px', opacity: 0.7 }} />
              Filters
            </button>
          </div>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <DataTable
                  dense
                  columns={columns.map((column, index) => ({
                    ...column,
                    cell: (row) => (
                      <Draggable draggableId={column.id} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {column.selector(row)}
                          </div>
                        )}
                      </Draggable>
                    ),
                  }))}
                  data={data}
                  customStyles={CadDashboardTableCustomStyles}
                  pagination
                  responsive
                  striped
                  highlightOnHover
                  fixedHeader
                // selectableRowsHighlight
                // paginationPerPage={'13'}
                // paginationRowsPerPageOptions={[5, 10, 15, 20]}
                // fixedHeaderScrollHeight='600px'
                // persistTableHead={true}
                />
                {provided.placeholder}
              </div>
            )}

          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
};

export default IncidentTableSection;
import React, { memo, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Decrypt_Id_Name, Encrypted_Id_Name, getShowingDateText, getShowingWithOutTime } from '../../Common/Utility';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Vehicle_Search_Data } from '../../../redux/actionTypes';
import { fetchPostData } from '../../hooks/Api';
import { toastifyError } from '../../Common/AlertMsg';

const PropertySearchTab = (props) => {

    const { GetSingleData, searchModalState, setSearchModalState, mainIncidentID, value, setValue, loginPinID, loginAgencyID, MstVehicle, setPropertOther, setEditval } = props
    const dispatch = useDispatch();

    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const vehicleSearchData = useSelector((state) => state.Vehicle.vehicleSearchData);


    const VehicleCol = [
        {
            name: 'Vehicle Number',
            selector: (row) => row.VehicleNumber,
            sortable: true
        },
        {
            name: 'Incident Number',
            selector: (row) => row.IncidentNumber,
            sortable: true
        },
        {
            name: 'VIN',
            selector: (row) => row.VIN,
            sortable: true
        },
        {
            name: 'Manufacture Year',
            selector: (row) => row.ManufactureYear,
            sortable: true
        },
        {
            name: 'Loss Code',
            selector: (row) => row.LossCode_Description,
            sortable: true
        },
        {
            name: 'Category',
            selector: (row) => row.Category_Description,
            sortable: true
        },
        {
            name: 'Reported Date',
            selector: (row) => row.ReportedDtTm ? getShowingWithOutTime(row.ReportedDtTm) : " ",
            sortable: true
        },

        {
            name: <p className='text-end' style={{ position: 'absolute', top: '7px' }}>Action</p>,
            cell: row => <>
                {
                    <span onClick={() => { setEdit(row); console.log(row) }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1">
                        <i className="fa fa-edit"></i>
                    </span>
                }
            </>
        }
    ]

    const setEdit = (row) => {
        fetchPostData("PropertyVehicle/GetData_PropertyVehicleExist", {
            "MasterPropertyID": row.MasterPropertyID,
            "IncidentID": mainIncidentID ? mainIncidentID : '',
        }).then((data) => {
            if (data) {
                console.log(row.MasterPropertyID)
                if (data[0]?.Total === 0) {
                    GetSingle_Data(row.MasterPropertyID)
                    setSearchModalState(false);
                } else {
                    toastifyError('Vehicle Already Exists');
                    setSearchModalState(true);
                }
            }
        })
    }

    const GetSingle_Data = (masterPropertyId) => {
        const val = { 'MasterPropertyID': masterPropertyId, 'VehicleID': 0, 'PINID': loginPinID, 'IncidentID': 0, 'IsMaster': true }
        fetchPostData('PropertyVehicle/GetSingleData_PropertyVehicle', val).then((res) => {
            if (res) {
                setEditval(res);
            } else {
                setEditval([]);
            }
        })
    }

    const onCloseModel = () => {
        dispatch({ type: Vehicle_Search_Data, payload: [] });
        setSearchModalState(false)
    }

    return (
        searchModalState ?
            <div className="modal fade " style={{ background: "rgba(0,0,0, 0.5)", }} id="PropertyModal" tabIndex="-1" data-backdrop="false" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header px-3 p-2">
                            <h5 className="modal-title">Vehicle-Search-List</h5>
                            <button type="button" onClick={onCloseModel} className="close text-red border-0" data-dismiss="modal" style={{ alignSelf: 'end' }}>X</button>
                        </div>
                        <div className="box text-center px-2">
                            <div className="col-12 ">

                                <DataTable
                                    dense
                                    columns={VehicleCol}
                                    data={vehicleSearchData}
                                    pagination
                                    selectableRowsHighlight
                                    highlightOnHover
                                />


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :
            <></>
    )
}

export default memo(PropertySearchTab)

const Get_Property_Code = (data, dropDownData) => {
    const result = data?.map((sponsor) =>
        (sponsor.PropertyTypeID)
    )
    const result2 = dropDownData?.map((sponsor) => {
        if (sponsor.value === result[0]) {
            return { value: result[0], label: sponsor.label, id: sponsor.id }
        }
    }
    )
    const val = result2.filter(function (element) {
        return element !== undefined;
    });
    return val[0]?.id
}

const Get_LossCode = (data, dropDownData) => {
    const result = data?.map((sponsor) => (sponsor.LossCodeID))
    const result2 = dropDownData?.map((sponsor) => {
        if (sponsor.value === result[0]) {
            return { value: result[0], label: sponsor.label, id: sponsor.id }
        }
    })
    const val = result2.filter(function (element) {
        return element !== undefined;
    });
    return val[0]?.id
}
import React, { useRef } from 'react'
import img from '../../../../../src/img/images1.jpg'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { Decrypt_Id_Name, colourStyles, customStylesWithOutColor, getShowingDateText, getShowingWithOutTime } from '../../../Common/Utility';
import Select from "react-select";
import { useEffect } from 'react';
import { fetchData, fetchPostData } from '../../../hooks/Api';
import { useReactToPrint } from 'react-to-print';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../redux/actions/Agency';
import { toastifyError } from '../../../Common/AlertMsg';


const ArrestMaster = () => {

    const dispatch = useDispatch();
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const localStoreData = useSelector((state) => state.Agency.localStoreData);

    const [reportData, setReportData] = useState([]);
    const [verifyArrestMaster, setverifyArrestMaster] = useState(false);
    const [Arrestfromdate, setArrestfromdate] = useState('')
    const [ArrestTodate, setArrestTodate] = useState('')
    const [arestReportData, setArestReportData] = useState([]);
    const [LoginAgencyID, setLoginAgencyID] = useState('');
    const [LoginPinID, setLoginPinID,] = useState('');
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [zipList, setZipList] = useState([]);
    const [multiImage, setMultiImage] = useState([]);

    const [value, setValue] = useState({
        AgencyID: '', ArrestNumber: '', ArrestDtTmTo: '', ArrestDtTm: '', IncidentNumber: '', NameIDNumber: '',
        LastName: '', FirstName: '', MiddleName: '', DateOfBirthFrom: '', DateOfBirthTo: '', DirectionPrefix: '', Street: '',
        DirectionSufix: '', TypeSufix: '', PremiseNo: '', ApartmentNo: '', CommonPlace: '',
        ApartmentType: '', Street_Parse: '', PremiseNo_Parse: '', DirectionPrefix_Parse: '', TypeSuffix_Parse: '',
        DirectionSuffix_Parse: '', IsUsLocation: '', point_of_interest: '',
        neighborhood: '', subpremise: '', premise: '', Statefullname: '',
        CountryID: '', Country: '', State: '', City: '', CityID: '', ZipCodeID: '', ZipCode: '',
    });

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    function stateChanges(e) {
        if (e) {
            setValue({
                ...value,
                ['State']: e.value,
                ['Statefullname']: e.label
            });
            getCity(e.value)
        } else {
            setValue({
                ...value,
                ['State']: null, ['ZipName']: '', ['CityName']: ''
            })
            setCityList([])
            setZipList([])
        }
    }

    function cityChanges(e) {
        if (e) {
            setValue({
                ...value,
                ['CityID']: e.value,
                ['City']: e.label,
                ['ZipCodeID']: '',
                ['ZipName']: '',
            });
            getZipCode(e.value)
        } else {
            setValue({
                ...value,
                ['CityID']: null, ['ZipCodeID']: '',
            });
            setZipList()
        }
    }

    function zipChanges(e) {
        if (e) {
            setValue({
                ...value,
                ['ZipCodeID']: e.value, ['ZipCode']: e.label, ['ZipName']: { value: e.value, label: e.label }
            });
        } else {
            setValue({
                ...value,
                ['ZipCodeID']: null
            });
        }
    }

    const getStateList = async () => {
        fetchData("State_City_ZipCode/GetData_State").then(res => {
            if (res) {
                setStateList(changeArrayFormat(res, 'state'))
            }
            else setStateList([])
        })
    }

    const getCity = async (stateID) => {
        fetchPostData('State_City_ZipCode/GetData_City', { StateID: stateID }).then((res) => {
            if (res) {
                setCityList(changeArrayFormat(res, 'city'))
            }
            else setCityList(changeArrayFormat(res, 'city'))
        }).catch((error) => {
            console.error('There was an error!', error);
        });
    }

    const getZipCode = async (cityID) => {
        fetchPostData('State_City_ZipCode/GetData_ZipCode', { CityId: cityID }).then((res) => {
            if (res) {
                setZipList(changeArrayFormat(res, 'zip'))
            }
            else setZipList(changeArrayFormat(res, 'zip'))
        }).catch((error) => {
            console.error('There was an error!', error);
        });
    }

    // const onChangeArrestNumber = (e) => {
    //     if (e) {
    //         if (e.target.name === 'ArrestNumber') {
    //             let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
    //             if (ele[0]?.match(/[a-zA-Z\s]/g)) {
    //                 if (ele?.length <= 4) {
    //                     const alphabet = ele.toUpperCase().replace(/[^a-zA-Z\s]/g, '') || '';
    //                     setValue({
    //                         ...value,
    //                         [e.target.name]: alphabet
    //                     });
    //                 } else if (ele.length >= 15) {
    //                     e?.preventDefault()
    //                 }
    //                 else {
    //                     let subs = ele.substring(0, 4);
    //                     let subs2 = ele.substring(4).replace(/[^0-9]/g, '');
    //                     setValue({
    //                         ...value,
    //                         [e.target.name]: subs + '-' + subs2
    //                     })
    //                 }
    //             } else {
    //                 if (ele.length === 8) {
    //                     var cleaned = ('' + ele).replace(/[^a-zA-Z\s^0-9\s]/g, '');
    //                     var match = cleaned.match(/^(\d{2})(\d{6})$/);
    //                     if (match) {
    //                         setValue({
    //                             ...value,
    //                             [e.target.name]: match[1] + '-' + match[2]
    //                         })
    //                     }
    //                 } else if (ele.length >= 8) {
    //                     e?.preventDefault()
    //                 } else {
    //                     ele = e.target.value.split("'").join('').replace(/[^0-9\s]/g, '');
    //                     setValue({
    //                         ...value,
    //                         [e.target.name]: ele
    //                     })
    //                 }
    //             }
    //         } else {
    //             setValue({
    //                 ...value,
    //                 [e.target.name]: e.target.value
    //             })
    //         }
    //     } else {
    //         setValue({
    //             ...value,
    //             [e.target.name]: e.target.value
    //         })
    //     }
    // }

    const onChangeArrestNumber = (e) => {
        if (e) {
            if (e.target.name === 'ArrestNumber') {
                let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
                if (ele[0]?.match(/[a-zA-Z\s]/g)) {
                    let subs = ele.toUpperCase().replace(/[^a-zA-Z\s]/g, '').substring(0, 4);
                    let subs2 = ele.replace(/[^0-9]/g, '');
                    if (ele?.length <= 4) {
                        const alphabet = ele.toUpperCase().replace(/[^a-zA-Z\s]/g, '') || '';
                        setValue({
                            ...value,
                            [e.target.name]: alphabet
                        })
                    } else if (ele.length >= 15) {
                        e?.preventDefault()
                    } else if (subs2?.length >= 11) {
                        // console.log(subs2, 'asdasdas')
                    } else {
                        setValue({
                            ...value,
                            [e.target.name]: subs + '-' + subs2
                        })
                    }
                } else {
                    let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
                    if (ele[0]?.match(/[0-9]/g)) {
                        const digits = ele.replace(/[^0-9]/g, '');
                        if (ele?.length <= 2) {
                            setValue({
                                ...value,
                                [e.target.name]: digits
                            })
                        } else if (ele.length >= 9) {
                            e.preventDefault();
                        } else {
                            let subs = ele.substring(0, 2);
                            let subs2 = ele.substring(2).replace(/[^0-9]/g, '');
                            setValue({
                                ...value,
                                [e.target.name]: subs + '-' + subs2
                            })
                        }
                    } else {
                        setValue({
                            ...value,
                            [e.target.name]: e.target.value
                        })
                    }

                }
            }
        }
    }

    const Handlechange = (e) => {
        if (e.target.name === 'SSN') {
            let ele = e.target.value.replace(/\D/g, '');
            if (ele.length === 9) {
                const cleaned = ('' + ele).replace(/\D/g, '');
                const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
                if (match) {
                    setValue({
                        ...value,
                        [e.target.name]: match[1] + '-' + match[2] + '-' + match[3]
                    })
                }
            } else {
                ele = e.target.value.split('-').join('').replace(/\D/g, '');
                setValue({
                    ...value,
                    [e.target.name]: ele
                })
            }
            if (e.target.name === 'SSN') {
                return 'true';
            }
            if (e.target.name.length === 11) {
                return 'true'
            }
        } else if (e.target.name === 'IncidentNumber') {
            let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
            if (ele.length === 8) {
                const cleaned = ('' + ele).replace(/[^a-zA-Z0-9\s]/g, '');
                const match = cleaned.match(/^(\d{2})(\d{6})$/);
                if (match) {
                    setValue({
                        ...value,
                        [e.target.name]: match[1] + '-' + match[2]
                    })
                }
            } else {
                ele = e.target.value.split("'").join('').replace(/[^0-9\s]/g, '');
                setValue({
                    ...value,
                    [e.target.name]: ele
                })
            }
        } else if (e.target.name === 'NameIDNumber') {
            let ele = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
            if (ele.length <= 11) {
                const alphabet = ele[0]?.toUpperCase() || '';
                const digits = ele.slice(1).replace(/[^0-9]/g, '');
                // console.log(match)
                if (digits.length === 9) {
                    setValue({
                        ...value,
                        [e.target.name]: alphabet + '-' + digits
                    });
                } else {
                    setValue({
                        ...value,
                        [e.target.name]: alphabet + digits
                    });
                }
            } else {
                ele = e.target.value.split("'").join('').replace(/[^a-zA-Z0-9\s]/g, '');
                setValue({
                    ...value,
                    [e.target.name]: ele
                })
            }
        } else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }

    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID);
            setLoginPinID(localStoreData?.PINID);
            getStateList();
            getCity();
            getZipCode();
            getAgencyImg(localStoreData?.AgencyID);
        }
    }, [localStoreData])

    const get_ArrestSearchData = async () => {
        if (value?.ArrestDtTmTo?.trim()?.length > 0 || value?.ArrestDtTm?.trim()?.length > 0 || value?.ArrestNumber?.trim()?.length > 0 || value?.IncidentNumber?.trim()?.length > 0 || value?.MiddleName?.trim()?.length > 0 || value?.LastName?.trim()?.length > 0 || value?.NameIDNumber?.trim()?.length > 0 || value?.DateOfBirthFrom?.trim()?.length > 0 || value?.DateOfBirthTo?.trim()?.length > 0 || value?.City?.trim()?.length > 0 || value?.ZipCodeID !== null || value?.CityID !== null || value?.ZipCode !== null) {
            const { AgencyID, ArrestNumber, ArrestDtTmTo, ArrestDtTm, IncidentNumber, NameIDNumber, LastName, FirstName, MiddleName, DateOfBirthFrom, DateOfBirthTo, DirectionPrefix, Street, DirectionSufix, TypeSufix, City, State, ZipCode, PremiseNo, ApartmentNo, CommonPlace, ApartmentType, Street_Parse, PremiseNo_Parse, DirectionPrefix_Parse, TypeSuffix_Parse, Address, DirectionSuffix_Parse, ZipCodeID, CityID, IsUsLocation, CountryID, Country, point_of_interest, neighborhood, subpremise, premise, Statefullname
            } = value
            const val = {
                'AgencyID': LoginAgencyID, 'ArrestNumber': ArrestNumber, 'ArrestDtTmTo': ArrestDtTmTo, 'ArrestDtTm': ArrestDtTm, 'IncidentNumber': IncidentNumber, 'NameIDNumber': NameIDNumber, 'LastName': LastName, 'FirstName': FirstName, 'MiddleName': MiddleName, 'DateOfBirthFrom': DateOfBirthFrom, 'DirectionPrefix': DirectionPrefix, 'Street': Street, 'DirectionSufix': DirectionSufix, 'TypeSufix': TypeSufix, 'City': City, 'State': State, 'ZipCode': ZipCode, 'ApartmentNo': ApartmentNo, 'CommonPlace': CommonPlace, 'ApartmentType': ApartmentType, 'Street_Parse': Street_Parse, 'PremiseNo_Parse': PremiseNo_Parse, 'DirectionPrefix_Parse': DirectionPrefix_Parse, 'TypeSuffix_Parse': TypeSuffix_Parse, 'DirectionSuffix_Parse': DirectionSuffix_Parse, 'ZipCodeID': ZipCodeID, 'CityID': CityID, 'IsUsLocation': IsUsLocation, 'CountryID': CountryID, 'Country': Country, 'point_of_interest': point_of_interest, 'neighborhood': neighborhood, 'premise': premise, 'Statefullname': Statefullname, 'DateOfBirthTo': DateOfBirthTo, 'Address': Address, 'PremiseNo': PremiseNo, 'subpremise': subpremise,
            }
            fetchPostData('ArrestReport/GetData_ArrestReport', val).then((res) => {
                if (res.length > 0) {
                    setReportData(res)
                    setArestReportData(res[0])
                    setverifyArrestMaster(true)
                    getAgencyImg(LoginAgencyID)
                } else {
                    setReportData([])
                    setArestReportData([]);
                    setverifyArrestMaster(false);
                    toastifyError("Data Not Available");
                }
            });
        }
    }

    const getAgencyImg = (LoginAgencyID) => {
        const val = { 'AgencyID': LoginAgencyID }
        fetchPostData('Agency/GetDataAgencyPhoto', val).then((res) => {
            if (res) {
                let imgUrl = `data:image/png;base64,${res[0]?.Agency_Photo}`;
                setMultiImage(imgUrl);
            }
            else { console.log("errror") }
        })
    }

    // const get_MasterArest_Report = () => {
    //     const val = { 'ArrestID': '', 'AgencyID': '', }
    //     fetchPostData('ArrestReport/GetData_ArrestReport', val).then((res) => {
    //         if (res) {
    //             setArestReportData(res)
    //         } else { setArestReportData([]); }
    //     })
    // }


    useEffect(() => {
        if (arestReportData?.length > 0) {
            setverifyArrestMaster(true);
        } else {
            setverifyArrestMaster(false);
        }
    }, []);

    const resetFields = () => {
        setValue({
            ...value,
            AgencyID: '', ArrestNumber: '', ArrestDtTmTo: '', ArrestDtTm: '', IncidentNumber: '', NameIDNumber: '',
            LastName: '', FirstName: '', MiddleName: '', DateOfBirthFrom: '', DateOfBirthTo: '', DirectionPrefix: '', Street: '',
            DirectionSufix: '', TypeSufix: '', City: '', State: '', ZipCode: '', PremiseNo: '', ApartmentNo: '', CommonPlace: '',
            ApartmentType: '', Street_Parse: '', PremiseNo_Parse: '', DirectionPrefix_Parse: '', TypeSuffix_Parse: '',
            DirectionSuffix_Parse: '', ZipCodeID: '', CityID: '', IsUsLocation: '', CountryID: '', Country: '', point_of_interest: '',
            neighborhood: '', subpremise: '', premise: '', Statefullname: ''
        })
        setverifyArrestMaster(false)
        setArestReportData([]);
    }

    const componentRef = useRef();
    const printForm = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Data',
        onAfterPrint: () => { '' }
    })

    return (
        <>
            <div class="section-body view_page_design pt-3">
                <div className="row clearfix">
                    <div className="col-12 col-sm-12">
                        <div className="card Agency">
                            <div className="card-body">
                                <div className="col-12 col-md-12 col-lg-12 mb-2 " >
                                    <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                                        <p className="p-0 m-0 d-flex align-items-center">Arrest Master Report</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-2  col-md-2 col-lg-2  " >
                                            <div className="text-field">
                                                <input type="text" name='ArrestNumber' id='ArrestNumber' className='' value={value?.ArrestNumber} onChange={onChangeArrestNumber} />
                                                <label htmlFor="">Arrest Number</label>
                                            </div>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3 " style={{ marginTop: '-5px' }}>
                                            <div className="dropdown__box">
                                                <DatePicker
                                                    id='ArrestDtTm'
                                                    name='ArrestDtTm'
                                                    dateFormat="MM/dd/yyyy"
                                                    isClearable={value?.ArrestDtTm ? true : false}
                                                    // selected={ArrestDtTm}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    autoComplete='Off'
                                                    maxDate={new Date()}
                                                    selected={value?.ArrestDtTm && new Date(value?.ArrestDtTm)}
                                                    // ref={startRef}
                                                    // onKeyDown={onKeyDown}
                                                    onChange={(date) => { setValue({ ...value, ['ArrestDtTm']: date ? getShowingDateText(date) : null }) }}
                                                    timeInputLabel
                                                    placeholderText='Select...'
                                                />
                                                <label htmlFor="" className='pt-1'>Arrest Date From</label>
                                            </div>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3 " style={{ marginTop: '-5px' }}>
                                            <div className="dropdown__box">
                                                <DatePicker
                                                    name='ArrestDtTmTo'
                                                    id='ArrestDtTmTo'
                                                    onChange={(date) => { setValue({ ...value, ['ArrestDtTmTo']: date ? getShowingDateText(date) : null }) }}
                                                    selected={value?.ArrestDtTmTo && new Date(value?.ArrestDtTmTo)}
                                                    dateFormat="MM/dd/yyyy"
                                                    timeInputLabel
                                                    // ref={startRef1}
                                                    // onKeyDown={onKeyDown}
                                                    isClearable={value?.ArrestDtTmTo ? true : false}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    autoComplete='Off'
                                                    disabled={value?.ArrestDtTm ? false : true}
                                                    maxDate={new Date()}
                                                    placeholderText='Select...'
                                                    minDate={new Date(value?.ArrestDtTm)}
                                                />
                                                <label htmlFor="" className='pt-1'>Arrest Date To</label>
                                            </div>
                                        </div>
                                        <div className="col-2  col-md-2 col-lg-2 " >
                                            <div className="text-field">
                                                <input type="text" name='NameIDNumber' id='NameIDNumber' maxLength={11} className='' value={value?.NameIDNumber} onChange={Handlechange} />
                                                <label htmlFor="">Name Number</label>
                                            </div>
                                        </div>
                                        <div className="col-2  col-md-2 col-lg-2  " >
                                            <div className="text-field">
                                                <input type="text" name='IncidentNumber' id='IncidentNumber' maxLength={9} className='' value={value?.IncidentNumber} onChange={Handlechange} />
                                                <label htmlFor="">Incident Number</label>
                                            </div>
                                        </div>
                                        <div className="col-4  col-md-4 col-lg-4 pt-1 mt-1 ">
                                            <div className="dropdown__box">
                                                <textarea name='Address' id='Address' rows='1' className="form-control pt-2 pb-2 " ></textarea>
                                                <label htmlFor="" className='pl-0'>Address</label>
                                            </div>
                                        </div>
                                        <div class="col-3 col-md-3  col-lg-3   pt-2">
                                            <div className="dropdown__box">
                                                <Select
                                                    name="State"
                                                    styles={colourStyles}
                                                    isClearable
                                                    placeholder="Select..."
                                                    value={stateList?.filter((obj) => obj.value === value?.State)}
                                                    options={stateList}
                                                    onChange={stateChanges}
                                                    isDisabled={false}
                                                    classNamePrefix="select"
                                                    menuPlacement="bottom"
                                                />
                                                <label htmlFor="" className=''>State</label>
                                            </div>
                                        </div>
                                        <div class="col-3 col-md-3  col-lg-3 mt-2">
                                            <div className="dropdown__box">
                                                <Select
                                                    name="CityID"
                                                    styles={colourStyles}
                                                    isClearable
                                                    placeholder="Select..."
                                                    value={cityList?.filter((obj) => obj.value === value?.CityID)}
                                                    isDisabled={false}
                                                    options={cityList}
                                                    onChange={cityChanges}
                                                />
                                                <label htmlFor="" className=''>City</label>
                                            </div>
                                        </div>
                                        <div className="col-2  col-md-2 col-lg-2 mt-2" >
                                            <div className="text-field">
                                                {/* <input type="text" name='zip' id='IncidentNumberFrom' className='' /> */}
                                                <Select
                                                    name='ZipCodeID'
                                                    value={zipList?.filter((obj) => obj.value === value?.ZipCodeID)}
                                                    classNamePrefix="select"
                                                    styles={colourStyles}
                                                    menuPlacement="bottom"
                                                    options={zipList}
                                                    isClearable
                                                    onChange={zipChanges}
                                                    isDisabled={false}
                                                />
                                                <label htmlFor="" className=''>Zip</label>
                                            </div>
                                        </div>
                                    </div>
                                </div >
                                <div className="col-12 ">
                                    <fieldset className='mt-2'>
                                        <legend>Name Information</legend>
                                        <div className="row mt-2">
                                            <div class="col-6 col-md-3">
                                                <div className="text-field">
                                                    <input type="text" name='LastName' id='LastName' value={value?.LastName}
                                                        onChange={Handlechange} />
                                                    <label className=''>Last Name</label>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-3">
                                                <div className="text-field">
                                                    <input type="text" name='FirstName' id='FirstName' value={value?.FirstName}
                                                        onChange={Handlechange} />
                                                    <label className=''>First Name</label>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-3">
                                                <div className="text-field">
                                                    <input type="text" name='MiddleName' id='MiddleName' value={value?.MiddleName} onChange={Handlechange} />
                                                    <label className=''>Middle Name</label>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-3">
                                                <div className="text-field">
                                                    <input type="text" name='SSN' id='SSN' maxLength={9} value={value?.SSN} onChange={Handlechange} />
                                                    <label className=''>SSN</label>
                                                </div>
                                            </div>
                                            <div className="col-3 col-md-4 col-lg-3 mt-2 ">
                                                <div className="date__box ">
                                                    <DatePicker
                                                        id='DateOfBirthFrom'
                                                        name='DateOfBirthFrom'
                                                        dateFormat="MM/dd/yyyy"
                                                        onChange={(date) => {
                                                            if (date) {
                                                                setValue({ ...value, ['DateOfBirthFrom']: date ? getShowingWithOutTime(date) : "" })
                                                            } else {
                                                                setValue({ ...value, ['DateOfBirthFrom']: null, ['DateOfBirthTo']: null })
                                                            }
                                                        }}
                                                        isClearable={value.DateOfBirthFrom ? true : false}
                                                        selected={value?.DateOfBirthFrom && new Date(value.DateOfBirthFrom)}
                                                        placeholderText={'Select...'}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        autoComplete='Off'
                                                        maxDate={new Date()}
                                                    />
                                                    <label htmlFor="">DOB From</label>
                                                </div>
                                            </div>
                                            <div className="col-3 col-md-4 col-lg-3 mt-2 ">
                                                <div className="date__box ">
                                                    <DatePicker
                                                        id='DateOfBirthTo'
                                                        name='DateOfBirthTo'
                                                        dateFormat="MM/dd/yyyy"
                                                        onChange={(date) => setValue({ ...value, ['DateOfBirthTo']: date ? getShowingWithOutTime(date) : "" })}
                                                        isClearable={value.DateOfBirthTo ? true : false}
                                                        selected={value?.DateOfBirthTo && new Date(value.DateOfBirthTo)}
                                                        placeholderText={'Select...'}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        autoComplete='Off'
                                                        maxDate={new Date()}
                                                        minDate={new Date(value.DateOfBirthFrom)}
                                                    />
                                                    <label htmlFor="">DOB To</label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    {/* <div className="form-check mt-2  ">
                                        <input className="form-check-input " type="checkbox" value="Display" name="Display" id="flexRadioDefault2" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                            Display Height & Weight
                                        </label>
                                    </div> */}
                                </div>
                                <div className="col-12 col-md-12 col-lg-12 mt-2 pt-1 text-right">
                                    <button className="btn btn-sm bg-green text-white px-2 py-1" onClick={() => { get_ArrestSearchData(); }} >Show Report</button>
                                    <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" onClick={() => { setverifyArrestMaster(false); resetFields(); }}>Clear</button>
                                    <Link to={'/Reports'}>
                                        <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" >Close</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                verifyArrestMaster &&
                <>
                    <div className="container mt-1" ref={componentRef}>
                        <div className="row" style={{ border: '1px solid #80808085' }}>
                            <>
                                <div className="col-4 col-md-3 col-lg-2">
                                    <div className="main">
                                        <img src={multiImage} className="img-fluid" alt='Agency_Photo' style={{ border: '1px solid aliceblue', marginTop: '4px', width: '150px' }} />
                                    </div>
                                </div>
                                <div className="col-7  col-md-7 col-lg-9 mt-4 pt-1 ml-5">
                                    <div className="main">
                                        <h5 className='text-dark text-bold'>{arestReportData?.Agency_Name} Test Test Test</h5>
                                        <p className='text-p'>Address: <span className=''>{arestReportData?.Agency_Address1}Test</span></p>
                                        <div className='d-flex '>
                                            <p className='text-p'>State: <span className='new-span '>{arestReportData?.StateName}</span>
                                            </p>
                                            <p className='text-p ml-5 pl-1'>City: <span className='new-span  '>{arestReportData?.CityName}</span>
                                            </p>
                                            <p className='text-p ml-2'>Zip: <span className='new-span  '>{arestReportData?.Agency_ZipId}</span>
                                            </p>
                                        </div>
                                        <div className='d-flex'>
                                            <p className='text-p'>Phone: <span className='new-span  '>{arestReportData?.Agency_Phone}</span></p>
                                            <p className='text-p ml-3 '>Fax: <span className='new-span  '> {arestReportData?.Agency_Fax}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </>
                            <div className="col-12">
                                <hr style={{ border: '1px solid rgb(3, 105, 184)' }} />
                            </div>
                            {/* <div className="container mt-1" ref={componentRef} >
                                <div className="row" style={{ border: '1px solid #80808085' }}>
                                    {
                                        arestReportData[0]?.Agency.length > 0 ?
                                            <>
                                                <div className="col-3 ">
                                                    <div className="main">
                                                        <img src={arestReportData[0]?.Agency[0]?.Agency_Photo} className="img-fluid" alt />
                                                    </div>
                                                </div>
                                                <div className="col-8 mt-4">
                                                    <div className="main">
                                                        <h5 className='text-dark text-bold'>{arestReportData[0]?.Agency[0]?.Agency_Name}</h5>
                                                        <p>{arestReportData[0]?.Agency[0]?.Agency_Address1}</p>
                                                        <p>{arestReportData[0]?.Agency[0]?.Agency_Phone}</p>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="col-3 ">
                                                    <div className="main">
                                                        <img src={img} className="img-fluid" alt />
                                                    </div>
                                                </div>
                                                <div className="col-8 mt-4">
                                                    <div className="main">
                                                        <h5 className='text-dark text-bold'>{"Agency_Name"}</h5>
                                                        <p>{"Agency_Address"}</p>
                                                        <p>{"Agency_Phone"}</p>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                    <div className="col-12">
                                        <hr style={{ border: '1px solid rgb(3, 105, 184)' }} />
                                    </div>
                                    <div className="container">
                                        <div className="col-12">

                                            <div className="table-responsive" style={{ border: '1px solid #ddd' }}>

                                                <div className="table-responsive" >
                                                    <div className="text-white text-bold bg-green py-1 px-3  d-flex justify-content-between align-items-center">
                                                        <p className="p-0 m-0 d-flex align-items-center">Arrest Information:</p>
                                                        <p className="p-0 m-0 d-flex align-items-center" >
                                                            {getShowingDateText(arestReportData[0]?.ArrestDtTm)}
                                                        </p>
                                                    </div>
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <h6 className='text-dark text-bold'>Arrest Number</h6>
                                                                    <p>{arestReportData[0]?.ArrestNumber}</p>
                                                                </td>
                                                                <td colSpan={2}>
                                                                    <h6 className='text-dark text-bold'>Arrestee Name</h6>
                                                                    <p>{arestReportData[0]?.Arrestee_Name}</p>
                                                                </td>
                                                                <td >
                                                                    <h6 className='text-dark text-bold'>Arrest Date</h6>
                                                                    <p>{getShowingDateText(arestReportData[0]?.ArrestDtTm)}</p>
                                                                </td>
                                                                <td >
                                                                    <h6 className='text-dark text-bold'>ArrestType Description</h6>
                                                                    <p>{arestReportData[0]?.ArrestType_Description}</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="table-responsive" style={{ border: '1px solid #ddd' }}>
                                                    {
                                                        arestReportData[0]?.Charge.length > 0 ?
                                                            <>
                                                                <h5 className="text-white text-bold bg-green py-1 px-3" >Charge:</h5>

                                                                <table className="table table-bordered" >
                                                                    <thead className='text-dark master-table'>
                                                                        <tr>
                                                                            <th className=''>ArrestNumber</th>
                                                                            <th className=''>ChargeCode Description</th>
                                                                            <th className=''>IncidentNumber</th>
                                                                            <th className=''>NIBRS_Description</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='master-tbody'>
                                                                        {
                                                                            arestReportData[0]?.Charge?.map((item, key) => (
                                                                                <>
                                                                                    <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                        <td>{item.ArrestNumber}</td>
                                                                                        <td>{item.ChargeCode_Description}</td>
                                                                                        <td>{item.IncidentNumber}</td>
                                                                                        <td>{item.NIBRS_Description}</td>
                                                                                    </tr>
                                                                                </>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                                <div className="table-responsive" style={{ border: '1px solid #ddd' }}>
                                                
                                                    {
                                                        arestReportData[0]?.ArrestNarrative.length > 0 ?
                                                            <>
                                                                <h5 className="text-white text-bold bg-green py-1 px-3" >Narrative:</h5>
                                                       
                                                                <table className="table table-bordered" >
                                                                    <thead className='text-dark master-table'>
                                                                        <tr>
                                                                            <th className=''>Narrative Description</th>
                                                                            <th className=''>ReportedBy_Description</th>
                                                                            <th className=''>Narrative Date</th>
                                                                            <th className=''>Narrative Comments</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='master-tbody'>
                                                                        {
                                                                            arestReportData[0]?.ArrestNarrative?.map((item, key) => (
                                                                                <>
                                                                                    <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                        <td>{item.NarrativeDescription}</td>
                                                                                        <td>{item.ReportedBy_Description}</td>
                                                                                        <td>{getShowingDateText(item.NarrativeDtTm)}</td>
                                                                                        <td>{item.NarrativeComments}</td>

                                                                                    </tr>
                                                                                </>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>

                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                                <div className="table-responsive" style={{ border: '1px solid #ddd' }}>
                                                   
                                                    {
                                                        arestReportData[0]?.ArrestComments.length > 0 ?
                                                            <>
                                                                <h5 className="text-white text-bold bg-green py-1 px-3" >Comments:</h5>
                                                              
                                                                <table className="table table-bordered" style={{ marginTop: '-15px' }}>
                                                                    <thead className='text-dark master-table'>
                                                                        <tr>
                                                                            <th className=''>Comments</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='master-tbody'>
                                                                        {
                                                                            arestReportData[0]?.ArrestComments?.map((item, key) => (
                                                                                <>
                                                                                    <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                        <td>{item.Comments}</td>

                                                                                    </tr>
                                                                                </>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>

                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                                <div className="table-responsive" style={{ border: '1px solid #ddd' }}>
                                              
                                                    {
                                                        arestReportData[0]?.ArrestProperty.length > 0 ?
                                                            <>
                                                                <h5 className="text-white text-bold bg-green py-1 px-3" >Property:</h5>
                                                             
                                                                <table className="table table-bordered" >
                                                                    <thead className='text-dark master-table'>
                                                                        <tr>
                                                                            <th className=''>Property Number</th>
                                                                            <th className=''>PropertyType_Description</th>
                                                                            <th className=''>Reported Date</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='master-tbody'>
                                                                        {
                                                                            arestReportData[0]?.ArrestProperty?.map((item, key) => (
                                                                                <>
                                                                                    <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                        <td>{item.PropertyNumber}</td>
                                                                                        <td>{item.PropertyType_Description}</td>
                                                                                        <td>{getShowingDateText(item.ReportedDtTm)}</td>

                                                                                    </tr>
                                                                                </>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>

                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                                <div className="table-responsive" style={{ border: '1px solid #ddd' }}>
                                           
                                                    {
                                                        arestReportData[0]?.ArrestCourtInformation.length > 0 ?
                                                            <>
                                                                <h5 className="text-white text-bold bg-green py-1 px-3" >Court Information:</h5>
                                                                <table className="table table-bordered" >
                                                                    <thead className='text-dark master-table'>
                                                                        <tr>
                                                                            <th className=''>Name</th>
                                                                            <th className=''>Attorney</th>
                                                                            <th className=''>Prosecutor</th>
                                                                            <th className=''>Appear DateTime</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='master-tbody'>
                                                                        {
                                                                            arestReportData[0]?.ArrestCourtInformation?.map((item, key) => (
                                                                                <>
                                                                                    <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                        <td>{item.Name}</td>
                                                                                        <td>{item.Attorney}</td>
                                                                                        <td>{item.Prosecutor}</td>
                                                                                        <td>{getShowingDateText(item.AppearDateTime)}</td>
                                                                                    </tr>
                                                                                </>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>

                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                </div>




                                                <div className="table-responsive" style={{ border: '1px solid #ddd' }}>
                                                
                                                    {
                                                        arestReportData[0]?.ArrestCriminalActivity.length > 0 ?
                                                            <>
                                                                <h5 className="text-white text-bold bg-green py-1 px-3" >Criminal Activity:</h5>

                                                                <table className="table table-bordered" >
                                                                    <thead className='text-dark master-table'>
                                                                        <tr>
                                                                            <th className=''>CriminalActivity_Description</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='master-tbody'>
                                                                        {
                                                                            arestReportData[0]?.ArrestCriminalActivity?.map((item, key) => (
                                                                                <>
                                                                                    <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                        <td>{item.CriminalActivity_Description}</td>

                                                                                    </tr>
                                                                                </>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>

                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                                <div className="table-responsive" style={{ border: '1px solid #ddd' }}>
                                           
                                                    {
                                                        arestReportData[0]?.ArrestPoliceForce.length > 0 ?
                                                            <>
                                                                <h5 className="text-white text-bold bg-green py-1 px-3" >Police Force:</h5>

                                                                <table className="table table-bordered">
                                                                    <thead className='text-dark master-table'>
                                                                        <tr>
                                                                            <th className=''>Officer Name</th>
                                                                            <th className=''>PoliceForce_Description</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='master-tbody'>
                                                                        {
                                                                            arestReportData[0]?.ArrestPoliceForce?.map((item, key) => (
                                                                                <>
                                                                                    <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                        <td>{item.Officer_Name}</td>
                                                                                        <td>{item.ArrPoliceForce_Description}</td>

                                                                                    </tr>
                                                                                </>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>

                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                                <div className="table-responsive" style={{ border: '1px solid #ddd' }}>
                                              
                                                    <h5 className="text-white text-bold bg-green py-1 px-3" >Arrest Image:
                                                    </h5>

                                                    <table className="table table-bordered" >
                                                        <tbody className=''>
                                                            <div className="row">
                                                                {
                                                                    arestReportData[0]?.ArrestPhoto
                                                                        .length > 0 ?
                                                                        arestReportData[0]?.ArrestPhoto
                                                                            ?.map((item, index) => {
                                                                        
                                                                                return (
                                                                                    <div className="col-3">
                                                                                        <div className="main" key={index}>
                                                                                            <img src={item.Photo} style={{ height: '200px' }} />
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        :
                                                                        <>
                                                                        </>
                                                                }
                                                            </div>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>  */}

                            <div className="container mt-1">
                                <div className="col-12">

                                    {
                                        arestReportData?.Charge?.length > 0 ?

                                            <>
                                                {
                                                    arestReportData?.Charge?.map((obj) => (
                                                        <div className="table-responsive" >
                                                            <h5 className="text-center">Arrest Master Report</h5>
                                                            <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                                                <p className="p-0 m-0 d-flex align-items-center">Arrest Information</p>
                                                            </div>
                                                            <table className="table table-bordered">
                                                                <tbody>
                                                                    <tr>
                                                                        <td >
                                                                            <h6 className='text-dark text-bold'>Arrest ID:</h6>
                                                                            <p className='text-list'>{obj?.ArrestID}</p>
                                                                        </td>
                                                                        <td >
                                                                            <h6 className='text-dark text-bold'>Arrest Number</h6>
                                                                            <p className='text-list'>{obj?.ArrestNumber}</p>
                                                                        </td>
                                                                        <td >
                                                                            <h6 className='text-dark text-bold'>Arrest Type</h6>
                                                                            <p className='text-list'>{obj?.ArrestType_Description}</p>
                                                                        </td>
                                                                        <td>
                                                                            <h6 className='text-dark text-bold'>ArrestDtTm:</h6>
                                                                            <p className='text-list'>{obj?.ArrestDtTm}</p>
                                                                        </td>
                                                                        <td >
                                                                            <h6 className='text-dark text-bold'>Arrestee Name</h6>
                                                                            <p className='text-list'>{obj?.Arrestee_Name}</p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td >
                                                                            <h6 className='text-dark text-bold'>Address:</h6>
                                                                            <p className='text-list'>sdfdfgdgdfg</p>

                                                                        </td>
                                                                        <td >
                                                                            <h6 className='text-dark text-bold'>SSN:</h6>
                                                                            <p className='text-list'>54664465</p>
                                                                        </td>
                                                                        <td >
                                                                            <h6 className='text-dark text-bold'>Agency Name</h6>
                                                                            <p className='text-list'>{obj?.Agency_Name}</p>
                                                                        </td>
                                                                        <td>
                                                                            <h6 className='text-dark text-bold'>ArresteeID</h6>
                                                                            <p className='text-list'>{obj?.ArresteeID}</p>

                                                                        </td>
                                                                        <td>
                                                                            <h6 className='text-dark text-bold'>Supervisor Name</h6>
                                                                            <p className='text-list'>{obj?.Supervisor_Name}</p>

                                                                        </td>
                                                                    </tr>


                                                                    {/* <tr> */}
                                                                    {/* <td colSpan={2}>
                                                            <h6 className='text-dark text-bold'>Race:</h6>
                                                            <p className='text-list'>jsfhdggu</p>
                                                        </td> */}

                                                                    {/* <td colSpan={3}>
                                                            <h6 className='text-dark text-bold'>Alias Name:</h6>
                                                            <p className='text-list'>fsdfsf</p>
    
                                                        </td> */}

                                                                    {/* <td colSpan={2}>
                                                            <h6 className='text-dark text-bold'>Identification Type:</h6>
                                                            <p className='text-list'>sdfsdfs</p>
    
                                                        </td>
                                                        <td colSpan={2}>
                                                            <h6 className='text-dark text-bold'>Identification Number:</h6>
                                                            <p className='text-list'>16546540</p>
    
                                                        </td>
                                                        <td colSpan={2}>
                                                            <h6 className='text-dark text-bold'>DL Number:</h6>
                                                            <p className='text-list'>105466</p>
    
                                                        </td>
    
                                                    </tr> */}
                                                                    <tr>

                                                                        {/* <td colSpan={2}>
                                                            <h6 className='text-dark text-bold'>Weapon:</h6>
                                                            <p className='text-list'>sfdd</p>
                                                        </td> */}
                                                                        {/* <td colSpan={2}>
                                                            <h6 className='text-dark text-bold'>Ethnicity:</h6>
                                                            <p className='text-list'>fghg</p>
                                                        </td> */}

                                                                        {/* <td colSpan={2}>
                                                            <h6 className='text-dark text-bold'>Birth Place:</h6>
                                                            <p className='text-list'>sdfsdfsdf</p>
    
                                                        </td> */}


                                                                        {/* <td colSpan={2}>
                                                            <h6 className='text-dark text-bold'>Marital Status:</h6>
                                                            <p className='text-list'>sdfd</p>
    
                                                        </td> */}
                                                                        {/* <td colSpan={2}>
                                                            <h6 className='text-dark text-bold'>Phone Number:</h6>
                                                            <p className='text-list'>14564540</p>
    
                                                        </td> */}
                                                                    </tr>
                                                                    <tr>

                                                                        {/* <td colSpan={2}>
                                                            <h6 className='text-dark text-bold'>Height:</h6>
                                                            <p className='text-list'>10</p>
                                                        </td> */}

                                                                        {/* <td colSpan={2}>
                                                            <h6 className='text-dark text-bold'>Weight:</h6>
                                                            <p className='text-list'>54</p>
                                                        </td> */}
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                            :
                                            <>
                                            </>
                                    }


                                    {/* <div className="table-responsive">
                                        <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                            <p className="p-0 m-0 d-flex align-items-center">Name Alias Information</p>
                                        </div>
                                        <table className="table ">
                                            <thead className='text-dark master-table'>
                                                <tr>
                                                    <th className=''>Alias Name</th>
                                                    <th className=''>DOB</th>
                                                    <th className=''>SSN</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className='text-list'>1246855</td>
                                                    <td className='text-list'>{getShowingDateText()}</td>
                                                    <td className='text-list'>dsfsdf</td>
                                                </tr>
                                            </tbody>

                                        </table>
                                        <hr />
                                    </div> */}
                                    {/* Name Image */}


                                    {/* <div className="table-responsive mt-2" style={{ border: '1px solid #ddd' }}>
                                        <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                            <p className="p-0 m-0 d-flex align-items-center">Name Image Information</p>
                                        </div>
                                        <table className="table table-bordered" >
                                            <tbody className=''>
                                                <div className="row">
                                                    {
                                                        arestReportData[0]?.ArrestPhoto
                                                            .length > 0 ?
                                                            arestReportData[0]?.ArrestPhoto
                                                                ?.map((item, index) => {

                                                                    return (
                                                                        <div className="col-3">
                                                                            <div className="main" key={index}>
                                                                                <img src={item.Photo} style={{ height: '200px' }} />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            :
                                                            <>
                                                            </>
                                                    }
                                                </div>
                                            </tbody>
                                        </table>
                                    </div> */}

                                    {/* SMT */}

                                    {/* <div className="table-responsive mt-1" >

                                        <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                            <p className="p-0 m-0 d-flex align-items-center">SMT Information</p>
                                        </div>
                                        <table className="table table-bordered" >
                                            <thead className='text-dark master-table'>
                                                <tr>
                                                    <th className=''>SMT Location</th>
                                                </tr>
                                            </thead>
                                            <tbody className='master-tbody'>
                                                {
                                                    arestReportData[0]?.ArrestNarrative?.map((item, key) => (
                                                        <>
                                                            <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                <td className='text-list'>{item.SMTLocation}</td>
                                                            </tr>
                                                        </>
                                                    ))
                                                }
                                            </tbody>
                                        </table>

                                    </div> */}
                                    {/* Arrest */}
                                    <div className="table-responsive mt-1" >
                                        <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                            <p className="p-0 m-0 d-flex align-items-center">Arrest Information</p>
                                        </div>
                                        <table className="table table-bordered" >
                                            <thead className='text-dark master-table'>
                                                <tr>
                                                    <th className='' style={{ width: '100px' }}>Arresting Agency Name</th>
                                                    <th className='' style={{ width: '100px' }}>Arrest Date/Time</th>
                                                    <th className='' style={{ width: '100px' }}>Arrest Number</th>
                                                    <th className='' style={{ width: '100px' }}>Arrest Type</th>
                                                    <th className='' style={{ width: '100px' }}>Supervisor Name</th>
                                                </tr>
                                            </thead>
                                            <tbody className='master-tbody'>
                                                {
                                                    arestReportData[0]?.ArrestNarrative?.map((item, key) => (
                                                        <>
                                                            <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                <td className='text-list' style={{ width: '100px' }}>{item.Agency}</td>
                                                                <td className='text-list' style={{ width: '100px' }}>{item.NarrativeDescription}</td>
                                                                <td className='text-list' style={{ width: '100px' }}>{item.ReportedBy_Description}</td>
                                                                <td className='text-list' style={{ width: '100px' }}>{getShowingDateText(item.NarrativeDtTm)}</td>
                                                                <td className='text-list' style={{ width: '100px' }}>{item.NarrativeComments}</td>

                                                            </tr>
                                                        </>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* charge */}
                                    {/* <div className="table-responsive mt-1" >
                                        <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                            <p className="p-0 m-0 d-flex align-items-center">Charge Information</p>
                                        </div>
                                        <table className="table table-bordered" >
                                            <thead className='text-dark master-table'>
                                                <tr>
                                                    <th className='' style={{ width: '100px' }}>Incident Number</th>
                                                    <th className='' style={{ width: '100px' }}>NIBRS Code</th>
                                                    <th className='' style={{ width: '100px' }}>Charge Code</th>
                                                    <th className='' style={{ width: '100px' }}>Total Penalty</th>
                                                    <th className='' style={{ width: '100px' }}>Sentence</th>
                                                </tr>
                                            </thead>
                                            <tbody className='master-tbody'>
                                                {
                                                    arestReportData[0]?.ArrestNarrative?.map((item, key) => (
                                                        <>
                                                            <tr key={key} >
                                                                <td style={{ width: '100px' }}>{item.NarrativeDescription}</td>
                                                                <td style={{ width: '100px' }}>{item.ReportedBy_Description}</td>
                                                                <td style={{ width: '100px' }}>{getShowingDateText(item.NarrativeDtTm)}</td>
                                                                <td style={{ width: '100px' }}>{item.NarrativeComments}</td>

                                                            </tr>
                                                        </>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div> */}
                                    {/* court */}
                                    {/* <div className="table-responsive mt-2" >
                                        <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                            <p className="p-0 m-0 d-flex align-items-center">Court Information</p>
                                        </div>
                                        <table className="table table-bordered" >
                                            <thead className='text-dark master-table'>
                                                <tr>
                                                    <td colSpan={3}>
                                                        <h6 className='text-dark text-bold'>Name:</h6>
                                                        <p className='text-list'>asdf</p>
                                                    </td>
                                                    <td colSpan={3}>
                                                        <h6 className='text-dark text-bold'>Court Name:</h6>
                                                        <p className='text-list'>asdf</p>
                                                    </td>
                                                    <td colSpan={3}>
                                                        <h6 className='text-dark text-bold'> Judge Name:</h6>
                                                        <p className='text-list'>asdf</p>
                                                    </td>
                                                    <td colSpan={3}>
                                                        <h6 className='text-dark text-bold'>Attorney:</h6>
                                                        <p className='text-list'>asdf</p>
                                                    </td>
                                                    <td colSpan={3}>
                                                        <h6 className='text-dark text-bold'>Prosecutor:</h6>
                                                        <p className='text-list'>asdf</p>

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}>
                                                        <h6 className='text-dark text-bold'>Appear Date/Time:</h6>
                                                        <p className='text-list'>asdf</p>

                                                    </td>
                                                    <td colSpan={3}>
                                                        <h6 className='text-dark text-bold'>Plea:</h6>
                                                        <p className='text-list'>asdf</p>

                                                    </td>
                                                    <td colSpan={3}>
                                                        <h6 className='text-dark text-bold'>Plea Date/Time:</h6>
                                                        <p className='text-list'>asdf</p>

                                                    </td>
                                                    <td colSpan={3}>
                                                        <h6 className='text-dark text-bold'>Appear Date/Time:</h6>
                                                        <p className='text-list'>asdf</p>

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={12}>
                                                        <h6 className='text-dark text-bold'>Court Appear Reason:</h6>
                                                        <p className='text-list'>asdf</p>

                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody className='master-tbody'>
                                        {
                                            arestReportData[0]?.ArrestCourtInformation?.map((item, key) => (
                                                <>
                                                    <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                        <td>{item.Name}</td>
                                                        <td>{item.CourtName}</td>
                                                        <td>{item.JudgeName}</td>
                                                        <td>{item.Attorney}</td>
                                                        <td>{item.Prosecutor}</td>
                                                        <td>{item.CourtAppearReason}</td>
                                                        <td>{getShowingDateText(item.AppearDateTime)}</td>
                                                    </tr>
                                                </>
                                            ))
                                        }
                                    </tbody>
                                        </table>
                                    </div> */}
                                    {/* Narrative */}
                                    {/* <div className="table-responsive" >
                                        <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                            <p className="p-0 m-0 d-flex align-items-center">Narrative Information</p>
                                        </div>
                                        <table className="table table-bordered" >
                                            <thead className='text-dark master-table'>
                                                <tr>
                                                    <th className=''>Arrest Date/Time</th>
                                                    <th className=''>Reported By</th>
                                                    <th className=''>Narrative Type</th>
                                                    <th className=''>Comments</th>
                                                </tr>
                                            </thead>
                                            <tbody className='master-tbody'>
                                                {
                                                    arestReportData[0]?.ArrestNarrative?.map((item, key) => (
                                                        <>
                                                            <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                <td>{item.NarrativeDescription}</td>
                                                                <td>{item.ReportedBy_Description}</td>
                                                                <td>{getShowingDateText(item.NarrativeDtTm)}</td>
                                                                <td>{item.NarrativeComments}</td>

                                                            </tr>
                                                        </>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default ArrestMaster

export const changeArrayFormat = (data, type) => {
    if (type === 'zip') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.zipId, label: sponsor.Zipcode })
        )
        return result
    }
    if (type === 'state') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.StateID, label: sponsor.StateName })
        )
        return result
    }
    if (type === 'city') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.CityID, label: sponsor.CityName })
        );
        return result
    }
}

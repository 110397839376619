// Import Component
import React, { useState, useEffect, useContext } from 'react'
import { AddDeleteUpadate, fetchPostData, ScreenPermision } from '../../../../hooks/Api'
import Select from "react-select";
import DataTable from 'react-data-table-component';
import { Decrypt_Id_Name, base64ToString, tableCustomStyles } from '../../../../Common/Utility';
import { toastifySuccess } from '../../../../Common/AlertMsg';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../../redux/actions/Agency';
import { useLocation } from 'react-router-dom';

const ScreenPermission = () => {

    const dispatch = useDispatch();
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';

    const [moduleFK, setModuleFK] = useState([])
    const [applicationScreen, setApplicationScreen] = useState([])
    const [groupFieldPermissions, setGroupFieldPermissions] = useState([])
    const [effectiveScreenPermission, setEffectiveScreenPermission] = useState([])
    const [pinID, setPinID] = useState('');

    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    const query = useQuery();
    var aId = query?.get("Aid");
    var aIdSta = query?.get("ASta");

    if (!aId) aId = 0;
    else aId = parseInt(base64ToString(aId));

    const [value, setValue] = useState({
        'ApplicationId': '', 'ModuleFK': '', 'AgencyID': '', 'screenid': '', 'GroupId': ''
    })

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (localStoreData) {
            setPinID(localStoreData?.PINID);
            get_ModuleFK('1');
            getScreenPermision(localStoreData?.AgencyID, localStoreData?.PINID);
        }
    }, [localStoreData]);

    // onChange Hooks Function
    const handleChange = (e, row) => {
        setValue({
            ...value,
            ['GroupId']: e.target.value
        });
        update_GroupField_Permissions(e, row)
    }

    // Get Effective Screeen Permission
    const getScreenPermision = (aId, pinID) => {
        ScreenPermision("A008", aId, pinID).then(res => {
            if (res) { console.log(res); setEffectiveScreenPermission(res); }
            else setEffectiveScreenPermission()
        });
    }

    const ModuleFKChange = (e) => {
        if (e) {
            setValue({ ...value, ['ModuleFK']: e.value, ['screenid']: '', })
            get_ApplicationScreen(e.value);
            setApplicationScreen([]);
            setGroupFieldPermissions([])
        } else {
            setValue({ ...value, ['ModuleFK']: null, ['screenid']: null, })
            setApplicationScreen([]);
            setGroupFieldPermissions([])
        }
    }

    const ApplicationScreenChange = (e) => {
        if (e) {
            setValue({ ...value, ['screenid']: e.value })
            get_GroupField_Permissions(e.value);
        } else {
            setValue({ ...value, ['screenid']: null })
            setGroupFieldPermissions([])
        }
    }

    // Get Module and Application Screen And Group Field permission
    const get_ModuleFK = (id) => {
        const val = { ApplicationId: id }
        fetchPostData('ScreenPermission/GetData_Module', val).then(res => {
            if (res) { setModuleFK(changeArrayFormat(res, 'modul')); }
            else setModuleFK()
        })
    }

    const get_ApplicationScreen = (id) => {
        const val = { ModuleFK: id, IsChield: 0 }
        fetchPostData('ScreenPermission/GetData_ApplicationScreen', val).then(res => {
            if (res) {
                console.log(res)
                setApplicationScreen(changeArrayFormat(res, 'screen'))
            } else {
                setApplicationScreen([]);
                setGroupFieldPermissions()
            }
        })
    }

    const get_GroupField_Permissions = (screenid) => {
        const val = { 'AgencyID': aId, 'screenid': screenid }
        fetchPostData('ScreenPermission/GetData_GroupScreenPermissions', val).then(res => {
            if (res) {
                setGroupFieldPermissions(res);
            } else {
                setGroupFieldPermissions()
            }
        })
    }

    // Update group field permission
    const update_GroupField_Permissions = (e, row) => {
        const { screenid, AgencyID } = value
        const val = {
            'Display': e.target.name === 'Display' ? e.target.checked : row.Display,
            'Add': e.target.name === 'AddOK' ? e.target.checked : row.AddOK,
            'Change': e.target.name === 'Change' ? e.target.checked : row.Change,
            'Delete': e.target.name === 'DeleteOK' ? e.target.checked : row.DeleteOK,
            'GroupID': e.target.value,
            'screenid': screenid,
            'AgencyId': aId,
            'ModifiedByUserFK': pinID,
        }
        console.log(val)
        AddDeleteUpadate('ScreenPermission/UpdateGroupScreenPermissions', val).then(res => {
            console.log(res)
            if (res?.success) {
                const parsedData = JSON.parse(res.data);
                toastifySuccess(parsedData?.Table?.[0]?.Message);
            }
            get_GroupField_Permissions(screenid)
        })
    }

    // Table Columns Array
    const columns = [
        {
            name: 'Group Name',
            selector: (row) => row.GroupName,
            sortable: true
        },
        {
            name: 'Display',
            selector: (row) => <input type="checkbox" checked={row.Display} value={row.GroupId} name='Display'
                disabled={effectiveScreenPermission ? effectiveScreenPermission[0]?.Changeok === 1 ? false : true : false}
                onChange={(e) => handleChange(e, row)} />,
            sortable: true
        },
        {
            name: 'Add',
            selector: (row) => <input type="checkbox" checked={row.AddOK} value={row.GroupId} name='AddOK'
                disabled={effectiveScreenPermission ? effectiveScreenPermission[0]?.Changeok === 1 ? false : true : false}
                onChange={(e) => handleChange(e, row)} />,
            sortable: true
        },
        {
            name: 'Change',
            selector: (row) => <input type="checkbox" checked={row.Change} value={row.GroupId} name='Change'
                disabled={effectiveScreenPermission ? effectiveScreenPermission[0]?.Changeok === 1 ? false : true : false}
                onChange={(e) => handleChange(e, row)} />,
            sortable: true
        },
        {
            name: 'Delete',
            selector: (row) => <input type="checkbox" checked={row.DeleteOK} value={row.GroupId} name='DeleteOK'
                disabled={effectiveScreenPermission ? effectiveScreenPermission[0]?.Changeok === 1 ? false : true : false}
                onChange={(e) => handleChange(e, row)} />,
            sortable: true
        }

    ]

    return (
        <div className="col-12 ">
            <div className="row ">
                <div className="col-2 col-md-2 col-lg-1 mt-2 pt-1">
                    <label htmlFor="" className='new-label'>Module</label>
                </div>
                <div className="col-10 col-md-4 col-lg-5 mt-2 ">
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        name="ModuleFK"
                        value={moduleFK?.filter((obj) => obj.value === value?.ModuleFK)}
                        options={moduleFK}
                        isClearable
                        onChange={ModuleFKChange}
                    />
                </div>
                <div className="col-2 col-md-2 col-lg-2 mt-2 pt-1">
                    <label htmlFor="" className='new-label'>Application Screen</label>
                </div>
                <div className="col-10 col-md-4 col-lg-4 mt-2 ">
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        name="screenid"
                        value={applicationScreen?.filter((obj) => obj.value === value?.screenid)}
                        options={applicationScreen}
                        isClearable
                        onChange={ApplicationScreenChange}
                    />
                </div>
            </div>
            <div className="col-12 mt-2">
                <DataTable
                    columns={columns}
                    data={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? groupFieldPermissions : '' : ''}
                    dense
                    paginationRowsPerPageOptions={[10, 15]}
                    highlightOnHover
                    noContextMenu
                    pagination
                    responsive
                    subHeaderAlign="right"
                    subHeaderWrap
                    showHeader={true}
                    persistTableHead={true}
                    customStyles={tableCustomStyles}
                    noDataComponent={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? "There are no data to display" : "You don’t have permission to view data" : 'There are no data to display'}
                />
            </div>
        </div>


    )
}

export default ScreenPermission

export const changeArrayFormat = (data, type) => {
    if (type === 'modul') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.ModulePK, label: sponsor.ModuleName, })
        )
        return result
    }
    if (type === 'screen') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.ScreenID, label: sponsor.Description, })
        )
        return result
    }
}

import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Select from "react-select";
import { AddDeleteUpadate, fetchPostData } from '../../../../../../hooks/Api';
import DataTable from 'react-data-table-component';
import { Comman_changeArrayFormat } from '../../../../../../Common/ChangeArrayFormat';
import { toastifySuccess } from '../../../../../../Common/AlertMsg';
import DeletePopUpModal from '../../../../../../Common/DeleteModal';
import { RequiredFieldIncident } from '../../../../../Utility/Personnel/Validation';
import { AgencyContext } from '../../../../../../../Context/Agency/Index';
import { components } from "react-select";


const Officer = (props) => {

  const { get_NameVictim_Count, } = useContext(AgencyContext);

  const { victimID, loginPinID,nameID, loginAgencyID, } = props
  const SelectedValue = useRef();
  const [officerDrpData, setOfficerDrpData] = useState();
  const [victimOfficerData, setVictimOfficerData] = useState();
  const [victimOfficerID, setVictimOfficerID] = useState();
  const [OfficerID, setOfficerID] = useState();
  const [typeOfSecurityEditVal, setTypeOfSecurityEditVal] = useState();

  const [value, setValue] = useState({
    'OfficerID': '',
    'NameID': nameID,
    'VictimID': victimID,
    'CreatedByUserFK': loginPinID,
  })
  const [multiSelected, setMultiSelected] = useState({
    OfficerID: null,
  })
  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
  const [errors, setErrors] = useState({
    'DropError': '',
  })

  const Reset = () => {
    setValue({
      ...value,
      'OfficerID': '',
    })
    setErrors({
      ...errors,
      'DropError': '',
    });
  }

  const check_Validation_Error = (e) => {
    if (RequiredFieldIncident(value.OfficerID)) {
      setErrors(prevValues => { return { ...prevValues, ['DropError']: RequiredFieldIncident(value.OfficerID) } })
    }
  }

  const { DropError } = errors

  // useEffect(() => {
  //   if (DropError === 'true') {
  //     Add_Victim_Officer();
  //   }
  // }, [DropError])
  useEffect(() => {
    if (typeOfSecurityEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['OfficerID']: typeOfSecurityEditVal } }) }
  }, [typeOfSecurityEditVal])

  useEffect(() => {
    get_Victim_Officer_Drp(victimID, loginAgencyID);
    get_Victim_Officer_Data(victimID);
  }, [victimID])

  const get_Victim_Officer_Data = () => {
    const val = {
      'VictimID': victimID,
    }
    fetchPostData('VictimOfficer/GetData_VictimOfficer', val).then((res) => {
      if (res) {
        setTypeOfSecurityEditVal(Comman_changeArrayFormat(res, 'VictimOfficerID', 'NameID', 'PretendToBeID', 'OfficerID', 'Officer_Name'));
      } else {
        setTypeOfSecurityEditVal([]);
      }
    })
  }

  const get_Victim_Officer_Drp = (victimID, loginAgencyID) => {
    const val = {
      'VictimID': victimID,
      'AgencyID': loginAgencyID,
    }
    fetchPostData('VictimOfficer/GetData_InsertVictimOfficer', val).then((data) => {
      if (data) {
        setOfficerDrpData(Comman_changeArrayFormat(data, 'PINID', 'OfficerName'))
      } else {
        setOfficerDrpData([])
      }
    })
  }

  // const Add_Victim_Officer = () => {
  //   AddDeleteUpadate('VictimOfficer/Insert_VictimOfficer', value).then((res) => {
  //     if (res) {
  //       toastifySuccess(res.Message)
  //       get_Victim_Officer_Data(victimID);
  //       get_Victim_Officer_Drp(victimID, loginAgencyID);
  //       get_NameVictim_Count(victimID)
  //       onClear()
  //       Reset();
  //       setErrors({
  //         'DropError': '',
  //       });
  //     }
  //   })
  // }

  // const Delete_victim_Officer = () => {
  //   const val = {
  //     'VictimOfficerID': victimOfficerID,
  //     'DeletedByUserFK': loginPinID,
  //   }
  //   AddDeleteUpadate('VictimOfficer/Delete_VictimOfficer', val).then((res) => {
  //     if (res) {
  //       toastifySuccess(res.Message);
  //       get_Victim_Officer_Drp(victimID, loginAgencyID);
  //       get_Victim_Officer_Data(victimID);
  //       get_NameVictim_Count(victimID)

  //     } else console.log("Somthing Wrong");
  //   })
  // }

  

  const ChangeDropDown = (e, name) => {
    if (e) {
      setValue({
        ...value,
        [name]: e.value
      })
    } else setValue({
      ...value,
      [name]: null
    })
  }

  const onClear = () => {
    SelectedValue?.current?.clearValue();
  };

  const columns = [
    {
      name: 'Description',
      selector: (row) => row.Officer_Name,
      sortable: true
    },
    {
      name: <p className='text-end' style={{ position: 'absolute', top: 8, left: '15px' }}>Delete</p>,
      cell: row =>
        <Link to={`#`} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" onClick={() => { setVictimOfficerID(row.VictimOfficerID) }} data-toggle="modal" data-target="#DeleteModal">
          <i className="fa fa-trash"></i>
        </Link>


    }
  ]
  const Officer = (multiSelected) => {
    setMultiSelected({
      ...multiSelected,
      OfficerID: multiSelected
    })
    const len = multiSelected.length - 1
    if (multiSelected?.length < typeOfSecurityEditVal?.length) {
      let missing = null;
      let i = typeOfSecurityEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(typeOfSecurityEditVal[--i])) ? missing : typeOfSecurityEditVal[i];
      }
      DelSertBasicInfo(missing.value, 'VictimOfficerID', 'VictimOfficer/Delete_VictimOfficer')
    } else {
      InSertBasicInfo(multiSelected[len].value, 'OfficerID', 'VictimOfficer/Insert_VictimOfficer')
    }
  }

  const InSertBasicInfo = (id, col1, url) => {
    const val = {
      'NameID': nameID,
    'VictimID': victimID,
      [col1]: id,
      'CreatedByUserFK': loginPinID,
    }
    AddDeleteUpadate(url, val).then((res) => {
      if (res) {
        console.log(res)
        toastifySuccess(res.Message);
        get_Victim_Officer_Drp(victimID, loginAgencyID);
        // get_IncidentTab_Count(incidentID);
        col1 === 'OfficerID' && get_Victim_Officer_Data();
      } else {
        console.log("Somthing Wrong");
      }
    })
  }

  const DelSertBasicInfo = (victimOfficerID, col1, url) => {
    const val = {
      [col1]: victimOfficerID,
      'DeletedByUserFK': loginPinID,
    }
    AddDeleteUpadate(url, val).then((res) => {
      if (res) {
        toastifySuccess(res.Message);
        get_Victim_Officer_Drp(victimID, loginAgencyID);
        // get_IncidentTab_Count(incidentID);
        col1 === 'victimOfficerID' && get_Victim_Officer_Data()
      } else {
        console.log("res");
      }
    })
  }
  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      minHeight: 60,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };
  return (
    <>
      <div className="col-12 " id='display-not-form'>
        <div className="col-12 col-md-12 mt-2 pt-1 p-0" >
          <div className="bg-line  py-1 px-2 d-flex justify-content-between align-items-center">
            <p className="p-0 m-0">Officer</p>
          </div>
        </div>
        <div className="row">
          <div className="col-2 col-md-2 col-lg-1 mt-3">
            <label htmlFor="" className='label-name '>Officer{errors.DropError !== 'true' ? (
              <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.DropError}</span>
            ) : null}</label>
          </div>
          <div className="col-4 col-md-4 col-lg-4  mt-2" >
            <Select
               options={officerDrpData}
              isClearable
             closeMenuOnSelect={false}
              placeholder="Select.."
              ref={SelectedValue}
              className="basic-multi-select"
              isMulti
              styles={customStylesWithOutColor}
              components={{ MultiValue, }}
              onChange={(e) => Officer(e)}
              value={multiSelected.OfficerID}
              name='OfficerID'
             
          
            />
          </div>
          {/* <div className="col-6 col-md-6 col-lg-4 mt-2">
            <div className='dropdown__box'>
              <Select
                name='OfficerID'
                styles={colourStyles}
                isClearable
                options={officerDrpData}
                onChange={(e) => { ChangeDropDown(e, 'OfficerID'); }}
                placeholder="Select.."
                ref={SelectedValue}
              />
              <label htmlFor="">Officer</label>
              {errors.DropError !== 'true' ? (
                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.DropError}</span>
              ) : null}
            </div>
          </div>
          <div className="col-2 col-md-6 col-lg-8    pl-3" style={{ marginTop: '22px' }}>
            <Link to=''>
              <button type="button" className="btn btn-md py-1 btn-success pl-2  text-center" onClick={() => { check_Validation_Error(); }}>Save</button>
            </Link>
          </div> */}
        </div>
      </div>
      {/* <div className="col-12">
        <DataTable
          columns={columns}
          data={victimOfficerData}
          dense
          pagination
          paginationPerPage={'3'}
          paginationRowsPerPageOptions={[3]}
          selectableRowsHighlight
          highlightOnHover
          noDataComponent={"There are no data to display"}
        />
      </div> */}
      {/* <DeletePopUpModal func ={Delete_victim_Officer} /> */}
    </>
  )
}

export default Officer
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Select from "react-select";
import { AddDeleteUpadate, fetchPostData } from '../../../../../../hooks/Api';
import DataTable from 'react-data-table-component';
import { threeColVictimInjuryArray, Comman_changeArrayFormatBasicInfo } from '../../../../../../Common/ChangeArrayFormat';
import { toastifySuccess } from '../../../../../../Common/AlertMsg';
import DeletePopUpModal from '../../../../../../Common/DeleteModal';
import { RequiredFieldIncident } from '../../../../../Utility/Personnel/Validation';
import { AgencyContext } from '../../../../../../../Context/Agency/Index';
import { tableCustomStyles } from '../../../../../../Common/Utility';
import { components } from "react-select";

const InjuryType = (props) => {
  const { get_NameVictim_Count, } = useContext(AgencyContext);

  const { victimID, nameID, loginPinID,incidentID, } = props
  const SelectedValue = useRef();
  //screen permission 
  const [injuryTypeDrp, setInjuryTypeDrp] = useState();

  const [injuryTypeData, setInjuryTypeData] = useState();
  const [nameEventInjury, setNameEventInjury] = useState();
  const [injuryTypeEditVal, setInjuryTypeEditVal] = useState();

  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const [value, setValue] = useState({
    'VictimInjuryID': '',
    'NameID': nameID,
    'VictimID': victimID,
    'CreatedByUserFK': loginPinID,
     'NameEventInjuryID' : '',



  })

  const [errors, setErrors] = useState({
    'DropError': '',
  })

  const Reset = () => {
    setValue({
      ...value,
      'VictimInjuryID': '',
    })
    setErrors({
      ...errors,
      ['DropError']: '',
    });
  }
  const [multiSelected, setMultiSelected] = useState({
    VictimInjuryID: null,
  })
  const check_Validation_Error = (e) => {
    if (RequiredFieldIncident(value.VictimInjuryID)) {
      setErrors(prevValues => { return { ...prevValues, ['DropError']: RequiredFieldIncident(value.VictimInjuryID) } })
    }
  }

 

  // Check All Field Format is True Then Submit 
  const { DropError } = errors

  // useEffect(() => {
  //   if (DropError === 'true') {
  //     Add_InjuryType_Offense();
  //   }
  // }, [DropError])
  useEffect(() => {
    if (victimID) {
      get_Data_InjuryType_Drp(incidentID,victimID);
    }
  }, [incidentID,victimID])

  // useEffect(() => {
  //   get_Data_InjuryType_Drp(incidentID,victimID);
  // }, [incidentID,victimID])

  useEffect(() => {
    if (victimID) { get_InjuryType_Data(); }
  }, [victimID])

  const get_InjuryType_Data = () => {
    const val = {
      'VictimID': victimID,
    }
    fetchPostData('InjuryVictim/GetData_InjuryVictim', val).then((res) => {
      if (res) {
        setInjuryTypeEditVal(Comman_changeArrayFormatBasicInfo(res, 'VictimInjuryID', 'NameID', 'PretendToBeID', 'NameEventInjuryID', 'VictimInjury_Description'));
        setInjuryTypeData(res)
      } else {
        setInjuryTypeEditVal([]);
      }
    })
  }

  const get_Data_InjuryType_Drp = (incidentID,victimID) => {
    const val = {
      'IncidentID':incidentID,
        'VictimID':victimID
    }
    fetchPostData('InjuryVictim/GetData_InsertVictimInjury', val).then((data) => {
      if (data) {
        setInjuryTypeDrp(threeColVictimInjuryArray(data, 'VictimInjuryID', 'Description','InjuryCode'))
      } else {
        setInjuryTypeDrp([])
      }
    })
  }

  // const Add_InjuryType_Offense = () => {
  //   AddDeleteUpadate('InjuryVictim/Insert_VictimInjury', value).then((res) => {
  //     if (res) {
  //       toastifySuccess(res.Message)
  //       get_InjuryType_Data(victimID);
  //       get_Data_InjuryType_Drp(victimID);
  //       get_NameVictim_Count(victimID)
  //       onClear()
  //       Reset();
  //       setErrors({
  //         'DropError': '',
  //       });
  //     }
  //   })
  // }

  // const DeleteMethodOfOperation = () => {
  //   const val = {
  //     'NameEventInjuryID': nameEventInjuryID,
  //     'DeletedByUserFK': LoginPinID,
  //   }
  //   AddDeleteUpadate('InjuryVictim/Delete_VictimInjury', val).then((res) => {
  //     if (res) {
  //       toastifySuccess(res.Message);
  //       get_Data_InjuryType_Drp(victimID);
  //       get_InjuryType_Data(victimID);
  //       get_NameVictim_Count(victimID)

  //     } else console.log("Somthing Wrong");
  //   })
  // }

  const ChangeDropDown = (e, name) => {
    if (e) {
      setValue({
        ...value,
        [name]: e.value
      })
    } else {
      setValue({
        ...value,
        [name]: null
      })
    }
  }

  const onClear = () => {
    SelectedValue?.current?.clearValue();
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles, backgroundColor: "#fce9bf",
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };
  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      minHeight: 60,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };
  useEffect(() => {
    if (injuryTypeEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['VictimInjuryID']: injuryTypeEditVal } }) }
  }, [injuryTypeEditVal])

  const InjuryType = (multiSelected) => {
    setMultiSelected({
      ...multiSelected,
      VictimInjuryID: multiSelected
    })
    const len = multiSelected.length - 1
    if (multiSelected?.length < injuryTypeEditVal?.length) {
      let missing = null;
      let i = injuryTypeEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(injuryTypeEditVal[--i])) ? missing : injuryTypeEditVal[i];
      }
      DelSertBasicInfo(missing.id, 'nameEventInjuryID', 'InjuryVictim/Delete_VictimInjury')
    } else {
      InSertBasicInfo(multiSelected[len].value, 'VictimInjuryID', 'InjuryVictim/Insert_VictimInjury')
    }
  }

  
  
  const InSertBasicInfo = (id, col1, url) => {
    const val = {
      'NameID': nameID,
      'VictimID': victimID,
      [col1]: id,
      'CreatedByUserFK': loginPinID,
    }
    AddDeleteUpadate(url, val).then((res) => {
      if (res) {
        console.log(res)
        toastifySuccess(res.Message);
        // get_IncidentTab_Count(incidentID);
        col1 === 'VictimInjuryID' && get_InjuryType_Data();
      } else {
        console.log("Somthing Wrong");
      }
    })
  }

  const DelSertBasicInfo = (nameEventInjuryID, col1, url) => {
    const val = {
      [col1]: nameEventInjuryID,
      'DeletedByUserFK': loginPinID,
    }
    AddDeleteUpadate(url, val).then((res) => {
      if (res) {
        console.log(res);
        toastifySuccess(res.Message);
        // get_IncidentTab_Count(incidentID);
        col1 === 'VictimInjuryID' && get_InjuryType_Data()
      } else {
        console.log("res");
      }
    })
  }
  return (
    <>
      <div className="col-12 " id='display-not-form'>
        <div className="col-12 col-md-12 mt-2 pt-1 p-0" >
          <div className="bg-line  py-1 px-2 d-flex justify-content-between align-items-center">
            <p className="p-0 m-0">Injury Type</p>
          </div>
        </div>
        <div className="row">
          <div className="col-2 col-md-2 col-lg-1 mt-3">
            <Link to={'/ListManagement?page=Injury%20Type&call=/Name-Home'} className='new-link'>
              Injury Type  {errors.DropError !== 'true' ? (
                <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.DropError}</p>
              ) : null}
            </Link>
          </div>
          <div className="col-4 col-md-4 col-lg-4  mt-2" >
            <Select
              name='VictimInjuryID'
              isClearable
              options={injuryTypeDrp}
              closeMenuOnSelect={false}
              // onChange={(e) => { ChangeDropDown(e, 'VictimInjuryID'); }}
              placeholder="Select.."
              ref={SelectedValue}
              components={{ MultiValue, }}
              onChange={(e) => InjuryType(e)}
              value={multiSelected.VictimInjuryID}
              className="basic-multi-select"
              isMulti
              styles={customStylesWithOutColor}
            />
          </div>
          {/* <div className="col-2 col-md-6 col-lg-7 mt-2 pt-1   pl-3">
            <Link to=''>
              <button type="button" className="btn btn-md py-1 btn-success pl-2  text-center" onClick={() => { check_Validation_Error(); }} >Save</button>
            </Link>
          </div> */}
        </div>
      </div>
      {/* <div className="col-12 mt-3">
        <DataTable
          columns={columns}
          data={injuryTypeData}
          dense
          pagination
          paginationPerPage={'3'}
          paginationRowsPerPageOptions={[3]}
          selectableRowsHighlight
          highlightOnHover
          noDataComponent={"There are no data to display"}
          customStyles={tableCustomStyles}
          fixedHeader
          fixedHeaderScrollHeight='200px'
        />
      </div> */}
      {/* <DeletePopUpModal func={DeleteMethodOfOperation} /> */}
    </>
  )
}

export default InjuryType
import React, { memo, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Decrypt_Id_Name, Encrypted_Id_Name, getShowingDateText } from '../../Common/Utility';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { PropertySearch_Modal_Status, Property_Search_Data } from '../../../redux/actionTypes';
import { fetchPostData } from '../../hooks/Api';
import { toastifyError } from '../../Common/AlertMsg';
import { get_PropertyLossCode_Drp_Data } from '../../../redux/actions/DropDownsData';

const PropertySearchTab = (props) => {

    const { get_PropertyArticle_Single_Data, get_PropertyBoat_Single_Data, get_PropertOther_Single_Data, get_PropertySecurity_Single_Data, get_PropertyWeapon_Single_Data, searchModalState, setSearchModalState, mainIncidentID, value, setValue, loginPinID, loginAgencyID, MstPage, setPropertOther, setPropertyBoat, setPropertyWeapon, setPropertySecurity, setPropertyArticle, setLossCode, PropertyCategory, PropertyClassification, setPropertyNumber } = props
    const dispatch = useDispatch();
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const propertySearchType = useSelector((state) => state.Property.propertySearchType);
    const propertyTypeData = useSelector((state) => state.DropDown.propertyTypeData);
    const propertySearchData = useSelector((state) => state.Property.propertySearchData);
    const propertySearchModalStatus = useSelector((state) => state.Property.propertySearchModalStatus);
    const propertyLossCodeDrpData = useSelector((state) => state.DropDown.propertyLossCodeDrpData);

    const [editval, setEditValue] = useState([]);

    const ArticleCol = [
        {
            name: 'Property Number',
            selector: (row) => <>{row?.PropertyNumber} </>,
            sortable: true
        },
        {
            name: 'Incident Number',
            selector: (row) => <>{row?.IncidentNumber} </>,
            sortable: true
        },
        {
            name: 'Property Type',
            selector: (row) => row.PropertyType,
            sortable: true
        },
        // {
        //     name: 'Property Description',
        //     selector: (row) => row.PropertyDescription,
        //     sortable: true
        // },
        // {
        //     name: 'Property Reason',
        //     selector: (row) => row.PropertyReason,
        //     sortable: true
        // },
        {
            name: 'Loss Code',
            selector: (row) => row.PropertyLossCode_Description,
            sortable: true
        },
        {
            name: 'Brand',
            selector: (row) => row.Brand,
            sortable: true
        },
        {
            name: 'Model',
            selector: (row) => row.ModelID,
            sortable: true
        },
        {
            name: 'Serial',
            selector: (row) => row.SerialID,
            sortable: true
        },
        // {
        //     name: 'Reported Date/Time',
        //     selector: (row) => row.ReportedDate ? getShowingDateText(row.ReportedDate) : '',
        //     sortable: true
        // },
        // {
        //     name: 'Property Value',
        //     selector: (row) => row.PropertyValue,
        //     sortable: true
        // },
        // {
        //     name: 'Owner',
        //     selector: (row) => row.Owner_Description,
        //     sortable: true
        // },
        // {
        //     name: 'Brand',
        //     selector: (row) => row.Brand,
        //     sortable: true
        // },

        // {
        //     name: 'Misc Description',
        //     selector: (row) => row.Misc_Description,
        //     sortable: true
        // },
        // {
        //     name: 'Destroy Date/Time',
        //     selector: (row) => row.DestroyDate ? getShowingDateText(row.DestroyDate) : '',
        //     sortable: true
        // },
        // {
        //     name: 'Is Recovered',
        //     selector: (row) => row.IsRecovered,
        //     sortable: true
        // },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: '7px' }}>Action</p>,
            cell: row => <>
                {
                    <span onClick={() => setEditVal(row)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1">
                        <i className="fa fa-edit"></i>
                    </span>
                }
            </>
        }
    ]

    const BoatCol = [
        {
            name: 'Property Number',
            selector: (row) => <>{row?.PropertyNumber} </>,
            sortable: true
        },
        {
            name: 'Incident Number',
            selector: (row) => <>{row?.IncidentNumber} </>,
            sortable: true
        },
        {
            name: 'Property Type',
            selector: (row) => row.PropertyType,
            sortable: true
        },

        {
            name: 'BoatIDNumber',
            selector: (row) => <>{row?.BoatIDNumber} </>,
            sortable: true
        },
        {
            name: 'Loss Code',
            selector: (row) => row.PropertyLossCode_Description,
            sortable: true
        },
        {
            name: 'Comments',
            selector: (row) => row.Comments,
            sortable: true
        },
        {
            name: 'Registration State Name',
            selector: (row) => row.Registration_StateName,
            sortable: true
        },
        {
            name: 'Registration Number',
            selector: (row) => row.RegistrationNumber,
            sortable: true
        },
        {
            name: 'HIN',
            selector: (row) => row.HIN,
            sortable: true
        },
        {
            name: 'Manufacture Year',
            selector: (row) => row.ManufactureYear,
            sortable: true
        },
        // {
        //     name: 'Make_Description',
        //     selector: (row) => row.Make_Description,
        //     sortable: true
        // },
        // {
        //     name: 'Model_Description',
        //     selector: (row) => row.Model_Description,
        //     sortable: true
        // },
        // {
        //     name: 'Length',
        //     selector: (row) => row.Length,
        //     sortable: true
        // },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: '7px' }}>Action</p>,
            cell: row => <>
                {
                    <span onClick={() => setEditVal(row)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1">
                        <i className="fa fa-edit"></i>
                    </span>
                }
            </>
        }
    ]

    const OtherCol = [
        {
            name: 'Property Number',
            selector: (row) => <>{row?.PropertyNumber} </>,
            sortable: true
        },
        {
            name: 'Incident Number',
            selector: (row) => <>{row?.IncidentNumber} </>,
            sortable: true
        },
        {
            name: 'Property Type',
            selector: (row) => row.PropertyType,
            sortable: true
        },
        {
            name: 'Loss Code',
            selector: (row) => row.PropertyLossCode_Description,
            sortable: true
        },
        {
            name: 'SerialID',
            selector: (row) => <>{row?.SerialID} </>,
            sortable: true
        },
        {
            name: 'Quantity',
            selector: (row) => <>{row?.Quantity} </>,
            sortable: true
        },
        {
            name: 'ModelID',
            selector: (row) => <>{row?.ModelID} </>,
            sortable: true
        },
        {
            name: 'Brand',
            selector: (row) => <>{row?.Brand} </>,
            sortable: true
        },
        // {
        //     name: 'TopColor_Description',
        //     selector: (row) => <>{row?.TopColor_Description} </>,
        //     sortable: true
        // },
        // {
        //     name: 'BottomColor_Description',
        //     selector: (row) => <>{row?.BottomColor_Description} </>,
        //     sortable: true
        // },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: '7px' }}>Action</p>,
            cell: row => <>
                {
                    <span onClick={() => setEditVal(row)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1">
                        <i className="fa fa-edit"></i>
                    </span>
                }
            </>
        }
    ]

    const WeaponCol = [
        {
            name: 'Property Number',
            selector: (row) => <>{row?.PropertyNumber} </>,
            sortable: true
        },
        {
            name: 'Incident Number',
            selector: (row) => <>{row?.IncidentNumber} </>,
            sortable: true
        },
        {
            name: 'Property Type',
            selector: (row) => row.PropertyType,
            sortable: true
        },
        {
            name: 'Loss Code',
            selector: (row) => row.PropertyLossCode_Description,
            sortable: true
        },
        {
            name: 'BarrelLength',
            selector: (row) => <>{row?.BarrelLength} </>,
            sortable: true
        },
        {
            name: 'Caliber',
            selector: (row) => <>{row?.Caliber} </>,
            sortable: true
        },
        // {
        //     name: 'Finish',
        //     selector: (row) => <>{row?.Finish} </>,
        //     sortable: true
        // },
        // {
        //     name: 'ManufactureYear',
        //     selector: (row) => <>{row?.ManufactureYear} </>,
        //     sortable: true
        // },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: '7px' }}>Action</p>,
            cell: row => <>
                {
                    <span onClick={() => setEditVal(row)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1">
                        <i className="fa fa-edit"></i>
                    </span>
                }
            </>
        }
    ]

    const SecurityCol = [
        {
            name: 'Property Number',
            selector: (row) => <>{row?.PropertyNumber} </>,
            sortable: true
        },
        {
            name: 'Incident Number',
            selector: (row) => <>{row?.IncidentNumber} </>,
            sortable: true
        },
        {
            name: 'Property Type',
            selector: (row) => row.PropertyType,
            sortable: true
        },
        {
            name: 'SecurityIDNumber',
            selector: (row) => <>{row?.SecurityIDNumber} </>,
            sortable: true
        },
        {
            name: 'Loss Code',
            selector: (row) => row.PropertyLossCode_Description,
            sortable: true
        },
        {
            name: 'SecurityDtTm',
            selector: (row) => <>{row?.SecurityDtTm} </>,
            sortable: true
        },
        // {
        //     name: 'MeasureTypeID',
        //     selector: (row) => <>{row?.MeasureTypeID} </>,
        //     sortable: true
        // },
        {
            name: 'IssuingAgency',
            selector: (row) => <>{row?.IssuingAgency} </>,
            sortable: true
        },
        {
            name: 'Denomination',
            selector: (row) => <>{row?.Denomination} </>,
            sortable: true
        },
        {
            name: 'SerialID',
            selector: (row) => <>{row?.SerialID} </>,
            sortable: true
        },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: '7px' }}>Action</p>,
            cell: row => <>
                {
                    <span onClick={() => setEditVal(row)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1">
                        <i className="fa fa-edit"></i>
                    </span>
                }
            </>
        }
    ]


    const setEditVal = (row) => {
        fetchPostData("Property/GetData_PropertyExist", {
            "MasterPropertyID": row.MasterPropertyID,
            "IncidentID": mainIncidentID ? mainIncidentID : '',
        }).then((data) => {
            // console.log(data)
            if (data) {
                if (data[0]?.Total === 0) {
                    // setEditValue(row);
                    GetSingleData(row.MasterPropertyID)
                    setSearchModalState(false);
                    // dispatch({ type: PropertySearch_Modal_Status, payload: false });
                } else {
                    toastifyError('Property Already Exists');
                    setSearchModalState(true);
                    // dispatch({ type: PropertySearch_Modal_Status, payload: true });
                }
            }
        })
    }

    const GetSingleData = (masterPropertyId) => {
        const val = { 'MasterPropertyID': masterPropertyId, 'PropertyID': 0, 'PINID': loginPinID, 'IncidentID': 0, 'IsMaster': true }
        fetchPostData('Property/GetSingleData_Property', val).then((res) => {
            // console.log(res);
            if (res) { setEditValue(res); }
            else { setEditValue([]); }
        })
    }

    useEffect(() => {
        // console.log(editval)
        if (editval?.length > 0) {
            sessionStorage.setItem("propertyStolenValue", Encrypted_Id_Name(editval[0]?.Value, 'SForStolenValue'));

            if (Get_Property_Code(editval, propertyTypeData) === 'A') {
                get_PropertyArticle_Single_Data(editval[0]?.MasterPropertyID, 0, Get_Property_Code(editval, propertyTypeData))
                setPropertOther([]); setPropertyBoat([]); setPropertyWeapon([]); setPropertySecurity([])
                dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '1', '', '', '', '', '')); console.log("Call  Type === A")

            }
            else if (Get_Property_Code(editval, propertyTypeData) === 'B') {
                get_PropertyBoat_Single_Data(editval[0]?.MasterPropertyID, 0, Get_Property_Code(editval, propertyTypeData))
                setPropertOther([]); setPropertyArticle([]); setPropertyWeapon([]); setPropertySecurity([])
                dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '1', '', '', '', '')); console.log("Call  Type === B")

            } else if (Get_Property_Code(editval, propertyTypeData) === 'O') {
                get_PropertOther_Single_Data(editval[0]?.MasterPropertyID, 0, Get_Property_Code(editval, propertyTypeData))
                setPropertyArticle([]); setPropertyBoat([]); setPropertyWeapon([]); setPropertySecurity([])
                dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '', '', '1', '', '')); console.log("Call  Type === O")

            } else if (Get_Property_Code(editval, propertyTypeData) === 'S') {
                get_PropertySecurity_Single_Data(editval[0]?.MasterPropertyID, 0, Get_Property_Code(editval, propertyTypeData))
                setPropertOther([]); setPropertyBoat([]); setPropertyWeapon([]); setPropertyArticle([])
                dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '', '1', '', '', '')); console.log("Call  Type === S")

            } else if (Get_Property_Code(editval, propertyTypeData) === 'G') {
                get_PropertyWeapon_Single_Data(editval[0]?.MasterPropertyID, 0, Get_Property_Code(editval, propertyTypeData))
                setPropertOther([]); setPropertyBoat([]); setPropertyArticle([]); setPropertySecurity([]);
                dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '', '', '', '', '1')); console.log("Call  Type === G")

            }
            //  else if (Get_Property_Code(editval, propertyTypeData) === 'D') {
            //     get_Data_Drug_Modal(editval[0]?.MasterPropertyID, propertyID);
            //     setPropertOther([]); setPropertyBoat([]); setPropertyArticle([]); setPropertySecurity([]); setPropertyWeapon([]);
            //     dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '', '', '', '1', '')); console.log("Call  Type === D")
            // }

            setLossCode(Get_LossCode(editval, propertyLossCodeDrpData));
            setValue({
                ...value,
                // 'PropertyID': MstPage === "MST-Property-Dash" ? '' : editval[0]?.PropertyID,
                'MasterPropertyID': editval[0]?.MasterPropertyID,
                'PropertyNumber': editval[0]?.PropertyNumber,
                'PropertyTypeID': editval[0]?.PropertyTypeID, 'ModifiedByUserFK': loginPinID,
                'CategoryID': editval[0]?.CategoryID, 'ClassificationID': editval[0]?.ClassificationID,
                'ReportedDtTm': editval[0]?.ReportedDtTm ? getShowingDateText(editval[0]?.ReportedDtTm) : '',
                'DestroyDtTm': editval[0]?.DestroyDtTm ? getShowingDateText(editval[0]?.DestroyDtTm) : '',
                'Value': editval[0]?.Value ? editval[0]?.Value : '',
                'OfficerID': editval[0]?.OfficerID, 'LossCodeID': editval[0]?.LossCodeID, 'PropertyTag': editval[0]?.PropertyTag,
                'NICB': editval[0]?.NICB, 'Description': editval[0]?.Description, 'IsEvidence': editval[0]?.IsEvidence,
                'IsSendToPropertyRoom': editval[0]?.IsSendToPropertyRoom, 'IsPropertyRecovered': editval[0]?.IsPropertyRecovered,
                // 'PossessionOfID': editval[0]?.PossessionOfID,
                'PropertyCategoryCode': Get_Property_Code(editval, propertyTypeData),
                'PropertyArticleID': Get_Property_Code(editval, propertyTypeData) === "A" ? editval[0].PropertyArticle[0]?.PropertyArticleID : '',
                'PropertyBoatID': Get_Property_Code(editval, propertyTypeData) === "B" ? editval[0].PropertyBoat[0]?.PropertyBoatID : "",
                'PropertyOtherID': Get_Property_Code(editval, propertyTypeData) === "O" ? editval[0].PropertyOther[0]?.PropertyOtherID : '',
                'PropertySecurityID': Get_Property_Code(editval, propertyTypeData) === "S" ? editval[0].PropertySecurity[0]?.PropertySecurityID : '',
                'PropertyWeaponID': Get_Property_Code(editval, propertyTypeData) === "G" ? editval[0].PropertyWeapon[0]?.PropertyWeaponID : '',
            });
            PropertyCategory(editval[0]?.PropertyTypeID);
            PropertyClassification(editval[0]?.CategoryID);
            setPropertyNumber(editval[0]?.PropertyNumber);
        }
    }, [editval]);
    // console.log(editval);

    const onCloseModel = () => {
        // dispatch({ type: PropertySearch_Modal_Status, payload: false });
        dispatch({ type: Property_Search_Data, payload: [] });
        setSearchModalState(false)
    }

    // console.log(propertySearchData);
    return (
        searchModalState ?
            <div className="modal fade " style={{ background: "rgba(0,0,0, 0.5)", }} id="PropertyModal" tabIndex="-1" data-backdrop="false" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header px-3 p-2">
                            <h5 className="modal-title">Property-Search-List</h5>
                            <button type="button" onClick={onCloseModel} className="close text-red border-0" data-dismiss="modal" style={{ alignSelf: 'end' }}>X</button>
                        </div>
                        <div className="box text-center px-2">
                            <div className="col-12 ">
                                {
                                    propertySearchType === 'Pro-Security' &&
                                    <DataTable
                                        dense
                                        columns={SecurityCol}
                                        data={propertySearchData}
                                        pagination
                                        selectableRowsHighlight
                                        highlightOnHover
                                    />
                                }
                                {
                                    propertySearchType === 'Pro-Article' &&
                                    <DataTable
                                        dense
                                        columns={ArticleCol}
                                        data={propertySearchData}
                                        pagination
                                        selectableRowsHighlight
                                        highlightOnHover
                                    />
                                }
                                {
                                    propertySearchType === 'Pro-Other' &&
                                    <DataTable
                                        dense
                                        columns={OtherCol}
                                        data={propertySearchData}
                                        pagination
                                        selectableRowsHighlight
                                        highlightOnHover
                                    />
                                }
                                {
                                    propertySearchType === 'Pro-Weapon' &&
                                    <DataTable
                                        dense
                                        columns={WeaponCol}
                                        data={propertySearchData}
                                        pagination
                                        selectableRowsHighlight
                                        highlightOnHover
                                    />
                                }
                                {
                                    propertySearchType === 'Pro-Boat' &&
                                    <DataTable
                                        dense
                                        columns={BoatCol}
                                        data={propertySearchData}
                                        pagination
                                        selectableRowsHighlight
                                        highlightOnHover
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :
            <></>
    )
}

export default memo(PropertySearchTab)

const Get_Property_Code = (data, dropDownData) => {
    const result = data?.map((sponsor) =>
        (sponsor.PropertyTypeID)
    )
    const result2 = dropDownData?.map((sponsor) => {
        if (sponsor.value === result[0]) {
            return { value: result[0], label: sponsor.label, id: sponsor.id }
        }
    }
    )
    const val = result2.filter(function (element) {
        return element !== undefined;
    });
    return val[0]?.id
}

const Get_LossCode = (data, dropDownData) => {
    const result = data?.map((sponsor) => (sponsor.LossCodeID))
    const result2 = dropDownData?.map((sponsor) => {
        if (sponsor.value === result[0]) {
            return { value: result[0], label: sponsor.label, id: sponsor.id }
        }
    })
    const val = result2.filter(function (element) {
        return element !== undefined;
    });
    return val[0]?.id
}
import React from "react";
import { Tab, Nav } from "react-bootstrap";
import ConfirmModal from '../../Components/Common/ConfirmModal';

import HospitalStatusCodeSection from '../../CADComponents/UtilitiesScreen/HospitalStatusCodeSection';
import HospitalNameCodeSection from '../../CADComponents/UtilitiesScreen/HospitalNameCodeSection';
import ResourceSection from '../../CADComponents/UtilitiesScreen/ResourceSection';
import ResourceTypeSection from '../../CADComponents/UtilitiesScreen/ResourceTypeSection';
import ZoneSection from '../../CADComponents/UtilitiesScreen/ZoneSection';
import StationCodeSection from '../../CADComponents/UtilitiesScreen/StationCodeSection';
import ShiftSection from '../../CADComponents/UtilitiesScreen/ShiftSection';
import CallForServiceCodeSection from '../../CADComponents/UtilitiesScreen/CallForServiceCodeSection';
import CFSAgencyCallFilterSection from '../../CADComponents/UtilitiesScreen/CFSAgencyCallFilterSection';
import "./index.css";
// import ResizableContainer from "../../CADComponents/Common/ResizableContainer";

const CADUtilities = () => {
  return (
    <>
      {/* CAD Utilities */}
      <div className="utilities-main-container">
        {/* Tab Controller */}
        <div id="utilities-tab-container">
          {/* Tab Navigation */}
          <Tab.Container
            id="utilities-tabs"
            defaultActiveKey="hospital_status_code-container"
          >
            <div id="utilities-nav-tabs-container">
              <Nav variant="tabs" className="utilities-nav-tabs">
                <Nav.Item>
                  <Nav.Link eventKey="hospital_status_code-container">Hospital Status Code</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="hospital_name_code-container">Hospital Name Code</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="resource-container">Resource</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="resource_type-container">Resource Type</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="zone-container">Zone</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="station_code-container">Station Code</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="shift-container">Shift</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="call_for_service_code-container">Call for Service Code</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="CFS_agency_call_filter-container">CFS Agency Call Filter</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>

            {/* Tab Content */}
            <div id="utilities-tab-content-container">
              <Tab.Content className="w-100">
                <Tab.Pane eventKey="hospital_status_code-container">
                  <HospitalStatusCodeSection />
                </Tab.Pane>
                <Tab.Pane eventKey="hospital_name_code-container">
                  <HospitalNameCodeSection />
                </Tab.Pane>
                <Tab.Pane eventKey="resource-container">
                  <ResourceSection />
                </Tab.Pane>
                <Tab.Pane eventKey="resource_type-container">
                  <ResourceTypeSection />
                </Tab.Pane>
                <Tab.Pane eventKey="zone-container">
                  <ZoneSection />
                </Tab.Pane>
                <Tab.Pane eventKey="station_code-container">
                  <StationCodeSection />
                </Tab.Pane>
                <Tab.Pane eventKey="shift-container">
                  <ShiftSection />
                </Tab.Pane>
                <Tab.Pane eventKey="call_for_service_code-container">
                  <CallForServiceCodeSection />
                </Tab.Pane>
                <Tab.Pane eventKey="CFS_agency_call_filter-container">
                  <CFSAgencyCallFilterSection />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
      </div>
      <ConfirmModal />
    </>
  );
};

export default CADUtilities;

import React, { useEffect, useState } from 'react';
import FormTitleSection from '../../Common/FormTitleSection'
import DataTable from 'react-data-table-component';
import Select from "react-select";
import Dropdown from 'react-bootstrap/Dropdown';
import { SendIcon } from '../../../Components/Filter/Filter';
import { tableCustomStyles } from '../../../Components/Common/Utility';
import { colorLessStyle_Select } from '../../Utility/CustomStylesForReact';
import '../section.css';
import useObjState from '../../../CADHook/useObjState';
import { alarmLevelData } from '../../../CADUtils/constant';
import MasterTableListServices from "../../../CADServices/APIs/masterTableList";
import { useQuery } from 'react-query';
import { dropDownDataModel } from '../../../CADUtils/functions/common';
import { toastifySuccess } from '../../../Components/Common/AlertMsg';


const ResourceSection = () => {
  const [filterTypeIdOption, setFilterTypeIdOption] = useState('Contains');
  const [pageStatus, setPageStatus] = useState("1");
  const [resourceId, setResourceId] = useState("")
  const [zoneDropDown, setZoneDropDown] = useState([])
  const [
    resourceState,
    setResourceState,
    handleResourceState,
    clearResourceState,
  ] = useObjState({
    agencyCode: "",
    agencyType: "",
    agencyType2: "",
    resource: "",
    resourceType: "",
    station: "",
    primaryOfficer: "",
    primaryOfficer2: "",
    primaryOfficer3: "",
    primaryOfficer4: "",
    primaryOfficer5: "",
    primaryOfficer6: "",
    primaryOfficer7: "",
    primaryOfficer8: "",
    hours24Resource: false,
    carResource: false,
    zone: "",
    dutyStatus: "offduty",
    shift: "",
  });

  const shiftDataKey = `/MasterShift/GetShift`;
  const payloadShift = { ShiftId: "" };
  const { data: shiftData } = useQuery(
    [shiftDataKey, { payloadShift },],
    MasterTableListServices.getShift,
    {
      refetchOnWindowFocus: false,
    }
  );

  const stationCodeKey = `/MasterStationCode/GetStationCode`;
  const payloadStationCode = { stationID: "" };
  const { data: stationCodeData } = useQuery(
    [stationCodeKey, { payloadStationCode },],
    MasterTableListServices.getStationCode,
    {
      refetchOnWindowFocus: false,
    }
  );

  const GetResourceTypeKey = `/MasterResourceType/GetResourceType`;
  const payloadResourceType = { ResourceTypeID: "" };
  const { data: resourceTypeData } = useQuery(
    [GetResourceTypeKey, { payloadResourceType },],
    MasterTableListServices.getResourceType,
    {
      refetchOnWindowFocus: false,
    }
  );

  const getZoneKey = `/GeoPetrolZone/GetData_Zone`;
  const payloadZone = { zoneType: "" };
  const { data: getZoneData, isSuccess: isFetchGeoZoneData } = useQuery(
    [getZoneKey, { payloadZone },],
    MasterTableListServices.getDataZone,
    {
      refetchOnWindowFocus: false,
    }
  );

  const GetPrimaryOfficerKey = `/MasterPrimaryOfficer/PrimaryOfficer`;
  const { data: getPrimaryOfficerData } = useQuery(
    [GetPrimaryOfficerKey, {},],
    MasterTableListServices.getPrimaryOfficer,
    {
      refetchOnWindowFocus: false,
    }
  );

  const getAgencyCodeKey = `MasterAgency/MasterAgencyCode`;
  const { data: getAgencyCodeData } = useQuery(
    [getAgencyCodeKey, {},],
    MasterTableListServices.getAgencyCode,
    {
      refetchOnWindowFocus: false,
    }
  );

  const getResourcesKey = `CallTackerResource/GetData_Resources`;
  const { data: getResourcesData, isSuccess, refetch } = useQuery(
    [getResourcesKey, { CFSID: "" },],
    MasterTableListServices.getResources,
    {
      refetchOnWindowFocus: false,
    }
  );

  const getByIdResourcesKey = `CallTackerResource/GetData_Resources/${resourceId}`;
  const { data: getByIdResourcesData, isSuccess: isFetchGetByIdResource } = useQuery(
    [getByIdResourcesKey, { ResourceID: resourceId },],
    MasterTableListServices.getResources,
    {
      refetchOnWindowFocus: false,
      enabled: !!resourceId
    }
  );

  useEffect(() => {
    if (getByIdResourcesData && isFetchGetByIdResource) {
      const data = JSON.parse(getByIdResourcesData?.data?.data)?.Table?.[0]
      console.log("data", data)
      setResourceState({
        agencyCode: getAgencyCodeData?.data?.data?.Table?.find((i) => i?.AgencyID === data?.AgencyCode) || "",
        agencyType: "",
        agencyType2: "",
        resource: data?.ResourceID || "",
        resourceType: resourceTypeData?.data?.data?.Table?.find((i) => i?.ResourceTypeID === data?.ResourceTypeCode) || "",
        station: data?.StationCode ? stationCodeData?.data?.data?.Table?.find((i) => i?.stationID === data?.StationCode) : "",
        primaryOfficer: getPrimaryOfficerData?.data?.data?.Table?.find((i) => i?.PINID === data?.Officer1) || "",
        primaryOfficer2: getPrimaryOfficerData?.data?.data?.Table?.find((i) => i?.PINID === data?.Officer2) || "",
        primaryOfficer3: getPrimaryOfficerData?.data?.data?.Table?.find((i) => i?.PINID === data?.Officer3) || "",
        primaryOfficer4: getPrimaryOfficerData?.data?.data?.Table?.find((i) => i?.PINID === data?.Officer4) || "",
        primaryOfficer5: getPrimaryOfficerData?.data?.data?.Table?.find((i) => i?.PINID === data?.Officer5) || "",
        primaryOfficer6: getPrimaryOfficerData?.data?.data?.Table?.find((i) => i?.PINID === data?.Officer6) || "",
        primaryOfficer7: getPrimaryOfficerData?.data?.data?.Table?.find((i) => i?.PINID === data?.Officer7) || "",
        primaryOfficer8: getPrimaryOfficerData?.data?.data?.Table?.find((i) => i?.PINID === data?.Officer8) || "",
        hours24Resource: data?.Is24HourResource || false,
        carResource: data?.IsCarResource || false,
        zone: zoneDropDown?.find((i) => i?.value === data?.ResourceZone) || "",
        dutyStatus: data?.OnOffDuty === "onduty" ? true : false || false,
        shift: shiftData?.data?.data?.Table?.find((i) => i?.ShiftId === data?.ShiftCode) || "",
      })
    }
  }, [getAgencyCodeData?.data?.data?.Table, getByIdResourcesData, getPrimaryOfficerData?.data?.data?.Table, isFetchGetByIdResource, resourceTypeData?.data?.data?.Table, setResourceState, shiftData?.data?.data?.Table, stationCodeData?.data?.data?.Table, zoneDropDown])

  useEffect(() => {
    if (isFetchGeoZoneData && getZoneData) {
      const data = JSON.parse(getZoneData?.data?.data)?.Table || [];
      setZoneDropDown(dropDownDataModel(data, "ZoneID", "ZoneDescription"));
    }
  }, [isFetchGeoZoneData, getZoneData]);

  const data = [
    { resourceNumber: '001', resourceType: 'Fire Truck', stationCode: 'ST01', status: true, },
    { resourceNumber: '002', resourceType: 'Ambulance', stationCode: 'ST02', status: false, },
    { resourceNumber: '003', resourceType: 'Fire Ambulance', stationCode: 'ST03', status: true, },
    { resourceNumber: '004', resourceType: 'Fire Ambulance', stationCode: 'ST04', status: true, },
    { resourceNumber: '005', resourceType: 'Fire Ambulance', stationCode: 'ST05', status: false, },
    { resourceNumber: '006', resourceType: 'Fire Ambulance', stationCode: 'ST06', status: true, },
  ];

  const [toggleStates, setToggleStates] = useState(
    data.map(() => true)
  );

  const handleToggle = (index) => {
    setToggleStates(prevStates =>
      prevStates.map((state, i) => (i === index ? !state : state))
    );
  };

  const columns = [
    {
      name: 'Resource #',
      selector: row => row?.ResourceID,
      sortable: true,
    },
    {
      name: 'Resource Type',
      selector: row => row?.ResourceTypeCode,
      sortable: true,
      width: "30%",
    },
    {
      name: 'Station Code',
      selector: row => row?.StationCode,
      sortable: true,
    },
    {
      name:
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {'Status'}
        </div>,
      cell: (row, index) =>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <span
            onClick={() => handleToggle(index)}
            className="btn btn-sm text-white px-1 py-0 mr-1"
            data-toggle="modal" data-target="#ConfirmModal"
            style={{ background: "#ddd", cursor: "pointer" }}
          >
            {toggleStates[index] ? (
              <i className="fa fa-toggle-on" style={{ color: "green" }} aria-hidden="true"></i>
            ) : (
              <i className="fa fa-toggle-off" style={{ color: "red" }} aria-hidden="true"></i>
            )}
          </span>
        </div>,
      width: "70px",
    },

  ];

  async function handleSave() {
    const data = {
      AgencyCode: resourceState?.agencyCode?.AgencyID || "",
      ResourceNumber: resourceState?.resource,
      ResourceTypeCode: resourceState?.resourceType?.ResourceTypeID || "",
      StationCode: resourceState?.station?.stationID || "",
      Officer1: resourceState?.primaryOfficer?.PINID,
      Officer2: resourceState?.primaryOfficer2?.PINID,
      Officer3: resourceState?.primaryOfficer3?.PINID,
      Officer4: resourceState?.primaryOfficer4?.PINID,
      Officer5: resourceState?.primaryOfficer5?.PINID,
      Officer6: resourceState?.primaryOfficer6?.PINID,
      Officer7: resourceState?.primaryOfficer7?.PINID,
      Officer8: resourceState?.primaryOfficer8?.PINID,
      Is24HourResource: resourceState?.hours24Resource ? 1 : 0,
      IsCarResource: resourceState?.carResource ? 1 : 0,
      ResourceZone: resourceState?.zone?.value || "",
      OnOffDuty: resourceState?.dutyStatus === "onduty" ? 1 : 0,
      ShiftCode: resourceState?.shift?.ShiftId || "",
    }
    const response = await MasterTableListServices.insertResource(data);
    if (response?.status == 200) {
      toastifySuccess("Data Saved Successfully");
      clearResourceState();
      refetch();
    }
  }

  return (
    <>
      <div className='utilities-tab-content-main-container'>
        <div className='utilities-tab-content-form-container'>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 incident-tab ">
              <ul className="nav nav-tabs mb-1 pl-2 " id="myTab" role="tablist">
                <span className={`nav-item ${pageStatus === '1' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("1"); }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '1' ? 'Red' : '' }}>Active</span>
                <span className={`nav-item ${pageStatus === '0' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("0"); }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '0' ? 'Red' : '' }}>InActive</span>
              </ul>
            </div>
            {
              pageStatus === '1' ?
                <>
                  <div className='utilities-tab-content-form-main'>
                    {/* line 1 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Agency Code
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <Select
                          styles={colorLessStyle_Select}
                          placeholder="Select"
                          options={getAgencyCodeData?.data?.data?.Table}
                          getOptionLabel={(v) => v?.ShortName}
                          getOptionValue={(v) => v?.AgencyID}
                          isClearable
                          value={resourceState?.agencyCode}
                          onChange={(e) => { handleResourceState("agencyCode", e) }}
                        />
                      </div>
                      <div className="col-1 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Agency Type
                        </label>
                      </div>
                      <div className="col-1 d-flex align-self-center justify-content-end">
                        <input
                          type="text"
                          className="form-control py-1 new-input highlighter-input-readonly"
                          placeholder='Placeholder'
                          value={resourceState?.agencyType}
                          readOnly
                        />
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <input
                          type="text"
                          className="form-control py-1 new-input highlighter-input-readonly"
                          placeholder='Auto fetch'
                          value={resourceState?.agencyType2}
                          readOnly
                        />
                      </div>
                    </div>
                    {/* line 2 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Resource #
                        </label>
                      </div>
                      <div className="col-3 d-flex align-self-center justify-content-end">
                        <input
                          type="text"
                          className="form-control py-1 new-input"
                          placeholder='Placeholder'
                          value={resourceState?.resource}
                          onChange={(e) => { handleResourceState("resource", e.target.value) }}
                        />
                      </div>
                      <div className="col-1 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Resource Type
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <Select
                          styles={colorLessStyle_Select}
                          placeholder="Select"
                          options={resourceTypeData?.data?.data?.Table}
                          value={resourceState?.resourceType}
                          getOptionLabel={(v) => v?.ResourceTypeCode + " | " + v?.description}
                          getOptionValue={(v) => v?.ResourceTypeID}
                          onChange={(e) => { handleResourceState("resourceType", e) }}
                        />
                      </div>
                      <div className="col-1 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Station
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <Select
                          styles={colorLessStyle_Select}
                          placeholder="Select"
                          options={stationCodeData?.data?.data?.Table}
                          getOptionLabel={(v) => v?.stationCode + " | " + v?.description}
                          getOptionValue={(v) => v?.stationID}
                          value={resourceState?.station}
                          onChange={(e) => { handleResourceState("station", e) }}
                        />
                      </div>

                    </div>
                    {/* line 3 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Primary Officer
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <Select
                          styles={colorLessStyle_Select}
                          placeholder="Select"
                          options={getPrimaryOfficerData?.data?.data?.Table}
                          getOptionLabel={(v) => v?.FirstName + " " + v?.LastName}
                          getOptionValue={(v) => v?.PINID}
                          value={resourceState?.primaryOfficer}
                          isClearable
                          onChange={(e) => { handleResourceState("primaryOfficer", e) }}
                        />
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <Select
                          styles={colorLessStyle_Select}
                          placeholder="Select"
                          options={getPrimaryOfficerData?.data?.data?.Table}
                          getOptionLabel={(v) => v?.FirstName + " " + v?.LastName}
                          getOptionValue={(v) => v?.PINID}
                          isClearable
                          value={resourceState?.primaryOfficer2}
                          onChange={(e) => { handleResourceState("primaryOfficer2", e) }}
                        />
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <Select
                          styles={colorLessStyle_Select}
                          placeholder="Select"
                          options={getPrimaryOfficerData?.data?.data?.Table}
                          getOptionLabel={(v) => v?.FirstName + " " + v?.LastName}
                          getOptionValue={(v) => v?.PINID}
                          isClearable
                          value={resourceState?.primaryOfficer3}
                          onChange={(e) => { handleResourceState("primaryOfficer3", e) }}
                        />
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <Select
                          styles={colorLessStyle_Select}
                          placeholder="Select"
                          options={getPrimaryOfficerData?.data?.data?.Table}
                          getOptionLabel={(v) => v?.FirstName + " " + v?.LastName}
                          getOptionValue={(v) => v?.PINID}
                          isClearable
                          value={resourceState?.primaryOfficer4}
                          onChange={(e) => { handleResourceState("primaryOfficer4", e) }}
                        />
                      </div>
                    </div>
                    {/* line 4 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        {/* Empty Space */}
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <Select
                          styles={colorLessStyle_Select}
                          placeholder="Select"
                          options={getPrimaryOfficerData?.data?.data?.Table}
                          getOptionLabel={(v) => v?.FirstName + " " + v?.LastName}
                          getOptionValue={(v) => v?.PINID}
                          isClearable
                          value={resourceState?.primaryOfficer5}
                          onChange={(e) => { handleResourceState("primaryOfficer5", e) }}
                        />
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <Select
                          styles={colorLessStyle_Select}
                          placeholder="Select"
                          options={getPrimaryOfficerData?.data?.data?.Table}
                          getOptionLabel={(v) => v?.FirstName + " " + v?.LastName}
                          getOptionValue={(v) => v?.PINID}
                          isClearable
                          value={resourceState?.primaryOfficer6}
                          onChange={(e) => { handleResourceState("primaryOfficer6", e) }}
                        />
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <Select
                          styles={colorLessStyle_Select}
                          placeholder="Select"
                          options={getPrimaryOfficerData?.data?.data?.Table}
                          getOptionLabel={(v) => v?.FirstName + " " + v?.LastName}
                          getOptionValue={(v) => v?.PINID}
                          isClearable
                          value={resourceState?.primaryOfficer7}
                          onChange={(e) => { handleResourceState("primaryOfficer7", e) }}
                        />
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <Select
                          styles={colorLessStyle_Select}
                          placeholder="Select"
                          options={getPrimaryOfficerData?.data?.data?.Table}
                          getOptionLabel={(v) => v?.FirstName + " " + v?.LastName}
                          getOptionValue={(v) => v?.PINID}
                          isClearable
                          value={resourceState?.primaryOfficer8}
                          onChange={(e) => { handleResourceState("primaryOfficer8", e) }}
                        />
                      </div>
                    </div>
                    {/* line 5 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        {/* Empty Space */}
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-start" style={{ gap: '3.5px' }}>
                        <input type="checkbox" checked={resourceState?.hours24Resource} onChange={(e) => { handleResourceState("hours24Resource", e.target.checked) }} />24 Hours Resource
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-start" style={{ gap: '3.5px' }}>
                        <input type="checkbox" checked={resourceState?.carResource} onChange={(e) => { handleResourceState("carResource", e.target.checked) }} />Car Resource
                      </div>
                    </div>
                    {/* line 6 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Zone
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <Select
                          styles={colorLessStyle_Select}
                          placeholder="Select"
                          options={zoneDropDown}
                          isClearable
                          value={resourceState?.zone}
                          onChange={(e) => { handleResourceState("zone", e) }}
                        />
                      </div>
                    </div>

                    <FormTitleSection title="On / Off Duty Status" />

                    {/* line 7 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        {/* Empty Space */}
                      </div>
                      <div className="d-flex align-self-center justify-content-start" style={{ width: '120px' }}>
                        <div className='d-flex align-self-center justify-content-start' style={{ gap: '5px' }}>
                          <input type="radio" id="offduty" value="offduty" checked={resourceState?.dutyStatus === 'offduty'} onChange={(e) => { handleResourceState("dutyStatus", e.target.value) }} />
                          <label for="offduty" style={{ margin: '0', }}>Off Duty</label>
                        </div>
                      </div>
                      <div className="d-flex align-self-center justify-content-start" style={{ width: '120px' }}>
                        <div className='d-flex align-self-center justify-content-start' style={{ gap: '5px' }}>
                          <input type="radio" id="onduty" value="onduty" checked={resourceState?.dutyStatus === 'onduty'} onChange={(e) => {
                            handleResourceState("dutyStatus", e.target.value)
                          }} />
                          <label for="onduty" style={{ margin: '0', }}>On Duty</label>
                        </div>
                      </div>
                      <div className="d-flex align-self-center justify-content-end" style={{ width: '50px' }}>
                        <label for="" className="tab-form-label">
                          Shift
                        </label>
                      </div>
                      <div className="col-3 d-flex align-self-center justify-content-end">
                        <Select
                          styles={colorLessStyle_Select}
                          placeholder="Select"
                          getOptionLabel={(v) => v?.ShiftCode + " | " + v?.ShiftDescription}
                          getOptionValue={(v) => v?.ShiftId}
                          options={shiftData?.data?.data?.Table}
                          isClearable
                          value={resourceState?.shift}
                          onChange={(e) => { handleResourceState("shift", e) }}
                        />
                      </div>
                    </div>
                  </div>
                </>
                :
                <>
                </>
            }
          </div>

        </div>

        <div className='utilities-tab-content-table-container'>
          <div className="row">
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control py-1 new-input"
                placeholder='Search By Code...'
              />
            </div>
            <div className="col-1 d-flex align-self-center justify-content-end">
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeIdOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control py-1 new-input"
                placeholder='Search By Description...'
              />
            </div>
            <div className="col-1 d-flex align-self-center justify-content-end">
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeIdOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {isSuccess &&
            <div className="table-responsive">
              <DataTable
                dense
                columns={columns}
                data={JSON.parse(getResourcesData?.data?.data || [])?.Table}
                customStyles={tableCustomStyles}
                pagination
                responsive
                striped
                highlightOnHover
                fixedHeader
                onRowClicked={(row) => {
                  setResourceId(row?.ResourceID);
                }}
              />
            </div>}

          <div className="utilities-tab-content-button-container" >
            <button type="button" onClick={() => { clearResourceState() }} className="btn btn-sm btn-success">Cancel</button>
            <button type="button" onClick={() => { handleSave() }} className="btn btn-sm btn-success">Save</button>
          </div>
        </div>
      </div>

    </>
  );
};

export default ResourceSection;
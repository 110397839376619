import React, { memo, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from 'react-redux';
import Select from "react-select";
import CallTakerServices from "../../CADServices/APIs/callTaker";
import {
  dropDownDataModel,
  handleNumberTextKeyDown,
  handleTextKeyDown,
  isEmpty,
  isEmptyObject,
} from "../../CADUtils/functions/common";
import GeoServices from "../../CADServices/APIs/geo";
import Location from "../Common/Location";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import GEOModal from "../GEOModal";
import NameSearchModal from "../NameSearch/NameSearchModal";
import VehicleSearchModal from "../VehicleSearch/VehicleSearchModal";
import ContactInfoModal from "../ContactInfoModal";
import LocationInformationModal from "../LocationInformationModal";
import useObjState from "../../CADHook/useObjState";
import { zoneDropDownData } from "../../CADUtils/constant";

const CallTakerModal = (props) => {
  const { openCallTakerModal, setCallTakerModal } = props;
  const mapRef = useRef(null);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBBfoUsiF8ZX5iopPppB9x5XRriRaALhiY",
  });
  const localStoreData = useSelector((state) => state.Agency.localStoreData);

  //#region //! useState 
  const [loginAgencyID, setLoginAgencyID] = useState('');
  const [receiveSourceDropDown, setReceiveSourceDropDown] = useState([]);
  const [prioritiesDropDown, setPrioritiesDropDown] = useState([]);
  const [reasonCodeDropDown, setReasonCodeDropDown] = useState([]);
  const [vehiclePlateTypeDropDown, setVehiclePlateTypeDropDown] = useState([]);
  const [resourceDropDown, setResourceDropDown] = useState([]);
  const [tagYearDropDown, setTagYearDropDown] = useState([]);
  const [stateDropDown, setStateDropDown] = useState([]);
  const [premiseDropDown, setPremiseDropDown] = useState([]);
  const [cfsDropDown, setCFSDropDown] = useState([]);
  const [cfsDescDropDown, setCFSDescDropDown] = useState([]);
  const [flagDropDown, setFlagDropDown] = useState([]);
  const [locationStatus, setLocationStatus] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(0);
  const [geoLocationID, setGeoLocationID] = useState();
  const [onSelectLocation, setOnSelectLocation] = useState(true);
  const [locationList, setLocationList] = useState();
  const [nameId, setNameId] = useState("");
  const [vehicleId, setVehicleID] = useState("");
  const [incidentId, setIncidentId] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [activeIndexLocation, setActiveIndexLocation] = useState([[], []]);
  const [openGEOModal, setOpenGEOModal] = useState(false);
  const [openContactInfoModal, setOpenContactInfoModal] = useState(false);
  const [openLocationInformationModal, setOpenLocationInformationModal] = useState(false);
  const [isSelectLocation, setIsSelectLocation] = useState(false);
  const [CFSID, setCFSID] = useState(null);
  const [selectedButton, setSelectedButton] = useState([]);
  const [zoom, setZoom] = useState(17);
  const [isOpenSearchNameModel, setIsOpenSearchNameModel] = useState(false);
  const [isOpenVehicleSearchModel, setIsOpenVehicleSearchModel] = useState(false);
  const [nameData, setNameData] = useState([]);
  const [
    nameCallTaker,
    setNameCallTaker,
    handleNameCallTaker,
    clearState,
  ] = useObjState({
    FirstName: "",
    LastName: "",
    NameReasonCodeID: [""],
  });
  console.log("nameCallTaker", nameCallTaker)
  const [
    errorCallTaker,
    _setErrorCallTaker,
    handleErrorCallTaker,
    clearStateCallTaker,
  ] = useObjState({
    location: false,
    ReceiveSourceID: false,
    CallforServiceID: false,
    CFSLPriority: false,
    patrolZone: false,
    emsZone: false,
    fireZone: false,
    otherZone: false,
    Resource1: false,
  });

  const initialFormValues = {
    PremiseNo: "",
    stDirection: "",
    Street: "",
    stDirection2: "",
    ApartmentNo: "",
    commonPlaceName: "",
    premiseType: null,
    City: "",
    ZipCode: "",
    mileMarker: "",
    coordinateX: "",
    coordinateY: "",
    AltStreet: "",
    intersection1: "",
    intersection2: "",
    verify: false,
    patrolZone: null,
    emsZone: null,
    fireZone: null,
    otherZone: null,
    currentFlag: null,
    location: "",
    IsVerify: true,
    isStreet: false,
    isCity: false,
    isPremiseNo: false,
    isZipCode: false,
    isMileMarker: false,
    isCommonPlaceName: false,
    isStDirection: false,
    isStDirection2: false,
    isIntersection1: false,
    isIntersection2: false,
    isAltStreet: false,
    isApartmentNo: false,
    isCoordinateX: false,
    isCoordinateY: false,
  };

  const [geoFormValues, setGEOFormValues] = useState(initialFormValues);

  const initialValueContact = {
    LastName: "",
    MiddleName: "",
    FirstName: "",
    PhoneType: {},
    PhoneNumber: "",
  };

  const [contactInformation, setContactInformation] =
    useState(initialValueContact);

  const incidentInitialValues = {
    location: "",
    ApartmentNo: "",
    ReceiveSourceID: "",
    CallforServiceID: "",
    CFSLDetails: "",
    CFSLPriority: "",
    CFSL: 0,
    CFSF: 0,
    CFSE: 0,
    OTHER: 0,
    Comments: "",
    Resource1: "",
    Resource2: "",
    Resource3: "",
    Resource4: "",
    Resource5: "",
    Resource6: "",
    Resource7: "",
    Resource8: "",
  };

  const [incidentFormValues, setIncidentFormValues] = useState(
    incidentInitialValues
  );

  // const [incidentNameForm, setIncidentNameFrom] = useState({
  //   FirstName: "",
  //   LastName: "",
  //   ReasonCode: [],
  // });

  const [vehicleData, setVehicleData] = useState([]);
  const [
    vehicleCallTaker,
    setVehicleCallTaker,
    handleVehicleCallTaker,
    clearVehicleState,
  ] = useObjState({
    Plate: "",
    StateCode: null,
    PlateTypeCode: null,
    TagYear: null,
  });

  //#endregion

  const locations = [
    {
      lng: parseFloat(geoFormValues?.coordinateX) || -98.5795,
      lat: parseFloat(geoFormValues?.coordinateY) || 39.8283,
      status: "open",
      name: geoFormValues?.location,
    },
  ];

  const mapCenter = {
    lng: parseFloat(geoFormValues?.coordinateX) || -98.5795,
    lat: parseFloat(geoFormValues?.coordinateY) || 39.8283,
  };

  //#region //! API

  const receiveSourceKey = `/CallTakerReceiveSource/GetData_ReceiveSource`;
  const { data: receiveSourceData, isSuccess: isFetchReceiveSourceData } =
    useQuery(
      [receiveSourceKey, { AgencyID: loginAgencyID }],
      CallTakerServices.getReceiveSource,
      {
        refetchOnWindowFocus: false,
        enabled: !!loginAgencyID
      }
    );

  const cfsKey = `/CallTackerCFS/GetData_CFS`;
  const { data: cfsData, isSuccess: isFetchCFSData } = useQuery(
    [cfsKey],
    CallTakerServices.getCFS,
    {
      refetchOnWindowFocus: false,
    }
  );

  const getNameReasonCodeKey = `/DispatcherNameReasonCode/GetDataDropDown_NameReasonCode`;
  const payloadReasonCode = { CategoryID: 1 };
  const { data: nameReasonCodeData, isSuccess: isFetchNameReasonCodeData } =
    useQuery(
      [getNameReasonCodeKey, { payloadReasonCode }],
      CallTakerServices.getNameReasonCode,
      {
        refetchOnWindowFocus: false,
      }
    );

  const prioritiesKey = `CallTackerPriorieties/GetData_Priorities`;
  const { data: prioritiesData, isSuccess: isFetchPrioritiesData } = useQuery(
    [prioritiesKey],
    CallTakerServices.getPriorities,
    {
      refetchOnWindowFocus: false,
    }
  );

  const vehiclePlateTypePayload = {
    PINID: 0,
    IsActive: 1,
    IsSuperAdmin: 1,
    AgencyID: loginAgencyID,
  };

  const vehiclePlateTypeKey = `/CallTakerVehiclePlateType/GetData_PropertyVehiclePlateType`;
  const { data: vehiclePlateTypeData, isSuccess: isFetchVehiclePlateTypeData } =
    useQuery(
      [
        vehiclePlateTypeKey,
        {
          vehiclePlateTypePayload,
        },
      ],
      CallTakerServices.getPropertyVehiclePlateType,
      {
        refetchOnWindowFocus: false,
        enabled: !!loginAgencyID
      }
    );

  const tagYearKey = `/CallTakerVehiclePlateType/GetData_TagYear`;
  const { data: tagYearData, isSuccess: isFetchTagYearData } = useQuery(
    [tagYearKey],
    CallTakerServices.getTagYear,
    {
      refetchOnWindowFocus: false,
    }
  );

  const stateKey = `/CallTakerState/GetData_State`;
  const { data: stateData, isSuccess: isFetchStateData } = useQuery(
    [stateKey],
    CallTakerServices.getState,
    {
      refetchOnWindowFocus: false,
    }
  );

  const payloadGetDataResources = { CFSID: CFSID };

  const resourcesKey = `/CallTackerResource/GetData_Resources`;
  const { data: resourcesData, isSuccess: isFetchResourcesData } = useQuery(
    [resourcesKey,
      {
        payloadGetDataResources
      },],
    CallTakerServices.getResources,
    {
      refetchOnWindowFocus: false,
    }
  );

  const getPremiseKey = `/GeoPremiseType/GetData_Premise`;
  const { data: premiseData, isSuccess: isFetchPremiseData } = useQuery(
    [getPremiseKey, {}],
    GeoServices.getPremise,
    {
      refetchOnWindowFocus: false,
    }
  );

  const getFlagKey = `/GeoFlage/GetData_Flag`;
  const { data: flagData, isSuccess: isFetchFlagData } = useQuery(
    [getFlagKey, {}],
    GeoServices.getFlag,
    {
      refetchOnWindowFocus: false,
    }
  );

  const payload = { GeoLocationID: geoLocationID };
  const getContactKey = `/GeoLocationContactDetail/GetData_GeoContact/${geoLocationID}`;
  const {
    data: contactData,
    isSuccess: isFetchContactData,
  } = useQuery([getContactKey, { payload }], GeoServices.getContactData, {
    refetchOnWindowFocus: false,
    enabled: !!geoLocationID,
  });

  //#endregion

  useEffect(() => {
    if (localStoreData) {
      setLoginAgencyID(localStoreData?.AgencyID);
    }
  }, [localStoreData]);

  useEffect(() => {
    if (isFetchReceiveSourceData && receiveSourceData) {
      const data = JSON.parse(receiveSourceData?.data?.data);
      setReceiveSourceDropDown(
        dropDownDataModel(data?.Table, "ReceiveSourceID", "ReceiveSourceCode")
      );
    }
  }, [isFetchReceiveSourceData, receiveSourceData]);

  useEffect(() => {
    if (isFetchNameReasonCodeData && nameReasonCodeData) {
      const data = JSON.parse(nameReasonCodeData?.data?.data);
      setReasonCodeDropDown(
        dropDownDataModel(data?.Table, "NameReasonCodeID", "Description")
      );
    }
  }, [isFetchNameReasonCodeData, nameReasonCodeData]);

  const cfsDataDummy = {
    "success": true,
    "data": {
      "Table": [
        { "CFSID": 100, "CFSL": 1, "CFSF": 1, "CFSE": 1, "OTHER": 1, "Description": "Police" },
        { "CFSID": 101, "CFSL": 0, "CFSF": 0, "CFSE": 1, "OTHER": 0, "Description": "Fire" },
        { "CFSID": 109, "CFSL": 1, "CFSF": 0, "CFSE": 1, "OTHER": 0, "Description": "Law" },
        { "CFSID": 104, "CFSL": 0, "CFSF": 0, "CFSE": 1, "OTHER": 0, "Description": "Other" },
      ]
    },
    "Message": "Successfully", "Code": 200
  }

  useEffect(() => {
    if (isFetchCFSData && cfsData) {

      const data = cfsDataDummy?.data?.Table;

      const dropDownDataModel1 = (data, value, label) => {
        const result = data?.map((item) => ({
          value: item[value],
          label: `${item[value]} | ${item[label]}`,
          name: item[label],
        }));
        return result;
      };
      setCFSDropDown(dropDownDataModel1(data, "CFSID", "Description"));
      setCFSDescDropDown(dropDownDataModel1(data, "CFSID", "Description"));
    }
  }, [isFetchCFSData, cfsData]);

  useEffect(() => {
    if (isFetchPrioritiesData && prioritiesData) {
      const data = JSON.parse(prioritiesData?.data?.data);
      setPrioritiesDropDown(
        dropDownDataModel(data?.Table, "PriCode", "PriDesc")
      );
    }
  }, [isFetchPrioritiesData, prioritiesData]);

  useEffect(() => {
    if (isFetchVehiclePlateTypeData && vehiclePlateTypeData) {
      const data = JSON.parse(vehiclePlateTypeData?.data?.data);
      setVehiclePlateTypeDropDown(
        dropDownDataModel(data?.Table, "PlateTypeCode", "Description")
      );
    }
  }, [isFetchVehiclePlateTypeData, vehiclePlateTypeData]);

  useEffect(() => {
    if (isFetchResourcesData && resourcesData) {
      const data = JSON.parse(resourcesData?.data?.data);
      setResourceDropDown(
        dropDownDataModel(data?.Table, "ResourceNumber", "ResourceNumber")
      );
    }
  }, [isFetchResourcesData, resourcesData]);

  useEffect(() => {
    if (isFetchPremiseData && premiseData) {
      const data = JSON.parse(premiseData?.data?.data);
      setPremiseDropDown(dropDownDataModel(data?.Table, "ID", "PremiseType"));
    }
  }, [isFetchPremiseData, premiseData]);

  useEffect(() => {
    if (isFetchTagYearData && tagYearData) {
      const data = JSON.parse(tagYearData?.data?.data);
      setTagYearDropDown(dropDownDataModel(data?.Table, "TagYear", "TagYear"));
    }
  }, [isFetchTagYearData, tagYearData]);

  useEffect(() => {
    if (isFetchStateData && stateData) {
      const data = JSON.parse(stateData?.data?.data);
      setStateDropDown(dropDownDataModel(data?.Table, "State", "StateName"));
    }
  }, [isFetchStateData, stateData]);

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const response = await GeoServices.getLocationData({
          Location: geoFormValues?.location,
        });
        let data;

        if (response?.data?.Data) {
          data = response?.data?.Data;
          setLocationList(data);
        } else {
          setLocationList([]);
          data = null;
        }
      } catch (error) {
        console.error("Error fetching location data:", error);
      }
    };

    if (geoFormValues?.location) {
      fetchLocationData();
    }
  }, [geoFormValues?.location]);

  useEffect(() => {
    if (isFetchFlagData && flagData) {
      const data = JSON.parse(flagData?.data?.data);
      setFlagDropDown(dropDownDataModel(data?.Table, "ID", "CurrentFlag"));
    }
  }, [isFetchFlagData, flagData]);

  useEffect(() => {
    if (isFetchContactData && contactData) {
      if (contactData?.data?.Data?.length === 0) {
        return;
      } else {
        try {
          const parsedData = JSON.parse(contactData?.data?.data);
          const contactInfo = parsedData?.Table;

          contactInfo.forEach((item) => {
            if (typeof item.PhoneNo === "string") {
              try {
                item.PhoneNo = JSON.parse(item.PhoneNo);
              } catch (error) {
                console.error("Error parsing PhoneNo:", error);
              }
            }
          });

          setContactList(contactInfo);
        } catch (error) {
          console.error("Error parsing contact data:", error);
        }
      }
    }
  }, [isFetchContactData, contactData]);

  function handleClear() {
    setGeoLocationID();
    clearStateCallTaker();
    setGEOFormValues(initialFormValues);
    setIsSelectLocation(false);
  }

  const onCloseLocation = () => {
    setCallTakerModal(false);
    setGeoLocationID("");
    clearStateCallTaker();
    setContactList([]);
    setContactInformation(initialValueContact);
    handleClear();
    setVehicleID("");
    // setIncidentNameFrom({
    //   FirstName: "",
    //   LastName: "",
    //   ReasonCode: [],
    // });

    setNameId("");
    setIncidentId([]);
    setIncidentFormValues(incidentInitialValues);
    setGEOFormValues(initialFormValues);
    setActiveIndexLocation([[], []]);
    setIsSelectLocation(false);
  };

  const handleInputChangeIncident = (e) => {
    const { name, value } = e.target;
    setIncidentFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChangeIncident = (selectedOption, { name }) => {
    setIncidentFormValues((prevState) => ({
      ...prevState,
      [name]: selectedOption,
    }));
  };

  const handleSelectChangeIncidentCFS = (selectedOption, { name }) => {
    const updatedValues = { ...incidentFormValues };
    setCFSID(selectedOption?.value)
    const selectedData = cfsDataDummy.data.Table.find(
      (item) => item.CFSID === selectedOption?.value
    );

    if (name === "CallforServiceID") {
      updatedValues.CallforServiceID = selectedOption;
      const selectedDescription = cfsDescDropDown.find(
        (item) => item.value === selectedOption?.value
      );
      updatedValues.CFSLDetails = selectedDescription;
    }
    if (name === "CFSLDetails") {
      updatedValues.CFSLDetails = selectedOption;
      const selectedDescription = cfsDropDown.find(
        (item) => item.value === selectedOption?.value
      );
      updatedValues.CallforServiceID = selectedDescription;
    }
    if (selectedData) {
      setIncidentFormValues(() => ({
        ...updatedValues,
        CFSL: selectedData.CFSL,
        CFSF: selectedData.CFSF,
        CFSE: selectedData.CFSE,
        OTHER: selectedData.OTHER,
      }));
    }
  };

  const handleSelectIncidentName = (selectedOption, { name }) => {
    const data = selectedOption.map(item => item?.value)

    handleNameCallTaker("NameReasonCodeID", data)
  };

  const handleSelectIncidentVehicle = (selectedOption, { name }) => {
    handleVehicleCallTaker(name, selectedOption)
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setGEOFormValues((prevState) => ({
      ...prevState,
      [name]: selectedOption,
    }));
  };
  //#region  handler
  //
  const getColorByStatus = (status) => {
    switch (status) {
      case "open":
        return "green";
      case "closed":
        return "red";
      case "pending":
        return "yellow";
      default:
        return "blue";
    }
  };

  const validateGeoFormValues = () => {
    let isError = false;
    const keys = Object.keys(errorCallTaker);
    keys.map((field) => {
      if (
        field === "location" &&
        isEmpty(geoFormValues[field])) {
        handleErrorCallTaker(field, true);
        isError = true;
      } else if (field === "ReceiveSourceID" && isEmptyObject(incidentFormValues[field])) {
        handleErrorCallTaker(field, true);
        isError = true;
      } else if (field === "CallforServiceID" && isEmptyObject(incidentFormValues[field])) {
        handleErrorCallTaker(field, true);
        isError = true;
      } else if (field === "patrolZone" && isEmptyObject(geoFormValues[field])) {
        handleErrorCallTaker(field, true);
        isError = true;
      } else if (field === "emsZone" && isEmptyObject(geoFormValues[field])) {
        handleErrorCallTaker(field, true);
        isError = true;
      } else if (field === "fireZone" && isEmptyObject(geoFormValues[field])) {
        handleErrorCallTaker(field, true);
        isError = true;
      } else if (field === "otherZone" && isEmptyObject(geoFormValues[field])) {
        handleErrorCallTaker(field, true);
        isError = true;
      } else if (field === "Resource1" && isEmptyObject(incidentFormValues[field])) {
        handleErrorCallTaker(field, true);
        isError = true;
      }
      else if (field === "CFSLPriority" && isEmptyObject(incidentFormValues[field])) {
        handleErrorCallTaker(field, true);
        isError = true;
      } else {
        handleErrorCallTaker(field, false);
      }
      // if (isEmptyObject(geoFormValues[field])) {
      //   handleErrorCallTaker(field, true);
      //   isError = true;
      // } else {
      //   handleErrorCallTaker(field, false);
      // }
    });
    return !isError;
  };

  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: "all .2s ease",
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null
    }),
    input: (provided) => ({
      ...provided,
      minWidth: '0px',
      maxWidth: '100%',
    }),
  };

  const customStylesWithOutColorArrow = {
    control: base => ({
      ...base,
      // height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: "all .2s ease",
      transform: state.selectProps.menuIsOpen ? null : "rotate(180deg)"
    }),
    input: (provided) => ({
      ...provided,
      minWidth: '0px',
      maxWidth: '100%',
    }),
  };

  const colourStylesEC = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fce9bf",
      minHeight: 35,
      height: 20,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: "all .2s ease",
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null
    }),
    input: (provided) => ({
      ...provided,
      minWidth: '0px',
      maxWidth: '100%',
    }),
  };

  const addName = async () => {
    setNameData((prevData) => [...prevData, nameCallTaker]);
    // const response = await CallTakerServices.updateName(nameCallTaker);
    // console.log("res", response)
    clearState();
  };

  const addVehicle = () => {
    setVehicleData((prevData) => [...prevData, vehicleCallTaker]);
    clearVehicleState();
  };
  //#endregion
  const onSave = async () => {
    if (!validateGeoFormValues()) return;
    setSelectedButton(prevSelected =>
      prevSelected.includes(2)
        ? prevSelected.filter(item => item !== 2)
        : [...prevSelected, 2]
    );

    //#region 
    const formData = new FormData();

    // Incident Data
    formData.append('Incident.ApartmentNo', incidentFormValues?.ApartmentNo || "");
    formData.append('Incident.CFSE', incidentFormValues?.CFSE ?? "");
    formData.append('Incident.CFSF', incidentFormValues?.CFSF ?? "");
    formData.append('Incident.CFSL', incidentFormValues?.CFSL ?? "");
    formData.append('Incident.CFSLDetails', incidentFormValues?.CFSLDetails?.value || "");
    formData.append('Incident.CFSLPriority', incidentFormValues?.CFSLPriority?.value || "");
    formData.append('Incident.CallforServiceID', incidentFormValues?.CallforServiceID?.value || "");
    formData.append('Incident.Comments', incidentFormValues?.Comments || "");
    formData.append('Incident.CrimeLocation', geoFormValues?.location || "");
    formData.append('Incident.OTHER', incidentFormValues?.OTHER ?? "");
    formData.append('Incident.ReceiveSourceID', incidentFormValues?.ReceiveSourceID?.value || "");

    // Resource Data
    formData.append('Incident.Resource1', incidentFormValues?.Resource1?.value || "");
    formData.append('Incident.Resource2', incidentFormValues?.Resource2?.value || "");
    formData.append('Incident.Resource3', incidentFormValues?.Resource3?.value || "");
    formData.append('Incident.Resource4', incidentFormValues?.Resource4?.value || "");
    formData.append('Incident.Resource5', incidentFormValues?.Resource5?.value || "");
    formData.append('Incident.Resource6', incidentFormValues?.Resource6?.value || "");
    formData.append('Incident.Resource7', incidentFormValues?.Resource7?.value || "");
    formData.append('Incident.Resource8', incidentFormValues?.Resource8?.value || "");

    // Name Data
    nameData.forEach((name, nameIndex) => {
      formData.append(`Name[${nameIndex}].IsMaster`, name.IsMaster ? "true" : "false");
      formData.append(`Name[${nameIndex}].FirstName`, name.FirstName || "");
      formData.append(`Name[${nameIndex}].LastName`, name.LastName || "");

      // name.NameReasonCodeID.forEach((reasonCodeID, reasonIndex) => {
      //   formData.append(`Name[${nameIndex}].NameReasonCodeID[${reasonIndex}]`, reasonCodeID || "");
      // });
      console.log("JSON.stringify(nameCallTaker.NameReasonCodeID)", nameData)
      formData.append(`Name[${nameIndex}].NameReasonCodeID`, JSON.stringify(name?.NameReasonCodeID));
    });

    // Vehicle Data
    vehicleData.forEach((vehicle, vehicleIndex) => {
      formData.append(`Vehicle[${vehicleIndex}].CallerID`, "00");  // Static values
      formData.append(`Vehicle[${vehicleIndex}].ID`, "00");
      formData.append(`Vehicle[${vehicleIndex}].Plate`, vehicle?.Plate || "");
      formData.append(`Vehicle[${vehicleIndex}].StateCode`, vehicle?.StateCode?.value || "");
      formData.append(`Vehicle[${vehicleIndex}].PlateTypeCode`, vehicle?.PlateTypeCode?.value || "");
      formData.append(`Vehicle[${vehicleIndex}].TagYear`, vehicle?.TagYear?.value || "");
    });

    // Now, the formData object has all Incident, Resource, Name, and Vehicle data
    try {
      const response = await CallTakerServices.insertName(formData);
      console.log("res", response)
      onCloseLocation()
      //   const data = JSON.parse(response?.data?.MasterNameID);
      //   setNameId(data);
    } catch (error) {
      console.error("Failed to insert name data", error);
    }


    //#endregion








    // const insertIncident = {
    //   CrimeLocation: geoFormValues?.location || "",
    //   ApartmentNo: incidentFormValues?.ApartmentNo || "",
    //   ReceiveSourceID: incidentFormValues?.ReceiveSourceID?.value || "",
    //   CallforServiceID: incidentFormValues?.CallforServiceID?.value || "",
    //   CFSLDetails: incidentFormValues?.CFSLDetails?.value || "",
    //   CFSLPriority: incidentFormValues?.CFSLPriority?.value || "",
    //   CFSE: incidentFormValues?.CFSE,
    //   CFSF: incidentFormValues?.CFSF,
    //   CFSL: incidentFormValues?.CFSL,
    //   OTHER: incidentFormValues?.OTHER,
    //   Comments: incidentFormValues?.Comments || "",
    //   Resource1: incidentFormValues?.Resource1?.value || "",
    //   Resource2: incidentFormValues?.Resource2?.value || "",
    //   Resource3: incidentFormValues?.Resource3?.value || "",
    //   Resource4: incidentFormValues?.Resource4?.value || "",
    //   Resource5: incidentFormValues?.Resource5?.value || "",
    //   Resource6: incidentFormValues?.Resource6?.value || "",
    //   Resource7: incidentFormValues?.Resource7?.value || "",
    //   Resource8: incidentFormValues?.Resource8?.value || "",
    // };
    // let incidentIDs;
    // try {
    //   const insertResponse = await CallTakerServices.insertIncident(
    //     insertIncident
    //   );

    //   incidentIDs = insertResponse?.data?.results?.map(item => item?.incidentID);
    //   setIncidentId(incidentIDs);
    // } catch (error) {
    //   console.error("Error during insert:", error);
    // }

    // // Name
    // if (nameData?.length > 0 && incidentIDs?.length > 0) {
    //   const formData = new FormData();

    //   incidentIDs.forEach((id, index) => {
    //     formData.append(`IncidentID[${index}]`, id);
    //   });

    //   nameData.forEach((name, nameIndex) => {
    //     formData.append(`Name[${nameIndex}].IsMaster`, "true");
    //     formData.append(`Name[${nameIndex}].FirstName`, name.FirstName);
    //     formData.append(`Name[${nameIndex}].LastName`, name.LastName);

    //     name.NameReasonCodeID.forEach((reasonCodeID, reasonIndex) => {
    //       formData.append(`Name[${nameIndex}].NameReasonCodeID[${reasonIndex}]`, reasonCodeID);
    //     });
    //   });

    //   try {
    //     const response = await CallTakerServices.insertName(formData);
    //     const data = JSON.parse(response?.data?.MasterNameID);
    //     setNameId(data);
    //   } catch (error) {
    //     console.error("Failed to insert name data", error);
    //   }
    // }

    // // Vehicle
    // if (vehicleData?.length > 0 && incidentIDs?.length > 0) {

    //   const formData = new FormData();

    //   incidentIDs.forEach((id, index) => {
    //     formData.append(`IncidentID[${index}]`, id);
    //   });

    //   vehicleData.forEach((name, nameIndex) => {
    //     formData.append(`Vehical[${nameIndex}].CallerID`, "00");
    //     formData.append(`Vehical[${nameIndex}].ID`, "00");
    //     formData.append(`Vehical[${nameIndex}].Plate`, name?.Plate);
    //     formData.append(`Vehical[${nameIndex}].StateCode`, name?.StateCode?.value);
    //     formData.append(`Vehical[${nameIndex}].PlateTypeCode`, name?.PlateTypeCode?.value);
    //     formData.append(`Vehical[${nameIndex}].TagYear`, name?.TagYear?.value);
    //   });

    //   try {
    //     const response = await CallTakerServices.insertVehicle(formData);
    //     const data = JSON.parse(response?.data?.data);
    //     setVehicleID(data?.Table[0]?.VehicleID);
    //   } catch (error) {
    //     console.error("Failed to insert vehicle data", error);
    //   }
    // }

    // //Location
    // const payloadContact = {
    //   PhoneNo: contactInformation?.PhoneNumber,
    //   FirstName: contactInformation?.FirstName,
    //   MiddleName: contactInformation?.MiddleName,
    //   LastName: contactInformation?.LastName,
    //   PhoneType: contactInformation?.PhoneType?.label,
    //   ID: new Date().getTime(),
    // };

    // const prevContactList = [];

    // const contactData = (prevContactList) => {
    //   if (Array.isArray(prevContactList)) {
    //     return [...prevContactList, payloadContact];
    //   } else {
    //     return [payloadContact];
    //   }
    // };

    // const updatedContactList = contactData(prevContactList);
    // const payload = new FormData();
    // payload.append("Street", geoFormValues?.Street || "");
    // payload.append("DirectionPrefix", geoFormValues?.stDirection || "");
    // payload.append("DirectionSufix", geoFormValues?.stDirection2 || "");
    // payload.append("City", geoFormValues?.City || "");
    // payload.append("ZipCode", geoFormValues?.ZipCode || "");
    // payload.append("PremiseNo", geoFormValues?.PremiseNo || "");
    // payload.append("ApartmentNo", geoFormValues?.ApartmentNo || "");
    // payload.append("CommonPlace", geoFormValues?.commonPlaceName || "");
    // payload.append("PremiseType", geoFormValues?.premiseType?.label || "");
    // payload.append("Latitude", geoFormValues?.coordinateX || "");
    // payload.append("Longitude", geoFormValues?.coordinateY || "");
    // payload.append("MileMaker", geoFormValues?.mileMarker || "");
    // payload.append("AltStreet", geoFormValues?.AltStreet || "");
    // payload.append(
    //   "InterDirectionPrefix",
    //   geoFormValues?.intersection1 || ""
    // );
    // payload.append("InterDirectionSufix", geoFormValues?.intersection2 || "");
    // payload.append("PatrolZone", geoFormValues?.patrolZone?.label || "");
    // payload.append("EMSZone", geoFormValues?.emsZone?.label || "");
    // payload.append("FireZone", geoFormValues?.fireZone?.label || "");
    // payload.append("OtherZone", geoFormValues?.otherZone?.label || "");
    // payload.append("IsVerified", geoFormValues?.IsVerify || "");
    // payload.append("Location", geoFormValues?.location || "");
    // const contactListJson = JSON.stringify(contactList);
    // payload.append("GeoLocationContactsJson", contactListJson || []);
    // payload.append("Action", geoLocationID ? "update" : "insert");
    // payload.append("incidentID", JSON.stringify(incidentIDs));

    // const currentFlag =
    //   geoFormValues?.currentFlag?.map((item) => item.label) || [];
    // const currentFlagData = JSON.stringify(currentFlag);
    // payload.append("CurrentFlage", currentFlagData);


    // const insertUpdateLocation = async () => {
    //   if (geoLocationID) {
    //     payload.append("ID", geoLocationID);
    //     await GeoServices.insertLocation(payload);
    //   } else {
    //     const response = await GeoServices.insertLocation(payload);
    //     if (response?.data?.success) {
    //       const data = JSON.parse(response?.data?.data);
    //       setGeoLocationID(data?.Table[0]?.GeoLocationID);
    //     }
    //   }
    // };

    // if (incidentIDs.length > 0 && isSelectLocation && !isEmptyObject(geoFormValues.patrolZone) && !isEmptyObject(geoFormValues.fireZone) && !isEmptyObject(geoFormValues.emsZone) && !isEmptyObject(geoFormValues.otherZone)) {
    //   insertUpdateLocation()
    // }

    setNameData([])
  };
  const onNameSearch = async () => {
    setIsOpenSearchNameModel(true)

  }
  return (
    <>
      {openCallTakerModal ? (
        <dialog
          className="modal fade"
          style={{
            background: "rgba(0,0,0, 0.5)",
            zIndex: "200",
            overflow: "hidden",
          }}
          id="CallTakerModal"
          tabIndex="-1"
          aria-hidden="true"
          data-backdrop="false"
        >
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div
              className="modal-content modal-content-cad"
              style={{
                maxHeight: "calc(100vh - 100px)",
                overflowY: "auto",
              }}
            >
              <div className="modal-body">
                {/* Modal Title */}
                <div className="row">
                  <div className="col-12 p-0 pb-2">
                    <div className="py-0 px-2 d-flex justify-content-between align-items-center">
                      <p
                        className="p-0 m-0 font-weight-medium"
                        style={{
                          fontSize: 18,
                          fontWeight: 500,
                          letterSpacing: 0.5,
                        }}
                      >
                        Call Taker
                      </p>
                    </div>
                  </div>
                </div>
                <div className="m-1">
                  <fieldset
                    style={{ border: "1px solid gray" }}
                    className="tab-form-container"
                  >
                    {/* Incident */}
                    <fieldset className="tab-form-container">
                      <legend className="cad-legend">Incident</legend>
                      <div className="d-flex">
                        <div
                          className="col-8"
                          style={{ display: "grid", gap: "5px" }}
                        >
                          {/* Row 1 */}
                          <div className="tab-form-row">
                            <div className="col-1 d-flex align-self-center justify-content-end">
                              <label htmlFor="" className="tab-form-label" style={{ textAlign: "end" }}>
                                Location {errorCallTaker.location && (
                                  <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Location"}</p>
                                )}
                              </label>
                            </div>
                            <div className="col-6 w-100 inner-input-fullw">
                              <Location
                                {...{
                                  value: geoFormValues,
                                  setValue: setGEOFormValues,
                                  locationStatus,
                                  setLocationStatus,
                                  updateStatus,
                                  setOnSelectLocation,
                                  locationList,
                                  setContactList: setContactList,
                                  setGeoLocationID,
                                  setContactInformation,
                                  flagDropDown,
                                  premiseDropDown,
                                  setZoom,
                                  setIsSelectLocation,
                                }}
                                col="location"
                                locationID="NameLocationID"
                                check={true}
                                verify={geoFormValues.IsVerify}
                                page="Name"
                                isGEO
                              />
                            </div>
                            <div className="col-2 d-flex tab-form-row-gap">
                              <div className=" d-flex align-self-center justify-content-end">
                                <label
                                  for=""
                                  class="tab-form-label text-nowrap"
                                >
                                  Apt/Suite#
                                </label>
                              </div>
                              <input
                                type="number"
                                className="form-control  py-1 new-input"
                                name="ApartmentNo"
                                value={incidentFormValues.ApartmentNo}
                                onChange={handleInputChangeIncident}
                              />
                            </div>
                            <div className="col-3 d-flex tab-form-row-gap">
                              <label
                                htmlFor=""
                                className="new-label text-nowrap" style={{ textAlign: "end" }}
                              >
                                Receive Source{errorCallTaker.ReceiveSourceID && (
                                  <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Source"}</p>
                                )}
                              </label>
                              <Select
                                isClearable
                                options={receiveSourceDropDown}
                                placeholder="Select..."
                                styles={colourStylesEC}
                                className="w-100"
                                name="ReceiveSourceID"
                                value={incidentFormValues.ReceiveSourceID}
                                onChange={handleSelectChangeIncident}
                              />
                            </div>
                          </div>

                          {/* Row 2 */}
                          <div className="tab-form-row">
                            <div className="col-1 d-flex align-self-center justify-content-end">
                              <label htmlFor="" className="new-label" style={{ textAlign: "end" }}>
                                CFS :{errorCallTaker.CallforServiceID && (
                                  <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select CFS"}</p>
                                )}
                              </label>
                            </div>
                            <div className="col-2 d-flex tab-form-row-gap">
                              <Select
                                options={cfsDropDown}
                                placeholder="Select..."
                                styles={colourStylesEC}
                                className="w-100"
                                name="CallforServiceID"
                                value={incidentFormValues.CallforServiceID}
                                onChange={handleSelectChangeIncidentCFS}
                                getOptionLabel={(option) => option.value}
                                getOptionValue={(option) => option.label}
                                formatOptionLabel={(option, { context }) => {
                                  return context === 'menu' ? option.label : option?.value
                                }}
                              />
                            </div>
                            <div className="col-6 d-flex tab-form-row-gap">
                              <Select
                                options={cfsDescDropDown}
                                placeholder="Select..."
                                styles={colourStylesEC}
                                className="w-100"
                                name="CFSLDetails"
                                value={incidentFormValues.CFSLDetails}
                                onChange={handleSelectChangeIncidentCFS}
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.value}
                                formatOptionLabel={(option, { context }) => {
                                  return context === 'menu' ? option.label : option?.name
                                }}
                              />
                            </div>{" "}
                            <div className="col-3 d-flex tab-form-row-gap">
                              <div className=" d-flex align-self-center justify-content-end">
                                <label class="tab-form-label text-nowrap" style={{ textAlign: "end" }}>
                                  Priority {errorCallTaker.CFSLPriority && (
                                    <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Priority"}</p>
                                  )}
                                </label>
                              </div>
                              <Select
                                isClearable
                                options={prioritiesDropDown}
                                placeholder="Select..."
                                styles={colourStylesEC}
                                className="w-100"
                                name="CFSLPriority"
                                value={incidentFormValues.CFSLPriority}
                                onChange={handleSelectChangeIncident}
                              />
                            </div>
                          </div>

                          {/* Row 3 */}
                          <div className="tab-form-row">
                            <div className="col-8 d-flex align-self-center justify-content-between offset-1">
                              <div
                                className="d-flex align-items-center"
                                style={{ gap: "5px" }}
                              >
                                <input type="checkbox" checked={incidentFormValues.CFSL} readOnly />
                                <span>L - Law</span>
                              </div>
                              <div
                                className="d-flex align-items-center"
                                style={{ gap: "5px" }}
                              >
                                <input type="checkbox" checked={incidentFormValues.CFSF} readOnly />
                                <span>F - Fire</span>
                              </div>
                              <div
                                className="d-flex align-items-center"
                                style={{ gap: "5px" }}
                              >
                                <input type="checkbox" checked={incidentFormValues.CFSE} readOnly />
                                <span>E - Emergency Medical Service</span>
                              </div>
                              <div
                                className="d-flex align-items-center"
                                style={{ gap: "5px" }}
                              >
                                <input type="checkbox" checked={incidentFormValues.OTHER} readOnly />
                                <span>O - Other</span>
                              </div>
                            </div>
                          </div>

                          {/* Row 5 */}

                          <div className="tab-form-row">
                            <div className="col-1 d-flex align-items-center justify-content-end">
                              <label className="tab-form-label text-nowrap" style={{ textAlign: "end" }}>Law Zone {errorCallTaker.patrolZone && (
                                <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Law Zone"}</p>
                              )}</label>
                            </div>
                            <div className="col-11 tab-form-row-gap d-flex w-100">
                              <Select
                                name="patrolZone"
                                styles={colourStylesEC}
                                isClearable
                                options={zoneDropDownData}
                                placeholder="Select..."
                                className="w-100"
                                value={geoFormValues.patrolZone}
                                onChange={handleSelectChange}
                              />
                              <div className="d-flex align-items-center justify-content-end">
                                <label className="tab-form-label text-nowrap" style={{ textAlign: "end" }}>Fire Zone {errorCallTaker.fireZone && (
                                  <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Fire Zone"}</p>
                                )}</label>
                              </div>
                              <Select
                                name="fireZone"
                                styles={colourStylesEC}
                                isClearable
                                options={zoneDropDownData}
                                placeholder="Select..."
                                className="w-100"
                                value={geoFormValues.fireZone}
                                onChange={handleSelectChange}
                              />
                              <div className="d-flex align-items-center justify-content-end">
                                <label className="tab-form-label text-nowrap" style={{ textAlign: "end" }}>EMS Zone {errorCallTaker.emsZone && (
                                  <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select EMS Zone"}</p>
                                )}</label>
                              </div>
                              <Select
                                name="emsZone"
                                styles={colourStylesEC}
                                isClearable
                                options={zoneDropDownData}
                                placeholder="Select..."
                                className="w-100"
                                value={geoFormValues.emsZone}
                                onChange={handleSelectChange}
                              />
                              <div className="d-flex align-items-center justify-content-end">
                                <label className="tab-form-label text-nowrap" style={{ textAlign: "end" }}>Other Zone {errorCallTaker.otherZone && (
                                  <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Other Zone"}</p>
                                )}</label>
                              </div>
                              <Select
                                name="otherZone"
                                styles={colourStylesEC}
                                isClearable
                                options={zoneDropDownData}
                                placeholder="Select..."
                                className="w-100"
                                value={geoFormValues.otherZone}
                                onChange={handleSelectChange}
                              />
                            </div>
                          </div>
                          {/* Row 4 */}
                          <div className="tab-form-row">
                            <div className="col-1 d-flex align-self-center justify-content-end">
                              <label for="" class="tab-form-label text-nowrap">
                                Notes
                              </label>
                            </div>
                            <div className="col-11 d-flex tab-form-row-gap">
                              <input
                                type="text"
                                className="form-control  py-1 new-input"
                                name="Comments"
                                value={incidentFormValues.Comments}
                                onChange={handleInputChangeIncident}
                              />
                            </div>
                          </div>

                          {/* Row 7 */}
                          <div className="tab-form-row">
                            <div className="col-1 d-flex align-self-center justify-content-end">
                              <label htmlFor="" className="new-label">
                                Flags
                              </label>
                            </div>{" "}
                            <div className="col-9 d-flex tab-form-row-gap">
                              {geoFormValues?.currentFlag?.map(
                                (item, index) => {
                                  const labelStyles = {
                                    Fire: {
                                      backgroundColor: "#FF0000",
                                      color: "#ffff",
                                    },
                                    Tresspass: {
                                      backgroundColor: "#0000FF",
                                      color: "#ffff",
                                    },
                                    "Medical-Emergency": {
                                      backgroundColor: "#008000",
                                      color: "#ffff",
                                    },
                                    Hazardous: {
                                      backgroundColor: "#FFFF00",
                                      color: "#000",
                                    },
                                  };

                                  const defaultStyle = {
                                    backgroundColor: "#e6e6e6",
                                    color: "#ffff",
                                  };

                                  const { backgroundColor, color } =
                                    labelStyles[item?.label] || defaultStyle;

                                  return (
                                    <button
                                      key={index}
                                      type="button"
                                      style={{
                                        backgroundColor,
                                        color,
                                        border: "none",
                                      }}
                                      className="btn btn-sm btn-CADprimary1"
                                    >
                                      {item?.label}
                                    </button>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          {isLoaded ? (
                            <GoogleMap
                              mapContainerStyle={{
                                height: "275px",
                              }}
                              zoom={zoom}
                              center={mapCenter}
                              onLoad={(map) => (mapRef.current = map)}
                              onZoomChanged={() => {
                                if (mapRef.current) {
                                  setZoom(mapRef.current.getZoom());
                                }
                              }}
                            >
                              {locations?.map((location, index) => {
                                return (
                                  <Marker
                                    key={index}
                                    position={{
                                      lat: location.lat,
                                      lng: location.lng,
                                    }}
                                    icon={{
                                      path: window.google.maps.SymbolPath
                                        .CIRCLE,
                                      scale: 10,
                                      fillColor: getColorByStatus(
                                        location.status
                                      ),
                                      fillOpacity: 1,
                                      strokeWeight: 1,
                                    }}
                                    title={location.name}
                                    onClick={() =>
                                      console.log(`Location: ${location.name}`)
                                    }
                                  />
                                );
                              })}
                            </GoogleMap>
                          ) : (
                            <div>Loading...</div>
                          )}
                        </div>
                      </div>
                    </fieldset>

                    {/* Resources */}
                    <fieldset className="tab-form-container">
                      <legend className="cad-legend">Resources</legend>
                      <div className="tab-form-row">
                        <div
                          className="col-12 d-flex align-items-center justify-content-end select-container mr-1"
                          style={{ gap: "4.5px" }}
                        >
                          <div>
                            <label className="tab-form-label text-nowrap" style={{ textAlign: "end" }}> Resources # {errorCallTaker.Resource1 && (
                              <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select  Resources"}</p>
                            )}</label>
                          </div>
                          <Select
                            isClearable
                            options={resourceDropDown}
                            placeholder="Select..."
                            className="w-100 ml-1"
                            name="Resource1"
                            value={incidentFormValues?.Resource1}
                            onChange={handleSelectChangeIncident}
                            styles={colourStylesEC}
                            maxMenuHeight={180}
                          />
                          <Select
                            isClearable
                            value={incidentFormValues?.Resource2}
                            onChange={handleSelectChangeIncident}
                            options={resourceDropDown}
                            name="Resource2"
                            placeholder="Select..."
                            className="w-100"
                            styles={customStylesWithOutColor}
                            maxMenuHeight={180}

                          />
                          <Select
                            isClearable
                            value={incidentFormValues?.Resource3}
                            onChange={handleSelectChangeIncident}
                            options={resourceDropDown}
                            placeholder="Select..."
                            name="Resource3"
                            className="w-100"
                            styles={customStylesWithOutColor}
                            maxMenuHeight={150}

                          />
                          <Select
                            isClearable
                            value={incidentFormValues?.Resource4}
                            onChange={handleSelectChangeIncident}
                            options={resourceDropDown}
                            placeholder="Select..."
                            className="w-100"
                            name="Resource4"
                            styles={customStylesWithOutColor}
                            maxMenuHeight={150}

                          />
                          <Select
                            isClearable
                            value={incidentFormValues?.Resource5}
                            onChange={handleSelectChangeIncident}
                            options={resourceDropDown}
                            placeholder="Select..."
                            className="w-100"
                            name="Resource5"
                            styles={customStylesWithOutColor}
                            maxMenuHeight={180}

                          />
                          <Select
                            isClearable
                            value={incidentFormValues?.Resource6}
                            onChange={handleSelectChangeIncident}
                            options={resourceDropDown}
                            placeholder="Select..."
                            className="w-100"
                            name="Resource6"
                            styles={customStylesWithOutColor}
                            maxMenuHeight={180}

                          />
                          <Select
                            isClearable
                            value={incidentFormValues?.Resource7}
                            onChange={handleSelectChangeIncident}
                            options={resourceDropDown}
                            placeholder="Select..."
                            className="w-100"
                            name="Resource7"
                            styles={customStylesWithOutColor}
                            maxMenuHeight={180}

                          />
                          <Select
                            isClearable
                            options={resourceDropDown}
                            placeholder="Select..."
                            name="Resource8"
                            value={incidentFormValues?.Resource8}
                            onChange={handleSelectChangeIncident}
                            styles={customStylesWithOutColor}
                            maxMenuHeight={180}

                            className="w-100"
                          />
                        </div>
                      </div>
                    </fieldset>

                    {/* Name */}
                    <fieldset className="tab-form-container">
                      <legend className="cad-legend">Name</legend>
                      <div className="tab-form-row">
                        <div className="col-10 d-flex tab-form-row-gap">
                          <div className="d-flex align-self-center justify-content-end">
                            <label
                              htmlFor=""
                              className="tab-form-label text-nowrap"
                            >
                              Last Name
                            </label>
                          </div>
                          <input
                            type="text"
                            value={nameCallTaker.LastName}
                            name="LastName"
                            onChange={(e) => handleNameCallTaker("LastName", e.target.value)}

                            className="form-control  py-1 new-input  ml-2"
                            onKeyDown={handleTextKeyDown}
                          />{" "}
                          <div className="d-flex align-self-center justify-content-end">
                            <label htmlFor="" className="new-label text-nowrap tab-form-label">
                              First Name
                            </label>
                          </div>
                          <input
                            type="text"
                            className="form-control py-1 new-input"
                            name="FirstName"
                            value={nameCallTaker.FirstName}
                            onChange={(e) => handleNameCallTaker("FirstName", e.target.value)}
                            onKeyDown={handleTextKeyDown}
                          />

                          <div className=" d-flex align-self-center justify-content-end">
                            <label
                              htmlFor=""
                              className="tab-form-label text-nowrap"
                            >
                              Reason Code
                            </label>
                          </div>
                          <Select
                            isClearable
                            options={reasonCodeDropDown}
                            placeholder="Select..."
                            styles={customStylesWithOutColorArrow}
                            className="w-100"
                            name="NameReasonCodeID"
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={true}
                            allowSelectAll={true}
                            value={reasonCodeDropDown.filter(option => nameCallTaker.NameReasonCodeID.includes(option.value))}
                            onChange={handleSelectIncidentName}
                            menuPlacement="top"
                          />
                        </div>
                        <div
                          className="col-2 d-flex w-100"
                          style={{ gap: "3px" }}
                        >
                          <button
                            type="button"
                            className="btn btn-sm btn-border w-25"
                            onClick={addName}
                          >
                            <i className="fa fa-plus"></i>
                          </button>
                          <button type="button" data-toggle="modal" data-target="#SearchModal" className="btn btn-sm btn-CADprimary w-100 d-flex align-items-center justify-content-center"
                            onClick={() => {
                              onNameSearch();
                            }}
                          >
                            <i className="fa fa-search mr-2"></i>
                            <span>Search</span>
                          </button>
                        </div>
                      </div>
                    </fieldset>

                    {/* Vehicle */}
                    <fieldset className="tab-form-container">
                      <legend className="cad-legend">Vehicle</legend>
                      <div className="tab-form-row">
                        <div className="col-10 d-flex tab-form-row-gap">
                          <div className="col d-flex align-self-center justify-content-end ml-4">
                            <label htmlFor="" className="tab-form-label new-label text-nowrap">
                              Plate
                            </label>
                          </div>
                          <input
                            type="text"
                            name="Plate"
                            value={vehicleCallTaker.Plate}
                            onChange={(e) => handleVehicleCallTaker("Plate", e.target.value)}
                            className="form-control  py-1 new-input ml-2"
                            onKeyDown={handleNumberTextKeyDown}
                            menuPlacement="top"
                          />
                          <div className=" d-flex align-self-center justify-content-end">
                            <label
                              htmlFor=""
                              className="tab-form-label text-nowrap"
                            >
                              State
                            </label>
                          </div>
                          <Select
                            isClearable
                            options={stateDropDown}
                            placeholder="Select..."
                            styles={customStylesWithOutColorArrow}
                            className="w-100"
                            name="StateCode"
                            value={vehicleCallTaker.StateCode}
                            onChange={handleSelectIncidentVehicle}
                            menuPlacement="top"
                          />
                          <div className=" d-flex align-self-center justify-content-end">
                            <label
                              htmlFor=""
                              className="tab-form-label text-nowrap"
                            >
                              Plate Type
                            </label>
                          </div>
                          <Select
                            isClearable
                            options={vehiclePlateTypeDropDown}
                            placeholder="Select..."
                            name="PlateTypeCode"
                            value={vehicleCallTaker.PlateTypeCode}
                            onChange={handleSelectIncidentVehicle}
                            styles={customStylesWithOutColorArrow}
                            className="w-100"
                            menuPlacement="top"
                          />
                          <div className=" d-flex align-self-center justify-content-end">
                            <label
                              htmlFor=""
                              className="tab-form-label text-nowrap"
                            >
                              Tag Year
                            </label>
                          </div>
                          <Select
                            isClearable
                            options={tagYearDropDown}
                            placeholder="Select..."
                            name="TagYear"
                            value={vehicleCallTaker.TagYear}
                            onChange={handleSelectIncidentVehicle}
                            styles={customStylesWithOutColorArrow}
                            className="w-100"
                            menuPlacement="top"
                          />
                        </div>
                        <div
                          className="col-2 d-flex w-100"
                          style={{ gap: "3px" }}
                        >
                          <button
                            type="button"
                            className="btn btn-sm btn-border w-25"
                            onClick={addVehicle}
                          >
                            <i className="fa fa-plus"></i>
                          </button>
                          <button type="button" data-toggle="modal" data-target="#SearchModal" className="btn btn-sm btn-CADprimary w-100 d-flex align-items-center justify-content-center"
                            onClick={() => setIsOpenVehicleSearchModel(true)}
                          >
                            <i className="fa fa-search mr-2"></i>
                            <span>Search</span>
                          </button>
                        </div>
                      </div>
                    </fieldset>

                  </fieldset>
                </div>
              </div>

              {/* Shortcut key and Buttons */}
              <div
                className="tab-form-row d-flex align-content-center px-3 py-2"
                style={{
                  position: "sticky",
                  bottom: "0",
                  zIndex: 100,
                  backgroundColor: "white",
                  marginBottom: "0",
                }}
              >
                <div className="col-9 d-flex tab-form-row-gap justify-content-center">
                  <button type="button"
                    className={`btn btn-sm w-25 ${selectedButton.includes(1) ? 'btn-border selected' : 'btn-border'}`}
                    onClick={() => {
                      setSelectedButton(prevSelected =>
                        prevSelected.includes(1)
                          ? prevSelected.filter(item => item !== 1)
                          : [...prevSelected, 1]
                      );
                    }}
                  >
                    <div style={{ display: "grid" }}>
                      <span>Comments</span>
                    </div>
                  </button>
                  <button type="button"
                    className={`btn btn-sm w-25 ${selectedButton.includes(2) ? 'btn-border selected' : 'btn-border'}`}
                    onClick={() => {
                      onSave();
                    }}
                  >
                    <div style={{ display: "grid" }}>
                      <span>Dispatch</span>
                    </div>
                  </button>
                  <button
                    type="button"
                    className={`btn btn-sm w-25 ${selectedButton.includes(3) ? 'btn-border selected' : 'btn-border'}`}
                    data-toggle="modal"
                    data-target="#LocationInformationModal"
                    onClick={() => {
                      setSelectedButton(prevSelected =>
                        prevSelected.includes(3)
                          ? prevSelected.filter(item => item !== 3)
                          : [...prevSelected, 3]
                      );
                      setOpenLocationInformationModal(true);
                    }}
                  >
                    <div style={{ display: "grid" }}>
                      <span>Location</span>
                    </div>
                  </button>
                  <button
                    type="button"
                    className={`btn btn-sm w-25 ${selectedButton.includes(4) ? 'btn-border selected' : 'btn-border'}`}
                    data-toggle="modal"
                    data-target="#GEOModal"
                    onClick={() => {
                      setSelectedButton(prevSelected =>
                        prevSelected.includes(4)
                          ? prevSelected.filter(item => item !== 4)
                          : [...prevSelected, 4]
                      );
                      setOpenGEOModal(true);
                    }}
                  >
                    <div style={{ display: "grid" }}>
                      <span>GEO</span>
                    </div>
                  </button>
                  <button
                    type="button"
                    className={`btn btn-sm w-25 ${selectedButton.includes(5) ? 'btn-border selected' : 'btn-border'}`}
                    data-toggle="modal"
                    data-target="#ContactInfoModal"
                    onClick={() => {
                      setSelectedButton(prevSelected =>
                        prevSelected.includes(5)
                          ? prevSelected.filter(item => item !== 5)
                          : [...prevSelected, 5]
                      );
                      setOpenContactInfoModal(true);
                    }}
                  >
                    <div style={{ display: "grid" }}>
                      <span>Contact</span>
                    </div>
                  </button>
                  <button type="button"
                    className={`btn btn-sm w-25 ${selectedButton.includes(6) ? 'btn-border selected' : 'btn-border'}`}
                    onClick={() => {
                      setSelectedButton(prevSelected =>
                        prevSelected.includes(6)
                          ? prevSelected.filter(item => item !== 6)
                          : [...prevSelected, 6]
                      );
                    }}
                  >
                    <div style={{ display: "grid" }}>
                      <span>Location Flag</span>
                    </div>
                  </button>
                </div>
                <div className="col-3 d-flex justify-content-end tab-form-row-gap">
                  <button
                    type="button"
                    data-dismiss="modal"
                    className="cancel-button"
                    onClick={() => {
                      onCloseLocation();
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </dialog >
      ) : (
        <> </>
      )}
      <GEOModal {...{ openGEOModal, setOpenGEOModal, setSelectedButton }} />
      {isOpenSearchNameModel && <NameSearchModal {...{ isOpenSearchNameModel, setIsOpenSearchNameModel, nameCallTaker, setNameCallTaker }} />}
      {isOpenVehicleSearchModel && <VehicleSearchModal {...{ isOpenVehicleSearchModel, setIsOpenVehicleSearchModel, vehicleCallTaker, setVehicleCallTaker }} />}
      {openContactInfoModal && <ContactInfoModal {...{ openContactInfoModal, setOpenContactInfoModal, setSelectedButton, contactList, setContactList }} />}
      <LocationInformationModal {...{ openLocationInformationModal, setOpenLocationInformationModal, setSelectedButton, geoFormValues, setGEOFormValues }} />
    </>
  );
};

export default memo(CallTakerModal);

import React from "react";
import DataTable from 'react-data-table-component';
import { tableCustomStyles } from "../../../../Components/Common/Utility";
import { customTableStyles } from '../../../Utility/CustomStylesForReact';

const columns = [
  {
    name: 'Resource #',
    selector: row => row.resourceNumber,
    sortable: true,
  },
  {
    name: 'Status',
    selector: row => row.status,
    sortable: true,
  },
  {
    name: 'Status Date & Time',
    selector: row => row.statusDateTime,
    sortable: true,
    format: row => new Date(row.statusDateTime).toLocaleString(),
  },
];

const data = [
  {
    resourceNumber: '12345',
    status: 'Active',
    statusDateTime: '2024-07-24T10:30:00Z',
  },
  {
    resourceNumber: '67890',
    status: 'Inactive',
    statusDateTime: '2024-07-24T11:00:00Z',
  },
  {
    resourceNumber: '54321',
    status: 'Pending',
    statusDateTime: '2024-07-24T11:30:00Z',
  },
  // Add more data objects as needed
];


const ResourceStatusTabSection = () => {
  return (
    <>
      <div style={{ width: '70%' }}>
        <DataTable
          dense
          columns={columns}
          data={data}
          customStyles={tableCustomStyles}
          pagination // enable pagination
          responsive // enable responsive design
          striped // add zebra-striping to the table rows
          highlightOnHover // highlight row on hover
          fixedHeader
          // fixedHeaderScrollHeight="200px" 
        />
      </div>
    </>
  );
};

export default ResourceStatusTabSection;

import React from "react";
import DataTable from "react-data-table-component";
import { tableCustomStyles } from "../../Components/Common/Utility";
import { Link } from "react-router-dom";
import { phoneTypes } from "../../CADUtils/constant";

function GEOContactTable({ contactList, setContactInformation, setContactList, setItemId, setEditItemId, setIsEditMode }) {
  const set_Edit_Value = (row) => {
    const PhoneType = phoneTypes.find(phoneType => phoneType.label === row.PhoneType);
    const updatedRow = {
      ...row,
      PhoneType,
    };
    setContactInformation(updatedRow);
    setIsEditMode(true);
    setEditItemId(row.ID);
  };

  const handleDeleteContact = (id) => {
    setContactList((prevContactList) => prevContactList.filter(contact => contact.ID !== id));
  };

  // phoneTypes.find(phoneType => phoneType.label === row.PhoneType);
  const columns = [
    {
      name: (
        <p className="text-end" style={{ position: "absolute", top: "7px" }}>
          Last Name
        </p>
      ),
      cell: (row) => <div>{row.LastName}</div>,
      selector: (row) => (row.LastName ? row.LastName : ""),
    },
    {
      name: "Middle Name",
      selector: (row) => (row.MiddleName ? row.MiddleName : ""),

      sortable: true,
    },
    {
      name: "First Name",
      selector: (row) => (row.FirstName ? row.FirstName : ""),

      sortable: true,
    },
    {
      name: "Phone Type",
      selector: (row) => (row.PhoneType ? row.PhoneType : ""),
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) =>
        row.PhoneNo || "",
      sortable: true,
    },
    {
      width: "68px",
      name: (
        <div
          className="d-flex justify-content-end"
        // style={{ position: "absolute", top: "7px", right: 0 }}
        >
          Delete
        </div>
      ),
      cell: (row) => (
        <div className="d-flex justify-content-end btn btn-sm bg-green text-white px-1 py-0 mr-1" onClick={() =>
          handleDeleteContact(row.ID)
        }>
          <i className="fa fa-trash"></i>
        </div>
      ),
    },
  ];

  return (
    <DataTable
      dense
      columns={columns}
      data={contactList}
      customStyles={tableCustomStyles}
      pagination
      responsive
      striped
      highlightOnHover
      fixedHeader
      selectableRowsHighlight
      paginationPerPage={"13"}
      paginationRowsPerPageOptions={[5, 10, 15, 20]}
      fixedHeaderScrollHeight="600px"
      persistTableHead={true}
      onRowClicked={(row) => {
        set_Edit_Value(row);
      }}
    />
  );
}

export default GEOContactTable;

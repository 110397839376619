import React, { useRef, useContext, useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { Link, useLocation } from 'react-router-dom';
import Select from "react-select";
import { toastifySuccess } from '../../../../Common/AlertMsg';
import { Comman_changeArrayFormat, Comman_changeArrayFormatBasicInfo, threeColArrayWithCode, typeofsecurityArray } from '../../../../Common/ChangeArrayFormat';
import DeletePopUpModal from '../../../../Common/DeleteModal';
import { Decrypt_Id_Name, DecryptedList, base64ToString, tableCustomStyles } from '../../../../Common/Utility';
import { fetchPostData, AddDeleteUpadate, ScreenPermision } from '../../../../hooks/Api';
import { RequiredFieldIncident } from '../../../Utility/Personnel/Validation';
import FindListDropDown from '../../../../Common/FindListDropDown';
import { IncTypeListDropDownArray } from '../../../../Utility/ListDropDownArray/ListDropArray';
import Loader from '../../../../Common/Loader';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import { components } from "react-select";
import SelectBox from '../../../../Common/SelectBox';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../../redux/actions/Agency';

const TypeOfSecurity = () => {


  const useQuery = () => {
    const params = new URLSearchParams(useLocation().search);
    return {
      get: (param) => params.get(param)
    };
  };

  const query = useQuery();
  var IncID = query?.get("IncId");
  if (!IncID) IncID = 0;
  else IncID = parseInt(base64ToString(IncID));

  const dispatch = useDispatch()
  const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const [disabled, setDisabled] = useState(false);

  const SelectedValue = useRef();
  const { get_IncidentTab_Count, } = useContext(AgencyContext);
  const [typeOfSecurityData, setTypeOfSecurityData] = useState();
  const [typeOfSecurityEditVal, setTypeOfSecurityEditVal] = useState();
  const [typeOfSecurityList, setTypeOfSecurityList] = useState([]);
  const [loder, setLoder] = useState(false);
  const [loginAgencyID, setLoginAgencyID] = useState('');
  const [incidentID, setIncidentID] = useState('');
  const [loginPinID, setLoginPinID] = useState('');
  const [effectiveScreenPermission, setEffectiveScreenPermission] = useState();

  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const [value, setValue] = useState({
    'SecurityId': '', 'SecurityIdName': '', 'IncidentSecurityID': '',
    'ModifiedByUserFK': '', 'IncidentId': '', 'CreatedByUserFK': '',
  })

  const [multiSelected, setMultiSelected] = useState({
    securityID: null,
  })

  useEffect(() => {
    if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
      if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
    }
  }, []);


  useEffect(() => {
    if (localStoreData) {
      setLoginAgencyID(localStoreData?.AgencyID); setLoginPinID(localStoreData?.PINID);
      setValue({ ...value, 'CreatedByUserFK': localStoreData?.PINID });
    }
  }, [localStoreData]);

  useEffect(() => {
    if (IncID) {
      setValue({ ...value, 'IncidentId': IncID, 'CreatedByUserFK': loginPinID }); setIncidentID(IncID); get_Security_Data(IncID);
      //  get_IncidentTab_Count(IncID); 
    } else { }
  }, [IncID]);

  const [errors, setErrors] = useState({
    'TypeOfSecError': '',
  })

  useEffect(() => {
    if (typeOfSecurityEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['securityID']: typeOfSecurityEditVal } }) }
  }, [typeOfSecurityEditVal])


  const typeofsecurity = (multiSelected) => {
    setMultiSelected({
      ...multiSelected,
      securityID: multiSelected
    })
    const len = multiSelected.length - 1
    if (multiSelected?.length < typeOfSecurityEditVal?.length) {
      let missing = null;
      let i = typeOfSecurityEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(typeOfSecurityEditVal[--i])) ? missing : typeOfSecurityEditVal[i];
      }
      DelSertBasicInfo(missing.id, 'IncidentSecurityID', 'TypeOfSecurity/DeleteTypeOfSecurity')
    } else {
      InSertBasicInfo(multiSelected[len].value, 'SecurityId', 'TypeOfSecurity/InsertTypeOfSecurity')
    }
  }

  useEffect(() => {
    if (loginAgencyID) {
      get_Security_DropDown(loginAgencyID);
    }
  }, [loginAgencyID])

  const get_Security_Data = (incidentID) => {
    const val = { 'IncidentId': incidentID, }
    fetchPostData('TypeOfSecurity/GetDataTypeOfSecurity', val).then((res) => {
      if (res) {
        setTypeOfSecurityEditVal(typeofsecurityArray(res, 'SecurityId', 'IncidentID', 'PretendToBeID', 'IncidentSecurityID', 'Security_Description'));
      } else {
        setTypeOfSecurityEditVal([]);
      }
    })
  }

  //--------Security_fetchData----------//
  const get_Security_DropDown = (loginAgencyID) => {
    const val = { 'AgencyID': loginAgencyID }
    fetchPostData('TypeOfSecurity/GetData_InsertTypeOfSceurity', val).then((data) => {
      if (data) {
        setTypeOfSecurityList(threeColArrayWithCode(data, 'SecurityId', 'Description', 'SecurityCode'));
      }
      else {
        setTypeOfSecurityList([])
      }
    })
  }

  const ChangeDropDown = (e, name) => {
    if (e) {
      setValue({
        ...value,
        [name]: e.value
      })
    } else setValue({
      ...value,
      [name]: null
    })
  }

  const onClear = () => {
    SelectedValue?.current?.clearValue();
  };

  const InSertBasicInfo = (id, col1, url) => {
    setDisabled(true)
    const val = {
      'IncidentId': incidentID,
      [col1]: id,
      'CreatedByUserFK': loginPinID,
    }
    AddDeleteUpadate(url, val).then((res) => {
      if (res) {
        setDisabled(false)
        toastifySuccess(res.Message);
        get_IncidentTab_Count(incidentID);
        col1 === 'SecurityId' && get_Security_Data(IncID);
      } else {
        console.log("Somthing Wrong");
      }
    })
  }

  const DelSertBasicInfo = (IncidentSecurityID, col1, url) => {
    const val = {
      [col1]: IncidentSecurityID,
      'DeletedByUserFK': loginPinID,
    }
    AddDeleteUpadate(url, val).then((res) => {
      if (res) {
        toastifySuccess(res.Message);
        get_IncidentTab_Count(incidentID);
        col1 === 'IncidentSecurityID' && get_Security_Data(IncID)
      } else {
        console.log("res");
      }
    })
  }

  // Custom Style
  const colourStyles = {
    control: (styles) => ({
      ...styles, backgroundColor: "#fce9bf",
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };

  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      minHeight: 60,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };

  return (
    <>
      <div className="col-12 ">
        <div className="row mt-1">
          <div className="col-2 col-md-2 col-lg-2 mt-4">
            <label htmlFor="" className='new-label'>Type Of Security</label>
          </div>
          <div className="col-7 col-md-7 col-lg-5 mt-2 mb-2">
            {
              value?.SecurityIdName ?
                <Select
                  className="basic-multi-SelectBox"
                  isMulti
                  name='SecurityId'
                  isClearable
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  options={typeOfSecurityList}
                  onChange={(e) => typeofsecurity(e)}
                  value={multiSelected.securityID}
                  components={{ MultiValue, }}
                  placeholder="Select Type Of Security From List.."
                  styles={customStylesWithOutColor}
                />
                :
                <Select
                  className="basic-multi-select"
                  isMulti
                  name='SecurityId'
                  isClearable
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  options={typeOfSecurityList}
                  onChange={(e) => typeofsecurity(e)}
                  value={multiSelected.securityID}
                  placeholder="Select Type Of Security From List.."
                  components={{ MultiValue, }}
                  styles={customStylesWithOutColor}
                  pagination
                  paginationPerPage={'100'}
                  paginationRowsPerPageOptions={[100, 150, 200, 500]}
                  showPaginationBottom={100}
                />
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default TypeOfSecurity


import React, { useEffect, useRef, useState } from 'react'
import { PDFDocument } from 'pdf-lib';
import Loader from '../../Common/Loader';


const PrintUCR = () => {

    const [arrayBuffer, setArrayBuffer] = useState(null);
    const [pdfURL, setPdfURL] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [values, setValues] = useState({});
    const iframeRef = useRef(null);


    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
            const reader = new FileReader();
            reader.onload = function (e) {
                const arrayBuffer = e.target.result;
                setArrayBuffer(arrayBuffer);
            };

            reader.onerror = function (error) {
                console.error('Error reading file:', error);
            };
            reader.readAsArrayBuffer(file);
        } else {
            console.error('Please upload a valid PDF file.');
        }
    };


    useEffect(() => {
        if (arrayBuffer) {
            const setPDF = async () => {
                setIsLoading(true)
                const fieldsObject = {};
                const pdfDoc = await PDFDocument.load(arrayBuffer)

                const form = pdfDoc.getForm();
                const fields = form.getFields();
                fields.forEach((field, index) => {
                    const type = field.constructor.name;
                    const name = field.getName();
                    console.log(`Field ${index + 1}: ${name} (${type})`);
                    fieldsObject[name.replace(/ +/g, "")] = type;
                    setValues(fieldsObject);
                });

                const AdjustmentField = fieldsObject?.chkIDate ? form.getCheckBox('chkIDate') : null;
                const chkAttempt = fieldsObject?.chkAttempt ? form.getCheckBox('chkAttempt') : null;
                const chkMale = fieldsObject?.chkMale ? form.getCheckBox('chkMale') : null;
                const chkBlack = fieldsObject?.chkBlack ? form.getCheckBox('chkBlack') : null;
                const chkHispanic = fieldsObject?.chkHispanic ? form.getCheckBox('chkHispanic') : null;
                const chkOffFemale = fieldsObject?.chkOffFemale ? form.getCheckBox('chkOffFemale') : null;
                const chkOffNonHispanic = fieldsObject?.chkOffNonHispanic ? form.getCheckBox('chkOffNonHispanic') : null;
                const chkOffAsian = fieldsObject?.chkOffAsian ? form.getCheckBox('chkOffAsian') : null;
                const chkPA = fieldsObject?.chkPA ? form.getCheckBox('chkPA') : null;
                const chkBG = fieldsObject?.chkBG ? form.getCheckBox('chkBG') : null;
                const chkFirearm = fieldsObject?.chkFirearm ? form.getCheckBox('chkFirearm') : null;
                const chkPoison = fieldsObject?.chkPoison ? form.getCheckBox('chkPoison') : null;
                const chkKnife = fieldsObject?.chkKnife ? form.getCheckBox('chkKnife') : null;
                const chkLossTeath = fieldsObject?.chkLossTeath ? form.getCheckBox('chkLossTeath') : null;
                const chkHighWay = fieldsObject?.chkHighWay ? form.getCheckBox('chkHighWay') : null;
                const chkSexualAssault = fieldsObject?.chkSexualAssault ? form.getCheckBox('chkSexualAssault') : null;
                const chkCompEquipment = fieldsObject?.chkCompEquipment ? form.getCheckBox('chkCompEquipment') : null;
                const chkCrimeYes = fieldsObject?.chkCrimeYes ? form.getCheckBox('chkCrimeYes') : null;

                const txtAgency = fieldsObject?.txtAgency ? form.getTextField('txtAgency') : null;
                const txtIncident = fieldsObject?.txtIncident ? form.getTextField('txtIncident') : null;
                const txtIncidentNumber = fieldsObject?.txtIncidentNumber ? form.getTextField('txtIncidentNumber') : null;
                const txtVictim = fieldsObject?.txtVictim ? form.getTextField('txtVictim') : null;
                const txtMinAge = fieldsObject?.txtMinAge ? form.getTextField('txtMinAge') : null;
                const txtNVictims = fieldsObject?.txtNVictims ? form.getTextField('txtNVictims') : null;
                const OffenderSeq = fieldsObject?.OffenderSeq ? form.getTextField('Offender Seq') : null;
                const txtOffMinAge = fieldsObject?.txtOffMinAge ? form.getTextField('txtOffMinAge') : null;
                const txtOffNumber = fieldsObject?.txtOffNumber ? form.getTextField('txtOffNumber') : null;

                AdjustmentField?.check()
                chkAttempt?.check()
                chkMale?.check()
                chkBlack?.check()
                chkHispanic?.check()
                chkOffFemale?.check()
                chkOffNonHispanic?.check()
                chkOffAsian?.check()

                chkPA?.check()
                chkBG?.check()
                chkFirearm?.check()
                chkPoison?.check()
                chkKnife?.check()
                chkLossTeath?.check()
                chkHighWay?.check()
                chkSexualAssault?.check()
                chkCompEquipment?.check()
                chkCrimeYes?.check()

                txtAgency?.setText('454545123')
                txtIncident?.setText('13:24')
                txtIncidentNumber?.setText('BGBG23456723')
                txtVictim?.setText('1')
                txtMinAge?.setText('18')
                txtNVictims?.setText('11')
                OffenderSeq?.setText('15')
                txtOffMinAge?.setText('15')
                txtOffNumber?.setText('4')

                const pdfBytes = await pdfDoc.save();
                const blob = new Blob([pdfBytes], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                setPdfURL(url)
                setIsLoading(false)
            }
            setPDF();

        }
    }, [arrayBuffer])

    const fillForm = async () => {
        // const newWindow = window.open(pdfURL, '_self');
        // if (newWindow) {
        //   newWindow.onload = () => {
        //     newWindow.print();
        //   };
        // }
        const iframe = iframeRef.current;
        iframe.src = pdfURL;

        // // Wait for the iframe to load and then print
        iframe.onload = () => {
            iframe.contentWindow.print();
        };

    }

    return (
        !isLoading ?
            <>
                <div className="section-body view_page_design pt-1">
                    <div className="row clearfix" >
                        <div className="col-12 col-sm-12">
                            <div className="card Agency  name-card ">
                                <div className="card-body">
                                    <div className="col-12 col-md-12 col-lg-12 ">
                                        <div className="row " style={{ marginTop: '-10px' }}>
                                            <label className='mr-1'>
                                                <span className='btn btn-primary'>Select File</span>
                                                <input type="file" id="fileInput" accept="application/pdf" onChange={handleFileChange} />
                                                {arrayBuffer && <p>PDF loaded successfully!</p>}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12 ">
                                        <div className="row">
                                            {pdfURL && <iframe src={pdfURL} style={{ height: "700px", width: "100%" }} />}
                                        </div>
                                    </div>
                                    <div className="btn-box  text-right  mr-1 mb-1 mt-3" >
                                        {pdfURL && <button type="button" data-dismiss="modal" className="btn btn-sm btn-success mr-1" onClick={fillForm}>Print</button>}
                                    </div>
                                    <iframe ref={iframeRef} style={{ display: 'none', paddingTop: "2rem" }} title="pdf-frame"></iframe>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </> :
            <Loader />
    )
}

export default PrintUCR

import React, { memo, useEffect, useState } from "react";
import Select from "react-select";
import { useQuery } from 'react-query';
import MasterTableListServices from "../../CADServices/APIs/masterTableList";
import ResourcesStatusServices from "../../CADServices/APIs/resourcesStatus";
import useObjState from "../../CADHook/useObjState";
import { toastifySuccess } from "../../Components/Common/AlertMsg";

const DispatcherModal = (props) => {
    const { openDispatcherModal, setOpenDispatcherModal } = props;
    const [resourceDropDown, setResourceDropDown] = useState([])
    const [incidentsDropDown, setIncidentsDropDown] = useState([])
    const [
        dispatchState,
        _setDispatchState,
        handleDispatchState,
        clearDispatchState,
    ] = useObjState({
        IncidentID: "",
        Resources1: "",
        Resources2: "",
        Resources3: "",
        Resources4: "",
        Resources5: "",
        Resources6: "",
        Resources7: "",
        Resources8: "",
        Comments: ""
    });

    const getIncidentsKey = `CallTakerIncident/GetData_Incident_dp`;
    const { data: getIncidentsData, isSuccess: isFetchIncidentData } = useQuery(
        [getIncidentsKey, {},],
        ResourcesStatusServices.getIncidents,
        {
            refetchOnWindowFocus: false,
            enabled: openDispatcherModal
        }
    );

    const getResourcesKey = `CallTackerResource/GetData_Resources`;
    const { data: getResourcesData, isSuccess } = useQuery(
        [getResourcesKey, { CFSID: "" },],
        MasterTableListServices.getResources,
        {
            refetchOnWindowFocus: false,
            enabled: openDispatcherModal
        }
    );

    useEffect(() => {
        if (getIncidentsData && isFetchIncidentData) {
            const resources = getIncidentsData?.data?.data?.Table || []
            console.log("resources", resources)
            setIncidentsDropDown(resources)
        }
    }, [getIncidentsData, isFetchIncidentData])

    useEffect(() => {
        if (getResourcesData && isSuccess) {
            const resources = JSON.parse(getResourcesData?.data?.data || [])?.Table
            setResourceDropDown(resources)
        }
    }, [getResourcesData, isSuccess])

    const onCloseLocation = () => {
        clearDispatchState()
        setOpenDispatcherModal(false);
    };

    async function handleSave() {
        const data = {
            ResourceStatus: "Dispatch",
            IncidentID: dispatchState?.IncidentID?.IncidentID,
            Resources1: dispatchState?.Resources1?.ResourceID,
            Resources2: dispatchState?.Resources2?.ResourceID,
            Resources3: dispatchState?.Resources3?.ResourceID,
            Resources4: dispatchState?.Resources4?.ResourceID,
            Resources5: dispatchState?.Resources5?.ResourceID,
            Resources6: dispatchState?.Resources6?.ResourceID,
            Resources7: dispatchState?.Resources7?.ResourceID,
            Resources8: dispatchState?.Resources8?.ResourceID,
            Comments: dispatchState?.Comments
        }
        const response = await ResourcesStatusServices.insertDispatcher(data);
        if (response?.status === 200) {
            toastifySuccess("Data Saved Successfully");
            onCloseLocation();
        }
    }

    const customStylesWithOutColor = {
        control: (base) => ({
            ...base,
            height: 20,
            minHeight: 35,
            fontSize: 14,
            marginTop: 2,
            boxShadow: 0,
        }),
    };

    return (
        <>
            {openDispatcherModal ? (
                <>
                    <dialog
                        className="modal fade"
                        style={{ background: "rgba(0,0,0, 0.5)", zIndex: "200" }}
                        id="DispatcherModal"
                        tabIndex="-1"
                        aria-hidden="true"
                        data-backdrop="false"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-xl">
                            <div className="modal-content modal-content-cad">
                                <div className="modal-body">
                                    <div className="row pb-2">
                                        <div className="py-0 px-2 d-flex justify-content-between align-items-center">
                                            <p
                                                className="p-0 m-0 font-weight-medium"
                                                style={{
                                                    fontSize: 18,
                                                    fontWeight: 500,
                                                    letterSpacing: 0.5,
                                                }}
                                            >
                                                Dispatch
                                            </p>
                                        </div>
                                    </div>
                                    <div className="m-1">
                                        <fieldset style={{ border: "1px solid gray" }}>
                                            <div className="tab-form-container">
                                                <div className="tab-form-row">
                                                    <div className="col-2 d-flex justify-content-end">
                                                        <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Incident#</label>
                                                    </div>
                                                    <div className="col-2">
                                                        <Select
                                                            name="Incident"
                                                            styles={customStylesWithOutColor}
                                                            isClearable
                                                            isSearchable
                                                            options={incidentsDropDown}
                                                            value={dispatchState?.IncidentID}
                                                            getOptionLabel={(v) => v?.IncidentNumber}
                                                            getOptionValue={(v) => v?.IncidentID}
                                                            onChange={(e) => handleDispatchState("IncidentID", e)}
                                                            placeholder="Select..."
                                                            className="w-100"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="tab-form-row">
                                                    <div className="col-2 d-flex align-items-center justify-content-end">
                                                        <label className="tab-form-label text-nowrap" >
                                                            Resources
                                                        </label>
                                                    </div>
                                                    <div
                                                        className="col-10 d-flex align-items-center justify-content-end select-container mr-1"
                                                        style={{ gap: "4.5px" }}
                                                    >
                                                        <Select
                                                            isClearable
                                                            options={resourceDropDown}
                                                            placeholder="Select..."
                                                            className="w-100"
                                                            name="Resource1"
                                                            getOptionLabel={(v) => v?.ResourceNumber}
                                                            getOptionValue={(v) => v?.ResourceID}
                                                            value={dispatchState?.Resources1}
                                                            onChange={(e) => handleDispatchState("Resources1", e)}
                                                            styles={customStylesWithOutColor}
                                                        />
                                                        <Select
                                                            isClearable
                                                            options={resourceDropDown}
                                                            name="Resource2"
                                                            placeholder="Select..."
                                                            className="w-100"
                                                            getOptionLabel={(v) => v?.ResourceNumber}
                                                            getOptionValue={(v) => v?.ResourceID}
                                                            value={dispatchState?.Resources2}
                                                            onChange={(e) => handleDispatchState("Resources2", e)}
                                                            styles={customStylesWithOutColor}
                                                        />
                                                        <Select
                                                            isClearable
                                                            placeholder="Select..."
                                                            options={resourceDropDown}
                                                            name="Resource3"
                                                            className="w-100"
                                                            getOptionLabel={(v) => v?.ResourceNumber}
                                                            getOptionValue={(v) => v?.ResourceID}
                                                            value={dispatchState?.Resources3}
                                                            onChange={(e) => handleDispatchState("Resources3", e)}
                                                            styles={customStylesWithOutColor}
                                                        />
                                                        <Select
                                                            isClearable
                                                            options={resourceDropDown}
                                                            placeholder="Select..."
                                                            className="w-100"
                                                            name="Resource4"
                                                            getOptionLabel={(v) => v?.ResourceNumber}
                                                            getOptionValue={(v) => v?.ResourceID}
                                                            value={dispatchState?.Resources4}
                                                            onChange={(e) => handleDispatchState("Resources4", e)}
                                                            styles={customStylesWithOutColor}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="tab-form-row">

                                                    <div
                                                        className="col-10 offset-2 d-flex align-items-center justify-content-end select-container mr-1"
                                                        style={{ gap: "4.5px" }}
                                                    >
                                                        <Select
                                                            isClearable
                                                            options={resourceDropDown}
                                                            placeholder="Select..."
                                                            className="w-100"
                                                            name="Resource1"
                                                            getOptionLabel={(v) => v?.ResourceNumber}
                                                            getOptionValue={(v) => v?.ResourceID}
                                                            value={dispatchState?.Resources5}
                                                            onChange={(e) => handleDispatchState("Resources5", e)}
                                                            styles={customStylesWithOutColor}
                                                        />
                                                        <Select
                                                            isClearable
                                                            options={resourceDropDown}
                                                            name="Resource2"
                                                            placeholder="Select..."
                                                            className="w-100"
                                                            getOptionLabel={(v) => v?.ResourceNumber}
                                                            getOptionValue={(v) => v?.ResourceID}
                                                            value={dispatchState?.Resources6}
                                                            onChange={(e) => handleDispatchState("Resources6", e)}
                                                            styles={customStylesWithOutColor}
                                                        />
                                                        <Select
                                                            isClearable
                                                            options={resourceDropDown}
                                                            placeholder="Select..."
                                                            name="Resource3"
                                                            className="w-100"
                                                            getOptionLabel={(v) => v?.ResourceNumber}
                                                            getOptionValue={(v) => v?.ResourceID}
                                                            value={dispatchState?.Resources7}
                                                            onChange={(e) => handleDispatchState("Resources7", e)}
                                                            styles={customStylesWithOutColor}
                                                        />
                                                        <Select
                                                            isClearable
                                                            options={resourceDropDown}
                                                            placeholder="Select..."
                                                            className="w-100"
                                                            name="Resource4"
                                                            getOptionLabel={(v) => v?.ResourceNumber}
                                                            getOptionValue={(v) => v?.ResourceID}
                                                            value={dispatchState?.Resources8}
                                                            onChange={(e) => handleDispatchState("Resources8", e)}
                                                            styles={customStylesWithOutColor}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="tab-form-row">
                                                    <div className="col-2 d-flex align-items-center justify-content-end">
                                                        <label className="tab-form-label text-nowrap" >
                                                            Comments
                                                        </label>
                                                    </div>
                                                    <div
                                                        className="col-10 d-flex align-items-center justify-content-end select-container"
                                                    >
                                                        <textarea name="comments" rows='2'
                                                            className="form-control py-1 new-input" value={dispatchState?.Comments}
                                                            style={{ height: '60px' }}
                                                            onChange={(e) => handleDispatchState("Comments", e.target.value)} />

                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    {/* Buttons */}
                                    <div className="row">
                                        <div className="col-12 p-0">
                                            <div className="py-0 px-2 d-flex justify-content-end align-items-center">
                                                <div className="d-flex justify-content-end tab-form-row-gap mt-1">
                                                    <button
                                                        type="button"
                                                        className="save-button ml-2"
                                                        onClick={() => handleSave()}
                                                    >
                                                        {'Save'}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        data-dismiss="modal"
                                                        className="cancel-button"
                                                        onClick={onCloseLocation}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </dialog >
                </>
            ) : (
                <> </>
            )
            }
        </>
    );
};

export default memo(DispatcherModal);

import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import IncidentSummaryModel from './IncidentSummaryModel';
import { fetchPostData } from '../../hooks/Api';
import NameListing from '../ShowAllList/NameListing';
import IdentifyFieldColor from '../../Common/IdentifyFieldColor';
import { Decrypt_Id_Name, getShowingWithOutTime, tableCustomStyles } from '../../Common/Utility';
import OtherSummaryModel from './OtherSummaryModel';
import { useLocation } from 'react-router-dom';
import { get_LocalStoreData } from '../../../redux/actions/Agency';
import { get_ScreenPermissions_Data } from '../../../redux/actions/IncidentAction';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

const VehicleInvolvement = (props) => {

    const { idColName, masterID, tabID, incId, url, scrCode } = props
    const [clickedRow, setClickedRow] = useState(null);
    const dispatch = useDispatch()
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const effectiveScreenPermission = useSelector((state) => state.Incident.effectiveScreenPermission);

    const [transactionData, setTransactionData] = useState([]);
    const [incSummModal, setIncSummModal] = useState(false);
    const [otherSummModal, setOtherSummModal] = useState(false);
    const [otherColName, setOtherColName] = useState('');
    const [otherColID, setOtherColID] = useState('');
    const [otherUrl, setOtherUrl] = useState('');
    const [updateCount, setupdateCount] = useState(1);
    const useQuery = () => new URLSearchParams(useLocation().search);
    let openPage = useQuery().get('page');


    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (localStoreData) {
            dispatch(get_ScreenPermissions_Data(scrCode, localStoreData?.AgencyID, localStoreData?.PINID));
        }
    }, [localStoreData]);

    useEffect(() => {
        if (masterID) {
            Get_TransactionData(masterID);
        }
    }, [masterID]);

    const Get_TransactionData = () => {
        const val = { [idColName]: masterID }
        fetchPostData('TransactionLog/GetData_VehicleTransactionLog', val).then((res) => {
            if (res) {
                console.log(res);
                setTransactionData(res)
            } else {
                setTransactionData();
            }
        })
    }

    const columns = [
        {
            cell: row =>
                <div className="div" >
                    <a data-toggle="modal" data-target={`${row?.TransactionName == "Incident" ? "#IncSummaryModel" : "#OtherSummaryModel"}`}
                        style={{ textDecoration: 'underline' }}
                        onClick={() => {
                            setupdateCount(updateCount + 1);
                            if (row?.TransactionName == "Incident") {
                                setIncSummModal(true)
                            } else if (row?.TransactionName != "Incident") {
                                setOtherSummModal(true)
                                console.log(row)
                                switch (row?.TransactionName) {
                                    case "Name": setOtherColName('NameID'); setOtherColID(row?.ID); setOtherUrl('Summary/NameSummary'); break;
                                    case "Arrest": setOtherColName('ArrestID'); setOtherColID(row?.ID); setOtherUrl('Summary/ArrestSummary'); break;
                                    default:
                                }
                            }
                        }}
                    >
                        MS
                    </a>
                </div>
        },
        {
            width: '120px',
            name: 'Full Name',
            selector: (row) => row.Name,
            sortable: true
        },
        {
            width: '180px',
            name: 'Transaction Name',
            selector: (row) => row.TransactionName,
            sortable: true
        },
        {
            width: '180px',
            name: 'Transaction Number',
            selector: (row) => row.TransactionNumber,
            sortable: true
        },
    ]

    const conditionalRowStyles = [
        {
            when: row => row === clickedRow,
            style: {
                backgroundColor: '#001f3fbd',
                color: 'white',
                cursor: 'pointer',
            },
        },
    ];

    const onClickedRow = (row) => {
        // setClickedRow(row);
        // if (row?.TransactionName === "Incident") {
        //     setIncSummModal(true)
        // } else if (row?.TransactionName != "Incident") {
        //     setOtherSummModal(true)
        // }
    }

    return (
        <>
            <NameListing />
            <div className="col-md-12 mt-2">
                <div className="col-12 mt-2">
                    <DataTable
                        dense
                        columns={columns}
                        // data={transactionData}
                        data={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? transactionData : [] : transactionData}
                        pagination
                        selectableRowsHighlight
                        highlightOnHover
                        responsive
                        showPaginationBottom={10}
                        customStyles={tableCustomStyles}
                        onRowClicked={(row) => {
                            onClickedRow(row);
                        }}
                        fixedHeader
                        persistTableHead={true}
                        fixedHeaderScrollHeight='330px'
                        conditionalRowStyles={conditionalRowStyles}
                    />
                </div>
            </div>
            <IdentifyFieldColor />
            <IncidentSummaryModel {...{ setIncSummModal, incSummModal }} />
            <OtherSummaryModel
                {...{ otherSummModal, setOtherSummModal, updateCount, openPage }}
                otherColName={otherColName}
                otherColID={otherColID}
                otherUrl={otherUrl}
            />
        </>
    )
}

export default VehicleInvolvement
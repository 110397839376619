import axios from "axios";
class MasterTableListServices {


    insertCFS = async (payload) => {
        if (payload) {
            return await axios.post(`/MasterCallforServiceCode/InsertCallforServiceCode`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.insertCFS");
        return null;
    };
    getShift = async ({ queryKey }) => {
        const [_key, payloadShift] = queryKey;
        if (payloadShift) {
            return await axios.post(`/MasterShift/GetShift`, payloadShift);
        }
        console.warn("payload not provided, MasterTableListServices.getShift");
        return null;
    };

    getStationCode = async ({ queryKey }) => {
        const [_key, payloadStationCode] = queryKey;
        if (payloadStationCode) {
            return await axios.post(`/MasterStationCode/GetStationCode`, payloadStationCode);
        }
        console.warn("payload not provided, MasterTableListServices.GetStationCode");
        return null;
    };

    getResourceType = async ({ queryKey }) => {
        const [_key, payloadGetResourceType] = queryKey;
        if (payloadGetResourceType) {
            return await axios.post(`/MasterResourceType/GetResourceType`, payloadGetResourceType);
        }
        console.warn("payload not provided, MasterTableListServices.GetResourceType");
        return null;
    };

    getDataZone = async ({ queryKey }) => {
        const [_key, payloadGetResourceType] = queryKey;
        if (payloadGetResourceType) {
            return await axios.post(`/GeoPetrolZone/GetData_Zone`, payloadGetResourceType);
        }
        console.warn("payload not provided, MasterTableListServices.GetDataZone");
        return null;
    };

    getResources = async ({ queryKey }) => {
        const [_key, payloadGetResources] = queryKey;
        if (payloadGetResources) {
            return await axios.post(`/CallTackerResource/GetData_Resources`, payloadGetResources);
        }
        console.warn("payload not provided, MasterTableListServices.getResources");
        return null;
    };

    getPrimaryOfficer = async ({ queryKey }) => {
        const [_key] = queryKey;
        return await axios.post(`/MasterPrimaryOfficer/PrimaryOfficer`);
    };

    getAgencyCode = async ({ queryKey }) => {
        const [_key] = queryKey;
        return await axios.post(`/MasterAgency/MasterAgencyCode`);
    };

    insertResource = async (payload) => {
        if (payload) {
            return await axios.post(`/CallTackerResource/InsertResource`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.InsertResource");
        return null;
    };

    updateResource = async (payload) => {
        if (payload) {
            return await axios.post(`/CallTackerResource/UpdateResource`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.updateResource");
        return null;
    };

    insertResourceType = async (payload) => {
        if (payload) {
            return await axios.post(`/MasterResourceType/InsertResourceType`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.insertResourceType");
        return null;
    };

    getAgencyCode = async ({ queryKey }) => {
        const [_key] = queryKey;
        return await axios.post(`/MasterAgency/MasterAgencyCode`);
    };

    insertZone = async (payload) => {
        if (payload) {
            return await axios.post(`/GeoPetrolZone/InsertZone`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.insertZone");
        return null;
    };

    getZone = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/GeoPetrolZone/GetData_Zone`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.getZone");
        return null;
    };
}

const instance = new MasterTableListServices();

export default instance;
